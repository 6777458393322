import { TSupportedLanguages } from '@models/supported-languages';
import { enPrivacyPolicy } from '@utils/privacy-policy/en';
import { jaPrivacyPolicy } from '@utils/privacy-policy/ja';

export const i18n = LangSettings({
  privacyPolicyMessageBody: {
    ja: jaPrivacyPolicy,
    en: enPrivacyPolicy,
    zhcn: enPrivacyPolicy,
    zhtw: enPrivacyPolicy,
    ko: enPrivacyPolicy,
    th: enPrivacyPolicy,
    vi: enPrivacyPolicy,
  },
  controlTemp: {
    ja: '管理温度',
    en: 'Controlled Temperature',
    zhcn: '控制温度',
    zhtw: '控制溫度',
    ko: '관리 온도',
    th: 'ควบคุมอุณหภูมิ',
    vi: 'Nhiệt độ được kiểm soát',
  },
  storagePeriod: {
    ja: '滞在時間',
    en: 'Storage Duration',
    zhcn: '停留时间',
    zhtw: '停留時間',
    ko: '체류 시간',
    th: 'ระยะเวลาการจัดเก็บ',
    vi: 'Thời lượng lưu trữ',
  },
  slipNo: {
    ja: '伝票番号',
    en: 'Slip Number',
    zhcn: '单号',
    zhtw: '單號',
    ko: '전표 번호',
    th: 'หมายเลขสลิป',
    vi: 'Số phiếu',
  },
  shipmentDate: {
    ja: '出荷日時',
    en: 'Shipping Date',
    zhcn: '装运日期和时间',
    zhtw: '裝運日期和時間',
    ko: '출하 일자',
    th: 'วันที่จัดส่ง',
    vi: 'Ngày vận chuyển',
  },
  shipmentDateShort: {
    ja: '出荷',
    en: 'Shipping',
    zhcn: '运输',
    zhtw: '運輸',
    ko: '출하',
    th: 'การส่งสินค้า',
    vi: 'Đang chuyển hàng',
  },
  sellDate: {
    ja: '販売日時',
    en: 'Sales Date',
    zhcn: '销售日期和时间',
    zhtw: '銷售日期和時間',
    ko: '판매 일자',
    th: 'วันที่ขาย',
    vi: 'Ngày bán hàng',
  },
  sellDateShort: {
    ja: '販売',
    en: 'Sales',
    zhcn: '销售',
    zhtw: '銷售',
    ko: '판매',
    th: 'ฝ่ายขาย',
    vi: 'Việc bán hàng',
  },
  shippedBy: {
    ja: '実施者',
    en: 'Shipped by',
    zhcn: '寄件者',
    zhtw: '寄件者',
    ko: '배송 담당',
    th: 'จัดส่งโดย',
    vi: 'Vận chuyển bằng',
  },
  receiveDate: {
    ja: '入荷日時',
    en: 'Receiving Date',
    zhcn: '货到日时',
    zhtw: '收件日時',
    ko: '입하 날짜',
    th: 'วันที่รับ',
    vi: 'Ngày nhận',
  },
  receiveDateShort: {
    ja: '入荷',
    en: 'Receiving',
    zhcn: '货到',
    zhtw: '收件',
    ko: '입하',
    th: 'การรับ',
    vi: 'Đang nhận',
  },
  receivedBy: {
    ja: '実施者',
    en: 'Received by',
    zhcn: '收件者',
    zhtw: '收件者',
    ko: '입하 담당',
    th: 'ที่ได้รับจาก',
    vi: 'Nhận bởi',
  },
  shippingDestination: {
    ja: '出荷先',
    en: 'Shipping Destination',
    zhcn: '运送目的地',
    zhtw: '運送目的地',
    ko: '출하처',
    th: 'ปลายทางการจัดส่งสินค้า',
    vi: 'Điểm đến vận chuyển',
  },
  sellingDate: {
    ja: '販売日',
    en: 'Sales Date',
    zhcn: '发售日期',
    zhtw: '銷售日期',
    ko: '판매 일',
    th: 'วันที่ขาย',
    vi: 'Ngày bán hàng',
  },
  distributionInfo: {
    ja: '流通情報',
    en: 'Distribution Information',
    zhcn: '配送信息',
    zhtw: '配送信息',
    ko: '유통 정보',
    th: 'ข้อมูลการจัดจำหน่าย',
    vi: 'Thông tin phân phối',
  },
  brand: {
    ja: '銘柄',
    en: 'Brand',
    zhcn: '品牌',
    zhtw: '品牌',
    ko: '브랜드',
    th: 'ยี่ห้อ',
    vi: 'Thương hiệu',
  },
  serialNo: {
    ja: 'シリアル番号',
    en: 'Serial Number',
    zhcn: '序列号',
    zhtw: '序列號',
    ko: '일련 번호',
    th: 'หมายเลขซีเรียล',
    vi: 'Số seri',
  },
  brewShipmentDate: {
    ja: '酒蔵出荷日時',
    en: 'Brewery Shipping Date',
    zhcn: '啤酒出货日期和时间',
    zhtw: '酒廠出貨日期和時間',
    ko: '양조장 출하 날짜',
    th: 'วันที่จัดส่งโรงเบียร์',
    vi: 'Ngày vận chuyển nhà máy bia',
  },
  alertDate: {
    ja: 'アラート発出日時',
    en: 'Alert Time',
    zhcn: '警报发出日期和时间',
    zhtw: '警報發出日期和時間',
    ko: '알림 발생일시',
    th: 'เวลาแจ้งเตือน',
    vi: 'Thời gian cảnh báo',
  },
  mapAddressBaidu: {
    ja: '住所 [BAIDUマップ用]',
    en: 'Baidu Map Address',
    zhcn: '地址[为百度地图]',
    zhtw: '地址[為百度地圖]',
    ko: '주소 [Baidu지도 용]',
    th: 'ที่อยู่แผนที่ไป่ตู้',
    vi: 'Địa chỉ bản đồ Baidu',
  },
  notificationList: {
    ja: 'おしらせ',
    en: 'Notification',
    zhcn: '通知',
    zhtw: '通報',
    ko: '알림',
    th: 'การแจ้งเตือน',
    vi: 'Thông báo',
  },
  notificationRegister: {
    ja: 'お知らせ配信',
    en: 'Send Notification',
    zhcn: '寄送通知',
    zhtw: '寄送通知',
    ko: '알림 전송',
    th: 'ส่งการแจ้งเตือน',
    vi: 'Gửi thông báo',
  },
  notificationHistory: {
    ja: 'お知らせ履歴',
    en: 'Notification History',
    zhcn: '通知记录',
    zhtw: '通知記錄',
    ko: '알림 이력',
    th: 'ประวัติการแจ้งเตือน',
    vi: 'Lịch sử thông báo',
  },
  notification: {
    ja: 'お知らせ',
    en: 'Notification',
    zhcn: '寄送',
    zhtw: '寄送',
    ko: '알림',
    th: 'การแจ้งเตือน',
    vi: 'Thông báo',
  },
  distributionManagement: {
    ja: '流通管理',
    en: 'Distribution Management',
    zhcn: '分销管理',
    zhtw: '分銷管理',
    ko: '유통 관리',
    th: 'การจัดการการจัดจำหน่าย',
    vi: 'Quản lý phân phối',
  },
  distributionHistory: {
    ja: '流通履歴',
    en: 'Distribution Record',
    zhcn: '分销历史',
    zhtw: '分銷歷史',
    ko: '유통 이력',
    th: 'บันทึกการจัดจำหน่าย',
    vi: 'Bản ghi phân phối',
  },
  monitoring: {
    ja: 'モニタリング',
    en: 'Monitoring',
    zhcn: '监控',
    zhtw: '監控',
    ko: '모니터링',
    th: 'การตรวจสอบ',
    vi: 'Giám sát',
  },
  unhandledAlerts: {
    ja: 'アラート',
    en: 'Alerts',
    zhcn: '警报',
    zhtw: '警報',
    ko: '알림',
    th: 'การแจ้งเตือน',
    vi: 'Cảnh báo',
  },
  ordersAndShipments: {
    ja: '入出荷・注文',
    en: 'Shipping/Receiving or Order',
    zhcn: '输入出货和订单',
    zhtw: '輸入出貨和訂單',
    ko: '입 출하 · 주문',
    th: 'การจัดส่ง/การรับหรือการสั่งซื้อ',
    vi: 'Vận chuyển/Nhận hàng hoặc Đặt hàng',
  },
  register: {
    ja: '登録',
    en: 'Register',
    zhcn: '注册',
    zhtw: '註冊',
    ko: '등록',
    th: 'ลงทะเบียน',
    vi: 'Đăng ký',
  },
  scanOrRegister: {
    ja: '登録・スキャン',
    en: 'Register or Scan',
    zhcn: '注册和扫描',
    zhtw: '註冊和掃描',
    ko: '등록 · 스캔',
    th: 'ลงทะเบียนหรือสแกน',
    vi: 'Đăng ký hoặc quét',
  },
  chat: {
    ja: 'チャット',
    en: 'Chat',
    zhcn: '聊天',
    zhtw: '聊天',
    ko: '채팅',
    th: 'แชท',
    vi: 'Trò chuyện',
  },
  reportViaChat: {
    ja: 'チャットで報告する',
    en: 'Report via Chat',
    zhcn: '通过聊天举报',
    zhtw: '通過聊天舉報',
    ko: '채팅으로 보고',
    th: 'รายงานผ่านทางแชท',
    vi: 'Báo cáo qua Trò chuyện',
  },
  purchaseHistory: {
    ja: '注文履歴',
    en: 'Order History',
    zhcn: '订单历史',
    zhtw: '訂單歷史',
    ko: '주문 내역',
    th: 'ประวัติการสั่งซื้อ',
    vi: 'lịch sử đơn hàng',
  },
  conflictResolution: {
    ja: '異常対応',
    en: 'Alert Handling',
    zhcn: '异常处理',
    zhtw: '異常處理',
    ko: '경고 대응',
    th: 'การจัดการการแจ้งเตือน',
    vi: 'Xử lý cảnh báo',
  },
  taskManagement: {
    ja: 'タスク管理',
    en: 'Task Management',
    zhcn: '任务管理',
    zhtw: '任務管理',
    ko: '작업 관리',
    th: 'การจัดการงาน',
    vi: 'Quản lý tác vụ',
  },
  restaurantManagement: {
    ja: 'レストラン管理',
    en: 'Restaurant Management',
    zhcn: '餐厅管理',
    zhtw: '餐廳管理',
    ko: '레스토랑 관리',
    th: 'การจัดการร้านอาหาร',
    vi: 'Quản lý nhà hàng',
  },
  restaurantInspection: {
    ja: 'レストラン調査',
    en: 'Restaurant Inspection',
    zhcn: '餐厅调查',
    zhtw: '餐廳調查',
    ko: '레스토랑 조사',
    th: 'การตรวจสอบร้านอาหาร',
    vi: 'Kiểm tra nhà hàng',
  },
  addNewMasterInfo: {
    ja: '{data}を新規登録',
    en: 'Add New {data}',
    zhcn: '{data}报名',
    zhtw: '{data}報名',
    ko: '{data}신규등록',
    th: 'เพิ่มใหม่ {ข้อมูล}',
    vi: 'Thêm {dữ liệu} mới',
  },
  restaurantAdd: {
    ja: 'レストラン追加',
    en: 'Add New Restaurant',
    zhcn: '追加餐厅',
    zhtw: '追加餐廳',
    ko: '레스토랑 추가',
    th: 'เพิ่มร้านอาหารใหม่',
    vi: 'Thêm nhà hàng mới',
  },
  salesManagement: {
    ja: '販売管理',
    en: 'Sales Management',
    zhcn: '销售管理',
    zhtw: '銷售管理',
    ko: '판매 관리',
    th: 'การจัดการการขาย',
    vi: 'Quản lý bán hàng',
  },
  salesTally: {
    ja: '販売数',
    en: 'Sales Quantity',
    zhcn: '整体销售量',
    zhtw: '總銷售量',
    ko: '총 판매 수',
    th: 'ปริมาณการขาย',
    vi: 'Số lượng khuyến mãi',
  },
  sales: {
    ja: '販売数',
    en: 'Sales Quantity',
    zhcn: '整体销售量',
    zhtw: '總銷售量',
    ko: '총 판매 수',
    th: 'ปริมาณการขาย',
    vi: 'Số lượng khuyến mãi',
  },
  consumer: {
    ja: '消費者',
    en: 'Consumer',
    zhcn: '消费者',
    zhtw: '消費者',
    ko: '소비자',
    th: 'ผู้บริโภค',
    vi: 'Người tiêu dùng',
  },
  consumerTrends: {
    ja: '消費者傾向',
    en: 'Consumer Trend',
    zhcn: '消费風向',
    zhtw: '消費風向',
    ko: '소비자 동향',
    th: 'ผู้บริโภค',
    vi: 'Người tiêu dùng',
  },
  custom1: {
    ja: 'カスタム01',
    en: 'Custom 1',
    zhcn: '客戶01',
    zhtw: '客戶01',
    ko: '사용자 정의 01',
    th: 'กำหนดเอง 1',
    vi: 'tùy chỉnh 1',
  },
  customization: {
    ja: 'カスタマイズ',
    en: 'Customization',
    zhcn: '客定',
    zhtw: '客制',
    ko: '커스터마이즈',
    th: 'การปรับแต่ง',
    vi: 'Tùy chỉnh',
  },
  reports: {
    ja: 'レポート',
    en: 'Report',
    zhcn: '报告',
    zhtw: '報告',
    ko: '보고서',
    th: 'รายงาน',
    vi: 'Báo cáo',
  },
  reportCreate: {
    ja: 'レポート作成',
    en: 'Create Report',
    zhcn: '报告制成',
    zhtw: '報告製作',
    ko: '보고서 작성',
    th: 'สร้างรายงาน',
    vi: 'Tạo báo cáo',
  },
  masterInfoManagement: {
    ja: 'マスター情報の閲覧・管理',
    en: 'Master Information View or Management',
    zhcn: '查看和掌握信息管理',
    zhtw: '查看和掌握信息管理',
    ko: '마스터 정보의 열람 · 관리',
    th: 'มุมมองข้อมูลหลักหรือการจัดการ',
    vi: 'Xem hoặc quản lý thông tin chính',
  },
  masterInfo: {
    ja: 'マスター情報',
    en: 'Master Information',
    zhcn: '主信息',
    zhtw: '主信息',
    ko: '마스터 정보',
    th: 'ข้อมูลหลัก',
    vi: 'Thông tin chính',
  },
  brewery: {
    ja: '酒蔵',
    en: 'Brewery',
    zhcn: '酿酒厂',
    zhtw: '釀酒廠',
    ko: '양조장',
    th: 'โรงเบียร์',
    vi: 'Nhà máy bia',
  },
  distributor: {
    ja: '酒販店',
    en: 'Distributor',
    zhcn: '酒类专卖店',
    zhtw: '酒類專賣店',
    ko: '유통업자',
    th: 'ผู้จัดจำหน่าย',
    vi: 'Nhà phân phối',
  },
  restaurant: {
    ja: 'レストラン',
    en: 'Restaurant',
    zhcn: '餐厅',
    zhtw: '餐廳',
    ko: '레스토랑',
    th: 'ร้านอาหาร',
    vi: 'Nhà hàng',
  },
  vip: {
    ja: 'VIP顧客',
    en: 'VIP',
    zhcn: 'VIP客户',
    zhtw: 'VIP客戶',
    ko: 'VIP 고객',
    th: 'วีไอพี',
    vi: 'CAO CẤP',
  },
  systemSettings: {
    ja: 'システム管理',
    en: 'System Management',
    zhcn: '系统管理',
    zhtw: '系統管理',
    ko: '시스템 관리',
    th: 'การจัดการระบบ',
    vi: 'Quản lý hệ thống',
  },
  environmentSettings: {
    ja: '環境設定',
    en: 'Environment Setting',
    zhcn: '环境设置',
    zhtw: '環境設置',
    ko: '환경 설정',
    th: 'การตั้งค่าสภาพแวดล้อม',
    vi: 'Cài đặt môi trường',
  },
  settings: {
    ja: '設定',
    en: 'Settings',
    zhcn: '设置',
    zhtw: '設置',
    ko: '설정',
    th: 'การตั้งค่า',
    vi: 'Cài đặt',
  },
  perHour: {
    ja: '時間',
    en: 'hr',
    zhcn: '小时',
    zhtw: '小時',
    ko: '시간',
    th: 'ชม',
    vi: 'giờ',
  },
  perDay: {
    ja: '日',
    en: 'day',
    zhcn: '天',
    zhtw: '天',
    ko: '매일',
    th: 'วัน',
    vi: 'ngày',
  },
  systemEnvironmentSettings: {
    ja: 'システム環境設定',
    en: 'System Environment Setting',
    zhcn: '系统预置',
    zhtw: '系統預置',
    ko: '시스템 환경 설정',
    th: 'การตั้งค่าสภาพแวดล้อมของระบบ',
    vi: 'Cài đặt môi trường hệ thống',
  },
  inputRequest: {
    ja: 'を入力',
    en: 'Enter the',
    zhcn: '输入',
    zhtw: '輸入',
    ko: '를 입력',
    th: 'ป้อน',
    vi: 'Nhập',
  },
  inputSelect: {
    ja: 'を選択',
    en: 'select',
    zhcn: '选择',
    zhtw: '選擇',
    ko: '선택',
    th: 'เลือก',
    vi: 'lựa chọn',
  },
  inputHalfWidthNumeric: {
    ja: '（半角数字）',
    en: '(Numeric)',
    zhcn: '（单字节数）',
    zhtw: '（單字節數）',
    ko: '(반각 숫자)',
    th: '(ตัวเลข)',
    vi: '(Số)',
  },
  brewShipAt: {
    ja: '酒蔵出荷日',
    en: 'Brewery Shipping Date',
    zhcn: '装运日期啤酒厂',
    zhtw: '裝運日期啤酒廠',
    ko: '양조장 출하 일',
    th: 'วันที่จัดส่งโรงเบียร์',
    vi: 'Ngày vận chuyển nhà máy bia',
  },
  alertStatusType: {
    ja: '目的ステータス',
    en: 'Target Alert Status',
    zhcn: '目的警报状态',
    zhtw: '目標警報類型',
    ko: '목적 상태',
    th: 'สถานะการแจ้งเตือนเป้าหมาย',
    vi: 'Trạng thái cảnh báo mục tiêu',
  },
  alertType: {
    ja: 'アラート種別',
    en: 'Alert Type',
    zhcn: '警报类型',
    zhtw: '警報類型',
    ko: '알람 종류',
    th: 'ประเภทการแจ้งเตือน',
    vi: 'Loại cảnh báo',
  },
  dataCreateDate: {
    ja: 'データ作成日時',
    en: 'Data Creation Date',
    zhcn: '数据创建日期和时间',
    zhtw: '數據創建日期和時間',
    ko: '데이터 작성 된 시간',
    th: 'วันที่สร้างข้อมูล',
    vi: 'Ngày tạo dữ liệu',
  },
  dashboard: {
    ja: 'ダッシュボード',
    en: 'Dashboard',
    zhcn: '仪表板',
    zhtw: '儀表板',
    ko: '대시 보드',
    th: 'แผงควบคุม',
    vi: 'bảng điều khiển',
  },
  status: {
    ja: 'ステータス',
    en: 'Status',
    zhcn: '状态',
    zhtw: '狀態',
    ko: '상태',
    th: 'สถานะ',
    vi: 'Trạng thái',
  },
  searchConditions: {
    ja: '検索条件',
    en: 'Search filter',
    zhcn: '搜索条件',
    zhtw: '搜索條件',
    ko: '검색 조건',
    th: 'เงื่อนไขการค้นหา',
    vi: 'Bộ lọc tìm kiếm',
  },
  searchWithAboveConditions: {
    ja: 'この条件で検索',
    en: 'Search with this filter',
    zhcn: '在这种情况下搜索',
    zhtw: '在這種情況下搜索',
    ko: '이 조건으로 검색',
    th: 'ค้นหาด้วยเงื่อนไขนี้',
    vi: 'Tìm kiếm bằng bộ lọc này',
  },
  cancel: {
    ja: 'キャンセル',
    en: 'Cancel',
    zhcn: '取消',
    zhtw: '取消',
    ko: '취소',
    th: 'ยกเลิก',
    vi: 'Hủy bỏ',
  },
  return: {
    ja: '戻る',
    en: 'Back',
    zhcn: '回去',
    zhtw: '回去',
    ko: '돌아 가기',
    th: 'กลับ',
    vi: 'Mặt sau',
  },
  searchFilter: {
    ja: '検索・フィルタ',
    en: 'Search/Filter',
    zhcn: '搜索和过滤器',
    zhtw: '搜索和過濾器',
    ko: '검색 필터',
    th: 'ค้นหา/กรอง',
    vi: 'Tìm kiếm/Lọc',
  },
  search: {
    ja: '検索',
    en: 'Search',
    zhcn: '搜索',
    zhtw: '搜索',
    ko: '검색',
    th: 'ค้นหา',
    vi: 'Tìm kiếm',
  },
  name: {
    ja: '名前',
    en: 'Name',
    zhcn: '姓',
    zhtw: '姓',
    ko: '이름',
    th: 'ชื่อ',
    vi: 'Tên',
  },
  englishParenthesis: {
    ja: '（英語）',
    en: '(English)',
    zhcn: '（英语）',
    zhtw: '（英語）',
    ko: '(영문)',
    th: '(ภาษาอังกฤษ)',
    vi: '(Tiếng Anh)',
  },
  id: {
    ja: 'ID',
    en: 'ID',
    zhcn: 'ID',
    zhtw: 'ID',
    ko: 'ID',
    th: 'บัตรประจำตัวประชาชน',
    vi: 'NHẬN DẠNG',
  },
  flagShip: {
    ja: '旗艦',
    en: 'Flagship',
    zhcn: '旗舰',
    zhtw: '旗艦',
    ko: '주력',
    th: 'เรือธง',
    vi: 'Soái hạm',
  },
  sellingInfo: {
    ja: '販売情報',
    en: 'Sales Information',
    zhcn: '销售信息',
    zhtw: '銷售信息',
    ko: '판매 정보',
    th: 'ข้อมูลการขาย',
    vi: 'Thông tin bán hàng',
  },
  lastInspectionDate: {
    ja: '最終検査日',
    en: 'Last Inspection Date',
    zhcn: '上次检查日期',
    zhtw: '上次檢查日期',
    ko: '최종 검사일',
    th: 'วันที่ตรวจสอบครั้งสุดท้าย',
    vi: 'Ngày kiểm tra lần cuối',
  },
  link: {
    ja: 'リンク',
    en: 'Link',
    zhcn: '关联',
    zhtw: '關聯',
    ko: '링크',
    th: 'ลิงค์',
    vi: 'liên kết',
  },
  active: {
    ja: 'Active',
    en: 'Active',
    zhcn: '积极的',
    zhtw: '積極的',
    ko: 'Active',
    th: 'คล่องแคล่ว',
    vi: 'Tích cực',
  },
  deactivated: {
    ja: 'Deactivated',
    en: 'Deactivated',
    zhcn: '停用',
    zhtw: '停用',
    ko: 'Deactivated',
    th: 'ปิดการใช้งาน',
    vi: 'Đã tắt',
  },
  reviewing: {
    ja: 'Reviewing',
    en: 'Reviewing',
    zhcn: '回顾',
    zhtw: '回顧',
    ko: 'Reviewing',
    th: 'กำลังทบทวน',
    vi: 'Đang xem xét',
  },
  blacklisted: {
    ja: 'Blacklisted',
    en: 'Blacklisted',
    zhcn: '列入黑名单',
    zhtw: '列入黑名單',
    ko: 'Blacklisted',
    th: 'ขึ้นบัญชีดำ',
    vi: 'Danh sách đen',
  },
  open: {
    ja: '開く',
    en: 'Open',
    zhcn: '打开',
    zhtw: '打開',
    ko: '열기',
    th: 'เปิด',
    vi: 'Mở',
  },
  close: {
    ja: '閉める',
    en: 'Close',
    zhcn: '关闭',
    zhtw: '關閉',
    ko: '닫기',
    th: 'ปิด',
    vi: 'Đóng',
  },
  base: {
    ja: '拠点',
    en: 'Location',
    zhcn: '地点',
    zhtw: '地點數量',
    ko: '거점',
    th: 'ที่ตั้ง',
    vi: 'Vị trí',
  },
  users: {
    ja: 'ユーザ',
    en: 'User',
    zhcn: '用户',
    zhtw: '用戶',
    ko: '사용자',
    th: 'ผู้ใช้',
    vi: 'Người dùng',
  },
  inventory: {
    ja: '在庫数',
    en: 'Inventory Quantity',
    zhcn: '庫存数',
    zhtw: '庫存數',
    ko: '재고 수량',
    th: 'ปริมาณสินค้าคงคลัง',
    vi: 'Số lượng hàng tồn kho',
  },
  lastDeliveryDate: {
    ja: '最終配達日',
    en: 'Last Shipping Date',
    zhcn: '最后交货日期',
    zhtw: '最後交貨日期',
    ko: '최종 납품 일',
    th: 'วันที่จัดส่งครั้งล่าสุด',
    vi: 'Ngày vận chuyển cuối cùng',
  },
  menuPrice: {
    ja: 'メニュー価格',
    en: 'Menu Price',
    zhcn: '酒单价格',
    zhtw: '酒單價格',
    ko: '메뉴 가격',
    th: 'เมนู ราคา',
    vi: 'Thực đơn Giá',
  },
  mainMenu: {
    ja: 'メインメニュー',
    en: 'Main Menu',
    zhcn: '主菜单',
    zhtw: '主菜單',
    ko: '메인 메뉴',
    th: 'เมนูหลัก',
    vi: 'Thực đơn chính',
  },
  temp: {
    ja: '温度 [℃]',
    en: 'Temperature [℃]',
    zhcn: '温度[℃]',
    zhtw: '溫度[℃]',
    ko: '온도 [℃]',
    th: 'อุณหภูมิ [℃]',
    vi: 'Nhiệt độ [oC]',
  },
  shippedFromBrewery: {
    ja: '酒蔵出荷済み',
    en: 'Shipped from Brewery',
    zhcn: '啤酒已出貨',
    zhtw: '啤酒已出貨',
    ko: '양조장에서 출하 완료',
    th: 'ส่งมาจากโรงเบียร์',
    vi: 'Vận chuyển từ Nhà máy bia',
  },
  distributorInstock: {
    ja: '酒販店入荷済み',
    en: 'Received at Distributor',
    zhcn: '酒类专卖店已收货',
    zhtw: '酒類專賣店已收貨',
    ko: '유통업자에 입고 완료',
    th: 'รับได้ที่ตัวแทนจำหน่าย',
    vi: 'Nhận tại Nhà phân phối',
  },
  distributorDispatched: {
    ja: '酒販店出荷済み',
    en: 'Shipped from Distributor',
    zhcn: '酒类专卖店发货',
    zhtw: '酒類專賣店發貨',
    ko: '유통업자에서 출하 완료',
    th: 'จัดส่งจากตัวแทนจำหน่าย',
    vi: 'Vận chuyển từ nhà phân phối',
  },
  restaurantInstock: {
    ja: 'レストラン入荷済み',
    en: 'Received at Restaurant',
    zhcn: '餐厅已收货',
    zhtw: '餐廳已收貨',
    ko: '레스토랑에 입하 완료',
    th: 'รับได้ที่ร้านอาหาร',
    vi: 'Nhận tại nhà hàng',
  },
  purchasedByConsumer: {
    ja: '消費済み',
    en: 'Consumed',
    zhcn: '已选购',
    zhtw: '已選購',
    ko: '소비 된',
    th: 'บริโภค',
    vi: 'tiêu thụ',
  },
  productShipment: {
    ja: '商品出荷',
    en: 'Product Shipping',
    zhcn: '产品出货',
    zhtw: '產品出貨',
    ko: '상품출하',
    th: 'การจัดส่งสินค้า',
    vi: 'Vận chuyển sản phẩm',
  },
  stockManagement: {
    ja: 'ボトル管理',
    en: 'Bottle Management',
    zhcn: '商品管理',
    zhtw: '商品管理',
    ko: '상품 관리',
    th: 'การจัดการขวด',
    vi: 'Quản lý chai',
  },
  productShipmentOrReceive: {
    ja: '商品の配送/入荷/販売',
    en: 'Delivering/Receiving /Selling Stock',
    zhcn: '商品配送/收货/销售',
    zhtw: '商品配送/收貨/銷售',
    ko: '상품 배송/입하/판매',
    th: 'ส่งมอบ/รับ/ขายสต๊อก',
    vi: 'Giao/Nhận/Bán hàng',
  },
  scanDateAlert: {
    ja: '商品の入荷スキャンが実施されていません',
    en: 'Product receiving scan has not been completed',
    zhcn: '产品库存扫描尚未完成',
    zhtw: '產品庫存掃描尚未完成',
    ko: '상품의 입하 스캔이 완료되지 않았습니다',
    th: 'การสแกนการรับสินค้ายังไม่เสร็จสิ้น',
    vi: 'Quá trình quét nhận sản phẩm chưa hoàn tất',
  },
  scanQuantityAlert: {
    ja: '商品出荷数と入荷数が一致しません',
    en: 'Product shipping and receiving quantity do not match',
    zhcn: '产品出货入货数量不符',
    zhtw: '產品出貨入貨數量不符',
    ko: '제품 출하량과 입하 수가 일치하지 않습니다',
    th: 'ปริมาณการจัดส่งและการรับสินค้าไม่ตรงกัน',
    vi: 'Số lượng sản phẩm vận chuyển và nhận hàng không khớp nhau',
  },
  product: {
    ja: '商品',
    en: 'Product',
    zhcn: '产品',
    zhtw: '產品',
    ko: '상품',
    th: 'ผลิตภัณฑ์',
    vi: 'Sản phẩm',
  },
  qualityAlert: {
    ja: '商品の入荷後{day}日以上販売がされていません',
    en: 'Product not sold for more than {day} days after receiving',
    zhcn: '货物到达超过{day}日后不得出售',
    zhtw: '貨物到達超過{day}日後不得/出售',
    ko: '상품이 입하 후 {day}일 이상/판매가되지 않습니다',
    th: 'สินค้าไม่ได้จำหน่ายนานกว่า {day} วันหลังจากได้รับ',
    vi: 'Sản phẩm không được bán trong hơn {day} ngày sau khi nhận được',
  },
  routeAlert: {
    ja: '商品出荷先と入荷先が一致しません',
    en: 'Product shipping destination and receiving location do not match',
    zhcn: '商品发货目的地和到达目的地的不匹配',
    zhtw: '商品發貨目的地和到達目的地的不合',
    ko: '상품 출하 대상 입하 정보가 일치하지 않습니다',
    th: 'ปลายทางการส่งสินค้าและสถานที่รับสินค้าไม่ตรงกัน',
    vi: 'Điểm đến vận chuyển sản phẩm và địa điểm nhận hàng không khớp',
  },
  consumerLocationAlert: {
    ja: '消費者のスキャン位置情報が登録済のものと一致しません',
    en: 'Consumer scan was not conducted at the registered location',
    zhcn: '消费者的扫描地奌与注册信息不一致',
    zhtw: '消費者的掃描地點與註冊地不合',
    ko: '소비자의 스캔 위치 정보가 등록 된 것과 일치하지 않습니다',
    th: 'ไม่ได้ดำเนินการสแกนผู้บริโภค ณ สถานที่ที่ลงทะเบียน',
    vi: 'Việc quét người tiêu dùng không được tiến hành tại địa điểm đã đăng ký',
  },
  illegalRouteAlert: {
    ja: '商品入荷時のスキャン位置情報が登録済のものと一致しません',
    en: 'Product receiving scan was not conducted at the registered location',
    zhcn: '收货扫描地址与登录地址不同',
    zhtw: '收貨掃描地址與登錄地址不同',
    ko: '상품 입고시 스캔 위치 정보가 등록 된 것과 일치하지 않습니다',
    th: 'ไม่ได้ดำเนินการสแกนการรับผลิตภัณฑ์ ณ ตำแหน่งที่ลงทะเบียน',
    vi: 'Việc nhận sản phẩm không được thực hiện quét tại địa điểm đã đăng ký',
  },
  onlyPcSupportedDevicePage: {
    ja: 'このページはPCブラウザでのみ表示できます',
    en: 'This page is only viewable on PC browsers',
    zhcn: '此页面仅在PC浏览器上可见',
    zhtw: '此頁面僅在PC瀏覽器上可見',
    ko: '이 페이지는 PC 브라우저에서만 볼 수 있습니다.',
    th: 'หน้านี้สามารถดูได้บนเบราว์เซอร์พีซีเท่านั้น',
    vi: 'Trang này chỉ có thể xem được trên trình duyệt PC',
  },
  select: {
    ja: '選択',
    en: 'Select',
    zhcn: '选择',
    zhtw: '選擇',
    ko: '선택하다',
    th: 'เลือก',
    vi: 'Lựa chọn',
  },
  japaneseSushi: {
    ja: '和食（寿司）',
    en: 'Japanese (Sushi)',
    zhcn: '日本（寿司）',
    zhtw: '日本（壽司）',
    ko: '일본 (초밥)',
    th: 'ญี่ปุ่น (ซูชิ)',
    vi: 'Nhật Bản (Sushi)',
  },
  japaneseTempura: {
    ja: '和食（天ぷら）',
    en: 'Japanese (Tempura)',
    zhcn: '日本（天妇罗）',
    zhtw: '日本（天婦羅）',
    ko: '일본 (튀김)',
    th: 'ญี่ปุ่น (เทมปุระ)',
    vi: 'Tiếng Nhật (Tempura)',
  },
  japaneseKaiseki: {
    ja: '和食（懐石）',
    en: 'Japanese (Kaiseki)',
    zhcn: '日本（怀石）',
    zhtw: '日本（懷石）',
    ko: '일본 (회석)',
    th: 'ญี่ปุ่น (ไคเซกิ)',
    vi: 'Tiếng Nhật (Kaiseki)',
  },
  japaneseKappoRyori: {
    ja: '和食（割烹）',
    en: 'Japanese (Kappo-ryori)',
    zhcn: '日本（割烹料理）',
    zhtw: '日本（割烹料理）',
    ko: '일본 (Kappoririri)',
    th: 'ญี่ปุ่น (คัปโปเรียวริ)',
    vi: 'Tiếng Nhật (Kappo-ryori)',
  },
  japaneseTeppanyaki: {
    ja: '和食（鉄板焼き）',
    en: 'Japanese (Teppanyaki)',
    zhcn: '日本（铁板烧）',
    zhtw: '日本（鐵板燒）',
    ko: '일본 (철판 구이)',
    th: 'ญี่ปุ่น (เทปันยากิ)',
    vi: 'Nhật Bản (Teppanyaki)',
  },
  japaneseYakitoriKushiyakiChicken: {
    ja: '和食（焼き鳥/串焼き/鶏肉）',
    en: 'Japanese (Yakitori/Kushiyaki/Chicken）',
    zhcn: '日本（烤鸡/吐/鸡）',
    zhtw: '日本（烤雞/吐/雞）',
    ko: '일본 (닭 꼬치 / 꼬치 구이 / 닭)',
    th: 'อาหารญี่ปุ่น (ยากิโทริ/คุชิยากิ/ไก่)',
    vi: 'Món Nhật (Yakitori/Kushiyaki/Gà)',
  },
  japaneseIzakayaSakeBar: {
    ja: '和食（居酒屋/酒バー）',
    en: 'Japanese (Izakaya/Sake Bar)',
    zhcn: '日本（酒馆/酒吧）',
    zhtw: '日本（酒館/酒吧）',
    ko: '일본 (술집 / 술 바)',
    th: 'อาหารญี่ปุ่น (อิซากายะ/บาร์สาเก)',
    vi: 'Tiếng Nhật (Izakaya/Sake Bar)',
  },
  japaneseModern: {
    ja: '和食（モダン）',
    en: 'Japanese (Modern)',
    zhcn: '日本（现代）',
    zhtw: '日本（現代）',
    ko: '일본 (현대)',
    th: 'ญี่ปุ่น (สมัยใหม่)',
    vi: 'Tiếng Nhật (Hiện đại)',
  },
  steakYakinikuBBQ: {
    ja: 'ステーキ/焼肉/ BBQ',
    en: 'Steak/Yakiniku/BBQ',
    zhcn: '牛排/烤肉/烧烤',
    zhtw: '牛排/烤肉/燒烤',
    ko: '스테이크 / 불고기 / BBQ',
    th: 'สเต็ก/ยากินิกุ/บาร์บีคิว',
    vi: 'Bít tết/Yakiniku/BBQ',
  },
  chineseCantonese: {
    ja: '中国（広東）',
    en: 'Chinese (Cantonese)',
    zhcn: '中国（广东）',
    zhtw: '中國（廣東）',
    ko: '중국어 (광동)',
    th: 'จีน (กวางตุ้ง)',
    vi: 'Tiếng Trung (Quảng Đông)',
  },
  chineseSichuan: {
    ja: '中国（四川）',
    en: 'Chinese (Sichuanese)',
    zhcn: '中国（四川）',
    zhtw: '中國（四川）',
    ko: '중국어 (쓰촨성)',
    th: 'จีน (เสฉวน)',
    vi: 'Tiếng Trung (Tứ Xuyên)',
  },
  chineseShanghainese: {
    ja: '中国（上海）',
    en: 'Chinese (Shanghainese)',
    zhcn: '中国（上海）',
    zhtw: '中國（上海）',
    ko: '중국 (상해)',
    th: 'จีน (เซี่ยงไฮ้)',
    vi: 'Tiếng Trung (Thượng Hải)',
  },
  chineseTaiwanese: {
    ja: '中国（台湾）',
    en: 'Chinese (Taiwanese)',
    zhcn: '中国（台湾）',
    zhtw: '中國（台灣）',
    ko: '중국어 (대만)',
    th: 'จีน (ไต้หวัน)',
    vi: 'Tiếng Trung (Đài Loan)',
  },
  chineseZhejiang: {
    ja: '中国（浙江）',
    en: 'Chinese (Zhejiang)',
    zhcn: '中国（浙江）',
    zhtw: '中國（浙江）',
    ko: '중국어 (절강)',
    th: 'จีน (เจ้อเจียง)',
    vi: 'Tiếng Trung (Chiết Giang)',
  },
  european: {
    ja: 'ヨーロッパ',
    en: 'European',
    zhcn: '欧洲',
    zhtw: '歐洲',
    ko: '유럽',
    th: 'ยุโรป',
    vi: 'Châu Âu',
  },
  french: {
    ja: 'フランス',
    en: 'French',
    zhcn: '法国',
    zhtw: '法國',
    ko: '프랑스',
    th: 'ภาษาฝรั่งเศส',
    vi: 'người Pháp',
  },
  italian: {
    ja: 'イタリア',
    en: 'Italian',
    zhcn: '意大利',
    zhtw: '意大利',
    ko: '이탈리아',
    th: 'ภาษาอิตาลี',
    vi: 'người Ý',
  },
  fusion: {
    ja: 'フュージョン',
    en: 'Fusion',
    zhcn: '聚变',
    zhtw: '聚變',
    ko: '퓨전',
    th: 'ฟิวชั่น',
    vi: 'Dung hợp',
  },
  korean: {
    ja: '韓国',
    en: 'Korean',
    zhcn: '韩国',
    zhtw: '韓國',
    ko: '한국',
    th: 'เกาหลี',
    vi: 'Hàn Quốc',
  },
  southEastAsian: {
    ja: '東南アジア',
    en: 'South-East Asian',
    zhcn: '东南亚',
    zhtw: '東南亞',
    ko: '동남아',
    th: 'เอเชียตะวันออกเฉียงใต้',
    vi: 'Đông Nam Á',
  },
  others: {
    ja: 'その他',
    en: 'Other',
    zhcn: '其他',
    zhtw: '其他',
    ko: '기타',
    th: 'อื่น',
    vi: 'Khác',
  },
  under10: {
    ja: '10,000以下',
    en: 'under 10,000',
    zhcn: '1万以下',
    zhtw: '1萬以下',
    ko: '10,000 이하',
    th: 'ต่ำกว่า 10,000',
    vi: 'dưới 10.000',
  },
  under10below30: {
    ja: '10,000 〜 30,000',
    en: '10,000 - 30,000',
    zhcn: '10,000-30,000',
    zhtw: '10,000-30,000',
    ko: '10,000 ~ 30,000',
    th: '10,000 - 30,000',
    vi: '10.000 - 30.000',
  },
  under30below50: {
    ja: '30,000 〜 50,000',
    en: '30,000 - 50,000',
    zhcn: '30,000-50,000',
    zhtw: '30,000-50,000',
    ko: '30,000 ~ 50,000',
    th: '30,000 - 50,000',
    vi: '30,000 - 50,000',
  },
  over50: {
    ja: '50,000以上',
    en: '50,000+',
    zhcn: '超过50,000',
    zhtw: '超過50,000',
    ko: '50,000 이상',
    th: '50,000+',
    vi: '50,000+',
  },
  cuisineType: {
    ja: '料理ジャンル',
    en: 'Cuisine Type',
    zhcn: '菜式',
    zhtw: '菜式',
    ko: '요리 종류',
    th: 'ประเภทอาหาร',
    vi: 'Loại món ăn',
  },
  priceType: {
    ja: '客単価（食事+ドリンク込）',
    en: 'Price (Per Person) Food+Drink',
    zhcn: '客单价（含餐+酒）',
    zhtw: '客单价（含餐+酒）',
    ko: '객 단가 (식사 + 음료 포함)',
    th: 'ราคา (ต่อท่าน) อาหาร+เครื่องดื่ม',
    vi: 'Giá (Mỗi người) Đồ ăn+Đồ uống',
  },
  awardsComments: {
    ja: '受賞歴・コメント',
    en: 'Awards / Comments',
    zhcn: '获奖・评论',
    zhtw: '獲獎・評論',
    ko: '수상 경력 · 코멘트',
    th: 'รางวัล/ความคิดเห็น',
    vi: 'Giải thưởng / Bình luận',
  },
  website: {
    ja: 'ウェブサイトURL',
    en: 'Website URL',
    zhcn: '网站URL',
    zhtw: '網站URL',
    ko: '웹사이트URL',
    th: 'URL ของเว็บไซต์',
    vi: 'URL trang web',
  },
  image: {
    ja: '画像',
    en: 'Image',
    zhcn: '图像',
    zhtw: '圖像',
    ko: '이미지',
    th: 'ภาพ',
    vi: 'Hình ảnh',
  },
  refrigerator: {
    ja: '冷蔵庫',
    en: 'Refrigerator',
    zhcn: '冰箱',
    zhtw: '冰箱',
    ko: '냉장고',
    th: 'ตู้เย็น',
    vi: 'Tủ lạnh',
  },
  tempPanel: {
    ja: '温度管理パネル',
    en: 'Temperature Panel',
    zhcn: '温度控制面板',
    zhtw: '溫度控制面板',
    ko: '온도 관리 패널',
    th: 'แผงอุณหภูมิ',
    vi: 'Bảng nhiệt độ',
  },
  restaurantExterior: {
    ja: '外装',
    en: 'Restaurant Exterior',
    zhcn: '外观',
    zhtw: '外觀',
    ko: '레스토랑 외관',
    th: 'ภายนอกร้านอาหาร',
    vi: 'Ngoại thất nhà hàng',
  },
  restaurantInterior: {
    ja: '内装',
    en: 'Restaurant Interior',
    zhcn: '内装',
    zhtw: '內裝',
    ko: '레스토랑 인테리어',
    th: 'ภายในร้านอาหาร',
    vi: 'Nội thất nhà hàng',
  },
  glassware: {
    ja: '食器類',
    en: 'Glassware',
    zhcn: '餐具类',
    zhtw: '餐具類',
    ko: '식기',
    th: 'เครื่องแก้ว',
    vi: 'Đồ thủy tinh',
  },
  unknown: {
    ja: '不明',
    en: 'Unknown',
    zhcn: '不明',
    zhtw: '不明',
    ko: '알수 없는 상태',
    th: 'ไม่ทราบ',
    vi: 'không xác định',
  },
  unspecified: {
    ja: '未指定',
    en: 'Unspecified',
    zhcn: '未指定',
    zhtw: '未指定',
    ko: '미지정',
    th: 'ไม่ระบุ',
    vi: 'Không xác định',
  },
  unprocessed: {
    ja: '未対応',
    en: 'Urgent',
    zhcn: '受理中',
    zhtw: '受理中',
    ko: '미대응',
    th: 'ด่วน',
    vi: 'Cấp bách',
  },
  unprocessedAlert: {
    ja: '未対応アラート',
    en: 'Urgent Alert',
    zhcn: '受理中警报',
    zhtw: '受理中警報',
    ko: '미대응 알림',
    th: 'การแจ้งเตือนด่วน',
    vi: 'Cảnh báo khẩn cấp',
  },
  unvalidated: {
    ja: '未承認',
    en: 'Unapproved',
    zhcn: '未受理',
    zhtw: '未處理',
    ko: '미인증',
    th: 'ไม่ได้รับการอนุมัติ',
    vi: 'Không được chấp thuận',
  },
  unprocessable: {
    ja: '未解決',
    en: 'Unresolved',
    zhcn: '未解决',
    zhtw: '未解决',
    ko: '미해결',
    th: 'ยังไม่ได้รับการแก้ไข',
    vi: 'Chưa được giải quyết',
  },
  processedBy: {
    ja: '対応者',
    en: 'Updated by',
    zhcn: '处理人员',
    zhtw: '處理人員',
    ko: '대응자',
    th: 'ปรับปรุงโดย',
    vi: 'Cập nhật',
  },
  process2: {
    ja: '対応',
    en: 'Process',
    zhcn: '应对',
    zhtw: '應對',
    ko: '대응',
    th: 'กระบวนการ',
    vi: 'Quá trình',
  },
  revert: {
    ja: '戻す',
    en: 'Revert',
    zhcn: '回滚',
    zhtw: '回滾',
    ko: '롤백',
    th: 'เปลี่ยนกลับ',
    vi: 'Hoàn nguyên',
  },
  validating: {
    ja: '承認中',
    en: 'Approving',
    zhcn: '受理中',
    zhtw: '處理中',
    ko: '인증중',
    th: 'อนุมัติ',
    vi: 'Phê duyệt',
  },
  telephoneNumber: {
    ja: '電話番号',
    en: 'Phone Number',
    zhcn: '电话号码',
    zhtw: '電話號碼',
    ko: '전화번호',
    th: 'หมายเลขโทรศัพท์',
    vi: 'Số điện thoại',
  },
  chairCount: {
    ja: '席数',
    en: 'Seating Capacity',
    zhcn: '座位数量',
    zhtw: '座位數量',
    ko: '좌석 수',
    th: 'ความจุของที่นั่ง',
    vi: 'Số chỗ ngồi',
  },
  drinkMenuSample: {
    ja: 'ドリンクメニュー例',
    en: 'Drink Menu Sample',
    zhcn: '饮料菜单样本',
    zhtw: '飲料菜單樣本',
    ko: '음료 메뉴 샘플',
    th: 'ตัวอย่างเมนูเครื่องดื่ม',
    vi: 'Thực đơn đồ uống mẫu',
  },
  solved: {
    ja: '解決済',
    en: 'Resolved',
    zhcn: '已解决',
    zhtw: '已解決',
    ko: '해결된',
    th: 'แก้ไขแล้ว',
    vi: 'Đã giải quyết',
  },
  processing: {
    ja: '対応中',
    en: 'Processing',
    zhcn: '处理中',
    zhtw: '處理中',
    ko: '대응중',
    th: 'กำลังประมวลผล',
    vi: 'Xử lý',
  },
  process: {
    ja: '対応',
    en: 'Process',
    zhcn: '处理',
    zhtw: '處理',
    ko: '대응',
    th: 'แปรรูป',
    vi: 'Quá trình',
  },
  processed: {
    ja: '解決済',
    en: 'Resolved',
    zhcn: '已解决',
    zhtw: '已解決',
    ko: '해결된',
    th: 'แก้ไขแล้ว',
    vi: 'Đã giải quyết',
  },
  approved: {
    ja: '承認済',
    en: 'Approved',
    zhcn: '允許',
    zhtw: '核准',
    ko: '승인된',
    th: 'ที่ได้รับการอนุมัติ',
    vi: 'Tán thành',
  },
  uploadImages: {
    ja: '画像をアップロード',
    en: 'Upload image',
    zhcn: '上传图片',
    zhtw: '上傳圖片',
    ko: '사진을 업로드',
    th: 'ที่ได้รับการอนุมัติ',
    vi: 'Tán thành',
  },
  date: {
    ja: '日付',
    en: 'Date',
    zhcn: '日期',
    zhtw: '日期',
    ko: '날짜',
    th: 'วันที่',
    vi: 'Ngày',
  },
  sortNewer: {
    ja: '{field}（新しい順）',
    en: 'Sort by {field} (newest to oldest)',
    zhcn: '以新排序',
    zhtw: '以新排序',
    ko: '{field} 새로운 순서',
    th: 'จัดเรียงตาม {field} (จากใหม่ไปเก่าที่สุด)',
    vi: 'Sắp xếp theo {field} (mới nhất đến cũ nhất)',
  },
  sortOlder: {
    ja: '{field}（古い順）',
    en: 'Sort by {field} (oldest to newest)',
    zhcn: '以旧排序',
    zhtw: '以舊排序',
    ko: '{field} 오래된 순',
    th: 'จัดเรียงตาม {field} (เก่าที่สุดไปใหม่ที่สุด)',
    vi: 'Sắp xếp theo {field} (cũ nhất đến mới nhất)',
  },
  sortAscending: {
    ja: '{field}（昇順）',
    en: 'Sort by {field} (ascending order)',
    zhcn: '{field}顺序（升序）',
    zhtw: '{field}順序（升序）',
    ko: '{field} 순 (상승 할)',
    th: 'จัดเรียงตาม {field} (เรียงลำดับจากน้อยไปหามาก)',
    vi: 'Sắp xếp theo {field} (thứ tự tăng dần)',
  },
  sortDescending: {
    ja: '{field}（降順）',
    en: 'Sort by {field} (descending order)',
    zhcn: '{field}顺序（降序）',
    zhtw: '{field}順序（降序）',
    ko: '{field} 순 (강 것이다)',
    th: 'จัดเรียงตาม {field} (เรียงลำดับจากมากไปน้อย)',
    vi: 'Sắp xếp theo {field} (thứ tự giảm dần)',
  },
  sortByStatus: {
    ja: 'アラートステータスで並べ替え',
    en: 'Sort by alert status',
    zhcn: '顺序（降序',
    zhtw: '顺序（降序）',
    ko: '경고 상태별로 정렬',
    th: 'จัดเรียงตามสถานะการแจ้งเตือน',
    vi: 'Sắp xếp theo trạng thái cảnh báo',
  },
  convertTo: {
    ja: '{field}にする',
    en: 'Change Status to {field}',
    zhcn: '以{field}改成',
    zhtw: '以{field}改成',
    ko: '{field}하기',
    th: 'เปลี่ยนสถานะเป็น {field}',
    vi: 'Thay đổi trạng thái thành {field}',
  },
  convertTo2: {
    ja: '{field}する',
    en: '{field}',
    zhcn: '改成{field}',
    zhtw: '改成{field}',
    ko: '{field}하기',
    th: '{สนาม}',
    vi: '{cánh đồng}',
  },
  inputComment: {
    ja: 'コメントを入力',
    en: 'Input comment ',
    zhcn: '评论输入',
    zhtw: '評論輸入',
    ko: '댓글 입력',
    th: 'ป้อนความคิดเห็น',
    vi: 'Nhập bình luận',
  },
  alertResolutionTitle: {
    ja: 'アラートは{field}',
    en: 'Alert is {field}',
    zhcn: '快讯{field}',
    zhtw: '警告{field}',
    ko: '경고는 {field}',
    th: 'การแจ้งเตือนคือ {field}',
    vi: 'Cảnh báo là {field}',
  },
  updateHistory: {
    ja: '変更履歴',
    en: 'Update History',
    zhcn: '更改记录',
    zhtw: '更改記錄',
    ko: '변경 내역',
    th: 'การแจ้งเตือนคือ {field}',
    vi: 'Cảnh báo là {field}',
  },
  updateUser: {
    ja: '変更者',
    en: 'Updated by',
    zhcn: '更改者',
    zhtw: '更改者',
    ko: '수정 자',
    th: 'ปรับปรุงโดย',
    vi: 'Cập nhật',
  },
  editDistributionData: {
    ja: '流通データを修正',
    en: 'Modify distribution data',
    zhcn: '修改分布数据',
    zhtw: '修改分佈數據',
    ko: '유통 데이터를 수정',
    th: 'แก้ไขข้อมูลการกระจาย',
    vi: 'Sửa đổi dữ liệu phân phối',
  },
  editShippingDestination: {
    ja: '出荷先を変更する',
    en: 'Change shipping destination',
    zhcn: '要更改发货目的地',
    zhtw: '要更改發貨目的地',
    ko: '출하지 변경',
    th: 'เปลี่ยนปลายทางการจัดส่ง',
    vi: 'Thay đổi điểm đến vận chuyển',
  },
  cancelDelivery: {
    ja: '出荷をキャンセルする',
    en: 'Cancel delivery',
    zhcn: '取消发货',
    zhtw: '取消發貨',
    ko: '배송 취소',
    th: 'ยกเลิกการจัดส่ง',
    vi: 'Hủy giao hàng',
  },
  editShippingDestinationSlipNumber: {
    ja: '伝票番号を入力する',
    en: 'Input New Slip Number',
    zhcn: '输入新的发货单号',
    zhtw: '輸入新的發貨單號',
    ko: '문서 번호 입력',
    th: 'กรอกหมายเลขสลิปใหม่',
    vi: 'Nhập số phiếu mới',
  },
  selectShippingDestination: {
    ja: '出荷先を選択',
    en: 'Select shipping destination',
    zhcn: '选择货运目的地',
    zhtw: '選擇貨運目的地',
    ko: '도착지를 선택',
    th: 'เลือกปลายทางการจัดส่ง',
    vi: 'Chọn điểm đến vận chuyển',
  },
  runUpdate: {
    ja: '変更する',
    en: 'Update',
    zhcn: '改变',
    zhtw: '改變',
    ko: '변경',
    th: 'อัปเดต',
    vi: 'Cập nhật',
  },
  scanProduct: {
    ja: '商品をスキャンする',
    en: 'Scan Product',
    zhcn: '商品扫码',
    zhtw: '商品掃描',
    ko: '상품을 스캔',
    th: 'สแกนผลิตภัณฑ์',
    vi: 'Quét sản phẩm',
  },
  notifyNotDelivered: {
    ja: '未配達を通知する',
    en: 'Notify “Not delivered yet”',
    zhcn: '通知未送达',
    zhtw: '通知未送達',
    ko: '배달되지 않음 알림',
    th: 'แจ้งว่า “ยังไม่ได้จัดส่ง”',
    vi: 'Thông báo “Chưa giao”',
  },
  registerAsSold: {
    ja: '販売済として登録',
    en: 'Register as sold',
    zhcn: '注册为已售',
    zhtw: '註冊為已售',
    ko: '판매 등록',
    th: 'ลงทะเบียนว่าขายแล้ว',
    vi: 'Đăng ký như đã bán',
  },
  registerAsInStock: {
    ja: '在庫として登録',
    en: 'Register as in-stock',
    zhcn: '注册为在库',
    zhtw: '註冊為在庫',
    ko: '재고로 등록',
    th: 'ลงทะเบียนเป็นสินค้าในสต็อก',
    vi: 'Đăng ký hàng có sẵn',
  },
  ok: {
    ja: 'OK',
    en: 'OK',
    zhcn: 'OK',
    zhtw: 'OK',
    ko: 'OK',
    th: 'ตกลง',
    vi: 'ĐƯỢC RỒI',
  },
  approvedBy: {
    ja: '承認者',
    en: 'Approved by',
    zhcn: '授权',
    zhtw: '授權',
    ko: '승인자',
    th: 'อนุญาตโดย',
    vi: 'Được chấp nhận bởi',
  },
  conductor: {
    ja: '対応者',
    en: 'Handler',
    zhcn: '处理人员',
    zhtw: '處理人員',
    ko: '대응자',
    th: 'ตัวจัดการ',
    vi: 'Người xử lý',
  },
  dayAndTime: {
    ja: '日時',
    en: 'Time',
    zhcn: '日期和时间',
    zhtw: '日期和時間',
    ko: '일시',
    th: 'เวลา',
    vi: 'Thời gian',
  },
  badgeNew: {
    ja: 'NEW',
    en: 'NEW',
    zhcn: '新的',
    zhtw: '新的',
    ko: 'NEW',
    th: 'ใหม่',
    vi: 'MỚI',
  },
  confirm: {
    ja: '確認',
    en: 'Confirm',
    zhcn: '确认',
    zhtw: '確認',
    ko: '확인',
    th: 'ยืนยัน',
    vi: 'Xác nhận',
  },
  sbcSupportDesk: {
    ja: 'SBCサポートデスク',
    en: 'SBC Support Desk',
    zhcn: 'SBC支持台',
    zhtw: 'SBC支持台',
    ko: 'SBC 지원 데스크',
    th: 'โต๊ะสนับสนุน SBC',
    vi: 'Bàn hỗ trợ SBC',
  },
  inputTitle: {
    ja: 'タイトルを入力',
    en: 'Input title',
    zhcn: '输入标题',
    zhtw: '輸入標題',
    ko: '제목을 입력',
    th: 'ชื่ออินพุต',
    vi: 'Tiêu đề đầu vào',
  },
  inputBody: {
    ja: '本文を入力',
    en: 'Input body',
    zhcn: '输入文字',
    zhtw: '輸入文字',
    ko: '본문을 입력',
    th: 'ร่วมกันกระทำ',
    vi: 'Nội dung đầu vào',
  },
  runSend: {
    ja: '配信する',
    en: 'Send',
    zhcn: '发信',
    zhtw: '發信',
    ko: '배달',
    th: 'อินพุตเนื้อหา',
    vi: 'Nội dung đầu vào',
  },
  sendWithTheFollowingContents: {
    ja: 'この内容で配信する',
    en: 'Send the following content',
    zhcn: '內容发信',
    zhtw: '內容發信',
    ko: '이 내용을 전달하는',
    th: 'ส่ง',
    vi: 'Gửi',
  },
  processingHistory: {
    ja: 'コメント',
    en: 'Comment',
    zhcn: 'Comment',
    zhtw: 'Comment',
    ko: 'Comment',
    th: 'ความคิดเห็น',
    vi: 'Bình luận',
  },
  alertLocation: {
    ja: 'アラートが発生した拠点',
    en: 'Alert Location',
    zhcn: '发生警报的位置',
    zhtw: '發生警報的位置',
    ko: '경고가 발생한 거점',
    th: 'ตำแหน่งการแจ้งเตือน',
    vi: 'Vị trí cảnh báo',
  },
  area: {
    ja: 'エリア',
    en: 'Area',
    zhcn: '区域',
    zhtw: '區域',
    ko: '지역',
    th: 'พื้นที่',
    vi: 'Khu vực',
  },
  alertContents: {
    ja: 'アラート内容',
    en: 'Alert Description',
    zhcn: '警报内容',
    zhtw: '警報內容',
    ko: '알림 내용',
    th: 'คำอธิบายการแจ้งเตือน',
    vi: 'Mô tả cảnh báo',
  },
  setToUnprocessable: {
    ja: '未解決にする（管理者・酒蔵）',
    en: 'Set to Unresolved (Admin & Brewery users)',
    zhcn: '设为未解决（管理员/啤酒厂）',
    zhtw: '設為未解決（管理員/啤酒廠）',
    ko: '미해결로함(관리자, 양조장)',
    th: 'ตั้งค่าเป็นไม่ได้รับการแก้ไข (ผู้ใช้ผู้ดูแลระบบและโรงเบียร์)',
    vi: 'Đặt thành Chưa được giải quyết (Người dùng Quản trị viên & Nhà máy bia)',
  },
  toUnprocessable: {
    ja: '未解決にする（管理者・酒蔵）',
    en: 'Change Status to Unresolved (Admin & Brewery users)',
    zhcn: '更改状态为未解决（管理员/啤酒厂）',
    zhtw: '更改狀態為未解决（管理員/啤酒廠）',
    ko: '미해결로 변경(관리자, 양조장)',
    th: 'ตั้งค่าเป็นไม่ได้รับการแก้ไข (ผู้ใช้ผู้ดูแลระบบและโรงเบียร์)',
    vi: 'Đặt thành Chưa được giải quyết (Người dùng Quản trị viên & Nhà máy bia)',
  },
  toUrgent: {
    ja: 'Urgent状態にする（管理者・酒蔵）',
    en: 'Change Status to Urgent (Admin & Brewery users)',
    zhcn: '更改状态为Urgent（管理员/啤酒厂）',
    zhtw: '更改狀態為Urgent（管理員/啤酒廠）',
    ko: 'Urgent 변경(관리자, 양조장)',
    th: 'เปลี่ยนสถานะเป็นไม่ได้รับการแก้ไข (ผู้ใช้ผู้ดูแลระบบและโรงเบียร์)',
    vi: 'Thay đổi Trạng thái thành Chưa được giải quyết (Người dùng Quản trị viên & Nhà máy bia)',
  },
  convertAlertTo: {
    ja: 'アラートステータスを変更',
    en: 'Change Alert Status',
    zhcn: '更改警报状态',
    zhtw: '更改警報狀態',
    ko: '알람 상태를 변경',
    th: 'เปลี่ยนสถานะการแจ้งเตือน',
    vi: 'Thay đổi trạng thái cảnh báo',
  },
  assigneeNameField: {
    ja: 'コメントを残す（必須）',
    en: 'Leave a comment (Required)',
    zhcn: '发表评论（必填）',
    zhtw: '發表評論（必填）',
    ko: '코멘트를 남기십시오 (필수).',
    th: 'ใส่ความเห็น (จำเป็น)',
    vi: 'Để lại bình luận (Bắt buộc)',
  },
  selectRangeDistance: {
    ja: '許容距離差を選択してください',
    en: 'Select Tolerable Distance Difference',
    zhcn: '选择可容忍的距离差异',
    zhtw: '選擇可容忍的距離差異',
    ko: '허용 거리를 선택하시오',
    th: 'เลือกความแตกต่างระยะทางที่ยอมรับได้',
    vi: 'Chọn chênh lệch khoảng cách có thể chấp nhận được',
  },
  editScanRangeDistance: {
    ja: 'スキャン範囲を変更する',
    en: 'Edit Tolerable Distance Difference',
    zhcn: '改变扫描范围',
    zhtw: '改變掃描範圍',
    ko: '스캔 범위를 변경 함',
    th: 'แก้ไขค่าความต่างของระยะทางที่ยอมรับได้',
    vi: 'Chỉnh sửa sự khác biệt khoảng cách chấp nhận được',
  },
  sendNotificationTitle: {
    ja: 'お知らせを配信する',
    en: 'Send notification',
    zhcn: '发送通知',
    zhtw: '發送通知',
    ko: '소식을 전달하는',
    th: 'ส่งการแจ้งเตือน',
    vi: 'Gửi thông báo',
  },
  receiveOrSale: {
    ja: '入荷・販売',
    en: 'Receive/Selling',
    zhcn: '到达・销售',
    zhtw: '到達・銷售',
    ko: '입하/판매',
    th: 'รับ/ขาย',
    vi: 'Nhận/Bán',
  },
  updateBrand: {
    ja: '商品情報を編集',
    en: 'Update Brand Information',
    zhcn: '编辑产品信息',
    zhtw: '編輯產品信息',
    ko: '상품 정보를 편집',
    th: 'อัปเดตข้อมูลแบรนด์',
    vi: 'Cập nhật thông tin thương hiệu',
  },
  customerLocation: {
    ja: 'カスタマー情報',
    en: 'Customer Information',
    zhcn: '客户位置',
    zhtw: '客戶位置',
    ko: '업데이트',
    th: 'ข้อมูลลูกค้า',
    vi: 'thông tin khách hàng',
  },
  updateCustomerLocation: {
    ja: 'カスタマー情報を編集',
    en: 'Update Customer Information',
    zhcn: '更新客户位置',
    zhtw: '更新客戶位置',
    ko: '고객 정보 업데이트',
    th: 'อัปเดตข้อมูลลูกค้า',
    vi: 'Cập nhật thông tin khách hàng',
  },
  updateUserIdPass: {
    ja: 'ID・PASS編集',
    en: 'Update User ID/Password',
    zhcn: '更新用户名・密码',
    zhtw: '更新用戶名・密碼',
    ko: '사용자 ID / 비밀번호 업데이트',
    th: 'อัปเดต ID ผู้ใช้/รหัสผ่าน',
    vi: 'Cập nhật ID người dùng/Mật khẩu',
  },
  privacyPolicy: {
    ja: 'プライバシーポリシー',
    en: 'Privacy Policy',
    zhcn: '隱私政策',
    zhtw: '隐私政策',
    ko: '개인정보정책',
    th: 'นโยบายความเป็นส่วนตัว',
    vi: 'Chính sách bảo mật',
  },
  scanDueDate: {
    ja: 'スキャン実施期限',
    en: 'Scan Due Date',
    zhcn: '扫描期限',
    zhtw: '掃描期限',
    ko: '스캔 실시 기한',
    th: 'สแกนวันครบกำหนด',
    vi: 'Ngày đến hạn quét',
  },
  scanQualityDate: {
    ja: '在庫品質保証期間',
    en: 'Inventory Quality Certified Duration',
    zhcn: '库存质量保证期间',
    zhtw: '庫存質量保證期間',
    ko: '재고 품질 보증 기간',
    th: 'ระยะเวลาการรับรองคุณภาพสินค้าคงคลัง',
    vi: 'Thời hạn chứng nhận chất lượng hàng tồn kho',
  },
  registrationError: {
    ja: '登録処理中にエラーが発生しました',
    en: 'An issue has occured during registration',
    zhcn: '在注册过程中发生了错误',
    zhtw: '在註冊過程中發生了錯誤',
    ko: '등록처리중 오류가 발생했습니다',
    th: 'เกิดปัญหาระหว่างการลงทะเบียน',
    vi: 'Đã xảy ra sự cố trong quá trình đăng ký',
  },
  serialCollection: {
    ja: 'シリアル管理',
    en: 'Serial Numbers',
    zhcn: '系列收藏',
    zhtw: '系列收藏',
    ko: '일련관리',
    th: 'หมายเลขซีเรียล',
    vi: 'Số seri',
  },
  activateLocation: {
    ja: '承認する',
    en: 'Approve',
    zhcn: '批准',
    zhtw: '批准',
    ko: '승인하다',
    th: 'อนุมัติ',
    vi: 'Chấp thuận',
  },
  locationStatusReviewing: {
    ja: '未承認',
    en: 'Inactive',
    zhcn: '未批准',
    zhtw: '未批准',
    ko: '미승인',
    th: 'ไม่ได้ใช้งาน',
    vi: 'Không hoạt động',
  },
  locationStatusActivated: {
    ja: '承認済',
    en: 'Activated',
    zhcn: '已确认',
    zhtw: '已確認',
    ko: '승인 완료',
    th: 'เปิดใช้งานแล้ว',
    vi: 'Đã kích hoạt',
  },
  activateLocationMessage: {
    ja: '拠点は承認されました',
    en: 'Location was activated',
    zhcn: '地点已确认',
    zhtw: '地點已確認',
    ko: '거점이 승인되었습니다',
    th: 'เปิดใช้งานตำแหน่งแล้ว',
    vi: 'Vị trí đã được kích hoạt',
  },
  notificationSent: {
    ja: '配信が完了しました',
    en: 'Notification sent',
    zhcn: '交貨完成',
    zhtw: '交貨完成',
    ko: '배달이 완료되었습니다',
    th: 'ส่งการแจ้งเตือนแล้ว',
    vi: 'Đã gửi thông báo',
  },
  soldMessage: {
    ja: '販売が完了しました',
    en: 'Selling complete',
    zhcn: '銷售完成',
    zhtw: '銷售完成',
    ko: '판매가 완료되었습니다',
    th: 'ขายหมดครับ',
    vi: 'Bán hoàn thành',
  },
  resetShippingInfo: {
    ja: '酒蔵出荷情報のリセット',
    en: 'Reset brewery shipping information',
    zhcn: '重置酒厂运输信息',
    zhtw: '重置酒廠運輸信息',
    ko: '양조장 출하 정보 재설정',
    th: 'รีเซ็ตข้อมูลการจัดส่งโรงเบียร์',
    vi: 'Đặt lại thông tin vận chuyển của nhà máy bia',
  },
  confirmSmt: {
    ja: '{field}を確認する',
    en: 'Check {Field}',
    zhcn: '检查{field}',
    zhtw: '檢查{field}',
    ko: '{field} 확인',
    th: 'ตรวจสอบ {ฟิลด์}',
    vi: 'Kiểm tra {Trường}',
  },
  confirmResetShippingInfo: {
    ja: '酒蔵出荷に関する以下の情報をリセットしますか？',
    en: 'Reset following brewery shipping information?',
    zhcn: '您想重置关于酒厂运输的下列信息吗？',
    zhtw: '您想重置關於酒廠運輸的下列信息嗎？',
    ko: '양조장 출하에 대한 다음 정보를 재설정 하시겠습니까?',
    th: 'รีเซ็ตข้อมูลการจัดส่งโรงเบียร์ต่อไปนี้หรือไม่',
    vi: 'Đặt lại thông tin vận chuyển của nhà máy bia sau đây?',
  },
  resetRestaurantArrivallInfo: {
    ja: 'レストラン入荷情報のリセット',
    en: 'Reset restaurant receiving information',
    zhcn: '重置餐厅收货信息',
    zhtw: '重置餐廳收貨信息',
    ko: '레스토랑 입하 정보 재설정',
    th: 'รีเซ็ตข้อมูลการรับร้านอาหาร',
    vi: 'Reset thông tin nhận nhà hàng',
  },
  confirmResetRestaurantIArrivalnfo: {
    ja: 'レストラン入荷に関する以下の情報をリセットしますか？',
    en: 'Reset following restaurant receiving information?',
    zhcn: '您想重置关于餐厅收货的下列信息吗？',
    zhtw: '您想重置關於餐廳收貨的下列信息嗎？',
    ko: '레스토랑 입하 관련 다음 정보를 재설정 하시겠습니까?',
    th: 'รีเซ็ตข้อมูลการรับข้อมูลร้านอาหารต่อไปนี้หรือไม่',
    vi: 'Đặt lại thông tin nhận nhà hàng sau?',
  },
  resetRestaurantSaleInfo: {
    ja: 'レストラン販売情報のリセット',
    en: 'Reset restaurant sales information',
    zhcn: '重置餐厅销售信息',
    zhtw: '重置餐廳銷售信息',
    ko: '레스토랑 판매 정보 재설정',
    th: 'รีเซ็ตข้อมูลการขายร้านอาหาร',
    vi: 'Đặt lại thông tin bán hàng nhà hàng',
  },
  confirmResetRestaurantSaleInfo: {
    ja: 'レストラン販売に関する以下の情報をリセットしますか？',
    en: 'Reset following restaurant sales information?',
    zhcn: '您想重置关于餐厅销售的下列信息吗？',
    zhtw: '您想重置關於餐廳銷售的下列信息嗎？',
    ko: '레스토랑 판매에 관련 다음 정보를 재설정 하시겠습니까?',
    th: 'รีเซ็ตข้อมูลการขายร้านอาหารต่อไปนี้หรือไม่',
    vi: 'Đặt lại thông tin bán hàng của nhà hàng sau đây?',
  },
  reload: {
    ja: 'データ取得に失敗しました、再読み込みをしてください',
    en: 'Failed to acquire data, please reload',
    zhcn: '未能获取数据，请重新加载',
    zhtw: '未能獲取數據，請重新加載',
    ko: '데이터를 가져 오는 데 실패했습니다. 다시 시도 하십시오',
    th: 'รับข้อมูลไม่สำเร็จ โปรดโหลดซ้ำ',
    vi: 'Không lấy được dữ liệu, vui lòng tải lại',
  },
  scanPosition: {
    ja: 'スキャン位置（緯度経度）',
    en: 'Scan position (latitude/longitude)',
    zhcn: '扫描坐标（纬度经度）',
    zhtw: '掃描坐標（緯度經度）',
    ko: '스캔 위치 (위도 경도)',
    th: 'ตำแหน่งการสแกน (ละติจูด/ลองจิจูด)',
    vi: 'Vị trí quét (vĩ độ/kinh độ)',
  },
  surveyFlag: {
    ja: '要調査フラグ',
    en: 'Survey flag',
    zhcn: '待调查标记',
    zhtw: '待調查標記',
    ko: '조사 플래그',
    th: 'ธงสำรวจ',
    vi: 'Cờ khảo sát',
  },
  serverError: {
    ja: 'サーバ内部でエラーが発生しました',
    en: 'An error has occurred in server',
    zhcn: '服务器内发生错误',
    zhtw: '服務器內發生錯誤',
    ko: '서버 내부 오류가 발생했습니다',
    th: 'เกิดข้อผิดพลาดในเซิร์ฟเวอร์',
    vi: 'Đã xảy ra lỗi trong máy chủ',
  },
  sessionTimedout: {
    ja: 'セッションの有効期限が切れました。恐れ入りますが再度ログインしてください。',
    en: 'Session has expired. Please login again.',
    zhcn: '会话已过期。 很抱歉，请重新登录。',
    zhtw: '會話已過期。 很抱歉，請重新登錄。',
    ko: '세션이 만료되었습니다. 다시 로그인하십시오.',
    th: 'เซสชั่นหมดอายุแล้ว กรุณาเข้าสู่ระบบอีกครั้ง',
    vi: 'Phiên đã hết hạn. Xin vui lòng đăng nhập lại.',
  },
  serialLength: {
    ja: '* 最大文字数は{field}文字です',
    en: '* The maximum number of characters is {field}',
    zhcn: '*最大字符数为{field}',
    zhtw: '*最大字符數為{field}',
    ko: '* 최대 길이는 {field} 자입니다',
    th: 'จำนวนอักขระสูงสุดคือ {field}',
    vi: 'Số lượng ký tự tối đa là {field}',
  },
  onlyNumbers: {
    ja: '* 数字以外の文字が含まれています',
    en: '* Contains non-numeric characters',
    zhcn: '*含有数字以外的字符',
    zhtw: '*含有數字以外的字符',
    ko: '* 숫자가 아닌 문자가 포함되어 있습니다',
    th: 'ประกอบด้วยอักขระที่ไม่ใช่ตัวเลข',
    vi: 'Chứa ký tự không phải số',
  },
  illegalSellingAlert: {
    ja: '商品販売時のスキャン位置情報が登録済のものと一致しません',
    en: 'Product selling scan was not conducted at the registered location',
    zhcn: '在产品销售时扫描位置信息与已注册的内容不符',
    zhtw: '在產品銷售時掃描位置信息與已註冊的內容不符',
    ko: '제품 판매시 스캔 위치 정보가 등록된 것과 일치하지 않습니다',
    th: 'ไม่ได้ดำเนินการสแกนการขายผลิตภัณฑ์ ณ สถานที่ที่ลงทะเบียน',
    vi: 'Quét bán sản phẩm không được thực hiện tại địa điểm đã đăng ký',
  },
  distQualityAlert: {
    ja: '商品の出荷後{day}日以上出荷・配送がされていません',
    en: 'Product not shipped/delivered for more than {day} days after receiving',
    zhcn: '产品发货后超过{day}天未发货或交付',
    zhtw: '產品發貨後超過{day}天未發貨或交付',
    ko: '상품의 출하 후 {day}일 이상 출하 배송이 되지 않았습니다',
    th: 'สินค้าไม่ได้จัดส่ง/จัดส่งนานกว่า {day} วันหลังจากได้รับ',
    vi: 'Sản phẩm không được vận chuyển/giao trong hơn {day} ngày sau khi nhận',
  },
  illegalShippingAlert: {
    ja: '商品出荷スキャンは登録場所で行われていません',
    en: 'Product shipping scan was not conducted at the registered location',
    zhcn: '产品运输扫描未在注册位置进行',
    zhtw: '產品運輸掃描未在註冊位置進行',
    ko: '등록된 위치에서 제품 배송 스캔이 수행되지 않았습니다',
    th: 'ไม่ได้ดำเนินการสแกนการจัดส่งสินค้าในสถานที่ที่ลงทะเบียน',
    vi: 'Việc quét vận chuyển sản phẩm không được thực hiện tại địa điểm đã đăng ký',
  },
  searchBySerialnumber: {
    ja: 'シリアル番号で検索',
    en: 'Search by serial number',
    zhcn: '按序列号搜索',
    zhtw: '按序列号搜索',
    ko: '일련 번호로 검색',
    th: 'ค้นหาตามหมายเลขซีเรียล',
    vi: 'Tìm kiếm theo số serial',
  },
  addDoStatusUpdateButtonText: {
    ja: '更新する',
    en: 'Update',
    zhcn: '更新',
    zhtw: '更新',
    ko: '업데이트',
    th: 'อัปเดต',
    vi: 'Cập nhật',
  },
  addStatusUpdateButtonText: {
    ja: 'ステータス更新',
    en: 'Status Update',
    zhcn: '状态更新',
    zhtw: '狀態更新',
    ko: '상황 업데이트',
    th: 'อัพเดทสถานะ',
    vi: 'Cập nhật trạng thái',
  },
  addFilterButtonText: {
    ja: 'フィルタを追加',
    en: 'Add filter',
    zhcn: '添加过滤器',
    zhtw: '添加过滤器',
    ko: '필터 추가',
    th: 'เพิ่มตัวกรอง',
    vi: 'Thêm bộ lọc',
  },
  clearAllButtonText: {
    ja: 'すべてクリア',
    en: 'Clear all',
    zhcn: '清除所有',
    zhtw: '清除所有',
    ko: '모두 지우기',
    th: 'ลบทั้งหมด',
    vi: 'Làm sạch tất cả',
  },
  filterPlaceHolderAlertStatus: {
    ja: 'アラートステータスを選択',
    en: 'Select alert status',
    zhcn: '选择警报状态',
    zhtw: '选择警报状态',
    ko: '경고 상태 선택',
    th: 'เลือกสถานะการแจ้งเตือน',
    vi: 'Chọn trạng thái cảnh báo',
  },
  filterPlaceHolderAlertType: {
    ja: 'アラートタイプを選択',
    en: 'Select alert type',
    zhcn: '选择警报类型',
    zhtw: '选择警报类型',
    ko: '알림 유형 선택',
    th: 'เลือกประเภทการแจ้งเตือน',
    vi: 'Chọn loại cảnh báo',
  },
  filterPlaceHolderRegion: {
    ja: '地域を選択',
    en: 'Select region',
    zhcn: '选择地区',
    zhtw: '选择地区',
    ko: '지역 선택',
    th: 'เลือกภูมิภาค',
    vi: 'Chọn khu vực',
  },
  filterPlaceHolderLocationName: {
    ja: '拠点名',
    en: 'Location name',
    zhcn: '地点名称',
    zhtw: '地点名称',
    ko: '위치 명',
    th: 'ชื่อสถานที่',
    vi: 'Tên địa điểm',
  },
  alertStatusLabel: {
    ja: 'アラートステータス',
    en: 'Alert Status',
    zhcn: '警报状态',
    zhtw: '警报状态',
    ko: '경고 상태',
    th: 'สถานะการแจ้งเตือน',
    vi: 'Trạng thái cảnh báo',
  },
  regionLabel: {
    ja: '地域',
    en: 'Region',
    zhcn: '地区',
    zhtw: '地区',
    ko: '지역',
    th: 'ภูมิภาค',
    vi: 'Khu vực',
  },
  receivingScanDelayAlert: {
    ja: '入荷スキャン遅延アラート',
    en: 'Delayed Receiving Scan Alert',
    zhcn: '接收扫描延迟警报',
    zhtw: '接收扫描延迟警报',
    ko: '스캔 지연 알림 수신',
    th: 'การแจ้งเตือนการสแกนการรับล่าช้า',
    vi: 'Cảnh báo quét nhận bị trì hoãn',
  },
  inventoryQualityAlert: {
    ja: '品質管理アラート',
    en: 'Quality Control Alert',
    zhcn: '库存质量警报',
    zhtw: '库存质量警报',
    ko: '재고 품질 알림',
    th: 'การแจ้งเตือนการควบคุมคุณภาพ',
    vi: 'Cảnh báo kiểm soát chất lượng',
  },
  gpsAlert: {
    ja: 'GPSアラート',
    en: 'GPS Alert',
    zhcn: '全球定位系统警报',
    zhtw: '全球定位系统警报',
    ko: 'GPS 경보',
    th: 'การแจ้งเตือนด้วย GPS',
    vi: 'Cảnh báo GPS',
  },
  receivingScanDelayAlert2: {
    ja: '入荷遅延アラート',
    en: 'Delayed Receiving Scan',
    zhcn: '接收扫描延迟警报',
    zhtw: '接收扫描延迟警报',
    ko: '스캔 지연 알림 수신',
    th: 'การสแกนการรับล่าช้า',
    vi: 'Quét nhận bị trì hoãn',
  },
  inventoryQualityAlert2: {
    ja: '品質管理アラート',
    en: 'Quality Control',
    zhcn: '库存质量警报',
    zhtw: '库存质量警报',
    ko: '재고 품질 알림',
    th: 'ควบคุมคุณภาพ',
    vi: 'Kiểm soát chất lượng',
  },
  gpsAlert2: {
    ja: 'GPSアラート',
    en: 'GPS',
    zhcn: '全球定位系统警报',
    zhtw: '全球定位系统警报',
    ko: 'GPS 경보',
    th: 'จีพีเอส',
    vi: 'GPS',
  },
  revertTo: {
    ja: 'ステータスを{field}に戻す',
    en: 'Revert Status to {field}',
    zhcn: '将状态恢复为{field}',
    zhtw: '將狀態恢復為{field}',
    ko: '상태를 {필드}로 되돌리기',
    th: 'เปลี่ยนสถานะเป็น {field}',
    vi: 'Hoàn nguyên trạng thái về {field}',
  },
  addSalesRecord: {
    ja: '販売記録を追加',
    en: 'Adding sales record',
    zhcn: '添加销售记录',
    zhtw: '添加銷售記錄',
    ko: '판매 기록을 추가하십시오',
    th: 'เพิ่มบันทึกการขาย',
    vi: 'Thêm hồ sơ bán hàng',
  },
  updateAlertStatus: {
    ja: '警告ステータスを「未解決」によって更新されます',
    en: 'Update alert status to "Unresolved" by',
    zhcn: '将警报状态更新为“未解决”',
    zhtw: '將警報狀態更新為“未解決”',
    ko: '경고 상태를 "해결되지 않은"로 업데이트하십시오',
    th: 'อัปเดตสถานะการแจ้งเตือนเป็น "ยังไม่แก้ไข" โดย',
    vi: 'Cập nhật trạng thái cảnh báo thành "Chưa giải quyết" bởi',
  },
  leavingAComment: {
    ja: 'コメントを入力',
    en: 'Leaving a comment',
    zhcn: '留下评论',
    zhtw: '留下評論',
    ko: '의견을 남기십시오',
    th: 'แสดงความคิดเห็น',
    vi: 'Để lại một bình luận',
  },
  adminSaleRecord: {
    ja: 'Adminによる記録',
    en: 'Sales recorded by Admin',
    zhcn: 'Admin录制的销售',
    zhtw: 'Admin錄製的銷售',
    ko: '관리자가 기록한 판매',
    th: 'ยอดขายที่บันทึกโดยผู้ดูแลระบบ',
    vi: 'Doanh số được ghi nhận bởi Admin',
  },
  scanByUser: {
    ja: 'ユーザーによるスキャン',
    en: 'Scanned by the user',
    zhcn: '由用户扫描',
    zhtw: '由用戶掃描',
    ko: '사용자가 스캔 한 것입니다',
    th: 'สแกนโดยผู้ใช้',
    vi: 'Được quét bởi người dùng',
  },
  chooseOption: {
    ja: 'オプションを選択',
    en: 'Continue to choose an option',
    zhcn: '继续选择一个选项',
    zhtw: '繼續選擇一個選項',
    ko: '옵션을 계속 선택하십시오.',
    th: 'เลือกตัวเลือกต่อไป',
    vi: 'Tiếp tục chọn một tùy chọn',
  },
  skip: {
    ja: 'Skip',
    en: 'Skip',
    zhcn: 'Skip',
    zhtw: 'Skip',
    ko: 'Skip',
    th: 'ข้าม',
    vi: 'Nhảy',
  },
  pleasePasteTheQRDataMessage: {
    ja: '*QRスキャナーで読み取ったデータを、そのまま貼り付けてください',
    en: '*Input data read by a QR',
    zhcn: '*数据由QR扫描仪读取，请复制并粘贴',
    zhtw: '*數據由QR掃描儀讀取，請複製並貼上',
    ko: '*QR 스캐너로 스캔 한 데이터를 그대로 붙여 넣으십시오',
    th: '*ป้อนข้อมูลที่อ่านโดย QR',
    vi: '*Nội dung đầu vào được đọc bằng QR',
  },
  pleaseBringTheQRCodeCloserToTheCamera: {
    ja: '*QRをカメラに近づけてください',
    en: '*Please move QR code closer to the camera',
    zhcn: '*请将QR码贴近相机',
    zhtw: '*請將QR碼貼近相機',
    ko: '*QR 코드를 카메라에 가까이 하십시오',
    th: '*ป้อนข้อมูลที่อ่านโดย QR',
    vi: '* Dữ liệu đầu vào được đọc bằng QR',
  },
  reading: {
    ja: '読み取り中...',
    en: 'Reading...',
    zhcn: '读取中...',
    zhtw: '讀取中 ...',
    ko: '읽는 중',
    th: 'การอ่าน...',
    vi: 'Đọc...',
  },
  externalQR: {
    ja: 'URL入力',
    en: 'Input QR code URL',
    zhcn: '输入QR码的URL',
    zhtw: '輸入QR碼的URL',
    ko: 'URL 입력',
    th: 'ป้อน URL โค้ด QR',
    vi: 'Nhập URL mã QR',
  },
  camera: {
    ja: 'カメラスキャン',
    en: 'Camera Scan',
    zhcn: '相机',
    zhtw: '相機',
    ko: '카메라스캔',
    th: 'สแกนกล้อง',
    vi: 'Quét máy ảnh',
  },
  invalidQRCode: {
    ja: '無効なQRコードです',
    en: 'Invalid QR code',
    zhcn: '无效的QR码',
    zhtw: '無效的QR碼',
    ko: '잘못된 QR 코드',
    th: 'รหัส QR ไม่ถูกต้อง',
    vi: 'Mã QR không hợp lệ',
  },
  confirmNotifyNotDelivered: {
    ja: '未到着をサプライヤーに通知しますか？',
    en: 'Do you need to notify the supplier that the bottle is not delivered yet?',
    zhcn: '是否通知专卖店该货品未到货？',
    zhtw: '是否通知專賣店該貨品未到貨？',
    ko: '이 병이 배송되지 않는다고 유통업체에 알리시겠습니까?',
    th: 'คุณแน่ใจหรือไม่ที่จะแจ้งซัพพลายเออร์ว่ายังไม่ได้จัดส่งขวด?',
    vi: 'Bạn có cần thông báo cho nhà cung cấp rằng chai chưa được giao không?',
  },
  confirmRegisterAsSold: {
    ja: 'このボトルを販売済みとして登録してもよろしいですか?',
    en: 'Do you need to register that this bottle was sold without scan?',
    zhcn: '您确定将此货品登记为已售出吗？',
    zhtw: '您確定將此貨品登記為已售出嗎？',
    ko: '이 병을 판매된 것으로 등록하시겠습니까?',
    th: 'คุณแน่ใจหรือไม่ว่าได้ลงทะเบียนว่าขวดนี้ขายโดยไม่มีการสแกน',
    vi: 'Bạn có cần đăng ký rằng chai này đã được bán mà không cần quét không?',
  },
  notifyDistributorDone: {
    ja: '通知はサプライヤーに送信されました。',
    en: 'Notification has been sent to the supplier.',
    zhcn: '通知将发送给专卖店。',
    zhtw: '通知將發送給專賣店。',
    ko: '배포자에게 알림이 전송됩니다.',
    th: 'การแจ้งเตือนถูกส่งไปยังซัพพลายเออร์แล้ว',
    vi: 'Thông báo đã được gửi đến nhà cung cấp.',
  },
  registerAsInStockError: {
    ja:
      'QR コードの形式が間違っているか、コードが一致しません。ボトルに記載されているQRコードをスキャンしてください。',
    en: 'QR code format incorrect or code not matched. Please scan the QR code on the bottle.',
    zhcn: 'QR 码格式不正确或序列号不匹配。请扫描货品上的二维码。',
    zhtw: 'QR 碼格式不正確或序列號不匹配。請掃描貨品上的二維碼。',
    ko: 'QR 코드 형식이 잘못되었거나 코드가 일치하지 않습니다. 병에 있는 QR 코드를 스캔하세요.',
    th: 'ได้ส่งการแจ้งเตือนไปยังซัพพลายเออร์แล้ว',
    vi: 'Thông báo đã được gửi đến nhà cung cấp.',
  },
  registerAsInStockDone: {
    ja:
      '在庫確認が完了しました。 \n日本酒の鮮度を保つため、入荷後60日以内に販売いただくようお願いします。',
    en:
      'Stock has been confirmed. \nTo keep the fresh quality of sake, please try to sell within 60 days after delivery.',
    zhcn: '库存已确认。 \n为保持清酒的新鲜品质，请尽量在发货后60天内售出。请扫描酒瓶上的二维码。',
    zhtw: '庫存已確認。 \n為保持清酒的新鮮品質，請盡量在發貨後60天內售出。請掃描酒瓶上的二維碼。',
    ko:
      '재고가 확인되었습니다. \n사케의 신선한 품질을 유지하기 위해 배송 후 60일 이내에 판매하도록 하십시오. 병에 있는 QR 코드를 스캔하십시오.',
    th:
      'สต็อกได้รับการยืนยันแล้ว \nเพื่อรักษาคุณภาพสาเกที่สดใหม่ โปรดพยายามขายภายใน 60 วันหลังคลอด',
    vi:
      'Chứng khoán đã được xác nhận. \nĐể đảm bảo chất lượng rượu sake được duy trì, vui lòng cố gắng bán trong vòng 60 ngày sau khi giao hàng.',
  },
  confirmCancelDelivery: {
    ja: 'この配送をキャンセルしてもよろしいですか? \nこのボトルは在庫に戻されます。',
    en: 'Do you need to cancel this delivery? This stock will be returned to your inventory.',
    zhcn: '您确定要取消这次送货吗？ \n该货品将退回您的库存。',
    zhtw: '您確定要取消這次送貨嗎？ \n該貨品將退回您的庫存。',
    ko: '이 배송을 취소하시겠습니까? \n이 재고는 인벤토리로 반환됩니다.',
    th: 'คุณแน่ใจหรือว่าจะยกเลิกการจัดส่งนี้ \nสต็อกนี้จะถูกส่งคืนไปยังสินค้าคงคลังของคุณ',
    vi:
      'Bạn có cần hủy chuyến giao hàng này không? \nCổ phiếu này sẽ được trả lại vào kho của bạn.',
  },
  adminSetAlertResolved: {
    ja: '解決済みに変更する',
    en: 'Set as resolved',
    zhcn: '设置为已解决',
    zhtw: '設置為已解決',
    ko: '해결됨으로 변경',
    th: 'ตั้งเป็นแก้ไขแล้ว',
    vi: 'Đặt là đã giải quyết',
  },
  adminSetAlertUnresolved: {
    ja: '未解決に変更する',
    en: 'Set as unresolved',
    zhcn: '设置为未解决',
    zhtw: '設置為未解決',
    ko: '미결제로 변경',
    th: 'ตั้งเป็นยังไม่ได้รับการแก้ไข',
    vi: 'Đặt là chưa được giải quyết',
  },
  charts: {
    ja: 'データ分析',
    en: 'Data Analytics',
    zhcn: '数据分析',
    zhtw: '數據分析',
    ko: '데이터 분석',
    th: 'การวิเคราะห์ข้อมูล',
    vi: 'Phân tích dữ liệu',
  },
  qualityMonitoring: {
    ja: '品質管理',
    en: 'Quality Management',
    zhcn: '质量管理',
    zhtw: '品質管理',
    ko: '품질 관리',
    th: 'การจัดการคุณภาพ',
    vi: 'Quản lý chất lượng',
  },
  shipChange: {
    ja: '在庫管理',
    en: 'Stock Management',
    zhcn: '库存管理',
    zhtw: '庫存管理',
    ko: '재고관리',
    th: 'การจัดการสต็อก',
    vi: 'Quản lý chứng khoán',
  },
  period: {
    ja: '期間',
    en: 'Period',
    zhcn: '时期',
    zhtw: '時期',
    ko: '기간',
    th: 'ระยะเวลา',
    vi: 'Giai đoạn',
  },
  country: {
    ja: '国',
    en: 'Country',
    zhcn: '国家',
    zhtw: '國家',
    ko: '국가',
    th: 'ประเทศ',
    vi: 'Quốc gia',
  },
  pleaseSelect: {
    ja: '選択してください...',
    en: 'Please select...',
    zhcn: '请选择...',
    zhtw: '請選擇...',
    ko: '선택하십시오',
    th: 'โปรดเลือก...',
    vi: 'Vui lòng chọn...',
  },
  reset: {
    ja: 'リセット',
    en: 'Reset',
    zhcn: '重置',
    zhtw: '重置',
    ko: '재설정',
    th: 'รีเซ็ต',
    vi: 'Cài lại',
  },
  locCategory: {
    ja: '拠点種別',
    en: 'Location Type',
    zhcn: '位置类型',
    zhtw: '位置類型',
    ko: '거점 유형',
    th: 'ประเภทสถานที่',
    vi: 'Loại địa điểm',
  },
  salesPerformance: {
    ja: '販売実績',
    en: 'Sales Performance',
    zhcn: '销售业绩',
    zhtw: '銷售成績',
    ko: '판매 실적',
    th: 'ประสิทธิภาพการขาย',
    vi: 'Hiệu suất bán hàng',
  },
  inventoryTurnoverPeriod: {
    ja: '在庫回転日数',
    en: 'Inventory Turnover',
    zhcn: '存货周转期',
    zhtw: '庫存周轉天數',
    ko: '재고 회전율',
    th: 'การหมุนเวียนสินค้าคงคลัง',
    vi: 'Doanh thu hàng tồn kho',
  },
  totalSales: {
    ja: '販売総数',
    en: 'Total Sales',
    zhcn: '整体销售量',
    zhtw: '總銷售量',
    ko: '총 판매 수',
    th: 'ยอดขายทั้งหมด',
    vi: 'Tổng doanh thu',
  },
  privateCustomer: {
    ja: '（個人顧客）',
    en: ' (Private Customer) ',
    zhcn: '（私人客户）',
    zhtw: '（直客）',
    ko: '（개인 고객）',
    th: '(ลูกค้าส่วนตัว)',
    vi: '(Khách hàng cá nhân)',
  },
  baseNum: {
    ja: '拠点数',
    en: 'Location',
    zhcn: '地点',
    zhtw: '地點數量',
    ko: '거점',
    th: 'ที่ตั้ง',
    vi: 'Vị trí',
  },
  activeAlert: {
    ja: '発生中アラート',
    en: 'Active Alerts',
    zhcn: '有效警报',
    zhtw: '警報訊息',
    ko: '발생된 경고',
    th: 'การแจ้งเตือนที่ใช้งานอยู่',
    vi: 'Cảnh báo hoạt động',
  },
  alertNum: {
    ja: 'アラート件数',
    en: 'Number of alerts',
    zhcn: '警报数量',
    zhtw: '警報數量',
    ko: '경고 건수',
    th: 'จำนวนการแจ้งเตือน',
    vi: 'Số lượng cảnh báo',
  },
  ownSiteAlertNum: {
    ja: '自拠点アラート数',
    en: 'Number of alerts at your own location',
    zhcn: '自己的站点警报计数',
    zhtw: '自己的營業據點警報數量',
    ko: '자신의 거점에 대한 경고 수',
    th: 'จำนวนการแจ้งเตือน ณ ตำแหน่งของคุณเอง',
    vi: 'Số lượng cảnh báo tại vị trí của riêng bạn',
  },
  countryNum: {
    ja: '該当国数',
    en: 'Number of countries',
    zhcn: '适用国家数量',
    zhtw: '適用國家數量',
    ko: '해당 국가 수',
    th: 'จำนวนประเทศ',
    vi: 'Số lượng quốc gia',
  },
  distNum: {
    ja: '該当ディストリビューター数',
    en: 'Number of distributors',
    zhcn: '适用酒类店铺数量',
    zhtw: '適用經銷商數量',
    ko: '해당 수입사 수',
    th: 'จำนวนผู้จัดจำหน่าย',
    vi: 'Số lượng nhà phân phối',
  },
  restNum: {
    ja: '該当オントレード軒数',
    en: 'Number of on-trade stores',
    zhcn: '适用餐厅数目',
    zhtw: '適用餐廳數量',
    ko: '해당 업장 수',
    th: 'จำนวนร้านค้าที่ซื้อขาย',
    vi: 'Số lượng cửa hàng kinh doanh',
  },
  alertTotal: {
    ja: 'アラート総数',
    en: 'Total number of alerts',
    zhcn: '警报总数',
    zhtw: '警報總數',
    ko: '총 경고 건수',
    th: 'จำนวนการแจ้งเตือนทั้งหมด',
    vi: 'Tổng số cảnh báo',
  },
  locAlertNum: {
    ja: '拠点別アラート件数',
    en: 'Number of alerts by location',
    zhcn: '按地点划分的警报数量',
    zhtw: '依位置劃分的警報數量',
    ko: '거점별 경고 건수',
    th: 'จำนวนการแจ้งเตือนตามสถานที่',
    vi: 'Số lượng cảnh báo theo vị trí',
  },
  alertTable: {
    ja: 'アラート一覧',
    en: 'Alert List',
    zhcn: '警报列表',
    zhtw: '警報列表',
    ko: '경고 목록',
    th: 'รายการแจ้งเตือน',
    vi: 'Danh sách cảnh báo',
  },
  alertHistory: {
    ja: 'アラート履歴',
    en: 'Alert History',
    zhcn: '警报历史',
    zhtw: '警報歷史紀錄',
    ko: '경고 내역',
    th: 'ประวัติการแจ้งเตือน',
    vi: 'Lịch sử cảnh báo',
  },
  totalAlertNum: {
    ja: 'アラート件数合計',
    en: 'Total',
    zhcn: '警报总数',
    zhtw: '警報總數',
    ko: '경고 건수 합계',
    th: 'ทั้งหมด',
    vi: 'Tổng cộng',
  },
  bottleNum: {
    ja: '本数',
    en: 'Number of bottles',
    zhcn: '瓶数',
    zhtw: '瓶數',
    ko: '병 수',
    th: 'จำนวนขวด',
    vi: 'Số lượng chai',
  },
  soldBottleNum: {
    ja: '銘柄別販売数',
    en: 'Number of bottles sold by brand',
    zhcn: '按品牌销售的瓶数',
    zhtw: '各品牌的銷售數量(瓶)',
    ko: '품목별 판매 수',
    th: 'จำนวนขวดที่ขายตามแบรนด์',
    vi: 'Số chai bán được theo thương hiệu',
  },
  soldLocNum: {
    ja: '銘柄別販売オントレード軒数',
    en: 'Number of restaurants with sales on-trade stores by brand',
    zhcn: '餐厅按品牌划分的销售业绩数量',
    zhtw: '餐廳銷售各品牌的數量',
    ko: '품목별 판매 실적이 있는 업장 수',
    th: 'จำนวนร้านอาหารที่มียอดขายร้านค้าตามแบรนด์',
    vi: 'Số lượng nhà hàng có cửa hàng bán lẻ theo thương hiệu',
  },
  unsoldInventory: {
    ja: '未販売在庫',
    en: 'Total inventory at distributor and restaurants',
    zhcn: '分销商和餐厅的总库存',
    zhtw: '分銷商和餐廳的總庫存',
    ko: '디스트리뷰터와 레스토랑의 총 재고 수',
    th: 'สินค้าคงคลังทั้งหมดที่ผู้จัดจำหน่ายและร้านอาหาร',
    vi: 'Tổng tồn kho tại nhà phân phối và nhà hàng',
  },
  shipDestInventory: {
    ja: '出荷先在庫総数',
    en: 'Shipping destination inventory',
    zhcn: '目的地总库存',
    zhtw: '目的地总库存',
    ko: '배송 대상 재고 총 수',
    th: 'สินค้าคงคลังปลายทางการจัดส่ง',
    vi: 'Hàng tồn kho điểm đến vận chuyển',
  },
  selfInventory: {
    ja: '自拠点在庫',
    en: 'Inventory in your location',
    zhcn: '各自地点存货数量',
    zhtw: '自有據點的庫存',
    ko: '자신 거점의 보유 재고 수',
    th: 'สินค้าคงคลังในตำแหน่งของคุณ',
    vi: 'Hàng tồn kho ở vị trí của bạn',
  },
  brewInventory: {
    ja: '酒蔵在庫',
    en: "Brewery's inventory",
    zhcn: '清酒厂库存',
    zhtw: '酒造庫存',
    ko: '양조장 재고',
    th: 'สินค้าคงคลังของโรงเบียร์',
    vi: 'Hàng tồn kho của nhà máy bia',
  },
  distBrandInventory: {
    ja: 'ディストリビューター別在庫総数',
    en: 'Total Inventory of each distributor',
    zhcn: '每个分销商的总库存',
    zhtw: '每個經銷商的總庫存量',
    ko: '각 수입사의 총 재고 수',
    th: 'สินค้าคงคลังรวมของผู้จัดจำหน่ายแต่ละราย',
    vi: 'Tổng tồn kho của từng nhà phân phối',
  },
  distInventory: {
    ja: 'ディストリビューター在庫',
    en: "Distributors' inventory",
    zhcn: '经销商库存',
    zhtw: '經銷商庫存',
    ko: '유통업체 재고 수',
    th: 'สินค้าคงคลังของผู้จัดจำหน่าย',
    vi: 'Hàng tồn kho của nhà phân phối',
  },
  restInventory: {
    ja: 'オントレード在庫',
    en: "on-trade stores' inventory",
    zhcn: '餐厅库存',
    zhtw: '餐廳庫存',
    ko: '레스토랑 재고',
    th: 'สินค้าคงคลังของร้านค้าที่มีการค้าขาย',
    vi: 'Hàng tồn kho của các cửa hàng thương mại',
  },
  alertGraphYAxis: {
    ja: '　　　　アラート件数',
    en: 'Number of alerts',
    zhcn: '警报数量',
    zhtw: '警报数量',
    ko: '경고 건수',
    th: 'จำนวนการแจ้งเตือน',
    vi: 'Số lượng cảnh báo',
  },
  alertLocGraphYAxis: {
    ja: '　　　　　　　拠点数',
    en: 'Number of locations',
    zhcn: '地点数量',
    zhtw: '地点数量',
    ko: '거점 수',
    th: 'จำนวนสถานที่',
    vi: 'Số lượng vị trí',
  },
  invGraphYAxis1: {
    ja: '　　　　　　販売数（本）',
    en: 'Number of Sales',
    zhcn: '销售数量',
    zhtw: '銷售數量(瓶)',
    ko: '판매 수 (병)',
    th: 'จำนวนการขาย (ขวด)',
    vi: 'Số lượng bán (chai)',
  },
  invGraphYAxis2: {
    ja: '　　　　在庫回転日数（日）',
    en: 'Inventory turnover days (days)',
    zhcn: '库存周转天数（天）',
    zhtw: '庫存周轉天數(天)',
    ko: '재고 회전율 (일)',
    th: 'จำนวนวันที่หมุนเวียนสินค้า',
    vi: 'Số ngày quay vòng hàng tồn kho',
  },
  invGraphYAxis3: {
    ja: '　　　　　　　軒数',
    en: 'Number of locations',
    zhcn: '地点数量',
    zhtw: '據點數量',
    ko: '거점 수',
    th: 'จำนวนสถานที่',
    vi: 'Số lượng vị trí',
  },
  rowsPerPage: {
    ja: '表示行数',
    en: 'Rows',
    zhcn: '显示行数',
    zhtw: '顯示行數',
    ko: '표시행수',
    th: 'จำนวนแถวที่แสดง',
    vi: 'Số lượng hàng được hiển thị',
  },
  tableHeaderToolTip: {
    ja: '矢印をクリックして昇順／降順を切り替え',
    en: 'Click the arrow to toggle ascending/descending order',
    zhcn: '单击箭头以切换升序/降序',
    zhtw: '單擊箭頭以切換升序/降序',
    ko: '화살표를 클릭하여 오름차순/내림차순 전환',
    th: 'คลิกลูกศรเพื่อสลับลำดับจากน้อยไปมาก/จากมากไปน้อย',
    vi: 'Nhấp vào mũi tên để chuyển đổi tăng dần/giảm dần',
  },
  alertLocNum: {
    ja: 'アラート発出拠点数',
    en: 'Number of locations with alerts',
    zhcn: '有警报的地点数量',
    zhtw: '發布警報的地點數量',
    ko: '경고 발생 거점 수',
    th: 'จำนวนสถานที่ที่มีการแจ้งเตือน',
    vi: 'Số lượng vị trí có cảnh báo',
  },
  cardSummary: {
    ja: 'サマリー',
    en: 'Summary',
    zhcn: '概括',
    zhtw: '總結',
    ko: '요약',
    th: 'สรุป',
    vi: 'Bản tóm tắt',
  },
  cardDetail: {
    ja: '内訳',
    en: 'Breakdown',
    zhcn: '明细',
    zhtw: '明细',
    ko: '내역',
    th: 'ชำรุด',
    vi: 'Phá vỡ',
  },
  alertCountryNum: {
    ja: '発生国数',
    en: 'Number of countries with alerts',
    zhcn: '发出警报的国家数量',
    zhtw: '發生警報的國家數量',
    ko: '경고 발생 국가 수',
    th: 'จำนวนประเทศที่มีการแจ้งเตือน',
    vi: 'Số lượng quốc gia có cảnh báo',
  },
  relatedDistNum: {
    ja: '関与ディストリビューター数',
    en: 'Number of distributors',
    zhcn: '涉及的分销商数量',
    zhtw: '涉及經銷商的數量',
    ko: '관련된 수입사 수',
    th: 'จำนวนผู้จัดจำหน่าย',
    vi: 'Số lượng nhà phân phối',
  },
  distAlertNum: {
    ja: 'ディストリビューターアラート数',
    en: 'Number of alerts at distributors',
    zhcn: '分销商警报计数',
    zhtw: '經銷商警報數量',
    ko: '수입사의 경고 수',
    th: 'จำนวนการแจ้งเตือนที่ผู้จัดจำหน่าย',
    vi: 'Số lượng cảnh báo tại nhà phân phối',
  },
  alertDistNum: {
    ja: '発生ディストリビューター数',
    en: 'Number of distributors with alerts',
    zhcn: '有警报的经销商数量',
    zhtw: '發生警報的經銷商數量',
    ko: '경고 발생 수입사 수',
    th: 'จำนวนผู้จัดจำหน่ายพร้อมการแจ้งเตือน',
    vi: 'Số lượng nhà phân phối có cảnh báo',
  },
  restAlertNum: {
    ja: 'オントレードアラート数',
    en: 'Number of alerts at on-trade stores',
    zhcn: '餐厅的警报数量',
    zhtw: '餐廳的警報數量',
    ko: '업장의 경고 수',
    th: 'จำนวนการแจ้งเตือนที่ร้านค้าที่ซื้อขาย',
    vi: 'Số lượng cảnh báo tại các cửa hàng thương mại',
  },
  alertRestNum: {
    ja: '発生オントレード軒数',
    en: 'Number of on-trade stores with alerts',
    zhcn: '有警报的餐厅数量',
    zhtw: '發生警報的餐廳數量',
    ko: '경고가 발생된 업장 수',
    th: 'จำนวนร้านค้าที่ซื้อขายพร้อมการแจ้งเตือน',
    vi: 'Số lượng cửa hàng thương mại có cảnh báo',
  },
  restUnprocessableNum: {
    ja: '未解決数',
    en: 'Number of unresolved alerts',
    zhcn: '未解决警报数量',
    zhtw: '未解決警報數量',
    ko: '미해결 경고 수',
    th: 'จำนวนการแจ้งเตือนที่ยังไม่ได้รับการแก้ไข',
    vi: 'Số lượng cảnh báo chưa được giải quyết',
  },
  unprocessableRestNum: {
    ja: '発生オントレード軒数（未解決）',
    en: 'Number of on-trade stores with unresolved alerts',
    zhcn: '有警报的餐馆数量（未解决）',
    zhtw: '有未解決警報的餐廳數量',
    ko: '미해결 경고가 발생된 업장 수',
    th: 'จำนวนร้านค้าที่ซื้อขายพร้อมการแจ้งเตือนที่ยังไม่ได้รับการแก้ไข',
    vi: 'Số lượng cửa hàng thương mại có cảnh báo chưa được giải quyết',
  },
  notViaDist: {
    ja: '(ディストリビューターなし)',
    en: '(N/A)',
    zhcn: '(不通过经销商)',
    zhtw: '(不通過經銷商)',
    ko: '(유통업체를 통하지 않음)',
    th: 'ไม่มี',
    vi: 'không áp dụng',
  },
  DRSAlert: {
    ja: '入荷スキャン遅延アラート',
    en: 'DRS Alert',
    zhcn: '接收扫描延迟警报',
    zhtw: '接收扫描延迟警报',
    ko: '스캔 지연 알림 수신',
    th: 'การแจ้งเตือนความล่าช้าในการสแกนมาถึง',
    vi: 'Cảnh báo độ trễ quét đến',
  },
  QCAlert: {
    ja: '品質管理アラート',
    en: 'QC Alert',
    zhcn: '库存质量警报',
    zhtw: '库存质量警报',
    ko: '재고 품질 알림',
    th: 'การแจ้งเตือนการควบคุมคุณภาพ',
    vi: 'cảnh báo kiểm soát chất lượng',
  },
  onTrade: {
    ja: 'オントレード',
    en: 'On Trade Stores',
    zhcn: '餐馆',
    zhtw: '餐館',
    ko: '음식점',
    th: 'บนร้านค้าการค้า',
    vi: 'Trên các cửa hàng thương mại',
  },
  analysisDescription1: {
    ja:
      '発生中のアラート件数および一覧。シリアル番号のリンクをクリックし、アラート管理画面にて対応を実施してください。',
    en:
      'Currently active alerts: Please click on the serial number link and take action on the Alert Management page.',
    zhcn: '当前有效警报：请点击序列号链接并在警报管理页面采取措施',
    zhtw: '當前警報顯示 :點擊序列號的連結，並在警報管理頁面上採取對應措施',
    ko:
      '현재 발생된 경고 건수 및 목록입니다. 일련번호 링크를 클릭한 후 경고 관리 화면에서 대응해 주세요.',
    th:
      'จำนวนการแจ้งเตือนที่ใช้งานอยู่และรายการของการแจ้งเตือน โปรดคลิกลิงก์หมายเลขซีเรียลและดำเนินการในหน้าการจัดการการแจ้งเตือน',
    vi:
      'Số lượng cảnh báo đang hoạt động và danh sách cảnh báo. Vui lòng nhấp vào liên kết số sê-ri và thực hiện hành động trên Trang Quản lý cảnh báo.',
  },
  analysisDescription2: {
    ja:
      '過去に発出されたアラート情報から各拠点における商品の品質管理状況を確認し、入出荷処理と販売方法の見直しを実施してください。',
    en:
      'Check quality control status of products at each location based on alert history, and review incoming / outgoing stock processing / sales management.',
    zhcn: '请地点过往发布的警示信息，查看各站点产品质量控制情况，审核发货和收货流程及销售方式。',
    zhtw: '請地點數量過往發布的警報信息，確認各地點的品質管理狀況，並審查收發貨流程和銷售管理。',
    ko:
      '과거 발생된 경고 정보를 통해 각 거점의 상품 품질관리 현황을 확인하고, 입출고 처리 및 판매방법을 재검토 부탁드립니다. ',
    th:
      'การแจ้งเตือนที่ใช้งานอยู่ในปัจจุบัน: โปรดคลิกลิงก์หมายเลขซีเรียลและดำเนินการในหน้าการจัดการการแจ้งเตือน',
    vi:
      'Cảnh báo hiện đang hoạt động: Vui lòng nhấp vào liên kết số sê-ri và thực hiện hành động trên trang Quản lý cảnh báo.',
  },
  analysisDescription3: {
    ja: '過去に発出されたアラート件数の合計／推移',
    en: 'Total alerts issued / trends',
    zhcn: '过去发布的警报总数/数量趋势',
    zhtw: '過去發布的警報總數/發展趨勢',
    ko: '과거에 발생된 경고 건수의 합계 / 추이',
    th: 'รวมการแจ้งเตือนที่ออก / แนวโน้ม',
    vi: 'Tổng số cảnh báo được đưa ra/xu hướng',
  },
  analysisDescription4: {
    ja: '過去に発出されたアラートの拠点別の件数',
    en: 'Number of alerts issued by location',
    zhcn: '按地点划分的警报数量概括',
    zhtw: '過去依位置發布警報的數量',
    ko: '과거에 발생된 경고의 거점별 건수',
    th: 'จำนวนการแจ้งเตือนที่ออกตามสถานที่',
    vi: 'Số lượng cảnh báo được đưa ra theo vị trí',
  },
  analysisDescription5: {
    ja: '過去に発出されたアラートの一覧',
    en: 'Alert list',
    zhcn: '已发生的警报列表',
    zhtw: '過去發出的警報列表',
    ko: '과거에 발생된 경고 목록',
    th: 'รายการแจ้งเตือน',
    vi: 'Danh sách cảnh báo',
  },
  analysisDescription6: {
    ja: '最新の在庫状況を把握し、欠品が出ないよう在庫数および出荷数の管理を実施してください。',
    en:
      'Keep track of the latest inventory status and manage stock levels / shipment quantities to avoid shortages.',
    zhcn: '请及时了解最新库存情况，并管理好库存量/出货量，以防缺货。',
    zhtw: '請隨時掌握最新庫存狀況，管理庫存商品數量和出貨量，避免缺貨。',
    ko: '최신 재고 상황을 파악하고 결품이 나오지 않도록 재고 수량 및 출하 수량을 관리해 주세요.',
    th: 'ติดตามสถานะสินค้าคงคลังล่าสุดและจัดการระดับสต็อก/ปริมาณการจัดส่งเพื่อหลีกเลี่ยงการขาดแคลน',
    vi:
      'Theo dõi tình trạng tồn kho mới nhất và quản lý mức tồn kho/số lượng lô hàng để tránh tình trạng thiếu hụt.',
  },
  analysisDescription6Res: {
    ja: '最新の在庫状況を把握し、欠品が出ないよう販売計画およびオーダーを実施してください。',
    en:
      'Keep track of the latest inventory status and implement sales plans / deliveries to ensure products do not sell out.',
    zhcn: '请及时了解最新库存情况，并落实销售/配送计划，以防缺货。',
    zhtw: '請隨時掌握最新庫存狀況，注意銷售規劃和出貨狀況以避免缺貨。',
    ko: '최신 재고 상황을 파악하고 결품이 나오지 않도록 판매계획 및 발주를 관리해 주세요.',
    th: 'ติดตามสถานะสินค้าคงคลังล่าสุดและใช้แผนการขาย/การส่งมอบเพื่อให้แน่ใจว่าสินค้าไม่ขายหมด',
    vi:
      'Theo dõi tình trạng tồn kho mới nhất và thực hiện kế hoạch bán hàng/giao hàng để đảm bảo sản phẩm không bán hết.',
  },
  analysisDescription7: {
    ja: '各銘柄の販売傾向を確認し、出荷計画を実施してください。',
    en: 'Check sales trends (by brand) and implement shipping / delivery plans',
    zhcn: '检查每个品牌的销售趋势并执行出货/配送计划。',
    zhtw: '確認各品牌的銷售趨勢並執行出貨計劃',
    ko: '각 품목의 판매 경향을 확인하고 출하 계획을 세워주세요.',
    th: 'ตรวจสอบแนวโน้มการขาย (ตามแบรนด์) และดำเนินการตามแผนการจัดส่ง / การจัดส่ง',
    vi: 'Kiểm tra xu hướng bán hàng (theo thương hiệu) và thực hiện kế hoạch vận chuyển/giao hàng',
  },
  analysisDescription7Res: {
    ja: '各銘柄の販売傾向を確認し、販売／入荷計画を実施して下さい。',
    en: 'Check sales trends (by brand) and implement order / sales plans',
    zhcn: '检查每个品牌的销售趋势并执行销售/配送计划。',
    zhtw: '檢查各品牌的銷售趨勢並執行銷售/出貨計劃。',
    ko: '각 품목의 판매 경향을 확인하고 판매 / 입고 계획을 세워주세요.',
    th: 'ตรวจสอบแนวโน้มการขาย (ตามแบรนด์) และดำเนินการตามคำสั่งซื้อ / แผนการขาย',
    vi: 'Kiểm tra xu hướng bán hàng (theo thương hiệu) và thực hiện kế hoạch đặt hàng/bán hàng',
  },
  analysisDescription8: {
    ja:
      'オントレード（ホテル、レストラン、ダイニングバー、会員制クラブなどの飲食店）での銘柄別販売総数',
    en:
      'Total sales (by brand) at on-trade store (hotels, restaurants, dining bars, membership clubs, etc.)',
    zhcn: '餐馆中各品牌销售的单位数量',
    zhtw: '餐廳各品牌的總銷售量',
    ko: '업장에서의 품목별 총 판매 수',
    th:
      'ยอดขายรวม (ตามแบรนด์) ที่ร้านค้าในศูนย์การค้า (โรงแรม ร้านอาหาร บาร์ร้านอาหาร สโมสรสมาชิก ฯลฯ)',
    vi:
      'Tổng doanh số bán hàng (theo thương hiệu) tại cửa hàng thương mại (khách sạn, nhà hàng, quán ăn, câu lạc bộ thành viên, v.v.)',
  },
  analysisDescription9: {
    ja:
      'オントレード（ホテル、レストラン、ダイニングバー、会員制クラブなどの飲食店）での商品販売数の推移',
    en:
      'Trends in sales volumes at on-trade store (hotels, restaurants, dining bars, membership clubs, etc.)',
    zhcn: '餐厅销售产品数量的变化',
    zhtw: '餐廳銷售產品數量的趨勢',
    ko: '업장에서의 상품 판매 수의 추이',
    th: 'แนวโน้มปริมาณการขายที่ร้านค้าในร้านค้า (โรงแรม ร้านอาหาร บาร์ร้านอาหาร สโมสรสมาชิก ฯลฯ)',
    vi:
      'Xu hướng về doanh số bán hàng tại cửa hàng thương mại (khách sạn, nhà hàng, quán ăn, câu lạc bộ thành viên, v.v.)',
  },
  analysisDescription10: {
    ja:
      '指定期間において、オントレード（ホテル、レストラン、ダイニングバー、会員制クラブなどの飲食店）に１商品が入荷してから販売されるまでに必要な平均日数（数値が小さいほど販売まで早い）',
    en:
      'The average number of days required for a product to be sold after it arrives at an on-trade store (hotels, restaurants, dining bars, membership clubs, etc.) during a specified period (the smaller the number, the faster the product is sold)',
    zhcn:
      '在一段时间内，产品到达酒店，餐厅，餐吧，会员制俱乐部等场所后被销售掉的平均天数。（数字越小，产品被销售掉的速度越快）',
    zhtw:
      '產品送達餐廳（飯店、餐廳、餐酒館、會員俱樂部等）後的指定時間內，銷售所需的平均天數（數字越小，銷售速度越快）',
    ko:
      '지정 기간에 업장(호텔, 레스토랑, 다이닝바, 회원제 클럽 등의 음식점)에 한 상품이 입고된 후 판매되기까지 필요한 평균 일수 (수치가 작을수록 판매 소요 기간이 빠름)',
    th:
      'จำนวนวันโดยเฉลี่ยที่จำเป็นสำหรับการขายผลิตภัณฑ์หลังจากมาถึงร้านค้าที่ซื้อขายตามตลาด (โรงแรม ร้านอาหาร บาร์ร้านอาหาร สโมสรสมาชิก ฯลฯ) ในช่วงเวลาที่กำหนด (ยิ่งจำนวนน้อย สินค้าก็จะยิ่งเร็วขึ้นเท่านั้น) ขายแล้ว)',
    vi:
      'Số ngày trung bình cần thiết để một sản phẩm được bán sau khi nó đến một cửa hàng thương mại (khách sạn, nhà hàng, quán ăn, câu lạc bộ thành viên, v.v.) trong một khoảng thời gian nhất định (số càng nhỏ thì sản phẩm càng nhanh đã bán)',
  },
  analysisDescription11: {
    ja: 'アラートを発出した拠点（ディストリビューターおよびオントレード）の軒数推移',
    en: 'Trends in the number of locations (distributors / on-trade store) with alerts',
    zhcn: '发布警报的地点（经销商和餐厅）数量的变化',
    zhtw: '發出警報的地點（經銷商和餐廳）的數量變化',
    ko: '경고 발생 거점(수입사 및 업장)의 건수 추이',
    th: 'แนวโน้มจำนวนสถานที่ตั้ง (ผู้จัดจำหน่าย / ร้านค้าออนเทรด) พร้อมการแจ้งเตือน',
    vi: 'Xu hướng về số lượng địa điểm (nhà phân phối / cửa hàng thương mại) có cảnh báo',
  },
  analysisDescription12: {
    ja: '個人顧客への銘柄別販売総数',
    en: 'Number of bottles sold to private customers / retail',
    zhcn: '直接销售给私人客户的总瓶数',
    zhtw: '直客的總銷售量',
    ko: '개인 고객에게 판매한 품목별 총 판매 수',
    th: 'จำนวนขวดที่จำหน่ายให้กับลูกค้าเอกชน/ขายปลีก',
    vi: 'Số chai bán cho khách hàng cá nhân/bán lẻ',
  },
  analysisDescription13: {
    ja:
      '販売実績のあるオントレード（ホテル、レストラン、ダイニングバー、会員制クラブなどの飲食店）軒数の銘柄別推移',
    en:
      'Trends in the number of on-trade store (hotels, restaurants, dining bars, membership clubs, etc.)  selling products (by brand)',
    zhcn: '餐厅按品牌划分的销售业绩变化',
    zhtw: '餐廳銷售各產品的數量趨勢',
    ko: '품목별 판매 실적이 있는 업장 수의 추이',
    th:
      'แนวโน้มจำนวนร้านค้าในร้านค้า (โรงแรม ร้านอาหาร บาร์ร้านอาหาร สโมสรสมาชิก ฯลฯ) ที่ขายสินค้า (ตามแบรนด์)',
    vi:
      'Xu hướng số lượng cửa hàng thương mại (khách sạn, nhà hàng, quán ăn, câu lạc bộ thành viên, v.v.) bán sản phẩm (theo nhãn hiệu)',
  },
  analysisDescription14: {
    ja:
      'ディストリビューターが保有する在庫および選択されたオントレード（ホテル、レストラン、ダイニングバー、会員制クラブなどの飲食店）が保有する銘柄別の在庫総数',
    en:
      'Total inventory held by distributors / on-trade store (hotels, restaurants, dining bars, membership clubs, etc.) ',
    zhcn: '分销商和用户选择的餐厅持有的库存瓶数',
    zhtw: '分銷商和用戶選擇的餐廳持有的庫存瓶數',
    ko: '디스트리뷰터와 선택된 레스토랑이 보유한 재고 수',
    th:
      'สินค้าคงคลังทั้งหมดที่ถือครองโดยผู้จัดจำหน่าย / ร้านค้าออนเทรด (โรงแรม ร้านอาหาร บาร์ร้านอาหาร สโมสรสมาชิก ฯลฯ)',
    vi:
      'Tổng hàng tồn kho được nắm giữ bởi các nhà phân phối / cửa hàng thương mại (khách sạn, nhà hàng, quán ăn, câu lạc bộ thành viên, v.v.)',
  },
  analysisDescription15: {
    ja: 'ディストリビューターが保有する銘柄別の在庫数',
    en: 'Inventory held by distributors',
    zhcn: '分销商持有的库存',
    zhtw: '經銷商持有的庫存數量',
    ko: '수입사가 보유한 품목별 재고 수',
    th: 'สินค้าคงคลังที่ถือโดยผู้จัดจำหน่าย',
    vi: 'Hàng tồn kho do nhà phân phối nắm giữ',
  },
  analysisDescription16: {
    ja:
      '選択されたオントレード（ホテル、レストラン、ダイニングバー、会員制クラブなどの飲食店）が保有する銘柄別在庫数',
    en:
      'Inventory held by selected on-trade store (hotels, restaurants, dining bars, membership clubs, etc.) ',
    zhcn: '所选餐厅持有的品牌库存数量',
    zhtw: '所选餐厅持有的品牌库存数量',
    ko: '선택한 레스토랑이 보유한 종목별 재고 수',
    th: 'สินค้าคงคลังที่จัดขึ้นโดยร้านค้าที่เลือก (โรงแรม ร้านอาหาร บาร์ร้านอาหาร สโมสรสมาชิก ฯลฯ)',
    vi:
      'Khoảng không quảng cáo do cửa hàng thương mại chọn lọc nắm giữ (khách sạn, nhà hàng, quán ăn, câu lạc bộ thành viên, v.v.)',
  },
  analysisDescription17: {
    ja: '各ディストリビューターが保有する銘柄別の在庫総数',
    en: 'Total inventory (by brand) held by distributors',
    zhcn: '各经销商按品牌持有的库存总数',
    zhtw: '各經銷商按品牌持有的庫存總數',
    ko: '각 수입사가 보유한 품목별 총 재고 수',
    th: 'สินค้าคงคลังทั้งหมด (ตามแบรนด์) ที่ถือครองโดยผู้จัดจำหน่าย',
    vi: 'Tổng hàng tồn kho (theo thương hiệu) do nhà phân phối nắm giữ',
  },
  cardNoteBrew1: {
    ja:
      '*1 アラート総数：ディストリビューターおよびオントレード（ホテル、レストラン、ダイニングバー、会員制クラブなどの飲食店）で発生しているアラートの総数',
    en:
      '*1 Total number of alerts : Total number of alerts at distributors and on-trade store (hotels, restaurants, dining bars, membership clubs, etc.)',
    zhcn: '*1 警报总数：分销商和餐厅生成的警报总数',
    zhtw: '*1 警報總數：由經銷商和餐廳發布的警報總數',
    ko: '*1 총 경고 건수 : 수입사와 업장에서 발생된 총 경고 수',
    th:
      '*1 จำนวนการแจ้งเตือนทั้งหมด : จำนวนการแจ้งเตือนทั้งหมดที่ผู้จัดจำหน่ายและร้านค้าออนเทรด (โรงแรม ร้านอาหาร บาร์ร้านอาหาร สโมสรสมาชิก ฯลฯ)',
    vi:
      '*1 Tổng số cảnh báo : Tổng số cảnh báo tại nhà phân phối và cửa hàng thương mại (khách sạn, nhà hàng, quán ăn, câu lạc bộ thành viên, v.v.)',
  },
  cardNoteBrew2: {
    ja:
      '*2 関与ディストリビューター数：管理下にあるオントレードでアラートが発生している、もしくは自身の拠点でアラートが発生しているディストリビューター数',
    en:
      '*2 Total distributors: Number of distributors with alerts within their market or at their locations.',
    zhcn: '*2 分销商总数：在其市场或其所在地点发出警报的分销商数量',
    zhtw: '"*2 經銷商警報總數：在客戶餐廳或在自己的營業據點發出的警報"',
    ko:
      '*2 관련된 수입사 수 : 관리 중인 업장에서 경고가 발생하거나 자신의 거점에서 경고가 발생된 수입사 수',
    th:
      '*2 ผู้จัดจำหน่ายทั้งหมด: จำนวนผู้จัดจำหน่ายที่มีการแจ้งเตือนภายในตลาดหรือที่สถานที่ตั้งของตน',
    vi:
      '*2 Tổng số nhà phân phối: Số lượng nhà phân phối có cảnh báo trong thị trường hoặc tại địa điểm của họ.',
  },
  mobileDescription: {
    ja: '　*ヘッダーの矢印をタップして並び替え可能',
    en: '　*Data can be sorted by tapping the arrow in the header.',
    zhcn: '　*可以通过点击标题中的箭头对数据进行排序',
    zhtw: '　*可以通過點擊標題中的箭頭對數據進行排序',
    ko: '　*헤더의 화살표를 탭하여 데이터 재정렬 가능',
    th: '*สามารถจัดเรียงข้อมูลได้โดยการแตะลูกศรในส่วนหัว',
    vi: '*Dữ liệu có thể được sắp xếp bằng cách nhấn vào mũi tên trong tiêu đề.',
  },
  piece: {
    ja: '件',
    en: 'piece',
    zhcn: '块',
    zhtw: '塊',
    ko: '건',
    th: 'ชิ้น',
    vi: 'mảnh',
  },
  bottleToBeDelivered: {
    ja: '入荷予定のボトル',
    en: 'Bottles to be delivered',
    zhcn: '待交付的瓶子',
    zhtw: '待交付的瓶子',
    ko: '입하 예정의 병',
    th: 'จัดส่งแบบขวดครับ',
    vi: 'Chai sẽ được giao',
  },
  none: {
    ja: 'なし',
    en: 'None',
    zhcn: '没有任何',
    zhtw: '沒有任何',
    ko: '없음',
    th: 'ไม่มี',
    vi: 'Không có',
  },
  shippedFrom: {
    ja: '出荷元',
    en: 'Shipped from',
    zhcn: '从',
    zhtw: '從',
    ko: '출하원',
    th: 'ส่งมาจาก',
    vi: 'Chuyển từ',
  },
  number: {
    ja: '本数',
    en: 'Number',
    zhcn: '数字',
    zhtw: '數字',
    ko: '개수',
    th: 'ตัวเลข',
    vi: 'Con số',
  },
  shippingDate: {
    ja: '出荷日',
    en: 'Shipping date',
    zhcn: '发货日期',
    zhtw: '發貨日期',
    ko: '출하 일',
    th: 'วันที่จัดส่ง',
    vi: 'Ngày vận chuyển',
  },
  bottles: {
    ja: ' 本',
    en: ' bottle(s)',
    zhcn: ' 瓶',
    zhtw: ' 瓶',
    ko: ' 병',
    th: 'ขวด',
    vi: '(Các) chai',
  },
});

function LangSettings<T extends { [key: string]: { [key in TSupportedLanguages] } }>(arg: T): T {
  return arg;
}
