import TabPanel, { a11yProps } from '@elements/TabPanel';
import { i18n } from '@i18n/lang';
import { AppBar, Box, Grid, Tab, Tabs, Theme, Toolbar } from '@material-ui/core';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import { AllPages } from '@models/all-pages';
import { ECategoryType } from '@models/category-type';
import { IStateApps } from '@models/state-apps';
import { IStateServers } from '@models/state-servers';
import { setCurrentPage } from '@redux/actions/appsActions';
import { IStoreState } from '@redux/reducers';
import { LIMIT_PAGINATION_MAX } from '@utils/default-query';
import { getUserCategory } from '@utils/get-user-category';
import { History, LocationState } from 'history';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import userEnv from 'userEnv';
import { AlertManagement } from './alertManagement';
import { StockManagement } from './stockManagement';
import styles from './style.scss';

class ChartsClass extends React.PureComponent<Props, State> {
  private basePath;
  public pages = {
    offset: 0,
    limit: LIMIT_PAGINATION_MAX,
  };

  constructor(props) {
    super(props);
    this.basePath = `${userEnv.rootDir}charts/`;

    this.state = {
      setTab: 0,
      windowSize: {
        width: 0,
        height: 0,
      },
    };
  }

  handleChange(e, tabValue) {
    window.scrollTo(0, 0);
    this.setState({ setTab: tabValue });
  }

  getCurrentIndex() {
    const { setTab } = this.state;
    if (setTab) return setTab;
    else return 0;
  }

  public async componentDidMount() {
    try {
      //let locationsList;
      setCurrentPage('charts');
    } catch (err) {
      console.log(err, '-----error-----');
    }

    // get window size used for controling graph size
    //this.handleWindowResize();
    //window.addEventListener('resize', this.handleWindowResize);
  }

  /* functions for getting window size */
  /*
  ref = React.createRef() as any;

  handleWindowResize = _.debounce(() => {
    const windowSize = this.ref.current.getBoundingClientRect();
    this.setState({ windowSize });
  });

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }
  */

  render(): JSX.Element {
    const { apps, servers, classes } = this.props;
    const { user } = servers;
    const category = getUserCategory(user);
    const tabValue = this.getCurrentIndex();
    const lang = apps.currentLanguage;

    switch (category) {
      case ECategoryType.ADMIN: // 未指定
      case ECategoryType.BREWERY: // 酒蔵
      case ECategoryType.DISTRIBUTOR: // ディストリビュータ
      case ECategoryType.RESTAURANT: // レストラン
        return (
          <>
            <div className={this.props.classes.toolbar} />
            <AppBar position='sticky' className={this.props.classes.appBar}>
              <Toolbar>
                <Tabs
                  value={tabValue}
                  onChange={(e, v) => this.handleChange(e, v)}
                  aria-label='main-tabs'
                  variant='scrollable'
                >
                  <Tab
                    className={classes.noneTextTransform}
                    label={`${i18n.qualityMonitoring[lang]}`}
                    {...a11yProps(0)}
                  />
                  <Tab
                    className={classes.noneTextTransform}
                    label={`${i18n.shipChange[lang]}`}
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Toolbar>
            </AppBar>
            <div className={styles.container}>
              <TabPanel value={tabValue} index={0}>
                {tabValue === 0 ? (
                  <>
                    <Grid
                      container
                      justify-content='center'
                      alignContent='center'
                      alignItems='center'
                    >
                      <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                        {/** <>
                          {enableFilter ? <Box mt={1} /> : ''}
                          <Paper
                            elevation={enableFilter ? 4 : 0}
                            className={enableFilter ? classes.paper : classes.none}
                          >
                            {getFilterButton(
                              enableFilter,
                              () =>
                                this.setState({ enableFilter: !enableFilter }, () => {
                                  window.scrollTo(0, 0);
                                }),
                              lang,
                            )}
                            {enableFilter ? monitoringFilterView : ''}
                          </Paper>
                          {enableFilter ? <Box mt={1} /> : ''}
                        </>*/}

                        <div className={styles.cardIndex}>
                          <AlertManagement />
                        </div>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Box />
                )}
              </TabPanel>

              <TabPanel value={tabValue} index={1}>
                {tabValue === 1 ? (
                  <>
                    <Grid
                      container
                      justify-content='center'
                      alignContent='center'
                      alignItems='center'
                    >
                      <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                        {/** 
                        <>
                          {enableFilter ? <Box mt={1} /> : ''}
                          <Paper
                            elevation={enableFilter ? 4 : 0}
                            className={enableFilter ? classes.paper : classes.none}
                          >
                            {getFilterButton(
                              enableFilter,
                              () =>
                                this.setState({ enableFilter: !enableFilter }, () => {
                                  window.scrollTo(0, 0);
                                }),
                              lang,
                            )}
                            {enableFilter ? monitoringFilterView : ''}
                          </Paper>
                          {enableFilter ? <Box mt={1} /> : ''}
                        </>
                        */}
                        <div className={styles.cardIndex}>
                          <StockManagement />
                        </div>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Box />
                )}
              </TabPanel>
            </div>
          </>
        );
      default:
        return <></>;
    }
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  history: History<LocationState>;
  classes: any;
  setTab: number;
  mobileOpen?: boolean;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

export interface State {
  setTab: number;
  mobileOpen?: boolean;
  windowSize: {
    width: number;
    height: number;
  };
}

export interface IDispatchProps {
  setCurrentPage: (pageId: AllPages) => void;
}

const mapDispatchToProps: IDispatchProps = {
  setCurrentPage,
};

const myStyles = (theme: Theme): StyleRules => ({
  content: { width: '100%', marginLeft: 'auto', marginRight: 'auto' },
  toolbar: { paddingTop: '68px' },
  noneTextTransform: { textTransform: 'none', fontSize: '17px' },
  appBar: { marginTop: '64px', backgroundColor: '#15355c', flexDirection: 'row' },
  card: {
    padding: theme.spacing(2),
    textAlign: 'start',
    color: theme.palette.text.secondary,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

export const Charts = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(ChartsClass));
