import { EAlertStatus } from '@models/alert-status-type';

export function getPreviousStatus(status: EAlertStatus): EAlertStatus {
  if (!status) return;
  switch (status) {
    /*case EAlertStatus.APPROVED:
      return EAlertStatus.PROCESSED;*/
    /*case EAlertStatus.UNPROCESSED:
    case EAlertStatus.PROCESSED:
      return EAlertStatus.UNPROCESSABLE;*/
    case EAlertStatus.APPROVED:
    case EAlertStatus.PROCESSED:
      return EAlertStatus.UNPROCESSED;
    case EAlertStatus.UNPROCESSABLE:
      return EAlertStatus.UNPROCESSED;
  }
}
