import { TextDisplay } from '@visual/text-display';
import hash from 'object-hash';
import React from 'react';
import styles from './style.scss';

export class NestedTable extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { columns, rows } = this.props;
    if (!columns || !rows) return <React.Fragment />;
    return (
      <div className={styles.nestedTableContainer}>
        <table>
          <thead>{this.getColumnHeaders()}</thead>
          <tbody>{this.getRows()}</tbody>
        </table>
      </div>
    );
  }

  protected getColumnHeaders(): JSX.Element {
    const { columns } = this.props;
    if (!columns) return;
    return (
      <tr className={styles.row}>
        {columns.map((column, i) => (
          <td key={hash(i)} className={`${styles.cell} ${styles.headerCell}`}>
            <TextDisplay size='small'>{column}</TextDisplay>
          </td>
        ))}
      </tr>
    );
  }

  protected getRows(): JSX.Element[] {
    const { rows } = this.props;
    if (!rows) return;

    return rows.map((row, i) => {
      const { data } = row;
      return (
        <tr key={hash(i)} className={styles.row}>
          {data.map((data, j) => (
            <td key={hash(`${i}-${j}`)} className={styles.cell}>
              <TextDisplay size='small'>{data}</TextDisplay>
            </td>
          ))}
        </tr>
      );
    });
  }
}

export type Props = IStateProps;

export interface IStateProps {
  columns: string[];
  rows: { data: (string | number)[] }[];
}
