import { i18n } from '@i18n/lang';
import { TAlertStatus } from '@models/alert-status-type';
import { TSupportedLanguages } from '@models/supported-languages';
import { LANGUAGE_DEFAULT, LANGUAGE_ENGLISH } from '@utils/common';
import { Icon } from '@visual/icon';
import {
  TColors as TextDisplayColors,
  TextDisplay,
  TSizes as TextDisplaySizes,
} from '@visual/text-display';
import React from 'react';
import styles from './style.scss';

export class AlertStatusPill extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    lang: LANGUAGE_DEFAULT,
    isUnselected: false,
    theme: 'pill',
  };

  public render(): JSX.Element {
    const { type, lang, isUnselected, onClick, theme } = this.props;
    const useSmallFont = lang === LANGUAGE_ENGLISH;
    const toggleCheck = theme === 'toggle' && (
      <Icon
        style={{ paddingRight: '2px' }}
        size={useSmallFont ? 'x-small' : 'small'}
        type='check'
        color={isUnselected ? 'dark4' : 'white'}
      />
    );
    let statusTextColor: TextDisplayColors = isUnselected ? 'secondary-light2' : 'default';
    let textDisplaySize: TextDisplaySizes = 'small';

    switch (theme) {
      case 'toggle':
        statusTextColor = isUnselected ? 'dark2' : 'default';
        textDisplaySize = useSmallFont ? 'xx-small' : 'small';
        break;
      case 'big-pill':
        textDisplaySize = 'large';
        break;
      default:
        break;
    }

    return (
      <div className={this.getPillStyle()} onClick={onClick}>
        {toggleCheck}
        <TextDisplay
          size={textDisplaySize}
          weight='bold'
          color={statusTextColor}
          truncate='ellipsis'
        >
          {i18n[type][lang]}
        </TextDisplay>
      </div>
    );
  }

  protected getPillStyle() {
    const { type, isUnselected, onClick, theme } = this.props;
    return [
      styles.alertStatusPill,
      styles[theme],
      isUnselected ? styles.unselected : styles[type],
      onClick && styles.clickable,
    ]
      .filter((style) => style)
      .join(' ');
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  type: TAlertStatus;
  lang?: TSupportedLanguages;
  isUnselected?: boolean;
  theme?: Theme;
  count?: number;
}

export type Theme = 'pill' | 'big-pill' | 'toggle';

export interface IDispatchProps {
  onClick?: () => void;
}
