import Snackbar from '@material-ui/core/Snackbar';
import { IStateApps } from '@models/state-apps';
import { closeSnackbarMessage } from '@redux/actions/appsActions';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { MySnackbarContentWrapper } from './MySnackbarContentWrapper';

class SnackbarMessageClass extends React.PureComponent<Props> {
  render() {
    const { apps, closeSnackbarMessage } = this.props;
    const autoHideDuration = 6000;
    /* success / warning / error / info */
    return (
      apps.snackbarMessage && (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={apps.isOpenSnackbarMessage}
          autoHideDuration={autoHideDuration}
          onClose={closeSnackbarMessage}
        >
          <MySnackbarContentWrapper
            className=''
            onClose={closeSnackbarMessage}
            variant={apps.snackbarMessageFormat}
            message={apps.snackbarMessage}
          />
        </Snackbar>
      )
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
}

export interface IDispatchProps {
  closeSnackbarMessage: () => void;
}

const mapStateToProps = (state) => ({
  apps: state.apps,
});

const mapDispatchToProps = {
  closeSnackbarMessage,
};

export const SnackbarMessage = compose<any>(connect(mapStateToProps, mapDispatchToProps))(
  SnackbarMessageClass,
);
