import { i18n } from '@i18n/lang';
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Slider,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  CameraAlt as CameraAltIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Clear as ClearIcon,
  Close as CloseIcon,
  CloudUpload as CloudUploadIcon,
  Comment as CommentIcon,
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon,
  ErrorOutline as ErrorOutlineIcon,
  FilterList as FilterListIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  LibraryAdd as LibraryAddIcon,
  LibraryBooks as LibraryBooksIcon,
  Search as SearchIcon,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ExportToCsv } from 'export-to-csv';
import { useConfirm } from 'material-ui-confirm';
import React from 'react';
import { DateRangePicker } from 'rsuite';
import userEnv from 'userEnv';
import { formatString } from 'utils/format-string';
import { store } from '../redux/reducers';
import appLanguages from './app-languages';

export const BAIDU_ONLY_COUNTRIES = ['CN'];

export const SHANGHAI_COORDINATES = { lat: 121.48, lng: 31.236 };

export const TOKYO_COORDINATES = { lat: 35.681236, lng: 139.767125 };

export const HONGKONG_COORDINATES = { lat: 22.302711, lng: 114.177216 };

export const DEBOUNCE_TIMEOUT = 100;

export const LANGUAGE_JAPANESE = 'ja';

export const LANGUAGE_ENGLISH = 'en';

export const LANGUAGE_CHINESE = 'zhcn';

export const LANGUAGE_CHINESE_TRADITIONAL = 'zhtw';

export const LANGUAGE_KOREAN = 'ko';

export const LANGUAGE_THAI = 'th';

export const LANGUAGE_VIETAMESE = 'vi';

// Sreenath28Sept2021- Change Default Language
export const LANGUAGE_DEFAULT = 'en';

export const SCAN_DUE_DATE_DEFAULT = 24; // Hours

export const SCAN_QUALITY_DATE_DEFAULT = 60; // Days

export const NUMERIC_MIN = 1;

export const NUMERIC_MAX = 999999999999;

export const SERIAL_DIGIT = 5; //7 #Duval04Oct2021 - change back to 5 digit serials

export const materialTableLocalization = (lang = LANGUAGE_DEFAULT) => ({
  pagination: { labelDisplayedRows: '{from}-{to} of {count}' },
  toolbar: { nRowsSelected: '{0} row(s) selected' },
  header: { actions: '' },
  body: {
    emptyDataSourceMessage: (
      <Typography variant='body2' color='textSecondary'>
        {appLanguages.textLabelsBodyNoMatch[lang]}
      </Typography>
    ),
    filterRow: { filterTooltip: appLanguages.narrowDown[lang] },
    editRow: {
      cancelTooltip: appLanguages.cancel[lang],
      saveTooltip: appLanguages.register[lang],
      deleteText: (
        <Typography variant='subtitle1' color='textSecondary' component='span'>
          {appLanguages.deleteConfirmation[lang]}
        </Typography>
      ),
    },
    addTooltip: appLanguages.add[lang],
    deleteTooltip: appLanguages.delete[lang],
    editTooltip: appLanguages.edit[lang],
  },
});

export const isString = (v) => Boolean(v && (typeof v === 'string' || v instanceof String));

export const isNum = (v) => !isNaN(parseInt(v));

export const getYears = (lang = LANGUAGE_DEFAULT) => {
  const startYear = new Date().getFullYear(); // サービス開始年
  const currentYear = new Date().getFullYear(); // 現在の年
  const years = [];
  for (let y = startYear; y <= currentYear; y += 1)
    years.push({ id: y, name: `${y}${appLanguages.yearShort[lang]}` });
  return years;
};

export const formatGADate = (date) => {
  let format = 'YYYY-MM-DD';
  format = format.replace(/YYYY/g, date.getFullYear());
  format = format.replace(/MM/g, `0${date.getMonth() + 1}`.slice(-2));
  format = format.replace(/DD/g, `0${date.getDate()}`.slice(-2));
  return format;
};

export const toLatLngObj = (latLngStr) => {
  if (!isString(latLngStr)) return {};
  const latLng = latLngStr.split(',');
  if (latLng.length !== 2) return {};
  const lat = latLng[0];
  const lng = latLng[1];
  if (!isNum(lat) || !isNum(lng)) return {};
  return { lat: parseFloat(lat), lng: parseFloat(lng) };
};

export const exportToCSV = (filenamePrefix, objects) => {
  const filename = `${filenamePrefix}${new Date().toLocaleDateString().replace(/\//g, '_')}`;
  const csvExporter = new ExportToCsv({
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename,
  });
  csvExporter.generateCsv(objects);
};

export const nowrap = (label) => (
  <Typography variant='body2' noWrap>
    {label}
  </Typography>
);

export const nowrapHeader = (label) => (
  <Typography variant='body2' color='textSecondary' noWrap>
    {label}
  </Typography>
);

export const nowrapSubHeader = (label) => (
  <Typography variant='caption' color='textSecondary' noWrap>
    {label}
  </Typography>
);

export function nowrapBody(label) {
  return label ? (
    <Typography variant='body2' color='textPrimary' noWrap>
      {label}
    </Typography>
  ) : (
    <Typography variant='body2' color='textSecondary' noWrap>
      -
    </Typography>
  );
}

export const __nowrapHeader = (label) => label;

export const withConfirm = (Component) => (props) => (
  <Component confirm={useConfirm()} {...props} />
);

export const getDetailTitle = (title) => (
  <Grid container>
    <Grid item>
      <LibraryBooksIcon fontSize='inherit' />
    </Grid>
    <Grid item>
      <Box ml={1} />
    </Grid>
    <Grid item>
      <Typography variant='subtitle1'>{title}</Typography>
    </Grid>
  </Grid>
);

export const getEditTitle = (title) => (
  <Grid container>
    <Grid item>
      <EditIcon fontSize='inherit' />
    </Grid>
    <Grid item>
      <Box ml={1} />
    </Grid>
    <Grid item>
      <Typography variant='subtitle1'>{title}</Typography>
    </Grid>
  </Grid>
);

export const getAddTitle = (title) => (
  <Grid container>
    <Grid item>
      <LibraryAddIcon fontSize='inherit' />
    </Grid>
    <Grid item>
      <Box ml={1} />
    </Grid>
    <Grid item>
      <Typography variant='subtitle1'>{title}</Typography>
    </Grid>
  </Grid>
);

export const getSmallSlider = (label, value, min, max, disabled, onChange) => (
  <>
    <Typography variant='caption' color='textSecondary' gutterBottom>
      {label}
    </Typography>
    <Slider
      disabled={disabled}
      value={value}
      step={100}
      min={min}
      max={max}
      onChange={onChange}
      valueLabelDisplay='auto'
    />
  </>
);

export const getSmallTextField = (label, value, disabled, onChange) => (
  <TextField
    fullWidth
    size='small'
    variant='standard'
    label={label}
    value={value}
    onChange={onChange}
    margin='dense'
    disabled={disabled}
  />
);

export const getSmallTextField2 = (label, value, disabled, onChange) => (
  <TextField
    fullWidth
    size='small'
    variant='standard'
    label={
      <Typography variant='subtitle2' noWrap>
        {label}
      </Typography>
    }
    value={value}
    onChange={onChange}
    margin='dense'
    disabled={disabled}
  />
);

export const getSmallSearchField = (label, value, disabled, onChange) => (
  <TextField
    fullWidth
    size='small'
    variant='filled'
    label={label}
    value={value}
    onChange={onChange}
    margin='dense'
    disabled={disabled}
    InputProps={{
      endAdornment: (
        <InputAdornment position='end'>
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />
);

export const getSmallTextFieldForNumber = (label, value, disabled, onChange) => (
  <TextField
    fullWidth
    size='small'
    variant='standard'
    label={
      <Typography variant='subtitle2' noWrap>
        {label}
      </Typography>
    }
    value={value}
    onChange={onChange}
    type='number'
    margin='dense'
    disabled={disabled}
  />
);

export const getSmallTextFieldForFilterMaxMin = (
  minLabel,
  maxLabel,
  minValue,
  maxValue,
  minDisable,
  maxDisable,
  minOnChange,
  maxOnChange,
) => (
  <>
    <Grid container alignItems='flex-end'>
      <Grid item>{getSmallTextFieldForNumber(minLabel, minValue, minDisable, minOnChange)}</Grid>
      <Grid item>
        <Box ml={0.5} mr={0.5}>
          <Typography variant='subtitle1' color='textSecondary'>
            {' 〜 '}
          </Typography>
        </Box>
      </Grid>
      <Grid item>{getSmallTextFieldForNumber(maxLabel, maxValue, maxDisable, maxOnChange)}</Grid>
    </Grid>
  </>
);

export const getSmallSelect = (label, value, options, disabled, onChange) => (
  <TextField
    select
    fullWidth
    variant='standard'
    size='small'
    label={label}
    value={value}
    onChange={onChange}
    margin='dense'
    disabled={disabled}
    style={{ minWidth: '210px' }}
  >
    {options.map((o) => (
      <MenuItem key={o.id} value={o.id}>
        {o.name}
      </MenuItem>
    ))}
  </TextField>
);

export const getTextField = (label, value, disabled, onChange) => (
  <TextField
    fullWidth
    label={label}
    value={value}
    onChange={onChange}
    margin='dense'
    disabled={disabled}
    autoComplete='off'
  />
);

export const getNumericTextField = (label, value, disabled, onChange) => (
  <TextField
    fullWidth
    label={label}
    value={value}
    onChange={onChange}
    margin='dense'
    disabled={disabled}
    type='number'
    InputProps={{
      inputProps: {
        min: 0,
      },
    }}
  />
);

export const getTextField2 = (label, value, disabled, onChange) => (
  <TextField
    fullWidth
    label={label}
    value={value}
    onChange={onChange}
    variant='outlined'
    margin='dense'
    disabled={disabled}
  />
);

export const getTextFieldWithError = (
  label,
  value,
  disabled,
  onChange,
  error = false,
  helperText = '',
) => (
  <TextField
    fullWidth
    label={label}
    value={value}
    onChange={onChange}
    error={error}
    helperText={helperText}
    disabled={disabled}
  />
);

export const getTextFieldForNumber = (label, value, disabled, onChange) => (
  <TextField
    fullWidth
    label={label}
    value={value}
    onChange={onChange}
    margin='dense'
    type='number'
    disabled={disabled}
  />
);

export const getTextFieldForPassword = (
  label,
  value,
  disabled,
  onChange,
  showPassword,
  toggleShowPassword,
) => (
  <TextField
    fullWidth
    label={label}
    value={value}
    onChange={onChange}
    margin='dense'
    type={showPassword ? 'text' : 'password'}
    disabled={disabled}
    InputProps={
      toggleShowPassword && {
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              tabIndex={-1}
              aria-label='toggle password visibility'
              onClick={toggleShowPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }
    }
  />
);

export const getTextFieldMultiline = (label, value, disabled, onChange) => (
  <TextField
    fullWidth
    label={label}
    value={value}
    onChange={onChange}
    margin='dense'
    multiline
    rows={1}
    rowsMax={5}
    disabled={disabled}
  />
);

export const getTextFieldMultiline2 = (label, value, disabled, onChange) => (
  <TextField
    fullWidth
    label={label}
    value={value}
    onChange={onChange}
    margin='dense'
    variant='outlined'
    multiline
    rows={1}
    rowsMax={3}
    disabled={disabled}
  />
);

export const getAutocomplete = (label, value, options, disabled, onChange) => (
  <Autocomplete
    value={value}
    options={options}
    getOptionLabel={(o) => (o ? o.name || '' : '')}
    onChange={onChange}
    getOptionSelected={(opt, v) => String(opt.id) === String(v.id)}
    disabled={disabled}
    renderInput={(p) => (
      <TextField
        {...p}
        label={label}
        margin='normal'
        fullWidth
        style={{ minWidth: '180px' }}
        overflox-x='hidden'
      />
    )}
  />
);

export const getAutocompleteFreeSolo = (label, value, options, disabled, onChange, onBlur) => (
  <Autocomplete
    freeSolo
    value={value}
    options={options}
    onChange={onChange}
    disabled={disabled}
    renderInput={(p) => (
      <TextField {...p} label={label} margin='normal' onBlur={onBlur} fullWidth />
    )}
  />
);

export const getImageFileUploadButton = (
  id,
  label,
  inputClassName,
  buttonClassName,
  disabled,
  onChange,
) => (
  <label htmlFor={id}>
    <input
      accept='image/*'
      className={inputClassName}
      id={id}
      multiple
      type='file'
      onChange={onChange}
      disabled={disabled}
    />
    <Button
      size='small'
      variant='contained'
      component='span'
      className={buttonClassName}
      disabled={disabled}
      color='primary'
    >
      <Box ml={0.5} mr={0.5}>
        <CameraAltIcon />
      </Box>
      <Box ml={0.5} mr={0.5}>
        {label}
      </Box>
    </Button>
  </label>
);

export const getCSVFileUploadButton = (
  id,
  label,
  inputClassName,
  buttonClassName,
  disabled,
  onChange,
) => (
  <label htmlFor={id}>
    <input
      accept='text/csv'
      className={inputClassName}
      id={id}
      multiple
      type='file'
      onChange={onChange}
      disabled={disabled}
    />
    <Button
      size='small'
      variant='contained'
      component='span'
      className={buttonClassName}
      disabled={disabled}
      color='primary'
    >
      <Box ml={0.5} mr={0.5}>
        <CloudUploadIcon />
      </Box>
      <Box ml={0.5} mr={0.5}>
        {label}
      </Box>
    </Button>
  </label>
);

export const getFileDeleteButton = (label, className, disabled, onClick) => (
  <Button
    size='small'
    variant='contained'
    className={className}
    disabled={disabled}
    color='default'
    onClick={onClick}
    style={{ margin: '0px 5px' }}
  >
    <DeleteForeverIcon />
    <Box ml={1} />
    {label}
  </Button>
);

export const getCheckbox = (label, checked, onChange) => (
  <FormControlLabel control={<Checkbox checked={checked} onChange={onChange} />} label={label} />
);

export const getSmallCheckboxSecondary = (label, onClick, className = '') => (
  <Button
    size='small'
    variant='text'
    className={className}
    startIcon={<CheckBoxIcon color='secondary' />}
    color='secondary'
    onClick={onClick}
  >
    <Typography variant='inherit' color='secondary' noWrap>
      {label}
    </Typography>
  </Button>
);

export const getSmallCheckboxTextSecondary = (label, onClick, className = '') => (
  <Button
    size='small'
    variant='text'
    className={className}
    startIcon={<CheckBoxOutlineBlankIcon color='inherit' />}
    color='default'
    onClick={onClick}
  >
    <Typography variant='inherit' color='textSecondary' noWrap>
      {label}
    </Typography>
  </Button>
);

export const __getSmallCheckboxSecondary = (label, checked, onChange, className = '') => (
  <FormControlLabel
    control={<Checkbox size='small' color='secondary' checked={checked} onChange={onChange} />}
    label={
      <Typography variant='subtitle2' color='secondary' noWrap>
        {label}
      </Typography>
    }
    className={className}
  />
);

export const __getSmallCheckboxTextSecondary = (label, checked, onChange, className = '') => (
  <FormControlLabel
    control={<Checkbox size='small' color='default' checked={checked} onChange={onChange} />}
    label={
      <Typography variant='subtitle2' color='textSecondary' noWrap>
        {label}
      </Typography>
    }
    className={className}
  />
);

export const getKeyboardDatePicker = (
  format,
  label,
  okLabel,
  cancelLabel,
  value,
  className,
  onChange,
) => (
  <KeyboardDatePicker
    disableToolbar
    variant='dialog'
    margin='normal'
    format={format}
    label={label}
    okLabel={okLabel}
    cancelLabel={cancelLabel}
    value={value}
    onChange={onChange}
    className={className}
  />
);

export const getKeyboardDatePickerWithError = (
  format,
  label,
  okLabel,
  cancelLabel,
  value,
  minDate,
  minDateMessage,
  className,
  onChange,
) => (
  <KeyboardDatePicker
    disableToolbar
    variant='dialog'
    margin='normal'
    format={format}
    label={label}
    okLabel={okLabel}
    cancelLabel={cancelLabel}
    value={value}
    minDate={minDate}
    minDateMessage={minDateMessage}
    onChange={onChange}
    className={className}
  />
);

export const getSmallClearButton = (label, disabled, onClick) => (
  <Button
    size='small'
    variant='outlined'
    color='default'
    startIcon={<ClearIcon fontSize='small' />}
    disabled={disabled}
    onClick={onClick}
  >
    <Typography variant='caption' noWrap>
      {label}
    </Typography>
  </Button>
);

export const getSmallClearButton2 = (label, disabled, onClick, className = '') => (
  <Button
    size='small'
    variant='outlined'
    color='default'
    disabled={disabled}
    onClick={onClick}
    className={className}
  >
    <Typography variant='caption' noWrap>
      {label}
    </Typography>
  </Button>
);

export const getOutlinedButton = (label, disabled, onClick) => (
  <Button variant='outlined' color='default' disabled={disabled} onClick={onClick} size='small'>
    <Typography variant='body2' noWrap>
      {label}
    </Typography>
  </Button>
);

export const getFilterButton = (open, onClick, lang = LANGUAGE_DEFAULT) => (
  <>
    {open ? (
      <Tooltip title={appLanguages.filterClose[lang]}>
        <IconButton size='small' onClick={onClick}>
          <CloseIcon />
        </IconButton>
      </Tooltip>
    ) : (
      <Button variant='text' color='inherit' startIcon={<FilterListIcon />} onClick={onClick}>
        <Typography variant='inherit' noWrap>
          {appLanguages.filter[lang]}
        </Typography>
      </Button>
    )}
  </>
);

export const getExpandButton = (open, onClick) => (
  <IconButton aria-label='expand row' size='small' onClick={() => onClick()}>
    {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
  </IconButton>
);

export const __getFilterButton = (open, onClick, lang = LANGUAGE_DEFAULT) => (
  <Tooltip title={open ? appLanguages.filterClose[lang] : appLanguages.filterOpen[lang]}>
    <IconButton size='small' onClick={onClick}>
      {open ? <CloseIcon /> : <FilterListIcon />}
    </IconButton>
  </Tooltip>
);

export const getCommentsButton = (label, disabled, onClick) => (
  <Button
    size='small'
    variant='outlined'
    color='inherit'
    disabled={disabled}
    onClick={onClick}
    startIcon={<CommentIcon />}
  >
    {label}
  </Button>
);

export function getSuccessButton(label, tooltipTitle, onClick) {
  return label ? (
    <Tooltip title={tooltipTitle}>
      <Button
        size='small'
        variant='text'
        color='primary'
        onClick={onClick}
        endIcon={<CheckCircleOutlineIcon />}
      >
        <Typography variant='inherit' noWrap>
          {label}
        </Typography>
      </Button>
    </Tooltip>
  ) : (
    <Typography variant='body2' color='textSecondary'>
      -
    </Typography>
  );
}

export function getWarningButton(label, tooltipTitle, onClick, className = '') {
  return label ? (
    <Tooltip title={tooltipTitle}>
      <Button
        size='small'
        variant='text'
        onClick={onClick}
        startIcon={<ErrorOutlineIcon color='secondary' />}
        className={className}
      >
        <Typography variant='inherit' color='secondary' noWrap>
          {label}
        </Typography>
      </Button>
    </Tooltip>
  ) : (
    <Typography variant='body2' color='textSecondary'>
      -
    </Typography>
  );
}

export const getDateRangePicker = (
  label,
  value,
  onChange,
  lang = LANGUAGE_DEFAULT,
  disableFuture: Boolean = false,
) => {
  const { afterToday } = DateRangePicker;
  return (
    <Box mt={1.5} mb={1.5}>
      <Grid container alignItems='center'>
        <Grid item>
          <Typography variant='caption' color='textSecondary' noWrap>
            {label}
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems='center'>
        <Grid item>
          {/* 参考: https://rsuitejs.com/en/components/date-range-picker/ */}
          <DateRangePicker
            placeholder={appLanguages.inputPeriod[lang]}
            size='sm'
            format='YYYY/MM/DD'
            value={value}
            onChange={onChange}
            disabledDate={disableFuture ? afterToday() : null}
            style={{ width: '260px' }}
            locale={{
              sunday: appLanguages.daySunday[lang],
              monday: appLanguages.dayMonday[lang],
              tuesday: appLanguages.dayTuesday[lang],
              wednesday: appLanguages.dayWednesday[lang],
              thursday: appLanguages.dayThursday[lang],
              friday: appLanguages.dayFriday[lang],
              saturday: appLanguages.daySaturday[lang],
              ok: appLanguages.ok[lang],
              today: appLanguages.today[lang],
              yesterday: appLanguages.yesterday[lang],
              last7Days: appLanguages.lastSevenDays[lang],
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export const getDateRangePicker2 = (
  label,
  value,
  onChange,
  lang = LANGUAGE_DEFAULT,
  disableFuture: Boolean = false,
  width,
) => {
  const { afterToday } = DateRangePicker;
  return (
    <Box mt={1.5}>
      <Typography variant='subtitle1' color='textSecondary' noWrap>
        <p>{label}</p>
      </Typography>
      <Card elevation={1}>
        <Grid container alignItems='stretch'>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            {/* 参考: https://rsuitejs.com/en/components/date-range-picker/ */}
            <DateRangePicker
              placeholder={appLanguages.inputPeriod[lang]}
              size='lg'
              format='YYYY/MM/DD'
              value={value}
              onChange={onChange}
              disabledDate={disableFuture ? afterToday() : null}
              style={{ minWidth: '265px', width: width }}
              locale={{
                sunday: appLanguages.daySunday[lang],
                monday: appLanguages.dayMonday[lang],
                tuesday: appLanguages.dayTuesday[lang],
                wednesday: appLanguages.dayWednesday[lang],
                thursday: appLanguages.dayThursday[lang],
                friday: appLanguages.dayFriday[lang],
                saturday: appLanguages.daySaturday[lang],
                ok: appLanguages.ok[lang],
                today: appLanguages.today[lang],
                yesterday: appLanguages.yesterday[lang],
                last7Days: appLanguages.lastSevenDays[lang],
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

// Validation

export const getSerialCodeErrorMessage = (serialCode) => {
  const lang = store.getState().apps.currentLanguage;
  if (!serialCode) return '';
  if (String(serialCode).match(/[^0-9]+/)) {
    return i18n.onlyNumbers[lang];
  }
  if (String(serialCode).length > SERIAL_DIGIT) {
    return formatString(i18n.serialLength[lang], { field: SERIAL_DIGIT.toString() });
  }
  return '';
};

export const canAcceptRestaurantReceivedAt = (brewShipAt, restaurantReivedAt) => {
  if (
    brewShipAt &&
    restaurantReivedAt &&
    new Date(brewShipAt).getTime() > new Date(restaurantReivedAt).getTime()
  ) {
    return false;
  }
  return true;
};

export const canAcceptRestaurantSoldAt = (restaurantReivedAt, restaurantSoldAt) => {
  if (
    restaurantReivedAt &&
    restaurantSoldAt &&
    new Date(restaurantReivedAt).getTime() > new Date(restaurantSoldAt).getTime()
  ) {
    return false;
  }
  return true;
};

export const SERIAL_ACTION = {
  receive: 'receive',
  ship: 'ship',
  sell: 'sell',
  cancel: 'cancel',
};

export const extractSerialFromUrl = (url) => {
  if (!isString(url) || !(url.match(/http:\/\//) || url.match(/https:\/\//))) return undefined;
  const { qrDomain } = userEnv;
  if (String(url).indexOf(qrDomain) === -1) return undefined;
  const tmpList = url.split('/');
  const serial = tmpList[tmpList.length - 1].trim();
  if (isNaN(parseInt(serial))) return undefined;
  if (serial.length < 5) return undefined;
  return serial;
};
