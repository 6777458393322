import { i18n } from '@i18n/lang';
import { TSupportedLanguages } from '@models/supported-languages';
import { LANGUAGE_DEFAULT } from '@utils/common';
import { Logo } from '@visual/logo';
import { TextDisplay } from '@visual/text-display';
import React from 'react';
import styles from './style.scss';

export class Footer extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    isVisible: true,
    lang: LANGUAGE_DEFAULT,
  };

  public render(): JSX.Element {
    const { isVisible, lang, onPrivacyMessageClick } = this.props;
    if (!isVisible) return <React.Fragment />;
    return (
      <footer className={styles.footer}>
        <div className={styles.contents}>
          <Logo type='logo-white' />
          {onPrivacyMessageClick && (
            <div className={styles.privacyPolicyLink}>
              <TextDisplay display='inline' onClick={onPrivacyMessageClick}>
                {i18n.privacyPolicy[lang]}
              </TextDisplay>
            </div>
          )}
        </div>
        <div className={styles.copyright}>
          <TextDisplay size='x-small'>
            © JAPAN CRAFT SAKE COMPANY CO., LTD All Rights Reserved.
          </TextDisplay>
        </div>
      </footer>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  isVisible?: boolean;
  lang?: TSupportedLanguages;
}

export interface IDispatchProps {
  onPrivacyMessageClick?: () => void;
}
