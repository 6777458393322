import {
  AppBar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Paper,
  StyleRules,
  Tab,
  Tabs,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { IBrand } from '@models/brand';
import { IStateApps } from '@models/state-apps';
import { IStateServers } from '@models/state-servers';
import {
  closeBrandEditDialog,
  openSnackbarMessage,
  setEditBrand,
} from '@redux/actions/appsActions';
import { registerBrand } from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import appLanguages from '@utils/app-languages';
import {
  exportToCSV,
  getAddTitle,
  getAutocomplete,
  getCheckbox,
  getCSVFileUploadButton,
  getEditTitle,
  getFileDeleteButton,
  getImageFileUploadButton,
  getOutlinedButton,
  getTextField,
  getTextFieldMultiline,
  getTextFieldMultiline2,
} from '@utils/common';
import { DefaultQuery } from '@utils/default-query';
import { functions, storage } from '@utils/firebase';
import * as csvSync from 'csv-parse/lib/sync';
import * as hash from 'object-hash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import TabPanel, { a11yProps } from '../../../../elements/TabPanel';

class BrandEditDialogClass extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      mainTabValue: 0,
      csvFileText: null,
      csvFileObjects: null,
      tabValue: 0,
      enableDirectSettingOfImageUrl: false,
      enableDirectSettingOfLogoImageUrl: false,
      csvUpCount: 0,
      warningMessages: [],
      infoMessages: [],
      registering: false,
    };
  }

  getObject() {
    const { apps } = this.props;
    const o = apps.editBrand;
    return {
      id: (o && o.id) || null,
      publicId: (o && o.publicId) || '',
      url: (o && o.url) || '',
      imageUrl: (o && o.imageUrl) || '',
      logoImageUrl: (o && o.logoImageUrl) || '',
      subImageUrl: (o && o.subImageUrl) || '',
      videoUrl: (o && o.videoUrl) || '',
      locationId: (o && o.locationId) || 0,
      // 日本語
      name: (o && o.name) || '',
      intro: (o && o.intro) || '',
      tastingNote1: (o && o.tastingNote1) || '',
      tastingNote2: (o && o.tastingNote2) || '',
      paringFood: (o && o.paringFood) || '',
      servingSuggestion: (o && o.servingSuggestion) || '',
      riceSpecific: (o && o.riceSpecific) || '',
      bottleSize: (o && o.bottleSize) || '',
      alcohol: (o && o.alcohol) || '',
      description: (o && o.description) || '',
      // 英語
      enName: (o && o.enName) || '',
      enIntro: (o && o.enIntro) || '',
      enTastingNote1: (o && o.enTastingNote1) || '',
      enTastingNote2: (o && o.enTastingNote2) || '',
      enParingFood: (o && o.enParingFood) || '',
      enServingSuggestion: (o && o.enServingSuggestion) || '',
      enRiceSpecific: (o && o.enRiceSpecific) || '',
      enBottleSize: (o && o.enBottleSize) || '',
      enAlcohol: (o && o.enAlcohol) || '',
      enDescription: (o && o.enDescription) || '',
      // 中国語
      cnName: (o && o.cnName) || '',
      cnIntro: (o && o.cnIntro) || '',
      cnTastingNote1: (o && o.cnTastingNote1) || '',
      cnTastingNote2: (o && o.cnTastingNote2) || '',
      cnParingFood: (o && o.cnParingFood) || '',
      cnServingSuggestion: (o && o.cnServingSuggestion) || '',
      cnRiceSpecific: (o && o.cnRiceSpecific) || '',
      cnBottleSize: (o && o.cnBottleSize) || '',
      cnAlcohol: (o && o.cnAlcohol) || '',
      cnDescription: (o && o.cnDescription) || '',
      // 香港語
      hkName: (o && o.hkName) || '',
      hkIntro: (o && o.hkIntro) || '',
      hkTastingNote1: (o && o.hkTastingNote1) || '',
      hkTastingNote2: (o && o.hkTastingNote2) || '',
      hkParingFood: (o && o.hkParingFood) || '',
      hkServingSuggestion: (o && o.hkServingSuggestion) || '',
      hkRiceSpecific: (o && o.hkRiceSpecific) || '',
      hkBottleSize: (o && o.hkBottleSize) || '',
      hkAlcohol: (o && o.hkAlcohol) || '',
      hkDescription: (o && o.hkDescription) || '',
      // 韓国語
      krName: (o && o.krName) || '',
      krIntro: (o && o.krIntro) || '',
      krTastingNote1: (o && o.krTastingNote1) || '',
      krTastingNote2: (o && o.krTastingNote2) || '',
      krParingFood: (o && o.krParingFood) || '',
      krServingSuggestion: (o && o.krServingSuggestion) || '',
      krRiceSpecific: (o && o.krRiceSpecific) || '',
      krBottleSize: (o && o.krBottleSize) || '',
      krAlcohol: (o && o.krAlcohol) || '',
      krDescription: (o && o.krDescription) || '',
      // タイ語
      thName: (o && o.thName) || '',
      thIntro: (o && o.thIntro) || '',
      thTastingNote1: (o && o.thTastingNote1) || '',
      thTastingNote2: (o && o.thTastingNote2) || '',
      thParingFood: (o && o.thParingFood) || '',
      thServingSuggestion: (o && o.thServingSuggestion) || '',
      thRiceSpecific: (o && o.thRiceSpecific) || '',
      thBottleSize: (o && o.thBottleSize) || '',
      thAlcohol: (o && o.thAlcohol) || '',
      thDescription: (o && o.thDescription) || '',
      // ベトナム語
      viName: (o && o.viName) || '',
      viIntro: (o && o.viIntro) || '',
      viTastingNote1: (o && o.viTastingNote1) || '',
      viTastingNote2: (o && o.viTastingNote2) || '',
      viParingFood: (o && o.viParingFood) || '',
      viServingSuggestion: (o && o.viServingSuggestion) || '',
      viRiceSpecific: (o && o.viRiceSpecific) || '',
      viBottleSize: (o && o.viBottleSize) || '',
      viAlcohol: (o && o.viAlcohol) || '',
      viDescription: (o && o.viDescription) || '',
    };
  }

  updateEditObj(updateObj) {
    const { setEditBrand } = this.props;
    setEditBrand({ ...this.getObject(), ...updateObj });
  }

  handleChangePublicId(e) {
    this.updateEditObj({ publicId: e.target.value });
  }

  handleChangeUrl(e) {
    this.updateEditObj({ url: e.target.value });
  }

  handleChangeBrandLocation(v) {
    this.updateEditObj({ locationId: v });
  }

  handleClickRegister() {
    const {
      registerBrand,
      apps: { currentLanguage: lang },
    } = this.props;
    const { mainTabValue, csvFileObjects } = this.state;
    if (mainTabValue === 0) {
      const obj = this.getObject();
      obj.publicId = obj.publicId.trim().replace(/ /g, '.');
      registerBrand({ ...obj }, true);
    } else {
      this.clearInfoMessages();
      this.clearWarningMessages();
      this.setState({ registering: true });
      setTimeout(() => this.setState({ registering: false }), 3000);
      let index = 0;
      for (const csvFileObj of csvFileObjects) {
        index += 1;
        if (index > 20) {
          this.addWarningMessages(appLanguages.errorServerLoad[lang]);
          break;
        }
        if (!csvFileObj || csvFileObj.length < Object.keys(this.formatData()).length) {
          this.addWarningMessages(`${index}${appLanguages.errorRowNotEnoughItems[lang]}`);
          continue;
        }
        const obj = {
          publicId: csvFileObj[0],
          name: csvFileObj[1],
          url: csvFileObj[2],
          imageUrl: csvFileObj[3],
          logoImageUrl: csvFileObj[4],
          brewName: csvFileObj[5],
          brewPublicId: csvFileObj[6],
          // 日本語
          intro: csvFileObj[7],
          tastingNote1: csvFileObj[8],
          tastingNote2: csvFileObj[9],
          paringFood: csvFileObj[10],
          servingSuggestion: csvFileObj[11],
          riceSpecific: csvFileObj[12],
          bottleSize: csvFileObj[13],
          alcohol: csvFileObj[14],
          description: csvFileObj[15],
          // 英語
          enName: csvFileObj[16],
          enIntro: csvFileObj[17],
          enTastingNote1: csvFileObj[18],
          enTastingNote2: csvFileObj[19],
          enParingFood: csvFileObj[20],
          enServingSuggestion: csvFileObj[21],
          enRiceSpecific: csvFileObj[22],
          enBottleSize: csvFileObj[23],
          enAlcohol: csvFileObj[24],
          enDescription: csvFileObj[25],
          // 中国語
          cnName: csvFileObj[26],
          cnIntro: csvFileObj[27],
          cnTastingNote1: csvFileObj[28],
          cnTastingNote2: csvFileObj[29],
          cnParingFood: csvFileObj[30],
          cnServingSuggestion: csvFileObj[31],
          cnRiceSpecific: csvFileObj[32],
          cnBottleSize: csvFileObj[33],
          cnAlcohol: csvFileObj[34],
          cnDescription: csvFileObj[35],
          // 香港語
          hkName: csvFileObj[36],
          hkIntro: csvFileObj[37],
          hkTastingNote1: csvFileObj[38],
          hkTastingNote2: csvFileObj[39],
          hkParingFood: csvFileObj[40],
          hkServingSuggestion: csvFileObj[41],
          hkRiceSpecific: csvFileObj[42],
          hkBottleSize: csvFileObj[43],
          hkAlcohol: csvFileObj[44],
          hkDescription: csvFileObj[45],
          // 韓国語
          krName: csvFileObj[46],
          krIntro: csvFileObj[47],
          krTastingNote1: csvFileObj[48],
          krTastingNote2: csvFileObj[49],
          krParingFood: csvFileObj[50],
          krServingSuggestion: csvFileObj[51],
          krRiceSpecific: csvFileObj[52],
          krBottleSize: csvFileObj[53],
          krAlcohol: csvFileObj[54],
          krDescription: csvFileObj[55],
          // タイ語
          thName: csvFileObj[56],
          thIntro: csvFileObj[57],
          thTastingNote1: csvFileObj[58],
          thTastingNote2: csvFileObj[59],
          thParingFood: csvFileObj[60],
          thServingSuggestion: csvFileObj[61],
          thRiceSpecific: csvFileObj[62],
          thBottleSize: csvFileObj[63],
          thAlcohol: csvFileObj[64],
          thDescription: csvFileObj[65],
          // ベトナム語
          viName: csvFileObj[66],
          viIntro: csvFileObj[67],
          viTastingNote1: csvFileObj[68],
          viTastingNote2: csvFileObj[69],
          viParingFood: csvFileObj[70],
          viServingSuggestion: csvFileObj[71],
          viRiceSpecific: csvFileObj[72],
          viBottleSize: csvFileObj[73],
          viAlcohol: csvFileObj[74],
          viDescription: csvFileObj[75],
        };
        if (obj.publicId === '公開ID') continue; // ヘッダーはスキップ
        if (!obj.publicId || !obj.name || !obj.brewPublicId) {
          this.addWarningMessages(
            `${index}${appLanguages.errorRowIncompleteRequiredFieldsBrand[lang]}`,
          );
          continue;
        }
        const query = {
          ...DefaultQuery,
          limit: 1,
          where: { $or: [{ publicId: obj.publicId }, { name: obj.name }] },
        };
        const targetIndex = index;
        functions
          .httpsCallable('getBrands')(query)
          .then((result) => {
            const { objects } = result.data;
            if (objects && objects.length) {
              const resObj = objects[0];
              if (resObj.publicId === obj.publicId) {
                this.addWarningMessages(
                  `${targetIndex}${appLanguages.errorRowAlreadyRegistered[lang]}${appLanguages.publicId[lang]}(${obj.publicId})${appLanguages.desu[lang]}`,
                );
              } else if (resObj.name === obj.name) {
                this.addWarningMessages(
                  `${targetIndex}${appLanguages.errorRowAlreadyRegistered[lang]}${appLanguages.brand[lang]}(${obj.name})${appLanguages.desu[lang]}`,
                );
              }
            } else {
              // 新規登録のみ対応
              registerBrand(obj);
              this.addInfoMessages(
                `${targetIndex}${appLanguages.rowBrand[lang]}(${obj.publicId})${appLanguages.addedSuffix[lang]}`,
              );
            }
          });
      }
    }
  }

  addWarningMessages(message) {
    if (!message) return;
    const { warningMessages } = this.state;
    warningMessages.push(message);
    this.setState(warningMessages);
  }

  addInfoMessages(message) {
    if (!message) return;
    const { infoMessages } = this.state;
    infoMessages.push(message);
    this.setState(infoMessages);
  }

  clearWarningMessages() {
    this.setState({ warningMessages: [] });
  }

  clearInfoMessages() {
    this.setState({ infoMessages: [] });
  }

  handleChangeImgFiles(e) {
    const { files } = e.target;
    const { servers } = this.props;
    for (let i = 0; i < files.length; i += 1) {
      const imgFile = files[i];
      const fileName = `${imgFile.size}-${imgFile.lastModified}-${imgFile.type}`.replace('/', '_');
      const storageRef = storage.ref(`upload/${servers.user.uid}/brands/${fileName}`);
      const uploadTask = storageRef.put(imgFile);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          window.console.log(`Upload is ${progress}% done. state is ${snapshot.state}`);
        },
        (error) => {
          window.console.error(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.updateEditObj({ imageUrl: downloadURL });
          });
        },
      );
    }
  }

  handleChangeLogoImgFiles(e) {
    const { files } = e.target;
    const { servers } = this.props;
    for (let i = 0; i < files.length; i += 1) {
      const imgFile = files[i];
      const fileName = `${imgFile.size}-${imgFile.lastModified}-${imgFile.type}`.replace('/', '_');
      const storageRef = storage.ref(`upload/${servers.user.uid}/brand_logos/${fileName}`);
      const uploadTask = storageRef.put(imgFile);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          window.console.log(`Upload is ${progress}% done. state is ${snapshot.state}`);
        },
        (error) => {
          window.console.error(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.updateEditObj({ logoImageUrl: downloadURL });
          });
        },
      );
    }
  }

  handleChangeImgUrl(e) {
    this.updateEditObj({ imageUrl: e.target.value });
  }

  handleChangeLogoImgUrl(e) {
    this.updateEditObj({ logoImageUrl: e.target.value });
  }

  handleChangeCsvFiles(e) {
    const {
      openSnackbarMessage,
      apps: { currentLanguage: lang },
    } = this.props;
    const { files } = e.target;
    if (!files.length) return;
    const csvFile = files[0];
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.onerror = () => {
      openSnackbarMessage('warning', appLanguages.fileReadFailed[lang]);
    };
    reader.onload = () => {
      const text = reader?.result?.toString() || '';
      const result = csvSync(text);
      if (!result || !result.length) {
        openSnackbarMessage('warning', appLanguages.csvFormatError[lang]);
      } else {
        this.setState({
          csvFileText: text,
          csvFileObjects: result,
        });
      }
    };
    reader.readAsText(csvFile);
  }

  formatData() {
    const { apps } = this.props;
    const lang = apps.currentLanguage;
    return {
      [appLanguages.publicId[lang]]: '',
      [appLanguages.name[lang]]: '',
      [appLanguages.url[lang]]: '',
      [appLanguages.imageUrl[lang]]: '',
      [appLanguages.logoImageUrl[lang]]: '',
      [appLanguages.breweryName[lang]]: '',
      [appLanguages.breweryPublicId[lang]]: '',
      // 日本語
      [appLanguages.intro[lang]]: '',
      [appLanguages.tastingNote1[lang]]: '',
      [appLanguages.tastingNote2[lang]]: '',
      [appLanguages.paringFood[lang]]: '',
      [appLanguages.servingSuggestion[lang]]: '',
      [appLanguages.riceSpecific[lang]]: '',
      [appLanguages.bottleSize[lang]]: '',
      [appLanguages.alcohol[lang]]: '',
      [appLanguages.description[lang]]: '',
      // 英語
      [appLanguages.enIntro[lang]]: '',
      [appLanguages.enTastingNote1[lang]]: '',
      [appLanguages.enTastingNote2[lang]]: '',
      [appLanguages.enParingFood[lang]]: '',
      [appLanguages.enServingSuggestion[lang]]: '',
      [appLanguages.enRiceSpecific[lang]]: '',
      [appLanguages.enBottleSize[lang]]: '',
      [appLanguages.enAlcohol[lang]]: '',
      [appLanguages.enDescription[lang]]: '',
      // 中国語
      [appLanguages.cnIntro[lang]]: '',
      [appLanguages.cnTastingNote1[lang]]: '',
      [appLanguages.cnTastingNote2[lang]]: '',
      [appLanguages.cnParingFood[lang]]: '',
      [appLanguages.cnServingSuggestion[lang]]: '',
      [appLanguages.cnRiceSpecific[lang]]: '',
      [appLanguages.cnBottleSize[lang]]: '',
      [appLanguages.cnAlcohol[lang]]: '',
      [appLanguages.cnDescription[lang]]: '',
      // 香港語
      [appLanguages.hkIntro[lang]]: '',
      [appLanguages.hkTastingNote1[lang]]: '',
      [appLanguages.hkTastingNote2[lang]]: '',
      [appLanguages.hkParingFood[lang]]: '',
      [appLanguages.hkServingSuggestion[lang]]: '',
      [appLanguages.hkRiceSpecific[lang]]: '',
      [appLanguages.hkBottleSize[lang]]: '',
      [appLanguages.hkAlcohol[lang]]: '',
      [appLanguages.hkDescription[lang]]: '',
      // 韓国語
      [appLanguages.krIntro[lang]]: '',
      [appLanguages.krTastingNote1[lang]]: '',
      [appLanguages.krTastingNote2[lang]]: '',
      [appLanguages.krParingFood[lang]]: '',
      [appLanguages.krServingSuggestion[lang]]: '',
      [appLanguages.krRiceSpecific[lang]]: '',
      [appLanguages.krBottleSize[lang]]: '',
      [appLanguages.krAlcohol[lang]]: '',
      [appLanguages.krDescription[lang]]: '',
      // タイ語
      [appLanguages.thIntro[lang]]: '',
      [appLanguages.thTastingNote1[lang]]: '',
      [appLanguages.thTastingNote2[lang]]: '',
      [appLanguages.thParingFood[lang]]: '',
      [appLanguages.thServingSuggestion[lang]]: '',
      [appLanguages.thRiceSpecific[lang]]: '',
      [appLanguages.thBottleSize[lang]]: '',
      [appLanguages.thAlcohol[lang]]: '',
      [appLanguages.thDescription[lang]]: '',
      // ベトナム語
      [appLanguages.viIntro[lang]]: '',
      [appLanguages.viTastingNote1[lang]]: '',
      [appLanguages.viTastingNote2[lang]]: '',
      [appLanguages.viParingFood[lang]]: '',
      [appLanguages.viServingSuggestion[lang]]: '',
      [appLanguages.viRiceSpecific[lang]]: '',
      [appLanguages.viBottleSize[lang]]: '',
      [appLanguages.viAlcohol[lang]]: '',
      [appLanguages.viDescription[lang]]: '',
    };
  }

  render() {
    const { classes, apps, servers } = this.props;
    const {
      mainTabValue,
      csvFileText,
      csvFileObjects,
      tabValue,
      enableDirectSettingOfImageUrl,
      enableDirectSettingOfLogoImageUrl,
      csvUpCount,
      warningMessages,
      infoMessages,
      registering,
    } = this.state;
    const { closeBrandEditDialog } = this.props;
    const obj = this.getObject();
    const { id } = obj;

    const lang = apps.currentLanguage;

    // Brand Locations
    let brandLocationValue = null;
    const brandLocations = servers.brandLocations.map((o) => {
      const v = { id: o.id, name: o.name };
      if (o.id === obj.locationId) brandLocationValue = { ...v };
      return v;
    });

    const isRequesting = servers.isGetRequesting || servers.isRequesting || registering;

    const uploadMainImageView = (
      <Paper elevation={3}>
        <Grid container justify='center' alignItems='flex-start' alignContent='flex-start'>
          <Grid item xs={12}>
            <Box mt={1} />
          </Grid>
          <Grid item>
            {obj.imageUrl ? (
              <Card className={classes.cardRoot}>
                <CardActionArea>
                  <CardMedia className={classes.cardMedia} image={obj.imageUrl} title={obj.name} />
                </CardActionArea>
              </Card>
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={12}>
            <Box mt={1} />
          </Grid>
          {enableDirectSettingOfImageUrl ? (
            <Grid item xs={10}>
              {getTextField(
                `${appLanguages.imageUrl[lang]} (${appLanguages.require[lang]})`,
                obj.imageUrl || '',
                isRequesting,
                (e) => this.handleChangeImgUrl(e),
              )}
            </Grid>
          ) : (
            <Grid item>
              {getImageFileUploadButton(
                'main-image-file',
                `${appLanguages.imageUpload[lang]}`,
                classes.fileInput,
                classes.fileButton,
                isRequesting,
                (e) => this.handleChangeImgFiles(e),
              )}
              {obj.imageUrl
                ? getFileDeleteButton(
                    appLanguages.resetImage[lang],
                    classes.fileButton,
                    isRequesting,
                    () => this.updateEditObj({ imageUrl: '' }),
                  )
                : ''}
            </Grid>
          )}
          <Grid item xs={12}>
            <Box mt={1} />
          </Grid>
          <Grid item>
            {getCheckbox(
              `${appLanguages.enableDirectSettingOfImageUrl[lang]}`,
              enableDirectSettingOfImageUrl,
              (e) => this.setState({ enableDirectSettingOfImageUrl: e.target.checked }),
            )}
          </Grid>
        </Grid>
      </Paper>
    );

    const uploadLogoImageView = (
      <Paper elevation={3}>
        <Grid container justify='center' alignItems='flex-start' alignContent='flex-start'>
          <Grid item xs={12}>
            <Box mt={1} />
          </Grid>
          <Grid item>
            {obj.logoImageUrl ? (
              <Card className={classes.cardRoot2}>
                <CardActionArea>
                  <CardMedia
                    className={classes.cardMedia2}
                    image={obj.logoImageUrl}
                    title={obj.name}
                  />
                </CardActionArea>
              </Card>
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={12}>
            <Box mt={1} />
          </Grid>
          {enableDirectSettingOfLogoImageUrl ? (
            <Grid item xs={10}>
              {getTextField(
                `${appLanguages.logoImageUrl[lang]} (${appLanguages.require[lang]})`,
                obj.logoImageUrl || '',
                isRequesting,
                (e) => this.handleChangeLogoImgUrl(e),
              )}
            </Grid>
          ) : (
            <Grid item>
              {getImageFileUploadButton(
                'logo-image-file',
                `${appLanguages.logoImageUpload[lang]}`,
                classes.fileInput,
                classes.fileButton,
                isRequesting,
                (e) => this.handleChangeLogoImgFiles(e),
              )}
              {obj.logoImageUrl
                ? getFileDeleteButton(
                    appLanguages.resetImage[lang],
                    classes.fileButton,
                    isRequesting,
                    () => this.updateEditObj({ logoImageUrl: '' }),
                  )
                : ''}
            </Grid>
          )}
          <Grid item xs={12}>
            <Box mt={1} />
          </Grid>
          <Grid item>
            {getCheckbox(
              `${appLanguages.enableDirectSettingOfLogoImageUrl[lang]}`,
              enableDirectSettingOfLogoImageUrl,
              (e) => this.setState({ enableDirectSettingOfLogoImageUrl: e.target.checked }),
            )}
          </Grid>
        </Grid>
      </Paper>
    );

    const uploadCSVView = (
      <Paper elevation={0}>
        <Grid container justify='center' alignItems='flex-start' alignContent='flex-start'>
          <Grid item xs={12}>
            <Box mt={1} />
          </Grid>
          <Grid item>
            <Typography variant='caption'>{appLanguages.csvFileFormatPrefix[lang]}</Typography>
            <Button
              color='primary'
              onClick={() => exportToCSV('brands_format_', [this.formatData()])}
            >
              {appLanguages.here[lang]}
            </Button>
            <Typography variant='caption'>{appLanguages.downloadSuffix[lang]}</Typography>
          </Grid>
          <Grid item>
            {getCSVFileUploadButton(
              `brands-csv-file${csvUpCount}`,
              appLanguages.csvUpload[lang],
              classes.fileInput,
              classes.fileButton,
              isRequesting,
              (e) => this.handleChangeCsvFiles(e),
            )}
            {csvFileObjects && csvFileText ? (
              getFileDeleteButton(
                appLanguages.reset[lang],
                classes.fileButton,
                isRequesting,
                () => {
                  this.setState({ csvFileText: null, csvFileObjects: null });
                  this.clearInfoMessages();
                  this.clearWarningMessages();
                },
              )
            ) : (
              <Box />
            )}
          </Grid>
          <Grid item xs={12}>
            {csvFileObjects && csvFileText ? (
              getTextFieldMultiline2(
                appLanguages.csvFileContents[lang],
                csvFileText,
                true,
                () => null,
              )
            ) : (
              <Box />
            )}
          </Grid>
          <Grid item xs={12}>
            {warningMessages.map((message) => (
              <Alert key={hash(message)} severity='warning'>
                {message}
              </Alert>
            ))}
            {infoMessages.map((message) => (
              <Alert key={hash(message)} severity='success'>
                {message}
              </Alert>
            ))}
          </Grid>
        </Grid>
      </Paper>
    );

    return (
      <Dialog fullWidth open={apps.isOpenBrandEditDialog} onClose={() => closeBrandEditDialog()}>
        {isRequesting ? <LinearProgress color='secondary' /> : ''}
        <DialogTitle>
          {id
            ? getEditTitle(appLanguages.editBrand[lang])
            : getAddTitle(appLanguages.addNewBrand[lang])}
        </DialogTitle>
        <DialogContent>
          <Paper elevation={3} className={classes.paper}>
            {/*
            <AppBar position='static' color='default'>
              <Tabs
                value={mainTabValue}
                variant='scrollable'
                scrollButtons='auto'
                onChange={(e, v) => this.setState({ mainTabValue: v })}
              >
                <Tab label={appLanguages.dataUpload[lang]} {...a11yProps(0)} />
                <Tab label={appLanguages.csvFileBulkAdd[lang]} {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            */}
            <TabPanel value={mainTabValue} index={0}>
              <Box>
                {/* メイン画像 */}
                {uploadMainImageView}
                <Box mt={3} />
                {/* ロゴ画像 */}
                {uploadLogoImageView}
                {/* 拠点 */}
                <Box mt={1} />
                {getAutocomplete(
                  `${appLanguages.brewery[lang]} (${appLanguages.require[lang]})`,
                  brandLocationValue,
                  brandLocations,
                  isRequesting,
                  (e, v) => this.handleChangeBrandLocation(v ? v.id : 0),
                )}
                {/* 公開ID */}
                <Box mt={1} />
                {getTextField(
                  `${appLanguages.publicId[lang]} (${appLanguages.require[lang]})`,
                  obj.publicId || '',
                  isRequesting || false,
                  (e) => this.handleChangePublicId(e),
                )}
                {/* 銘柄URL */}
                <Box mt={1} />
                {getTextField(
                  `${appLanguages.brandUrl[lang]} (${appLanguages.require[lang]})`,
                  obj.url || '',
                  isRequesting || false,
                  (e) => this.handleChangeUrl(e),
                )}
                <Box mt={3} />
                <Paper elevation={3} className={classes.paper}>
                  <AppBar position='static' color='default'>
                    <Tabs
                      value={tabValue}
                      variant='scrollable'
                      scrollButtons='auto'
                      onChange={(e, v) => this.setState({ tabValue: v })}
                    >
                      <Tab label={appLanguages.langJapanese[lang]} {...a11yProps(0)} />
                      <Tab label={appLanguages.langEnglish[lang]} {...a11yProps(1)} />
                      <Tab label={appLanguages.langChinese[lang]} {...a11yProps(2)} />
                      <Tab label={appLanguages.langCantonese[lang]} {...a11yProps(3)} />
                      <Tab label={appLanguages.langKorean[lang]} {...a11yProps(4)} />
                      <Tab label={appLanguages.langThai[lang]} {...a11yProps(5)} />
                      <Tab label={appLanguages.langVietnamese[lang]} {...a11yProps(6)} />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={tabValue} index={0}>
                    {/* 日本語 */}
                    {tabValue === 0 ? (
                      <>
                        <Box mt={1} />
                        {/* 名前 */}
                        {getTextField(
                          `${appLanguages.name[lang]} (${appLanguages.require[lang]})`,
                          obj.name || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ name: e.target.value }),
                        )}
                        {/* 紹介 */}
                        {getTextFieldMultiline(
                          `${appLanguages.intro[lang]}`,
                          obj.intro || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ intro: e.target.value }),
                        )}
                        {/* 試飲情報(1) */}
                        {getTextFieldMultiline(
                          `${appLanguages.tastingNote1[lang]}`,
                          obj.tastingNote1 || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ tastingNote1: e.target.value }),
                        )}
                        {/* 試飲情報(2) */}
                        {getTextFieldMultiline(
                          `${appLanguages.tastingNote2[lang]}`,
                          obj.tastingNote2 || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ tastingNote2: e.target.value }),
                        )}
                        {/* ペアリングフード */}
                        {getTextFieldMultiline(
                          `${appLanguages.paringFood[lang]}`,
                          obj.paringFood || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ paringFood: e.target.value }),
                        )}
                        {/* おすすめの飲み方 */}
                        {getTextFieldMultiline(
                          `${appLanguages.servingSuggestion[lang]}`,
                          obj.servingSuggestion || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ servingSuggestion: e.target.value }),
                        )}
                        {/* 酒米 */}
                        {getTextField(
                          `${appLanguages.riceSpecific[lang]}`,
                          obj.riceSpecific || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ riceSpecific: e.target.value }),
                        )}
                        {/* ボトルサイズ */}
                        {getTextField(
                          `${appLanguages.bottleSize[lang]}`,
                          obj.bottleSize || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ bottleSize: e.target.value }),
                        )}
                        {/* アルコール度数 */}
                        {getTextField(
                          `${appLanguages.alcohol[lang]}`,
                          obj.alcohol || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ alcohol: e.target.value }),
                        )}
                        {/* 説明 */}
                        {getTextFieldMultiline(
                          `${appLanguages.description[lang]}`,
                          obj.description || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ description: e.target.value }),
                        )}
                      </>
                    ) : (
                      <Box />
                    )}
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    {/* 英語 */}
                    {tabValue === 1 ? (
                      <>
                        <Box mt={1} />
                        {getTextField(
                          `${appLanguages.enName[lang]}`,
                          obj.enName || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ enName: e.target.value }),
                        )}
                        {/* 紹介 */}
                        {getTextFieldMultiline(
                          `${appLanguages.enIntro[lang]}`,
                          obj.enIntro || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ enIntro: e.target.value }),
                        )}
                        {/* 試飲情報(1) */}
                        {getTextFieldMultiline(
                          `${appLanguages.enTastingNote1[lang]}`,
                          obj.enTastingNote1 || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ enTastingNote1: e.target.value }),
                        )}
                        {/* 試飲情報(2) */}
                        {getTextFieldMultiline(
                          `${appLanguages.enTastingNote2[lang]}`,
                          obj.enTastingNote2 || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ enTastingNote2: e.target.value }),
                        )}
                        {/* ペアリングフード */}
                        {getTextFieldMultiline(
                          `${appLanguages.enParingFood[lang]}`,
                          obj.enParingFood || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ enParingFood: e.target.value }),
                        )}
                        {/* おすすめの飲み方 */}
                        {getTextFieldMultiline(
                          `${appLanguages.enServingSuggestion[lang]}`,
                          obj.enServingSuggestion || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ enServingSuggestion: e.target.value }),
                        )}
                        {/* 酒米 */}
                        {getTextField(
                          `${appLanguages.enRiceSpecific[lang]}`,
                          obj.enRiceSpecific || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ enRiceSpecific: e.target.value }),
                        )}
                        {/* ボトルサイズ */}
                        {getTextField(
                          `${appLanguages.enBottleSize[lang]}`,
                          obj.enBottleSize || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ enBottleSize: e.target.value }),
                        )}
                        {/* アルコール度数 */}
                        {getTextField(
                          `${appLanguages.enAlcohol[lang]}`,
                          obj.enAlcohol || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ enAlcohol: e.target.value }),
                        )}
                        {/* 説明 */}
                        {getTextFieldMultiline(
                          `${appLanguages.enDescription[lang]}`,
                          obj.enDescription || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ enDescription: e.target.value }),
                        )}
                      </>
                    ) : (
                      <Box />
                    )}
                  </TabPanel>
                  <TabPanel value={tabValue} index={2}>
                    {/* 中国語 */}
                    {tabValue === 2 ? (
                      <>
                        <Box mt={1} />
                        {getTextField(
                          `${appLanguages.cnName[lang]}`,
                          obj.cnName || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ cnName: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.cnIntro[lang]}`,
                          obj.cnIntro || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ cnIntro: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.cnTastingNote1[lang]}`,
                          obj.cnTastingNote1 || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ cnTastingNote1: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.cnTastingNote2[lang]}`,
                          obj.cnTastingNote2 || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ cnTastingNote2: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.cnParingFood[lang]}`,
                          obj.cnParingFood || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ cnParingFood: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.cnServingSuggestion[lang]}`,
                          obj.cnServingSuggestion || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ cnServingSuggestion: e.target.value }),
                        )}
                        {getTextField(
                          `${appLanguages.cnRiceSpecific[lang]}`,
                          obj.cnRiceSpecific || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ cnRiceSpecific: e.target.value }),
                        )}
                        {getTextField(
                          `${appLanguages.cnBottleSize[lang]}`,
                          obj.cnBottleSize || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ cnBottleSize: e.target.value }),
                        )}
                        {getTextField(
                          `${appLanguages.cnAlcohol[lang]}`,
                          obj.cnAlcohol || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ cnAlcohol: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.cnDescription[lang]}`,
                          obj.cnDescription || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ cnDescription: e.target.value }),
                        )}
                      </>
                    ) : (
                      <Box />
                    )}
                  </TabPanel>
                  <TabPanel value={tabValue} index={3}>
                    {/* 香港語 */}
                    {tabValue === 3 ? (
                      <>
                        <Box mt={1} />
                        {getTextFieldMultiline(
                          `${appLanguages.hkName[lang]}`,
                          obj.hkName || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ hkName: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.hkIntro[lang]}`,
                          obj.hkIntro || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ hkIntro: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.hkTastingNote1[lang]}`,
                          obj.hkTastingNote1 || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ hkTastingNote1: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.hkTastingNote2[lang]}`,
                          obj.hkTastingNote2 || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ hkTastingNote2: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.hkParingFood[lang]}`,
                          obj.hkParingFood || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ hkParingFood: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.hkServingSuggestion[lang]}`,
                          obj.hkServingSuggestion || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ hkServingSuggestion: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.hkRiceSpecific[lang]}`,
                          obj.hkRiceSpecific || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ hkRiceSpecific: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.hkBottleSize[lang]}`,
                          obj.hkBottleSize || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ hkBottleSize: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.hkAlcohol[lang]}`,
                          obj.hkAlcohol || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ hkAlcohol: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.hkDescription[lang]}`,
                          obj.hkDescription || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ hkDescription: e.target.value }),
                        )}
                      </>
                    ) : (
                      <Box />
                    )}
                  </TabPanel>
                  <TabPanel value={tabValue} index={4}>
                    {/* 韓国語 */}
                    {tabValue === 4 ? (
                      <>
                        <Box mt={1} />
                        {getTextFieldMultiline(
                          `${appLanguages.krName[lang]}`,
                          obj.krName || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ krName: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.krIntro[lang]}`,
                          obj.krIntro || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ krIntro: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.krTastingNote1[lang]}`,
                          obj.krTastingNote1 || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ krTastingNote1: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.krTastingNote2[lang]}`,
                          obj.krTastingNote2 || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ krTastingNote2: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.krParingFood[lang]}`,
                          obj.krParingFood || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ krParingFood: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.krServingSuggestion[lang]}`,
                          obj.krServingSuggestion || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ krServingSuggestion: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.krRiceSpecific[lang]}`,
                          obj.krRiceSpecific || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ krRiceSpecific: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.krBottleSize[lang]}`,
                          obj.krBottleSize || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ krBottleSize: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.krAlcohol[lang]}`,
                          obj.krAlcohol || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ krAlcohol: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.krDescription[lang]}`,
                          obj.krDescription || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ krDescription: e.target.value }),
                        )}
                      </>
                    ) : (
                      <Box />
                    )}
                  </TabPanel>
                  <TabPanel value={tabValue} index={5}>
                    {/* タイ語 */}
                    {tabValue === 5 ? (
                      <>
                        <Box mt={1} />
                        {getTextFieldMultiline(
                          `${appLanguages.thName[lang]}`,
                          obj.thName || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ thName: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.thIntro[lang]}`,
                          obj.thIntro || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ thIntro: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.thTastingNote1[lang]}`,
                          obj.thTastingNote1 || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ thTastingNote1: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.thTastingNote2[lang]}`,
                          obj.thTastingNote2 || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ thTastingNote2: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.thParingFood[lang]}`,
                          obj.thParingFood || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ thParingFood: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.thServingSuggestion[lang]}`,
                          obj.thServingSuggestion || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ thServingSuggestion: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.thRiceSpecific[lang]}`,
                          obj.thRiceSpecific || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ thRiceSpecific: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.thBottleSize[lang]}`,
                          obj.thBottleSize || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ thBottleSize: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.thAlcohol[lang]}`,
                          obj.thAlcohol || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ thAlcohol: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.thDescription[lang]}`,
                          obj.thDescription || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ thDescription: e.target.value }),
                        )}
                      </>
                    ) : (
                      <Box />
                    )}
                  </TabPanel>
                  <TabPanel value={tabValue} index={6}>
                    {/* ベトナム語 */}
                    {tabValue === 6 ? (
                      <>
                        <Box mt={1} />
                        {getTextFieldMultiline(
                          `${appLanguages.viName[lang]}`,
                          obj.viName || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ viName: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.viIntro[lang]}`,
                          obj.viIntro || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ viIntro: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.viTastingNote1[lang]}`,
                          obj.viTastingNote1 || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ viTastingNote1: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.viTastingNote2[lang]}`,
                          obj.viTastingNote2 || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ viTastingNote2: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.viParingFood[lang]}`,
                          obj.viParingFood || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ viParingFood: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.viServingSuggestion[lang]}`,
                          obj.viServingSuggestion || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ viServingSuggestion: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.viRiceSpecific[lang]}`,
                          obj.viRiceSpecific || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ viRiceSpecific: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.viBottleSize[lang]}`,
                          obj.viBottleSize || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ viBottleSize: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.viAlcohol[lang]}`,
                          obj.viAlcohol || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ viAlcohol: e.target.value }),
                        )}
                        {getTextFieldMultiline(
                          `${appLanguages.viDescription[lang]}`,
                          obj.viDescription || '',
                          isRequesting || false,
                          (e) => this.updateEditObj({ viDescription: e.target.value }),
                        )}
                      </>
                    ) : (
                      <Box />
                    )}
                  </TabPanel>
                </Paper>
                <Box mb={3} />
              </Box>
            </TabPanel>
            <TabPanel value={mainTabValue} index={1}>
              {uploadCSVView}
            </TabPanel>
          </Paper>
        </DialogContent>
        {apps?.brandPopupOpener === 'master' && (
          <DialogActions>
            <Grid container justify='flex-end'>
              {getOutlinedButton(`${appLanguages.cancel[lang]}`, isRequesting, () =>
                closeBrandEditDialog(),
              )}
              <Box ml={1} />
              {getOutlinedButton(
                `${id ? appLanguages.update[lang] : appLanguages.register[lang]}`,
                isRequesting || (mainTabValue === 1 && !csvFileText),
                () => this.handleClickRegister(),
              )}
            </Grid>
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  classes: any;
}

export interface IDispatchProps {
  setEditBrand: (brandObj: IBrand) => void;
  registerBrand: (brandObj: any, closePopup?: boolean) => void;
  closeBrandEditDialog: () => void;
  openSnackbarMessage: (format: string, message: string) => void;
}

interface State {
  mainTabValue: number;
  csvFileText: any;
  csvFileObjects: any;
  tabValue: number;
  enableDirectSettingOfImageUrl: boolean;
  enableDirectSettingOfLogoImageUrl: boolean;
  csvUpCount: number;
  warningMessages: any;
  infoMessages: any;
  registering: boolean;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps = {
  setEditBrand,
  closeBrandEditDialog,
  registerBrand,
  openSnackbarMessage,
};

const myStyles = (theme: Theme): StyleRules => ({
  cardRoot: { maxWidth: 120, minWidth: 120 },
  cardMedia: { height: 180 },
  cardRoot2: { maxWidth: 280, minWidth: 280 },
  cardMedia2: { height: 58 },
  fileButton: { margin: theme.spacing(1) },
  fileInput: { display: 'none' },
  avatar: {
    width: theme.spacing(14),
    height: theme.spacing(14),
  },
});

export const BrandEditDialog = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(BrandEditDialogClass);
