import { PageConfig } from '@container/pages/main-menu/config/get-menu-items';
// import crypto from 'crypto';
import { History, LocationState } from 'history';
import userEnv from 'userEnv';
// import hash from 'object-hash';
import { AppRoutePaths } from './app-route-paths';

export function gotoPage(pageConfig: Partial<PageConfig>, history: History<LocationState>) {
  if (pageConfig.newWindowLink) {
    const appUrl = userEnv.appUrl;
    const uid = pageConfig.newWindowLink;
    const ts = Number(Date.now()) + userEnv.cryptoKey;
    window.open(appUrl + `/?uid=${uid}_${ts.toString()}`, '_self');
  } else {
    return history.push(`${AppRoutePaths[pageConfig.pageLink].link}`);
  }
}
