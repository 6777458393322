import { TSupportedLanguages } from '@models/supported-languages';
import { LANGUAGE_DEFAULT } from '@utils/common';
import { isOk } from '@utils/is-ok';
import { countryInfo } from './info';

export function getCountryName(code: string, lang: TSupportedLanguages = LANGUAGE_DEFAULT) {
  if (!code) return;
  const targetCountry = countryInfo.find((country) => country.code === code);
  if (!isOk(targetCountry)) return;
  return lang === 'ja' ? targetCountry?.name.short : targetCountry?.enName.short;
}
