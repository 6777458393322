import { i18n } from '@i18n/lang';
import { ERestaurantProductCuisineType } from '@models/restaurant-product-cuisine-type';
import { TSupportedLanguages } from '@models/supported-languages';

export function getCuisineTypeName(type: ERestaurantProductCuisineType, lang: TSupportedLanguages) {
  switch (type) {
    case ERestaurantProductCuisineType.UNKNOWN:
      return i18n.unspecified[lang];
    case ERestaurantProductCuisineType.JAPANESE_SUSHI:
      return i18n.japaneseSushi[lang];
    case ERestaurantProductCuisineType.JAPANESE_TEMPURA:
      return i18n.japaneseTempura[lang];
    case ERestaurantProductCuisineType.JAPANESE_KAISEKI:
      return i18n.japaneseKaiseki[lang];
    case ERestaurantProductCuisineType.JAPANESE_KAPPO_RYORI:
      return i18n.japaneseKappoRyori[lang];
    case ERestaurantProductCuisineType.JAPANESE_TEPPANYAKI:
      return i18n.japaneseTeppanyaki[lang];
    case ERestaurantProductCuisineType.JAPANESE_YAKITORI_KUSHIYAKI_CHICKEN:
      return i18n.japaneseYakitoriKushiyakiChicken[lang];
    case ERestaurantProductCuisineType.JAPANESE_IZAKAYA_SAKE_BAR:
      return i18n.japaneseIzakayaSakeBar[lang];
    case ERestaurantProductCuisineType.JAPANESE_MODERN:
      return i18n.japaneseModern[lang];
    case ERestaurantProductCuisineType.STEAK_YAKINIKU_BBQ:
      return i18n.steakYakinikuBBQ[lang];
    case ERestaurantProductCuisineType.CHINESE_CANTONESE:
      return i18n.chineseCantonese[lang];
    case ERestaurantProductCuisineType.CHINESE_SICHUAN:
      return i18n.chineseSichuan[lang];
    case ERestaurantProductCuisineType.CHINESE_SHANGHAINESE:
      return i18n.chineseShanghainese[lang];
    case ERestaurantProductCuisineType.CHINESE_TAIWANESE:
      return i18n.chineseTaiwanese[lang];
    case ERestaurantProductCuisineType.CHINESE_ZHEJIANG:
      return i18n.chineseZhejiang[lang];
    case ERestaurantProductCuisineType.EUROPEAN:
      return i18n.european[lang];
    case ERestaurantProductCuisineType.FRENCH:
      return i18n.french[lang];
    case ERestaurantProductCuisineType.ITALIAN:
      return i18n.italian[lang];
    case ERestaurantProductCuisineType.FUSION:
      return i18n.fusion[lang];
    case ERestaurantProductCuisineType.KOREAN:
      return i18n.korean[lang];
    case ERestaurantProductCuisineType.SOUTH_EAST_ASIAN:
      return i18n.southEastAsian[lang];
    case ERestaurantProductCuisineType.OTHERS:
      return i18n.others[lang];
    default:
      return i18n.unspecified[lang];
  }
}
