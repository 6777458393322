import { isOk } from '@utils/is-ok';
import { Icon, IconTypes } from '@visual/icon';
import hash from 'object-hash';
import React from 'react';
import styles from './style.scss';

export class SelectBox extends React.PureComponent<Props> {
  private PLACEHOLDER_VALUE = '-999';

  public static defaultProps: Partial<Props> = {
    size: 'fit',
    isNullable: true,
    theme: 'primary',
  };

  public render(): JSX.Element {
    const { selectedValue, placeholder, size } = this.props;
    return (
      <div className={`${styles.container} ${styles[size]}`}>
        {this.getPrefixIcon()}
        <select
          className={this.getSelectBoxClassNames()}
          defaultValue={selectedValue ? selectedValue.toString() : null}
          onChange={(e) => this.handleOnChange(e)}
        >
          {placeholder && this.renderPlaceholder()}
          {this.renderItems()}
        </select>
        {this.getSuffixArrowIcon()}
      </div>
    );
  }

  protected getPrefixIcon(): JSX.Element {
    const { prefixIcon, theme } = this.props;
    if (!prefixIcon) return;
    return (
      <span className={styles.prefixIcon}>
        <Icon type={prefixIcon} color={theme === 'primary' ? 'secondary-dark2' : 'primary'} />
      </span>
    );
  }

  protected getSuffixArrowIcon(): JSX.Element {
    const { theme } = this.props;
    return (
      <span className={styles.suffixIcon}>
        <Icon
          type='down'
          color={theme === 'primary' ? 'secondary-dark2' : 'primary'}
          size='small'
        />
      </span>
    );
  }

  protected getSelectBoxClassNames() {
    const { size, theme, prefixIcon } = this.props;
    return [styles.selectBox, styles[size], styles[theme], !!prefixIcon && styles.hasPrefixIcon]
      .filter((style) => style)
      .join(' ');
  }

  protected handleOnChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const newValue = event.target.value;
    const { onChange } = this.props;
    if (!onChange) return;
    if (newValue === this.PLACEHOLDER_VALUE) {
      onChange(null);
    } else {
      onChange(newValue);
    }
  }

  protected renderItems(): JSX.Element[] {
    const { items, disableOption } = this.props;
    if (!isOk(items)) return;
    return items.map((option, i) => (
      <option
        key={hash(i)}
        value={option.id}
        disabled={disableOption && disableOption === option.id}
      >
        {option.text}
      </option>
    ));
  }

  protected renderPlaceholder(): JSX.Element {
    return (
      <option hidden={!this.props.isNullable} value={this.PLACEHOLDER_VALUE}>
        {this.props.placeholder}
      </option>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  items: Item[];
  selectedValue?: Item['id'];
  theme?: 'primary' | 'secondary';
  placeholder?: string;
  size?: 'fit' | 'full';
  isNullable?: boolean;
  prefixIcon?: IconTypes;
  disableOption?: number;
}

export interface IDispatchProps {
  onChange?: (value: string) => void;
}

export interface Item {
  id: number | string;
  text: string;
}
