import { i18n } from '@i18n/lang';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Theme,
  Tooltip as TooltipMUI,
  Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { styled, StyleRules, withStyles } from '@material-ui/core/styles';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { ECategoryType } from '@models/category-type';
import { IQuery } from '@models/query';
import { IStateApps } from '@models/state-apps';
import { IStateServers } from '@models/state-servers';
import { setCurrentPage } from '@redux/actions/appsActions';
import {
  getAlertAnalysis,
  getInventory,
  getLocationRegions,
  getLocations,
  getTransaction,
  setAnalysisQuery,
} from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import appLanguages from '@utils/app-languages';
import {
  getDateRangePicker2,
  getFilterButton,
  getSmallClearButton,
  getSmallClearButton2,
} from '@utils/common';
import { LIMIT_PAGINATION_MAX } from '@utils/default-query';
import { formatDate } from '@utils/format-date';
import { getCuisineLangList } from '@utils/get-cuisine-lang-list';
import { getUserCategory } from '@utils/get-user-category';
import { History, LocationState } from 'history';
import _ from 'lodash';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { compose } from 'redux';
import userEnv from 'userEnv';
import styles from './style.scss';

class alertManagementClass extends React.PureComponent<Props, State> {
  private basePath;

  private today = new Date();
  private setDefaultPeriod() {
    let d = new Date();
    //d.setFullYear(d.getFullYear() - 1);
    // default period changed to half an year
    d.setMonth(d.getMonth() - 6);
    return d;
  }

  private initialFilters = {
    filterCreatedAt: [this.setDefaultPeriod(), this.today],
    filterSalesOrder: '',
    filterDestinationId: '',
    filterBrandId: '',
    filterCountry: '',
    filterSubCategory: '',
    filterCuisineType: '',
    filterDist: '',
    filterRest: {},
    originStateRest: {},
    originStateCountry: {},
    originBrandId: {},
    originSubCategory: {},
    originCuisineType: {},
  };
  public query: any = {};
  public pages = {
    offset: 0,
    limit: LIMIT_PAGINATION_MAX,
  };

  constructor(props) {
    super(props);
    this.basePath = `${userEnv.rootDir}charts/`;

    const { servers, setAnalysisQuery } = this.props;
    const { user } = servers;

    const orderBy = [
      ['alertAt', 'DESC'],
      ['id', 'ASC'],
    ];

    const queryInit = {
      ...servers.analysisQuery,
      order: orderBy,
      searchText: null,
      where: {
        ...{ alertAt: { $gte: formatDate(this.setDefaultPeriod()) } },
        ...(user.location.category === ECategoryType.BREWERY && { brewLocId: user.locationId }),
        ...(user.location.category === ECategoryType.DISTRIBUTOR && { distLocId: user.locationId }),
        ...(user.location.category === ECategoryType.RESTAURANT && { alertLocId: user.locationId }),
      },
    };

    const alertNumInit = {
      totalAlertNum: 0,
      countryNum: 0,
      relatedDistNum: 0,
      distAlertNum: 0,
      alertDistNum: 0,
      restAlertNum: 0,
      alertRestNum: 0,
      restUnprocessableNum: 0,
      unprocessableRestNum: 0,
    };

    this.state = {
      filterTimeout: undefined,
      ...this.initialFilters,
      enableFilter: false,
      initialized: false,
      page_active1: 0,
      rowsPerPage_active1: 25,
      page_active2: 0,
      rowsPerPage_active2: 25,
      page_active3: 0,
      rowsPerPage_active3: 25,
      page_loc: 0,
      rowsPerPage_loc: 25,
      page_history: 0,
      rowsPerPage_history: 25,
      tableSortOrder_active: 'desc',
      tableSortKey_active: 'alertDate',
      tableSortOrder_loc: 'desc',
      tableSortKey_loc: 'total',
      tableSortOrder_loc_detail: 'desc',
      tableSortKey_loc_detail: 'total',
      tableSortOrder_history: 'desc',
      tableSortKey_history: 'slip',
      tableSortOrder_history_detail: 'desc',
      tableSortKey_history_detail: 'serialCode',
      rawAlertData: null,
      filterData: null,
      relationTree: { Countries: {} },
      brands: [],
      subCategories: [],
      cuisineTypes: [],
      windowSize: {
        width: 0,
        height: 0,
      },
      expandedRow: null,
      expandedRow_loc: null,
      query: queryInit,
      width: window.innerWidth,
      isLoading: false,
      monthCount: 0,
      alertGraphData: null,
      alertLocGraphData: null,
      activeAlert_delay: null,
      activeAlert_quality: null,
      activeAlert_gps: null,
      alertNum_delay: alertNumInit,
      alertNum_quality: alertNumInit,
      alertNum_gps: alertNumInit,
      activeAlertNum_delay: alertNumInit,
      activeAlertNum_quality: alertNumInit,
      activeAlertNum_gps: alertNumInit,
      restAlertNum: null,
      distAlertNum: null,
      alertTableSummary: null,
      alertTableDetail: null,
    };

    setAnalysisQuery(queryInit);
  }

  updateQuery(newQuery) {
    const { query } = this.state;
    const { setAnalysisQuery } = this.props;
    const queryTemp = { ...query, ...newQuery };
    this.setState({ query: queryTemp }, () => {
      setAnalysisQuery(query);
    });
  }

  async fetchAlertAnalysis(query, didMount?) {
    console.time('fetchAnalysis');
    this.setState({ isLoading: true });

    const { getAlertAnalysis } = this.props;

    /*if文の条件を付与するとタブを移動した際、チェックを外した属性のチェックボックス自体が生成されなくなる*/
    //if (!(this.props.servers.alertAnalysis[0] && didMount)) {
    console.time('fetchAnalysis-API kick');
    await getAlertAnalysis(query);
    console.timeEnd('fetchAnalysis-API kick');
    //}
    const { servers } = this.props;
    const { alertAnalysis } = servers;
    const allLocs = servers.allLocations;

    console.time('fetchAnalysis-other');

    let rawAlertData = alertAnalysis.map((row) => {
      return {
        id: row.id,
        alertAt: row.alertAt,
        slip: row.slip,
        serialId: row.serial ? row.serial.id : '-',
        serialCode: row.serialCode,
        type: row.type,
        status: row.status,
        alertLocId: row.alertLocId,
        locType: row.locType,
        brandId: row.brandId,
        brandName: row.brand ? row.brand.name : '-',
        brewLocId: row.brewLocId,
        distLocId: row.distLocId,
        restLocId: row.restLocId,
        createdAt: row.createdAt,
        updatedAt: row.updatedAt,
        country: row.location?.country,
        subCategoryId: row.location?.subCategory,
        cuisineTypeId: row.location?.cuisineType,
      };
    });

    const countryMapping = {
      CN: 'CHN',
      JP: 'JPN',
      HK: 'HKG',
      SG: 'SGP',
      TW: 'TWN',
      KR: 'KOR',
    };

    // add locName to rawAlertData
    rawAlertData = rawAlertData.reduce((acc, current) => {
      //fix for brewry or distributor alerts if restLocId is not null
      if (current.brewLocId === current.alertLocId) {
        current.distLocId = null;
        current.restLocId = null;
        current.locType = 1;
      } else if (current.distLocId === current.alertLocId) {
        current.restLocId = null;
        current.locType = 2;
      }

      const findResult = allLocs.find((e) => e.id === current.alertLocId);

      if (findResult) {
        //const mappedCountry = countryMapping[findResult['country']] || findResult['country'];
        const mappedCountry = findResult['country'];
        acc.push({
          ...current,
          country: mappedCountry,
          locName: findResult['name'],
          yearMonth: this.convertYMStr(current.alertAt),
        });
      } else {
        acc.push({
          ...current,
          country: null,
          locName: null,
          yearMonth: this.convertYMStr(current.alertAt),
        });
      }
      return acc;
    }, []);

    rawAlertData = rawAlertData.filter((item) => item.distLocId !== null);

    await this.setState({ rawAlertData: rawAlertData });
    console.timeEnd('fetchAnalysis-other');

    console.time('processAlertData');
    await this.processAlertData();
    console.timeEnd('processAlertData');

    console.time('fetchAnalysis-tree');
    if (this.state.filterData == null || this.state.filterData !== rawAlertData) {
      this.setState({ filterData: rawAlertData });
      this.processData(rawAlertData);
    }

    console.timeEnd('fetchAnalysis-tree');

    this.setState({ isLoading: false });
    console.timeEnd('fetchAnalysis');
  }

  async processAlertData() {
    const { apps, servers } = this.props;
    const lang = apps.currentLanguage;
    const { rawAlertData } = this.state;
    const locs = servers.allLocations?.map((locObj) => {
      return { id: locObj.id, name: locObj.name };
    });

    let alertDate, locCategory, alertStatus, alertType, alertLoc, brewLoc, distLoc, restLoc;
    const locCategoryMap = {
      0: 'unspecified',
      1: 'brewery',
      2: 'distributor',
      3: 'restaurant',
    };
    const alertTypeMap = {
      1: 'receivingScanDelayAlert',
      3: 'inventoryQualityAlert',
      6: 'illegalRouteAlert',
      7: 'gpsAlert',
      8: 'inventoryQualityAlert',
      9: 'gpsAlert',
    };
    const alertStatusMap = {
      0: 'unspecified',
      1: 'unprocessed',
      2: 'processing',
      3: 'processed',
      4: 'approved',
      5: 'unprocessable',
    };

    /* convert attr num to arrt str */
    let alertData =
      rawAlertData !== null
        ? await rawAlertData.filter((element: any) => {
            return (
              element.type === 1 ||
              element.type === 3 ||
              element.type === 6 ||
              element.type === 7 ||
              element.type === 8 ||
              element.type === 9
            );
          })
        : null;

    alertData = await alertData.map((alertObj) => {
      alertDate = this.convertDateLocale(alertObj.alertAt, lang);
      locCategory = '';
      if (locCategoryMap.hasOwnProperty(alertObj.locType)) {
        locCategory = locCategoryMap[alertObj.locType];
      }
      alertType = '';
      if (alertTypeMap.hasOwnProperty(alertObj.type)) {
        alertType = alertTypeMap[alertObj.type];
      }
      alertStatus = '';
      if (alertStatusMap.hasOwnProperty(alertObj.status)) {
        alertStatus = alertStatusMap[alertObj.status];
      }
      alertLoc = locs.find((obj) => obj.id === alertObj.alertLocId);
      brewLoc = locs.find((obj) => obj.id === alertObj.brewLocId);
      distLoc = locs.find((obj) => obj.id === alertObj.distLocId);
      restLoc = locs.find((obj) => obj.id === alertObj.restLocId);

      let newType = '';
      switch (alertObj.type) {
        case 1:
          newType = 'receivingScanDelayAlert';
          break;
        case 3:
          newType = 'inventoryQualityAlert';
          break;
        case 6:
          newType = 'gpsAlert';
          break;
        case 7:
          newType = 'gpsAlert';
          break;
        case 8:
          newType = 'inventoryQualityAlert';
          break;
        case 9:
          newType = 'gpsAlert';
          break;
      }

      return {
        ...alertObj,
        alertDate: alertDate,
        alertType: alertType !== '' ? i18n[alertType][lang] : '-',
        newType: newType,
        locCategory: locCategory !== '' ? i18n[locCategory][lang] : '-',
        alertStatus: alertStatus !== '' ? i18n[alertStatus][lang] : '-',
        alertLocation: alertLoc !== undefined ? alertLoc.name : '-',
        brewery: brewLoc !== undefined ? brewLoc.name : '-',
        distributor: distLoc !== undefined ? distLoc.name : '-',
        restaurant: restLoc !== undefined ? restLoc.name : '-',
      };
    });

    alertData = await alertData.filter((element: any) => {
      /* 倉庫の管理機能が実装されるまではディストリビューターのGPSアラートは非表示 */
      //return element.locType === 2 || element.locType === 3;
      return (element.locType === 2 && element.newType !== 'gpsAlert') || element.locType === 3;
    });

    alertData = await alertData
      .sort(function (a, b) {
        return a.serialCode < b.serialCode ? -1 : 1;
      })
      .sort(function (a, b) {
        return a.slip < b.slip ? -1 : 1;
      })
      .sort(function (a, b) {
        return a.alertAt > b.alertAt ? -1 : 1;
      });

    const targetMonths = await this.createData_yearMonth(alertData);
    const monthCount = targetMonths.length;
    this.setState({ monthCount: monthCount });

    const alertGraphData = await this.createData_alertGraph(alertData, targetMonths, lang);
    this.setState({ alertGraphData: alertGraphData });

    const alertLocGraphData = await this.createData_alertLocGraph(alertData, targetMonths, lang);
    this.setState({ alertLocGraphData: alertLocGraphData });

    /* Alert data for cards  */
    //DR alert data : all
    const alert_delay = await alertData.filter((element: any) => {
      return element.newType === 'receivingScanDelayAlert';
    });
    const alertNum_delay = await this.createData_alertNum(alert_delay);
    this.setState({ alertNum_delay: alertNum_delay });

    //QC alert data : all
    const alert_quality = await alertData.filter((element: any) => {
      return element.newType === 'inventoryQualityAlert';
    });
    const alertNum_quality = await this.createData_alertNum(alert_quality);
    this.setState({ alertNum_quality: alertNum_quality });

    //GPS alert data : all
    const alert_gps = await alertData.filter((element: any) => {
      return element.newType === 'gpsAlert';
    });
    const alertNum_gps = await this.createData_alertNum(alert_gps);
    this.setState({ alertNum_gps: alertNum_gps });

    /* Alert data for cards whose status is active */
    const activeAlertData = alertData.filter((element: any) => {
      return element.status === 1;
    });

    //DR alert data：active
    const activeAlert_delay = await activeAlertData.filter((element: any) => {
      return element.newType === 'receivingScanDelayAlert';
    });
    this.setState({ activeAlert_delay: activeAlert_delay });

    const activeAlertNum_delay = await this.createData_alertNum(activeAlert_delay);
    this.setState({ activeAlertNum_delay: activeAlertNum_delay });

    //QC alert data : active
    const activeAlert_quality = await activeAlertData.filter((element: any) => {
      return element.newType === 'inventoryQualityAlert';
    });
    this.setState({ activeAlert_quality: activeAlert_quality });

    const activeAlertNum_quality = await this.createData_alertNum(activeAlert_quality);
    this.setState({ activeAlertNum_quality: activeAlertNum_quality });

    //GPS alert data : active
    const activeAlert_gps = await activeAlertData.filter((element: any) => {
      return element.newType === 'gpsAlert';
    });
    this.setState({ activeAlert_gps: activeAlert_gps });

    const activeAlertNum_gps = await this.createData_alertNum(activeAlert_gps);
    this.setState({ activeAlertNum_gps: activeAlertNum_gps });

    const restAlertNum = await this.createData_restAlertNum(alertData);
    this.setState({ restAlertNum: restAlertNum });

    //total alert num of subordinate restaurant
    const distAlertNum = await (() => {
      const dist_group = _.groupBy(restAlertNum, (value) => {
        return value.distributor;
      });

      const totalAlertNum = _.map(dist_group, (obj) => {
        return {
          country: obj[0].country,
          name: obj[0].distributor,
          total: _.sumBy(obj, 'total'),
          delayAlertNum: _.sumBy(obj, 'delayAlertNum'),
          qualityAlertNum: _.sumBy(obj, 'qualityAlertNum'),
          gpsAlertNum: _.sumBy(obj, 'gpsAlertNum'),
          unprocessableNum: _.sumBy(obj, 'unprocessableNum'),
        };
      }).sort((a: any, b: any) => {
        return a.name < b.name ? -1 : 1;
      });

      return totalAlertNum;
    })();
    this.setState({ distAlertNum: distAlertNum });

    const alertTableSummary = await this.createData_alertSummaryTable(alertData);
    this.setState({ alertTableSummary: alertTableSummary });

    const alertTableDetail = await this.createData_alertDetailTable(alertData);
    this.setState({ alertTableDetail: alertTableDetail });
  }

  // convert date format based on chosen language
  convertDateLocale = (dateTime, lang) => {
    dateTime = new Date(dateTime);
    let newDateStr;
    let year = dateTime.getFullYear();
    let month = dateTime.getMonth();
    let date = dateTime.getDate();
    switch (lang) {
      case 'en':
        newDateStr = `${('00' + date).slice(-2)}/${('00' + (month + 1)).slice(-2)}/${year}`;
      default:
        newDateStr = `${year}/${('00' + (month + 1)).slice(-2)}/${('00' + date).slice(-2)}`;
        break;
    }
    return newDateStr;
  };

  createData_yearMonth = (data) => {
    const gr_mnth = _.groupBy(data, (value) => {
      return value.yearMonth;
    });

    const gr_mnth_obj = _.map(Object.keys(gr_mnth), (value) => {
      return {
        name: value,
      };
    });

    const months = Array.from(new Set(Object.values(gr_mnth_obj))).sort((a: any, b: any) => {
      return a.name < b.name ? -1 : 1;
    });

    let arr_months = [];
    months.forEach((element: any, index) => {
      arr_months[index] = element.name;
    });

    const start = arr_months[0];
    const end = arr_months[arr_months.length - 1];

    var startDate = new Date(start + '/01');
    var endDate = new Date(end + '/01');
    const diffMonth = (d1, d2) => {
      let d1Month, d2Month;
      d1Month = d1.getFullYear() * 12 + d1.getMonth();
      d2Month = d2.getFullYear() * 12 + d2.getMonth();
      return d2Month - d1Month;
    };

    const monthCount = diffMonth(startDate, endDate) + 1;
    let targetMonths = [];
    let month_obj, month_str, offset;

    // Create list in yyyy/mm format
    for (let i = 0; i < monthCount; i++) {
      if (i == 0) {
        offset = 0;
      } else {
        offset = 1;
      }
      month_obj = new Date(startDate.setMonth(startDate.getMonth() + offset));
      month_str = `${month_obj.getFullYear()}/${('00' + (month_obj.getMonth() + 1)).slice(-2)}`;
      targetMonths.push(month_str);
    }
    return targetMonths;
  };

  createData_alertGraph(data, targetMonths, lang) {
    // add flags of unprocessable
    data = data.map((item) => {
      if (item.status === 5) {
        return { ...item, unprocessable: 1 };
      } else {
        return { ...item, unprocessable: 0 };
      }
    });

    // remove duplicates
    /*
    const removeDupRows: any = (data) => {
      const uniqueEntries = new Set();
      return data.filter((entry) => {
        const key = `${entry.alertLocId}-${entry.serialCode}-${entry.yearMonth}-${entry.newType}-${entry.unprocessable}`;
        if (!uniqueEntries.has(key)) {
          uniqueEntries.add(key);
          return true;
        }
        return false;
      });
    };

    const uniqueData = removeDupRows(data);
    console.log(uniqueData);
    */

    const group = _.groupBy(data, (value) => {
      return value.yearMonth + '#' + value.newType + '#' + value.unprocessable;
    });

    let group_aggregated = _.map(group, (value) => {
      let typeStr = '';
      if (value[0]['newType'] === 'receivingScanDelayAlert') {
        if (value[0]['unprocessable'] === 1) {
          typeStr = i18n['DRSAlert'][lang] + '(' + i18n['unprocessable'][lang] + ')';
        } else {
          typeStr = i18n['DRSAlert'][lang];
        }
      } else if (value[0]['newType'] === 'inventoryQualityAlert') {
        if (value[0]['unprocessable'] === 1) {
          typeStr = i18n['QCAlert'][lang] + '(' + i18n['unprocessable'][lang] + ')';
        } else {
          typeStr = i18n['QCAlert'][lang];
        }
      } else if (value[0]['newType'] === 'gpsAlert') {
        if (value[0]['unprocessable'] === 1) {
          typeStr = i18n['gpsAlert'][lang] + '(' + i18n['unprocessable'][lang] + ')';
        } else {
          typeStr = i18n['gpsAlert'][lang];
        }
      }
      return {
        name: value[0].yearMonth,
        type: typeStr,
        count: value.length,
      };
    }).filter((value) => {
      return value.type !== '';
    });

    //create an empty object in the format CN:0,JP:0,...
    let keyArray = [
      i18n['DRSAlert'][lang],
      i18n['DRSAlert'][lang] + '(' + i18n['unprocessable'][lang] + ')',
      i18n['QCAlert'][lang],
      i18n['QCAlert'][lang] + '(' + i18n['unprocessable'][lang] + ')',
      i18n['gpsAlert'][lang],
      i18n['gpsAlert'][lang] + '(' + i18n['unprocessable'][lang] + ')',
    ];

    let key_month = {};
    keyArray.forEach((element, index) => {
      key_month[element] = 0;
    });

    //Aggregate in the form of CN:0,JP:0,...
    let group_reshaped = group_aggregated
      .reduce((acc, current) => {
        const findResult = acc.find((e) => e.name === current.name);
        if (findResult) {
          findResult[current['type']] = current.count;
        } else {
          acc.push({
            name: current.name,
            [current['type']]: current.count,
          });
        }
        return acc;
      }, [])
      .sort(function (a, b) {
        return a.name < b.name ? -1 : 1;
      });

    let alert_mnth = [];
    if (lang !== 'en') {
      alert_mnth = _.map(targetMonths, (item) => {
        return { name: item };
      });
    } else {
      //Convert year and month to format like Apr.2023
      group_reshaped = _.map(group_reshaped, (obj) => {
        const month_name = this.convertMonthStr(obj.name + '/01');
        return { ...obj, name: month_name };
      });

      alert_mnth = _.map(targetMonths, (item) => {
        const month_name = this.convertMonthStr(item + '/01');
        return { name: month_name };
      });
    }

    const alert_graph = alert_mnth.map((itemA) => {
      const itemB = group_reshaped.find((item) => item.name === itemA.name);
      return { ...itemA, ...itemB };
    });

    return alert_graph;
  }

  createData_alertLocGraph(data, targetMonths, lang) {
    //add flags of unprocessable
    data = data.map((item) => {
      if (item.status === 5) {
        return { ...item, unprocessable: 1 };
      } else {
        return { ...item, unprocessable: 0 };
      }
    });

    const group1 = _.groupBy(data, (value) => {
      return (
        value.yearMonth + '#' + value.newType + '#' + value.alertLocId + '#' + value.unprocessable
      );
    });

    const group_agg1 = _.map(group1, (value) => {
      let typeStr = '';
      if (value[0]['newType'] === 'receivingScanDelayAlert') {
        if (value[0]['unprocessable'] === 1) {
          typeStr = i18n['DRSAlert'][lang] + '(' + i18n['unprocessable'][lang] + ')';
        } else {
          typeStr = i18n['DRSAlert'][lang];
        }
      } else if (value[0]['newType'] === 'inventoryQualityAlert') {
        if (value[0]['unprocessable'] === 1) {
          typeStr = i18n['QCAlert'][lang] + '(' + i18n['unprocessable'][lang] + ')';
        } else {
          typeStr = i18n['QCAlert'][lang];
        }
      } else if (value[0]['newType'] === 'gpsAlert') {
        if (value[0]['unprocessable'] === 1) {
          typeStr = i18n['gpsAlert'][lang] + '(' + i18n['unprocessable'][lang] + ')';
        } else {
          typeStr = i18n['gpsAlert'][lang];
        }
      }
      return {
        name: value[0].yearMonth,
        type: typeStr,
        locId: value[0].alertLocId,
      };
    }).filter((value) => {
      return value.type !== '';
    });

    const group2 = _.groupBy(group_agg1, (value) => {
      return value.name + '#' + value.type;
    });

    const group_agg2 = _.map(group2, (value) => {
      return {
        name: value[0].name,
        type: value[0].type,
        count: value.length,
      };
    });

    //create an empty object in the format CN:0,JP:0,...
    let keyArray = [
      i18n['DRSAlert'][lang],
      i18n['DRSAlert'][lang] + '(' + i18n['unprocessable'][lang] + ')',
      i18n['QCAlert'][lang],
      i18n['QCAlert'][lang] + '(' + i18n['unprocessable'][lang] + ')',
      i18n['gpsAlert'][lang],
      i18n['gpsAlert'][lang] + '(' + i18n['unprocessable'][lang] + ')',
    ];

    let key_month = {};
    keyArray.forEach((element, index) => {
      key_month[element] = 0;
    });

    //Aggregate in the form of CN:0,JP:0,...
    let group_reshaped = group_agg2
      .reduce((acc, current) => {
        const findResult = acc.find((e) => e.name === current.name);
        if (findResult) {
          findResult[current['type']] = current.count;
        } else {
          acc.push({
            name: current.name,
            [current['type']]: current.count,
          });
        }
        return acc;
      }, [])
      .sort(function (a, b) {
        return a.name < b.name ? -1 : 1;
      });
    /*
    //Convert year and month to format like Apr.2023
    group_reshaped = _.map(group_reshaped, (obj) => {
      const month_name = this.convertMonthStr(obj.name + '/01');
      return { ...obj, name: month_name };
    });

    //Bind with the created object
    const alert_mnth = targetMonths.reduce((acc, current) => {
      const month_name = this.convertMonthStr(current + '/01');
      acc.push({
        name: month_name,
      });
      return acc;
    }, []);

    alert_mnth.reduce((acc, current) => {
      var index = group_reshaped.findIndex((e) => e.name === current.name);
      if (index !== -1) {
        Object.assign(current, group_reshaped[index]);
      }
    }, []);

    return alert_mnth;
    */
    let alert_mnth = [];
    if (lang !== 'en') {
      alert_mnth = _.map(targetMonths, (item) => {
        return { name: item };
      });
    } else {
      //Convert year and month to format like Apr.2023
      group_reshaped = _.map(group_reshaped, (obj) => {
        const month_name = this.convertMonthStr(obj.name + '/01');
        return { ...obj, name: month_name };
      });

      alert_mnth = _.map(targetMonths, (item) => {
        const month_name = this.convertMonthStr(item + '/01');
        return { name: month_name };
      });
    }

    const alert_graph = alert_mnth.map((itemA) => {
      const itemB = group_reshaped.find((item) => item.name === itemA.name);
      return { ...itemA, ...itemB };
    });

    return alert_graph;
  }

  createData_alertNum(data) {
    // remove duplicates
    /*
    const removeDupRows: any = (data) => {
      const uniqueEntries = new Set();
      return data.filter((entry) => {
        const key = `${entry.alertLocId}-${entry.serialCode}-${entry.yearMonth}-${entry.newType}-${entry.unprocessable}`;
        if (!uniqueEntries.has(key)) {
          uniqueEntries.add(key);
          return true;
        }
        return false;
      });
    };

    const uniqueData = removeDupRows(data);
    //const uniqueData = data;
    console.log(uniqueData);
    */
    const group_country = _.groupBy(data, (value) => {
      return value.country;
    });

    console.log(group_country);
    delete group_country.undefined;
    delete group_country.null;
    console.log(group_country);

    // number of related distributors
    const group_relatedDist = _.groupBy(data, (value) => {
      return value.distributor;
    });

    // for the number of distributors
    const distData = data.filter((element: any) => {
      return element.locType === 2;
    });

    const group_dist = _.groupBy(distData, (value) => {
      return value.alertLocId;
    });

    // for the number of restaurants
    const restData = data.filter((element: any) => {
      return element.locType === 3;
    });

    const group_rest = _.groupBy(restData, (value) => {
      return value.alertLocId;
    });

    // for the number of unprocessable in restaurant
    const restUnprocessableData = data.filter((element: any) => {
      return element.status === 5;
    });

    const group_unprocessableRest = _.groupBy(restUnprocessableData, (value) => {
      return value.alertLocId;
    });

    let alertNumObj = {
      totalAlertNum: data.length,
      countryNum: Object.keys(group_country).length,
      relatedDistNum: Object.keys(group_relatedDist).length,
      distAlertNum: distData.length,
      alertDistNum: Object.keys(group_dist).length,
      restAlertNum: restData.length,
      alertRestNum: Object.keys(group_rest).length,
      restUnprocessableNum: restUnprocessableData.length,
      unprocessableRestNum: Object.keys(group_unprocessableRest).length,
    };

    return alertNumObj;
  }

  createData_restAlertNum(data) {
    const group = _.groupBy(data, (value) => {
      return value.alertLocId + '#' + value.distLocId;
    });

    const restAlertNum = _.map(group, (obj) => {
      const delayAlertNum = obj.filter((element: any) => {
        return element.newType === 'receivingScanDelayAlert';
      }).length;

      const qualityAlertNum = obj.filter((element: any) => {
        return element.newType === 'inventoryQualityAlert';
      }).length;

      const gpsAlertNum = obj.filter((element: any) => {
        return element.newType === 'gpsAlert';
      }).length;

      const unprocessableNum = obj.filter((element: any) => {
        return element.status === 5;
      }).length;

      return {
        country: obj[0].country,
        name: obj[0].alertLocation,
        total: delayAlertNum + qualityAlertNum + gpsAlertNum,
        delayAlertNum: delayAlertNum,
        qualityAlertNum: qualityAlertNum,
        gpsAlertNum: gpsAlertNum,
        unprocessableNum: unprocessableNum,
        brewery: obj[0].brewery,
        distributor: obj[0].distributor,
      };
    }).sort(function (a, b) {
      return a.name < b.name ? -1 : 1;
    });

    return restAlertNum;
  }

  createData_alertSummaryTable(data) {
    /* create table data(summary rows) */
    const group_slip = _.groupBy(data, (value) => {
      return value.slip;
    });

    const group_slip_agg = _.map(group_slip, (value) => {
      const group_type = _.groupBy(value, (row) => {
        return row.newType;
      });

      const type_count = _.map(group_type, (value) => {
        return {
          type: value[0].newType,
          count: value.length,
        };
      });

      const alertNumFormat = {
        gpsAlert: 0,
        inventoryQualityAlert: 0,
        receivingScanDelayAlert: 0,
      };
      let alertNum = type_count.reduce((acc, current) => {
        Object.assign(acc, { [current.type]: current.count });
        return acc;
      }, []);
      alertNum = Object.assign(alertNumFormat, alertNum);

      return {
        slip: value[0].slip,
        country: value[0].country,
        distributor: value[0].distributor,
        ...alertNum,
      };
    });

    return group_slip_agg;
  }

  createData_alertDetailTable(data) {
    /* create table data(detail rows) */
    const group_serial = _.groupBy(data, (value) => {
      return value.slip + '#' + value.serialCode;
    });

    const group_serial_agg = _.map(group_serial, (value) => {
      let alertStatusDate = {
        dAlertDate: '-',
        dAlertStatus: '-',
        dAlertId: null,
        qAlertDate: '-',
        qAlertStatus: '-',
        qAlertId: null,
        gAlertDate: '-',
        gAlertStatus: '-',
        gAlertId: null,
      };

      _.map(value, (row) => {
        switch (row.newType) {
          case 'receivingScanDelayAlert':
            alertStatusDate.dAlertDate = row.alertDate;
            alertStatusDate.dAlertStatus = row.alertStatus;
            alertStatusDate.dAlertId = row.id;
            break;
          case 'inventoryQualityAlert':
            alertStatusDate.qAlertDate = row.alertDate;
            alertStatusDate.qAlertStatus = row.alertStatus;
            alertStatusDate.qAlertId = row.id;
            break;
          case 'gpsAlert':
            alertStatusDate.gAlertDate = row.alertDate;
            alertStatusDate.gAlertStatus = row.alertStatus;
            alertStatusDate.gAlertId = row.id;
            break;
        }
      });

      return {
        slip: value[0].slip,
        alertLocation: value[0].alertLocation,
        serialId: value[0].serialId,
        serialCode: value[0].serialCode,
        brandName: value[0].brandName,
        ...alertStatusDate,
      };
    });

    return group_serial_agg;
  }

  sortKeys = (obj) => {
    let sorted = { Countries: {} };
    const sortedKeys = Object.keys(obj.Countries).sort();
    const sortedData = sortedKeys.reduce((acc, key) => {
      acc[key] = obj.Countries[key];
      return acc;
    }, {});
    for (const countryKey in sortedData) {
      sorted.Countries[countryKey] = { Dists: {} };
    }

    let distSorted = sorted;
    for (const countryKey in sortedData) {
      const sortedDists = Object.values(obj.Countries[countryKey].Dists).sort((a: any, b: any) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      distSorted.Countries[countryKey] = {
        Dists: sortedDists.reduce((acc: any, dist: any) => {
          return { ...acc, [dist.name]: dist };
        }, {}),
      };
    }

    return distSorted;
  };

  processData(data) {
    const relationTree = { ...this.state.relationTree }; // Create a copy of the current state
    let filterCountry = { ...this.state.filterCountry };
    let brands = { ...this.state.brands };
    let subCategories = { ...this.state.subCategories };
    let cuisineTypes = { ...this.state.cuisineTypes };
    let filterRest = { ...this.state.filterRest };
    let filterBrandId = { ...this.state.filterBrandId };
    let filterCuisineType = { ...this.state.filterCuisineType };
    let filterSubCategory = { ...this.state.filterSubCategory };
    let originStateRest = { ...this.state.originStateRest };
    let originStateCountry = { ...this.state.originStateCountry };
    let originBrandId = { ...this.state.originBrandId };
    let originCuisineType = { ...this.state.originCuisineType };
    let originSubCategory = { ...this.state.originSubCategory };
    const { subCategoryMap } = userEnv;
    const { servers, apps } = this.props;
    const tempBrands = [];
    const tempSubCategory = [];
    const tempCuisineType = [];
    let newRest = filterRest;
    let newCountry = filterCountry;
    let newBrand = filterBrandId;
    let newCuisineType = filterCuisineType;
    let newSubCategoriesFilter = filterSubCategory;
    const lang = apps.currentLanguage;

    const filteredData = data.map((item) => ({
      country: item.country,
      distLocId: item.distLocId,
      restLocId: item.restLocId,
      brandName: item.brandName,
      brandId: item.brandId,
      subCategoryId: item.subCategoryId,
      cuisineTypeId: item.cuisineTypeId,
    }));

    // create unique value array without duplicates
    const uniqueFilteredData = filteredData.reduce((accumulator, item) => {
      const existingItem = accumulator.find(
        (existing) =>
          existing.country === item.country &&
          existing.distLocId === item.distLocId &&
          existing.restLocId === item.restLocId &&
          existing.brandName === item.brandName &&
          existing.brandId === item.brandId &&
          existing.subCategoryId === item.subCategoryId &&
          existing.cuisineTypeId === item.cuisineTypeId,
      );

      if (!existingItem) {
        accumulator.push(item);
      }

      return accumulator;
    }, []);

    uniqueFilteredData.forEach((item) => {
      let country = item.country;
      const distLocId = item.distLocId;
      const restLocId = item.restLocId;
      const brandName = item.brandName;
      const brandId = item.brandId;
      let subCategoryId = item.subCategoryId;
      let cuisineTypeId = item.cuisineTypeId;

      tempBrands.push({ id: brandId, name: brandName });

      if (subCategoryId != null) {
        const subCategory = {
          id: subCategoryId,
          name: subCategoryMap[lang][subCategoryId],
        };
        tempSubCategory.push(subCategory);
      }

      if (cuisineTypeId !== null || cuisineTypeId !== undefined) {
        const cuisineTypesLang = getCuisineLangList(lang);
        const cuisineTypeLang = cuisineTypesLang.find(
          (cuisineType) => cuisineType.id === cuisineTypeId,
        );
        tempCuisineType.push(cuisineTypeLang);
      }

      if (country != null) {
        if (!relationTree.Countries[country]) {
          relationTree.Countries[country] = { Dists: {} };
          if (newCountry[country] == null || newCountry[country] == undefined) {
            newCountry[country] = true;
          }
        }

        if (distLocId !== '' && distLocId !== null) {
          if (!relationTree.Countries[country].Dists[distLocId]) {
            let value;
            servers.allLocations?.map((locObj) => {
              if (locObj.id === distLocId) value = { ...locObj };
            });
            relationTree.Countries[country].Dists[distLocId] = {
              ...value,
              Rests: { [distLocId]: { ...value } },
            };
            if (newRest[distLocId] == null || newRest[distLocId] == undefined) {
              newRest[distLocId] = true;
            }
          }
          if (restLocId !== '' && restLocId !== null) {
            let value;
            servers.allLocations?.map((locObj) => {
              if (locObj.id === restLocId) value = { ...locObj };
            });
            relationTree.Countries[country].Dists[distLocId].Rests[restLocId] = { ...value };
            if (newRest[restLocId] == null || newRest[restLocId] == undefined) {
              newRest[restLocId] = true;
            }
          }
        } else {
          if (restLocId !== '' && restLocId !== null) {
            if (!relationTree.Countries[country].Dists[restLocId]) {
              let value;
              servers.allLocations?.map((locObj) => {
                if (locObj.id === restLocId) value = { ...locObj };
              });
              if (!relationTree.Countries[country].Dists[country]) {
                // In case of not going through a distributor
                /*
                relationTree.Countries[country].Dists[country] = {
                  id: [country],
                  name: i18n.notViaDist[lang],
                  Rests: { [restLocId]: { ...value } },
                };
                */
              } else {
                relationTree.Countries[country].Dists[country].Rests[restLocId] = { ...value };
              }
              if (newRest[restLocId] == null || newRest[restLocId] == undefined) {
                newRest[restLocId] = true;
              }
            }
          }
        }
      }
    });

    const tempNewBrands = { ...brands };
    tempBrands.forEach((obj: any, index) => {
      const newKey = Object.keys(tempNewBrands).length + index + 2;
      tempNewBrands[newKey] = obj;
    });
    const tempBrandsArray = Object.values(tempNewBrands);

    const newBrands: any = tempBrandsArray.reduce((uniqueArray: any, obj: any) => {
      // Check if any of the properties (id or name) are null or undefined
      if (obj.id === null || obj.id === undefined || obj.name === null || obj.name === undefined) {
        return uniqueArray; // Skip comparison if any property is null or undefined
      }

      // Check if the object is already present in the uniqueArray
      const isDuplicate = uniqueArray.some((t) => t.id === obj.id && t.name === obj.name);
      if (!isDuplicate) {
        uniqueArray.push(obj); // Add the object to the uniqueArray if it's not a duplicate
        if (newBrand[obj.id] == null || newBrand[obj.id] == 'undefined') newBrand[obj.id] = true;
      }

      return uniqueArray;
    }, []);

    const tempNewSubCategories = { ...subCategories };
    tempSubCategory.forEach((obj, index) => {
      const newKey = Object.keys(tempNewSubCategories).length + index + 2;
      tempNewSubCategories[newKey] = obj;
    });
    const tempSubCategoriesArray = Object.values(tempNewSubCategories);

    const newSubCategories: any = tempSubCategoriesArray.reduce((uniqueArray: any, obj: any) => {
      // Check if any of the properties (id or name) are null or undefined
      if (obj.id === null || obj.id === undefined || obj.name === null || obj.name === undefined) {
        return uniqueArray; // Skip comparison if any property is null or undefined
      }

      // Check if the object is already present in the uniqueArray
      const isDuplicate = uniqueArray.some((t) => t.id === obj.id && t.name === obj.name);
      if (!isDuplicate) {
        uniqueArray.push(obj); // Add the object to the uniqueArray if it's not a duplicate
        if (newSubCategoriesFilter[obj.id] == null || newSubCategoriesFilter[obj.id] == 'undefined')
          newSubCategoriesFilter[obj.id] = true;
      }

      return uniqueArray;
    }, []);

    const tempNewCuisineTypes = { ...cuisineTypes };
    tempCuisineType.forEach((obj, index) => {
      const newKey = Object.keys(tempNewCuisineTypes).length + index + 2;
      tempNewCuisineTypes[newKey] = obj;
    });
    const tempCuisineTypesArray = Object.values(tempNewCuisineTypes);

    const newCuisineTypes: any = tempCuisineTypesArray.reduce((uniqueArray: any, obj: any) => {
      // Check if any of the properties (id or name) are null or undefined
      if (obj === null || obj === undefined) {
        return uniqueArray; // Skip comparison if any property is null or undefined
      }

      // Check if the object is already present in the uniqueArray
      const isDuplicate = uniqueArray.some((t) => t.id === obj.id && t.name === obj.name);
      if (!isDuplicate) {
        uniqueArray.push(obj); // Add the object to the uniqueArray if it's not a duplicate
        if (newCuisineType[obj.id] == null || newCuisineType[obj.id] == 'undefined')
          newCuisineType[obj.id] = true;
      }

      return uniqueArray;
    }, []);
    filterRest = { ...filterRest, ...newRest };
    filterCountry = { ...filterCountry, ...newCountry };
    filterBrandId = { ...filterBrandId, ...newBrand };
    filterSubCategory = { ...filterSubCategory, ...newSubCategoriesFilter };
    filterCuisineType = { ...filterCuisineType, ...newCuisineType };
    originStateCountry = { ...originStateCountry, ...filterCountry };
    originStateRest = { ...originStateRest, ...filterRest };
    originBrandId = { ...originBrandId, ...filterBrandId };
    originSubCategory = { ...originSubCategory, ...filterSubCategory };
    originCuisineType = { ...originCuisineType, ...filterCuisineType };

    const sortedRelationTree = relationTree;
    const sortedBrands = newBrands.sort((a: any, b: any) => a.name.localeCompare(b.name));
    const sortedSubCategories = newSubCategories.sort((a: any, b: any) =>
      a.name.localeCompare(b.name),
    );
    const sortedCuisineTypes = newCuisineTypes.sort((a: any, b: any) =>
      a.name.localeCompare(b.name),
    );

    this.setState({ relationTree: sortedRelationTree });
    this.setState({ brands: sortedBrands });
    this.setState({ subCategories: sortedSubCategories });
    this.setState({ cuisineTypes: sortedCuisineTypes });
    this.setState({ filterRest: filterRest });
    this.setState({ filterCountry: filterCountry });
    this.setState({ filterBrandId: filterBrandId });
    this.setState({ filterSubCategory: filterSubCategory });
    this.setState({ filterCuisineType: filterCuisineType });
    this.setState({ originStateRest: this.toTrue(originStateRest) });
    this.setState({ originStateCountry: this.toTrue(originStateCountry) });
    this.setState({ originBrandId: this.toTrue(originBrandId) });
    this.setState({ originSubCategory: this.toTrue(originSubCategory) });
    this.setState({ originCuisineType: this.toTrue(originCuisineType) });
  }

  toTrue = (data) => {
    const updatedData = {};
    for (const key in data) {
      updatedData[key] = true;
    }
    return updatedData;
  };

  /* functions for getting window size */
  ref = React.createRef() as any;

  handleWindowResize = _.debounce(() => {
    const windowSize = this.ref.current.getBoundingClientRect();
    this.setState({ windowSize });
  });

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({
      width: window.innerWidth,
    });
  };

  public async componentDidMount() {
    console.time('componentDidMount');
    try {
      setCurrentPage('charts');
    } catch (err) {
      console.log(err, '-----error-----');
    }
    console.time('componentDidMount - getLocation');
    const { getLocationRegions, servers } = this.props;
    const { user } = servers;
    await getLocationRegions({});

    const query: any = {
      offset: 0,
      limit: 10000,
      searchText: '',
      order: [['updatedAt', 'DESC']],
      totalCounts: 0,
      where: {
        hidden: false,
        $or: [{ category: ECategoryType.DISTRIBUTOR }, { category: ECategoryType.RESTAURANT }],
      },
    };
    await getLocations(query);

    query.where = {
      ...{ alertAt: { $gte: formatDate(this.setDefaultPeriod()) } },
      ...(user.location.category === ECategoryType.BREWERY && { brewLocId: user.locationId }),
      ...(user.location.category === ECategoryType.DISTRIBUTOR && { distLocId: user.locationId }),
      ...(user.location.category === ECategoryType.RESTAURANT && { alertLocId: user.locationId }),
    };

    console.timeEnd('componentDidMount - getLocation');
    console.time('componentDidMount - fetchInvAnalysis');

    this.fetchAnalysis(query);
    console.timeEnd('componentDidMount - fetchInvAnalysis');

    query.where = {
      ...{ alertAt: { $gte: formatDate(this.setDefaultPeriod()) } },
      ...(user.location.category === ECategoryType.BREWERY && { brewLocId: user.locationId }),
      ...(user.location.category === ECategoryType.DISTRIBUTOR && { distLocId: user.locationId }),
      ...(user.location.category === ECategoryType.RESTAURANT && { alertLocId: user.locationId }),
    };

    console.time('componentDidMount - fetchAlertAnalysis');
    await this.fetchAlertAnalysis(query, true);
    console.timeEnd('componentDidMount - fetchAlertAnalysis');

    this.setState({ query: query });
    console.time('componentDidMount - getWindowSize');
    // get window size used for controling graph size
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    if (this.state.width > 1024) this.setState({ enableFilter: true });
    console.timeEnd('componentDidMount - getWindowSize');
    console.timeEnd('componentDidMount');
  }

  async fetchAnalysis(query) {
    const { servers, getInventory, getTransaction } = this.props;
    const { user } = servers;
    const query2 = query;
    query2.where = {
      ...{ latestScanAt: { $gte: formatDate(this.setDefaultPeriod()) } },
      ...(user.location.category === ECategoryType.BREWERY && { brewLocId: user.locationId }),
      ...(user.location.category === ECategoryType.DISTRIBUTOR && { distLocId: user.locationId }),
      ...(user.location.category === ECategoryType.RESTAURANT && { locId: user.locationId }),
    };

    if (!this.props.servers.transaction[0]) setTimeout(getTransaction(query), 100);

    const invQuery = query;
    invQuery.order = [];
    invQuery.where = {
      ...(user.location.category === ECategoryType.BREWERY && { brewLocId: user.locationId }),
      ...(user.location.category === ECategoryType.DISTRIBUTOR && { distLocId: user.locationId }),
      ...(user.location.category === ECategoryType.RESTAURANT && { locId: user.locationId }),
    };

    if (!this.props.servers.inventory[0]) setTimeout(getInventory(invQuery), 100);
  }

  /* function for convert date time into string */
  convertYMStr(dateTime) {
    dateTime = new Date(dateTime);
    let ymStr;
    let year = dateTime.getFullYear();
    let month = dateTime.getMonth();
    ymStr = `${year}/${('00' + (month + 1)).slice(-2)}`;
    return ymStr;
  }

  //Convert year and month to format like Apr.2023
  convertMonthStr(date) {
    const newDate = new Date(date);
    const month_name =
      new Intl.DateTimeFormat('en', { month: 'short' }).format(newDate) +
      '.' +
      newDate.getFullYear();
    return month_name;
  }

  handleChangeFilterBrand(v) {
    this.clearFilterTimeout();

    const { name, checked } = v;
    this.clearFilterTimeout();
    this.setState(
      (prevState) => {
        const filterBrandId = { ...prevState.filterBrandId };
        if (!filterBrandId[name]) {
          filterBrandId[name] = checked;
        } else {
          filterBrandId[name] = false;
        }
        return { filterBrandId };
      },
      () => {
        const { filterBrandId } = this.state;
        const newFilter = [];
        for (const name in filterBrandId) {
          if (filterBrandId[name]) {
            newFilter.push(name);
          }
        }
        const filter = {
          brandId: { $or: newFilter },
        };
        this.setFilter(filter);
      },
    );
  }

  handleChangeFilterDistDestination(v, country) {
    const { relationTree } = this.state;
    const { name, checked } = v;
    Object.values(relationTree.Countries[country].Dists[name].Rests).map((rest: any) => {
      this.handleChangeFilterRestDestination({ name: rest.id, checked: checked });
    });
  }

  handleChangeFilterRestDestination(v) {
    const { name, checked } = v;
    const { servers } = this.props;
    this.setState(
      (prevState) => {
        const filterRest = prevState.filterRest;
        filterRest[name] = checked;
        return { filterRest };
      },
      () => {
        const { filterRest } = this.state;
        const filter = Object.entries(filterRest).reduce((acc, [name, value]) => {
          if (value) {
            const matchingLocations = servers.allLocations?.filter(
              (locObj) => locObj.id === Number(name),
            );
            matchingLocations.forEach((locObj) => {
              if (locObj.category === 3) {
                acc.push({ restLocId: name });
              } else {
                acc.push({ distLocId: name });
              }
            });
          }
          return acc;
        }, []);

        const { user } = servers;
        /*
        const baseWhere = {
          //...(user.location.category === ECategoryType.BREWERY && { locId: user.locationId }),
          ...(user.location.category === ECategoryType.DISTRIBUTOR && {
            alertLocId: user.locationId,
          }),
          ...(user.location.category === ECategoryType.RESTAURANT && {
            alertLocId: user.locationId,
          }),
        };
        filter.push(baseWhere);
        */
        this.setFilter({ $or: filter });
      },
    );
  }

  async handleClickResetFilter() {
    //this.setState({ isLoading: true });

    const {
      originStateRest,
      originStateCountry,
      originBrandId,
      originSubCategory,
      originCuisineType,
    } = this.state;
    this.setState({
      filterCreatedAt: [this.setDefaultPeriod(), this.today],
      filterSalesOrder: '',
      filterDestinationId: '',
      filterBrandId: originBrandId,
      filterCountry: originStateCountry,
      filterSubCategory: originSubCategory,
      filterCuisineType: originCuisineType,
      filterDist: '',
      filterRest: originStateRest,
    });
    const { servers } = this.props;
    const { user } = servers;
    const query = {
      ...servers.analysisQuery,
      where: {
        alertAt: { $gte: formatDate(this.setDefaultPeriod()) },
        ...(user.location.category === ECategoryType.BREWERY && { brewLocId: user.locationId }),
        ...(user.location.category === ECategoryType.DISTRIBUTOR && { distLocId: user.locationId }),
        ...(user.location.category === ECategoryType.RESTAURANT && { alertLocId: user.locationId }),
      },
    };
    this.updateQuery(query);
    await this.fetchAlertAnalysis(query);

    //this.setState({ isLoading: false });
  }

  async handleClickSearch() {
    //this.setState({ isLoading: true });

    const { query } = this.state;

    if (this.filterCheck()) {
      await this.setState({ initialized: false }, () => {
        this.fetchAlertAnalysis(query);
      });
    } else {
      this.setState({ rawAlertData: [] });

      const invalidQuery = {
        ...query,
        where: {
          brewLocId: null,
        },
      };
      //In case all of the checkboxes are unchecked, issue unvalid sql and fetch nothing
      /*
      const invalidQuery: any = {
        offset: 0,
        limit: 10000,
        searchText: '',
        order: [['updatedAt', 'DESC']],
        totalCounts: 0,
        where: {
          hidden: false,
          brewLocId: null,
        },
      };
      */

      await this.setState({ initialized: false }, () => {
        this.fetchAlertAnalysis(invalidQuery);
      });
    }

    //this.setState({ isLoading: false });
  }

  filterCheck() {
    const lists = [
      this.state.filterBrandId,
      //this.state.filterCountry,
      this.state.filterRest,
      this.state.filterSubCategory,
      this.state.filterCuisineType,
      this.state.filterRest,
    ];

    // Check if there is at least one "true" value in each list
    const result = lists.every((list) => Object.values(list).some((value) => value === true));
    return result;
  }

  handleChangeFilterCountry(v) {
    const { relationTree } = this.state;
    const { name, checked } = v;
    Object.values(relationTree.Countries[name].Dists).map((dist: any) => {
      this.handleChangeFilterDistDestination({ name: dist.id, checked: checked }, name);
    });
    this.setState(
      (prevState) => {
        const filterCountry = { ...prevState.filterCountry };
        filterCountry[name] = checked;
        return { filterCountry };
      },
      /*,
      () => {
        const { filterCountry } = this.state;
        const newFilter = [];
        for (const name in filterCountry) {
          //console.log('restFilter ' + JSON.stringify(filterCountry));
          //console.log('name ' + name);
          newFilter.push({ country: name });
        }
        const filter = {
          country: { $or: newFilter },
        };
        //console.log('filterCountry ' + JSON.stringify(filterCountry));
        this.setFilter(filter);
      },
      */
    );
  }
  handleChangeCreatedAt(v) {
    this.handleChangeTime('filterCreatedAt', 'alertAt', v);
  }

  handleChangeTime(filterKey, dbKey, v) {
    this.clearFilterTimeout();
    const from = v && v.length && new Date(v[0].setHours(0, 0, 0));
    const to = v && v.length && new Date(v[1].setHours(23, 59, 59));
    const newState: Partial<State> = {};

    newState[filterKey] = v;

    newState.filterTimeout = setTimeout(() => {
      if (v.length) {
        this.setFilter({ [dbKey]: { $gte: from.toISOString(), $lte: to.toISOString() } });
      } else {
        this.unsetFilter(dbKey);
      }
    }, 1000);

    this.setState(newState as State);
  }

  protected handleChangeSubCategory(v) {
    this.clearFilterTimeout();
    const { name, checked } = v;
    this.setState(
      (prevState) => {
        const filterSubCategory = { ...prevState.filterSubCategory };
        if (!filterSubCategory[name]) {
          filterSubCategory[name] = checked;
        } else {
          filterSubCategory[name] = false;
        }
        return { filterSubCategory };
      },
      () => {
        const { filterSubCategory } = this.state;
        const newFilter = [];
        for (const name in filterSubCategory) {
          if (filterSubCategory[name]) {
            newFilter.push(name);
          }
        }
        const filter = {
          subCategory: { $or: newFilter },
        };
        this.setFilter(filter);
      },
    );
  }

  protected handleChangeFilterCuisineType(v) {
    this.clearFilterTimeout();
    const { name, checked } = v;
    this.clearFilterTimeout();
    this.setState(
      (prevState) => {
        const filterCuisineType = { ...prevState.filterCuisineType };
        if (!filterCuisineType[name]) {
          filterCuisineType[name] = checked;
        } else {
          filterCuisineType[name] = false;
        }
        return { filterCuisineType };
      },
      () => {
        const { filterCuisineType } = this.state;
        const newFilter = [];
        for (const name in filterCuisineType) {
          if (filterCuisineType[name]) {
            newFilter.push(name);
          }
        }
        const filter = {
          cuisineType: { $or: newFilter },
        };
        this.setFilter(filter);
      },
    );
  }

  handleChangeFilterSalesOrder(v) {
    this.clearFilterTimeout();
    this.setState({
      filterSalesOrder: v,
      filterTimeout: setTimeout(() => this.setFilter({ distSlip: { $like: `%${v}%` } }), 1000),
    });
  }

  clearFilterTimeout() {
    const { filterTimeout } = this.state;

    if (filterTimeout) clearTimeout(filterTimeout);
  }

  setFilter(newWhere) {
    const { query } = this.state;
    const where = { ...query.where, ...newWhere };
    const queryUpd = Object.assign({}, query, { where });
    this.updateQuery(queryUpd);
    this.setState({ filterTimeout: null });
  }

  unsetFilter(key) {
    const { servers } = this.props;
    const { where } = servers.analysisQuery;
    if (where[key]) delete where[key];
    this.setFilter(where);
  }

  protected chartsView() {
    const { apps, servers, classes } = this.props;
    const lang = apps.currentLanguage;
    const {
      monthCount,
      alertGraphData,
      alertLocGraphData,
      alertNum_delay,
      alertNum_quality,
      alertNum_gps,
      activeAlert_delay,
      activeAlert_quality,
      activeAlert_gps,
      activeAlertNum_delay,
      activeAlertNum_quality,
      activeAlertNum_gps,
      restAlertNum,
      distAlertNum,
      alertTableSummary,
      alertTableDetail,
    } = this.state;

    const CustomCard = styled(Card)(({ theme }) => ({
      border: '1px solid darkgray',
      //boxShadow: '1px 1px 1px 1px lightgray',
    }));

    const insertTags_cards = (data, title, alertType) => {
      const category = getUserCategory(servers.user);
      let tag = [];
      let strColor = 'black';

      switch (title) {
        case i18n.receivingScanDelayAlert[lang]:
          strColor = '#3495f0';
          break;
        case i18n.inventoryQualityAlert[lang]:
          strColor = '#77c175';
          break;
        case i18n.gpsAlert[lang]:
          strColor = '#f4a507';
          break;
      }

      switch (category) {
        case ECategoryType.ADMIN:
        case ECategoryType.BREWERY:
          if (alertType === 'active') {
            tag.push(
              <div className={styles.graphTopAlertBrew}>
                <CustomCard className={styles.cardAlertActiveBrew} elevation={2}>
                  <h4 className={styles.cardTitleBrew}>{title}</h4>
                  <div className={styles.cardAlertDividerTop}>
                    <Divider />
                    <p className={styles.cardItemCategory}>{i18n['cardSummary'][lang]}</p>
                  </div>

                  <Grid container spacing={3} alignItems='flex-start'>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValue} style={{ color: strColor }}>
                        {data.totalAlertNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['alertTotal'][lang]}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValue} style={{ color: strColor }}>
                        {data.countryNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['alertCountryNum'][lang]}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValue} style={{ color: strColor }}>
                        {data.relatedDistNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['relatedDistNum'][lang]}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValue} style={{ color: strColor }}>
                        {data.alertRestNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['alertRestNum'][lang]}</p>
                    </Grid>
                  </Grid>

                  <div className={styles.cardAlertDividerBottom}>
                    <Divider />
                    <p className={styles.cardItemCategory}>{i18n['cardDetail'][lang]}</p>
                  </div>

                  <Grid container spacing={3} alignItems='flex-start'>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValue} style={{ color: strColor }}>
                        {data.distAlertNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['distAlertNum'][lang]}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValue} style={{ color: strColor }}>
                        {data.alertDistNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['alertDistNum'][lang]}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValue} style={{ color: strColor }}>
                        {data.restAlertNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['restAlertNum'][lang]}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValue} style={{ color: strColor }}>
                        {data.alertRestNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['alertRestNum'][lang]}</p>
                    </Grid>
                  </Grid>
                </CustomCard>
              </div>,
            );
          } else if (alertType === 'history') {
            tag.push(
              <div className={styles.graphTopAlertBrew}>
                <CustomCard className={styles.cardAlertHistBrew} elevation={2}>
                  <h4 className={styles.cardTitleBrew}>{title}</h4>
                  <div className={styles.cardAlertDividerTop}>
                    <Divider />
                    <p className={styles.cardItemCategory}>{i18n['cardSummary'][lang]}</p>
                  </div>

                  <Grid container spacing={3} alignItems='flex-start'>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValue} style={{ color: strColor }}>
                        {data.totalAlertNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['alertTotal'][lang]}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValue} style={{ color: strColor }}>
                        {data.countryNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['alertCountryNum'][lang]}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValue} style={{ color: strColor }}>
                        {data.relatedDistNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['relatedDistNum'][lang]}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValue} style={{ color: strColor }}>
                        {data.alertRestNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['alertRestNum'][lang]}</p>
                    </Grid>
                  </Grid>

                  <div className={styles.cardAlertDividerBottom}>
                    <Divider />
                    <p className={styles.cardItemCategory}>{i18n['cardDetail'][lang]}</p>
                  </div>

                  <Grid container spacing={3} alignItems='flex-start'>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValue} style={{ color: strColor }}>
                        {data.distAlertNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['distAlertNum'][lang]}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValue} style={{ color: strColor }}>
                        {data.alertDistNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['alertDistNum'][lang]}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValue} style={{ color: strColor }}>
                        {data.restAlertNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['restAlertNum'][lang]}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValue} style={{ color: strColor }}>
                        {data.alertRestNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['alertRestNum'][lang]}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValueUnprocessable}>
                        {data.restUnprocessableNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['restUnprocessableNum'][lang]}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValueUnprocessable}>
                        {data.unprocessableRestNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['unprocessableRestNum'][lang]}</p>
                    </Grid>
                  </Grid>
                </CustomCard>
              </div>,
            );
          }
          break;
        case ECategoryType.DISTRIBUTOR:
          if (alertType === 'active') {
            tag.push(
              <div className={styles.graphTopAlert}>
                <CustomCard className={styles.cardAlert} elevation={2}>
                  <h4 className={styles.cardTitle}>{title}</h4>
                  <Divider />
                  <Grid container spacing={3} alignItems='flex-start'>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValue} style={{ color: strColor }}>
                        {data.distAlertNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['ownSiteAlertNum'][lang]}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <></>
                    </Grid>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValue} style={{ color: strColor }}>
                        {data.restAlertNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['restAlertNum'][lang]}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValue} style={{ color: strColor }}>
                        {data.alertRestNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['alertRestNum'][lang]}</p>
                    </Grid>
                  </Grid>
                </CustomCard>
              </div>,
            );
          } else if (alertType === 'history') {
            tag.push(
              <div className={styles.graphTopAlert}>
                <CustomCard className={styles.cardAlertHistDist} elevation={2}>
                  <h4 className={styles.cardTitle}>{title}</h4>
                  <Divider />
                  <Grid container spacing={3} alignItems='flex-start'>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValue} style={{ color: strColor }}>
                        {data.distAlertNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['ownSiteAlertNum'][lang]}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <></>
                    </Grid>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValue} style={{ color: strColor }}>
                        {data.restAlertNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['restAlertNum'][lang]}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValue} style={{ color: strColor }}>
                        {data.alertRestNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['alertRestNum'][lang]}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValueUnprocessable}>
                        {data.restUnprocessableNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['restUnprocessableNum'][lang]}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValueUnprocessable}>
                        {data.unprocessableRestNum}
                      </h4>
                      <p className={styles.cardItemTitle}>{i18n['unprocessableRestNum'][lang]}</p>
                    </Grid>
                  </Grid>
                </CustomCard>
              </div>,
            );
          }
          break;
        case ECategoryType.RESTAURANT:
          if (alertType === 'active') {
            tag.push(
              <div className={styles.graphTopAlertRest}>
                <CustomCard className={styles.cardAlertRest} elevation={2}>
                  <h4 className={styles.cardTitleRest}>{title}</h4>
                  <Divider />
                  <Grid container spacing={3} alignItems='flex-start'>
                    <Grid item xs={12}>
                      <h4 className={styles.cardItemValueRest} style={{ color: strColor }}>
                        {data.restAlertNum}
                      </h4>
                      <p className={styles.cardItemTitleRest}>{i18n['ownSiteAlertNum'][lang]}</p>
                    </Grid>
                  </Grid>
                </CustomCard>
              </div>,
            );
          } else if (alertType === 'history') {
            tag.push(
              <div className={styles.graphTopAlertRest}>
                <CustomCard className={styles.cardAlertRest} elevation={2}>
                  <h4 className={styles.cardTitleRest}>{title}</h4>
                  <Divider />
                  <Grid container spacing={3} alignItems='flex-start'>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValueRest} style={{ color: strColor }}>
                        {data.restAlertNum}
                      </h4>
                      <p className={styles.cardItemTitleRest}>{i18n['ownSiteAlertNum'][lang]}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <h4 className={styles.cardItemValueRestUnprocessable}>
                        {data.restUnprocessableNum}
                      </h4>
                      <p className={styles.cardItemTitleRest}>
                        {i18n['restUnprocessableNum'][lang]}
                      </p>
                    </Grid>
                  </Grid>
                </CustomCard>
              </div>,
            );
          }
          break;
      }

      return tag;
    };

    //for Bar Chart
    const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
      const { x, y, payload } = props;
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={16}
            textAnchor='end'
            fill='#666'
            transform='rotate(-35)'
            style={{ fontSize: 13 }}
          >
            {payload.value}
          </text>
        </g>
      );
    };

    //for Line Chart
    const CustomizedLabel: FunctionComponent<any> = (props: any) => {
      const { x, y, stroke, value } = props;
      return (
        <text x={x} y={y} dy={-4} fill={stroke} textAnchor='middle'>
          {value}
        </text>
      );
    };

    const CustomLegend = (props) => {
      const { payload } = props;
      /*
      const scanDelay_up_str = i18n['DRSAlert'][lang] + '(' + i18n['unprocessable'][lang] + ')';
      const quality_up_str = i18n['QCAlert'][lang] + '(' + i18n['unprocessable'][lang] + ')';
      const gps_up_str = i18n['gpsAlert'][lang] + '(' + i18n['unprocessable'][lang] + ')';

      //each unresolved alert does not show the legend
      let filteredData = payload.filter(
        (entry) =>
          entry.dataKey !== 'name' &&
          entry.dataKey !== scanDelay_up_str &&
          entry.dataKey !== quality_up_str &&
          entry.dataKey !== gps_up_str,
      );

      filteredData.push({
        dataKey: 'Dummy',
        type: 'square',
        value: i18n['unprocessable'][lang],
        color: '#e65151',
      });
      */
      return (
        <div
          style={{
            alignItems: 'right',
            justifyContent: 'center',
            marginBottom: 10,
            //paddingLeft: 70,
            fontSize: 13,
          }}
        >
          {payload.map((entry) => (
            <div
              key={`item-${entry.dataKey}`}
              style={{ display: 'flex', alignItems: 'center', marginLeft: 60 }}
            >
              <svg width={10} height={10} style={{ marginRight: 5 }}>
                <rect fill={entry.color} width={10} height={10} />
              </svg>
              <div>{entry.value}</div>
            </div>
          ))}
        </div>
      );
    };

    const insertTags_alertBarchart = (data, typeStr, color) => {
      if (data !== null && data !== undefined) {
        //Filters the items of the object to the items passed in the typeStr argument
        const filteredData = data.map((item) => {
          const filteredItem = {
            name: item.name,
          };
          //convert item name including the string of 'unprocessable'
          if (i18n[typeStr][lang] + '(' + i18n['unprocessable'][lang] + ')' in item) {
            filteredItem[i18n['unprocessable'][lang]] =
              item[i18n[typeStr][lang] + '(' + i18n['unprocessable'][lang] + ')'];
          }
          if (i18n[typeStr][lang] in item) {
            filteredItem[i18n[typeStr][lang]] = item[i18n[typeStr][lang]];
          }
          return filteredItem;
        });

        let tag = [];
        tag.push(
          <ResponsiveContainer width={'100%'} height={'100%'}>
            <BarChart
              data={filteredData}
              style={{ fontSize: '14px' }}
              margin={{
                top: 0,
                right: 10,
                left: 25,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='name' interval={0} height={60} tick={<CustomizedAxisTick />} />
              <YAxis
                label={{
                  value: i18n['alertGraphYAxis'][lang],
                  angle: -90,
                  position: 'insideBottomLeft',
                }}
              />
              <Tooltip />
              <Legend
                verticalAlign='top'
                align='right'
                iconType='square'
                content={<CustomLegend />}
              />
              <Bar
                dataKey={i18n[typeStr][lang]}
                fill={color}
                maxBarSize={20}
                stackId='a'
                radius={[3, 3, 3, 3]}
              >
                <LabelList
                  dataKey={i18n[typeStr][lang]}
                  position='center'
                  fontSize={12}
                  label={<CustomizedLabel />}
                />
              </Bar>
              <Bar
                dataKey={i18n['unprocessable'][lang]}
                fill='#e65151'
                maxBarSize={20}
                stackId='a'
                radius={[3, 3, 3, 3]}
              >
                <LabelList
                  dataKey={i18n['unprocessable'][lang]}
                  position='center'
                  fontSize={12}
                  label={<CustomizedLabel />}
                />
              </Bar>
              <Brush
                className='LineChart-brush'
                dataKey='name'
                stroke='gray'
                height={15}
                startIndex={monthCount > 12 ? monthCount - 12 : 0}
              />
            </BarChart>
          </ResponsiveContainer>,
        );
        return tag;
      }
    };

    const insertTags_alertLocBarchart = (data) => {
      const category = getUserCategory(servers.user);

      if (data !== null && data !== undefined) {
        //Filters the items of the object to the items passed in the typeStr argument
        const DRSAlertData = data.map((item) => {
          const filteredItem = {
            name: item.name,
          };
          //convert item name including the string of 'unprocessable'
          if (i18n['DRSAlert'][lang] + '(' + i18n['unprocessable'][lang] + ')' in item) {
            filteredItem[i18n['unprocessable'][lang]] =
              item[i18n['DRSAlert'][lang] + '(' + i18n['unprocessable'][lang] + ')'];
          }
          if (i18n['DRSAlert'][lang] in item) {
            filteredItem[i18n['DRSAlert'][lang]] = item[i18n['DRSAlert'][lang]];
          }
          return filteredItem;
        });

        const QCAlertData = data.map((item) => {
          const filteredItem = {
            name: item.name,
          };
          //convert item name including the string of 'unprocessable'
          if (i18n['QCAlert'][lang] + '(' + i18n['unprocessable'][lang] + ')' in item) {
            filteredItem[i18n['unprocessable'][lang]] =
              item[i18n['QCAlert'][lang] + '(' + i18n['unprocessable'][lang] + ')'];
          }
          if (i18n['QCAlert'][lang] in item) {
            filteredItem[i18n['QCAlert'][lang]] = item[i18n['QCAlert'][lang]];
          }
          return filteredItem;
        });

        const gpsAlertData = data.map((item) => {
          const filteredItem = {
            name: item.name,
          };
          //convert item name including the string of 'unprocessable'
          if (i18n['gpsAlert'][lang] + '(' + i18n['unprocessable'][lang] + ')' in item) {
            filteredItem[i18n['unprocessable'][lang]] =
              item[i18n['gpsAlert'][lang] + '(' + i18n['unprocessable'][lang] + ')'];
          }
          if (i18n['gpsAlert'][lang] in item) {
            filteredItem[i18n['gpsAlert'][lang]] = item[i18n['gpsAlert'][lang]];
          }
          return filteredItem;
        });

        let tag = [];
        switch (category) {
          case ECategoryType.ADMIN:
          case ECategoryType.BREWERY:
          case ECategoryType.DISTRIBUTOR:
            tag.push(
              <>
                <h4 className={styles.graphtitle}>{i18n.alertLocNum[lang]}</h4>
                <div className={styles.description}>
                  <h5>
                    <span>
                      <EmojiObjectsOutlinedIcon className={styles.bulbIcon} />
                    </span>
                    {i18n.analysisDescription11[lang]}
                  </h5>
                </div>

                <div className={styles.wrapper}>
                  <div className={styles.graphLocAlert}>
                    <ResponsiveContainer width={'100%'} height={'100%'}>
                      <BarChart
                        data={DRSAlertData}
                        style={{ fontSize: '14px' }}
                        margin={{
                          top: 0,
                          right: 10,
                          left: 25,
                          bottom: 0,
                        }}
                      >
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis
                          dataKey='name'
                          interval={0}
                          height={60}
                          tick={<CustomizedAxisTick />}
                        />
                        <YAxis
                          label={{
                            value: i18n['alertLocGraphYAxis'][lang],
                            angle: -90,
                            position: 'insideBottomLeft',
                          }}
                        />
                        <Tooltip />
                        <Legend verticalAlign='top' iconType='square' content={<CustomLegend />} />
                        <Bar
                          dataKey={i18n['DRSAlert'][lang]}
                          fill={'#5fa9ef'}
                          maxBarSize={20}
                          stackId='a'
                          radius={[3, 3, 3, 3]}
                        >
                          <LabelList
                            dataKey={i18n['DRSAlert'][lang]}
                            position='center'
                            fontSize={12}
                            label={<CustomizedLabel />}
                          />
                        </Bar>
                        <Bar
                          dataKey={i18n['unprocessable'][lang]}
                          fill='#e65151'
                          maxBarSize={20}
                          stackId='a'
                          radius={[3, 3, 3, 3]}
                        >
                          <LabelList
                            dataKey={i18n['unprocessable'][lang]}
                            position='center'
                            fontSize={12}
                            label={<CustomizedLabel />}
                          />
                        </Bar>
                        <Brush
                          className='LineChart-brush'
                          dataKey='name'
                          stroke='gray'
                          height={15}
                          startIndex={monthCount > 12 ? monthCount - 12 : 0}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>

                  <div className={styles.graphLocAlert}>
                    <ResponsiveContainer width={'100%'} height={'100%'}>
                      <BarChart
                        data={QCAlertData}
                        style={{ fontSize: '14px' }}
                        margin={{
                          top: 0,
                          right: 10,
                          left: 25,
                          bottom: 0,
                        }}
                      >
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis
                          dataKey='name'
                          interval={0}
                          height={60}
                          tick={<CustomizedAxisTick />}
                        />
                        <YAxis
                          label={{
                            value: i18n['alertLocGraphYAxis'][lang],
                            angle: -90,
                            position: 'insideBottomLeft',
                          }}
                        />
                        <Tooltip />
                        <Legend verticalAlign='top' iconType='square' content={<CustomLegend />} />
                        <Bar
                          dataKey={i18n['QCAlert'][lang]}
                          fill={'#8ec28c'}
                          maxBarSize={20}
                          stackId='a'
                          radius={[3, 3, 3, 3]}
                        >
                          <LabelList
                            dataKey={i18n['QCAlert'][lang]}
                            position='center'
                            fontSize={12}
                            label={<CustomizedLabel />}
                          />
                        </Bar>
                        <Bar
                          dataKey={i18n['unprocessable'][lang]}
                          fill='#e65151'
                          maxBarSize={20}
                          stackId='a'
                          radius={[3, 3, 3, 3]}
                        >
                          <LabelList
                            dataKey={i18n['unprocessable'][lang]}
                            position='center'
                            fontSize={12}
                            label={<CustomizedLabel />}
                          />
                        </Bar>
                        <Brush
                          className='LineChart-brush'
                          dataKey='name'
                          stroke='gray'
                          height={15}
                          startIndex={monthCount > 12 ? monthCount - 12 : 0}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>

                  <div className={styles.graphLocAlert}>
                    {category == ECategoryType.ADMIN && (
                      <ResponsiveContainer width={'100%'} height={'100%'}>
                        <BarChart
                          data={gpsAlertData}
                          style={{ fontSize: '14px' }}
                          margin={{
                            top: 0,
                            right: 10,
                            left: 25,
                            bottom: 0,
                          }}
                        >
                          <CartesianGrid strokeDasharray='3 3' />
                          <XAxis
                            dataKey='name'
                            interval={0}
                            height={60}
                            tick={<CustomizedAxisTick />}
                          />
                          <YAxis
                            label={{
                              value: i18n['alertLocGraphYAxis'][lang],
                              angle: -90,
                              position: 'insideBottomLeft',
                            }}
                          />
                          <Tooltip />
                          <Legend
                            verticalAlign='top'
                            iconType='square'
                            content={<CustomLegend />}
                          />
                          <Bar
                            dataKey={i18n['gpsAlert'][lang]}
                            fill={'#f8be4d'}
                            maxBarSize={20}
                            stackId='a'
                            radius={[3, 3, 3, 3]}
                          >
                            <LabelList
                              dataKey={i18n['gpsAlert'][lang]}
                              position='center'
                              fontSize={12}
                              label={<CustomizedLabel />}
                            />
                          </Bar>
                          <Bar
                            dataKey={i18n['unprocessable'][lang]}
                            fill='#e65151'
                            maxBarSize={20}
                            stackId='a'
                            radius={[3, 3, 3, 3]}
                          >
                            <LabelList
                              dataKey={i18n['unprocessable'][lang]}
                              position='center'
                              fontSize={12}
                              label={<CustomizedLabel />}
                            />
                          </Bar>
                          <Brush
                            className='LineChart-brush'
                            dataKey='name'
                            stroke='gray'
                            height={15}
                            startIndex={monthCount > 12 ? monthCount - 12 : 0}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    )}
                  </div>
                </div>
                {incrementSectionNumber()}
              </>,
            );
            break;
          case ECategoryType.RESTAURANT:
            break;
        }

        return tag;
      }
    };

    /* function for table: sort*/
    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }

    /* function for table: sort for table */
    function getComparator_table<Key extends keyof any>(
      order: Order,
      orderBy: any,
    ): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
      return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    }

    /* control table styles in accordance with window size */
    function insertTags_tableSize(windowWidth) {
      if (windowWidth >= 1920) {
        return { height: 500, width: 1550 };
      } else if (windowWidth >= 1024 && windowWidth < 1920) {
        return { height: 500, width: 1000 };
      } else if (windowWidth >= 576 && windowWidth < 1024) {
        return { height: 500, width: 700 };
      } else if (windowWidth < 576) {
        return { height: 500, width: 315 };
      }
    }

    function insertTags_tableSize_activeAlert(windowWidth) {
      if (windowWidth >= 1920) {
        return { height: 350, width: 500 };
      } else if (windowWidth >= 576 && windowWidth < 1920) {
        return { height: 350, width: 330 };
      } else if (windowWidth < 576) {
        return { height: 350, width: 315 };
      }
    }

    function insertTags_tableStyle(windowSize) {
      if (windowSize.width < 576) {
        return { height: 'max-content', width: 'max-content' };
      } else {
        return { height: 'max-content' };
      }
    }

    const handleRowClick = (row) => {
      const { expandedRow } = this.state;
      if (row === expandedRow) {
        this.setState({ expandedRow: null });
      } else {
        this.setState({ expandedRow: row });
      }
    };

    const handleRowClick_loc = (row) => {
      const { expandedRow_loc } = this.state;
      if (row === expandedRow_loc) {
        this.setState({ expandedRow_loc: null });
      } else {
        this.setState({ expandedRow_loc: row });
      }
    };

    function stableSort(array, comparator) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
    }

    function insertTags_hyperLinkColor(value) {
      if (value == i18n.unprocessable[lang] && value == i18n.unprocessed[lang]) {
        return styles.tableHyperLink;
      }
    }

    /*control table styles in accordance with window size */
    const { width } = this.state;

    const insertTags_activeAlertTable = (data, alertType) => {
      const tableColumns_rest: tableColumn[] = [
        {
          id: 'alertDate',
          label: i18n.alertDate[lang],
          minWidth: 120,
          align: 'right',
        },
        {
          id: 'serialCode',
          label: i18n.serialNo[lang],
          minWidth: 120,
          align: 'center',
          format: (value: number) => value.toLocaleString('en-US'),
        },
        {
          id: 'brandName',
          label: i18n.brand[lang],
          minWidth: 120,
          align: 'left',
          format: (value: number) => value.toLocaleString('en-US'),
        },
      ];

      const tableColumns_dist: tableColumn[] = [
        ...tableColumns_rest,
        {
          id: 'alertLocation',
          label: i18n.alertLocation[lang],
          minWidth: 120,
          align: 'left',
          format: (value: number) => value.toLocaleString('en-US'),
        },
      ];

      const tableColumns_brew: tableColumn[] = [
        ...tableColumns_dist,
        {
          id: 'distributor',
          label: i18n.distributor[lang],
          minWidth: 120,
          align: 'left',
        },
        {
          id: 'country',
          label: i18n.country[lang],
          minWidth: 120,
          align: 'left',
        },
      ];

      //Restaurants only, hide 'alertLocation' column
      const category = getUserCategory(servers.user);
      let tableColumns = [];
      switch (category) {
        case ECategoryType.ADMIN:
        case ECategoryType.BREWERY:
          tableColumns = tableColumns_brew;
          break;
        case ECategoryType.DISTRIBUTOR:
          tableColumns = tableColumns_dist;
          break;
        case ECategoryType.RESTAURANT:
          tableColumns = tableColumns_rest;
          break;
      }

      /* table sort function for active alert table(scan delay alert) */
      const { page_active1, rowsPerPage_active1 } = this.state;
      const handleChangePage_active1 = (event: unknown, newPage: number) => {
        this.setState({ page_active1: newPage });
      };

      const handleChangeRowsPerPage_active1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ rowsPerPage_active1: +event.target.value });
        this.setState({ page_active1: 0 });
      };

      /* table sort function for active alert table(quality alert) */
      const { page_active2, rowsPerPage_active2 } = this.state;
      const handleChangePage_active2 = (event: unknown, newPage: number) => {
        this.setState({ page_active2: newPage });
      };

      const handleChangeRowsPerPage_active2 = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ rowsPerPage_active2: +event.target.value });
        this.setState({ page_active2: 0 });
      };

      /* table sort function for active alert table(gps alert) */
      const { page_active3, rowsPerPage_active3 } = this.state;
      const handleChangePage_active3 = (event: unknown, newPage: number) => {
        this.setState({ page_active3: newPage });
      };

      const handleChangeRowsPerPage_active3 = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ rowsPerPage_active3: +event.target.value });
        this.setState({ page_active3: 0 });
      };

      /** sort handler for active alert table */
      const { tableSortOrder_active, tableSortKey_active } = this.state;
      const createSortHandler = (property: keyof tableData) => (
        event: React.MouseEvent<unknown>,
      ) => {
        this.setState({ tableSortKey_active: property });
        this.setState(
          tableSortOrder_active === 'asc'
            ? { tableSortOrder_active: 'desc' }
            : { tableSortOrder_active: 'asc' },
        );
      };

      let page, rowsPerPage, handleChangePage, handleChangeRowsPerPage;
      switch (alertType) {
        case 'receivingScanDelayAlert':
          page = page_active1;
          rowsPerPage = rowsPerPage_active1;
          handleChangePage = handleChangePage_active1;
          handleChangeRowsPerPage = handleChangeRowsPerPage_active1;
          break;
        case 'inventoryQualityAlert':
          page = page_active2;
          rowsPerPage = rowsPerPage_active2;
          handleChangePage = handleChangePage_active2;
          handleChangeRowsPerPage = handleChangeRowsPerPage_active2;
          break;
        case 'gpsAlert':
          page = page_active3;
          rowsPerPage = rowsPerPage_active3;
          handleChangePage = handleChangePage_active3;
          handleChangeRowsPerPage = handleChangeRowsPerPage_active3;
          break;
      }

      let tag = [];
      tag.push(
        <>
          <CustomCard className={styles.tableRootMini} elevation={2}>
            <TableContainer style={insertTags_tableSize_activeAlert(width)}>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead className={classes.root}>
                  <TableRow>
                    {tableColumns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          fontSize: '0.8em',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <TooltipMUI title={i18n.tableHeaderToolTip[lang]} placement='top'>
                          <TableSortLabel
                            active={tableSortKey_active === column.id}
                            direction={tableSortOrder_active}
                            onClick={createSortHandler(column.id)}
                          >
                            {column.label}
                          </TableSortLabel>
                        </TooltipMUI>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data !== null ? (
                    stableSort(
                      data,
                      getComparator_table(tableSortOrder_active, tableSortKey_active),
                    )
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        return (
                          <React.Fragment key={row.id}>
                            <TableRow hover role='checkbox' tabIndex={-1}>
                              {tableColumns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                      fontSize: '0.8em',
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    {column.id === 'serialCode' ? (
                                      <a
                                        href={`/monitoring-details/${row.serialId}/${row.id}`}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                      >
                                        {column.format(value)}
                                      </a>
                                    ) : column.format && typeof value === 'number' ? (
                                      column.format(value)
                                    ) : (
                                      value
                                    )}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          </React.Fragment>
                        );
                      })
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              labelRowsPerPage={i18n.rowsPerPage[lang]}
              rowsPerPageOptions={[25, 50, 100]}
              // component='div'
              count={data !== null ? data.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ fontSize: '0.8em' }}
              labelDisplayedRows={({ from, to, count }) => (
                <Typography
                  style={{ fontSize: '0.9em', marginLeft: '-20px', marginRight: '-20px' }}
                >
                  {`${from}-${to} / ${count}`}
                </Typography>
              )}
            />
          </CustomCard>
          {insertTags_mobileDescription()}
        </>,
      );
      return tag;
    };

    const insertTags_locAlertNumTable_detail = (data, distributor, detailTableColumns) => {
      const detailData = data.filter((element: any) => {
        return element.distributor === distributor;
      });

      const { tableSortOrder_loc_detail, tableSortKey_loc_detail } = this.state;
      //detailData
      const tag = stableSort(
        detailData,
        getComparator_table(tableSortOrder_loc_detail, tableSortKey_loc_detail),
      ).map((row) => {
        return (
          <TableRow hover role='checkbox' tabIndex={-1} style={{ height: 5 }}>
            {detailTableColumns.map((column) => {
              const value = row[column.id];
              let strColor = '#000000';
              let fontWeight = 400;
              switch (column.id) {
                case 'delayAlertNum':
                  strColor = '#5fa9ef';
                  fontWeight = 700;
                  break;
                case 'qualityAlertNum':
                  strColor = '#72ae70';
                  fontWeight = 700;
                  break;
                case 'gpsAlertNum':
                  strColor = '#f4a507';
                  fontWeight = 700;
                  break;
                case 'unprocessableNum':
                  strColor = 'red';
                  fontWeight = 700;
                  break;
                case 'total':
                  strColor = 'black';
                  fontWeight = 700;
                  break;
                default:
                  strColor = 'black';
                  fontWeight = 400;
                  break;
              }
              return (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ width: 250, color: strColor, fontWeight: fontWeight }}
                >
                  {column.format && typeof value === 'number' ? column.format(value) : value}
                </TableCell>
              );
            })}
          </TableRow>
        );
      });
      return tag;
    };

    const insertTags_locAlertNumTable = (summaryData, detailData) => {
      const category = getUserCategory(servers.user);

      let locAlertNumTableColumns_detail;

      if (category === ECategoryType.ADMIN) {
        locAlertNumTableColumns_detail = [
          {
            id: 'name',
            label: i18n.base[lang],
            minWidth: 220,
            align: 'center',
          },
          {
            id: 'total',
            label: i18n.totalAlertNum[lang],
            minWidth: 100,
            align: 'center',
          },
          {
            id: 'delayAlertNum',
            label: i18n.receivingScanDelayAlert[lang],
            minWidth: 100,
            align: 'center',
          },
          {
            id: 'qualityAlertNum',
            label: i18n.inventoryQualityAlert[lang],
            minWidth: 100,
            align: 'center',
          },
          {
            id: 'gpsAlertNum',
            label: i18n.gpsAlert[lang],
            minWidth: 100,
            align: 'center',
          },
          {
            id: 'unprocessableNum',
            label: i18n.unprocessable[lang],
            minWidth: 100,
            align: 'center',
          },
        ];
      } else {
        locAlertNumTableColumns_detail = [
          {
            id: 'name',
            label: i18n.base[lang],
            minWidth: 220,
            align: 'center',
          },
          {
            id: 'delayAlertNum',
            label: i18n.receivingScanDelayAlert[lang],
            minWidth: 100,
            align: 'center',
          },
          {
            id: 'qualityAlertNum',
            label: i18n.inventoryQualityAlert[lang],
            minWidth: 100,
            align: 'center',
          },
          {
            id: 'unprocessableNum',
            label: i18n.unprocessable[lang],
            minWidth: 100,
            align: 'center',
          },
        ];
      }
      const locAlertNumTableColumns: locAlertNumTableColumns[] = [
        {
          id: 'country',
          label: i18n.country[lang],
          minWidth: 100,
          align: 'center',
        },
        ...locAlertNumTableColumns_detail,
      ];

      /* table paging function */
      const { page_loc, rowsPerPage_loc } = this.state;
      const handleChangePage_loc = (event: unknown, newPage: number) => {
        this.setState({ page_loc: newPage });
      };

      const handleChangeRowsPerPage_loc = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ rowsPerPage_loc: +event.target.value });
        this.setState({ page_loc: 0 });
      };

      /** sort handler for alert history table */
      const { tableSortOrder_loc, tableSortKey_loc } = this.state;
      const createSortHandler = (property: keyof locAlertNumTableData) => (
        event: React.MouseEvent<unknown>,
      ) => {
        this.setState({ tableSortKey_loc: property });
        this.setState(
          tableSortOrder_loc === 'asc'
            ? { tableSortOrder_loc: 'desc' }
            : { tableSortOrder_loc: 'asc' },
        );
      };

      /** sort handler for alert detail table */
      const { tableSortOrder_loc_detail, tableSortKey_loc_detail } = this.state;
      const createSortHandler_detail = (property: keyof locAlertNumTableData) => (
        event: React.MouseEvent<unknown>,
      ) => {
        this.setState({ tableSortKey_loc_detail: property });
        this.setState(
          tableSortOrder_loc_detail === 'asc'
            ? { tableSortOrder_loc_detail: 'desc' }
            : { tableSortOrder_loc_detail: 'asc' },
        );
      };

      const { expandedRow_loc } = this.state;

      let tag = [];
      switch (category) {
        case ECategoryType.ADMIN:
        case ECategoryType.BREWERY: // Brewery: Display alert count table for distributors and restaurants
          tag.push(
            <>
              <h4 className={styles.graphtitle}>{i18n.locAlertNum[lang]}</h4>
              {incrementSectionNumber()}
              <div className={styles.description}>
                <h5>
                  <span>
                    <EmojiObjectsOutlinedIcon className={styles.bulbIcon} />
                  </span>
                  {i18n.analysisDescription4[lang]}
                </h5>
              </div>
              <div className={styles.alertTable}>
                <CustomCard className={styles.tableRoot} elevation={2}>
                  <TableContainer style={insertTags_tableSize(width)}>
                    <Table
                      stickyHeader
                      aria-label='sticky table'
                      style={insertTags_tableStyle(width)}
                    >
                      <TableHead className={classes.root}>
                        <TableRow>
                          <TableCell />
                          {locAlertNumTableColumns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                                height: 5,
                                width: 250,
                                whiteSpace: 'nowrap',
                              }}
                            >
                              <TooltipMUI title={i18n.tableHeaderToolTip[lang]} placement='top'>
                                <TableSortLabel
                                  active={tableSortKey_loc === column.id}
                                  direction={tableSortOrder_loc}
                                  onClick={createSortHandler(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              </TooltipMUI>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {summaryData !== null ? (
                          stableSort(
                            summaryData,
                            getComparator_table(tableSortOrder_loc, tableSortKey_loc),
                          )
                            .slice(
                              page_loc * rowsPerPage_loc,
                              page_loc * rowsPerPage_loc + rowsPerPage_loc,
                            )
                            .map((row) => {
                              return (
                                <React.Fragment key={row.slip}>
                                  <TableRow
                                    hover
                                    role='checkbox'
                                    tabIndex={-1}
                                    style={{ height: 5 }}
                                  >
                                    <TableCell style={{ width: 15 }}>
                                      <IconButton
                                        aria-label='expand row'
                                        size='small'
                                        onClick={() => handleRowClick_loc(row.name)}
                                      >
                                        {expandedRow_loc === row.name ? (
                                          <KeyboardArrowUpIcon />
                                        ) : (
                                          <KeyboardArrowDownIcon />
                                        )}
                                      </IconButton>
                                    </TableCell>
                                    {locAlertNumTableColumns.map((column) => {
                                      const value = row[column.id];

                                      let strColor = '#000000';
                                      let fontWeight = 400;
                                      let fontSize = '13px';
                                      switch (column.id) {
                                        case 'delayAlertNum':
                                          strColor = '#5fa9ef';
                                          fontWeight = 700;
                                          fontSize = '16px';
                                          break;
                                        case 'qualityAlertNum':
                                          strColor = '#72ae70';
                                          fontWeight = 700;
                                          fontSize = '16px';
                                          break;
                                        case 'gpsAlertNum':
                                          strColor = '#f4a507';
                                          fontWeight = 700;
                                          fontSize = '16px';
                                          break;
                                        case 'unprocessableNum':
                                          strColor = 'red';
                                          fontWeight = 700;
                                          fontSize = '16px';
                                          break;
                                        case 'total':
                                          strColor = 'black';
                                          fontWeight = 700;
                                          fontSize = '16px';
                                          break;
                                        default:
                                          strColor = 'black';
                                          fontWeight = 400;
                                          fontSize = '13px';
                                          break;
                                      }

                                      return (
                                        <TableCell
                                          key={column.id}
                                          align={column.align}
                                          style={{
                                            color: strColor,
                                            fontWeight: fontWeight,
                                            fontSize: fontSize,
                                          }}
                                        >
                                          {column.format && typeof value === 'number'
                                            ? column.format(value)
                                            : value}
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        paddingBottom: 0,
                                        paddingTop: 0,
                                        paddingLeft: 50,
                                        paddingRight: 50,
                                      }}
                                      colSpan={7}
                                    >
                                      <Collapse
                                        in={expandedRow_loc === row.name}
                                        timeout='auto'
                                        unmountOnExit
                                      >
                                        <Table size='small' aria-label='detailTable'>
                                          <TableHead className={classes.root}>
                                            <TableRow>
                                              {locAlertNumTableColumns_detail.map((column) => (
                                                <TableCell
                                                  key={column.id}
                                                  align={column.align}
                                                  style={{
                                                    minWidth: column.minWidth,
                                                    height: 5,
                                                    width: 150,
                                                    backgroundColor: '#f5f5f5',
                                                    //fontSize: '0.8em',
                                                    whiteSpace: 'nowrap',
                                                  }}
                                                >
                                                  <TableSortLabel
                                                    active={tableSortKey_loc_detail === column.id}
                                                    direction={tableSortOrder_loc_detail}
                                                    onClick={createSortHandler_detail(column.id)}
                                                  >
                                                    {column.label}
                                                  </TableSortLabel>
                                                </TableCell>
                                              ))}
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            <>
                                              {insertTags_locAlertNumTable_detail(
                                                detailData,
                                                row.name,
                                                locAlertNumTableColumns_detail,
                                              )}
                                            </>
                                          </TableBody>
                                        </Table>
                                      </Collapse>
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              );
                            })
                        ) : (
                          <></>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    labelRowsPerPage={i18n.rowsPerPage[lang]}
                    rowsPerPageOptions={[25, 50, 100]}
                    // component='div'
                    count={summaryData !== null ? summaryData.length : 0}
                    rowsPerPage={rowsPerPage_loc}
                    page={page_loc}
                    onPageChange={handleChangePage_loc}
                    onRowsPerPageChange={handleChangeRowsPerPage_loc}
                    style={{ fontSize: '0.8em' }}
                    labelDisplayedRows={({ from, to, count }) => (
                      <Typography
                        style={{ fontSize: '0.9em', marginLeft: '-20px', marginRight: '-20px' }}
                      >
                        {`${from}-${to} / ${count}`}
                      </Typography>
                    )}
                  />
                </CustomCard>
                {insertTags_mobileDescription()}
              </div>
            </>,
          );
          incrementSectionNumber();
          break;
        case ECategoryType.DISTRIBUTOR: // Distributor: Show restaurant alert count table
          tag.push(
            <>
              <h4 className={styles.graphtitle}>{i18n.locAlertNum[lang]}</h4>
              <div className={styles.description}>
                <h5>
                  <span>
                    <EmojiObjectsOutlinedIcon className={styles.bulbIcon} />
                  </span>
                  {i18n.analysisDescription4[lang]}
                </h5>
              </div>
              <div className={styles.alertTable}>
                <CustomCard className={styles.tableRoot} elevation={2}>
                  <TableContainer style={insertTags_tableSize(width)}>
                    <Table
                      stickyHeader
                      aria-label='sticky table'
                      style={insertTags_tableStyle(width)}
                    >
                      <TableHead className={classes.root}>
                        <TableRow>
                          {locAlertNumTableColumns_detail.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                                height: 5,
                                width: 250,
                                whiteSpace: 'nowrap',
                              }}
                            >
                              <TooltipMUI title={i18n.tableHeaderToolTip[lang]} placement='top'>
                                <TableSortLabel
                                  active={tableSortKey_loc === column.id}
                                  direction={tableSortOrder_loc}
                                  onClick={createSortHandler(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              </TooltipMUI>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {detailData !== null ? (
                          stableSort(
                            detailData,
                            getComparator_table(tableSortOrder_loc, tableSortKey_loc),
                          )
                            .slice(
                              page_loc * rowsPerPage_loc,
                              page_loc * rowsPerPage_loc + rowsPerPage_loc,
                            )
                            .map((row) => {
                              return (
                                <React.Fragment key={row.slip}>
                                  <TableRow
                                    hover
                                    role='checkbox'
                                    tabIndex={-1}
                                    style={{ height: 5 }}
                                  >
                                    {locAlertNumTableColumns_detail.map((column) => {
                                      const value = row[column.id];
                                      let strColor = '#000000';
                                      switch (column.id) {
                                        case 'delayAlertNum':
                                          strColor = '#5fa9ef';
                                          break;
                                        case 'qualityAlertNum':
                                          strColor = '#72ae70';
                                          break;
                                        case 'gpsAlertNum':
                                          strColor = '#f4a507';
                                          break;
                                        case 'unprocessableNum':
                                          strColor = 'red';
                                          break;
                                        default:
                                          strColor = 'black';
                                          break;
                                      }
                                      return (
                                        <TableCell
                                          key={column.id}
                                          align={column.align}
                                          style={{ color: strColor }}
                                        >
                                          {column.format && typeof value === 'number'
                                            ? column.format(value)
                                            : value}
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        paddingBottom: 0,
                                        paddingTop: 0,
                                        paddingLeft: 50,
                                        paddingRight: 50,
                                      }}
                                      colSpan={7}
                                    ></TableCell>
                                  </TableRow>
                                </React.Fragment>
                              );
                            })
                        ) : (
                          <></>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    labelRowsPerPage={i18n.rowsPerPage[lang]}
                    rowsPerPageOptions={[25, 50, 100]}
                    // component='div'
                    count={summaryData !== null ? summaryData.length : 0}
                    rowsPerPage={rowsPerPage_loc}
                    page={page_loc}
                    onPageChange={handleChangePage_loc}
                    onRowsPerPageChange={handleChangeRowsPerPage_loc}
                    style={{ fontSize: '0.8em' }}
                    labelDisplayedRows={({ from, to, count }) => (
                      <Typography
                        style={{ fontSize: '0.9em', marginLeft: '-20px', marginRight: '-20px' }}
                      >
                        {`${from}-${to} / ${count}`}
                      </Typography>
                    )}
                  />
                </CustomCard>
                {insertTags_mobileDescription()}
              </div>
            </>,
          );
          incrementSectionNumber();
          break;
        case ECategoryType.RESTAURANT:
          break;
      }

      return tag;
    };

    const insertTags_alertHitoryTable = (summaryData, detailData) => {
      /* columns for table */
      const category = getUserCategory(servers.user);
      let detailTableColumns_rest;
      if (category == ECategoryType.ADMIN) {
        detailTableColumns_rest = [
          {
            id: 'serialCode',
            label: i18n.serialNo[lang],
            minWidth: 110,
            align: 'center',
          },
          {
            id: 'brandName',
            label: i18n.brand[lang],
            minWidth: 110,
            align: 'left',
          },
          {
            id: 'dAlertDate',
            label: i18n.alertDate[lang],
            minWidth: 110,
            align: 'center',
          },
          {
            id: 'dAlertStatus',
            label: i18n.status[lang],
            minWidth: 110,
            align: 'center',
          },
          {
            id: 'qAlertDate',
            label: i18n.alertDate[lang],
            minWidth: 110,
            align: 'center',
          },
          {
            id: 'qAlertStatus',
            label: i18n.status[lang],
            minWidth: 110,
            align: 'center',
          },
          {
            id: 'gAlertDate',
            label: i18n.alertDate[lang],
            minWidth: 110,
            align: 'center',
          },
          {
            id: 'gAlertStatus',
            label: i18n.status[lang],
            minWidth: 110,
            align: 'center',
          },
        ];
      } else {
        detailTableColumns_rest = [
          {
            id: 'serialCode',
            label: i18n.serialNo[lang],
            minWidth: 110,
            align: 'center',
          },
          {
            id: 'brandName',
            label: i18n.brand[lang],
            minWidth: 110,
            align: 'left',
          },
          {
            id: 'dAlertDate',
            label: i18n.alertDate[lang],
            minWidth: 110,
            align: 'center',
          },
          {
            id: 'dAlertStatus',
            label: i18n.status[lang],
            minWidth: 110,
            align: 'center',
          },
          {
            id: 'qAlertDate',
            label: i18n.alertDate[lang],
            minWidth: 110,
            align: 'center',
          },
          {
            id: 'qAlertStatus',
            label: i18n.status[lang],
            minWidth: 110,
            align: 'center',
          },
          // {
          //   id: 'gAlertDate',
          //   label: i18n.alertDate[lang],
          //   minWidth: 110,
          //   align: 'center',
          // },
          // {
          //   id: 'gAlertStatus',
          //   label: i18n.status[lang],
          //   minWidth: 110,
          //   align: 'center',
          // },
        ];
      }
      const detailTableColumns_brew_dist: detailTableColumn[] = [
        {
          id: 'alertLocation',
          label: i18n.alertLocation[lang],
          minWidth: 110,
          align: 'center',
        },
        ...detailTableColumns_rest,
      ];

      //Switch columns to be displayed between Admin/Brewery and Distributor/Restaurant
      let detailTableColumns = [];
      let subHeaderColSpan = 3;
      switch (category) {
        case ECategoryType.ADMIN:
        case ECategoryType.BREWERY:
          detailTableColumns = detailTableColumns_brew_dist;
          break;
        case ECategoryType.DISTRIBUTOR:
          detailTableColumns = detailTableColumns_brew_dist;
          break;
        case ECategoryType.RESTAURANT:
          detailTableColumns = detailTableColumns_rest;
          subHeaderColSpan = 2;
          break;
      }

      /* pagination function */
      const { page_history, rowsPerPage_history } = this.state;
      const handleChangePage_history = (event: unknown, newPage: number) => {
        this.setState({ page_history: newPage });
      };

      const handleChangeRowsPerPage_history = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ rowsPerPage_history: +event.target.value });
        this.setState({ page_history: 0 });
      };

      /** sort handler for alert history detail table */
      const { tableSortOrder_history_detail, tableSortKey_history_detail } = this.state;
      const createSortHandler_detail = (property: keyof detailTableData) => (
        event: React.MouseEvent<unknown>,
      ) => {
        this.setState({ tableSortKey_history_detail: property });
        this.setState(
          tableSortOrder_history_detail === 'asc'
            ? { tableSortOrder_history_detail: 'desc' }
            : { tableSortOrder_history_detail: 'asc' },
        );
      };

      const { expandedRow } = this.state;

      let tag = [];
      tag.push(
        <>
          <CustomCard className={styles.tableRoot} elevation={2}>
            <TableContainer style={insertTags_tableSize(width)}>
              <Table stickyHeader aria-label='sticky table' style={insertTags_tableStyle(width)}>
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell align='center' colSpan={subHeaderColSpan} />
                    <TableCell align='center' colSpan={2}>
                      {i18n.receivingScanDelayAlert[lang]}
                    </TableCell>
                    <TableCell align='center' colSpan={2}>
                      {i18n.inventoryQualityAlert[lang]}
                    </TableCell>

                    {category == ECategoryType.ADMIN && (
                      <TableCell align='center' colSpan={2}>
                        {i18n.gpsAlert[lang]}
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    {detailTableColumns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          height: 5,
                          width: 250,
                          whiteSpace: 'nowrap',
                          top: '52px',
                        }}
                      >
                        <TooltipMUI title={i18n.tableHeaderToolTip[lang]} placement='top'>
                          <TableSortLabel
                            active={tableSortKey_history_detail === column.id}
                            direction={tableSortOrder_history_detail}
                            onClick={createSortHandler_detail(column.id)}
                          >
                            {column.label}
                          </TableSortLabel>
                        </TooltipMUI>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detailData !== null ? (
                    stableSort(
                      detailData,
                      getComparator_table(
                        tableSortOrder_history_detail,
                        tableSortKey_history_detail,
                      ),
                    )
                      .slice(
                        page_history * rowsPerPage_history,
                        page_history * rowsPerPage_history + rowsPerPage_history,
                      )
                      .map((row) => {
                        return (
                          <TableRow hover role='checkbox' tabIndex={-1} style={{ height: 5 }}>
                            {detailTableColumns.map((column) => {
                              const value = row[column.id];
                              let alertId = null;
                              if (column.id === 'dAlertStatus' && row.dAlertId !== null) {
                                alertId = row.dAlertId;
                              } else if (column.id === 'qAlertStatus' && row.qAlertId !== null) {
                                alertId = row.qAlertId;
                              } else if (column.id === 'gAlertStatus' && row.gAlertId !== null) {
                                alertId = row.gAlertId;
                              }

                              //put a hyperlink in the alert status column
                              if (alertId !== null) {
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    <a
                                      href={`/monitoring-details/${row.serialId}/${alertId}`}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      className={insertTags_hyperLinkColor(value)}
                                    >
                                      {column.format && typeof value === 'number'
                                        ? column.format(value)
                                        : value}
                                    </a>
                                  </TableCell>
                                );
                              } else {
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    {column.format && typeof value === 'number'
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              }
                            })}
                          </TableRow>
                        );
                      })
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              labelRowsPerPage={i18n.rowsPerPage[lang]}
              rowsPerPageOptions={[25, 50, 100]}
              count={detailData !== null ? detailData.length : 0}
              rowsPerPage={rowsPerPage_history}
              page={page_history}
              onPageChange={handleChangePage_history}
              onRowsPerPageChange={handleChangeRowsPerPage_history}
              style={{ fontSize: '0.8em' }}
              labelDisplayedRows={({ from, to, count }) => (
                <Typography
                  style={{ fontSize: '0.9em', marginLeft: '-20px', marginRight: '-20px' }}
                >
                  {`${from}-${to} / ${count}`}
                </Typography>
              )}
            />
          </CustomCard>
          {insertTags_mobileDescription()}
        </>,
      );
      return tag;
    };

    const insertTags_mobileDescription = () => {
      const { width } = this.state;
      let tag = [];
      if (width < 576)
        tag.push(
          <>
            <p className={styles.mobileDescription}>{i18n.mobileDescription[lang]}</p>
          </>,
        );
      return tag;
    };

    const insertTags_brewNotes = () => {
      const category = getUserCategory(servers.user);
      let tag = [];
      switch (category) {
        case ECategoryType.ADMIN:
        case ECategoryType.BREWERY:
          tag.push(
            <>
              <div className={styles.cardNoteBrew}>
                <p>{i18n['cardNoteBrew1'][lang]}</p>
                <p>{i18n['cardNoteBrew2'][lang]}</p>
              </div>
            </>,
          );
          return tag;
        case ECategoryType.DISTRIBUTOR:
        case ECategoryType.RESTAURANT:
          break;
      }
    };

    let sectionNumber = 1;
    function incrementSectionNumber(): any {
      sectionNumber += 1;
    }

    const category = getUserCategory(servers.user);

    return (
      servers.user && (
        <main className={classes.content}>
          <div className={styles.outerFrame} ref={this.ref}>
            <h3 className={styles.chaptertitle}>{i18n.activeAlert[lang]}</h3>
            <div className={styles.description}>
              <h4>
                <span>
                  <EmojiObjectsOutlinedIcon className={styles.bulbIcon} />
                </span>
                {i18n.analysisDescription1[lang]}
              </h4>
            </div>

            {insertTags_brewNotes()}

            <div className={styles.wrapper}>
              <div className={styles.groupAlert}>
                {insertTags_cards(
                  activeAlertNum_delay,
                  i18n.receivingScanDelayAlert[lang],
                  'active',
                )}

                <div className={styles.graphBottomAlert}>
                  {insertTags_activeAlertTable(activeAlert_delay, 'receivingScanDelayAlert')}
                </div>
              </div>

              <div className={styles.groupAlert}>
                {insertTags_cards(
                  activeAlertNum_quality,
                  i18n.inventoryQualityAlert[lang],
                  'active',
                )}

                <div className={styles.graphBottomAlert}>
                  {insertTags_activeAlertTable(activeAlert_quality, 'inventoryQualityAlert')}
                </div>
              </div>

              <div className={styles.groupAlert}>
                {category == ECategoryType.ADMIN &&
                  insertTags_cards(activeAlertNum_gps, i18n.gpsAlert[lang], 'active')}

                <div className={styles.graphBottomAlert}>
                  {category == ECategoryType.ADMIN &&
                    insertTags_activeAlertTable(activeAlert_gps, 'gpsAlert')}
                </div>
              </div>
            </div>

            <h3 className={styles.chaptertitle}>{i18n.alertHistory[lang]}</h3>
            <div className={styles.description}>
              <h4>
                <span>
                  <EmojiObjectsOutlinedIcon className={styles.bulbIcon} />
                </span>
                {i18n.analysisDescription2[lang]}
              </h4>
            </div>

            <h4 className={styles.graphtitle}>{i18n.alertTotal[lang]}</h4>
            {/* <h4 className={styles.graphtitle}>{sectionNumber + ' : ' + i18n.alertTotal[lang]}</h4> */}

            {incrementSectionNumber()}

            <div className={styles.description}>
              <h5>
                <span>
                  <EmojiObjectsOutlinedIcon className={styles.bulbIcon} />
                </span>
                {i18n.analysisDescription3[lang]}
              </h5>
            </div>

            {insertTags_brewNotes()}

            <div className={styles.wrapper}>
              <div className={styles.groupAlert}>
                {insertTags_cards(alertNum_delay, i18n.receivingScanDelayAlert[lang], 'history')}

                <div className={styles.graphAlert}>
                  {insertTags_alertBarchart(alertGraphData, 'DRSAlert', '#5fa9ef')}
                </div>
              </div>

              <div className={styles.groupAlert}>
                {insertTags_cards(alertNum_quality, i18n.inventoryQualityAlert[lang], 'history')}

                <div className={styles.graphAlert}>
                  {insertTags_alertBarchart(alertGraphData, 'QCAlert', '#8ec28c')}
                </div>
              </div>

              <div className={styles.groupAlert}>
                {category == ECategoryType.ADMIN &&
                  insertTags_cards(alertNum_gps, i18n.gpsAlert[lang], 'history')}

                <div className={styles.graphAlert}>
                  {category == ECategoryType.ADMIN &&
                    insertTags_alertBarchart(alertGraphData, 'gpsAlert', '#f8be4d')}
                </div>
              </div>
            </div>

            {insertTags_alertLocBarchart(alertLocGraphData)}

            {insertTags_locAlertNumTable(distAlertNum, restAlertNum)}

            <h4 className={styles.graphtitle}>{i18n.alertTable[lang]}</h4>

            {incrementSectionNumber()}

            <div className={styles.description}>
              <h5>
                <span>
                  <EmojiObjectsOutlinedIcon className={styles.bulbIcon} />
                </span>
                {i18n.analysisDescription5[lang]}
              </h5>
            </div>
            <div className={styles.alertTable}>
              {insertTags_alertHitoryTable(alertTableSummary, alertTableDetail)}
            </div>
          </div>

          <div className={styles.footerMargin} />
        </main>
      )
    );
  }

  countryUserFilter() {
    const { servers } = this.props;
    const { filterRest, relationTree } = this.state;
    const countries = Object.keys(relationTree.Countries);
    const { user } = servers;
    const category = getUserCategory(user);

    let allRests: any[] = [];

    countries.map((country) => {
      const dists = relationTree.Countries[country].Dists;
      Object.values(dists).forEach((dist: any) => {
        const rests = Object.values(dist.Rests)
          .filter((rest: any) => rest.id) // Filter out rests without an ID
          .sort((a: any, b: any) => a.name.localeCompare(b.name));

        allRests.push(...rests);
      });
    });

    // Remove dist itself's row if user is distributor
    if (category === ECategoryType.DISTRIBUTOR) {
      allRests = allRests.filter((item) => item.category !== 2);
    }

    // Remove duplicate rests based on their ID
    const uniqueRests = Array.from(new Set(allRests.map((rest) => rest.id))).map((id) =>
      allRests.find((rest) => rest.id === id),
    );

    // Sort the allDists array by name
    uniqueRests.sort((a, b) => a.name.localeCompare(b.name));

    return (
      <div>
        {countries ? (
          category === ECategoryType.DISTRIBUTOR ? (
            <div>
              {uniqueRests.map((rest: any) => (
                <div className={styles.filterStyle} key={rest.name}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterRest[rest.id]}
                        onClick={(e) =>
                          this.handleChangeFilterRestDestination(e.target ? e.target : 0)
                        }
                        name={rest.id}
                      />
                    }
                    label={rest.name}
                    style={{ margin: '0px' }} // reduce top and bottom margin to 0
                  />
                </div>
              ))}
            </div>
          ) : (
            // for brewery
            <div className={styles.filterStyle}>
              {countries
                .sort((a, b) => a.localeCompare(b))
                .map((country) => {
                  const dists = Object.values(
                    relationTree.Countries[country].Dists,
                  ).sort((a: any, b: any) => a.name.localeCompare(b.name));

                  return (
                    <Accordion style={{ boxShadow: 'none' }} key={country}>
                      <AccordionSummary
                        style={{ minHeight: '40px' }}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.countryCheck(country)}
                              indeterminate={this.countryIntermediate(country)}
                              onChange={(e) => this.handleChangeFilterCountry(e.target)}
                              name={country}
                            />
                          }
                          label={country}
                          style={{ margin: '0px' }} // reduce top and bottom margin to 0
                        />
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: '0px 16px 0px' }}>
                        <div className={styles.filterStyle2}>
                          {dists.map((dist: any) => {
                            const rests = Object.values(dist.Rests)
                              .filter((rest: any) => rest.id) // Filter out rests without an ID
                              .sort((a: any, b: any) => a.name.localeCompare(b.name));

                            return (
                              <Accordion style={{ boxShadow: 'none' }} key={dist.id}>
                                <AccordionSummary
                                  style={{ minHeight: '40px' }}
                                  expandIcon={<ExpandMoreIcon />}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.distCheck(country, dist.id)}
                                        indeterminate={this.distIntermediate(country, dist.id)}
                                        onChange={(e) =>
                                          this.handleChangeFilterDistDestination(
                                            e.target ? e.target : 0,
                                            country,
                                          )
                                        }
                                        name={dist.id}
                                      />
                                    }
                                    label={dist.name}
                                    style={{ margin: '0px' }} // reduce top and bottom margin to 0
                                  />
                                </AccordionSummary>
                                <AccordionDetails style={{ padding: '0px 32px 0px' }}>
                                  <div className={styles.filterStyle2}>
                                    {Object.values(rests).map((rest: any) => {
                                      if (rest.id) {
                                        return (
                                          <div key={rest.id}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={filterRest[rest.id]}
                                                  onClick={(e) =>
                                                    this.handleChangeFilterRestDestination(
                                                      e.target ? e.target : 0,
                                                    )
                                                  }
                                                  name={rest.id}
                                                />
                                              }
                                              label={rest.name}
                                              style={{ margin: '0px' }} // reduce top and bottom margin to 0
                                            />
                                          </div>
                                        );
                                      }
                                    })}
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            );
                          })}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
            </div>
          )
        ) : (
          ''
        )}
      </div>
    );
  }

  distIntermediate(country: string, id: any): boolean {
    const { relationTree, filterRest } = this.state;
    let intermediate = false;
    const dist = relationTree.Countries[country].Dists[id];
    const rests = dist.Rests;
    //console.log('distChk ' + JSON.stringify(dist));
    if (!this.distCheck(country, id)) {
      Object.values(rests).map((rest: any) => {
        if (filterRest[rest.id]) {
          intermediate = true;
        }
      });
    }
    return intermediate;
  }

  distCheck(country: string, id: any): boolean {
    const { relationTree, filterRest } = this.state;
    let check = true;
    const dist = relationTree.Countries[country].Dists[id];
    const rests = dist.Rests;
    Object.values(rests).map((rest: any) => {
      if (!filterRest[rest.id]) {
        check = false;
      }
    });
    return check;
  }

  countryIntermediate(country: string): boolean {
    const { relationTree, filterRest, filterCountry } = this.state;
    let intermediate = false;
    Object.values(relationTree.Countries[country].Dists).map((dist: any) => {
      const rests = dist.Rests;
      if (!this.countryCheck(country)) {
        Object.values(rests).map((rest: any) => {
          if (filterRest[rest.id]) {
            intermediate = true;
          }
        });
      }
    });
    return intermediate;
  }

  countryCheck(country: string): boolean {
    const { relationTree, filterRest, filterCountry } = this.state;
    let check = true;
    Object.values(relationTree.Countries[country].Dists).map((dist: any) => {
      const rests = dist.Rests;
      Object.values(rests).map((rest: any) => {
        if (!filterRest[rest.id]) {
          check = false;
        }
      });
    });
    return check;
  }

  render(): JSX.Element {
    const { apps, servers, classes } = this.props;
    const {
      enableFilter,
      filterCreatedAt,
      filterSalesOrder,
      filterDestinationId,
      filterBrandId,
      filterCountry,
      filterSubCategory,
      filterCuisineType,
      brands,
      subCategories,
      cuisineTypes,
    } = this.state;
    const { user } = servers;
    const category = getUserCategory(user);
    const lang = apps.currentLanguage;
    const { width } = this.state;
    const insertTags_datePickerWidth = (windowWidth) => {
      if (windowWidth >= 1500) {
        return '270px';
      } else if (windowWidth >= 1024 && windowWidth < 1500) {
        return '265px';
      } else if (windowWidth >= 576 && windowWidth < 1500) {
        return '800px';
      } else if (windowWidth < 576) {
        return '400px';
      }
    };

    const filterView = (
      <>
        <Grid container alignItems='flex-end'>
          <Grid item>
            <Box ml={2} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            {getDateRangePicker2(
              i18n.period[lang],
              filterCreatedAt,
              (v) => this.handleChangeCreatedAt(v),
              lang,
              true, //disable ruture
              insertTags_datePickerWidth(width),
            )}
          </Grid>
          {category === ECategoryType.RESTAURANT ? (
            <>
              <Grid item xs={12}>
                <Box mt={1} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Accordion
                  defaultExpanded={true}
                  style={{ border: '1px solid lightgray', marginTop: '5px' }}
                >
                  <AccordionSummary
                    style={{ maxHeight: '40px', minHeight: '40px' }}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>{i18n.brand[lang]}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: '0px 16px 10px' }}>
                    <div>
                      {Object.values(brands).map((brand: any) => {
                        return (
                          <div key={brand.id}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={filterBrandId[brand.id]}
                                  onChange={(e) => this.handleChangeFilterBrand(e ? e.target : 0)}
                                  name={brand.id.toString()}
                                />
                              }
                              label={brand.name}
                              style={{ padding: '0px 16px 0px' }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Box mt={1} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Accordion
                  defaultExpanded={true}
                  style={{ border: '1px solid lightgray', marginTop: '5px' }}
                >
                  <AccordionSummary
                    style={{ maxHeight: '40px', minHeight: '40px' }}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>
                      {category === ECategoryType.DISTRIBUTOR
                        ? appLanguages.user[lang]
                        : appLanguages.regionUser[lang]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: '0px 16px 10px' }}>
                    <div>{this.countryUserFilter()}</div>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid item xs={12}>
                <Box mt={1} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Accordion
                  defaultExpanded={true}
                  style={{ border: '1px solid lightgray', marginTop: '5px' }}
                >
                  <AccordionSummary
                    style={{ maxHeight: '40px', minHeight: '40px' }}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>{i18n.brand[lang]}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: '0px 16px 10px' }}>
                    <div>
                      {Object.values(brands).map((brand: any) => {
                        return (
                          <div key={brand.id}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={filterBrandId[brand.id]}
                                  onChange={(e) => this.handleChangeFilterBrand(e ? e.target : 0)}
                                  name={brand.id.toString()}
                                />
                              }
                              label={brand.name}
                              style={{ padding: '0px 16px 0px' }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid item xs={12}>
                <Box mt={1} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Accordion
                  defaultExpanded={true}
                  style={{ border: '1px solid lightgray', marginTop: '5px' }}
                >
                  <AccordionSummary
                    style={{ maxHeight: '40px', minHeight: '40px' }}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>{i18n.cuisineType[lang]}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: '0px 16px 10px' }}>
                    <div>
                      {Object.values(cuisineTypes).map((cuisineType: any) => {
                        return (
                          <div key={cuisineType.id}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={filterCuisineType[cuisineType.id]}
                                  onChange={(e) =>
                                    this.handleChangeFilterCuisineType(e ? e.target : 0)
                                  }
                                  name={cuisineType.id.toString()}
                                />
                              }
                              label={cuisineType.name}
                              style={{ margin: '0px', wordBreak: 'break-all' }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Box mt={1} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Accordion
                  defaultExpanded={true}
                  style={{ border: '1px solid lightgray', marginTop: '5px' }}
                >
                  <AccordionSummary
                    style={{ maxHeight: '40px', minHeight: '40px' }}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>{appLanguages.locationBusinessType[lang]}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: '0px 16px 10px' }}>
                    <div>
                      {Object.values(subCategories).map((subCategorie: any) => {
                        //filteredRests
                        return (
                          <div key={subCategorie.id}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={filterSubCategory[subCategorie.id]}
                                  onChange={(e) => this.handleChangeSubCategory(e ? e.target : 0)}
                                  name={subCategorie.id}
                                />
                              }
                              label={subCategorie.name}
                              style={{ margin: '0px', wordBreak: 'break-all' }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </>
          )}
        </Grid>
        {filterCreatedAt.length ||
        filterCountry ||
        filterSubCategory ||
        filterCuisineType ||
        filterSalesOrder ||
        filterDestinationId ||
        filterBrandId ? (
          <Grid container justify='flex-end'>
            <Grid item xs={12}>
              <Box mt={1} />
            </Grid>
            <Grid item>
              {getSmallClearButton(i18n.reset[lang], false, () => this.handleClickResetFilter())}
            </Grid>
            <Grid item style={{ marginLeft: '16px' }}>
              {getSmallClearButton2(appLanguages.search[lang], false, () =>
                this.handleClickSearch(),
              )}
            </Grid>
          </Grid>
        ) : (
          ''
        )}
      </>
    );

    return !this.state.isLoading ? (
      <>
        <Grid container spacing={2} alignItems='stretch'>
          {enableFilter ? (
            <Grid item xs={12} sm={12} md={3} xl={2}>
              {getFilterButton(
                enableFilter,
                () =>
                  this.setState({ enableFilter: !enableFilter }, () => {
                    window.scrollTo(0, 0);
                  }),
                lang,
              )}
              {filterView}
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
              {getFilterButton(
                enableFilter,
                () =>
                  this.setState({ enableFilter: !enableFilter }, () => {
                    window.scrollTo(0, 0);
                  }),
                lang,
              )}
              {this.chartsView()}
            </Grid>
          )}
          {enableFilter ? (
            <Grid item xs={12} sm={12} md={9} xl={10}>
              {this.chartsView()}
            </Grid>
          ) : null}
        </Grid>
      </>
    ) : (
      <Grid container justify='center'>
        <CircularProgress className={classes.progress} />
      </Grid>
    );
  }
}
const myStyles = (theme: Theme): StyleRules => ({
  content: { width: '100%', marginLeft: 'auto', marginRight: 'auto' },
  button: { marginTop: theme.spacing(1), marginRight: theme.spacing(1) },
  actionsContainer: { marginBottom: theme.spacing(2) },
  resetContainer: { padding: theme.spacing(3) },
  paper: { padding: theme.spacing(2) },
  gridContainer: {
    minWidth: '236px',
  },
});

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  history: History<LocationState>;
  classes: any;
  filterTimeout: any;
  enableFilter: boolean;
  initialized: boolean;
  filterCreatedAt: any;
  filterSalesOrder: any;
  filterDestinationId: any;
  filterBrandId: any;
  filterSubCategory: any;
  filterCuisineType: any;
  filterDist: any;
  filterRest: any;
  originStateRest: any;
  originStateCountry: any;
  originBrandId: any;
  originSubCategory: any;
  originCuisineType: any;
}

export type Props = IStateProps & IDispatchProps;
const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

type Order = 'asc' | 'desc';
interface tableColumn {
  id:
    | 'slip'
    | 'alertDate'
    | 'serialCode'
    | 'alertType'
    | 'country'
    | 'alertLocation'
    | 'brandName'
    | 'distributor';
  label: string;
  minWidth?: number;
  maxWidth?: number;
  align?: 'left' | 'center' | 'right' | 'justify';
  format?: (value: any) => string;
}

interface locAlertNumTableColumns {
  id:
    | 'country'
    | 'name'
    | 'total'
    | 'delayAlertNum'
    | 'qualityAlertNum'
    | 'gpsAlertNum'
    | 'unprocessableNum';
  label: string;
  minWidth?: number;
  maxWidth?: number;
  align?: 'left' | 'center' | 'right' | 'justify';
  format?: (value: any) => string;
}

interface summaryTableColumn {
  id:
    | 'slip'
    | 'country'
    | 'distributor'
    | 'receivingScanDelayAlert'
    | 'inventoryQualityAlert'
    | 'gpsAlert';
  label: string;
  minWidth?: number;
  maxWidth?: number;
  align?: 'left' | 'center' | 'right' | 'justify';
  format?: (value: any) => string;
}

interface detailTableColumn {
  id:
    | 'alertLocation'
    | 'serialCode'
    | 'brandName'
    | 'dAlertDate'
    | 'dAlertStatus'
    | 'qAlertDate'
    | 'qAlertStatus'
    | 'gAlertDate'
    | 'gAlertStatus';
  label: string;
  minWidth?: number;
  maxWidth?: number;
  align?: 'left' | 'center' | 'right' | 'justify';
  format?: (value: any) => string;
}

interface tableData {
  slip: string;
  alertDate: string;
  serialCode: number;
  alertType: number;
  alertStatus: number;
  country: string;
  alertLocation: number;
  brandName: string;
  brewery: string;
  distributor: string;
  restaurant: string;
}

interface locAlertNumTableData {
  country: string;
  name: string;
  total: number;
  delayAlertNum: number;
  qualityAlertNum: number;
  gpsAlertNum: number;
  unprocessableNum: number;
}

interface summaryTableData {
  slip: string;
  country: string;
  distributor: string;
  receivingScanDelayAlert: number;
  inventoryQualityAlert: number;
  gpsAlert: number;
}

interface detailTableData {
  alertLocation: any;
  serialCode: number;
  brandName: any;
  dAlertDate: any;
  dAlertStatus: any;
  qAlertDate: any;
  qAlertStatus: any;
  gAlertDate: any;
  gAlertStatus: any;
}

export interface State {
  filterTimeout: any;
  enableFilter: boolean;
  initialized: boolean;
  filterCreatedAt: any;
  filterSalesOrder: any;
  filterDestinationId: any;
  filterBrandId: any;
  filterCountry: any;
  filterSubCategory: any;
  filterCuisineType: any;
  filterDist: any;
  filterRest: any;
  originStateRest: any;
  originStateCountry: any;
  originBrandId: any;
  originSubCategory: any;
  originCuisineType: any;
  page_active1: number;
  rowsPerPage_active1: number;
  page_active2: number;
  rowsPerPage_active2: number;
  page_active3: number;
  rowsPerPage_active3: number;
  page_loc: number;
  rowsPerPage_loc: number;
  page_history: number;
  rowsPerPage_history: number;
  tableSortKey_active: any;
  tableSortOrder_active: Order;
  tableSortKey_loc: any;
  tableSortOrder_loc: Order;
  tableSortKey_loc_detail: any;
  tableSortOrder_loc_detail: Order;
  tableSortKey_history: any;
  tableSortOrder_history: Order;
  tableSortKey_history_detail: any;
  tableSortOrder_history_detail: Order;
  rawAlertData: any;
  filterData: any;
  relationTree: any;
  brands: any;
  subCategories: any;
  cuisineTypes: any;
  query: any;
  windowSize: {
    width: number;
    height: number;
  };
  expandedRow: any;
  expandedRow_loc: any;
  width: number;
  isLoading: boolean;
  monthCount: number;
  alertGraphData: any;
  alertLocGraphData: any;
  activeAlert_delay: any;
  activeAlert_quality: any;
  activeAlert_gps: any;
  alertNum_delay: any;
  alertNum_quality: any;
  alertNum_gps: any;
  activeAlertNum_delay: any;
  activeAlertNum_quality: any;
  activeAlertNum_gps: any;
  restAlertNum: any;
  distAlertNum: any;
  alertTableSummary: any;
  alertTableDetail: any;
}

export interface IDispatchProps {
  getLocationRegions: (query: IQuery) => void;
  setAnalysisQuery: (query: IQuery) => void;
  getAlertAnalysis: (query: IQuery) => any;
  getTransaction: (query: IQuery) => any;
  getInventory: (query: IQuery) => any;
}

const mapDispatchToProps: IDispatchProps = {
  getLocationRegions,
  setAnalysisQuery,
  getAlertAnalysis,
  getTransaction,
  getInventory,
};

export const AlertManagement = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(alertManagementClass);
