import { i18n } from '@i18n/lang';
import { AllPages } from '@models/all-pages';

export const AppRoutePaths: RoutePath = {
  brands: { path: '/brands', link: '/brands', name: 'updateBrand' },
  breweries: { path: '/breweries', link: '/breweries', name: 'updateCustomerLocation' },
  customization: { path: '/customization', link: '/customization', name: 'customization' },
  dashboard: { path: '/dashboard', link: '/dashboard', name: 'dashboard' },
  'distribution-history': {
    path: '/distribution-history',
    link: '/distribution-history',
    name: 'distributionHistory',
  },
  'distribution-detail': {
    path: '/distribution-detail/:serialId',
    link: '/distribution-detail',
    name: 'distributionInfo',
  },
  distributors: { path: '/distributors', link: '/distributors', name: 'updateCustomerLocation' },
  monitoring: { path: '/monitoring', link: '/monitoring', name: 'unhandledAlerts' },
  'monitoring-details': {
    path: '/monitoring-details/:serialId/:alertId',
    link: '/monitoring-details',
    name: 'unhandledAlerts',
  },
  'notification-list': {
    path: '/notification-list',
    link: '/notification-list',
    name: 'notificationList',
  },
  'notification-register': {
    path: '/pc/notification-register',
    link: '/pc/notification-register',
    name: 'sendNotificationTitle',
  },
  'purchase-history': {
    path: '/purchase-history',
    link: '/purchase-history',
    name: 'purchaseHistory',
  },
  reports: { path: '/reports', link: '/reports', name: 'reports' },
  restaurants: { path: '/restaurants', link: '/restaurants', name: 'updateCustomerLocation' },
  'scans-ingoing': { path: '/scans-ingoing', link: '/scans-ingoing', name: 'scanOrRegister' },
  'scans-outgoing': { path: '/scans-outgoing', link: '/scans-outgoing', name: 'scanOrRegister' },
  'survey-history': { path: '/survey-history', link: '/survey-history', name: 'updateHistory' },
  'task-management': { path: '/task-management', link: '/task-management', name: 'taskManagement' },
  vips: { path: '/vips', link: '/vips', name: 'vip' },
  'restaurant-add': { path: '/restaurant-add', link: '/restaurant-add', name: 'restaurantAdd' },
  'restaurant-inpect': {
    path: '/restaurant-inpect',
    link: '/restaurant-inpect',
    name: 'restaurantInspection',
  },
  'system-settings': { path: '/system-settings', link: '/system-settings', name: 'systemSettings' },
  'main-menu': { path: '/main-menu', link: '/main-menu', name: 'mainMenu' },
  'add-temp-restaurant-user': {
    path: '/add-temp-restaurant-user',
    link: '/add-temp-restaurant-user',
    name: 'temp',
  },
  chat: { path: '/chat', link: '/chat', name: 'chat' },
  master: { path: '/master*', link: '/master*' },
  section: { path: '/section', link: '/section' },
  'restaurant-management': {
    path: '/pc/restaurant-management',
    link: '/pc/restaurant-management',
    name: 'restaurantManagement',
  },
  'master-brands': {
    path: '/master/brands',
    link: '/master/brands',
    name: 'updateBrand',
  },
  'master-locations': {
    path: '/master/locations',
    link: '/master/locations',
    name: 'updateCustomerLocation',
  },
  'master-users': {
    path: '/master/users',
    link: '/master/users',
    name: 'updateUserIdPass',
  },
  charts: {
    path: '/charts',
    link: '/charts',
    name: 'charts',
  },
};

export type RoutePath = {
  [key in AllPages]: { path: string; link: string; name?: keyof typeof i18n };
};
