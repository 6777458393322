import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Icon } from '@visual/icon';
import React from 'react';
import styles from './style.scss';

export class DatePicker extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  public static defaultProps: Partial<Props> = {
    size: 'fit',
  };
  public render(): JSX.Element {
    const { placeholder, size } = this.props;
    const { value } = this.state;
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          format='yyyy/MM/dd'
          emptyLabel={placeholder}
          fullWidth={size === 'full'}
          className={styles.datePicker}
          InputProps={{ disableUnderline: true }}
          value={value}
          onChange={(newDate) => this.handleOnChange(newDate)}
          keyboardIcon={<Icon type='calender' color='dark' size='small' />}
        />
      </MuiPickersUtilsProvider>
    );
  }

  protected handleOnChange(newDate: Date) {
    const { onChange } = this.props;
    this.setState({ value: newDate });
    if (!onChange) return;
    onChange(newDate);
  }
}
export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  value?: Date;
  placeholder?: string;
  size?: 'full' | 'fit';
}

export interface IDispatchProps {
  onChange?: (date: Date) => void;
}

interface State {
  value?: Date;
}
