import { i18n } from '@i18n/lang';
import { TSupportedLanguages } from '@models/supported-languages';
import { LANGUAGE_DEFAULT } from '@utils/common';
import { TextDisplay } from '@visual/text-display';
import React from 'react';
import styles from './style.scss';

export class DistributionAlertSummary extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    lang: LANGUAGE_DEFAULT,
  };

  public render(): JSX.Element {
    const { name, country, message, serial, alertAt, lang, alertResolutionButton } = this.props;
    return (
      <div className={styles.distributionAlertSummary}>
        {name && (
          <div className={styles.detailRow}>
            <div className={styles.flex}>
              <div className={styles.leftFlexItem}>
                <TextDisplay size='x-small'>{i18n.alertLocation[lang]}</TextDisplay>
                <TextDisplay weight='bold'>{name}</TextDisplay>
              </div>
              {country && (
                <div>
                  <TextDisplay size='x-small'>{i18n.area[lang]}</TextDisplay>
                  <TextDisplay weight='bold'>{country}</TextDisplay>
                </div>
              )}
            </div>
          </div>
        )}
        <div className={styles.detailRow}>
          <TextDisplay size='x-small'>{i18n.alertContents[lang]}</TextDisplay>
          <TextDisplay weight='bold' color='alert'>
            {message}
          </TextDisplay>
        </div>
        <div className={styles.detailRow}>
          <div className={styles.flex}>
            <div className={styles.leftFlexItem}>
              <TextDisplay size='x-small'>{i18n.serialNo[lang]}</TextDisplay>
              <TextDisplay>{serial}</TextDisplay>
            </div>
            <div>
              <TextDisplay size='x-small'>{i18n.alertDate[lang]}</TextDisplay>
              <TextDisplay>{alertAt}</TextDisplay>
            </div>
          </div>
        </div>
        {alertResolutionButton && (
          <div className={[styles.detailRow, styles.centeredRow].join(' ')}>
            {alertResolutionButton}
          </div>
        )}
      </div>
    );
  }
}

export type Props = IStateProps;

export interface IStateProps {
  name: string;
  country: string;
  message: string;
  serial: number;
  alertAt: string;
  lang?: TSupportedLanguages;
  alertResolutionButton?: React.ReactNode;
}
