import WhiteLinearProgress from '@elements/WhiteLinearProgress';
import { Box, Grid, LinearProgress, Paper } from '@material-ui/core';
import { MuiThemeProvider, StyleRules, Theme, withStyles } from '@material-ui/core/styles';
import { AllPages } from '@models/all-pages';
import { ECategoryType } from '@models/category-type';
import { ILocation } from '@models/location';
import { IQuery } from '@models/query';
import { ERestaurantStatus } from '@models/restaurant-status-type';
import { IStateApps } from '@models/state-apps';
import { IStateAuth } from '@models/state-auth';
import { IStateServers } from '@models/state-servers';
import {
  openLocationEditDialog,
  setCurrentPage,
  setEditLocation,
} from '@redux/actions/appsActions';
import {
  delLocation,
  getLocationDetail,
  getLocationRegions,
  getMasterLocations,
  getSuppliers,
  setLocationsQuery,
} from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import appLanguages from '@utils/app-languages';
import {
  getDateRangePicker,
  getFilterButton,
  getSmallClearButton,
  getSmallSearchField,
  getSmallSelect,
  getSmallTextField,
  withConfirm,
} from '@utils/common';
import { DefaultQuery } from '@utils/default-query';
import { getUserCategory } from '@utils/get-user-category';
import {
  appMuiTheme,
  disableDisplayOpts,
  enableEmptyOpts,
  enableSortOnlyOpts,
  getActionsCustomBodyRender,
  getSelectCustomBodyRender,
  getTimeAtCustomBodyRender,
  muiDataTableCommonOptions,
  rowDataToObj,
} from '@utils/mui-datatables-ops';
import 'date-fns';
import MUIDataTable from 'mui-datatables';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import userEnv from 'userEnv';
import { LocationEditDialog } from './config/location-edit-dialog';
import { LocationsCustomToolbar } from './config/locations-custom-toolbar';

class LocationsClass extends React.PureComponent<Props, State> {
  private initialFilters = {
    filterCreatedAt: [],
    filterUpdatedAt: [],
    filterName: '',
    filterPublicId: '',
    filterCategory: '',
    filterSubCategory: '',
    filterHidden: '',
    filterCountry: '',
    filterCustomerStatus: '',
  };

  constructor(props) {
    super(props);
    const { apps } = this.props;
    const { categoryMapLocation, subCategoryMap } = userEnv;
    const lang = apps.currentLanguage;
    // options
    const categoryOptions = {
      ...enableSortOnlyOpts,
      customBodyRender: (v) => getSelectCustomBodyRender(categoryMapLocation[lang], v),
    };
    const subCategoryOptions = {
      ...enableSortOnlyOpts,
      customBodyRender: (v, meta) => {
        const { columnIndex, rowData } = meta;
        const category = rowData[columnIndex - 1]; // category ==> 0: 'None', 1: 'Brewery', 2: 'Distributor', 3: 'Restaurant'
        return category === 3 ? getSelectCustomBodyRender(subCategoryMap[lang], v) : '-';
      },
    };
    const hiddenOptions = {
      ...enableSortOnlyOpts,
      customBodyRender: (v) => (v ? appLanguages.enable[lang] : appLanguages.disable[lang]),
      display: false,
    };
    const rangeOptions = { ...enableSortOnlyOpts, customBodyRender: (v) => `${v}m` };
    const timeOptions = { ...enableSortOnlyOpts, customBodyRender: getTimeAtCustomBodyRender };
    const actionOptions = {
      ...enableEmptyOpts,
      customBodyRender: (_, mt) => this.getActionsCustomBodyRender(mt),
    };
    const approvalOptions = {
      ...enableEmptyOpts,
      customBodyRender: (_, mt) => this.getApprovalActionsCustomBodyRender(mt),
    };
    const qualityAlertDurationOptions = {
      ...enableSortOnlyOpts,
      customBodyRender: (value, meta) => {
        const { columnIndex, rowData } = meta;
        let duration = rowData[columnIndex];
        if (!duration) return 'OFF';
        else return value;
      },
    };

    const { isAdmin, isBrewery } = this.getUserTypes();
    const approvalStatusOptions = isAdmin || isBrewery ? approvalOptions : disableDisplayOpts;

    this.state = {
      enableFilter: false,
      filterTimeout: undefined,
      ...this.initialFilters,
      initialized: false,
      columns: [
        { name: 'id', options: { ...disableDisplayOpts } },
        // 日本語
        { name: 'information', options: { ...disableDisplayOpts } },
        { name: 'description', options: { ...disableDisplayOpts } },
        { name: 'mainAddress', options: { ...disableDisplayOpts } },
        { name: 'email', options: { ...disableDisplayOpts } },
        // 英語
        { name: 'enName', options: { ...disableDisplayOpts } },
        { name: 'enInformation', options: { ...disableDisplayOpts } },
        { name: 'enDescription', options: { ...disableDisplayOpts } },
        { name: 'enMainAddress', options: { ...disableDisplayOpts } },
        // 中国語
        { name: 'cnName', options: { ...disableDisplayOpts } },
        { name: 'cnInformation', options: { ...disableDisplayOpts } },
        { name: 'cnDescription', options: { ...disableDisplayOpts } },
        { name: 'cnMainAddress', options: { ...disableDisplayOpts } },
        // 香港語
        { name: 'hkName', options: { ...disableDisplayOpts } },
        { name: 'hkInformation', options: { ...disableDisplayOpts } },
        { name: 'hkDescription', options: { ...disableDisplayOpts } },
        { name: 'hkMainAddress', options: { ...disableDisplayOpts } },
        // 韓国語
        { name: 'krName', options: { ...disableDisplayOpts } },
        { name: 'krInformation', options: { ...disableDisplayOpts } },
        { name: 'krDescription', options: { ...disableDisplayOpts } },
        { name: 'krMainAddress', options: { ...disableDisplayOpts } },
        // タイ語
        { name: 'thName', options: { ...disableDisplayOpts } },
        { name: 'thInformation', options: { ...disableDisplayOpts } },
        { name: 'thDescription', options: { ...disableDisplayOpts } },
        { name: 'thMainAddress', options: { ...disableDisplayOpts } },
        // ベトナム語
        { name: 'viName', options: { ...disableDisplayOpts } },
        { name: 'viInformation', options: { ...disableDisplayOpts } },
        { name: 'viDescription', options: { ...disableDisplayOpts } },
        { name: 'viMainAddress', options: { ...disableDisplayOpts } },
        // Restaurant Fields
        { name: 'cuisineType', options: { ...disableDisplayOpts } },
        { name: 'priceType', options: { ...disableDisplayOpts } },
        { name: 'awardsComment', options: { ...disableDisplayOpts } },
        { name: 'linkAddress', options: { ...disableDisplayOpts } },
        { name: 'status', options: { ...disableDisplayOpts } },
        { name: 'creatorId', options: { ...disableDisplayOpts } },

        // 基本設定
        {
          name: 'latitude',
          label: appLanguages.latitude[lang],
          options: { ...disableDisplayOpts },
        },
        {
          name: 'longitude',
          label: appLanguages.longitude[lang],
          options: { ...disableDisplayOpts },
        },
        {
          name: 'mapAddress',
          label: appLanguages.mapAddress[lang],
          options: { ...disableDisplayOpts },
        },
        {
          name: 'supplierId',
          label: appLanguages.mapAddress[lang],
          options: { ...disableDisplayOpts },
        },
        {
          name: 'name',
          label: appLanguages.name[lang],
          options: { ...enableSortOnlyOpts },
        },
        {
          name: 'customerCode',
          label: appLanguages.customerCode[lang],
          options: { ...enableSortOnlyOpts },
        },
        {
          name: 'category',
          label: appLanguages.category[lang],
          options: categoryOptions,
        },
        {
          name: 'subCategory',
          label: appLanguages.locationBusinessType[lang],
          options: subCategoryOptions,
        },
        {
          name: 'hidden',
          label: appLanguages.hiddenSetting[lang],
          options: hiddenOptions,
        },
        {
          name: 'range',
          label: appLanguages.allowableRange[lang],
          options: rangeOptions,
        },
        {
          name: 'quality_alert_duration',
          label: appLanguages.qualityAlertDuration[lang],
          options: qualityAlertDurationOptions,
        },
        {
          name: 'country',
          label: appLanguages.country[lang],
          options: isAdmin || isBrewery ? { ...enableSortOnlyOpts } : { ...disableDisplayOpts },
        },
        {
          name: 'createdAt',
          label: appLanguages.createdAt[lang],
          options: { ...timeOptions },
        },
        {
          name: 'updatedAt',
          label: appLanguages.updatedAt[lang],
          options: { ...timeOptions },
        },
        { name: '', options: approvalStatusOptions },
        { name: '', options: actionOptions },
      ],
    };
  }

  async componentDidMount() {
    const { servers, getMasterLocations, setCurrentPage, getLocationRegions } = this.props;
    const { where } = servers.locationsQuery;
    await getLocationRegions({});

    //If query already exists,show it.
    (Object.keys(where).length > 1 || (Object.keys(where).length === 1 && !where.name)) &&
      this.setState({ enableFilter: true });
    where.createdAt &&
      this.setState({ filterCreatedAt: [where.createdAt.$gte, where.createdAt.$lte] });
    where.updatedAt &&
      this.setState({ filterUpdatedAt: [where.updatedAt.$gte, where.updatedAt.$lte] });
    where.name && this.setState({ filterName: where.name.$like.replace(/%/g, '') });
    where.customerCode &&
      this.setState({ filterPublicId: where.customerCode.$like.replace(/%/g, '') });
    where.category && this.setState({ filterCategory: where.category });
    where.subCategory && this.setState({ filterSubCategory: where.subCategory });
    where.country && this.setState({ filterCountry: where.country });
    where.status && this.setState({ filterCustomerStatus: where.status });

    setTimeout(() => getMasterLocations({ ...servers.locationsQuery }), 300);
    setTimeout(() => this.setState({ initialized: true }), 600);
    setCurrentPage('master-locations');
  }

  getUserTypes() {
    const { servers } = this.props;
    const { user } = servers;
    const userCategory = getUserCategory(user);
    const isAdmin = userCategory === ECategoryType.ADMIN;
    const isBrewery = userCategory === ECategoryType.BREWERY;
    return { isAdmin, isBrewery };
  }

  getActionsCustomBodyRender(tableMeta) {
    const {
      servers,
      confirm,
      delLocation,
      apps: { currentLanguage: lang },
    } = this.props;
    const obj: any = this.rowDataToObj(tableMeta.rowData);
    const isRequesting = servers ? servers.isRequesting : true;
    const delTitle = appLanguages.deleteLocation[lang];
    let locTitle;
    obj.customerCode
      ? (locTitle = `${obj.name}(${obj.customerCode})`)
      : (locTitle = ` ${obj.name}`);
    const delDesc = `${appLanguages.location[lang]}「${locTitle}」${appLanguages.deleteConfirmationSuffix[lang]}`;
    const onClickEdit = (o) => this.handleOnClickEdit(o);
    const onClickDelete = (o) => delLocation(o);
    return getActionsCustomBodyRender(
      obj,
      delTitle,
      delDesc,
      isRequesting,
      onClickEdit,
      onClickDelete,
      confirm,
    );
  }

  handleOnClickApproval(obj) {
    console.log('handleOnClickApproval');
  }

  getApprovalActionsCustomBodyRender(tableMeta) {
    const {
      apps: { currentLanguage: lang },
    } = this.props;
    const obj: Partial<ILocation> = this.rowDataToObj(tableMeta.rowData);
    let title = '-';
    switch (obj.category) {
      case ECategoryType.RESTAURANT:
        switch (obj.status) {
          case ERestaurantStatus.REVIEWING:
            title = appLanguages.locationStatusReviewing[lang];
            break;
          case ERestaurantStatus.PENDING:
            title = appLanguages.locationStatusPending[lang];
            break;
          default:
            title = appLanguages.locationStatusActivated[lang];
            break;
        }
        break;
      default:
        break;
    }
    return title;
  }

  setFilter(newWhere) {
    const { servers } = this.props;
    const where = { ...servers.locationsQuery.where, ...newWhere };
    this.updateQuery({ where, offset: 0 });
    this.setState({ filterTimeout: null });
  }

  unsetFilter(key) {
    const { servers } = this.props;
    const { where } = servers.locationsQuery;
    if (where[key] != undefined) delete where[key];
    this.setFilter(where);
  }

  clearFilterTimeout() {
    const { filterTimeout } = this.state;
    if (filterTimeout) clearTimeout(filterTimeout);
  }

  updateQuery(newQuery) {
    const { servers, setLocationsQuery, getMasterLocations } = this.props;
    const query = { ...servers.locationsQuery, ...newQuery };

    setLocationsQuery(query);
    getMasterLocations(query);
  }

  rowDataToObj(rowData) {
    const { columns } = this.state;
    return rowDataToObj(columns, rowData);
  }

  async handleOnClickEdit(obj) {
    const {
      servers,
      setEditLocation,
      openLocationEditDialog,
      getSuppliers,
      getLocationDetail,
    } = this.props;
    const category = obj && obj.category && obj.category > 1 ? Number(obj.category) : 0;
    let query = { ...servers.locationsQuery };
    query.where.id = obj.id;
    delete query.limit;
    delete query.offset;
    getSuppliers(DefaultQuery, category);
    getLocationDetail(query).then((res) => {
      setEditLocation(res.data.objects[0]);
      openLocationEditDialog(res.data.objects[0]);
      delete query.where.id;
    });
  }

  handleOnChangePage(currentPage) {
    const { servers } = this.props;
    const { limit } = servers.locationsQuery;
    this.updateQuery({ offset: currentPage * limit });
  }

  handleOnColumnSortChange(columns, changedColumn, direction) {
    for (const column of columns) {
      if (column.name === changedColumn) {
        if (direction.match(/desc/)) column.options.sortDirection = 'desc';
        else column.options.sortDirection = 'asc';
      } else {
        delete column.options.sortDirection;
      }
    }
    this.updateQuery({ order: [[changedColumn, direction.match(/desc/) ? 'DESC' : 'ASC']] });
  }

  handleChangeTime(filterKey, dbKey, v) {
    this.clearFilterTimeout();
    const from = v && v.length && new Date(v[0].setHours(0, 0, 0));
    const to = v && v.length && new Date(v[1].setHours(23, 59, 59));
    const newState: Partial<State> = {};

    newState[filterKey] = v;

    newState.filterTimeout = setTimeout(() => {
      if (v.length) {
        this.setFilter({ [dbKey]: { $gte: from.toISOString(), $lte: to.toISOString() } });
      } else {
        this.unsetFilter(dbKey);
      }
    }, 1000);

    this.setState(newState as State);
  }

  handleChangeCreatedAt(v) {
    this.handleChangeTime('filterCreatedAt', 'createdAt', v);
  }

  handleChangeUpdatedAt(v) {
    this.handleChangeTime('filterUpdatedAt', 'updatedAt', v);
  }

  handleChangeFilterName(v) {
    this.clearFilterTimeout();
    this.setState({
      filterName: v,
      filterTimeout: setTimeout(() => this.setFilter({ name: { $like: `%${v}%` } }), 1000),
    });
  }

  handleChangeFilterCustomerCode(v) {
    this.clearFilterTimeout();
    this.setState({
      filterPublicId: v,
      filterTimeout: setTimeout(() => this.setFilter({ customerCode: { $like: `%${v}%` } }), 1000),
    });
  }

  handleChangeFilterCategory(v) {
    if (v === '') this.clearFilterTimeout();
    this.setState({
      filterCategory: v === '' ? v : parseInt(v),
      filterTimeout: setTimeout(() => {
        if (v === '') return this.unsetFilter('category');
        if (parseInt(v) !== 3) {
          this.setState({ filterSubCategory: '' });
          this.setState({ filterCustomerStatus: '' });
          setTimeout(() => this.unsetFilter('subCategory'), 1000);
        }
        return this.setFilter({ category: parseInt(v) });
      }, 1000),
    });
  }

  handleChangeFilterSubCategory(v) {
    this.clearFilterTimeout();
    this.setState({
      filterSubCategory: v === '' ? v : parseInt(v),
      filterTimeout: setTimeout(
        () =>
          v === '' ? this.unsetFilter('subCategory') : this.setFilter({ subCategory: parseInt(v) }),
        1000,
      ),
    });
  }

  handleChangeFilterCountry(v) {
    this.clearFilterTimeout();
    this.setState({
      filterCountry: v,
      filterTimeout: setTimeout(
        () => (v === '' ? this.unsetFilter('country') : this.setFilter({ country: v })),
        1000,
      ),
    });
  }

  // handleChangeFilterHidden(v) {
  //   this.clearFilterTimeout();
  //   this.setState({
  //     filterHidden: v === '' ? v : parseInt(v),
  //     filterTimeout: setTimeout(
  //       () => (v === '' ? this.unsetFilter('hidden') : this.setFilter({ hidden: parseInt(v) })),
  //       1000,
  //     ),
  //   });
  // }

  handleChangeFilterCustomerStatus(v) {
    this.clearFilterTimeout();
    this.setState({
      filterCustomerStatus: v === '' ? v : parseInt(v),
      filterTimeout: setTimeout(() => {
        if (v === '') {
          this.unsetFilter('status');
        } else {
          this.setFilter({ status: parseInt(v) });
        }
      }, 1000),
    });
  }

  handleClickResetFilter() {
    this.setState({ ...this.initialFilters });
    const { servers, setLocationsQuery, getMasterLocations } = this.props;
    const name = servers.locationsQuery.where?.name;
    const query = { ...servers.locationsQuery, where: name ? { name } : {} };
    setLocationsQuery(query);
    getMasterLocations(query);
  }

  render() {
    const { classes, apps, servers } = this.props;
    const {
      enableFilter,
      filterTimeout,
      filterCreatedAt,
      filterUpdatedAt,
      filterName,
      filterPublicId,
      filterCategory,
      filterSubCategory,
      // filterHidden,
      filterCountry,
      filterCustomerStatus,
      initialized,
      columns,
    } = this.state;
    const lang = apps.currentLanguage;

    const { offset, limit } = servers.locationsQuery;
    const currentPage = offset / limit;
    const numberOfRows = limit;
    const { user } = servers;
    const userCategory = getUserCategory(user);
    const isBrewery = userCategory === ECategoryType.BREWERY;
    const isAdmin = userCategory === ECategoryType.ADMIN;
    const isDistributor = userCategory === ECategoryType.DISTRIBUTOR;
    const { categoryMapLocation, subCategoryMap } = userEnv;
    const categories = [
      { id: '', name: appLanguages.empty[lang] },
      ...Object.entries(categoryMapLocation[lang]).map(([k, v]) => ({
        id: k,
        name: v,
      })),
    ].filter((item) => {
      let isReturn = true;
      if (isDistributor) {
        switch (ECategoryType[item.id]) {
          case 'ADMIN':
            isReturn = false;
            break;
          case 'BREWERY':
            isReturn = false;
            break;
          default:
            break;
        }
      }
      return isReturn;
    });
    const subCategories = [
      { id: '', name: appLanguages.unspecified[lang] },
      ...Object.entries(subCategoryMap[lang]).map(([k, v]) => ({
        id: k,
        name: v,
      })),
    ];
    // const hiddenList = [
    //   { id: '', name: appLanguages.unspecified[lang] },
    //   { id: 0, name: appLanguages.disable[lang] },
    //   { id: 1, name: appLanguages.enable[lang] },
    // ];
    const customerStatusList = [
      { id: '', name: '-' },
      { id: 5, name: appLanguages.locationStatusPending[lang] },
      { id: 2, name: appLanguages.locationStatusActivated[lang] },
      { id: 1, name: appLanguages.locationStatusReviewing[lang] },
    ];

    const locationRegions = servers.locationRegions;
    const countries = locationRegions
      ? [
          { id: '', name: '-' },
          ...locationRegions.map((v) => ({
            id: v,
            name: v,
          })),
        ]
      : [];

    const { where } = servers.locationsQuery;
    const isRequesting =
      servers.isRequesting || servers.isGetRequesting || Boolean(filterTimeout) || !initialized;
    const filtersView = (
      <>
        <Grid container alignItems='flex-end'>
          <Grid item>
            {getDateRangePicker(
              appLanguages.createdAt[lang],
              filterCreatedAt,
              (v) => this.handleChangeCreatedAt(v),
              lang,
              true,
            )}
          </Grid>
          <Grid item>
            <Box ml={2} />
          </Grid>
          <Grid item>
            {getDateRangePicker(
              appLanguages.updatedAt[lang],
              filterUpdatedAt,
              (v) => this.handleChangeUpdatedAt(v),
              lang,
              true,
            )}
          </Grid>
        </Grid>
        {/* ------------------------------ */}
        <Grid container alignItems='flex-end'>
          {/* <Grid item>
            {getSmallTextField(appLanguages.name[lang], filterName, false, (e) =>
              this.handleChangeFilterName(e.target.value),
            )}
          </Grid> */}
          <Grid item>
            <Box ml={2} />
          </Grid>
          <Grid item>
            {getSmallTextField(appLanguages.customerCode[lang], filterPublicId, false, (e) =>
              this.handleChangeFilterCustomerCode(e.target.value),
            )}
          </Grid>
          <Grid item>
            <Box ml={2} />
          </Grid>
          <Grid item>
            {getSmallSelect(appLanguages.category[lang], filterCategory, categories, false, (e) =>
              this.handleChangeFilterCategory(e.target.value),
            )}
          </Grid>
          <Grid item>
            <Box ml={2} />
          </Grid>
          <Grid item>
            {getSmallSelect(
              appLanguages.locationBusinessType[lang],
              filterSubCategory,
              subCategories,
              false,
              (e) => this.handleChangeFilterSubCategory(e.target.value),
            )}
          </Grid>
          <Grid item>
            <Box ml={2} />
          </Grid>
          {/* <Grid item>
            {getSmallSelect(
              appLanguages.hiddenSetting[lang],
              filterHidden,
              hiddenList,
              false,
              (e) => this.handleChangeFilterHidden(e.target.value),
            )}
          </Grid> */}
          {(isAdmin || isBrewery) && (
            <Grid container alignItems='flex-end'>
              <Grid item>
                <Box ml={2} />
              </Grid>
              <Grid item>
                {getSmallSelect(appLanguages.country[lang], filterCountry, countries, false, (e) =>
                  this.handleChangeFilterCountry(e.target.value),
                )}
              </Grid>
              <Grid item>
                <Box ml={2} />
              </Grid>
              <Grid item>
                {getSmallSelect(
                  appLanguages.locationStatus[lang],
                  filterCustomerStatus,
                  customerStatusList,
                  false,
                  (e) => this.handleChangeFilterCustomerStatus(e.target.value),
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container justify='flex-end' alignItems='flex-end'>
          {Object.keys(where).length ? (
            <>
              <Grid item xs={3}>
                {/* <Box mt={1} /> */}
              </Grid>
              <Grid item>
                {getSmallClearButton(appLanguages.reset[lang], false, () =>
                  this.handleClickResetFilter(),
                )}
              </Grid>
            </>
          ) : null}
        </Grid>
      </>
    );

    /* 参考1: https://github.com/gregnb/mui-datatables */
    /* 参考2 (textLabels): https://github.com/gregnb/mui-datatables/blob/master/src/textLabels.js */
    return (
      <>
        <MuiThemeProvider theme={appMuiTheme}>
          {isRequesting ? <LinearProgress color='secondary' /> : <WhiteLinearProgress />}
          <MUIDataTable
            title={
              <>
                {enableFilter ? <Box mt={1} /> : ''}
                <Paper
                  elevation={enableFilter ? 3 : 0}
                  className={enableFilter ? classes.paper : classes.none}
                >
                  {(isBrewery || isAdmin || isDistributor) &&
                    getFilterButton(
                      enableFilter,
                      () => this.setState({ enableFilter: !enableFilter }),
                      lang,
                    )}
                  {enableFilter ? filtersView : ''}
                </Paper>
                {enableFilter ? <Box mt={1} /> : ''}
                <Grid container alignItems='flex-end'>
                  <Grid item>
                    <Box ml={2} />
                  </Grid>
                  <Grid item>
                    {getSmallSearchField(appLanguages.searchByName[lang], filterName, false, (e) =>
                      this.handleChangeFilterName(e.target.value),
                    )}
                  </Grid>
                </Grid>
                <Box mt={10} />
              </>
            }
            data={servers.locations}
            columns={columns}
            options={{
              ...muiDataTableCommonOptions(),
              currentPage,
              rowsPerPage: numberOfRows,
              count: servers.locationsTotalCounts,
              customToolbar: () => <LocationsCustomToolbar />,
              onChangePage: (currentPage) => this.handleOnChangePage(currentPage),
              onChangeRowsPerPage: (numberOfRows) =>
                this.updateQuery({ offset: 0, limit: numberOfRows }),
              onColumnSortChange: (changedColumn, direction) =>
                this.handleOnColumnSortChange(columns, changedColumn, direction),
            }}
          />
        </MuiThemeProvider>
        {apps.isOpenLocationEditDialog ? <LocationEditDialog /> : ''}
      </>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  auth: IStateAuth;
  classes: any;
  locationList: any;
}

export interface IDispatchProps {
  confirm?: any;
  setEditLocation: (locationObj: ILocation) => void;
  openLocationEditDialog: (locationObj: ILocation) => void;
  getMasterLocations: (query: IQuery) => void;
  getLocationDetail: (query: IQuery) => any;
  setLocationsQuery: (query: IQuery) => void;
  getLocationRegions: (query: IQuery) => void;
  delLocation: (locationObj: ILocation) => void;
  getSuppliers: (query: IQuery, category: ECategoryType) => void;
  setCurrentPage: (pageId: AllPages) => void;
}

interface State {
  filterCreatedAt: any;
  filterUpdatedAt: any;
  filterName: string;
  filterPublicId: string;
  filterCategory: string;
  filterSubCategory: string;
  filterCountry: string;
  filterHidden: string;
  filterCustomerStatus: string;
  enableFilter: boolean;
  filterTimeout: any;
  initialized: boolean;
  columns: any;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  auth: state.auth,
  servers: state.servers,
});

const mapDispatchToProps: IDispatchProps = {
  setEditLocation,
  openLocationEditDialog,
  getMasterLocations,
  getLocationDetail,
  setLocationsQuery,
  delLocation,
  getSuppliers,
  getLocationRegions,
  setCurrentPage,
};

const myStyles = (theme: Theme): StyleRules => ({
  none: {},
  paper: { padding: theme.spacing(2) },
});

export const Locations = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(withConfirm(LocationsClass));
