import { TAlertStatus } from '@models/alert-status-type';
import { isOk } from '@utils/is-ok';
import { TextDisplay } from '@visual/text-display';
import hash from 'object-hash';
import React from 'react';
import styles from './style.scss';

export class DistributionCard extends React.Component<Props> {
  public render(): JSX.Element {
    const { label, cardData } = this.props;

    if (!isOk(label) && !isOk(cardData)) return <React.Fragment />;
    return <>{this.getCard()}</>;
  }

  protected getCard(): JSX.Element {
    const { cardData, label, mainAlertMessages } = this.props;
    const content =
      isOk(cardData) &&
      cardData
        .filter((data) => data)
        .map((data, i) => {
          return (
            <div className={styles.contentRow} key={hash(i)}>
              <div className={styles.contentRowFlex}>
                <span>
                  <TextDisplay>{data.label}</TextDisplay>
                </span>
                <span>
                  <TextDisplay>{data.value}</TextDisplay>
                </span>
              </div>
              {this.getAlertMessage(data.alertMessage)}
            </div>
          );
        });

    return (
      <div className={this.getClassNames(isOk(content))}>
        <div className={styles.originName}>
          <TextDisplay size='large'>{label}</TextDisplay>
          {mainAlertMessages && <TextDisplay>{mainAlertMessages}</TextDisplay>}
        </div>
        <div className={styles.content}>{content}</div>
      </div>
    );
  }

  protected getClassNames(hasContent: boolean) {
    const { cardData, mainAlertMessages, alertStatus } = this.props;
    let hasAlert = false;
    if (isOk(cardData)) {
      hasAlert = isOk(cardData.filter((card) => card.alertMessage)) || isOk(mainAlertMessages);
    }

    return [
      styles.card,
      hasContent && styles.hasContent,
      hasAlert && alertStatus && styles[`card-alert-${alertStatus}`],
    ]
      .filter((style) => style)
      .join(' ');
  }

  protected getAlertMessage(alertMessage: string): JSX.Element {
    if (!isOk(alertMessage)) return;

    return (
      <div className={styles.alertMessageContainer}>
        <div className={styles.alertMessage}>
          <TextDisplay size='small' display='inline'>
            {alertMessage}
          </TextDisplay>
        </div>
      </div>
    );
  }
}

export type Props = IStateProps;

export interface IStateProps {
  label?: string;
  mainAlertMessages?: string;
  alertStatus?: TAlertStatus;
  cardData?: CardData[];
}

export interface CardData {
  label: string;
  value: string;
  alertMessage?: string;
}
