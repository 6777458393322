import TabPanel from '@elements/TabPanel';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Paper,
  StyleRules,
  TextField,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  AlarmAdd as TempRegisterIcon,
  Edit as EditIcon,
  LibraryAdd as LibraryAddIcon,
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ECategoryType } from '@models/category-type';
import { IStateApps } from '@models/state-apps';
import { IStateServers } from '@models/state-servers';
import { closeUserEditDialog, openSnackbarMessage, setEditUser } from '@redux/actions/appsActions';
import {
  activateLocation,
  activateUser,
  getUserLocations,
  registerUser,
} from '@redux/actions/serversActions';
import appLanguages from '@utils/app-languages';
import {
  exportToCSV,
  getAutocomplete,
  getCSVFileUploadButton,
  getFileDeleteButton,
  getImageFileUploadButton,
  getOutlinedButton,
  getTextField,
  getTextFieldForPassword,
  getTextFieldMultiline2,
} from '@utils/common';
import { DefaultQuery } from '@utils/default-query';
import { functions, storage } from '@utils/firebase';
import { getUserCategory } from '@utils/get-user-category';
import { isOk } from '@utils/is-ok';
import { validateEmail } from '@utils/validate-email';
import * as csvSync from 'csv-parse/lib/sync';
import * as hash from 'object-hash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import userEnv from 'userEnv';

class UserEditDialog extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = {
    tempRegistrationEnabled: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      mainTabValue: 0,
      csvFileText: null,
      csvFileObjects: null,
      csvUpCount: 0,
      warningMessages: [],
      infoMessages: [],
      registering: false,
      showPassword: false,
    };
  }

  public async componentDidMount() {
    const { servers, getUserLocations, apps, setEditUser } = this.props;
    const userCategory = getUserCategory(servers?.user);
    let locationListCategory = apps?.editUser?.locationCategory || servers?.user?.locationCategory;
    if (userCategory === ECategoryType.DISTRIBUTOR && !isOk(apps?.editUser?.locationCategory)) {
      locationListCategory = ECategoryType.RESTAURANT;
    }
    // const { apps, setEditUser } = this.props;
    setEditUser({
      ...apps.editUser,
      email: apps.editLocation.email ? apps.editLocation.email : apps.editUser.email,
    });

    await getUserLocations(locationListCategory);
  }

  updateSetEditUser(updateObj) {
    const { apps, setEditUser } = this.props;
    setEditUser({ ...apps.editUser, ...updateObj });
  }

  handleChangeName(e) {
    this.updateSetEditUser({ name: e.target.value });
  }

  handleChangeEmail(e) {
    this.updateSetEditUser({ email: e.target.value });
  }

  handleChangePassword(e) {
    this.updateSetEditUser({ password: e.target.value });
  }

  handleChangeApprovalComment(e) {
    this.updateSetEditUser({ approvalComment: e.target.value });
  }

  handleChangeLocationCategory(v) {
    const { getUserLocations } = this.props;
    getUserLocations(v && v > 0 ? Number(v) : 0);
    this.updateSetEditUser({ locationCategory: v });
  }

  handleChangeUserLocation(v) {
    this.updateSetEditUser({ locationId: v });
  }

  handleChangeCompany(e) {
    this.updateSetEditUser({ company: e.target.value });
  }

  handleActivateUser(approve = true) {
    const {
      activateUser,
      apps: { editUser: userObj },
    } = this.props;
    const params = {
      id: userObj.id,
      approval: approve,
      note: userObj.approvalComment,
    };

    activateUser(params);
    return true;
  }

  async handleClickRegister() {
    const {
      servers,
      apps,
      apps: { currentLanguage: lang },
      registerUser,
      openSnackbarMessage,
      isFirstCreate,
      activateLocation,
      locationObj,
    } = this.props;
    const { mainTabValue, csvFileObjects } = this.state;
    if (mainTabValue === 0) {
      const { editUser, currentLanguage } = apps;
      const lang = currentLanguage;
      if (!editUser?.id) {
        editUser.creatorId = servers?.user?.id;
      }
      if (!editUser?.name) return openSnackbarMessage('error', appLanguages.pleaseSetTheName[lang]);
      if (!editUser?.email)
        return openSnackbarMessage('error', appLanguages.pleaseSetTheEmail[lang]);
      if (!validateEmail(editUser?.email))
        return openSnackbarMessage('error', appLanguages.invalidEmailFormat[lang]);
      if (!editUser?.password && !editUser?.id)
        return openSnackbarMessage('error', appLanguages.pleaseSetThePassword[lang]);
      for (const userLocation of servers.userLocations) {
        if (userLocation.id === editUser?.locationId || isFirstCreate) {
          await registerUser(editUser, true)
            .then((res) => {
              if (res?.data === 'Success' && isFirstCreate)
                activateLocation({ ...locationObj }, true);
            })
            .catch((e) => console.log(e));
          return;
        }
      }
      // Error
      openSnackbarMessage('error', appLanguages.pleaseSetTheLocation[lang]);
    } else {
      this.clearInfoMessages();
      this.clearWarningMessages();
      this.setState({ registering: true });
      setTimeout(() => this.setState({ registering: false }), 3000);
      let index = 0;
      for (const csvFileObj of csvFileObjects) {
        index += 1;
        if (index > 20) {
          this.addWarningMessages(appLanguages.errorServerLoad[lang]);
          break;
        }
        if (!csvFileObj || csvFileObj.length < Object.keys(this.formatData()).length) {
          this.addWarningMessages(`${index}${appLanguages.errorRowNotEnoughItems[lang]}`);
          continue;
        }
        const obj = {
          name: csvFileObj[0], // 名前
          email: csvFileObj[1], // メールアドレス
          password: csvFileObj[2], // パスワード
          imageUrl: csvFileObj[3], // アバター画像URL
          company: csvFileObj[5], // 会社
          locPublicId: csvFileObj[6], // 拠点公開ID
        };
        if (obj.name === 'メールアドレス') continue; // ヘッダーはスキップ
        if (!obj.name || !obj.email || !obj.password) {
          this.addWarningMessages(
            `${index}${appLanguages.errorRowIncompleteRequiredFieldsUser[lang]}`,
          );
          continue;
        }
        if (!obj.email.match(/@/)) {
          this.addWarningMessages(`${index}${appLanguages.errorRowMailAddressFormatInvalid[lang]}`);
          continue;
        }
        const query = { ...DefaultQuery, limit: 1, where: { email: obj.email } };
        const targetIndex = index;
        // eslint-disable-next-line no-loop-func
        functions
          .httpsCallable('getUsers')(query)
          .then((result) => {
            const { data } = result;
            const { objects } = data;
            if (objects && objects.length) {
              // obj = { ...objects[0], ...obj, id: objects[0].id };
              this.addWarningMessages(
                `${targetIndex}${appLanguages.errorRowAlreadyRegistered[lang]}${appLanguages.email[lang]}(${obj.email})です。`,
              );
            } else {
              // 新規登録のみ対応
              registerUser(obj);
              this.addInfoMessages(
                `${targetIndex}${appLanguages.rowUser[lang]}(${obj.email})${appLanguages.addedSuffix[lang]}`,
              );
            }
          });
      }
    }
    return true;
  }

  addWarningMessages(message) {
    if (!message) return;
    const { warningMessages } = this.state;
    warningMessages.push(message);
    this.setState(warningMessages);
  }

  addInfoMessages(message) {
    if (!message) return;
    const { infoMessages } = this.state;
    infoMessages.push(message);
    this.setState(infoMessages);
  }

  clearWarningMessages() {
    this.setState({ warningMessages: [] });
  }

  clearInfoMessages() {
    this.setState({ infoMessages: [] });
  }

  handleShowPasswordToggle() {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  }

  handleChangeImgFiles(e) {
    const { files } = e.target;
    const { servers } = this.props;
    for (let i = 0; i < files.length; i += 1) {
      const imgFile = files[i];
      const fileName = `${imgFile.size}-${imgFile.lastModified}-${imgFile.type}`.replace('/', '_');
      const storageRef = storage.ref(`upload/${servers.user.uid}/users/${fileName}`);
      const uploadTask = storageRef.put(imgFile);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          window.console.log(`Upload is ${progress}% done. state is ${snapshot.state}`);
        },
        (error) => {
          window.console.error(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.updateSetEditUser({ imageUrl: downloadURL });
          });
        },
      );
    }
  }

  handleChangeCSVFiles(e) {
    const {
      openSnackbarMessage,
      apps: { currentLanguage: lang },
    } = this.props;
    const { csvUpCount } = this.state;
    this.setState({
      csvUpCount: csvUpCount + 1,
      warningMessages: [],
      infoMessages: [],
    });
    const { files } = e.target;
    if (!files.length) return;
    const csvFile = files[0];
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.onerror = () => {
      openSnackbarMessage('warning', appLanguages.fileReadFailed[lang]);
    };
    reader.onload = () => {
      const text: string = (reader.result as string) || '';
      const result = csvSync(text);
      if (!result || !result.length) {
        openSnackbarMessage('warning', appLanguages.csvFormatError[lang]);
      } else {
        this.setState({
          csvFileText: text,
          csvFileObjects: result,
        });
      }
    };
    reader.readAsText(csvFile);
  }

  formatData() {
    const { apps } = this.props;
    const lang = apps.currentLanguage;
    return {
      [appLanguages.name[lang]]: '',
      [appLanguages.email[lang]]: '',
      [appLanguages.password[lang]]: '',
      [appLanguages.avatarImageUrl[lang]]: '',
      [appLanguages.company[lang]]: '',
      [appLanguages.locationId[lang]]: '',
    };
  }

  renderUploadCSVView() {
    const { classes, apps, servers } = this.props;
    const {
      csvFileText,
      csvFileObjects,
      csvUpCount,
      warningMessages,
      infoMessages,
      registering,
    } = this.state;

    const lang = apps.currentLanguage;
    const isRequesting = servers.isGetRequesting || servers.isRequesting || registering;

    return (
      <Paper elevation={0}>
        <Grid container justify='center' alignItems='flex-start' alignContent='flex-start'>
          <Grid item xs={12}>
            <Box mt={1} />
          </Grid>
          <Grid item>
            <Typography variant='caption'>{appLanguages.csvFileFormatPrefix[lang]}</Typography>
            <Button
              color='primary'
              onClick={() => exportToCSV('users_format_', [this.formatData()])}
            >
              {appLanguages.here[lang]}
            </Button>
            <Typography variant='caption'>{appLanguages.downloadSuffix[lang]}</Typography>
          </Grid>
          <Grid item>
            {getCSVFileUploadButton(
              `users-csv-file${csvUpCount}`,
              appLanguages.csvUpload[lang],
              classes.fileInput,
              classes.fileButton,
              isRequesting,
              (e) => this.handleChangeCSVFiles(e),
            )}
            {csvFileObjects ? (
              getFileDeleteButton(
                appLanguages.reset[lang],
                classes.fileButton,
                isRequesting,
                () => {
                  this.setState({ csvFileText: null, csvFileObjects: null });
                  this.clearInfoMessages();
                  this.clearWarningMessages();
                },
              )
            ) : (
              <Box />
            )}
          </Grid>
          <Grid item xs={12}>
            {csvFileObjects && csvFileText ? (
              getTextFieldMultiline2(
                appLanguages.csvFileContents[lang],
                csvFileText,
                true,
                () => null,
              )
            ) : (
              <Box />
            )}
          </Grid>
          <Grid item xs={12}>
            {warningMessages.map((message) => (
              <Alert key={hash(message)} severity='warning'>
                {message}
              </Alert>
            ))}
            {infoMessages.map((message) => (
              <Alert key={hash(message)} severity='success'>
                {message}
              </Alert>
            ))}
          </Grid>
        </Grid>
      </Paper>
    );
  }

  renderUploadAvatarImageView() {
    const {
      classes,
      apps: { currentLanguage: lang, editUser: userObj },
      servers,
    } = this.props;
    const { registering } = this.state;

    const isRequesting = servers.isGetRequesting || servers.isRequesting || registering;

    return (
      <Paper elevation={3}>
        <Grid container justify='center' alignItems='flex-start' alignContent='flex-start'>
          <Grid item xs={12}>
            <Box mt={1} />
          </Grid>
          <Grid item>
            <Avatar
              onClick={() => null}
              src={userObj && userObj.imageUrl ? userObj.imageUrl : ''}
              className={classes.avatar}
            />
          </Grid>
          <Grid item xs={12}>
            <Box mt={1} />
          </Grid>
          <Grid item>
            {getImageFileUploadButton(
              'user-avatar-file',
              `${appLanguages.avatarImageUpload[lang]}`,
              classes.fileInput,
              classes.fileButton,
              isRequesting,
              (e) => this.handleChangeImgFiles(e),
            )}
            {userObj && userObj.imageUrl ? (
              getFileDeleteButton(
                appLanguages.resetImage[lang],
                classes.fileButton,
                isRequesting,
                () => this.updateSetEditUser({ imageUrl: '' }),
              )
            ) : (
              <Box />
            )}
          </Grid>
        </Grid>
      </Paper>
    );
  }

  renderUserInformation(forceDisable = false) {
    const {
      apps: { currentLanguage: lang, editUser: userObj },
      servers,
      isFirstCreate,
    } = this.props;
    const { registering, showPassword } = this.state;

    const { categoryMapUserRole } = userEnv;
    const userCategory = getUserCategory(servers?.user);

    // Location Category
    let locationCategories = Object.entries(categoryMapUserRole[lang]).map(([k, v]) => ({
      id: k,
      name: v,
    }));
    let defaultLocationCategory = 0;
    let currentLocationCategory =
      userObj && !isNaN(Number(userObj?.locationCategory))
        ? Number(userObj?.locationCategory)
        : null;

    if (userCategory === ECategoryType.DISTRIBUTOR) {
      locationCategories = locationCategories.filter(
        (category) => category.id === String(ECategoryType.RESTAURANT),
      );
      defaultLocationCategory = ECategoryType.RESTAURANT;
      if (!isOk(currentLocationCategory)) {
        currentLocationCategory = ECategoryType.RESTAURANT;
      }
    }

    const locationCategoryValue =
      currentLocationCategory === null
        ? null
        : {
            id: currentLocationCategory,
            name: categoryMapUserRole[lang][currentLocationCategory],
          };

    // User Locations
    const defaultUserLocation = 0;
    let userLocationValue = userObj ? { id: userObj.locationId, name: userObj.name } : null;
    const userLocations = servers.userLocations.map((o) => {
      const v = { id: o.id, name: `${o.name}` };
      if (userObj && o.id === userObj.locationId) userLocationValue = { ...v };
      return v;
    });
    const isRequesting =
      servers.isGetRequesting || servers.isRequesting || registering || forceDisable;
    let passwordLabel = `${appLanguages.password[lang]} (${appLanguages.require[lang]})`;
    if (userObj && userObj.id) {
      passwordLabel = `${appLanguages.password[lang]}`;
    }
    console.log(userObj.email);
    return (
      <>
        <Box mt={1} />
        {getTextField(
          `${appLanguages.name[lang]} (${appLanguages.require[lang]})`,
          userObj ? userObj.name || '' : '',
          isRequesting,
          (e) => this.handleChangeName(e),
        )}
        <Box mt={1} />
        {getTextField(
          `${appLanguages.email[lang]} (${appLanguages.require[lang]})`,
          userObj ? userObj.email || '' : '',
          isRequesting,
          (e) => this.handleChangeEmail(e),
        )}
        <Box mt={1} />
        {getTextFieldForPassword(
          passwordLabel,
          userObj ? userObj.password || '' : '',
          isRequesting,
          (e) => this.handleChangePassword(e),
          showPassword,
          () => this.handleShowPasswordToggle(),
        )}
        <Box mt={1} />
        {!isFirstCreate &&
          getAutocomplete(
            `${appLanguages.userBusinessType[lang]} (${appLanguages.require[lang]})`,
            locationCategoryValue,
            locationCategories,
            isRequesting,
            (e, v) => this.handleChangeLocationCategory(v ? v.id : defaultLocationCategory),
          )}
        <Box mt={1} />
        {
          <Autocomplete
            value={userLocationValue}
            options={userLocations}
            disabled={isRequesting || isFirstCreate}
            getOptionLabel={(o) => (o && o.name ? o.name : '')}
            onChange={(e, v) => this.handleChangeUserLocation(v ? v.id : defaultUserLocation)}
            getOptionSelected={(opt, v) => String(opt.id) === String(v.id)}
            renderInput={(p) => (
              <TextField
                {...p}
                label={`${appLanguages.belongLocation[lang]} ${
                  !userLocations.length
                    ? `(* ${appLanguages.pleaseRegTheLocBeforeUserReg[lang]})`
                    : `(${appLanguages.require[lang]})`
                }`}
                margin='normal'
                error={!userLocations.length}
                fullWidth
              />
            )}
          />
        }
        <Box mt={1} />
        {!isFirstCreate &&
          getTextField(
            `${appLanguages.company[lang]}`,
            userObj ? userObj.company || '' : '',
            isRequesting,
            (e) => this.handleChangeCompany(e),
          )}
        <Box mb={3} />
      </>
    );
  }

  renderButtons() {
    const {
      servers,
      apps: { currentLanguage: lang, editUser: userObj },
      closeUserEditDialog,
      tempRegistrationEnabled,
    } = this.props;
    const { mainTabValue, csvFileText, csvFileObjects, registering } = this.state;
    const id = userObj && userObj.id;
    const isRequesting = servers.isGetRequesting || servers.isRequesting || registering;

    const buttonList = [];

    // Cancel Button
    buttonList.push(
      getOutlinedButton(`${appLanguages.cancel[lang]}`, isRequesting, () => closeUserEditDialog()),
    );

    if (tempRegistrationEnabled && userObj.tempRegistration) {
      // Temp User Approve
      buttonList.push(
        getOutlinedButton(appLanguages.tempUserApprove[lang], !userObj.approvalComment, () =>
          setTimeout(() => this.handleActivateUser(true), 1),
        ),
      );

      // Temp User Reject
      buttonList.push(
        getOutlinedButton(appLanguages.tempUserReject[lang], !userObj.approvalComment, () =>
          setTimeout(() => this.handleActivateUser(false), 1),
        ),
      );
    } else {
      // Register / Update
      buttonList.push(
        getOutlinedButton(
          `${id ? appLanguages.update[lang] : appLanguages.register[lang]}`,
          isRequesting || (mainTabValue === 1 && (!csvFileObjects || !csvFileText)),
          () => setTimeout(() => this.handleClickRegister(), 1),
        ),
      );
    }

    return (
      <DialogActions>
        <Grid container justify='flex-end'>
          {buttonList.map((button, i) => (
            <React.Fragment key={hash(i)}>
              <Box ml={1} />
              {button}
            </React.Fragment>
          ))}
        </Grid>
      </DialogActions>
    );
  }

  renderTitle() {
    const {
      apps: { currentLanguage: lang, editUser: userObj },
      tempRegistrationEnabled,
      isFirstCreate,
    } = this.props;
    let icon;
    let titleText;

    if (!userObj || (userObj && !userObj.id) || isFirstCreate) {
      icon = <LibraryAddIcon fontSize='inherit' />;
      titleText = appLanguages.addNewUser[lang];
    } else if (tempRegistrationEnabled && userObj && userObj.tempRegistration) {
      icon = <TempRegisterIcon fontSize='inherit' />;
      titleText = appLanguages.tempRegistrationApproval[lang];
    } else {
      icon = <EditIcon fontSize='inherit' />;
      titleText = appLanguages.editUser[lang];
    }

    return (
      <Grid container>
        <Grid item>{icon}</Grid>
        <Grid item>
          <Box ml={1} />
        </Grid>
        <Grid item>{titleText}</Grid>
      </Grid>
    );
  }

  renderNormalUserContent() {
    const { classes, isFirstCreate } = this.props;
    const { mainTabValue } = this.state;

    return (
      <Paper elevation={3} className={classes.paper}>
        <TabPanel value={mainTabValue} index={0}>
          <Box>
            {!isFirstCreate && this.renderUploadAvatarImageView()}
            {this.renderUserInformation()}
          </Box>
        </TabPanel>
        <TabPanel value={mainTabValue} index={1}>
          {this.renderUploadCSVView()}
        </TabPanel>
      </Paper>
    );
  }

  renderTempUserContent() {
    const {
      apps: { editUser: userObj, currentLanguage: lang },
    } = this.props;
    return (
      <Box>
        {this.renderUserInformation(true)}
        {getTextFieldMultiline2(
          appLanguages.approvalComment[lang],
          userObj.approvalComment,
          false,
          (e) => this.handleChangeApprovalComment(e),
        )}
      </Box>
    );
  }

  render() {
    const {
      apps,
      servers,
      apps: { editUser: userObj },
      tempRegistrationEnabled,
    } = this.props;
    const { closeUserEditDialog } = this.props;
    const { registering } = this.state;
    const isRequesting = servers.isGetRequesting || servers.isRequesting || registering;

    return (
      <Dialog fullWidth open={apps.isOpenUserEditDialog} onClose={() => closeUserEditDialog()}>
        {isRequesting ? <LinearProgress color='secondary' /> : ''}
        <DialogTitle>{this.renderTitle()}</DialogTitle>
        <DialogContent>
          {tempRegistrationEnabled && userObj.tempRegistration
            ? this.renderTempUserContent()
            : this.renderNormalUserContent()}
        </DialogContent>
        {this.renderButtons()}
      </Dialog>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  classes: any;
  apps: IStateApps;
  servers: IStateServers;
  tempRegistrationEnabled?: boolean;
  isFirstCreate?: boolean;
  locationObj?: any;
}

export interface IDispatchProps {
  setEditUser: (any) => void;
  registerUser: (any, closePopup?: boolean) => any;
  closeUserEditDialog: () => void;
  getUserLocations: (num: number) => void;
  openSnackbarMessage: (type: any, message: any) => void;
  activateUser: (any) => void;
  activateLocation: (locationObj: any, closePopup?: boolean, isOnly?: boolean) => void;
}

interface State {
  mainTabValue: number;
  csvFileText: string;
  csvFileObjects: any;
  csvUpCount: number;
  warningMessages: any;
  infoMessages: any;
  registering: boolean;
  showPassword: boolean;
}

const mapStateToProps = (state) => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps = {
  setEditUser,
  closeUserEditDialog,
  registerUser,
  activateUser,
  getUserLocations,
  openSnackbarMessage,
  activateLocation,
};

const myStyles = (theme: Theme): StyleRules => ({
  fileButton: { margin: theme.spacing(1) },
  fileInput: { display: 'none' },
  avatar: {
    width: theme.spacing(14),
    height: theme.spacing(14),
  },
});

export default compose<any>(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(UserEditDialog);
