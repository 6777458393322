import { EAlertStatus, TAlertStatus } from '@models/alert-status-type';

export function getAlertStatusName(status: EAlertStatus): TAlertStatus {
  switch (status) {
    case EAlertStatus.UNPROCESSED:
    case EAlertStatus.PROCESSING:
      return 'unprocessed';
      break;
    /*case EAlertStatus.PROCESSING:
      return 'processing';
      break;*/
    case EAlertStatus.PROCESSED:
    case EAlertStatus.APPROVED:
      return 'processed';
      break;
    /*case EAlertStatus.APPROVED:
      return 'approved';
      break;*/
    case EAlertStatus.UNPROCESSABLE:
      return 'unprocessable';
      break;
  }
}
