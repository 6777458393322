import { RestaurantTableRow } from '@visual/restaurant-tablerow';
import { TextDisplay } from '@visual/text-display';
import hash from 'object-hash';
import React from 'react';
import styles from './style.scss';

export class RestaurantTable extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { children } = this.props;
    return (
      <table className={styles.restaurantTable}>
        {this.getColumnHeaders()}
        <tbody>{children}</tbody>
      </table>
    );
  }

  protected getColumnHeaders(): JSX.Element {
    const { columnHeaders } = this.props;
    return (
      <thead className={styles.tableHeader}>
        <tr className={styles.headerRow}>
          {columnHeaders.map((column, i) => (
            <td className={styles.headerCell} key={hash(i)}>
              <TextDisplay display='flex-centered-text'>{column.label}</TextDisplay>
            </td>
          ))}
        </tr>
      </thead>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  columnHeaders: ColumnHeader[];
  children: React.ReactElement<RestaurantTableRow>[];
}

export interface ColumnHeader {
  id: number;
  label: string;
  sortable?: boolean;
}

export interface IDispatchProps {
  onSortClick?: (columnId: number) => void;
}
