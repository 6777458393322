import WhiteLinearProgress from '@elements/WhiteLinearProgress';
import { Box, Grid, LinearProgress, Paper } from '@material-ui/core';
import { MuiThemeProvider, StyleRules, Theme, withStyles } from '@material-ui/core/styles';
import { AllPages } from '@models/all-pages';
import { IBrand } from '@models/brand';
import { IQuery } from '@models/query';
import { IStateApps } from '@models/state-apps';
import { IStateServers } from '@models/state-servers';
import { openBrandEditDialog, setCurrentPage, setEditBrand } from '@redux/actions/appsActions';
import {
  delBrand,
  getBrandLocations,
  getMasterBrands,
  setBrandsQuery,
} from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import appLanguages from '@utils/app-languages';
import {
  getDateRangePicker,
  getFilterButton,
  getSmallClearButton,
  getSmallTextField,
  nowrapHeader,
  withConfirm,
} from '@utils/common';
import * as cliTruncate from 'cli-truncate';
import MUIDataTable from 'mui-datatables';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  appMuiTheme,
  disableDisplayOpts,
  enableEmptyOpts,
  enableSortOnlyOpts,
  getActionsCustomBodyRender,
  getAvatarRoundedCustomBodyRender,
  getSelectCustomBodyRender,
  getTimeAtCustomBodyRender,
  muiDataTableCommonOptions,
  rowDataToObj,
} from '../../../utils/mui-datatables-ops';
import { BrandEditDialog } from './config/brand-edit-dialog';
import { BrandsCustomToolbar } from './config/brands-custom-toolbar';

class BrandsClass extends React.PureComponent<Props, State> {
  private initialFilters = {
    filterCreatedAt: [],
    filterUpdatedAt: [],
    filterName: '',
    filterPublicId: '',
    filterUrl: '',
  };

  constructor(props) {
    super(props);
    const { classes, apps } = this.props;
    const lang = apps.currentLanguage;
    const imageUrlOptions = {
      ...enableSortOnlyOpts,
      customBodyRender: (v) => (v ? getAvatarRoundedCustomBodyRender(v, classes.smallAvatar) : ''),
    };
    const locationIdOptions = {
      ...enableSortOnlyOpts,
      customBodyRender: (v) => getSelectCustomBodyRender(this.getLocationOptionMap(), v),
    };
    const urlOptions = { ...enableSortOnlyOpts, customBodyRender: (v) => cliTruncate(v, 20) };
    const timeOptions = { ...enableSortOnlyOpts, customBodyRender: getTimeAtCustomBodyRender };
    const actionOptions = {
      ...enableEmptyOpts,
      customBodyRender: (_, mt) => this.getActionsCustomBodyRender(mt),
    };

    this.state = {
      enableFilter: false,
      filterTimeout: undefined,
      ...this.initialFilters,
      initialized: false,
      columns: [
        // 日本語
        { name: 'intro', label: '', options: { ...disableDisplayOpts } },
        { name: 'tastingNote1', label: '', options: { ...disableDisplayOpts } },
        { name: 'tastingNote2', label: '', options: { ...disableDisplayOpts } },
        { name: 'paringFood', label: '', options: { ...disableDisplayOpts } },
        { name: 'servingSuggestion', label: '', options: { ...disableDisplayOpts } },
        { name: 'riceSpecific', label: '', options: { ...disableDisplayOpts } },
        { name: 'bottleSize', label: '', options: { ...disableDisplayOpts } },
        { name: 'alcohol', label: '', options: { ...disableDisplayOpts } },
        { name: 'description', label: '', options: { ...disableDisplayOpts } },
        // 英語
        { name: 'enName', label: '', options: { ...disableDisplayOpts } },
        { name: 'enIntro', label: '', options: { ...disableDisplayOpts } },
        { name: 'enTastingNote1', label: '', options: { ...disableDisplayOpts } },
        { name: 'enTastingNote2', label: '', options: { ...disableDisplayOpts } },
        { name: 'enParingFood', label: '', options: { ...disableDisplayOpts } },
        { name: 'enServingSuggestion', label: '', options: { ...disableDisplayOpts } },
        { name: 'enRiceSpecific', label: '', options: { ...disableDisplayOpts } },
        { name: 'enBottleSize', label: '', options: { ...disableDisplayOpts } },
        { name: 'enAlcohol', label: '', options: { ...disableDisplayOpts } },
        { name: 'enDescription', label: '', options: { ...disableDisplayOpts } },
        // 中国語
        { name: 'cnName', label: '', options: { ...disableDisplayOpts } },
        { name: 'cnIntro', label: '', options: { ...disableDisplayOpts } },
        { name: 'cnTastingNote1', label: '', options: { ...disableDisplayOpts } },
        { name: 'cnTastingNote2', label: '', options: { ...disableDisplayOpts } },
        { name: 'cnParingFood', label: '', options: { ...disableDisplayOpts } },
        { name: 'cnServingSuggestion', label: '', options: { ...disableDisplayOpts } },
        { name: 'cnRiceSpecific', label: '', options: { ...disableDisplayOpts } },
        { name: 'cnBottleSize', label: '', options: { ...disableDisplayOpts } },
        { name: 'cnAlcohol', label: '', options: { ...disableDisplayOpts } },
        { name: 'cnDescription', label: '', options: { ...disableDisplayOpts } },
        // 香港語
        { name: 'hkName', label: '', options: { ...disableDisplayOpts } },
        { name: 'hkIntro', label: '', options: { ...disableDisplayOpts } },
        { name: 'hkTastingNote1', label: '', options: { ...disableDisplayOpts } },
        { name: 'hkTastingNote2', label: '', options: { ...disableDisplayOpts } },
        { name: 'hkParingFood', label: '', options: { ...disableDisplayOpts } },
        { name: 'hkServingSuggestion', label: '', options: { ...disableDisplayOpts } },
        { name: 'hkRiceSpecific', label: '', options: { ...disableDisplayOpts } },
        { name: 'hkBottleSize', label: '', options: { ...disableDisplayOpts } },
        { name: 'hkAlcohol', label: '', options: { ...disableDisplayOpts } },
        { name: 'hkDescription', label: '', options: { ...disableDisplayOpts } },
        // 韓国語
        { name: 'krName', label: '', options: { ...disableDisplayOpts } },
        { name: 'krIntro', label: '', options: { ...disableDisplayOpts } },
        { name: 'krTastingNote1', label: '', options: { ...disableDisplayOpts } },
        { name: 'krTastingNote2', label: '', options: { ...disableDisplayOpts } },
        { name: 'krParingFood', label: '', options: { ...disableDisplayOpts } },
        { name: 'krServingSuggestion', label: '', options: { ...disableDisplayOpts } },
        { name: 'krRiceSpecific', label: '', options: { ...disableDisplayOpts } },
        { name: 'krBottleSize', label: '', options: { ...disableDisplayOpts } },
        { name: 'krAlcohol', label: '', options: { ...disableDisplayOpts } },
        { name: 'krDescription', label: '', options: { ...disableDisplayOpts } },
        // タイ語
        { name: 'thName', label: '', options: { ...disableDisplayOpts } },
        { name: 'thIntro', label: '', options: { ...disableDisplayOpts } },
        { name: 'thTastingNote1', label: '', options: { ...disableDisplayOpts } },
        { name: 'thTastingNote2', label: '', options: { ...disableDisplayOpts } },
        { name: 'thParingFood', label: '', options: { ...disableDisplayOpts } },
        { name: 'thServingSuggestion', label: '', options: { ...disableDisplayOpts } },
        { name: 'thRiceSpecific', label: '', options: { ...disableDisplayOpts } },
        { name: 'thBottleSize', label: '', options: { ...disableDisplayOpts } },
        { name: 'thAlcohol', label: '', options: { ...disableDisplayOpts } },
        { name: 'thDescription', label: '', options: { ...disableDisplayOpts } },

        // ベトナム語
        { name: 'viName', label: '', options: { ...disableDisplayOpts } },
        { name: 'viIntro', label: '', options: { ...disableDisplayOpts } },
        { name: 'viTastingNote1', label: '', options: { ...disableDisplayOpts } },
        { name: 'viTastingNote2', label: '', options: { ...disableDisplayOpts } },
        { name: 'viParingFood', label: '', options: { ...disableDisplayOpts } },
        { name: 'viServingSuggestion', label: '', options: { ...disableDisplayOpts } },
        { name: 'viRiceSpecific', label: '', options: { ...disableDisplayOpts } },
        { name: 'viBottleSize', label: '', options: { ...disableDisplayOpts } },
        { name: 'viAlcohol', label: '', options: { ...disableDisplayOpts } },
        { name: 'viDescription', label: '', options: { ...disableDisplayOpts } },
        // 基本情報
        { name: 'id', label: '', options: { ...disableDisplayOpts } },
        { name: 'logoImageUrl', label: '', options: { ...disableDisplayOpts } },
        {
          name: 'imageUrl',
          label: nowrapHeader(appLanguages.image[lang]),
          options: imageUrlOptions,
        },
        {
          name: 'name',
          label: nowrapHeader(appLanguages.name[lang]),
          options: { ...enableSortOnlyOpts },
        },
        {
          name: 'publicId',
          label: nowrapHeader(appLanguages.publicId[lang]),
          options: { ...enableSortOnlyOpts },
        },
        { name: 'url', label: nowrapHeader(appLanguages.brandUrl[lang]), options: urlOptions },
        {
          name: 'locationId',
          label: nowrapHeader(appLanguages.location[lang]),
          options: locationIdOptions,
        },
        {
          name: 'createdAt',
          label: nowrapHeader(appLanguages.createdAt[lang]),
          options: { ...timeOptions },
        },
        {
          name: 'updatedAt',
          label: nowrapHeader(appLanguages.updatedAt[lang]),
          options: { ...timeOptions },
        },
        { name: '', options: actionOptions },
      ],
    };
  }

  componentDidMount() {
    const { servers, getMasterBrands, getBrandLocations, setCurrentPage } = this.props;
    setTimeout(() => getBrandLocations(), 1);
    setTimeout(() => getMasterBrands({ ...servers.brandsQuery }), 300);
    setTimeout(() => this.setState({ initialized: true }), 600);
    setCurrentPage('master-brands');
  }

  getActionsCustomBodyRender(tableMeta) {
    const {
      servers,
      confirm,
      apps: { currentLanguage: lang },
    } = this.props;
    const obj: any = this.rowDataToObj(tableMeta.rowData);
    const isRequesting = servers ? servers.isRequesting : true;
    const delTitle = appLanguages.deletedBrand[lang];
    const delDesc = `${appLanguages.brand[lang]}「${obj.publicId}」${appLanguages.deleteConfirmationSuffix[lang]}`;
    const onClickEdit = (o) => this.handleOnClickEdit(o);
    const onClickDelete = (o) => this.handleOnClickDelete(o);
    return getActionsCustomBodyRender(
      obj,
      delTitle,
      delDesc,
      isRequesting,
      onClickEdit,
      onClickDelete,
      confirm,
    );
  }

  getLocationOptionMap() {
    const { servers } = this.props;
    const optionMap = {};
    servers.brandLocations.map((o) => {
      optionMap[o.id] = o.name;
      return o.id;
    });
    return optionMap;
  }

  setFilter(newWhere) {
    const { servers } = this.props;
    const where = { ...servers.brandsQuery.where, ...newWhere };
    this.updateQuery({ where });
    this.setState({ filterTimeout: null });
  }

  unsetFilter(key) {
    const { servers } = this.props;
    const { where } = servers.brandsQuery;
    if (where[key]) delete where[key];
    this.setFilter(where);
  }

  clearFilterTimeout() {
    const { filterTimeout } = this.state;
    if (filterTimeout) clearTimeout(filterTimeout);
  }

  rowDataToObj(rowData) {
    const { columns } = this.state;
    return rowDataToObj(columns, rowData);
  }

  updateQuery(newQuery) {
    const { servers, setBrandsQuery, getMasterBrands } = this.props;
    const query = { ...servers.brandsQuery, ...newQuery };
    setBrandsQuery(query);
    getMasterBrands(query);
  }

  handleOnClickEdit(obj) {
    const { setEditBrand, openBrandEditDialog, getBrandLocations } = this.props;
    getBrandLocations();
    setEditBrand(obj);
    openBrandEditDialog(obj);
  }

  handleOnClickDelete(obj) {
    const { delBrand } = this.props;
    delBrand(obj);
  }

  handleOnChangePage(currentPage) {
    const { servers } = this.props;
    const { limit } = servers.brandsQuery;
    this.updateQuery({ offset: currentPage * limit });
  }

  handleOnChangeRowsPerPage(numberOfRows) {
    this.updateQuery({ offset: 0, limit: numberOfRows });
  }

  handleOnColumnSortChange(changedColumn, direction) {
    const { columns } = this.state;
    for (let i = 0; i < columns.length; i += 1) {
      const column = columns[i];
      if (column.name === changedColumn) {
        if (direction.match(/desc/)) column.options.sortDirection = 'desc';
        else column.options.sortDirection = 'asc';
      } else {
        delete column.options.sortDirection;
      }
    }
    this.updateQuery({
      order: [[changedColumn, direction.match(/desc/) ? 'DESC' : 'ASC']],
    });
  }

  handleChangeTime(filterKey, dbKey, v) {
    this.clearFilterTimeout();
    const from = v && v.length && new Date(v[0].setHours(0, 0, 0));
    const to = v && v.length && new Date(v[1].setHours(23, 59, 59));
    const newState: Partial<State> = {
      [filterKey]: v,
      filterTimeout: setTimeout(
        () =>
          v.length
            ? this.setFilter({ [dbKey]: { $gte: from.toISOString(), $lte: to.toISOString() } })
            : this.unsetFilter(dbKey),
        1000,
      ),
    };
    this.setState(newState as State);
  }

  handleChangeCreatedAt(v) {
    this.handleChangeTime('filterCreatedAt', 'createdAt', v);
  }

  handleChangeUpdatedAt(v) {
    this.handleChangeTime('filterUpdatedAt', 'updatedAt', v);
  }

  handleChangeFilterName(v) {
    this.clearFilterTimeout();
    this.setState({
      filterName: v,
      filterTimeout: setTimeout(() => this.setFilter({ name: { $like: `%${v}%` } }), 1000),
    });
  }

  handleChangeFilterPublicId(v) {
    this.clearFilterTimeout();
    this.setState({
      filterPublicId: v,
      filterTimeout: setTimeout(() => this.setFilter({ publicId: { $like: `%${v}%` } }), 1000),
    });
  }

  handleChangeFilterUrl(v) {
    this.clearFilterTimeout();
    this.setState({
      filterUrl: v,
      filterTimeout: setTimeout(() => this.setFilter({ url: { $like: `%${v}%` } }), 1000),
    });
  }

  handleClickResetFilter() {
    this.setState({ ...this.initialFilters });
    const { servers, setBrandsQuery, getMasterBrands } = this.props;
    const query = { ...servers.brandsQuery, where: {} };
    setBrandsQuery(query);
    getMasterBrands(query);
  }

  render() {
    const { classes, apps, servers } = this.props;
    const {
      enableFilter,
      filterTimeout,
      filterCreatedAt,
      filterUpdatedAt,
      filterName,
      filterPublicId,
      filterUrl,
      initialized,
      columns,
    } = this.state;
    const { offset, limit, searchText } = servers.brandsQuery;
    const currentPage = offset / limit;
    const numberOfRows = limit;

    const lang = apps.currentLanguage;

    const { where } = servers.brandsQuery;
    const isRequesting =
      servers.isRequesting || servers.isGetRequesting || Boolean(filterTimeout) || !initialized;

    const filtersView = (
      <>
        <Grid container alignItems='flex-end'>
          <Grid item>
            {getDateRangePicker(
              appLanguages.createdAt[lang],
              filterCreatedAt,
              (v) => this.handleChangeCreatedAt(v),
              lang,
            )}
          </Grid>
          <Grid item>
            <Box ml={2} />
          </Grid>
          <Grid item>
            {getDateRangePicker(
              appLanguages.updatedAt[lang],
              filterUpdatedAt,
              (v) => this.handleChangeUpdatedAt(v),
              lang,
            )}
          </Grid>
        </Grid>
        {/* ------------------------------ */}
        <Grid container alignItems='flex-end'>
          <Grid item>
            {getSmallTextField(appLanguages.name[lang], filterName, false, (e) =>
              this.handleChangeFilterName(e.target.value),
            )}
          </Grid>
          <Grid item>
            <Box ml={2} />
          </Grid>
          <Grid item>
            {getSmallTextField(appLanguages.publicId[lang], filterPublicId, false, (e) =>
              this.handleChangeFilterPublicId(e.target.value),
            )}
          </Grid>
          <Grid item>
            <Box ml={2} />
          </Grid>
          <Grid item>
            {getSmallTextField(appLanguages.brandUrl[lang], filterUrl, false, (e) =>
              this.handleChangeFilterUrl(e.target.value),
            )}
          </Grid>
        </Grid>
        {Object.keys(where).length ? (
          <Grid container justify='flex-end' alignItems='flex-end'>
            <Grid item xs={12}>
              <Box mt={1} />
            </Grid>
            <Grid item>
              {getSmallClearButton(appLanguages.reset[lang], false, () =>
                this.handleClickResetFilter(),
              )}
            </Grid>
          </Grid>
        ) : (
          ''
        )}
      </>
    );

    /* 参考: https://github.com/gregnb/mui-datatables */
    return (
      <>
        <MuiThemeProvider theme={appMuiTheme}>
          {isRequesting ? <LinearProgress color='secondary' /> : <WhiteLinearProgress />}
          <MUIDataTable
            title={
              <>
                {enableFilter ? <Box mt={1} /> : ''}
                <Paper
                  elevation={enableFilter ? 3 : 0}
                  className={enableFilter ? classes.paper : classes.none}
                >
                  {getFilterButton(
                    enableFilter,
                    () => this.setState({ enableFilter: !enableFilter }),
                    lang,
                  )}
                  {enableFilter ? filtersView : ''}
                </Paper>
                {enableFilter ? <Box mt={1} /> : ''}
              </>
            }
            data={servers.brands}
            columns={columns}
            options={{
              ...muiDataTableCommonOptions(),
              currentPage,
              rowsPerPage: numberOfRows,
              searchText,
              count: servers.brandsTotalCounts,
              customToolbar: () => <BrandsCustomToolbar />,
              onChangePage: (currentPage) => this.handleOnChangePage(currentPage),
              onChangeRowsPerPage: (numberOfRows) => this.handleOnChangeRowsPerPage(numberOfRows),
              onColumnSortChange: (changedColumn, direction) =>
                this.handleOnColumnSortChange(changedColumn, direction),
            }}
          />
        </MuiThemeProvider>
        {apps.isOpenBrandEditDialog ? <BrandEditDialog /> : ''}
      </>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;

  servers: IStateServers;
  classes: any;
}

export interface IDispatchProps {
  confirm?: any;
  setEditBrand: (brandObj: IBrand) => void;
  openBrandEditDialog: (brandObj: IBrand) => void;
  getMasterBrands: (query: IQuery) => void;
  setBrandsQuery: (query: IQuery) => void;
  delBrand: (brandObj: IBrand) => void;
  getBrandLocations: () => void;
  setCurrentPage: (pageId: AllPages) => void;
}

interface State {
  enableFilter: boolean;
  filterTimeout: any;
  filterCreatedAt: any;
  filterUpdatedAt: any;
  filterName: string;
  filterPublicId: string;
  filterUrl: string;
  initialized: boolean;
  columns: any;
}
const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps: IDispatchProps = {
  setEditBrand,
  openBrandEditDialog,
  getMasterBrands,
  setBrandsQuery,
  delBrand,
  getBrandLocations,
  setCurrentPage,
};

const myStyles = (theme: Theme): StyleRules => ({
  smallAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  none: {},
  paper: { padding: theme.spacing(2) },
});

export const Brands = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(withConfirm(BrandsClass));
