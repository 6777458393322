import { TSupportedLanguages } from '@models/supported-languages';
import { LANGUAGE_DEFAULT } from '@utils/common';
import { SearchFilterLink } from '@visual/search-filter-link';
import React from 'react';
import styles from './style.scss';

export class SearchFilterAnchor extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    lang: LANGUAGE_DEFAULT,
  };

  public render(): JSX.Element {
    const { lang, onSearchButtonClick } = this.props;
    return (
      <div className={styles.searchFilterAnchor}>
        <SearchFilterLink lang={lang} onSearchButtonClick={onSearchButtonClick} />
      </div>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  lang?: TSupportedLanguages;
}
export interface IDispatchProps {
  onSearchButtonClick: () => void;
}
