import { EAlert } from '@models/alert-type';
import { IAlert } from '@models/api-get-alerts';
import { ECategoryType } from '@models/category-type';
import { ISerial } from '@models/serial';
import { isOk } from '@utils/is-ok';
import { getCurrentLocations } from './get-current-locations';

export function getAvailableAlertResolutions(
  userCategory: ECategoryType,
  targetSerial: ISerial,
  targetAlert: IAlert,
): IGetAvailableAlertResolutionsResponse {
  const { currentBrewLoc, currentDistLoc, currentRestLoc } = getCurrentLocations(targetSerial);

  // Alert Info
  let alertCategory: ECategoryType;
  if (isOk(targetAlert?.alertLocId)) {
    if (currentBrewLoc?.id === targetAlert?.alertLocId) {
      console.log(targetAlert);
      alertCategory = ECategoryType.BREWERY;
    } else if (currentDistLoc?.id === targetAlert?.alertLocId) {
      alertCategory = ECategoryType.DISTRIBUTOR;
    } else if (currentRestLoc?.id === targetAlert?.alertLocId) {
      alertCategory = ECategoryType.RESTAURANT;
    }
  }

  // Variable
  const isBrewUser = userCategory === ECategoryType.BREWERY;
  const isDistAlert = alertCategory === ECategoryType.DISTRIBUTOR;
  const isDistUser = userCategory === ECategoryType.DISTRIBUTOR;
  const isRestAlert = alertCategory === ECategoryType.RESTAURANT;
  const isRestUser = userCategory === ECategoryType.RESTAURANT;
  const isAdminUser = userCategory === ECategoryType.ADMIN;

  // Brew -> Dist -> Rest
  const isShippingPatternA = isOk(targetSerial?.distShipRestLoc);
  // Brew -> Rest
  /*const isShippingPatternB =
    isOk(targetSerial?.brewShipRestLoc) && !isOk(targetSerial?.brewShipDistLoc);*/

  // Flag Info
  let showChat = false;
  let showScanApp = false;
  let showShippingDestEdit = false;
  let showRangeEdit = false;
  let showSalesRecord = false;
  switch (targetAlert?.type) {
    case EAlert.SCAN_DATE:
      if (isDistAlert) {
        if (isBrewUser) {
          showShippingDestEdit = true;
          showChat = true;
        } else if (isDistUser) {
          showScanApp = true;
          showChat = true;
        } else if (isAdminUser) {
          showShippingDestEdit = true;
          showChat = false;
        }
      } else if (isRestAlert) {
        if ((isDistUser && isShippingPatternA) || isBrewUser) {
          showShippingDestEdit = true;
          showChat = true;
        } else if (isRestUser) {
          showScanApp = true;
          showChat = true;
        } else if (isAdminUser) {
          showShippingDestEdit = true;
          showChat = false;
        }
      }
      break;
    case EAlert.QUALITY:
      if (isRestAlert && (isDistUser || isRestUser)) {
        //isRestAlert && isRestUser
        showChat = true;
      } else if (isAdminUser || isBrewUser) {
        showSalesRecord = true;
      }
      break;
    case EAlert.DIST_QUALITY:
      if (isDistAlert && isDistUser) {
        showChat = true;
      } else if (isAdminUser || isBrewUser) {
        showSalesRecord = true;
      }
      break;
    case EAlert.ILLEGAL_ROUTE:
      if (isDistAlert) {
        if (isBrewUser || isDistUser || isAdminUser) {
          showChat = true;
        }
      } else if (isRestAlert) {
        if (isDistUser || isRestUser || isAdminUser) {
          showChat = true;
        }
      }
      break;
    case EAlert.ILLEGAL_SHIPPING:
      if (isDistAlert) {
        if (isBrewUser || isDistUser || isAdminUser) {
          showChat = true;
        }
      }
      break;
    case EAlert.ILLEGAL_SELLING:
      if (isRestAlert) {
        if (isDistUser || isRestUser || isAdminUser) {
          showChat = true;
        }
      }
      break;
    default:
      break;
  }

  return {
    showChat,
    showScanApp,
    showShippingDestEdit,
    showRangeEdit,
    showSalesRecord,
  };
}

interface IGetAvailableAlertResolutionsResponse {
  showChat: boolean;
  showScanApp: boolean;
  showShippingDestEdit: boolean;
  showRangeEdit: boolean;
  showSalesRecord: boolean;
}
