import {
  Avatar,
  Box,
  Button,
  createMuiTheme,
  Grid,
  IconButton,
  TableCell,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  AlarmAdd as TempRegisterIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import { store } from '@redux/reducers';
import cliTruncate from 'cli-truncate';
import React from 'react';
import appLanguages from './app-languages';
import { nowrap, nowrapBody, nowrapHeader } from './common';

export const isEmpty = (v) => v === undefined || v === null || v === '';

export const disableDisplayOpts = { display: false, sort: false, filter: false };
export const disableSortOpts = { display: true, sort: false, filter: false };
export const enableSortOnlyOpts = { display: true, sort: true, filter: false };
export const enableSortAndFilterOpts = {
  sort: true,
  filter: true,
  filterType: 'custom',
  filterList: [],
};
export const enableEmptyOpts = { empty: true, sort: false, filter: false };

export const appMuiTheme = createMuiTheme({
  overrides: {
    /*
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: 'none',
      },
    },
    MUIDataTableBodyCell: {},
    MuiTableRow: {},
    */
  },
  spacing: (factor) => `${0.2 * factor}rem`,
});

export const getHeaderCell = (index, name, sortDirection, sortColumn, rowSpan = 1, colSpan = 1) => (
  <TableCell rowSpan={rowSpan} colSpan={colSpan} onClick={() => sortColumn(index)} key={index}>
    {sortDirection && sortDirection !== 'none' ? (
      <TableSortLabel active={sortDirection !== null} direction={sortDirection}>
        {nowrapHeader(name)}
      </TableSortLabel>
    ) : (
      <TableSortLabel active={false}>{nowrapHeader(name)}</TableSortLabel>
    )}
  </TableCell>
);

export const appMuiThemeForReference = createMuiTheme({
  overrides: {
    MuiToolbar: {
      regular: {
        height: '2px',
        minHeight: '2px',
        '@media (min-width: 600px)': { minHeight: '2px' },
      },
    },
  },
  spacing: (factor) => `${0.2 * factor}rem`,
});

export const muiDataTableCommonOptions = () => {
  const lang = store.getState().apps.currentLanguage;
  return {
    rowsPerPageOptions: [10, 50, 100, 200],
    serverSide: true,
    viewColumns: false,
    responsive: 'scrollMaxHeight', // stacked, scroll, scrollMaxHeight, scrollFullHeight, scrollFullHeightFullWidth
    selectableRows: 'none', // "multiple", "single", "none"
    // selectableRows: servers.isRequesting ? 'none' : 'single',
    selectableRowsOnClick: false,
    search: false,
    searchOpen: false,
    print: false,
    download: false,
    downloadOptions: { filename: 'test.csv', separator: ',' },
    filter: false,
    filterType: 'dropdown', // 'checkbox', 'dropdown', 'multiselect', 'textField'
    setTableProps: () => ({ size: 'small' }),
    textLabels: {
      body: {
        noMatch: (
          <Box mt={3} mb={3}>
            <Typography variant='body2' color='textSecondary'>
              {appLanguages.textLabelsBodyNoMatch[lang]}
            </Typography>
          </Box>
        ),
        toolTip: appLanguages.textLabelsBodyToolTip[lang],
      },
      pagination: {
        next: appLanguages.textLabelsPaginationNext[lang],
        previous: appLanguages.textLabelsPaginationPrevious[lang],
        rowsPerPage: appLanguages.textLabelsPaginationRowsPerPage[lang],
        displayRows: appLanguages.textLabelsPaginationDisplayRows[lang],
      },
      toolbar: {
        search: appLanguages.textLabelsToolbarSearch[lang],
        downloadCsv: appLanguages.textLabelsToolbarDownloadCsv[lang],
        filterTable: appLanguages.textLabelsToolbarFilterTable[lang],
        viewColumns: appLanguages.textLabelsToolbarViewColumns[lang],
      },
      filter: {
        all: appLanguages.textLabelsFilterAll[lang],
        title: appLanguages.textLabelsFilterTitle[lang],
        reset: appLanguages.textLabelsFilterReset[lang],
      },
    },
    onDownload: (buildHead, buildBody, columns, data) =>
      window.console.log(buildHead, buildBody, columns, data),
    onSearchChange: (searchText) => window.console.log(searchText),
    onSearchClose: () => null,
    onFilterChange: (changedColumn, filterList, type) =>
      window.console.log(changedColumn, filterList, type),
  };
};

export const rowDataToObj = (columns, rowData) => {
  const obj = {};
  if (!columns || !Object.keys(columns).length || !rowData || !Object.keys(rowData).length)
    return obj;
  for (let i = 0; i < columns.length; i += 1) {
    obj[columns[i].name] = rowData[i];
  }
  return obj;
};

export const getFilterOptionsLogic = (v, filters) =>
  v && Array.isArray(filters) && filters.length && !filters.includes(v);

export const getAutocompleteFilterOptionsDisplay = (
  filterList,
  onChange,
  index,
  column,
  optionMap,
) => {
  const value = filterList[index] && !isEmpty(filterList[index][0]) ? filterList[index][0] : '';
  return (
    <>
      <Autocomplete
        value={value}
        style={{ width: 200 }}
        options={Object.keys(optionMap)}
        getOptionLabel={(id) =>
          isEmpty(id) ? '' : Object.keys(optionMap).includes(id) ? optionMap[id] : ''
        }
        onChange={(e, v) => {
          filterList[index] = isEmpty(v) ? [] : [v];
          onChange(filterList[index], index, column);
        }}
        renderInput={(p) => (
          <TextField
            {...p}
            size='small'
            label={<Typography noWrap>{column.label}</Typography>}
            margin='normal'
          />
        )}
      />
    </>
  );
};

export const getSelectCustomFilterListRender = (label, optionMap, v) =>
  `${label}: ${Object.keys(optionMap).length && v in optionMap ? optionMap[v] : ''}`;

export const getSelectCustomBodyRender = (optionMap, v) =>
  Object.keys(optionMap).length && v in optionMap ? nowrap(optionMap[v]) : '';

export const getAvatarCircleCustomBodyRender = (v, className) => (
  <Avatar variant='circle' src={v} className={className} />
);

export const getAvatarRoundedCustomBodyRender = (v, className) => (
  <Avatar variant='rounded' src={v} className={className} />
);

export const getStringCustomBodyRender = (v) => nowrapBody(cliTruncate(String(v) || '', 18));

export const getObjectCustomBodyRender = (v) => nowrapBody(cliTruncate((v && v.name) || '', 18));

export const getIntegerCustomBodyRender = (v) => {
  const num = parseInt(v);
  return nowrapBody((num && num.toLocaleString()) || '');
};

export const getDateCustomBodyRender = (v) =>
  v && v.match(/:/) ? new Date(v).toLocaleDateString().replace('2020/', '2020/') : '';

export const getTimeAtCustomBodyRender = (v) =>
  v && v.match(/:/) ? new Date(v).toLocaleDateString().replace('2020/', '2020/') : '';

export const getDeleteActionsRender = (
  obj,
  delTitle,
  delDesc,
  isRequesting,
  onClickDelete,
  onConfirm,
) => {
  const lang = store.getState().apps.currentLanguage;
  const confirmOptions = {
    title: (
      <Grid container>
        <Grid item>
          <DeleteIcon fontSize='inherit' />
        </Grid>
        <Grid item>
          <Box ml={1} />
        </Grid>
        <Grid item>
          <Typography variant='subtitle1'>{delTitle}</Typography>
        </Grid>
      </Grid>
    ),
    description: delDesc,
    confirmationText: appLanguages.yes[lang],
    cancellationText: appLanguages.no[lang],
  };
  return (
    <>
      <Tooltip title={appLanguages.deleteThis[lang]}>
        <IconButton
          size='small'
          color='default'
          onClick={() =>
            isRequesting
              ? null
              : onConfirm(confirmOptions)
                  .then(() => onClickDelete(obj))
                  .catch(() => null)
          }
        >
          <DeleteIcon fontSize='small' />
        </IconButton>
      </Tooltip>
    </>
  );
};

export function getDetailsActionsRender(obj, isRequesting, onClickEdit) {
  const lang = store.getState().apps.currentLanguage;
  return (
    <>
      <Tooltip title={appLanguages.openDetails[lang]}>
        <Button
          size='small'
          color='primary'
          variant='outlined'
          onClick={() => (isRequesting ? null : onClickEdit(obj))}
        >
          <Typography variant='caption' noWrap>
            {appLanguages.details[lang]}
          </Typography>
        </Button>
      </Tooltip>
    </>
  );
}

export const getEditActionsRender = (
  obj,
  isRequesting,
  onClickEdit,
  buttonOptions = { icon: 'edit' },
) => {
  const displayToolTip =
    buttonOptions['toolTip'] || appLanguages.updateThis[store.getState().apps.currentLanguage];
  let displayIcon;
  switch (buttonOptions.icon) {
    case 'temp-registration':
      displayIcon = <TempRegisterIcon fontSize='small' />;
      break;
    default:
      displayIcon = <EditIcon fontSize='small' />;
      break;
  }

  return (
    <>
      <Tooltip title={displayToolTip}>
        <IconButton
          size='small'
          color='primary'
          onClick={() => (isRequesting ? null : onClickEdit(obj))}
        >
          {displayIcon}
        </IconButton>
      </Tooltip>
    </>
  );
};

export const getActionsCustomBodyRender = (
  obj,
  delTitle,
  delDesc,
  isRequesting,
  onClickEdit,
  onClickDelete,
  onConfirm,
  buttonOptions = {
    edit: { icon: 'edit' },
    delete: { icon: 'delete' },
  },
) => (
  <>
    <Grid container wrap='nowrap'>
      <Grid item>{getEditActionsRender(obj, isRequesting, onClickEdit, buttonOptions.edit)}</Grid>
      <Grid item>
        <Box ml={1} />
      </Grid>
      <Grid item>
        {getDeleteActionsRender(obj, delTitle, delDesc, isRequesting, onClickDelete, onConfirm)}
      </Grid>
    </Grid>
  </>
);

export const onMuiTableFilterChange = (
  columnName,
  filterList,
  type,
  columns,
  serverSideFilterList,
  where,
  updateEdtObj,
  setServerSideFilterList,
) => {
  const newFilterList = [];
  for (let i = 0; i < columns.length; i += 1) {
    const column = columns[i];
    if (type === 'reset') {
      column.options.filterList = [];
      newFilterList.push([]);
    } else if (column.name === columnName) {
      const changedValue = filterList[i][0];
      if (isEmpty(changedValue)) {
        delete where[columnName];
        column.options.filterList = [];
        updateEdtObj({ where });
      } else {
        column.options.filterList = [changedValue];
        updateEdtObj({ where: { ...where, [columnName]: parseInt(changedValue) } });
      }
      newFilterList.push(filterList[i]);
    } else if (serverSideFilterList.length) {
      newFilterList.push(serverSideFilterList[i]);
    } else {
      newFilterList.push([]);
    }
  }
  if (type === 'reset') updateEdtObj({ where: {} });
  setServerSideFilterList(newFilterList);
};
