import { i18n } from '@i18n/lang';
import { EAlert } from '@models/alert-type';
import { ListPages } from '@models/all-pages';
import { ECategoryType } from '@models/category-type';
import { IStateApps } from '@models/state-apps';
import { IStateServers } from '@models/state-servers';
import { TSupportedLanguages } from '@models/supported-languages';
import { getAlertMessage } from '@utils/get-alert-message';
import { isOk } from '@utils/is-ok';
import { Item } from '@visual/search-filter-popup';

/*
  C2_モニタリング
  =============
  
  アラート種別
  銘柄
  拠点
  伝票番号
  シリアル番号
*/

export function getFields(
  lang: TSupportedLanguages,
  apps: IStateApps,
  servers: IStateServers,
): Item[] {
  const { allLocations, allBrands } = servers;
  const { filterSelectedValues: selVal } = apps;
  const pageName: ListPages = 'monitoring';

  return [
    {
      id: 0,
      selectedValue: selVal && selVal[pageName] && selVal[pageName][0],
      fieldName: 'alertAt',
      type: 'date-range',
      placeholder: 'アラート期間',
    },
    {
      id: 1,
      fieldName: 'type',
      selectedValue: selVal && selVal[pageName] && selVal[pageName][1],
      type: 'select',
      placeholder: `${i18n.alertType[lang]}${i18n.inputSelect[lang]}`,
      selectData: Object.keys(EAlert)
        .map((data) => {
          const typeId = Number(data);
          if (isNaN(typeId)) return;
          return {
            id: typeId,
            text: getAlertMessage(typeId, lang),
          };
        })
        .filter((type) => type),
    },
    {
      id: 2,
      selectedValue: selVal && selVal[pageName] && selVal[pageName][2],
      fieldName: 'brandId',
      type: 'select',
      placeholder: `${i18n.brand[lang]}${i18n.inputSelect[lang]}`,
      selectData: isOk(allBrands)
        ? allBrands.map((brand) => ({ text: brand.name, id: brand.id }))
        : [],
    },
    {
      id: 3,
      selectedValue: selVal && selVal[pageName] && selVal[pageName][3],
      fieldName: 'brewLocId',
      type: 'select',
      placeholder: `${i18n.brewery[lang]}${i18n.inputSelect[lang]}`,
      selectData: isOk(allLocations)
        ? allLocations
            .filter((loc) => loc.category === ECategoryType.BREWERY)
            .map((location) => ({ text: location.name, id: location.id }))
        : [],
    },
    {
      id: 4,
      selectedValue: selVal && selVal[pageName] && selVal[pageName][4],
      fieldName: 'distLocId',
      type: 'select',
      placeholder: `${i18n.distributor[lang]}${i18n.inputSelect[lang]}`,
      selectData: isOk(allLocations)
        ? allLocations
            .filter((loc) => loc.category === ECategoryType.DISTRIBUTOR)
            .map((location) => ({ text: location.name, id: location.id }))
        : [],
    },
    {
      id: 5,
      selectedValue: selVal && selVal[pageName] && selVal[pageName][5],
      fieldName: 'restLocId',
      type: 'select',
      placeholder: `${i18n.restaurant[lang]}${i18n.inputSelect[lang]}`,
      selectData: isOk(allLocations)
        ? allLocations
            .filter((loc) => loc.category === ECategoryType.RESTAURANT)
            .map((location) => ({ text: location.name, id: location.id }))
        : [],
    },
    {
      id: 6,
      selectedValue: selVal && selVal[pageName] && selVal[pageName][6],
      fieldName: 'serialCode',
      type: 'text',
      placeholder: i18n.serialNo[lang],
    },
    {
      id: 7,
      selectedValue: selVal && selVal[pageName] && selVal[pageName][7],
      fieldName: 'slipOrDistSlip',
      type: 'text',
      placeholder: i18n.slipNo[lang],
    },
  ];
}
