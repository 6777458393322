export const countryInfo: Country[] = [
  {
    code: 'JP',
    codeA3: 'JPN',
    codeN3: '392',
    name: {
      full: '日本国',
      short: '日本',
    },
    enName: {
      short: 'Japan',
      full: null,
    },
    frName: 'Japon (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'CN',
    codeA3: 'CHN',
    codeN3: '156',
    name: {
      full: '中華人民共和国',
      short: '中華人民共和国 (中国)',
    },
    enName: {
      short: 'China',
      full: "the People's Republic of China",
    },
    frName: 'Chine (la)',
    independent: true,
    territory: null,
    remark: {
      part1: 'See also TAIWAN, PROVINCE OF CHINA.',
    },
    note: null,
  },
  {
    code: 'HK',
    codeA3: 'HKG',
    codeN3: '344',
    name: {
      full: 'ホンコン(香港)特別行政区',
      short: 'ホンコン(香港)',
    },
    enName: {
      short: 'Hong Kong',
      full: 'the Hong Kong Special Administrative Region of China',
    },
    frName: 'Hong Kong',
    independent: false,
    territory: null,
    remark: {
      part2:
        'No subdivisions relevant for this standard. Included also as subdivision of China (CN-HK).',
    },
    note: null,
  },
  {
    code: 'KR',
    codeA3: 'KOR',
    codeN3: '410',
    name: {
      full: '大韓民国',
      short: '大韓民国 (韓国)',
    },
    enName: {
      short: 'Korea (the Republic of)',
      full: 'the Republic of Korea',
    },
    frName: 'Corée (la République de)',
    independent: true,
    territory: null,
    remark: {
      part1: 'Often referred to as South Korea.',
    },
    note: null,
  },
  {
    code: 'AF',
    codeA3: 'AFG',
    codeN3: '004',
    name: {
      full: 'アフガニスタン・イスラム共和国',
      short: 'アフガニスタン',
    },
    enName: {
      short: 'Afghanistan',
      full: 'the Islamic Republic of Afghanistan',
    },
    frName: "Afghanistan (l')",
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'AL',
    codeA3: 'ALB',
    codeN3: '008',
    name: {
      full: 'アルバニア共和国',
      short: 'アルバニア',
    },
    enName: {
      short: 'Albania',
      full: 'the Republic of Albania',
    },
    frName: "Albanie (l')",
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'DZ',
    codeA3: 'DZA',
    codeN3: '012',
    name: {
      full: 'アルジェリア民主人民共和国',
      short: 'アルジェリア',
    },
    enName: {
      short: 'Algeria',
      full: "the People's Democratic Republic of Algeria",
    },
    frName: "Algérie (l')",
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'AS',
    codeA3: 'ASM',
    codeN3: '016',
    name: {
      full: '米領サモア',
      short: null,
    },
    enName: {
      short: 'American Samoa',
      full: null,
    },
    frName: 'Samoa américaines (les)',
    independent: false,
    territory: ["Swain's Island", 'Tutuila'],
    remark: {
      part1: "Principal island:Tutuila. Includes: Swain's Island.",
      part2:
        'No subdivisions relevant for this standard. Included also as a subdivision of the United States (US-AS).',
    },
    note: null,
  },
  {
    code: 'AD',
    codeA3: 'AND',
    codeN3: '020',
    name: {
      full: 'アンドラ公国',
      short: 'アンドラ',
    },
    enName: {
      short: 'Andorra',
      full: 'the Principality of Andorra',
    },
    frName: "Andorre (l')",
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'AO',
    codeA3: 'AGO',
    codeN3: '024',
    name: {
      full: 'アンゴラ共和国',
      short: 'アンゴラ',
    },
    enName: {
      short: 'Angola',
      full: 'the Republic of Angola',
    },
    frName: "Angola (l')",
    independent: true,
    territory: ['Cabinda'],
    remark: {
      part1: 'Includes: Cabinda.',
    },
    note: null,
  },
  {
    code: 'AI',
    codeA3: 'AIA',
    codeN3: '660',
    name: {
      full: 'アンギラ',
      short: null,
    },
    enName: {
      short: 'Anguilla',
      full: null,
    },
    frName: 'Anguilla',
    independent: false,
    territory: null,
    remark: {
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'AQ',
    codeA3: 'ATA',
    codeN3: '010',
    name: {
      full: '南極',
      short: null,
    },
    enName: {
      short: 'Antarctica',
      full: null,
    },
    frName: "Antarctique (l')",
    independent: false,
    territory: null,
    remark: {
      part1: 'Territories south of 60° south latitude.',
      part2: 'No subdivisions relevant for this standard.',
      part3:
        'French Southern and Antarctic Territories (FQ, ATF, --) are now part of Antarctica and French Southern Territories (TF, ATF, 260). See also code element FQHH. Dronning Maud Land (NQ, ATN, 216) is now part of Antarctica. See also code element NQAQ.',
    },
    note: null,
  },
  {
    code: 'AG',
    codeA3: 'ATG',
    codeN3: '028',
    name: {
      full: 'アンティグア・バーブーダ',
      short: 'アンティグア・バーブーダ',
    },
    enName: {
      short: 'Antigua and Barbuda',
      full: null,
    },
    frName: 'Antigua-et-Barbuda',
    independent: true,
    territory: ['Redonda Island'],
    remark: {
      part1: 'Includes: Redonda Island.',
    },
    note: null,
  },
  {
    code: 'AR',
    codeA3: 'ARG',
    codeN3: '032',
    name: {
      full: 'アルゼンチン共和国',
      short: 'アルゼンチン',
    },
    enName: {
      short: 'Argentina',
      full: 'the Argentine Republic',
    },
    frName: "Argentine (l')",
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'AM',
    codeA3: 'ARM',
    codeN3: '051',
    name: {
      full: 'アルメニア共和国',
      short: 'アルメニア',
    },
    enName: {
      short: 'Armenia',
      full: 'the Republic of Armenia',
    },
    frName: "Arménie (l')",
    independent: true,
    territory: null,
    remark: {
      part3: 'Formerly part of USSR (SU, SUN, 810) before its split. See code element SUHH.',
    },
    note: null,
  },
  {
    code: 'AW',
    codeA3: 'ABW',
    codeN3: '533',
    name: {
      full: 'アルバ',
      short: null,
    },
    enName: {
      short: 'Aruba',
      full: null,
    },
    frName: 'Aruba',
    independent: false,
    territory: null,
    remark: {
      part2:
        'No subdivisions relevant for this standard. Included also as subdivision of Netherlands (NL-AW).',
    },
    note: null,
  },
  {
    code: 'AU',
    codeA3: 'AUS',
    codeN3: '036',
    name: {
      full: 'オーストラリア連邦',
      short: 'オーストラリア',
    },
    enName: {
      short: 'Australia',
      full: null,
    },
    frName: "Australie (l')",
    independent: true,
    territory: [
      'Ashmore and Cartier Islands',
      'Coral Sea Islands',
      'Lord Howe Island',
      'Macquarie Island',
    ],
    remark: {
      part1:
        'Often referred to as the Commonwealth of Australia. Includes: Lord Howe Island, Macquarie Island; and also Ashmore and Cartier Islands, and Coral Sea Islands which are Australian external territories.',
    },
    note: null,
  },
  {
    code: 'AT',
    codeA3: 'AUT',
    codeN3: '040',
    name: {
      full: 'オーストリア共和国',
      short: 'オーストリア',
    },
    enName: {
      short: 'Austria',
      full: 'the Republic of Austria',
    },
    frName: "Autriche (l')",
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'AZ',
    codeA3: 'AZE',
    codeN3: '031',
    name: {
      full: 'アゼルバイジャン共和国',
      short: 'アゼルバイジャン',
    },
    enName: {
      short: 'Azerbaijan',
      full: 'the Republic of Azerbaijan',
    },
    frName: "Azerbaïdjan (l')",
    independent: true,
    territory: null,
    remark: {
      part3: 'Formerly part of USSR (SU, SUN, 810) before its split. See code element SUHH.',
    },
    note: null,
  },
  {
    code: 'BS',
    codeA3: 'BHS',
    codeN3: '044',
    name: {
      full: 'バハマ国',
      short: 'バハマ',
    },
    enName: {
      short: 'Bahamas (the)',
      full: 'the Commonwealth of the Bahamas',
    },
    frName: 'Bahamas (les)',
    independent: true,
    territory: null,
    remark: {
      part2:
        'The island of New Providence, where the capital Nassau is located, is administered directly by the national government.',
    },
    note: null,
  },
  {
    code: 'BH',
    codeA3: 'BHR',
    codeN3: '048',
    name: {
      full: 'バーレーン王国',
      short: 'バーレーン',
    },
    enName: {
      short: 'Bahrain',
      full: 'the Kingdom of Bahrain',
    },
    frName: 'Bahreïn',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'BD',
    codeA3: 'BGD',
    codeN3: '050',
    name: {
      full: 'バングラデシュ人民共和国',
      short: 'バングラデシュ',
    },
    enName: {
      short: 'Bangladesh',
      full: "the People's Republic of Bangladesh",
    },
    frName: 'Bangladesh (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'BB',
    codeA3: 'BRB',
    codeN3: '052',
    name: {
      full: 'バルバドス',
      short: 'バルバドス',
    },
    enName: {
      short: 'Barbados',
      full: null,
    },
    frName: 'Barbade (la)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'BY',
    codeA3: 'BLR',
    codeN3: '112',
    name: {
      full: 'べラルーシ共和国',
      short: 'ベラルーシ',
    },
    enName: {
      short: 'Belarus',
      full: 'the Republic of Belarus',
    },
    frName: 'Bélarus (le)',
    independent: true,
    territory: null,
    remark: {
      part3:
        'Name changed from Byelorussian SSR (BY, BYS, 112) to Belarus. Code element BYS removed from ISO 3166-1. See code element BYAA. Formerly part of USSR (SU, SUN, 810) before its split. See also code element SUHH.',
    },
    note: null,
  },
  {
    code: 'BE',
    codeA3: 'BEL',
    codeN3: '056',
    name: {
      full: 'ベルギー王国',
      short: 'ベルギー',
    },
    enName: {
      short: 'Belgium',
      full: 'the Kingdom of Belgium',
    },
    frName: 'Belgique (la)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'BZ',
    codeA3: 'BLZ',
    codeN3: '084',
    name: {
      full: 'べリーズ',
      short: 'べリーズ',
    },
    enName: {
      short: 'Belize',
      full: null,
    },
    frName: 'Belize (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'BJ',
    codeA3: 'BEN',
    codeN3: '204',
    name: {
      full: 'べナン共和国',
      short: 'ベナン',
    },
    enName: {
      short: 'Benin',
      full: 'the Republic of Benin',
    },
    frName: 'Bénin (le)',
    independent: true,
    territory: null,
    remark: {
      part3: 'Name changed from Dahomey (DY, DHY, 204) to Benin . See code element DYBJ.',
    },
    note: null,
  },
  {
    code: 'BM',
    codeA3: 'BMU',
    codeN3: '060',
    name: {
      full: 'バミューダ諸島',
      short: null,
    },
    enName: {
      short: 'Bermuda',
      full: null,
    },
    frName: 'Bermudes (les)',
    independent: false,
    territory: null,
    remark: {
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'AX',
    codeA3: 'ALA',
    codeN3: '248',
    name: {
      full: 'オーランド諸島',
      short: null,
    },
    enName: {
      short: 'Åland Islands',
      full: null,
    },
    frName: 'Åland(les Îles)',
    independent: false,
    territory: null,
    remark: {
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'BT',
    codeA3: 'BTN',
    codeN3: '064',
    name: {
      full: 'ブータン王国',
      short: 'ブータン',
    },
    enName: {
      short: 'Bhutan',
      full: 'the Kingdom of Bhutan',
    },
    frName: 'Bhoutan (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'BO',
    codeA3: 'BOL',
    codeN3: '068',
    name: {
      full: 'ボリビア多民族国',
      short: 'ボリビア',
    },
    enName: {
      short: 'Bolivia (Plurinational State of)',
      full: 'the Plurinational State of Bolivia',
    },
    frName: 'Bolivie (État plurinational de)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'BQ',
    codeA3: 'BES',
    codeN3: '535',
    name: {
      full: '',
      short: null,
    },
    enName: {
      short: 'Bonaire, Sint Eustatius and Saba',
      full: null,
    },
    frName: 'Bonaire, Saint-Eustache et Saba',
    independent: false,
    territory: ['Bonaire', 'Saba', 'Sint Eustatius'],
    remark: {
      part1: 'Often referred to as Caribbean Netherlands.',
      part2: 'Included as a subdivision of the Netherlands (NL-BQ).',
      part3:
        'The code BQ was formerly used for British Antarctic Territory (BQ, ATB, --). See also code element BQAQ. BQ has been reused and reassigned to Bonaire, Sint Eustatius and Saba. The Netherlands Antilles (AN, ANT, 530) was divided into Bonaire, Saint Eustatius and Saba (BQ, BES, 535), Curaçao (CW, CUW, 531) and Sint Maarten (Dutch part) (SX, SXM, 534). See also code element ANHH.',
    },
    note: null,
  },
  {
    code: 'BA',
    codeA3: 'BIH',
    codeN3: '070',
    name: {
      full: 'ボスニア・ヘルツェゴビナ',
      short: 'ボスニア・ヘルツェゴビナ',
    },
    enName: {
      short: 'Bosnia and Herzegovina',
      full: null,
    },
    frName: 'Bosnie-Herzégovine (la)',
    independent: true,
    territory: ['Herzegovina *'],
    remark: {
      part1: 'Herzegovina * is the second significant part of composite country name.',
      part3: 'Formerly part of Yugoslavia (YU, YUG, 891) before its split. See code element YUCS.',
    },
    note: null,
  },
  {
    code: 'BW',
    codeA3: 'BWA',
    codeN3: '072',
    name: {
      full: 'ボツワナ共和国',
      short: 'ボツワナ',
    },
    enName: {
      short: 'Botswana',
      full: 'the Republic of Botswana',
    },
    frName: 'Botswana (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'BV',
    codeA3: 'BVT',
    codeN3: '074',
    name: {
      full: 'ブーベ島',
      short: null,
    },
    enName: {
      short: 'Bouvet Island',
      full: null,
    },
    frName: "Bouvet (l'Île)",
    independent: false,
    territory: null,
    remark: {
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'BR',
    codeA3: 'BRA',
    codeN3: '076',
    name: {
      full: 'ブラジル連邦共和国',
      short: 'ブラジル',
    },
    enName: {
      short: 'Brazil',
      full: 'the Federative Republic of Brazi',
    },
    frName: 'Brésil (le)',
    independent: true,
    territory: ['Fernando de Noronha Island', 'Martim Vaz Islands', 'Trindade Island'],
    remark: {
      part1: 'Includes: Fernando de Noronha Island, Martim Vaz Islands, Trindade Island.',
    },
    note: null,
  },
  {
    code: 'IO',
    codeA3: 'IOT',
    codeN3: '086',
    name: {
      full: '英領インド洋地域',
      short: null,
    },
    enName: {
      short: 'British Indian Ocean Territory (the)',
      full: null,
    },
    frName: "Indien (le Territoire britannique de l'océan)",
    independent: false,
    territory: ['Chagos Archipelago', 'Diego Garcia'],
    remark: {
      part1:
        'Comprises: Chagos Archipelago (Principal island: Diego Garcia). No subdivision reported',
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'BN',
    codeA3: 'BRN',
    codeN3: '096',
    name: {
      full: 'ブルネイ・ダルサラーム国',
      short: 'ブルネイ',
    },
    enName: {
      short: 'Brunei Darussalam',
      full: null,
    },
    frName: 'Brunéi Darussalam (le)',
    independent: true,
    territory: null,
    remark: {
      part1: 'Variant: Negara Brunei Darussalam.',
    },
    note: null,
  },
  {
    code: 'BG',
    codeA3: 'BGR',
    codeN3: '100',
    name: {
      full: 'ブルガリア共和国',
      short: 'ブルガリア',
    },
    enName: {
      short: 'Bulgaria',
      full: 'the Republic of Bulgaria',
    },
    frName: 'Bulgarie (la)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'BF',
    codeA3: 'BFA',
    codeN3: '854',
    name: {
      full: 'ブルキナファソ',
      short: 'ブルキナファソ',
    },
    enName: {
      short: 'Burkina Faso',
      full: null,
    },
    frName: 'Burkina Faso (le)',
    independent: true,
    territory: null,
    remark: {
      part3:
        'Name changed from Upper Volta (HV, HVO, 854) to Burkina Faso. See also code element HVBF.',
    },
    note: null,
  },
  {
    code: 'BI',
    codeA3: 'BDI',
    codeN3: '108',
    name: {
      full: 'ブルンジ共和国',
      short: 'ブルンジ',
    },
    enName: {
      short: 'Burundi',
      full: 'the Republic of Burundi',
    },
    frName: 'Burundi (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'CV',
    codeA3: 'CPV',
    codeN3: '132',
    name: {
      full: 'カーボベルデ共和国',
      short: 'カーボヴェルデ',
    },
    enName: {
      short: 'Cabo Verde',
      full: 'the Republic of Cabo Verde',
    },
    frName: 'Cabo Verde',
    independent: true,
    territory: ['São Tiago', 'São Vicente'],
    remark: {
      part1: 'Principal islands: São Tiago, São Vicente.',
    },
    note: null,
  },
  {
    code: 'KH',
    codeA3: 'KHM',
    codeN3: '116',
    name: {
      full: 'カンボジア王国',
      short: 'カンボジア',
    },
    enName: {
      short: 'Cambodia',
      full: 'the Kingdom of Cambodia',
    },
    frName: 'Cambodge (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'CM',
    codeA3: 'CMR',
    codeN3: '120',
    name: {
      full: 'カメルーン共和国',
      short: 'カメルーン',
    },
    enName: {
      short: 'Cameroon',
      full: 'the Republic of Cameroon',
    },
    frName: 'Cameroun (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'CA',
    codeA3: 'CAN',
    codeN3: '124',
    name: {
      full: 'カナダ',
      short: 'カナダ',
    },
    enName: {
      short: 'Canada',
      full: null,
    },
    frName: 'Canada (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'KY',
    codeA3: 'CYM',
    codeN3: '136',
    name: {
      full: 'ケイマン諸島',
      short: null,
    },
    enName: {
      short: 'Cayman Islands (the)',
      full: null,
    },
    frName: 'Caïmans (les Îles)',
    independent: false,
    territory: ['Grand Cayman'],
    remark: {
      part1: 'Principal island: Grand Cayman.',
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'CF',
    codeA3: 'CAF',
    codeN3: '140',
    name: {
      full: '中央アフリカ共和国',
      short: '中央アフリカ',
    },
    enName: {
      short: 'Central African Republic (the)',
      full: 'the Central African Republic',
    },
    frName: 'République centrafricaine (la)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'TD',
    codeA3: 'TCD',
    codeN3: '148',
    name: {
      full: 'チャド共和国',
      short: 'チャド',
    },
    enName: {
      short: 'Chad',
      full: 'the Republic of Chad',
    },
    frName: 'Tchad (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'CL',
    codeA3: 'CHL',
    codeN3: '152',
    name: {
      full: 'チリ共和国',
      short: 'チリ',
    },
    enName: {
      short: 'Chile',
      full: 'the Republic of Chile',
    },
    frName: 'Chili (le)',
    independent: true,
    territory: [
      'Easter Island',
      'Juan Fernández Islands',
      'Sala y Gómez Island',
      'San Ambrosio Island',
      'San Félix Island',
    ],
    remark: {
      part1:
        'Includes: Easter Island, Juan Fernández Islands, Sala y Gómez Island, San Ambrosio Island, San Félix Island.',
    },
    note: null,
  },
  {
    code: 'CX',
    codeA3: 'CXR',
    codeN3: '162',
    name: {
      full: 'クリスマス島',
      short: null,
    },
    enName: {
      short: 'Christmas Island',
      full: null,
    },
    frName: "Christmas (l'Île)",
    independent: false,
    territory: null,
    remark: {
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'CC',
    codeA3: 'CCK',
    codeN3: '166',
    name: {
      full: 'ココス諸島',
      short: null,
    },
    enName: {
      short: 'Cocos (Keeling) Islands (the)',
      full: null,
    },
    frName: 'Cocos (les Îles)/ Keeling (les Îles)',
    independent: false,
    territory: null,
    remark: {
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'CO',
    codeA3: 'COL',
    codeN3: '170',
    name: {
      full: 'コロンビア共和国',
      short: 'コロンビア',
    },
    enName: {
      short: 'Colombia',
      full: 'the Republic of Colombia',
    },
    frName: 'Colombie (la)',
    independent: true,
    territory: ['Malpelo Island', 'San Andrés y Providencia Islands'],
    remark: {
      part1: 'Includes: Malpelo Island, San Andrés y Providencia Islands.',
    },
    note: null,
  },
  {
    code: 'KM',
    codeA3: 'COM',
    codeN3: '174',
    name: {
      full: 'コモロ連合',
      short: 'コモロ',
    },
    enName: {
      short: 'Comoros (the)',
      full: 'the Union of the Comoros',
    },
    frName: 'Comores (les)',
    independent: true,
    territory: ['Anjouan', 'Grande Comore', 'Mohéli'],
    remark: {
      part1:
        'Comprises: Anjouan, Grande Comore, Mohéli. The language code 002 refers to the administrative language Shikomor that has not been assigned an ISO 639 language code.',
      part2:
        'The language code002 refers to the administrative language Shikomor that has not been assigned an ISO 639 language code.',
    },
    note: null,
  },
  {
    code: 'CD',
    codeA3: 'COD',
    codeN3: '180',
    name: {
      full: 'コンゴ民主共和国',
      short: 'コンゴ民主共和国',
    },
    enName: {
      short: 'Congo (the Democratic Republic of the)',
      full: 'the Democratic Republic of the Congo',
    },
    frName: 'Congo (la République démocratique du)',
    independent: true,
    territory: null,
    remark: {
      part1: 'Previous entry: ZAIRE. ',
      part2: 'Remark: Ex Zaire/Zaïre, change of name 1997-05-17',
      part3:
        'Name changed from Zaire (ZR, ZAR, 180) to the Democratic Republic of the Congo. See also code element ZRCD.',
    },
    note: null,
  },
  {
    code: 'CG',
    codeA3: 'COG',
    codeN3: '178',
    name: {
      full: 'コンゴ共和国',
      short: 'コンゴ共和国',
    },
    enName: {
      short: 'Congo (the)',
      full: 'the Republic of the Congo',
    },
    frName: 'Congo (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'CK',
    codeA3: 'COK',
    codeN3: '184',
    name: {
      full: 'クック諸島',
      short: 'クック',
    },
    enName: {
      short: 'Cook Islands (the)',
      full: null,
    },
    frName: 'Cook (les Îles)',
    independent: false,
    territory: ['Rarotonga'],
    remark: {
      part1: 'Principal island: Rarotonga.',
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'CR',
    codeA3: 'CRI',
    codeN3: '188',
    name: {
      full: 'コスタリカ共和国',
      short: 'コスタリカ',
    },
    enName: {
      short: 'Costa Rica',
      full: 'the Republic of Costa Rica',
    },
    frName: 'Costa Rica (le)',
    independent: true,
    territory: ['Coco Island'],
    remark: {
      part1: 'Includes: Coco Island.',
    },
    note: null,
  },
  {
    code: 'HR',
    codeA3: 'HRV',
    codeN3: '191',
    name: {
      full: 'クロアチア共和国',
      short: 'クロアチア',
    },
    enName: {
      short: 'Croatia',
      full: 'the Republic of Croatia',
    },
    frName: 'Croatie (la)',
    independent: true,
    territory: null,
    remark: {
      part3: 'Formerly part of Yougoslavia (YU, YUG, 891) before its split. See code element YUCS.',
    },
    note: null,
  },
  {
    code: 'CU',
    codeA3: 'CUB',
    codeN3: '192',
    name: {
      full: 'キューバ共和国',
      short: 'キューバ',
    },
    enName: {
      short: 'Cuba',
      full: 'the Republic of Cuba',
    },
    frName: 'Cuba',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'CW',
    codeA3: 'CUW',
    codeN3: '531',
    name: {
      full: '',
      short: null,
    },
    enName: {
      short: 'Curaçao',
      full: null,
    },
    frName: 'Curaçao',
    independent: false,
    territory: null,
    remark: {
      part2:
        'No subdivisions relevant for this standard. Included also as subdivision of Netherlands (NL-CW).',
      part3:
        'The Netherlands Antilles (AN, ANT, 530) was divided into Bonaire, Saint Eustatius and Saba (BQ, BES, 535), Curaçao (CW, CUW, 531) and Sint Maarten (Dutch part) (SX, SXM, 534). See also code element ANHH.',
    },
    note: null,
  },
  {
    code: 'CY',
    codeA3: 'CYP',
    codeN3: '196',
    name: {
      full: 'キプロス共和国',
      short: 'キプロス',
    },
    enName: {
      short: 'Cyprus',
      full: 'the Republic of Cyprus',
    },
    frName: 'Chypre',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'CZ',
    codeA3: 'CZE',
    codeN3: '203',
    name: {
      full: 'チェコ共和国',
      short: 'チェコ',
    },
    enName: {
      short: 'Czechia',
      full: 'the Czech Republic',
    },
    frName: 'Tchéquie (la)',
    independent: true,
    territory: null,
    remark: {
      part3:
        'Czechoslovakia (CS, CSK, 200) was divided into Czechia (CZ, CZE, 203), and Slovakia (SK, SVK, 703). See also code element CSHH.',
    },
    note: null,
  },
  {
    code: 'CI',
    codeA3: 'CIV',
    codeN3: '384',
    name: {
      full: 'コートジボワール共和国',
      short: 'コートジボワール',
    },
    enName: {
      short: "Côte d'Ivoire",
      full: "the Republic of Côte d'Ivoire",
    },
    frName: "Côte d'Ivoire (la)",
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'DK',
    codeA3: 'DNK',
    codeN3: '208',
    name: {
      full: 'デンマーク王国',
      short: 'デンマーク',
    },
    enName: {
      short: 'Denmark',
      full: 'the Kingdom of Denmark',
    },
    frName: 'Danemark (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'DJ',
    codeA3: 'DJI',
    codeN3: '262',
    name: {
      full: 'ジブチ共和国',
      short: 'ジブチ',
    },
    enName: {
      short: 'Djibouti',
      full: 'the Republic of Djibouti',
    },
    frName: 'Djibouti',
    independent: true,
    territory: null,
    remark: {
      part3:
        'Name changed from French Afars and Issas (AI, AFI, --) to Djibouti. See also code element AIDJ.',
    },
    note: null,
  },
  {
    code: 'DM',
    codeA3: 'DMA',
    codeN3: '212',
    name: {
      full: 'ドミニカ国',
      short: 'ドミニカ',
    },
    enName: {
      short: 'Dominica',
      full: 'the Commonwealth of Dominica',
    },
    frName: 'Dominique (la)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'DO',
    codeA3: 'DOM',
    codeN3: '214',
    name: {
      full: 'ドミニカ共和国',
      short: 'ドミニカ共和国',
    },
    enName: {
      short: 'Dominican Republic (the)',
      full: 'the Dominican Republic',
    },
    frName: 'dominicaine (la République)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'EC',
    codeA3: 'ECU',
    codeN3: '218',
    name: {
      full: 'エクアドル共和国',
      short: 'エクアドル',
    },
    enName: {
      short: 'Ecuador',
      full: 'the Republic of Ecuador',
    },
    frName: "Équateur (l')",
    independent: true,
    territory: ['Galápagos Islands'],
    remark: {
      part1: 'Includes: Galápagos Islands.',
    },
    note: null,
  },
  {
    code: 'EG',
    codeA3: 'EGY',
    codeN3: '818',
    name: {
      full: 'エジプト・アラブ共和国',
      short: 'エジプト',
    },
    enName: {
      short: 'Egypt',
      full: 'the Arab Republic of Egypt',
    },
    frName: "Égypte (l')",
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'SV',
    codeA3: 'SLV',
    codeN3: '222',
    name: {
      full: 'エルサルバドル共和国',
      short: 'エルサルバドル',
    },
    enName: {
      short: 'El Salvador',
      full: 'the Republic of El Salvador',
    },
    frName: 'El Salvador',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'GQ',
    codeA3: 'GNQ',
    codeN3: '226',
    name: {
      full: '赤道ギニア共和国',
      short: '赤道ギニア',
    },
    enName: {
      short: 'Equatorial Guinea',
      full: 'the Republic of Equatorial Guinea',
    },
    frName: 'Guinée équatoriale (la)',
    independent: true,
    territory: ['Annobón Island', 'Bioko Island', 'Continental Region (Rio Muni)', 'Rio Muni'],
    remark: {
      part1: 'Comprises: Annobón Island, Bioko Island, the Continental Region (Rio Muni).',
    },
    note: null,
  },
  {
    code: 'ER',
    codeA3: 'ERI',
    codeN3: '232',
    name: {
      full: 'エリトリア国',
      short: 'エリトリア',
    },
    enName: {
      short: 'Eritrea',
      full: 'the State of Eritrea',
    },
    frName: "Érythrée (l')",
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'EE',
    codeA3: 'EST',
    codeN3: '233',
    name: {
      full: 'エストニア共和国',
      short: 'エストニア',
    },
    enName: {
      short: 'Estonia',
      full: 'the Republic of Estonia',
    },
    frName: "Estonie (l')",
    independent: true,
    territory: null,
    remark: {
      part3: 'Formerly part of USSR (SU, SUN, 810) before its split. See code element SUHH.',
    },
    note: null,
  },
  {
    code: 'SZ',
    codeA3: 'SWZ',
    codeN3: '748',
    name: {
      full: 'エスワニティ王国',
      short: 'エスワティニ',
    },
    enName: {
      short: 'Eswatini',
      full: 'the Kingdom of Eswatini',
    },
    frName: "Eswatini (l')",
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'ET',
    codeA3: 'ETH',
    codeN3: '231',
    name: {
      full: 'エチオピア連邦民主共和国',
      short: 'エチオピア',
    },
    enName: {
      short: 'Ethiopia',
      full: 'the Federal Democratic Republic of Ethiopia',
    },
    frName: "Éthiopie (l')",
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'FK',
    codeA3: 'FLK',
    codeN3: '238',
    name: {
      full: 'フォークランド(マルビナス)諸島',
      short: null,
    },
    enName: {
      short: 'Falkland Islands (the) [Malvinas]',
      full: null,
    },
    frName: 'Falkland (les Îles)/Malouines (les Îles)',
    independent: false,
    territory: null,
    remark: {
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'FO',
    codeA3: 'FRO',
    codeN3: '234',
    name: {
      full: 'フェロー諸島',
      short: null,
    },
    enName: {
      short: 'Faroe Islands (the)',
      full: null,
    },
    frName: 'Féroé (les Îles)',
    independent: false,
    territory: null,
    remark: {
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'FJ',
    codeA3: 'FJI',
    codeN3: '242',
    name: {
      full: 'フィジー諸島共和国',
      short: 'フィジー',
    },
    enName: {
      short: 'Fiji',
      full: 'the Republic of Fiji',
    },
    frName: 'Fidji (les)',
    independent: true,
    territory: ['Rotuma Island', 'Vanua Levu', 'Viti Levu'],
    remark: {
      part1: 'Principal islands: Vanua Levu, Viti Levu. Includes Rotuma Island.',
    },
    note: null,
  },
  {
    code: 'FI',
    codeA3: 'FIN',
    codeN3: '246',
    name: {
      full: 'フィンランド共和国',
      short: 'フィンランド',
    },
    enName: {
      short: 'Finland',
      full: 'the Republic of Finland',
    },
    frName: 'Finlande (la)',
    independent: true,
    territory: ['Åland Islands'],
    remark: {
      part1: 'Includes: Åland Islands.',
    },
    note: null,
  },
  {
    code: 'FR',
    codeA3: 'FRA',
    codeN3: '250',
    name: {
      full: 'フランス共和国',
      short: 'フランス',
    },
    enName: {
      short: 'France',
      full: 'the French Republic',
    },
    frName: 'France (la)',
    independent: true,
    territory: null,
    remark: {
      part1:
        'Comprises: Metropolitan France, French Guiana, Guadeloupe, Martinique, La Réunion, Mayotte, Saint Barthélemy, Saint Martin, Saint Pierre and Miquelon, French Polynesia, French Southern Territories, New Caledonia, Wallis and Futuna. Includes: Clipperton Island.',
      part3:
        'Metropolitan France (FX, FXX, 249) is now incorporated into the entry for France (FR, FRA, 250). See also code element FXFR.',
    },
    note: null,
  },
  {
    code: 'GF',
    codeA3: 'GUF',
    codeN3: '254',
    name: {
      full: 'フランス領ギアナ',
      short: null,
    },
    enName: {
      short: 'French Guiana',
      full: null,
    },
    frName: 'Guyane française (la )',
    independent: false,
    territory: null,
    remark: {
      part2:
        'No subdivisions relevant for this standard. Included also as subdivision of France (FR-GF).',
    },
    note: null,
  },
  {
    code: 'PF',
    codeA3: 'PYF',
    codeN3: '258',
    name: {
      full: 'フランス領ポリネシア',
      short: null,
    },
    enName: {
      short: 'French Polynesia',
      full: null,
    },
    frName: 'Polynésie française (la)',
    independent: false,
    territory: [
      'Austral Islands',
      'Gambier Islands',
      'Marquesas Islands',
      'Society Archipelago',
      'Tahiti',
      'Tuamotu Islands',
    ],
    remark: {
      part1:
        'Comprises: Austral Islands, Gambier Islands, Marquesas Islands, Society Archipelago (Principal island: Tahiti), Tuamotu Islands.',
      part2:
        'No subdivisions relevant for this standard. Included also as a subdivision of France (FR-PF).',
    },
    note: null,
  },
  {
    code: 'TF',
    codeA3: 'ATF',
    codeN3: '260',
    name: {
      full: 'フランス領極南諸島',
      short: null,
    },
    enName: {
      short: 'French Southern Territories (the)',
      full: null,
    },
    frName: 'Terres australes françaises (les)',
    independent: false,
    territory: [
      'Bassas da India',
      'Crozet Archipelago',
      'French scattered Indian Ocean Islands',
      'Europa Island',
      'Glorioso Islands',
      'Juan de Nova Island',
      'Kerguelen Islands',
      'Saint-Paul Island',
      'Tromelin Island',
      'Amsterdam Island',
    ],
    remark: {
      part1:
        'Comprises: Amsterdam Island, Crozet Archipelago, Kerguelen Islands, Saint Paul Island and French scattered Indian Ocean Islands formed by Bassas da India, Europa Island, Glorioso Islands, Juan de Nova Island and Tromelin Island.',
      part2:
        'No subdivisions relevant for this standard. Included also as subdivision of France (FR-TF).',
      part3:
        'French Southern and Antarctic Territories (FQ, ATF, --) now part of Antarctica (AQ, ATA, 010) and French Southern Territories. See also code element FQHH.',
    },
    note: null,
  },
  {
    code: 'GA',
    codeA3: 'GAB',
    codeN3: '266',
    name: {
      full: 'ガボン共和国',
      short: 'ガボン',
    },
    enName: {
      short: 'Gabon',
      full: 'the Gabonese Republic',
    },
    frName: 'Gabon (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'GM',
    codeA3: 'GMB',
    codeN3: '270',
    name: {
      full: 'ガンビア共和国',
      short: 'ガンビア',
    },
    enName: {
      short: 'Gambia (the)',
      full: 'the Republic of the Gambia',
    },
    frName: 'Gambie (la)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'GE',
    codeA3: 'GEO',
    codeN3: '268',
    name: {
      full: 'ジョージア',
      short: 'ジョージア',
    },
    enName: {
      short: 'Georgia',
      full: null,
    },
    frName: 'Géorgie (la)',
    independent: true,
    territory: null,
    remark: {
      part1:
        'The code GE was formerly used for the Gilbert and Ellice Islands (GE, GEL, --) and has been reused and reassigned to Georgia. See also code element GEKI.',
      part3: 'Formerly part of USSR (SU, SUN, 810) before its split. See code element SUHH.',
    },
    note: null,
  },
  {
    code: 'DE',
    codeA3: 'DEU',
    codeN3: '276',
    name: {
      full: 'ドイツ連邦共和国',
      short: 'ドイツ',
    },
    enName: {
      short: 'Germany',
      full: 'the Federal Republic of Germany',
    },
    frName: "Allemagne (l')",
    independent: true,
    territory: null,
    remark: {
      part3:
        'Since reunification, the code elements for the former German Democratic Republic (DD, DDR, 278) have been incorporated into the code elements for Germany. See also code element DDDE.',
    },
    note: null,
  },
  {
    code: 'GH',
    codeA3: 'GHA',
    codeN3: '288',
    name: {
      full: 'ガーナ共和国',
      short: 'ガーナ',
    },
    enName: {
      short: 'Ghana',
      full: 'the Republic of Ghana',
    },
    frName: 'Ghana (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'GI',
    codeA3: 'GIB',
    codeN3: '292',
    name: {
      full: 'ジブラルタル',
      short: null,
    },
    enName: {
      short: 'Gibraltar',
      full: null,
    },
    frName: 'Gibraltar',
    independent: false,
    territory: null,
    remark: {
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'GR',
    codeA3: 'GRC',
    codeN3: '300',
    name: {
      full: 'ギリシャ共和国',
      short: 'ギリシャ',
    },
    enName: {
      short: 'Greece',
      full: 'the Hellenic Republic',
    },
    frName: 'Grèce (la)',
    independent: true,
    territory: ['Mount Athos autonomous area'],
    remark: {
      part1: 'Includes: Mount Athos autonomous area.',
    },
    note: null,
  },
  {
    code: 'GL',
    codeA3: 'GRL',
    codeN3: '304',
    name: {
      full: 'グリーンランド',
      short: null,
    },
    enName: {
      short: 'Greenland',
      full: null,
    },
    frName: 'Groenland (le)',
    independent: false,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'GD',
    codeA3: 'GRD',
    codeN3: '308',
    name: {
      full: 'グレナダ',
      short: 'グレナダ',
    },
    enName: {
      short: 'Grenada',
      full: null,
    },
    frName: 'Grenade (la)',
    independent: true,
    territory: ['Carriacou', 'Southern Grenadine Islands'],
    remark: {
      part1: 'Includes: Southern Grenadine Islands (Principal island: Carriacou).',
    },
    note: null,
  },
  {
    code: 'GP',
    codeA3: 'GLP',
    codeN3: '312',
    name: {
      full: 'グアドループ島',
      short: null,
    },
    enName: {
      short: 'Guadeloupe',
      full: null,
    },
    frName: 'Guadeloupe (la)',
    independent: false,
    territory: ['la Désirade', 'Marie-Galante', 'les Saintes'],
    remark: {
      part1: 'Includes: la Désirade, Marie-Galante, les Saintes.',
      part2:
        'No subdivisions relevant for this standard. Included also as subdivision of France (FR-GP).',
    },
    note: null,
  },
  {
    code: 'GU',
    codeA3: 'GUM',
    codeN3: '316',
    name: {
      full: 'グアム',
      short: null,
    },
    enName: {
      short: 'Guam',
      full: null,
    },
    frName: 'Guam',
    independent: false,
    territory: null,
    remark: {
      part2:
        'No subdivisions relevant for this standard. Included also as subdivision of the United States (US-GU).',
    },
    note: null,
  },
  {
    code: 'GT',
    codeA3: 'GTM',
    codeN3: '320',
    name: {
      full: 'グアテマラ共和国',
      short: 'グアテマラ',
    },
    enName: {
      short: 'Guatemala',
      full: 'the Republic of Guatemala',
    },
    frName: 'Guatemala (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'GG',
    codeA3: 'GGY',
    codeN3: '831',
    name: {
      full: 'ガーンジー島',
      short: null,
    },
    enName: {
      short: 'Guernsey',
      full: null,
    },
    frName: 'Guernesey',
    independent: false,
    territory: [
      'Alderney Island',
      'Brecqhou Island',
      'Burhou Island',
      'Sark Island',
      'Herm Island',
      'Jethou Island',
      'Lihou Island',
      'Little Sark Island',
    ],
    remark: {
      part1:
        'The Bailiwick of Guernsey also includes the islands of Alderney, Brecqhou, Burhou, Herm, Jethou, Lihou, Little Sark, and Sark.',
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'GN',
    codeA3: 'GIN',
    codeN3: '324',
    name: {
      full: 'ギニア共和国',
      short: 'ギニア',
    },
    enName: {
      short: 'Guinea',
      full: 'the Republic of Guinea',
    },
    frName: 'Guinée (la)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'GW',
    codeA3: 'GNB',
    codeN3: '624',
    name: {
      full: 'ギニアビサウ共和国',
      short: 'ギニアビサウ',
    },
    enName: {
      short: 'Guinea-Bissau',
      full: 'the Republic of Guinea-Bissau',
    },
    frName: 'Guinée-Bissau (la)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'GY',
    codeA3: 'GUY',
    codeN3: '328',
    name: {
      full: 'ガイアナ共和国',
      short: 'ガイアナ',
    },
    enName: {
      short: 'Guyana',
      full: 'the Co-operative Republic of Guyana',
    },
    frName: 'Guyana (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'HT',
    codeA3: 'HTI',
    codeN3: '332',
    name: {
      full: 'ハイチ共和国',
      short: 'ハイチ',
    },
    enName: {
      short: 'Haiti',
      full: 'the Republic of Haiti',
    },
    frName: 'Haïti',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'HM',
    codeA3: 'HMD',
    codeN3: '334',
    name: {
      full: 'ハード島・マクドナルド諸島',
      short: null,
    },
    enName: {
      short: 'Heard Island and McDonald Islands',
      full: null,
    },
    frName: "Heard-et-Îles MacDonald (l'Île)",
    independent: false,
    territory: ['McDonald Islands *'],
    remark: {
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'VA',
    codeA3: 'VAT',
    codeN3: '336',
    name: {
      full: 'バチカン市国',
      short: 'バチカン',
    },
    enName: {
      short: 'Holy See (the)',
      full: null,
    },
    frName: 'Saint-Siège (le)',
    independent: true,
    territory: null,
    remark: {
      part1: 'Previous entry: VATICAN CITY STATE (HOLY SEE).',
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'HN',
    codeA3: 'HND',
    codeN3: '340',
    name: {
      full: 'ホンジュラス共和国',
      short: 'ホンジュラス',
    },
    enName: {
      short: 'Honduras',
      full: 'the Republic of Honduras',
    },
    frName: 'Honduras (le)',
    independent: true,
    territory: ['Swan Islands'],
    remark: {
      part1: 'Includes: Swan Islands.',
    },
    note: null,
  },
  {
    code: 'HU',
    codeA3: 'HUN',
    codeN3: '348',
    name: {
      full: 'ハンガリー共和国',
      short: 'ハンガリー',
    },
    enName: {
      short: 'Hungary',
      full: null,
    },
    frName: 'Hongrie (la)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'IS',
    codeA3: 'ISL',
    codeN3: '352',
    name: {
      full: 'アイスランド共和国',
      short: 'アイスランド',
    },
    enName: {
      short: 'Iceland',
      full: 'the Republic of Iceland',
    },
    frName: "Islande (l')",
    independent: true,
    territory: null,
    remark: {
      part2:
        'Remark: The Icelandic characters ð (eze) and þ (thorn) may be written as “dh” and “th”.',
    },
    note: null,
  },
  {
    code: 'IN',
    codeA3: 'IND',
    codeN3: '356',
    name: {
      full: 'インド',
      short: 'インド',
    },
    enName: {
      short: 'India',
      full: 'the Republic of India',
    },
    frName: "Inde (l')",
    independent: true,
    territory: [
      'Andaman Islands',
      'Laccadive Islands',
      'Minicoy Island',
      'Nicobar Islands',
      'Amindivi Islands',
    ],
    remark: {
      part1:
        'Includes: Amindivi Islands, Andaman Islands, Laccadive Islands, Minicoy Island, Nicobar Islands.',
      part2: 'Remark: the forms used in the list are English-language forms provided by India.',
      part3: 'Sikkim (SK, SKM, --) is now part of the entry for India.',
    },
    note: null,
  },
  {
    code: 'ID',
    codeA3: 'IDN',
    codeN3: '360',
    name: {
      full: 'インドネシア共和国',
      short: 'インドネシア',
    },
    enName: {
      short: 'Indonesia',
      full: 'the Republic of Indonesia',
    },
    frName: "Indonésie (l')",
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'IR',
    codeA3: 'IRN',
    codeN3: '364',
    name: {
      full: 'イラン・イスラム共和国',
      short: 'イラン',
    },
    enName: {
      short: 'Iran (Islamic Republic of)',
      full: 'the Islamic Republic of Iran',
    },
    frName: "Iran (République Islamique d')",
    independent: true,
    territory: null,
    remark: {
      part1: 'Also referred to as Iran.',
    },
    note: null,
  },
  {
    code: 'IQ',
    codeA3: 'IRQ',
    codeN3: '368',
    name: {
      full: 'イラク共和国',
      short: 'イラク',
    },
    enName: {
      short: 'Iraq',
      full: 'the Republic of Iraq',
    },
    frName: "Iraq (l')",
    independent: true,
    territory: null,
    remark: {
      part2:
        'Kurdish and Arabic are both official languages according to the Iraqi constitution, which states “The federal and official institutions and agencies in the Kurdistan region shall use both languages.” ',
      part3:
        'The Neutral Zone (NT, NTZ, 536) was divided and is now part of Iraq (IQ, IRQ, 368) and part of Saudi Arabia (SA, SAU, 682). The code entry (NT, NTZ, 536) was deleted from ISO 3166-1. See also code elelement NTHH.',
    },
    note: null,
  },
  {
    code: 'IE',
    codeA3: 'IRL',
    codeN3: '372',
    name: {
      full: 'アイルランド',
      short: 'アイルランド',
    },
    enName: {
      short: 'Ireland',
      full: null,
    },
    frName: "Irlande (l')",
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'IM',
    codeA3: 'IMN',
    codeN3: '833',
    name: {
      full: 'マン島',
      short: null,
    },
    enName: {
      short: 'Isle of Man',
      full: null,
    },
    frName: 'Île de Man',
    independent: false,
    territory: null,
    remark: {
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'IL',
    codeA3: 'ISR',
    codeN3: '376',
    name: {
      full: 'イスラエル国',
      short: 'イスラエル',
    },
    enName: {
      short: 'Israel',
      full: 'the State of Israel',
    },
    frName: 'Israël',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'IT',
    codeA3: 'ITA',
    codeN3: '380',
    name: {
      full: 'イタリア共和国',
      short: 'イタリア',
    },
    enName: {
      short: 'Italy',
      full: 'the Republic of Italy',
    },
    frName: "Italie (l')",
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'JM',
    codeA3: 'JAM',
    codeN3: '388',
    name: {
      full: 'ジャマイカ',
      short: 'ジャマイカ',
    },
    enName: {
      short: 'Jamaica',
      full: null,
    },
    frName: 'Jamaïque (la)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'JE',
    codeA3: 'JEY',
    codeN3: '832',
    name: {
      full: 'ジャージー島',
      short: null,
    },
    enName: {
      short: 'Jersey',
      full: null,
    },
    frName: 'Jersey',
    independent: false,
    territory: null,
    remark: {
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'JO',
    codeA3: 'JOR',
    codeN3: '400',
    name: {
      full: 'ヨルダン・ハシミテ王国',
      short: 'ヨルダン',
    },
    enName: {
      short: 'Jordan',
      full: 'the Hashemite Kingdom of Jordan',
    },
    frName: 'Jordanie (la)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'KZ',
    codeA3: 'KAZ',
    codeN3: '398',
    name: {
      full: 'カザフスタン共和国',
      short: 'カザフスタン',
    },
    enName: {
      short: 'Kazakhstan',
      full: 'the Republic of Kazakhstan',
    },
    frName: 'Kazakhstan (le)',
    independent: true,
    territory: null,
    remark: {
      part3: 'Formerly part of USSR (SU, SUN, 810) before its split. See code element SUHH.',
    },
    note: null,
  },
  {
    code: 'KE',
    codeA3: 'KEN',
    codeN3: '404',
    name: {
      full: 'ケニア共和国',
      short: 'ケニア',
    },
    enName: {
      short: 'Kenya',
      full: 'the Republic of Kenya',
    },
    frName: 'Kenya (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'KI',
    codeA3: 'KIR',
    codeN3: '296',
    name: {
      full: 'キリバス共和国',
      short: 'キリバス',
    },
    enName: {
      short: 'Kiribati',
      full: 'the Republic of Kiribati',
    },
    frName: 'Kiribati',
    independent: true,
    territory: [
      'Abariringa',
      'Banaba',
      'Enderbury Island',
      'Gilbert Islands',
      'Kiritimati',
      'part of Line Islands',
      'Phoenix Islands',
      'Tarawa',
    ],
    remark: {
      part1:
        'Comprises: Gilbert Islands (Principal atoll: Tarawa, including Banaba), part of Line Islands (including Kiritimati), Phoenix Islands (including Abariringa, Enderbury Island).',
      part3:
        'Canton and Enderbury Islands (CT, CTE, 128) now part of Kirbati. See also code element CTKI. Gilbert and Ellice Islands (GE, GEL, --) divided into: Gilbert Islands now part of Kiribati, and Ellice Islands now part of Tuvalu (TV, TUV, 798). See also code element GEHH.',
    },
    note: null,
  },
  {
    code: 'KP',
    codeA3: 'PRK',
    codeN3: '408',
    name: {
      full: '北朝鮮=朝鮮民主主義人民共和国',
      short: null,
    },
    enName: {
      short: "Korea (the Democratic People's Republic of)",
      full: "the Democratic People's Republic of Korea",
    },
    frName: 'Corée (la République populaire démocratique de)',
    independent: true,
    territory: null,
    remark: {
      part1: 'Often referred to as North Korea.',
    },
    note: null,
  },
  {
    code: 'KW',
    codeA3: 'KWT',
    codeN3: '414',
    name: {
      full: 'クウェート国',
      short: 'クウェート',
    },
    enName: {
      short: 'Kuwait',
      full: 'the State of Kuwait',
    },
    frName: 'Koweït (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'KG',
    codeA3: 'KGZ',
    codeN3: '417',
    name: {
      full: 'キルギス共和国',
      short: 'キルギス',
    },
    enName: {
      short: 'Kyrgyzstan',
      full: 'the Kyrgyz Republic',
    },
    frName: 'Kirghizistan (le)',
    independent: true,
    territory: null,
    remark: {
      part3: 'Formerly part of USSR (SU, SUN, 810) before its split. See code element SUHH.',
    },
    note: null,
  },
  {
    code: 'LA',
    codeA3: 'LAO',
    codeN3: '418',
    name: {
      full: 'ラオス人民民主共和国',
      short: 'ラオス',
    },
    enName: {
      short: "Lao People's Democratic Republic (the)",
      full: "the Lao People's Democratic Republic",
    },
    frName: 'Lao (la République démocratique populaire)',
    independent: true,
    territory: null,
    remark: {
      part1: 'Also referred to as Laos.',
    },
    note: null,
  },
  {
    code: 'LV',
    codeA3: 'LVA',
    codeN3: '428',
    name: {
      full: 'ラトビア共和国',
      short: 'ラトビア',
    },
    enName: {
      short: 'Latvia',
      full: 'the Republic of Latvia',
    },
    frName: 'Lettonie (la)',
    independent: true,
    territory: null,
    remark: {
      part3: 'Formerly part of USSR (SU, SUN, 810) before its split. See code element SUHH.',
    },
    note: null,
  },
  {
    code: 'LB',
    codeA3: 'LBN',
    codeN3: '422',
    name: {
      full: 'レバノン共和国',
      short: 'レバノン',
    },
    enName: {
      short: 'Lebanon',
      full: 'the Lebanese Republic',
    },
    frName: 'Liban (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'LS',
    codeA3: 'LSO',
    codeN3: '426',
    name: {
      full: 'レソト王国',
      short: 'レソト',
    },
    enName: {
      short: 'Lesotho',
      full: 'the Kingdom of Lesotho',
    },
    frName: 'Lesotho (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'LR',
    codeA3: 'LBR',
    codeN3: '430',
    name: {
      full: 'リべリア共和国',
      short: 'リベリア',
    },
    enName: {
      short: 'Liberia',
      full: 'the Republic of Liberia',
    },
    frName: 'Libéria (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'LY',
    codeA3: 'LBY',
    codeN3: '434',
    name: {
      full: 'リビア国',
      short: 'リビア',
    },
    enName: {
      short: 'Libya',
      full: 'the State of Libya',
    },
    frName: 'Libye (la)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'LI',
    codeA3: 'LIE',
    codeN3: '438',
    name: {
      full: 'リヒテンシュタイン公国',
      short: 'リヒテンシュタイン',
    },
    enName: {
      short: 'Liechtenstein',
      full: 'the Principality of Liechtenstein',
    },
    frName: 'Liechtenstein (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'LT',
    codeA3: 'LTU',
    codeN3: '440',
    name: {
      full: 'リトアニア共和国',
      short: 'リトアニア',
    },
    enName: {
      short: 'Lithuania',
      full: 'the Republic of Lithuania',
    },
    frName: 'Lituanie (la)',
    independent: true,
    territory: null,
    remark: {
      part3: 'Formerly part of USSR (SU, SUN, 810) before its split. See code element SUHH.',
    },
    note: null,
  },
  {
    code: 'LU',
    codeA3: 'LUX',
    codeN3: '442',
    name: {
      full: 'ルクセンプルク大公国',
      short: 'ルクセンブルク',
    },
    enName: {
      short: 'Luxembourg',
      full: 'the Grand Duchy of Luxembourg',
    },
    frName: 'Luxembourg (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'MO',
    codeA3: 'MAC',
    codeN3: '446',
    name: {
      full: 'マカオ(澳門)特別行政区',
      short: null,
    },
    enName: {
      short: 'Macao',
      full: 'Macao Special Administrative Region of China',
    },
    frName: 'Macao',
    independent: false,
    territory: null,
    remark: {
      part2:
        'Subdivision in 2 districts, distrito (pt), which is not relevant for this part of ISO 3166. Included also as a subdivision of China (CN-MO).',
    },
    note: null,
  },
  {
    code: 'MG',
    codeA3: 'MDG',
    codeN3: '450',
    name: {
      full: 'マダガスカル共和国',
      short: 'マダガスカル',
    },
    enName: {
      short: 'Madagascar',
      full: 'the Republic of Madagascar',
    },
    frName: 'Madagascar',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'MW',
    codeA3: 'MWI',
    codeN3: '454',
    name: {
      full: 'マラウイ共和国',
      short: 'マラウイ',
    },
    enName: {
      short: 'Malawi',
      full: 'the Republic of Malawi',
    },
    frName: 'び',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'MY',
    codeA3: 'MYS',
    codeN3: '458',
    name: {
      full: 'マレーシア',
      short: 'マレーシア',
    },
    enName: {
      short: 'Malaysia',
      full: null,
    },
    frName: 'Malaisie (la)',
    independent: true,
    territory: ['Peninsular Malaysia', 'Sabah', 'Sarawa'],
    remark: {
      part1: 'Comprises: Peninsular Malaysia, Sabah, Sarawak.',
    },
    note: null,
  },
  {
    code: 'MV',
    codeA3: 'MDV',
    codeN3: '462',
    name: {
      full: 'モルディブ共和国',
      short: 'モルディブ',
    },
    enName: {
      short: 'Maldives',
      full: 'the Republic of Maldives',
    },
    frName: 'Maldives (les)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'ML',
    codeA3: 'MLI',
    codeN3: '466',
    name: {
      full: 'マリ共和国',
      short: 'マリ',
    },
    enName: {
      short: 'Mali',
      full: 'the Republic of Mali',
    },
    frName: 'Mali (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'MT',
    codeA3: 'MLT',
    codeN3: '470',
    name: {
      full: 'マルタ共和国',
      short: 'マルタ',
    },
    enName: {
      short: 'Malta',
      full: 'the Republic of Malta',
    },
    frName: 'Malte',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'MH',
    codeA3: 'MHL',
    codeN3: '584',
    name: {
      full: 'マーシャル諸島共和国',
      short: 'マーシャル',
    },
    enName: {
      short: 'Marshall Islands (the)',
      full: 'the Republic of the Marshall Islands',
    },
    frName: 'Marshall (les Îles)',
    independent: true,
    territory: ['Jaluit', 'Kwajalein', 'Majuro'],
    remark: {
      part1: 'Principal atolls: Jaluit, Kwajalein, Majuro.',
      part3:
        'Pacific Islands (Trust Territory) (PC, PCI, 582) were divided into: Marshall Islands, Federated States of Micronesia (FM, FSM, 583), Northern Mariana Islands (MP, MNP, 580) and Palau (PW, PLW, 585). See also code element PCHH.',
    },
    note: null,
  },
  {
    code: 'MQ',
    codeA3: 'MTQ',
    codeN3: '474',
    name: {
      full: 'マルチニーク島',
      short: null,
    },
    enName: {
      short: 'Martinique',
      full: null,
    },
    frName: 'Martinique (la)',
    independent: false,
    territory: null,
    remark: {
      part2:
        'No subdivisions relevant for this standard. Included also as a subdivision of France (FR-MQ).',
    },
    note: null,
  },
  {
    code: 'MR',
    codeA3: 'MRT',
    codeN3: '478',
    name: {
      full: 'モーリタニア・イスラム共和国',
      short: 'モーリタニア',
    },
    enName: {
      short: 'Mauritania',
      full: 'the Islamic Republic of Mauritania',
    },
    frName: 'Mauritanie (la)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'MU',
    codeA3: 'MUS',
    codeN3: '480',
    name: {
      full: 'モーリシャス共和国',
      short: 'モーリシャス',
    },
    enName: {
      short: 'Mauritius',
      full: 'the Republic of Mauritius',
    },
    frName: 'Maurice',
    independent: true,
    territory: ['Agalega Islands', 'Cargados Carajos Shoals', 'Rodrigues Island'],
    remark: {
      part1: 'Includes: Agalega Islands, Cargados Carajos Shoals, Rodrigues Island.',
    },
    note: null,
  },
  {
    code: 'YT',
    codeA3: 'MYT',
    codeN3: '175',
    name: {
      full: 'マイヨット島',
      short: null,
    },
    enName: {
      short: 'Mayotte',
      full: null,
    },
    frName: 'Mayotte',
    independent: false,
    territory: null,
    remark: {
      part2:
        'No subdivisions relevant for this standard. Included also as a subdivision of France (FR-YT).',
    },
    note: null,
  },
  {
    code: 'MX',
    codeA3: 'MEX',
    codeN3: '484',
    name: {
      full: 'メキシコ合衆国',
      short: 'メキシコ',
    },
    enName: {
      short: 'Mexico',
      full: 'the United Mexican States',
    },
    frName: 'Mexique (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'FM',
    codeA3: 'FSM',
    codeN3: '583',
    name: {
      full: 'ミクロネシア連邦',
      short: 'ミクロネシア',
    },
    enName: {
      short: 'Micronesia (Federated States of)',
      full: 'the Federated States of Micronesia',
    },
    frName: 'Micronésie (États fédérés de)',
    independent: true,
    territory: ['Caroline Islands', 'Chuuk, Kosrae', 'Pohnpei', 'Yap'],
    remark: {
      part1:
        'Comprises: Caroline Islands (except PALAU, see separate entry). Principal islands: Chuuk, Kosrae, Pohnpei, YapChuuk, Kosrae, Pohnpei, Yap.',
      part3:
        'Pacific Islands (Trust Territory) (PC, PCI, 582) were divided into: Marshall Islands (MH, MHL, 584), Federated States of Micronesia, Northern Mariana Islands (MP, MNP, 580) and Palau (PW, PLW, 585). See also code element PCHH.',
    },
    note: null,
  },
  {
    code: 'MD',
    codeA3: 'MDA',
    codeN3: '498',
    name: {
      full: 'モルドバ共和国',
      short: 'モルドバ',
    },
    enName: {
      short: 'Moldova (the Republic of)',
      full: 'the Republic of Moldova',
    },
    frName: 'Moldova (la République de)',
    independent: true,
    territory: null,
    remark: {
      part3: 'Formerly part of USSR (SU, SUN, 810) before its split. See code element SUHH.',
    },
    note: null,
  },
  {
    code: 'MC',
    codeA3: 'MCO',
    codeN3: '492',
    name: {
      full: 'モナコ公国',
      short: 'モナコ',
    },
    enName: {
      short: 'Monaco',
      full: 'the Principality of Monaco',
    },
    frName: 'Monaco',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'MN',
    codeA3: 'MNG',
    codeN3: '496',
    name: {
      full: 'モンゴル国',
      short: 'モンゴル',
    },
    enName: {
      short: 'Mongolia',
      full: null,
    },
    frName: 'Mongolie (la)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'ME',
    codeA3: 'MNE',
    codeN3: '499',
    name: {
      full: 'モンテネグロ',
      short: 'モンテネグロ',
    },
    enName: {
      short: 'Montenegro',
      full: null,
    },
    frName: 'Monténégro (le)',
    independent: true,
    territory: null,
    remark: {
      part1:
        'Montenegro was formerly part of former entries: YUGOSLAVIA (YU, YUG, 891) then SERBIA AND MONTENEGRO (CS, SCG, 891).',
      part3:
        'Serbia and Montenegro (CS, SCG, 891) was divided into Serbia (RS, SRB, 688) and Montenegro (ME, MNE, 499). See also code element CSXX.',
    },
    note: null,
  },
  {
    code: 'MS',
    codeA3: 'MSR',
    codeN3: '500',
    name: {
      full: 'モントセラト',
      short: null,
    },
    enName: {
      short: 'Montserrat',
      full: null,
    },
    frName: 'Montserrat',
    independent: false,
    territory: null,
    remark: {
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'MA',
    codeA3: 'MAR',
    codeN3: '504',
    name: {
      full: 'モロッコ王国',
      short: 'モロッコ',
    },
    enName: {
      short: 'Morocco',
      full: 'the Kingdom of Morocco',
    },
    frName: 'Maroc (le)',
    independent: true,
    territory: null,
    remark: {
      part2:
        'Remark: Entries followed by (EH) are located partially or fully in the territory of Western Sahara (ISO 3166 alpha-2 code element EH).',
    },
    note: null,
  },
  {
    code: 'MZ',
    codeA3: 'MOZ',
    codeN3: '508',
    name: {
      full: 'モザンビーク共和国',
      short: 'モザンビーク',
    },
    enName: {
      short: 'Mozambique',
      full: 'the Republic of Mozambique',
    },
    frName: 'Mozambique (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'MM',
    codeA3: 'MMR',
    codeN3: '104',
    name: {
      full: 'ミャンマー連邦',
      short: 'ミャンマー',
    },
    enName: {
      short: 'Myanmar',
      full: 'the Republic of the Union of Myanmar',
    },
    frName: 'Myanmar (le)',
    independent: true,
    territory: null,
    remark: {
      part2: 'Remark: the forms used in the list are English-language forms provided by Myanmar.',
      part3:
        'Name changed from former Burma (BU, BUR, 104) to Myanmar (MM MMR, 104). See code element BUMM.',
    },
    note: null,
  },
  {
    code: 'NA',
    codeA3: 'NAM',
    codeN3: '516',
    name: {
      full: 'ナミビア共和国',
      short: 'ナミビア',
    },
    enName: {
      short: 'Namibia',
      full: 'the Republic of Namibia',
    },
    frName: 'Namibie (la)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'NR',
    codeA3: 'NRU',
    codeN3: '520',
    name: {
      full: 'ナウル共和国',
      short: 'ナウル',
    },
    enName: {
      short: 'Nauru',
      full: 'the Republic of Nauru',
    },
    frName: 'Nauru',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'NP',
    codeA3: 'NPL',
    codeN3: '524',
    name: {
      full: 'ネパール連邦民主共和国',
      short: 'ネパール',
    },
    enName: {
      short: 'Nepal',
      full: 'the Federal Democratic Republic of Nepal',
    },
    frName: 'Népal (le)',
    independent: true,
    territory: null,
    remark: {
      part2:
        'In September 2015 Nepal created a new federal structure consisting of 7 provinces. Until now only two of these provinces have names. The rest are identified by numbers only. These provinces should replace the zones and development regions but this has not yet been fully implemented.',
    },
    note: null,
  },
  {
    code: 'NL',
    codeA3: 'NLD',
    codeN3: '528',
    name: {
      full: 'オランダ王国',
      short: 'オランダ',
    },
    enName: {
      short: 'Netherlands (the)',
      full: 'the Kingdom of the Netherlands',
    },
    frName: 'Pays-Bas (les)',
    independent: true,
    territory: null,
    remark: {
      part1: 'Includes: the islands Bonaire, Sint Eustatius and Saba.',
    },
    note: null,
  },
  {
    code: 'NC',
    codeA3: 'NCL',
    codeN3: '540',
    name: {
      full: 'ニューカレドニア',
      short: null,
    },
    enName: {
      short: 'New Caledonia',
      full: null,
    },
    frName: 'Nouvelle-Calédonie (la)',
    independent: false,
    territory: ['Loyalty Islands'],
    remark: {
      part1: 'Includes: Loyalty Islands.',
      part2:
        'No subdivisions relevant for this standard. Included also as a subdivision of France (FR-NC).',
    },
    note: null,
  },
  {
    code: 'NZ',
    codeA3: 'NZL',
    codeN3: '554',
    name: {
      full: 'ニュージーランド',
      short: 'ニュージーランド',
    },
    enName: {
      short: 'New Zealand',
      full: null,
    },
    frName: 'Nouvelle-Zélande (la)',
    independent: true,
    territory: [
      'Antipodes Islands',
      'Auckland Islands',
      'Campbell Island',
      'Chatham Islands',
      'Kermadec Islands',
    ],
    remark: {
      part1:
        'Includes: Antipodes Islands, Auckland Islands, Campbell Island, Chatham Islands, Kermadec Islands.',
    },
    note: null,
  },
  {
    code: 'NI',
    codeA3: 'NIC',
    codeN3: '558',
    name: {
      full: 'ニカラグア共和国',
      short: 'ニカラグア',
    },
    enName: {
      short: 'Nicaragua',
      full: 'the Republic of Nicaragua',
    },
    frName: 'Nicaragua (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'NE',
    codeA3: 'NER',
    codeN3: '562',
    name: {
      full: 'ニジェール共和国',
      short: 'ニジェール',
    },
    enName: {
      short: 'Niger (the)',
      full: 'the Republic of the Niger',
    },
    frName: 'Niger (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'NG',
    codeA3: 'NGA',
    codeN3: '566',
    name: {
      full: 'ナイジェリア連邦共和国',
      short: 'ナイジェリア',
    },
    enName: {
      short: 'Nigeria',
      full: 'the Federal Republic of Nigeria',
    },
    frName: 'Nigéria (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'NU',
    codeA3: 'NIU',
    codeN3: '570',
    name: {
      full: 'ニウエ',
      short: 'ニウエ',
    },
    enName: {
      short: 'Niue',
      full: null,
    },
    frName: 'Niue',
    independent: false,
    territory: null,
    remark: {
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'NF',
    codeA3: 'NFK',
    codeN3: '574',
    name: {
      full: 'ノーフォーク島',
      short: null,
    },
    enName: {
      short: 'Norfolk Island',
      full: null,
    },
    frName: "Norfolk (l'Île)",
    independent: false,
    territory: null,
    remark: {
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'MK',
    codeA3: 'MKD',
    codeN3: '807',
    name: {
      full: '北マケドニア共和国',
      short: '北マケドニア',
    },
    enName: {
      short: 'North Macedonia',
      full: 'the Republic of North Macedonia',
    },
    frName: 'Macédoine du Nord (la)',
    independent: true,
    territory: null,
    remark: {
      part3: 'Formerly part of Yugoslavia (YU, YUG, 891) before its split. See code element YUCS.',
    },
    note: null,
  },
  {
    code: 'MP',
    codeA3: 'MNP',
    codeN3: '580',
    name: {
      full: '北マリアナ諸島',
      short: null,
    },
    enName: {
      short: 'Northern Mariana Islands (the)',
      full: 'the Commonwealth of the Northern Mariana Islands',
    },
    frName: 'Mariannes du Nord (les Îles)',
    independent: false,
    territory: ['Mariana Islands (except GUAM)', 'Saipan (principal island)'],
    remark: {
      part1:
        'Comprises: Mariana Islands (except GUAM, see separate entry). (Principal island: Saipan).',
      part2:
        'No subdivisions relevant for this standard. Included also as a subdivision of the United States (US-MP).',
      part3:
        'Pacific Islands (Trust Territory) (PC, PCI, 582) were divided into: Marshall Islands (MH, MHL, 584), Federated States of Micronesia (FM, FSM, 583), Northern Mariana Islands, and Palau (PW, PLW, 585).',
    },
    note: null,
  },
  {
    code: 'NO',
    codeA3: 'NOR',
    codeN3: '578',
    name: {
      full: 'ノルウェー王国',
      short: 'ノルウェー',
    },
    enName: {
      short: 'Norway',
      full: 'the Kingdom of Norway',
    },
    frName: 'Norvège (la)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'OM',
    codeA3: 'OMN',
    codeN3: '512',
    name: {
      full: 'オマーン国',
      short: 'オマーン',
    },
    enName: {
      short: 'Oman',
      full: 'the Sultanate of Oman',
    },
    frName: 'Oman',
    independent: true,
    territory: ['part of the Musandam Peninsula'],
    remark: {
      part1: 'Includes: part of the Musandam Peninsula.',
    },
    note: null,
  },
  {
    code: 'PK',
    codeA3: 'PAK',
    codeN3: '586',
    name: {
      full: 'パキスタン・イスラム共和国',
      short: 'パキスタン',
    },
    enName: {
      short: 'Pakistan',
      full: 'the Islamic Republic of Pakistan',
    },
    frName: 'Pakistan (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'PW',
    codeA3: 'PLW',
    codeN3: '585',
    name: {
      full: 'パラオ共和国',
      short: 'パラオ',
    },
    enName: {
      short: 'Palau',
      full: 'the Republic of Palau',
    },
    frName: 'Palaos (les)',
    independent: true,
    territory: ['Babelthuap (principal island)', 'the west part of the Caroline Islands'],
    remark: {
      part1: 'Comprises: the west part of the Caroline Islands (Principal island: Babelthuap).',
      part3:
        'Pacific Islands (Trust Territory) (PC, PCI, 582) were divided into: Marshall Islands (MH, MHL, 584), Federated States of Micronesia (FM, FSM, 583), Northern Mariana Islands (MP, MNP, 580) and Palau. See also code element PCHH.',
    },
    note: null,
  },
  {
    code: 'PS',
    codeA3: 'PSE',
    codeN3: '275',
    name: {
      full: '西岸・ガザ(パレスチナ自治区)',
      short: null,
    },
    enName: {
      short: 'Palestine, State of',
      full: 'the State of Palestine',
    },
    frName: 'Palestine, État de',
    independent: false,
    territory: null,
    remark: {
      part2: 'Remark: Conventional names.',
    },
    note: null,
  },
  {
    code: 'PA',
    codeA3: 'PAN',
    codeN3: '591',
    name: {
      full: 'パナマ共和国',
      short: 'パナマ',
    },
    enName: {
      short: 'Panama',
      full: 'the Republic of Panama',
    },
    frName: 'Panama (le)',
    independent: true,
    territory: null,
    remark: {
      part3:
        'The Zone of the Panama Canal (PZ, PCZ, --) is now part of the entry for Panama (PA, PAN, 591). See also code element PZPA.',
    },
    note: null,
  },
  {
    code: 'PG',
    codeA3: 'PNG',
    codeN3: '598',
    name: {
      full: 'パプアニューギニア独立国',
      short: 'パプアニューギニア',
    },
    enName: {
      short: 'Papua New Guinea',
      full: 'the Independent State of Papua New Guinea',
    },
    frName: 'Papouasie-Nouvelle-Guinée (la)',
    independent: true,
    territory: [
      'Bismarck Archipelago',
      'Bougainville (principal island)',
      'Northern Solomon Islands',
    ],
    remark: {
      part1:
        'Includes: Bismarck Archipelago, Northern Solomon Islands (Principal island: Bougainville).',
    },
    note: null,
  },
  {
    code: 'PY',
    codeA3: 'PRY',
    codeN3: '600',
    name: {
      full: 'パラグアイ共和国',
      short: 'パラグアイ',
    },
    enName: {
      short: 'Paraguay',
      full: 'the Republic of Paraguay',
    },
    frName: 'Paraguay (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'PE',
    codeA3: 'PER',
    codeN3: '604',
    name: {
      full: 'ペルー共和国',
      short: 'ペルー',
    },
    enName: {
      short: 'Peru',
      full: 'the Republic of Peru',
    },
    frName: 'Pérou (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'PH',
    codeA3: 'PHL',
    codeN3: '608',
    name: {
      full: 'フィリピン共和国',
      short: 'フィリピン',
    },
    enName: {
      short: 'Philippines (the)',
      full: 'the Republic of the Philippines',
    },
    frName: 'Philippines (les)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'PN',
    codeA3: 'PCN',
    codeN3: '612',
    name: {
      full: 'ピトケアン諸島',
      short: null,
    },
    enName: {
      short: 'Pitcairn',
      full: null,
    },
    frName: 'Pitcairn',
    independent: false,
    territory: ['Ducie Island', 'Henderson Island', 'Oeno Island'],
    remark: {
      part1: 'Includes: Ducie Island, Henderson Island, Oeno Island.',
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'PL',
    codeA3: 'POL',
    codeN3: '616',
    name: {
      full: 'ポーランド共和国',
      short: 'ポーランド',
    },
    enName: {
      short: 'Poland',
      full: 'the Republic of Poland',
    },
    frName: 'Pologne (la)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'PT',
    codeA3: 'PRT',
    codeN3: '620',
    name: {
      full: 'ポルトガル共和国',
      short: 'ポルトガル',
    },
    enName: {
      short: 'Portugal',
      full: 'the Portuguese Republic',
    },
    frName: 'Portugal (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'PR',
    codeA3: 'PRI',
    codeN3: '630',
    name: {
      full: 'プエルトリコ',
      short: null,
    },
    enName: {
      short: 'Puerto Rico',
      full: null,
    },
    frName: 'Porto Rico',
    independent: false,
    territory: null,
    remark: {
      part2:
        'No subdivisions relevant for this standard. Included also as a subdivision of the United States (US-PR).',
    },
    note: null,
  },
  {
    code: 'QA',
    codeA3: 'QAT',
    codeN3: '634',
    name: {
      full: 'カタール国',
      short: 'カタール',
    },
    enName: {
      short: 'Qatar',
      full: 'the State of Qatar',
    },
    frName: 'Qatar (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'RO',
    codeA3: 'ROU',
    codeN3: '642',
    name: {
      full: 'ルーマニア',
      short: 'ルーマニア',
    },
    enName: {
      short: 'Romania',
      full: null,
    },
    frName: 'Roumanie (la)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'RU',
    codeA3: 'RUS',
    codeN3: '643',
    name: {
      full: 'ロシア連邦',
      short: 'ロシア',
    },
    enName: {
      short: 'Russian Federation (the)',
      full: 'the Russian Federation',
    },
    frName: 'Russie (la Fédération de)',
    independent: true,
    territory: ['Kaliningrad Region'],
    remark: {
      part1: 'Includes: the Kaliningrad Region.',
      part3: 'Formerly part of USSR (SU, SUN, 810) before its split. See code element SUHH.',
    },
    note: null,
  },
  {
    code: 'RW',
    codeA3: 'RWA',
    codeN3: '646',
    name: {
      full: 'ルワンダ共和国',
      short: 'ルワンダ',
    },
    enName: {
      short: 'Rwanda',
      full: 'the Republic of Rwanda',
    },
    frName: 'Rwanda (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'RE',
    codeA3: 'REU',
    codeN3: '638',
    name: {
      full: 'レユニオン',
      short: null,
    },
    enName: {
      short: 'Réunion',
      full: null,
    },
    frName: 'Réunion (La)',
    independent: false,
    territory: null,
    remark: {
      part2:
        'No subdivisions relevant for this standard. Included also as a subdivision of France (FR-RE).',
    },
    note: null,
  },
  {
    code: 'BL',
    codeA3: 'BLM',
    codeN3: '652',
    name: {
      full: 'サンバルテルミー',
      short: null,
    },
    enName: {
      short: 'Saint Barthélemy',
      full: null,
    },
    frName: 'Saint-Barthélemy',
    independent: false,
    territory: null,
    remark: {
      part2:
        'No subdivisions relevant for this standard. Included also as subdivision of France (FR-BL).',
    },
    note: null,
  },
  {
    code: 'SH',
    codeA3: 'SHN',
    codeN3: '654',
    name: {
      full: 'セントヘレナ・アセンション・トリスタンダクーニャ',
      short: null,
    },
    enName: {
      short: 'Saint Helena, Ascension and Tristan da Cunha',
      full: null,
    },
    frName: 'Sainte-Hélène, Ascension et Tristan da Cunha',
    independent: false,
    territory: [
      'Ascension Island',
      'Gough Island',
      'Inaccessible Island',
      'Nightingale Island',
      'Saint Helena Island',
      'Stoltenhoff Island',
      'Tristan da Cunha Archipelago',
    ],
    remark: {
      part1:
        'Comprises: Saint Helena Island, Ascension Island, Tristan da Cunha Archipelago (Gough Island, Inaccessible Island, Nightingale Island and Stoltenhoff Island).',
    },
    note: null,
  },
  {
    code: 'KN',
    codeA3: 'KNA',
    codeN3: '659',
    name: {
      full: 'セントキッツ・ネービス',
      short: 'セントクリストファー・ネーヴィス',
    },
    enName: {
      short: 'Saint Kitts and Nevis',
      full: null,
    },
    frName: 'Saint-Kitts-et-Nevis',
    independent: true,
    territory: ['Nevis *'],
    remark: {
      part1: 'the Federation of Saint Kitts and Nevis.',
    },
    note: null,
  },
  {
    code: 'LC',
    codeA3: 'LCA',
    codeN3: '662',
    name: {
      full: 'セントルシア',
      short: 'セントルシア',
    },
    enName: {
      short: 'Saint Lucia',
      full: null,
    },
    frName: 'Sainte-Lucie',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'MF',
    codeA3: 'MAF',
    codeN3: '663',
    name: {
      full: 'サンマルタン',
      short: null,
    },
    enName: {
      short: 'Saint Martin (French part)',
      full: null,
    },
    frName: 'Saint-Martin (partie française)',
    independent: false,
    territory: null,
    remark: {
      part1:
        'The island of Saint Martin is divided into the northern French part and the southern Dutch part.',
      part2:
        'No subdivisions relevant for this standard. Included also as subdivision of France (FR-MF).',
    },
    note: null,
  },
  {
    code: 'PM',
    codeA3: 'SPM',
    codeN3: '666',
    name: {
      full: 'サンピエール島・ミクロン島',
      short: null,
    },
    enName: {
      short: 'Saint Pierre and Miquelon',
      full: null,
    },
    frName: 'Saint-Pierre-et-Miquelon',
    independent: false,
    territory: ['Miquelon *'],
    remark: {
      part2:
        'No subdivisions relevant for this standard. Included also as a subdivision of France (FR-PM).',
    },
    note: null,
  },
  {
    code: 'VC',
    codeA3: 'VCT',
    codeN3: '670',
    name: {
      full: 'セントビンセント・グレナディーン諸島',
      short: 'セントビンセント',
    },
    enName: {
      short: 'Saint Vincent and the Grenadines',
      full: null,
    },
    frName: 'Saint-Vincent-et-les Grenadines',
    independent: true,
    territory: ['Bequia (principal island)', 'Northern Grenadine Islands', 'Saint Vincent Island'],
    remark: {
      part1:
        'Comprises: Northern Grenadine Islands (Principal island: Bequia), Saint Vincent Island.',
    },
    note: null,
  },
  {
    code: 'WS',
    codeA3: 'WSM',
    codeN3: '882',
    name: {
      full: 'サモア独立国',
      short: 'サモア',
    },
    enName: {
      short: 'Samoa',
      full: 'the Independent State of Samoa',
    },
    frName: 'Samoa (le)',
    independent: true,
    territory: ["Savai'i", 'Upolu'],
    remark: {
      part1: "Principal islands: Savai'i, Upolu.",
    },
    note: null,
  },
  {
    code: 'SM',
    codeA3: 'SMR',
    codeN3: '674',
    name: {
      full: 'サンマリノ共和国',
      short: 'サンマリノ',
    },
    enName: {
      short: 'San Marino',
      full: 'the Republic of San Marino',
    },
    frName: 'Saint-Marin',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'ST',
    codeA3: 'STP',
    codeN3: '678',
    name: {
      full: 'サントメ・プリンシペ民主共和国',
      short: 'サントメ・プリンシペ',
    },
    enName: {
      short: 'Sao Tome and Principe',
      full: 'the Democratic Republic of Sao Tome and Principe',
    },
    frName: 'Sao Tomé-et-Principe',
    independent: true,
    territory: ['Principe *'],
    remark: {
      part1: 'Príncipe* is the second significant part of composite country name.',
    },
    note: null,
  },
  {
    code: 'SA',
    codeA3: 'SAU',
    codeN3: '682',
    name: {
      full: 'サウジアラビア王国',
      short: 'アウジアラビア',
    },
    enName: {
      short: 'Saudi Arabia',
      full: 'the Kingdom of Saudi Arabia',
    },
    frName: "Arabie saoudite (l')",
    independent: true,
    territory: null,
    remark: {
      part3:
        'The Neutral Zone (NT, NTZ, 536) was divided and is now part of Iraq (IQ, IRQ, 368) and part of Saudi Arabia (SA, SAU, 682). The code entry (NT, NTZ, 536) was deleted from ISO 3166-1. See also code elelement NTHH.',
    },
    note: null,
  },
  {
    code: 'SN',
    codeA3: 'SEN',
    codeN3: '686',
    name: {
      full: 'セネガル共和国',
      short: 'セネガル',
    },
    enName: {
      short: 'Senegal',
      full: 'the Republic of Senegal',
    },
    frName: 'Sénégal (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'RS',
    codeA3: 'SRB',
    codeN3: '688',
    name: {
      full: 'セルビア共和国',
      short: 'セルビア',
    },
    enName: {
      short: 'Serbia',
      full: 'the Republic of Serbia',
    },
    frName: 'Serbie (la)',
    independent: true,
    territory: null,
    remark: {
      part1:
        'Serbia was formerly part of former entries: YUGOSLAVIA (YU, YUG, 891) then SERBIA AND MONTENEGRO (CS, SCG, 891).',
      part3:
        'Serbia and Montenegro (CS, SCG, 891) was divided into Serbia (RS, SRB, 688), and Montenegro (ME, MNE, 499). See code element CSXX.',
    },
    note: null,
  },
  {
    code: 'SC',
    codeA3: 'SYC',
    codeN3: '690',
    name: {
      full: 'セーシェル共和国',
      short: 'セーシェル',
    },
    enName: {
      short: 'Seychelles',
      full: 'the Republic of Seychelles',
    },
    frName: 'Seychelles (les)',
    independent: true,
    territory: [
      'Cosmoledo Islands',
      'Farquhar Islands',
      'Mahé (principal island)',
      'Aldabra Islands',
      'Amirante Islands',
    ],
    remark: {
      part1:
        'Principal island: Mahé; includes Aldabra Islands, Amirante Islands, Cosmoledo Islands, Farquhar Islands.',
    },
    note: null,
  },
  {
    code: 'SL',
    codeA3: 'SLE',
    codeN3: '694',
    name: {
      full: 'シエラレオネ共和国',
      short: 'シエラレオネ',
    },
    enName: {
      short: 'Sierra Leone',
      full: 'the Republic of Sierra Leone',
    },
    frName: 'Sierra Leone (la)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'SG',
    codeA3: 'SGP',
    codeN3: '702',
    name: {
      full: 'シンガポール共和国',
      short: 'シンガポール',
    },
    enName: {
      short: 'Singapore',
      full: 'the Republic of Singapore',
    },
    frName: 'Singapour',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'SX',
    codeA3: 'SXM',
    codeN3: '534',
    name: {
      full: '',
      short: null,
    },
    enName: {
      short: 'Sint Maarten (Dutch part)',
      full: null,
    },
    frName: 'Saint-Martin (partie néerlandaise)',
    independent: false,
    territory: null,
    remark: {
      part1:
        'The island of Saint Martin is divided into the northern French part and the southern Dutch part.',
      part2:
        'No subdivisions relevant for this standard. Included also as subdivision of Netherlands (NL-SX).',
      part3:
        'The Netherlands Antilles (AN, ANT, 530) was divided into Bonaire, Saint Eustatius and Saba (BQ, BES, 535), Curaçao (CW, CUW, 531) and Sint Maarten (Dutch part) (SX, SXM, 534). See also code element ANHH.',
    },
    note: null,
  },
  {
    code: 'SK',
    codeA3: 'SVK',
    codeN3: '703',
    name: {
      full: 'スロバキア共和国',
      short: 'スロバキア',
    },
    enName: {
      short: 'Slovakia',
      full: 'the Slovak Republic',
    },
    frName: 'Slovaquie (la)',
    independent: true,
    territory: null,
    remark: {
      part1:
        'Code element SK was formerly used for Sikkim (SK, SKM, --), now part of the entry for India (IN, IND, 356), was reused for Slovakia. See aslo code element SKIN.',
      part3:
        'Czechoslovakia (CS, CSK, 200) was divided into Czechia (CZ, CZE, 203), and Slovakia (SK, SVK, 703). See also code element CSHH.',
    },
    note: null,
  },
  {
    code: 'SI',
    codeA3: 'SVN',
    codeN3: '705',
    name: {
      full: 'スロべニア共和国',
      short: 'スロベニア',
    },
    enName: {
      short: 'Slovenia',
      full: 'the Republic of Slovenia',
    },
    frName: 'Slovénie (la)',
    independent: true,
    territory: null,
    remark: {
      part3: 'Formerly part of Yougoslavia (YU, YUG, 891) before its split. See code element YUCS.',
    },
    note: null,
  },
  {
    code: 'SB',
    codeA3: 'SLB',
    codeN3: '090',
    name: {
      full: 'ソロモン諸島',
      short: 'ソロモン',
    },
    enName: {
      short: 'Solomon Islands',
      full: null,
    },
    frName: 'Salomon (les Îles)',
    independent: true,
    territory: ['Guadalcanal (principal island)', 'Southern Solomon Islands', 'Santa Cruz Islands'],
    remark: {
      part1:
        'Comprises: Santa Cruz Islands, Southern Solomon Islands (Principal island: Guadalcanal).',
    },
    note: null,
  },
  {
    code: 'SO',
    codeA3: 'SOM',
    codeN3: '706',
    name: {
      full: 'ソマリア民主共和国',
      short: 'ソマリア',
    },
    enName: {
      short: 'Somalia',
      full: 'the Federal Republic of Somalia',
    },
    frName: 'Somalie (la)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'ZA',
    codeA3: 'ZAF',
    codeN3: '710',
    name: {
      full: '南アフリカ共和国',
      short: '南アフリカ共和国',
    },
    enName: {
      short: 'South Africa',
      full: 'the Republic of South Africa',
    },
    frName: "Afrique du Sud (l')",
    independent: true,
    territory: ['Marion Island', 'Prince Edward Island'],
    remark: {
      part1: 'Includes: Marion Island, Prince Edward Island.',
    },
    note: null,
  },
  {
    code: 'GS',
    codeA3: 'SGS',
    codeN3: '239',
    name: {
      full: '南ジョージア島・南サンドイッチ諸島',
      short: null,
    },
    enName: {
      short: 'South Georgia and the South Sandwich Islands',
      full: null,
    },
    frName: 'Géorgie du Sud-et-les Îles Sandwich du Sud (la)',
    independent: false,
    territory: ['South Sandwich Islands *'],
    remark: {
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'SS',
    codeA3: 'SSD',
    codeN3: '728',
    name: {
      full: '南スーダン共和国',
      short: '南スーダン',
    },
    enName: {
      short: 'South Sudan',
      full: 'the Republic of South Sudan',
    },
    frName: 'Soudan du Sud (le)',
    independent: true,
    territory: null,
    remark: {
      part1: 'Split of Sudan into Sudan (north part) and South Sudan (south part).',
    },
    note: null,
  },
  {
    code: 'ES',
    codeA3: 'ESP',
    codeN3: '724',
    name: {
      full: 'スペイン(王国)',
      short: 'スペイン',
    },
    enName: {
      short: 'Spain',
      full: 'the Kingdom of Spain',
    },
    frName: "Espagne (l')",
    independent: true,
    territory: null,
    remark: {
      part2:
        'Remark: In the autonomous communities of Galicia, Catalonia and the Balearics, the respective regional languages are the sole official languages of toponymy. Castilian language forms are given in square brackets for information. For the autonomous communities of Navarra, Valencia and the Basque Country, with the exception of the province names Bizkaia and Gipuzkoa, the regional language has co-official status with Castilian (regional language forms are marked with an asterisk).',
    },
    note: null,
  },
  {
    code: 'LK',
    codeA3: 'LKA',
    codeN3: '144',
    name: {
      full: 'スリランカ民主社会主義共和国',
      short: 'スリランカ',
    },
    enName: {
      short: 'Sri Lanka',
      full: 'the Democratic Socialist Republic of Sri Lanka',
    },
    frName: 'Sri Lanka',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'SD',
    codeA3: 'SDN',
    codeN3: '729',
    name: {
      full: 'スーダン共和国',
      short: 'スーダン',
    },
    enName: {
      short: 'Sudan (the)',
      full: 'the Republic of the Sudan',
    },
    frName: 'Soudan (le)',
    independent: true,
    territory: null,
    remark: {
      part1: 'Split of Sudan into Sudan (north part) and South Sudan (south part).',
    },
    note: null,
  },
  {
    code: 'SR',
    codeA3: 'SUR',
    codeN3: '740',
    name: {
      full: 'スリナム共和国',
      short: 'スリナム',
    },
    enName: {
      short: 'Suriname',
      full: 'the Republic of Suriname',
    },
    frName: 'Suriname (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'SJ',
    codeA3: 'SJM',
    codeN3: '744',
    name: {
      full: 'スバールバル諸島・ヤンマイエン島',
      short: null,
    },
    enName: {
      short: 'Svalbard and Jan Mayen',
      full: null,
    },
    frName: "Svalbard et l'Île Jan Mayen (le)",
    independent: false,
    territory: ['Bear Island', 'Jan Mayen'],
    remark: {
      part1: 'Includes: Bear Island.',
      part2:
        'No subdivisions relevant for this standard. Included also as a subdivision of Norway (NO-21, NO-22).',
    },
    note: null,
  },
  {
    code: 'SE',
    codeA3: 'SWE',
    codeN3: '752',
    name: {
      full: 'スウェーデン王国',
      short: 'スウェーデン',
    },
    enName: {
      short: 'Sweden',
      full: 'the Kingdom of Sweden',
    },
    frName: 'Suède (la)',
    independent: true,
    territory: null,
    remark: {
      part2:
        'Remark: Alphabetic and numeric codes co-exist. The alphabetic code is traditionally well known to the general public, whereas the numeric code (placed after the name within square brackets) is used mainly within national administration; it may in the longer term supersede the alphabetic code.',
    },
    note: null,
  },
  {
    code: 'CH',
    codeA3: 'CHE',
    codeN3: '756',
    name: {
      full: 'スイス連邦',
      short: 'スイス',
    },
    enName: {
      short: 'Switzerland',
      full: 'the Swiss Confederation',
    },
    frName: 'Suisse (la)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'SY',
    codeA3: 'SYR',
    codeN3: '760',
    name: {
      full: 'シリア・アラブ共和国',
      short: 'シリア',
    },
    enName: {
      short: 'Syrian Arab Republic (the)',
      full: 'the Syrian Arab Republic',
    },
    frName: 'République arabe syrienne (la)',
    independent: true,
    territory: null,
    remark: {
      part1: 'Often referred to as Syria.',
    },
    note: null,
  },
  {
    code: 'TW',
    codeA3: 'TWN',
    codeN3: '158',
    name: {
      full: '台湾(タイワン)',
      short: null,
    },
    enName: {
      short: 'Taiwan (Province of China)',
      full: null,
    },
    frName: 'Taïwan (Province de Chine)',
    independent: false,
    territory: ['Penghu (Pescadores) Islands'],
    remark: {
      part1: 'Includes: Penghu (Pescadores) Islands.',
    },
    note: null,
  },
  {
    code: 'TJ',
    codeA3: 'TJK',
    codeN3: '762',
    name: {
      full: 'タジキスタン共和国',
      short: 'タジキスタン',
    },
    enName: {
      short: 'Tajikistan',
      full: 'the Republic of Tajikistan',
    },
    frName: 'Tadjikistan (le)',
    independent: true,
    territory: null,
    remark: {
      part2:
        'Remark: The deletion of the region Karategin left one part of the country without name and without code in this part of ISO 3166. This section of the country is designated districts under republic administration (tgk: nohiyahoi tobei jumhurí) and comprises 13 districts (tgk: nohiya) which are administered directly by the central government at first-order level.',
      part3: 'Formerly part of USSR (SU, SUN, 810) before its split. See code element SUHH.',
    },
    note: null,
  },
  {
    code: 'TZ',
    codeA3: 'TZA',
    codeN3: '834',
    name: {
      full: 'タンザニア連合共和国',
      short: 'タンザニア',
    },
    enName: {
      short: 'Tanzania, the United Republic of',
      full: 'the United Republic of Tanzania',
    },
    frName: 'Tanzanie (la République-Unie de)',
    independent: true,
    territory: null,
    remark: {
      part1: 'Often referred to as Tanzania.',
    },
    note: null,
  },
  {
    code: 'TH',
    codeA3: 'THA',
    codeN3: '764',
    name: {
      full: 'タイ王国',
      short: 'タイ',
    },
    enName: {
      short: 'Thailand',
      full: 'the Kingdom of Thailand',
    },
    frName: 'Thaïlande (la)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'TL',
    codeA3: 'TLS',
    codeN3: '626',
    name: {
      full: '東ティモール民主共和国',
      short: '東ティモール',
    },
    enName: {
      short: 'Timor-Leste',
      full: 'the Democratic Republic of Timor-Leste',
    },
    frName: 'Timor-Leste (le)',
    independent: true,
    territory: ['the exclave of Oecussi'],
    remark: {
      part1: 'Includes the exclave of Oecussi.',
      part3:
        'Name changed from East Timor (TP, TMP, 626) to Timor-Leste. See also code entry TPTL.',
    },
    note: null,
  },
  {
    code: 'TG',
    codeA3: 'TGO',
    codeN3: '768',
    name: {
      full: 'トーゴ共和国',
      short: 'トーゴ',
    },
    enName: {
      short: 'Togo',
      full: 'the Togolese Republic',
    },
    frName: 'Togo (le)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'TK',
    codeA3: 'TKL',
    codeN3: '772',
    name: {
      full: 'トケラウ諸島',
      short: null,
    },
    enName: {
      short: 'Tokelau',
      full: null,
    },
    frName: 'Tokelau (les)',
    independent: false,
    territory: null,
    remark: {
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'TO',
    codeA3: 'TON',
    codeN3: '776',
    name: {
      full: 'トンガ王国',
      short: 'トンガ',
    },
    enName: {
      short: 'Tonga',
      full: 'the Kingdom of Tonga',
    },
    frName: 'Tonga (les)',
    independent: true,
    territory: ['Tongatapu (principal island)'],
    remark: {
      part1: 'Principal island: Tongatapu.',
    },
    note: null,
  },
  {
    code: 'TT',
    codeA3: 'TTO',
    codeN3: '780',
    name: {
      full: 'トリニダード・トバゴ共和国',
      short: 'トリニダード・トバゴ',
    },
    enName: {
      short: 'Trinidad and Tobago',
      full: 'the Republic of Trinidad and Tobago',
    },
    frName: 'Trinité-et-Tobago (la)',
    independent: true,
    territory: ['Tobago *'],
    remark: {
      part1: 'Tobago * is the second significant part of composite country name.',
    },
    note: null,
  },
  {
    code: 'TN',
    codeA3: 'TUN',
    codeN3: '788',
    name: {
      full: 'チュニジア共和国',
      short: 'チュニジア',
    },
    enName: {
      short: 'Tunisia',
      full: 'the Republic of Tunisia',
    },
    frName: 'Tunisie (la)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'TR',
    codeA3: 'TUR',
    codeN3: '792',
    name: {
      full: 'トルコ共和国',
      short: 'トルコ',
    },
    enName: {
      short: 'Turkey',
      full: 'the Republic of Turkey',
    },
    frName: 'Turquie (la)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'TM',
    codeA3: 'TKM',
    codeN3: '795',
    name: {
      full: 'トルクメニスタン',
      short: 'トルクメニスタン',
    },
    enName: {
      short: 'Turkmenistan',
      full: null,
    },
    frName: 'Turkménistan (le)',
    independent: true,
    territory: null,
    remark: {
      part2:
        'Remark: By presidential decree No. 1146, 1993-01-21. the Roman alphabet is officially used for the Turkmen language.',
      part3: 'Formerly part of USSR (SU, SUN, 810) before its split. See code element SUHH.',
    },
    note: null,
  },
  {
    code: 'TC',
    codeA3: 'TCA',
    codeN3: '796',
    name: {
      full: 'タークス・カイコス諸島',
      short: null,
    },
    enName: {
      short: 'Turks and Caicos Islands (the)',
      full: null,
    },
    frName: 'Turks-et-Caïcos (les Îles)',
    independent: false,
    territory: ['Caicos Islands'],
    remark: {
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'TV',
    codeA3: 'TUV',
    codeN3: '798',
    name: {
      full: 'ツバル',
      short: 'ツバル',
    },
    enName: {
      short: 'Tuvalu',
      full: null,
    },
    frName: 'Tuvalu (les)',
    independent: true,
    territory: ['Funafuti (principal atoll)'],
    remark: {
      part1: 'Principal atoll: Funafuti.',
      part3:
        'Gilbert and Ellice Islands (GE, GEL, --) divided into: Gilbert Islands incorporated into Kiribati (KI, KIR, 296), and Ellice Islands incorporated into Tuvalu (TV, TUV, 798). See code element GEHH.',
    },
    note: null,
  },
  {
    code: 'UG',
    codeA3: 'UGA',
    codeN3: '800',
    name: {
      full: 'ウガンダ共和国',
      short: 'ウガンダ',
    },
    enName: {
      short: 'Uganda',
      full: 'the Republic of Uganda',
    },
    frName: "Ouganda (l')",
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'UA',
    codeA3: 'UKR',
    codeN3: '804',
    name: {
      full: 'ウクライナ',
      short: 'ウクライナ',
    },
    enName: {
      short: 'Ukraine',
      full: null,
    },
    frName: "Ukraine (l')",
    independent: true,
    territory: null,
    remark: {
      part3: 'Formerly part of USSR (SU, SUN, 810) before its split. See code element SUHH.',
    },
    note: null,
  },
  {
    code: 'AE',
    codeA3: 'ARE',
    codeN3: '784',
    name: {
      full: 'アラブ首長国連邦',
      short: 'アラブ首長国連邦',
    },
    enName: {
      short: 'United Arab Emirates (the)',
      full: 'the United Arab Emirates',
    },
    frName: 'Émirats arabes unis (les)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'GB',
    codeA3: 'GBR',
    codeN3: '826',
    name: {
      full: 'グレートプリテン・北アイルランド連合王国(英国)',
      short: '英国',
    },
    enName: {
      short: 'United Kingdom of Great Britain and Northern Ireland (the)',
      full: 'the United Kingdom of Great Britain and Northern Ireland',
    },
    frName: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord (le)",
    independent: true,
    territory: null,
    remark: {
      part2:
        'BS 6879 gives alternative name forms in Welsh (cy) for some of the Welsh unitary authorities (together with alternative code elements). Since this part of ISO 3166 does not allow for duplicate coding of identical subdivisions, such alternative names in Welsh and code elements are shown for information purposes only in square brackets after the English name of the subdivision Country (en) / pays (fr), Province (en) / province (fr): ENG England country NIR Northern Ireland province SCT Scotland country WLS Wales [Cymru GB-CYM] country Included for completeness EAW England and Wales GBN Great Britain UKM United Kingdom.',
    },
    note: null,
  },
  {
    code: 'UM',
    codeA3: 'UMI',
    codeN3: '581',
    name: {
      full: '米領小離島',
      short: null,
    },
    enName: {
      short: 'United States Minor Outlying Islands (the)',
      full: null,
    },
    frName: 'Îles mineures éloignées des États-Unis (les)',
    independent: false,
    territory: [
      'Baker Island',
      'Howland Island',
      'Jarvis Island',
      'Johnston Atoll',
      'Kingman Reef',
      'Midway Islands',
      'Navassa Island',
      'Palmyra Atoll',
      'Wake Island',
    ],
    remark: {
      part1:
        'Comprises: In the Pacific Ocean: Baker Island, Howland Island, Jarvis Island, Johnston Atoll, Kingman Reef, Midway Islands, Palmyra Atoll, Wake Island In the Caribbean Sea: Navassa Island.',
      part2: 'Remark: Included also as a subdivision of the United States (US-UM).',
      part3:
        'Johnston Island (JT, JTN, 396) is now incorporated in the entry for US Minor Outlying Islands. See also code element JTUM. Midway Islands (MI, MID, 488) are now incorporated in the entry for US Minor Outlying Islands. See also code element MIUM. United States Miscellaneous Pacific Islands (PU, PUS, 849) are now incorporated in the entry for US Minor Outlying Islands. See also code element PUUM. Wake Island (WK, WAK, 872) is now incorporated in the entry for US Minor Outlying Islands (UM, UMI, 581). See also code element WKUM.',
    },
    note: null,
  },
  {
    code: 'US',
    codeA3: 'USA',
    codeN3: '840',
    name: {
      full: 'アメリカ合衆国',
      short: 'アメリカ合衆国',
    },
    enName: {
      short: 'United States of America (the)',
      full: 'the United States of America',
    },
    frName: "États-Unis d'Amérique (les)",
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'UY',
    codeA3: 'URY',
    codeN3: '858',
    name: {
      full: 'ウルグアイ東方共和国',
      short: 'ウルグアイ',
    },
    enName: {
      short: 'Uruguay',
      full: 'the Eastern Republic of Uruguay',
    },
    frName: "Uruguay (l')",
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'UZ',
    codeA3: 'UZB',
    codeN3: '860',
    name: {
      full: 'ウズベキスタン共和国',
      short: 'ウズベキスタン',
    },
    enName: {
      short: 'Uzbekistan',
      full: 'the Republic of Uzbekistan',
    },
    frName: "Ouzbékistan (l')",
    independent: true,
    territory: null,
    remark: {
      part2: 'In May 1995 a new Roman alphabet was officially decreed for the Uzbek language.',
      part3: 'Formerly part of USSR (SU, SUN, 810) before its split. See code element SUHH.',
    },
    note: null,
  },
  {
    code: 'VU',
    codeA3: 'VUT',
    codeN3: '548',
    name: {
      full: 'バヌアツ共和国',
      short: 'バヌアツ',
    },
    enName: {
      short: 'Vanuatu',
      full: 'the Republic of Vanuatu',
    },
    frName: 'Vanuatu (le)',
    independent: true,
    territory: ['Efate', 'Santo'],
    remark: {
      part1: 'Principal islands: Efate, Santo.',
      part3: 'Name changed from New Hebrides (NH, NHB, --) to Vanuatu. See also code element NHVU.',
    },
    note: null,
  },
  {
    code: 'VE',
    codeA3: 'VEN',
    codeN3: '862',
    name: {
      full: 'ベネズエラ・ボリバル共和国',
      short: 'ベネズエラ',
    },
    enName: {
      short: 'Venezuela (Bolivarian Republic of)',
      full: 'the Bolivarian Republic of Venezuela',
    },
    frName: 'Venezuela (République bolivarienne du)',
    independent: true,
    territory: ['Bird Island'],
    remark: {
      part1: 'Includes: Bird Island.',
    },
    note: null,
  },
  {
    code: 'VN',
    codeA3: 'VNM',
    codeN3: '704',
    name: {
      full: 'ベトナム社会主義共和国',
      short: 'ベトナム',
    },
    enName: {
      short: 'Viet Nam',
      full: 'the Socialist Republic of Viet Nam',
    },
    frName: 'Viet Nam (le)',
    independent: true,
    territory: null,
    remark: {
      part3:
        'The former Democratic Republic of Viet-Nam (VD, VDR, --) is now part of the entry for Viet Nam (VN, VNM, 704). See also the code element VDVN.',
    },
    note: null,
  },
  {
    code: 'VG',
    codeA3: 'VGB',
    codeN3: '092',
    name: {
      full: '英領バージン諸島',
      short: null,
    },
    enName: {
      short: 'Virgin Islands (British)',
      full: 'British Virgin Islands (the)',
    },
    frName: 'Vierges britanniques (les Îles)',
    independent: false,
    territory: ['Anegada', 'Jost Van Dyke', 'Tortola', 'Virgin Gorda'],
    remark: {
      part1: 'Principal islands: Anegada, Jost Van Dyke, Tortola, Virgin Gorda.',
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'VI',
    codeA3: 'VIR',
    codeN3: '850',
    name: {
      full: '米領バージン諸島',
      short: null,
    },
    enName: {
      short: 'Virgin Islands (U.S.)',
      full: 'the Virgin Islands of the United States',
    },
    frName: 'Vierges des États-Unis (les Îles)',
    independent: false,
    territory: ['Saint Croix', 'Saint John', 'Saint Thomas'],
    remark: {
      part1: 'Principal islands: Saint Croix, Saint John, Saint Thomas.',
      part2:
        'No subdivisions relevant for this standard. Included also as a subdivision of the United States (US-VI).',
    },
    note: null,
  },
  {
    code: 'WF',
    codeA3: 'WLF',
    codeN3: '876',
    name: {
      full: 'ワリス・フテュナ諸島',
      short: null,
    },
    enName: {
      short: 'Wallis and Futuna',
      full: 'Wallis and Futuna Islands',
    },
    frName: 'Wallis-et-Futuna',
    independent: false,
    territory: ['Futuna', 'Hoorn Islands', 'Uvea', 'Wallis Islands'],
    remark: {
      part1:
        'Comprises: Hoorn Islands (Principal island: Futuna), Wallis Islands (Principal island: Uvea).',
      part2: 'Included also as a subdivision of France (FR-WF).',
    },
    note: null,
  },
  {
    code: 'EH',
    codeA3: 'ESH',
    codeN3: '732',
    name: {
      full: '西サハラ',
      short: null,
    },
    enName: {
      short: 'Western Sahara*',
      full: null,
    },
    frName: 'Sahara occidental (le)*',
    independent: false,
    territory: null,
    remark: {
      part1: '* Provisional name.',
      part2: 'No subdivisions relevant for this standard.',
    },
    note: null,
  },
  {
    code: 'YE',
    codeA3: 'YEM',
    codeN3: '887',
    name: {
      full: 'イエメン共和国',
      short: 'イエメン',
    },
    enName: {
      short: 'Yemen',
      full: 'the Republic of Yemen',
    },
    frName: 'Yémen (le)',
    independent: true,
    territory: ['Socotra Island'],
    remark: {
      part1: 'Includes: Socotra Island.',
      part3:
        'The Democratic Yemen (YD, YMD, 720) was incorporated into the entry Yemen (YE, YEM, 887). See also the code element YDYE.',
    },
    note: null,
  },
  {
    code: 'ZM',
    codeA3: 'ZMB',
    codeN3: '894',
    name: {
      full: 'ザンビア共和国',
      short: 'ザンビア',
    },
    enName: {
      short: 'Zambia',
      full: 'the Republic of Zambia',
    },
    frName: 'Zambie (la)',
    independent: true,
    territory: null,
    remark: null,
    note: null,
  },
  {
    code: 'ZW',
    codeA3: 'ZWE',
    codeN3: '716',
    name: {
      full: 'ジンバブエ共和国',
      short: 'ジンバブエ',
    },
    enName: {
      short: 'Zimbabwe',
      full: 'the Republic of Zimbabwe',
    },
    frName: 'Zimbabwe (le)',
    independent: true,
    territory: null,
    remark: {
      part3:
        'Name changed from Southern Rhodesia (RH, RHO, --) to Zimbabwe (ZW, ZWE, 716). See also code element RHZW.',
    },
    note: null,
  },
  {
    code: 'XK',
    codeA3: null,
    codeN3: null,
    name: {
      full: 'コソボ共和国',
      short: 'コソボ',
    },
    enName: {
      short: 'Kosovo',
      full: 'the Republic of Kosovo',
    },
    frName: 'Kosovo (le)',
    independent: false,
    territory: null,
    remark: null,
    note: '国コードXKはISO 3166-1では定義されていない。',
  },
];

interface Country {
  code: string;
  codeA3: string;
  codeN3: string;
  name: Name;
  enName: Name;
  frName: string;
  independent: boolean;
  territory: string[];
  remark: any;
  note: string;
}

interface Name {
  full: string;
  short: string;
}
