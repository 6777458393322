import { i18n } from '@i18n/lang';
import { EAlertStatus } from '@models/alert-status-type';

export function getUserSuffix(status: EAlertStatus, lang) {
  switch (status) {
    case EAlertStatus.APPROVED:
      return i18n.approvedBy[lang];
    default:
      return i18n.processedBy[lang];
  }
}
