import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { IStateApps } from '@models/state-apps';
import { IStoreState } from '@redux/reducers';
import appLanguages from '@utils/app-languages';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

class NotFoundClass extends React.PureComponent<Props> {
  render() {
    const {
      apps: { currentLanguage: lang },
    } = this.props;

    return (
      <>
        <Box mt={3} />
        <Grid container justify='center'>
          <Grid item xs={10} className='text-bootstrap4-secondary'>
            <>{appLanguages.pageNotFound[lang]}</>
          </Grid>
        </Grid>
      </>
    );
  }
}

export type Props = IStateProps;

export interface IStateProps {
  apps: IStateApps;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
});

export const NotFound = compose(connect(mapStateToProps))(NotFoundClass);
