import { IQuery } from '@models/query';
import { LIST_PAGE_INITIAL_ITEM_LIMIT } from './config';

export const DefaultQuery: IQuery = {
  offset: 0,
  limit: LIST_PAGE_INITIAL_ITEM_LIMIT,
  searchText: '',
  order: [['createdAt', 'DESC']],
  where: {},
  totalCounts: 0,
};

export const LIMIT_PAGINATION_MAX = 200;
