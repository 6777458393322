import { Dialog, DialogContent } from '@material-ui/core';
import { isOk } from '@utils/is-ok';
import { TextDisplay } from '@visual/text-display';
import hash from 'object-hash';
import React from 'react';
import styles from './style.scss';

export class UpdateHistoryPopup extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    showPopup: false,
  };

  public render(): JSX.Element {
    const { showPopup, onClose, updateHistoryList } = this.props;
    if (!isOk(updateHistoryList)) return <React.Fragment />;
    return (
      <Dialog open={showPopup} onClose={onClose}>
        <DialogContent style={{ padding: 0 }}>
          <div className={styles.updateHistoryPopup}>
            {updateHistoryList.map((updateHistory, i) =>
              this.getUpdateHistoryRow(updateHistory, i),
            )}
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  protected getUpdateHistoryRow(updateHistory: UpdateHistory, index: number): JSX.Element {
    const displayUser = updateHistory.userTag && updateHistory.assigneeName && (
      <span
        className={styles.field}
      >{`${updateHistory.userTag} : ${updateHistory.assigneeName}`}</span>
    );

    return (
      <div className={styles.updateHistory} key={hash(index)}>
        <div className={styles.updateHistoryContent}>
          <TextDisplay color='primary'>{updateHistory.title}</TextDisplay>
        </div>
        <TextDisplay color='black'>
          <span className={styles.field}>{updateHistory.updateDate}</span>
          {displayUser}
        </TextDisplay>
      </div>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  updateHistoryList: UpdateHistory[];
  showPopup?: boolean;
}

export interface UpdateHistory {
  title: string;
  userTag: string;
  updateDate: string;
  assigneeName: string;
}

export interface IDispatchProps {
  onClose?: () => void;
}
