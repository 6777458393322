import { IStateApps } from '@models/state-apps';
import { IStateServers } from '@models/state-servers';
import { TSupportedLanguages } from '@models/supported-languages';
import { Item } from '@visual/search-filter-popup';

/*
  G3_各種情報_酒販店
  ================
  キーワード
  ID
  所在地
*/

export function getFields(
  lang: TSupportedLanguages,
  apps: IStateApps,
  servers: IStateServers,
): Item[] {
  return [];
}
