import { i18n } from '@i18n/lang';
import { ILocation } from '@models/location';
import { IRestaurantProduct } from '@models/restaurant-products';
import { ERestaurantStatus } from '@models/restaurant-status-type';
import { IStateApps } from '@models/state-apps';
import { IStateServers } from '@models/state-servers';
import { setExpandedRestStatusTableNestedTable } from '@redux/actions/appsActions';
import { getRestaurantProductsById } from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import { formatDate } from '@utils/format-date';
import { isOk } from '@utils/is-ok';
import { Props as RestaurantTableRowProps, RestaurantTableRow } from '@visual/restaurant-tablerow';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

class RestaurantStatusTableRowClass extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const {
      restaurantLocatonInfo,
      apps: { currentLanguage: lang },
      isLastRow,
      isFirstRow,
    } = this.props;
    const { name, customerCode, id } = restaurantLocatonInfo;

    const restTableRowProps: RestaurantTableRowProps = {
      id,
      name,
      lang,
      isFirstRow,
      isLastRow,
      code: customerCode,
      // TODO [SAKE-BC] DEFINE THESE
      status: ERestaurantStatus.ACTIVE,
      flagship: true,
      lastInspectionDate: formatDate(new Date()),
      // TODO [SAKE-BC] DEFINE THESE
      isNestedTableCollapsed: false,
      nestedTableData: this.getNestedTableData(),
      onNestedTableDisplayClick: (isDisplay: boolean, restLocId: number) =>
        this.handleOnNestedTableDisplayClick(isDisplay, restLocId),
    };

    return <RestaurantTableRow {...restTableRowProps} />;
  }

  protected getNestedTableData() {
    const {
      servers: { restaurantProductsById },
      apps: { currentLanguage: lang },
      restaurantLocatonInfo,
    } = this.props;
    const targetProducts: IRestaurantProduct[] = restaurantProductsById[restaurantLocatonInfo.id];
    if (!isOk(targetProducts)) return;
    return {
      columns: [
        i18n.brand[lang],
        i18n.sales[lang],
        i18n.inventory[lang],
        i18n.lastDeliveryDate[lang],
        i18n.menuPrice[lang],
        i18n.temp[lang],
      ],
      rows: targetProducts.map((product) => ({
        data: [
          product.brandName,
          product.sales,
          product.inventory,
          formatDate(product.lastDeliveryDate),
          `${[product.menuPrice, product.menuUnit].filter((data) => data).join('/')}`,
          product.temp,
        ],
      })),
    };
  }

  protected handleOnNestedTableDisplayClick(isDisplay: boolean, restLocId: number) {
    const {
      servers: { restaurantProductsById },
      getRestaurantProductsById,
    } = this.props;
    if (isDisplay && !isOk(restaurantProductsById[restLocId])) {
      // Call API if no Data
      getRestaurantProductsById(restLocId);
    }
    setExpandedRestStatusTableNestedTable(restLocId, isDisplay);
  }
}

export type Props = IStateProps & IDispatchProps & OwnProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
}

export interface OwnProps {
  restaurantLocatonInfo: ILocation;
  isFirstRow?: boolean;
  isLastRow?: boolean;
}

export interface IDispatchProps {
  setExpandedRestStatusTableNestedTable: (restLocId: number, isDisplay: boolean) => void;
  getRestaurantProductsById: (restLocId: number) => void;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps: IDispatchProps = {
  setExpandedRestStatusTableNestedTable,
  getRestaurantProductsById,
};

export const RestaurantStatusTableRow = compose(connect(mapStateToProps, mapDispatchToProps))(
  RestaurantStatusTableRowClass,
);

/*
 ========
 OR QUERY
 ========
 const query = {​​​​​​​ ...initialQuery, where: {​​​​​​​ hidden: false, $or: [{​​​​​​​ category: 2 }​​​​​​​, {​​​​​​​ category: 3 }​​​​​​​] }​​​​​​​ }​​​​​​​;
  const sequelizeOpsMap = {​​​​​​
  $or: Op.or,
  $and: Op.and,
  $between: Op.between,
  $ne: Op.ne,
  $gt: Op.gt,
  $gte: Op.gte,
  $lt: Op.lt,
  $lte: Op.lte,
  $notBetween: Op.notBetween,
  $in: Op.in,
  $notIn: Op.notIn,
  $like: Op.like,
  $notLike: Op.notLike,
}​​​​​​;
*/
