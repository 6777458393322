import { i18n } from '@i18n/lang';
// import { AllPages } from '@models/all-pages';
import { ECategoryType } from '@models/category-type';
import { IUser } from '@models/user';
import { getUserCategory } from '@utils/get-user-category';
import { TTheme as CardButtonTheme } from '@visual/card-button';
import { IconTypes } from '@visual/icon';

/**
 Rest:  Alert, Chat, Scan,                                             Notif, charts
 Dist:  Alert, Chat, Scan,               Location Master, User Master, Notif, charts
 Brew:  Alert, Chat, Scan, Brand Master, Location Master, User Master, Notif, charts
 Admin: Alert, Chat,       Brand Master, Location Master, User Master, Notif, charts
 */

export function getMenuItems(user: IUser): PageConfig[] {
  const userCategory = getUserCategory(user);

  switch (userCategory) {
    case ECategoryType.ADMIN:
      // [管理者] アラート、チャット、お知らせ配信、お知らせ履歴、商品情報、カスタマー情報、ID・PASS
      return [
        {
          icon: 'monitor',
          itemName: 'unhandledAlerts',
          pageLink: 'monitoring',
        },
        {
          icon: 'chat',
          itemName: 'chat',
          pageLink: 'chat',
        },
        {
          icon: 'edit',
          itemName: 'notificationRegister',
          pageLink: 'notification-register',
        },
        {
          icon: 'notice',
          itemName: 'notificationHistory',
          pageLink: 'notification-list',
        },
        {
          icon: 'orderscan',
          itemName: 'stockManagement',
          newWindowLink: user.uid,
        },
        {
          icon: 'brand',
          itemName: 'updateBrand',
          pageLink: 'master-brands',
        },
        {
          icon: 'map',
          itemName: 'updateCustomerLocation',
          pageLink: 'master-locations',
        },
        {
          icon: 'consumer',
          itemName: 'updateUserIdPass',
          pageLink: 'master-users',
        },
        {
          icon: 'charts',
          itemName: 'charts',
          pageLink: 'charts',
        },
      ];
    case ECategoryType.BREWERY:
      // [酒蔵] アラート、チャット、お知らせ、商品出荷、商品情報、ID・PASS、カスタマー情報
      return [
        {
          icon: 'monitor',
          itemName: 'unhandledAlerts',
          pageLink: 'monitoring',
        },
        {
          icon: 'chat',
          itemName: 'chat',
          pageLink: 'chat',
        },
        {
          icon: 'notice',
          itemName: 'notificationList',
          pageLink: 'notification-list',
        },
        {
          icon: 'orderscan',
          itemName: 'productShipment',
          newWindowLink: user.uid,
        },
        {
          icon: 'brand',
          itemName: 'updateBrand',
          pageLink: 'master-brands',
        },
        {
          icon: 'consumer',
          itemName: 'updateUserIdPass',
          pageLink: 'master-users',
        },
        {
          icon: 'map',
          itemName: 'updateCustomerLocation',
          pageLink: 'master-locations',
        },
        {
          icon: 'charts',
          itemName: 'charts',
          pageLink: 'charts',
        },
      ];
    case ECategoryType.DISTRIBUTOR:
      // [ディストリビューター] アラート、チャット、お知らせ、商品入荷/出荷、カスタマー情報、商品情報、ID・PASS
      return [
        {
          icon: 'monitor',
          itemName: 'unhandledAlerts',
          pageLink: 'monitoring',
        },
        {
          icon: 'chat',
          itemName: 'chat',
          pageLink: 'chat',
        },
        {
          icon: 'notice',
          itemName: 'notificationList',
          pageLink: 'notification-list',
        },
        {
          icon: 'orderscan',
          itemName: 'productShipmentOrReceive',
          newWindowLink: user.uid,
        },
        {
          icon: 'map',
          itemName: 'updateCustomerLocation',
          pageLink: 'master-locations',
        },
        {
          icon: 'consumer',
          itemName: 'updateUserIdPass',
          pageLink: 'master-users',
        },
        {
          icon: 'charts',
          itemName: 'charts',
          pageLink: 'charts',
        },
      ];
    case ECategoryType.RESTAURANT:
      // [レストラン] アラート、チャット、お知らせ、商品入荷/販売
      return [
        {
          icon: 'monitor',
          itemName: 'unhandledAlerts',
          pageLink: 'monitoring',
        },
        {
          icon: 'chat',
          itemName: 'chat',
          pageLink: 'chat',
        },
        {
          icon: 'notice',
          itemName: 'notificationList',
          pageLink: 'notification-list',
        },
        {
          icon: 'orderscan',
          itemName: 'productShipmentOrReceive',
          newWindowLink: user.uid,
        },
        {
          icon: 'charts',
          itemName: 'charts',
          pageLink: 'charts',
        },
      ];
  }
}

export interface PageConfig {
  icon: IconTypes;
  itemName: keyof typeof i18n;
  cardButtonTheme?: CardButtonTheme;
  pageLink?: string;
  newWindowLink?: string;
}
