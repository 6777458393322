import { EAlertStatus } from '@models/alert-status-type';
import { ECategoryType } from '@models/category-type';
import { IQuery } from '@models/query';
import { IUser } from '@models/user';
import { DefaultQuery } from '@utils/default-query';
import { getUserCategory, isUserAdmin } from '@utils/get-user-category';

export function getAlertsQuery(
  selectedSortItem: number,
  selectedStatus: EAlertStatus[],
  userInfo: IUser,
): IQuery {
  const monitoringQuery: IQuery = { ...DefaultQuery };

  // Sort
  switch (selectedSortItem) {
    case 0:
      // Alert At DESC
      monitoringQuery.order = [['alertAt', 'DESC']];
      break;
    case 1:
      // Alert At ASC
      monitoringQuery.order = [['alertAt', 'ASC']];
      break;
    case 2:
      // Serial No ASC
      monitoringQuery.order = [['serialCode', 'ASC']];
      break;
    case 3:
      // Serial No DESC
      monitoringQuery.order = [['serialCode', 'DESC']];
      break;
    case 4:
      // Status ASC
      monitoringQuery.order = [['status', 'ASC']];
      break;
  }

  // Status Filter
  monitoringQuery.where = {
    $or: selectedStatus.map((status) => ({ status: status })),
    ...getAlertWhere(userInfo),
  };

  return monitoringQuery;
}

export function getAlertsCountQuery(userInfo: IUser): IQuery {
  return {
    ...DefaultQuery,
    where: getAlertWhere(userInfo),
  };
}

export function getAlertsCountUrgentQuery(userInfo: IUser): IQuery {
  return {
    ...DefaultQuery,
    where: { alertLocId: userInfo.location?.id },
  };
}

export function getAlertsQueryPerSerialCodeAndSort(
  serial: string,
  selectedSortItem: number,
  userInfo: IUser,
) {
  const monitoringQuery: IQuery = { ...DefaultQuery };
  // Sort
  switch (selectedSortItem) {
    case 0:
      // Alert At DESC
      monitoringQuery.order = [['alertAt', 'DESC']];
      break;
    case 1:
      // Alert At ASC
      monitoringQuery.order = [['alertAt', 'ASC']];
      break;
    case 2:
      // Serial No ASC
      monitoringQuery.order = [['serialCode', 'ASC']];
      break;
    case 3:
      // Serial No DESC
      monitoringQuery.order = [['serialCode', 'DESC']];
      break;
    // case 4:
    //   // Status ASC
    //   monitoringQuery.order = [['status', 'ASC']];
    //   break;
  }

  if (serial) {
    monitoringQuery.where = {
      serialCode: { $like: `%${serial}%` },
      ...getAlertWhere(userInfo),
    };
  } else {
    monitoringQuery.where = {
      ...getAlertWhere(userInfo),
    };
  }
  return monitoringQuery;
}

export function getAlterWhereClause(query: any, userInfo: IUser) {
  const whereClause = query.where;
  const locationClause = getAlertWhere(userInfo);
  const newWhereClause = Object.assign({}, whereClause, locationClause);
  return newWhereClause;
}

function getAlertWhere(userInfo?: IUser) {
  // Access filter
  const userCategory = getUserCategory(userInfo);
  const userLocId = userInfo?.location?.id;
  if (!isUserAdmin(userInfo)) {
    return {
      // locType: userCategory,
      // alertLocId: userLocId,
      ...(userCategory === ECategoryType.BREWERY && { brewLocId: userLocId }),
      ...(userCategory === ECategoryType.DISTRIBUTOR && { distLocId: userLocId }),
      ...(userCategory === ECategoryType.RESTAURANT && { restLocId: userLocId }),
    };
  }
  return;
}
