import { i18n } from '@i18n/lang';
import { ERestaurantProductCuisineType } from '@models/restaurant-product-cuisine-type';
import { TSupportedLanguages } from '@models/supported-languages';

export function getCuisineLangList(lang: TSupportedLanguages): CuisineType[] {
  return [
    {
      id: ERestaurantProductCuisineType.UNKNOWN,
      name: i18n.unspecified[lang],
    },
    {
      id: ERestaurantProductCuisineType.JAPANESE_SUSHI,
      name: i18n.japaneseSushi[lang],
    },
    {
      id: ERestaurantProductCuisineType.JAPANESE_TEMPURA,
      name: i18n.japaneseTempura[lang],
    },
    {
      id: ERestaurantProductCuisineType.JAPANESE_KAISEKI,
      name: i18n.japaneseKaiseki[lang],
    },
    {
      id: ERestaurantProductCuisineType.JAPANESE_KAPPO_RYORI,
      name: i18n.japaneseKappoRyori[lang],
    },
    {
      id: ERestaurantProductCuisineType.JAPANESE_TEPPANYAKI,
      name: i18n.japaneseTeppanyaki[lang],
    },
    {
      id: ERestaurantProductCuisineType.JAPANESE_YAKITORI_KUSHIYAKI_CHICKEN,
      name: i18n.japaneseYakitoriKushiyakiChicken[lang],
    },
    {
      id: ERestaurantProductCuisineType.JAPANESE_IZAKAYA_SAKE_BAR,
      name: i18n.japaneseIzakayaSakeBar[lang],
    },
    {
      id: ERestaurantProductCuisineType.JAPANESE_MODERN,
      name: i18n.japaneseModern[lang],
    },
    {
      id: ERestaurantProductCuisineType.STEAK_YAKINIKU_BBQ,
      name: i18n.steakYakinikuBBQ[lang],
    },
    {
      id: ERestaurantProductCuisineType.CHINESE_CANTONESE,
      name: i18n.chineseCantonese[lang],
    },
    {
      id: ERestaurantProductCuisineType.CHINESE_SICHUAN,
      name: i18n.chineseSichuan[lang],
    },
    {
      id: ERestaurantProductCuisineType.CHINESE_SHANGHAINESE,
      name: i18n.chineseShanghainese[lang],
    },
    {
      id: ERestaurantProductCuisineType.CHINESE_TAIWANESE,
      name: i18n.chineseTaiwanese[lang],
    },
    {
      id: ERestaurantProductCuisineType.CHINESE_ZHEJIANG,
      name: i18n.chineseZhejiang[lang],
    },
    {
      id: ERestaurantProductCuisineType.EUROPEAN,
      name: i18n.european[lang],
    },
    {
      id: ERestaurantProductCuisineType.FRENCH,
      name: i18n.french[lang],
    },
    {
      id: ERestaurantProductCuisineType.ITALIAN,
      name: i18n.italian[lang],
    },
    {
      id: ERestaurantProductCuisineType.FUSION,
      name: i18n.fusion[lang],
    },
    {
      id: ERestaurantProductCuisineType.KOREAN,
      name: i18n.korean[lang],
    },
    {
      id: ERestaurantProductCuisineType.SOUTH_EAST_ASIAN,
      name: i18n.southEastAsian[lang],
    },
    {
      id: ERestaurantProductCuisineType.OTHERS,
      name: i18n.others[lang],
    },
  ];
}

interface CuisineType {
  id: ERestaurantProductCuisineType;
  name: string;
}
