import { CircularLoading } from '@container/components/circular-loading';
import {
  getAlertsCountQuery,
  getAlertsCountUrgentQuery,
} from '@container/pages/monitoring/config/get-alerts-query';
import { i18n } from '@i18n/lang';
import { Grid, StyleRules, Theme, withStyles } from '@material-ui/core';
import { AllPages } from '@models/all-pages';
import { ECategoryType } from '@models/category-type';
import { IQuery } from '@models/query';
import { IStateApps } from '@models/state-apps';
import { IStateAuth } from '@models/state-auth';
import { IStateServers } from '@models/state-servers';
import { setCurrentLanguage, setCurrentPage } from '@redux/actions/appsActions';
import { initAuth } from '@redux/actions/authActions';
import {
  getAlertAnalysis,
  getAlertsCount,
  getAlertsUrgentCount,
  getLocations,
  getNewNotificationCount,
  registerLoginHistory,
} from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import { DefaultQuery } from '@utils/default-query';
import { functions } from '@utils/firebase';
import { formatDate } from '@utils/format-date';
import { getUserCategory } from '@utils/get-user-category';
import { gotoPage } from '@utils/go-to-page';
import { isOk } from '@utils/is-ok';
import { CardButton } from '@visual/card-button';
import { ColoredBackground } from '@visual/colored-background';
import { DahsboardSummaryPopup } from '@visual/dashboard-summary-popup';
import { Icon } from '@visual/icon';
import { TextDisplay } from '@visual/text-display';
import { History, LocationState } from 'history';
import hash from 'object-hash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getMenuItems, PageConfig } from './config/get-menu-items';

class MainMenuClass extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      serialsList: null,
      showSummaryPopup: false,
    };
  }

  public async componentDidMount() {
    const {
      getNewNotificationCount,
      setCurrentPage,
      getAlertsCount,
      getAlertsUrgentCount,
      registerLoginHistory,
      servers: { isLoginHistoryRegistered },
    } = this.props;
    getNewNotificationCount(this.props?.servers?.user?.id);
    setCurrentPage('main-menu');
    getAlertsCount(getAlertsCountQuery(this.props?.servers?.user));
    getAlertsUrgentCount(getAlertsCountUrgentQuery(this.props?.servers?.user));
    const query = {
      offset: 0,
      limit: 10000,
      searchText: '',
      order: [['updatedAt', 'DESC']],
      totalCounts: 0,
      where: {
        hidden: false,
        $or: [{ category: ECategoryType.DISTRIBUTOR }, { category: ECategoryType.BREWERY }],
      },
    };
    getLocations(query);
    //this.toggleSummaryPopup();
    this.fetchSerials();
    if (!isLoginHistoryRegistered) {
      this.toggleSummaryPopup();
      registerLoginHistory();
    }
    this.fetchAlertAnalysis();
  }

  async fetchAlertAnalysis() {
    const { getAlertAnalysis, servers } = this.props;
    const { user } = servers;
    const query: any = {
      offset: 0,
      limit: 10000,
      searchText: '',
      order: [['updatedAt', 'DESC']],
      totalCounts: 0,
      where: {
        ...{ alertAt: { $gte: formatDate(this.setDefaultPeriod()) } },
        ...(user.location.category === ECategoryType.BREWERY && { brewLocId: user.locationId }),
        ...(user.location.category === ECategoryType.DISTRIBUTOR && { distLocId: user.locationId }),
        ...(user.location.category === ECategoryType.RESTAURANT && { alertLocId: user.locationId }),
      },
    };

    if (!this.props.servers.alertAnalysis[0]) setTimeout(getAlertAnalysis(query), 1000);
  }

  private setDefaultPeriod() {
    let d = new Date();
    //d.setFullYear(d.getFullYear() - 1);
    d.setMonth(d.getMonth() - 6);
    return d;
  }

  setSessionLanguage() {
    const { setCurrentLanguage, apps, servers } = this.props;
    if (servers?.user) {
      if (servers.user?.language && servers.user?.language != apps.currentLanguage) {
        setCurrentLanguage(servers.user.language);
      }
    }
  }
  async fetchAlerts() {}

  async fetchSerials() {
    const self = this;
    const { servers } = this.props;
    const userCategory = getUserCategory(servers.user);
    if (userCategory == ECategoryType.DISTRIBUTOR || userCategory == ECategoryType.RESTAURANT) {
      let query = {};
      if (userCategory == ECategoryType.DISTRIBUTOR) {
        query = {
          ...DefaultQuery,
          // This will display the associated restaurants from this distributor user
          where: {
            $and: [
              { brewShipDistLocId: servers.user?.locationId },
              { distRecvAt: null },
              { distShipAt: null },
            ],
          },
          order: [['brewShipAt', 'ASC']],
          limit: 10000,
        };
      } else {
        query = {
          ...DefaultQuery,
          // This will display the associated restaurants from this distributor user
          where: {
            $or: [
              { brewShipRestLocId: servers.user?.locationId },
              { distShipRestLocId: servers.user?.locationId },
            ],
            restRecvAt: null,
            restSoldAt: null,
          },
          order: [
            ['distShipAt', 'ASC'],
            ['brewShipAt', 'ASC'],
          ],
          limit: 10000,
        };
      }

      const request = functions.httpsCallable('getSerials');
      const response = await request({
        query: query,
      });
      const { data } = response;
      console.log(data.objects);
      self.setState({
        serialsList: data.objects,
      });
    }
  }

  public render(): JSX.Element {
    const {
      servers: { isRequesting, isGetRequesting, user },
      auth: { isRequesting: isAuthRequesting },
    } = this.props;

    const requestInProgress = isRequesting || isGetRequesting || isAuthRequesting || !isOk(user);
    if (user) this.setSessionLanguage();

    return (
      <ColoredBackground style={{ padding: '25px' }}>
        {requestInProgress ? <CircularLoading /> : this.getContents()}
      </ColoredBackground>
    );
  }

  protected getContents() {
    const {
      apps: { currentLanguage: lang },
      classes,
      servers: { user, newNotificationsCount, alertsCount },
    } = this.props;

    const menus = getMenuItems(user)?.filter((menu) => menu);
    if (!isOk(menus)) return;

    return (
      <>
        {this.getSummaryPopup()}
        <div className={classes.toolbar} />
        <Grid container justify='flex-start' alignItems='center' spacing={3}>
          {menus.map((menuItem, i) => {
            let badgeCount;
            if (menuItem.itemName === 'unhandledAlerts') {
              // TODO [SAKE-BC] Use getAlertCountByStatus API after MINAMI deploys it
              if (isOk(alertsCount)) {
                badgeCount = alertsCount.UNPROCESSED;
              }
            } else if (
              menuItem.itemName === 'notificationList' ||
              menuItem.itemName === 'notificationHistory'
            ) {
              badgeCount = newNotificationsCount;
            }
            return (
              <Grid className={classes.gridItem} item key={hash(`grid-item-${i}`)}>
                <CardButton
                  iconType={menuItem.icon}
                  onClick={() => this.handleMenuClick(menuItem)}
                  badgeCount={badgeCount}
                  theme={menuItem.cardButtonTheme}
                >
                  {i18n[menuItem.itemName][lang]}
                </CardButton>
              </Grid>
            );
          })}
        </Grid>
      </>
    );
  }

  protected handleMenuClick(menuItem: PageConfig) {
    const { history, setCurrentPage } = this.props;
    // Always set the current page to null on menu click!
    setCurrentPage(null);
    gotoPage(menuItem, history);
  }

  protected getSectionNames(name: string): JSX.Element {
    return (
      <>
        <Icon type='right2' color='primary' size='xx-small' />
        <TextDisplay
          display='inline'
          style={{ paddingLeft: '5px', paddingBottom: '5px', paddingTop: '40px' }}
        >
          {name}
        </TextDisplay>
      </>
    );
  }

  protected getSummaryPopup(): JSX.Element {
    const { showSummaryPopup, serialsList } = this.state;
    const {
      apps: { currentLanguage: lang },
      servers: { user, alertsUrgentCount, allLocations },
    } = this.props;

    const userCategory = getUserCategory(user);
    const dist = userCategory == ECategoryType.DISTRIBUTOR;
    if (userCategory == ECategoryType.DISTRIBUTOR || userCategory == ECategoryType.RESTAURANT) {
      return (
        <DahsboardSummaryPopup
          onClose={() => this.toggleSummaryPopup()}
          lang={lang}
          showPopup={showSummaryPopup}
          dist={dist}
          onConfirmationButtonClick={() => this.toggleSummaryPopup()}
          serialsList={serialsList ? serialsList : []}
          alerts={alertsUrgentCount ? alertsUrgentCount : []}
          locations={allLocations}
        />
      );
    } else return;
  }

  protected toggleSummaryPopup() {
    const { showSummaryPopup } = this.state;
    this.setState({
      showSummaryPopup: !showSummaryPopup,
    });
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  auth: IStateAuth;
  servers: IStateServers;
  classes: any;
  history?: History<LocationState>;
}

interface State {
  showSummaryPopup: boolean;
  serialsList: any;
}

export interface IDispatchProps {
  initAuth: () => void;
  getNewNotificationCount: (userId: any) => void;
  setCurrentPage: (pageId: AllPages) => void;
  getAlertsCount: (query: IQuery) => void;
  getAlertAnalysis: (query: IQuery) => any;
  getAlertsUrgentCount: (query: IQuery) => void;
  getLocations: (query: IQuery) => void;
  registerLoginHistory: () => void;
  setCurrentLanguage: (language: any) => void;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  auth: state.auth,
  servers: state.servers,
});

const mapDispatchToProps: IDispatchProps = {
  initAuth,
  getNewNotificationCount,
  setCurrentPage,
  getAlertsCount,
  getAlertAnalysis,
  getAlertsUrgentCount,
  getLocations,
  registerLoginHistory,
  setCurrentLanguage,
};

const myStyles = (theme: Theme): StyleRules => ({
  toolbar: { paddingTop: '68px' },
  gridItem: { padding: '10px' },
});
//setAlertsQuery,
//getAlerts,

export const MainMenu = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(MainMenuClass);
