import { CircularLoading } from '@container/components/circular-loading';
import { AddTempRestaurantUser } from '@container/pages/add-temp-restaurant-user';
import { IStateApps } from '@models/state-apps';
import { IStateAuth } from '@models/state-auth';
import { IStateServers } from '@models/state-servers';
import { Login } from '@old-components/login';
import { SnackbarMessage } from '@old-components/snackbar';
import { initAuth } from '@redux/actions/authActions';
import { IStoreState } from '@redux/reducers';
import { AppRoutePaths } from '@utils/app-route-paths';
import { isOk } from '@utils/is-ok';
import { History, LocationState } from 'history';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Layout } from '../layout';
import { LayoutPC } from '../layout-pc';

class InitializeClass extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
  }

  public componentDidMount() {
    this.props.initAuth();
  }

  public render(): JSX.Element {
    const {
      auth: { isRequesting: isAuthRequesting, user },
    } = this.props;
    const isLoggedIn = !isAuthRequesting && isOk(user);

    if (isAuthRequesting) {
      return <CircularLoading />;
    }
    if (!isLoggedIn)
      return (
        <>
          <Login />
          <SnackbarMessage />
        </>
      );

    return (
      <Switch>
        <Route
          exact
          path={AppRoutePaths['add-temp-restaurant-user'].path}
          component={AddTempRestaurantUser}
        />
        <Route path={'/pc/*'} component={LayoutPC} />
        <Route exact component={Layout} />
      </Switch>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  auth: IStateAuth;
  history?: History<LocationState>;
  classes: any;
}

export interface IDispatchProps {
  initAuth: () => void;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
  auth: state.auth,
});

const mapDispatchToProps: IDispatchProps = {
  initAuth,
};

export const Initialize = compose(connect(mapStateToProps, mapDispatchToProps))(
  withRouter(InitializeClass),
);
