import { i18n } from '@i18n/lang';
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Collapse,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { StyleRules, Theme, withStyles } from '@material-ui/core/styles';
import {
  ExitToApp as ExitToAppIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  Redo as RedoIcon,
  Settings as SettingsIcon,
  Translate as TranslateIcon,
} from '@material-ui/icons';
import { ECategoryType } from '@models/category-type';
import { IStateApps } from '@models/state-apps';
import { IStateAuth } from '@models/state-auth';
import { IStateServers } from '@models/state-servers';
import { TSupportedLanguages } from '@models/supported-languages';
import { initializeApps, setCurrentLanguage } from '@redux/actions/appsActions';
import { logout } from '@redux/actions/authActions';
import { initializeServers, registerUserLang } from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import appLanguages from '@utils/app-languages';
import { AppRoutePaths } from '@utils/app-route-paths';
import {
  LANGUAGE_CHINESE,
  LANGUAGE_CHINESE_TRADITIONAL,
  LANGUAGE_ENGLISH,
  LANGUAGE_JAPANESE,
  LANGUAGE_KOREAN,
  LANGUAGE_THAI,
  LANGUAGE_VIETAMESE,
} from '@utils/common';
import { getUserCategory, isUserAdmin } from '@utils/get-user-category';
import { isOk } from '@utils/is-ok';
import { Icon } from '@visual/icon';
import { TextDisplay } from '@visual/text-display';
import { History, LocationState } from 'history';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import userEnv from 'userEnv';
import styles from './style.scss';
import { SystemSettingsDialog } from './SystemSettingsDialog';

const storage = window.localStorage;

class HeaderClass extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      index: this.getCurrentIndex(),
      anchorEl: null,
      mouseOverButton: false,
      mouseOverMenu: false,
      langOpen: false,
      openSystemSettings: false,
    };
  }

  async setLanguage(lang) {
    const { servers, registerUserLang } = this.props;
    let user = servers.user;
    user.language = lang;
    console.log('language setting');
    await registerUserLang(user);
  }

  public render() {
    const { apps, servers, classes, auth, setCurrentLanguage } = this.props;
    const { anchorEl, mouseOverButton, mouseOverMenu, langOpen, openSystemSettings } = this.state;
    const lang = apps.currentLanguage;
    const open = mouseOverButton || mouseOverMenu;
    const progress = <LinearProgress color='secondary' />;
    const { user } = servers;
    const isAdmin = isUserAdmin(user);
    const userCategory = getUserCategory(user);
    let appManualUrl;
    switch (userCategory) {
      case ECategoryType.BREWERY:
      case ECategoryType.ADMIN:
        appManualUrl = userEnv.appManualBreweryUrl;
        break;
      case ECategoryType.DISTRIBUTOR:
        appManualUrl = userEnv.appManualDistributorUrl;
        break;
      case ECategoryType.RESTAURANT:
        appManualUrl = userEnv.appManualRestaurantUrl;
        break;
    }

    return (
      <>
        <div className={styles.header}>
          {auth.isRequesting ? progress : ''}
          <Toolbar>
            <div className={classes.container}>
              <div className={classes.logoContainer}>
                <Icon type='logo' color='primary' size='xx-large' onClick={() => this.gotoMenu()} />
                {this.getPageTitle()}
              </div>
              <div>
                <Tooltip
                  title={
                    user ? (
                      <>
                        <Typography variant='body2'>{`${appLanguages.name[lang]}: ${user.name}`}</Typography>
                        <Typography variant='body2'>{`${appLanguages.email[lang]}: ${user.email}`}</Typography>
                      </>
                    ) : (
                      ''
                    )
                  }
                  aria-label='account'
                >
                  <div
                    className={classes.settingsButton}
                    aria-controls={open ? 'account-list' : null}
                    aria-haspopup='true'
                    onClick={(e) => this.handleAccountClick(e)}
                    onMouseLeave={() => this.handleLeaveAccountButton()}
                  >
                    <SettingsIcon style={{ color: '#dbb26e' }} />
                  </div>
                </Tooltip>

                <Menu
                  id='account-list'
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => this.handleAccountClose()}
                  MenuListProps={{
                    onMouseEnter: () => this.handleEnterAccountMenu(),
                    onMouseLeave: () => this.handleLeaveAccountMenu(),
                  }}
                >
                  <MenuItem disabled onClick={() => null}>
                    <Card className={classes.cardRoot}>
                      <CardHeader
                        avatar={
                          <Avatar
                            className={classes.userAvatar}
                            alt={user ? user.name : ''}
                            src={user ? user.imageUrl : ''}
                          />
                        }
                        title={user ? user.name : ''}
                        subheader={
                          user ? (
                            <Box mt={1} ml={1}>
                              {`${user.email}`}
                            </Box>
                          ) : (
                            <></>
                          )
                        }
                      />
                    </Card>
                  </MenuItem>
                  <List>
                    {/* 言語設定 */}
                    <ListItem button onClick={() => this.handleClickLangMenu()}>
                      <ListItemIcon>
                        <TranslateIcon />
                      </ListItemIcon>
                      <ListItemText primary={appLanguages.languageSetting[lang]} />
                      {langOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItem>
                    <Collapse addEndListener={() => null} in={langOpen}>
                      <List>
                        <ListItem
                          button
                          onClick={() => {
                            setCurrentLanguage(LANGUAGE_ENGLISH);
                            this.setLanguage(LANGUAGE_ENGLISH);
                          }}
                          className={classes.nested}
                        >
                          <ListItemIcon>
                            {lang === LANGUAGE_ENGLISH ? (
                              <RadioButtonCheckedIcon />
                            ) : (
                              <RadioButtonUncheckedIcon />
                            )}
                          </ListItemIcon>
                          <ListItemText primary={appLanguages.langEnglish[LANGUAGE_ENGLISH]} />
                          {/* 'English' */}
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => {
                            setCurrentLanguage(LANGUAGE_JAPANESE);
                            this.setLanguage(LANGUAGE_JAPANESE);
                          }}
                          className={classes.nested}
                        >
                          <ListItemIcon>
                            {lang === LANGUAGE_JAPANESE ? (
                              <RadioButtonCheckedIcon />
                            ) : (
                              <RadioButtonUncheckedIcon />
                            )}
                          </ListItemIcon>
                          <ListItemText primary={appLanguages.langJapanese[LANGUAGE_JAPANESE]} />
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => {
                            setCurrentLanguage(LANGUAGE_CHINESE);
                            this.setLanguage(LANGUAGE_CHINESE);
                          }}
                          className={classes.nested}
                        >
                          <ListItemIcon>
                            {lang === LANGUAGE_CHINESE ? (
                              <RadioButtonCheckedIcon />
                            ) : (
                              <RadioButtonUncheckedIcon />
                            )}
                          </ListItemIcon>
                          <ListItemText primary={appLanguages.langChinese[LANGUAGE_CHINESE]} />
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => {
                            setCurrentLanguage(LANGUAGE_CHINESE_TRADITIONAL);
                            this.setLanguage(LANGUAGE_CHINESE_TRADITIONAL);
                          }}
                          className={classes.nested}
                        >
                          <ListItemIcon>
                            {lang === LANGUAGE_CHINESE_TRADITIONAL ? (
                              <RadioButtonCheckedIcon />
                            ) : (
                              <RadioButtonUncheckedIcon />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={appLanguages.langCantonese[LANGUAGE_CHINESE_TRADITIONAL]}
                          />
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => {
                            setCurrentLanguage(LANGUAGE_KOREAN);
                            this.setLanguage(LANGUAGE_KOREAN);
                          }}
                          className={classes.nested}
                        >
                          <ListItemIcon>
                            {lang === LANGUAGE_KOREAN ? (
                              <RadioButtonCheckedIcon />
                            ) : (
                              <RadioButtonUncheckedIcon />
                            )}
                          </ListItemIcon>
                          <ListItemText primary={appLanguages.langKorean[LANGUAGE_KOREAN]} />
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => {
                            setCurrentLanguage(LANGUAGE_THAI);
                            this.setLanguage(LANGUAGE_THAI);
                          }}
                          className={classes.nested}
                        >
                          <ListItemIcon>
                            {lang === LANGUAGE_THAI ? (
                              <RadioButtonCheckedIcon />
                            ) : (
                              <RadioButtonUncheckedIcon />
                            )}
                          </ListItemIcon>
                          <ListItemText primary={appLanguages.langThai[LANGUAGE_THAI]} />
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => {
                            setCurrentLanguage(LANGUAGE_VIETAMESE);
                            this.setLanguage(LANGUAGE_VIETAMESE);
                          }}
                          className={classes.nested}
                        >
                          <ListItemIcon>
                            {lang === LANGUAGE_VIETAMESE ? (
                              <RadioButtonCheckedIcon />
                            ) : (
                              <RadioButtonUncheckedIcon />
                            )}
                          </ListItemIcon>
                          <ListItemText primary={appLanguages.langVietnamese[LANGUAGE_VIETAMESE]} />
                        </ListItem>
                      </List>
                    </Collapse>
                    <Divider />
                    {/* 流通アプリへのリンク */}
                    <ListItem
                      button
                      onClick={() => setTimeout(() => window.open(appManualUrl, '__black'), 1)}
                    >
                      <ListItemIcon>
                        <RedoIcon />
                      </ListItemIcon>
                      <ListItemText primary={appLanguages.openAppManual[lang]} />
                    </ListItem>
                    {/* システム設定（管理者のみ） */}
                    {isAdmin ? (
                      <ListItem button onClick={() => this.setState({ openSystemSettings: true })}>
                        <ListItemIcon>
                          <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary={appLanguages.systemSettings[lang]} />
                      </ListItem>
                    ) : (
                      <></>
                    )}
                    {/* ログアウト */}
                    <ListItem button onClick={() => this.handleLogout()}>
                      <ListItemIcon>
                        <ExitToAppIcon />
                      </ListItemIcon>
                      <ListItemText primary={appLanguages.logout[lang]} />
                    </ListItem>
                  </List>
                </Menu>
              </div>
            </div>
          </Toolbar>
        </div>
        {openSystemSettings ? (
          <SystemSettingsDialog
            open={openSystemSettings}
            handleClose={() => this.setState({ openSystemSettings: false })}
          />
        ) : (
          <></>
        )}
      </>
    );
  }

  protected getPageTitle() {
    const {
      apps,
      apps: { currentLanguage: lang },
      classes,
    } = this.props;
    if (!isOk(apps?.currentPage)) return;
    let pageTitle;

    try {
      pageTitle = i18n[AppRoutePaths[apps?.currentPage].name][lang];
      return (
        <div className={classes.title}>
          <TextDisplay color='primary' size='large'>{`｜${pageTitle}`}</TextDisplay>
        </div>
      );
    } catch {
      return;
    }
  }

  protected gotoMenu() {
    const { history } = this.props;
    history.push(AppRoutePaths['main-menu'].link);
  }

  protected getUserTypes() {
    const { servers } = this.props;
    const { user } = servers;
    if (!user) return { isAdmin: false, isBrewery: false, isRestaurant: false };
    const userCategory = getUserCategory(user);
    // ADMIN and BREWERY users have the same access
    const isAdmin = isUserAdmin(user);
    const isBrewery = userCategory === ECategoryType.BREWERY;
    const isRestaurant = userCategory === ECategoryType.RESTAURANT;
    return { isAdmin, isBrewery, isRestaurant };
  }

  protected getCurrentIndex() {
    const { rootDir } = userEnv;
    const { history } = this.props;
    const path = history.location.pathname;
    if (path === `${rootDir}dashboard`) return 0;
    if (path === `${rootDir}section`) return 1;
    if (path === `${rootDir}chat`) return 2;
    if (path.match(new RegExp(`${rootDir}master.*`))) return 3;
    return 0;
  }

  protected handleChangeIndex(newValue) {
    const { history } = this.props;
    const { rootDir } = userEnv;
    this.setState({ index: newValue });
    window.scrollTo(0, 0);
    switch (newValue) {
      case 0:
        return history.push(`${rootDir}dashboard`);
      case 1:
        return history.push(`${rootDir}section`);
      case 2:
        return history.push(`${rootDir}chat`);
      case 3:
        return history.push(`${rootDir}master`);
      default:
        return history.push(`${rootDir}`);
    }
  }

  protected handleAccountClick(e) {
    this.setState({ mouseOverButton: true, anchorEl: e.currentTarget });
  }

  protected handleAccountMouseOver(e) {
    setTimeout(() => this.handleAccountClick(e), 300);
  }

  protected handleAccountClose() {
    this.setState({ mouseOverButton: false, mouseOverMenu: false });
  }

  protected handleLeaveAccountButton() {
    setTimeout(() => this.setState({ mouseOverButton: false }), 100);
  }

  protected handleEnterAccountMenu() {
    this.setState({ mouseOverMenu: true });
  }

  protected handleLeaveAccountMenu() {
    setTimeout(() => this.setState({ mouseOverMenu: false }), 100);
  }

  protected handleClickLangMenu() {
    const { langOpen } = this.state;
    this.setState({ langOpen: !langOpen });
  }

  protected handleLogout() {
    const { logout, initializeApps, initializeServers } = this.props;
    this.handleChangeIndex(-1);
    setTimeout(() => initializeApps(), 10);
    setTimeout(() => initializeServers(), 10);
    setTimeout(() => storage.clear(), 50);
    setTimeout(() => logout(), 100);
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  auth: IStateAuth;
  apps: IStateApps;
  servers: IStateServers;
  classes: Classes;
  history: History<LocationState>;
}

export interface IDispatchProps {
  setCurrentLanguage: (lang: TSupportedLanguages) => void;
  logout: () => void;
  initializeApps: () => void;
  initializeServers: () => void;
  registerUserLang: (user: any) => void;
}

interface State {
  index: number;
  anchorEl: any;
  mouseOverButton: boolean;
  mouseOverMenu: boolean;
  langOpen: boolean;
  openSystemSettings: boolean;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  auth: state.auth,
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps: IDispatchProps = {
  setCurrentLanguage,
  logout,
  initializeApps,
  initializeServers,
  registerUserLang,
};

const myStyles = (theme: Theme): StyleRules => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: { marginRight: theme.spacing(2) },
  badge: { top: '50%', right: -3 },
  cardRoot: {},
  logoAvatar: {
    cursor: 'pointer',
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  userAvatar: {
    cursor: 'pointer',
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    paddingLeft: '5px',
  },
  container: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  settingsButton: {
    height: '45px',
    width: '45px',
    backgroundColor: '#0f2540',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
});

interface Classes {
  appBar: string;
  menuButton: string;
  badge: string;
  cardRoot: string;
  logoAvatar: string;
  userAvatar: string;
  nested: string;
  logoContainer: string;
  title: string;
  container: string;
  settingsButton: string;
}

export const Header = compose<any>(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(HeaderClass));
