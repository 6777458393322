import React, { CSSProperties } from 'react';
import styles from './style.scss';

export class Icon extends React.Component<Props> {
  public static defaultProps: Partial<Props> = {
    isCircular: false,
    size: 'medium',
    color: 'default',
    circularBackgroundColor: 'dark3',
    display: 'block',
  };

  public render(): JSX.Element {
    const { style, onClick } = this.props;
    return <div className={this.getClassNames()} style={style} onClick={onClick} />;
  }

  protected getClassNames() {
    const { color, display, size, isCircular, type, circularBackgroundColor, onClick } = this.props;
    return [
      styles.icon,
      styles[type],
      styles[`size-${size}`],
      styles[`color-${color}`],
      styles[`display-${display}`],
      isCircular && styles.circular,
      onClick && styles.clickable,
      isCircular &&
        circularBackgroundColor &&
        styles[`circular-bg-color-${circularBackgroundColor}`],
    ]
      .filter((style) => style)
      .join(' ');
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IDispatchProps {
  onClick?: () => void;
}

export interface IStateProps {
  type: IconTypes;
  size?:
    | 'xx-small'
    | 'x-small'
    | 'small'
    | 'medium'
    | 'large'
    | 'x-large'
    | 'xx-large'
    | 'xxx-large';
  isCircular?: boolean;
  circularBackgroundColor?: 'dark3' | 'dark2' | 'secondary-dark1';
  color?:
    | 'black'
    | 'primary'
    | 'default'
    | 'secondary-dark1'
    | 'secondary-dark2'
    | 'dark'
    | 'dark0'
    | 'dark4'
    | 'white';
  style?: CSSProperties;
  lat?: number;
  lng?: number;
  text?: string;
  display?: 'block' | 'inline';
}

export type IconColors = 'black' | 'primary' | 'default' | 'secondary-dark1' | 'dark';
export type IconTypes =
  | 'notice'
  | 'distribution'
  | 'monitor'
  | 'orderscan'
  | 'history'
  | 'task'
  | 'chat'
  | 'dashboard'
  | 'customize'
  | 'report'
  | 'brewery'
  | 'brand'
  | 'distributor'
  | 'restaurant'
  | 'order'
  | 'camera'
  | 'menu'
  | 'close'
  | 'reload'
  | 'bottle'
  | 'edit'
  | 'plus'
  | 'top'
  | 'left'
  | 'right'
  | 'left2'
  | 'right2'
  | 'search'
  | 'filter'
  | 'map'
  | 'consumer'
  | 'check'
  | 'info'
  | 'question'
  | 'instock'
  | 'shipment'
  | 'list'
  | 'system'
  | 'r-inspection'
  | 'i-permission'
  | 'i-start'
  | 'i-history'
  | 'down'
  | 'calender'
  | 'language'
  | 'd-manage'
  | 'order2'
  | 'r-manage'
  | 'marketing'
  | 'master'
  | 'logo'
  | 'clock'
  | 'sorting'
  | 'charts';
