import { i18n } from '@i18n/lang';
import { TSupportedLanguages } from '@models/supported-languages';
import { DEBOUNCE_TIMEOUT, LANGUAGE_DEFAULT } from '@utils/common';
import { Button } from '@visual/button';
import { InputText } from '@visual/input-text';
import { NotificationDetailPopup } from '@visual/notification-detail-popup';
import { TextDisplay } from '@visual/text-display';
import React from 'react';
import { debounce } from 'throttle-debounce';
import styles from './style.scss';

export class NotificationRegister extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = {
    lang: LANGUAGE_DEFAULT,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      showConfirmationPopup: false,
      title: '',
      content: '',
    };
  }

  public render(): JSX.Element {
    const { lang } = this.props;
    const { title, content } = this.state;

    return (
      <>
        <div className={styles.notificationRegister}>
          <div className={styles.pageTitle}>
            <TextDisplay weight='bold' size='large'>
              {i18n.sendNotificationTitle[lang]}
            </TextDisplay>
          </div>
          <div className={styles.title}>
            <InputText
              value={title}
              theme='secondary'
              size='full'
              onChange={(title) => this.handleTitleChange(title)}
              placeholder={i18n.inputTitle[lang]}
            />
          </div>
          <div className={styles.content}>
            <InputText
              style={{ height: '330px' }}
              theme='secondary'
              type='textarea'
              size='full'
              value={content}
              onChange={(content) => this.handleContentChange(content)}
              placeholder={i18n.inputBody[lang]}
            />
          </div>
          <div>
            <Button color='status2' onClick={() => this.toggleSendPopup()}>
              {i18n.runSend[lang]}
            </Button>
          </div>
        </div>
        {this.getNotificationDetailPopup()}
      </>
    );
  }

  protected handleTitleChange(title: string | number) {
    this.setState({
      title: String(title),
    });
  }

  protected handleContentChange(content: string | number) {
    this.setState({
      content: String(content),
    });
  }

  protected getNotificationDetailPopup(): JSX.Element {
    const { showConfirmationPopup, title, content } = this.state;
    const { lang } = this.props;
    return (
      <NotificationDetailPopup
        lang={lang}
        showPopup={showConfirmationPopup}
        title={title}
        onClose={() => this.toggleSendPopup()}
        onSendClick={debounce(DEBOUNCE_TIMEOUT, true, () => this.handleOnSendClick())}
        onCancelClick={() => this.toggleSendPopup()}
      >
        {content}
      </NotificationDetailPopup>
    );
  }

  protected handleOnSendClick() {
    const { title, content } = this.state;
    const { onSendButtonClick } = this.props;
    if (!onSendButtonClick) return;
    this.toggleSendPopup();
    onSendButtonClick(title, content);
  }

  protected toggleSendPopup() {
    const { showConfirmationPopup } = this.state;
    this.setState({
      showConfirmationPopup: !showConfirmationPopup,
    });
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  lang?: TSupportedLanguages;
}

export interface IDispatchProps {
  onSendButtonClick?: (title: string, content: string) => void;
}

interface State {
  title?: string;
  content?: string;
  showConfirmationPopup?: boolean;
}
