import { i18n } from '@i18n/lang';
import { Button, Grid, Hidden, IconButton, Tooltip } from '@material-ui/core';
import { CloudDownload as CloudDownloadIcon, Refresh as RefreshIcon } from '@material-ui/icons';
import { IBrand } from '@models/brand';
import { IQuery } from '@models/query';
import { IStateApps } from '@models/state-apps';
import { IStateServers } from '@models/state-servers';
import { openBrandEditDialog } from '@redux/actions/appsActions';
import { getBrandLocations, getMasterBrands } from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import appLanguages from '@utils/app-languages';
import { exportToCSV } from '@utils/common';
import { functions } from '@utils/firebase';
import { formatString } from '@utils/format-string';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

class BrandsCustomToolbarClass extends React.PureComponent<Props> {
  handleClickEdit() {
    const { openBrandEditDialog, getBrandLocations } = this.props;
    getBrandLocations();
    openBrandEditDialog();
  }

  handleClickReload() {
    const { servers, getMasterBrands } = this.props;
    getMasterBrands(servers.brandsQuery);
  }

  formatData(obj) {
    const { apps } = this.props;
    const lang = apps.currentLanguage;
    return {
      [appLanguages.publicId[lang]]: obj.publicId || '',
      [appLanguages.name[lang]]: obj.name || '',
      [appLanguages.url[lang]]: obj.url || '',
      [appLanguages.imageUrl[lang]]: obj.imageUrl || '',
      [appLanguages.logoImageUrl[lang]]: obj.logoImageUrl || '',
      [appLanguages.breweryName[lang]]: (obj.location && obj.location.name) || '',
      [appLanguages.breweryPublicId[lang]]: (obj.location && obj.location.publicId) || '',
      // 日本語
      [appLanguages.intro[lang]]: obj.intro || '',
      [appLanguages.tastingNote1[lang]]: obj.tastingNote1 || '',
      [appLanguages.tastingNote2[lang]]: obj.tastingNote2 || '',
      [appLanguages.paringFood[lang]]: obj.paringFood || '',
      [appLanguages.servingSuggestion[lang]]: obj.servingSuggestion || '',
      [appLanguages.riceSpecific[lang]]: obj.riceSpecific || '',
      [appLanguages.bottleSize[lang]]: obj.bottleSize || '',
      [appLanguages.alcohol[lang]]: obj.alcohol || '',
      [appLanguages.description[lang]]: obj.description || '',
      // 英語
      [appLanguages.enName[lang]]: obj.enName || '',
      [appLanguages.enIntro[lang]]: obj.enIntro || '',
      [appLanguages.enTastingNote1[lang]]: obj.enTastingNote1 || '',
      [appLanguages.enTastingNote2[lang]]: obj.enTastingNote2 || '',
      [appLanguages.enParingFood[lang]]: obj.enParingFood || '',
      [appLanguages.enServingSuggestion[lang]]: obj.enServingSuggestion || '',
      [appLanguages.enRiceSpecific[lang]]: obj.enRiceSpecific || '',
      [appLanguages.enBottleSize[lang]]: obj.enBottleSize || '',
      [appLanguages.enAlcohol[lang]]: obj.enAlcohol || '',
      [appLanguages.enDescription[lang]]: obj.enDescription || '',
      // 中国語
      [appLanguages.cnName[lang]]: obj.cnName || '',
      [appLanguages.cnIntro[lang]]: obj.cnIntro || '',
      [appLanguages.cnTastingNote1[lang]]: obj.cnTastingNote1 || '',
      [appLanguages.cnTastingNote2[lang]]: obj.cnTastingNote2 || '',
      [appLanguages.cnParingFood[lang]]: obj.cnParingFood || '',
      [appLanguages.cnServingSuggestion[lang]]: obj.cnServingSuggestion || '',
      [appLanguages.cnRiceSpecific[lang]]: obj.cnRiceSpecific || '',
      [appLanguages.cnBottleSize[lang]]: obj.cnBottleSize || '',
      [appLanguages.cnAlcohol[lang]]: obj.cnAlcohol || '',
      [appLanguages.cnDescription[lang]]: obj.cnDescription || '',
      // 香港語
      [appLanguages.hkName[lang]]: obj.hkName || '',
      [appLanguages.hkIntro[lang]]: obj.hkIntro || '',
      [appLanguages.hkTastingNote1[lang]]: obj.hkTastingNote1 || '',
      [appLanguages.hkTastingNote2[lang]]: obj.hkTastingNote2 || '',
      [appLanguages.hkParingFood[lang]]: obj.hkParingFood || '',
      [appLanguages.hkServingSuggestion[lang]]: obj.hkServingSuggestion || '',
      [appLanguages.hkRiceSpecific[lang]]: obj.hkRiceSpecific || '',
      [appLanguages.hkBottleSize[lang]]: obj.hkBottleSize || '',
      [appLanguages.hkAlcohol[lang]]: obj.hkAlcohol || '',
      [appLanguages.hkDescription[lang]]: obj.hkDescription || '',
      // 韓国語
      [appLanguages.krName[lang]]: obj.krName || '',
      [appLanguages.krIntro[lang]]: obj.krIntro || '',
      [appLanguages.krTastingNote1[lang]]: obj.krTastingNote1 || '',
      [appLanguages.krTastingNote2[lang]]: obj.krTastingNote2 || '',
      [appLanguages.krParingFood[lang]]: obj.krParingFood || '',
      [appLanguages.krServingSuggestion[lang]]: obj.krServingSuggestion || '',
      [appLanguages.krRiceSpecific[lang]]: obj.krRiceSpecific || '',
      [appLanguages.krBottleSize[lang]]: obj.krBottleSize || '',
      [appLanguages.krAlcohol[lang]]: obj.krAlcohol || '',
      [appLanguages.krDescription[lang]]: obj.krDescription || '',
      // タイ語
      [appLanguages.thName[lang]]: obj.thName || '',
      [appLanguages.thIntro[lang]]: obj.thIntro || '',
      [appLanguages.thTastingNote1[lang]]: obj.thTastingNote1 || '',
      [appLanguages.thTastingNote2[lang]]: obj.thTastingNote2 || '',
      [appLanguages.thParingFood[lang]]: obj.thParingFood || '',
      [appLanguages.thServingSuggestion[lang]]: obj.thServingSuggestion || '',
      [appLanguages.thRiceSpecific[lang]]: obj.thRiceSpecific || '',
      [appLanguages.thBottleSize[lang]]: obj.thBottleSize || '',
      [appLanguages.thAlcohol[lang]]: obj.thAlcohol || '',
      [appLanguages.thDescription[lang]]: obj.thDescription || '',
      // ベトナム語
      [appLanguages.viName[lang]]: obj.viName || '',
      [appLanguages.viIntro[lang]]: obj.viIntro || '',
      [appLanguages.viTastingNote1[lang]]: obj.viTastingNote1 || '',
      [appLanguages.viTastingNote2[lang]]: obj.viTastingNote2 || '',
      [appLanguages.viParingFood[lang]]: obj.viParingFood || '',
      [appLanguages.viServingSuggestion[lang]]: obj.viServingSuggestion || '',
      [appLanguages.viRiceSpecific[lang]]: obj.viRiceSpecific || '',
      [appLanguages.viBottleSize[lang]]: obj.viBottleSize || '',
      [appLanguages.viAlcohol[lang]]: obj.viAlcohol || '',
      [appLanguages.viDescription[lang]]: obj.viDescription || '',
    };
  }

  handleClickDownload() {
    const prefix = 'brands';
    const { servers } = this.props;
    const totalCounts = servers.brandsTotalCounts;
    const limit = 500;
    let index = 0;
    const request = functions.httpsCallable('getMasterBrands');
    for (let offset = 0; offset < totalCounts; offset += 500) {
      const query = { ...servers.brandsQuery, offset, limit };
      request(query)
        // eslint-disable-next-line no-loop-func
        .then((result) =>
          exportToCSV(
            `${prefix}_${index}_`,
            result.data.objects.map((o) => this.formatData(o)),
          ),
        )
        .catch((error) => window.console.error(error));
      index += 1;
    }
  }

  render() {
    const {
      apps: { currentLanguage: lang },
    } = this.props;

    const gridListView = (
      <>
        <Grid item>
          <Tooltip title={appLanguages.csvDownload[lang]}>
            <IconButton onClick={() => this.handleClickDownload()}>
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title={appLanguages.reload[lang]}>
            <IconButton onClick={() => this.handleClickReload()}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title={appLanguages.addNewData[lang]}>
            <Button
              color='primary'
              disableElevation
              variant='contained'
              onClick={() => this.handleClickEdit()}
            >
              {formatString(i18n.addNewMasterInfo[lang], { data: i18n.product[lang] })}
            </Button>
          </Tooltip>
        </Grid>
      </>
    );

    return (
      <>
        <Hidden mdUp>
          <Grid container justify='center' alignContent='center' alignItems='center' wrap='nowrap'>
            {gridListView}
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Grid
            container
            justify='flex-end'
            alignContent='center'
            alignItems='center'
            wrap='nowrap'
          >
            {gridListView}
          </Grid>
        </Hidden>
      </>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
}

export interface IDispatchProps {
  openBrandEditDialog: (brandObj?: IBrand) => void;
  getMasterBrands: (query: IQuery) => void;
  getBrandLocations: () => void;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps: IDispatchProps = {
  openBrandEditDialog,
  getMasterBrands,
  getBrandLocations,
};

export const BrandsCustomToolbar = compose(connect(mapStateToProps, mapDispatchToProps))(
  BrandsCustomToolbarClass,
);
