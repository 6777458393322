import { i18n } from '@i18n/lang';
import { Dialog, DialogContent } from '@material-ui/core';
import { TSupportedLanguages } from '@models/supported-languages';
import { LANGUAGE_DEFAULT } from '@utils/common';
import { Logo } from '@visual/logo';
import { TextDisplay } from '@visual/text-display';
import React from 'react';
import styles from './style.scss';

export class BrandPopup extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    lang: LANGUAGE_DEFAULT,
    showBrandPopup: false,
  };

  public render(): JSX.Element {
    const { photo, showBrandPopup, onClose } = this.props;
    const showImage = photo ? (
      <img className={styles.brandImage} src={photo} />
    ) : (
      <Logo type='no-image' />
    );
    return (
      <Dialog open={showBrandPopup} onClose={onClose}>
        <DialogContent style={{ padding: 0 }}>
          <div className={styles.brandPopup}>
            <div className={styles.brandImageContainer}>{showImage}</div>
            <div className={styles.details}>{this.getDetails()}</div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  protected getDetails(): JSX.Element {
    const {
      brandName,
      breweryName,
      createDate,
      status,
      lang,
      onBrandNameClick,
      onBreweryNameClick,
    } = this.props;
    return (
      <>
        {brandName && this.getTextDisplay(i18n.brand[lang], brandName, onBrandNameClick)}
        {breweryName && this.getTextDisplay(i18n.brewery[lang], breweryName, onBreweryNameClick)}
        {createDate && this.getTextDisplay(i18n.dataCreateDate[lang], createDate)}
        {status && this.getTextDisplay(i18n.status[lang], status)}
      </>
    );
  }

  protected getTextDisplay(label: string, value: string, onClick?: () => void) {
    return (
      <div className={styles.row}>
        <span className={styles.cell}>
          <TextDisplay color='dark'>{label}</TextDisplay>
        </span>
        <span className={styles.cell}>
          <TextDisplay color={onClick ? 'secondary' : 'dark'} onClick={onClick}>
            {value}
          </TextDisplay>
        </span>
      </div>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  photo?: string;
  brandName: string;
  breweryName: string;
  createDate?: string;
  status?: string;
  lang?: TSupportedLanguages;
  showBrandPopup?: boolean;
}

export interface IDispatchProps {
  onBrandNameClick?: () => void;
  onBreweryNameClick?: () => void;
  onClose?: () => void;
}
