import { CircularLoading } from '@container/components/circular-loading';
import {
  OwnProps as RestaurantStatusTableRowOwnProps,
  RestaurantStatusTableRow,
} from '@container/components/restaurant-status-table-row';
import { i18n } from '@i18n/lang';
import { ECategoryType } from '@models/category-type';
import { IQuery } from '@models/query';
import { IStateApps } from '@models/state-apps';
import { IStateServers } from '@models/state-servers';
import { getMasterLocations, setLocationsQuery } from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import { DefaultQuery } from '@utils/default-query';
import { isOk } from '@utils/is-ok';
import { ColumnHeader, RestaurantTable } from '@visual/restaurant-table';
import { RestaurantTableRow } from '@visual/restaurant-tablerow';
import { History, LocationState } from 'history';
import hash from 'object-hash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

class RestaurantStatusTableClass extends React.PureComponent<Props> {
  public componentDidMount() {
    const initialQuery = { ...DefaultQuery };
    initialQuery.where = { category: ECategoryType.RESTAURANT };
    const { setLocationsQuery, getMasterLocations } = this.props;
    setLocationsQuery(initialQuery);
    getMasterLocations(initialQuery);
  }

  public render(): JSX.Element {
    const {
      apps: { currentLanguage: lang },
      servers: { locations, isRequesting, isGetRequesting },
    } = this.props;

    if (isRequesting || isGetRequesting) return <CircularLoading />;

    if (!isOk(locations)) return <React.Fragment />;

    const columnHeaders: ColumnHeader[] = [
      { id: 0, label: i18n.status[lang], sortable: true },
      { id: 1, label: i18n.name[lang], sortable: true },
      { id: 2, label: i18n.id[lang], sortable: true },
      { id: 3, label: i18n.flagShip[lang], sortable: true },
      { id: 4, label: i18n.sellingInfo[lang], sortable: true },
      { id: 5, label: i18n.lastInspectionDate[lang], sortable: true },
      { id: 6, label: i18n.link[lang] },
    ];

    const restaurantTableRows: React.ReactElement<RestaurantTableRow>[] = locations.map(
      (location, i) => {
        const props: RestaurantStatusTableRowOwnProps = {
          restaurantLocatonInfo: location,
          isFirstRow: i === 0,
          isLastRow: i === locations.length - 1,
        };
        return <RestaurantStatusTableRow key={hash(i)} {...props} />;
      },
    );

    return <RestaurantTable columnHeaders={columnHeaders}>{restaurantTableRows}</RestaurantTable>;
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  history?: History<LocationState>;
  classes: any;
}

export interface IDispatchProps {
  sortRows?: (columnKey: string) => void;
  getMasterLocations: (query: IQuery) => void;
  setLocationsQuery: (query: IQuery) => void;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps: IDispatchProps = {
  getMasterLocations,
  setLocationsQuery,
};

export const RestaurantStatusTable = compose(connect(mapStateToProps, mapDispatchToProps))(
  RestaurantStatusTableClass,
);
