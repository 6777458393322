import { i18n } from '@i18n/lang';
import { Dialog, DialogContent } from '@material-ui/core';
import { EAlertStatus } from '@models/alert-status-type';
import { TSupportedLanguages } from '@models/supported-languages';
import { LANGUAGE_DEFAULT } from '@utils/common';
import { isOk } from '@utils/is-ok';
import { Button, Props as ButtonProps } from '@visual/button';
import { InputText } from '@visual/input-text';
import { TextDisplay } from '@visual/text-display';
import React from 'react';
import styles from './style.scss';

export class AlertResolutionPopup extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = {
    lang: LANGUAGE_DEFAULT,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      assigneeName: '',
      cancelButtonLabel: props.cancelButtonLabel || i18n.cancel[props.lang],
      commentPlaceHolder: this.getPlaceHolder(),
    };
  }

  public render(): JSX.Element {
    const { showPopup, onClose, registerButtonProps, onCancelClick } = this.props;
    const { assigneeName, commentPlaceHolder, cancelButtonLabel } = this.state;
    return (
      <Dialog open={showPopup} onClose={() => onClose()}>
        <DialogContent style={{ padding: 0 }}>
          <div className={styles.alertResolvePopup}>
            <div className={styles.title}>
              <TextDisplay color='black' weight='bold' align='center'>
                {this.getTitle()}
              </TextDisplay>
            </div>
            <div className={styles.textArea}>
              <InputText
                size='full'
                placeholder={commentPlaceHolder}
                onChange={(val: string) => this.handleOnCommentChange(val)}
                value={assigneeName}
              ></InputText>
            </div>
            <div className={styles.buttonContainer}>
              <div className={styles.button}>
                <Button
                  {...registerButtonProps}
                  enabled={isOk(assigneeName)}
                  onClick={() => this.handleRegisterClick()}
                />
              </div>
              <div className={styles.button}>
                <Button
                  theme='secondary'
                  onClick={() => {
                    this.setState({ assigneeName: '' }, () => onCancelClick());
                  }}
                >
                  {cancelButtonLabel}
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  protected getPlaceHolder() {
    const { lang } = this.props;
    return i18n.assigneeNameField[lang];
  }

  protected getTitle() {
    const { lang, nextStatus } = this.props;
    if (nextStatus === EAlertStatus.UNPROCESSABLE) {
      return i18n.toUnprocessable[lang];
    } else {
      return i18n.convertAlertTo[lang];
    }
  }

  protected handleOnCommentChange(val: string) {
    const { onCommentChange } = this.props;
    this.setState({
      assigneeName: val,
    });
    if (!onCommentChange) return;
  }

  protected handleRegisterClick() {
    const { assigneeName } = this.state;
    const { onRegisterClick } = this.props;
    if (!onRegisterClick) return;
    onRegisterClick(assigneeName);
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  nextStatus: EAlertStatus;
  registerButtonProps: Partial<ButtonProps>;
  cancelButtonLabel?: string;
  lang?: TSupportedLanguages;
  showPopup?: boolean;
  assigneeName?: any;
}

export interface IDispatchProps {
  onCommentChange?: (assigneeName: string) => void;
  onRegisterClick?: (assigneeName: string) => void;
  onClose?: () => void;
  onCancelClick?: () => void;
}

interface State {
  assigneeName?: string;
  commentPlaceHolder?: string;
  cancelButtonLabel?: string;
}
