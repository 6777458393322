import { i18n } from '@i18n/lang';
import { Dialog, DialogContent } from '@material-ui/core';
import { ListPages } from '@models/all-pages';
import { TSupportedLanguages } from '@models/supported-languages';
import { LANGUAGE_DEFAULT } from '@utils/common';
import { isOk } from '@utils/is-ok';
import { Button } from '@visual/button';
import { DatePicker } from '@visual/date-picker';
import { DateRangePicker } from '@visual/date-range-picker';
import { IconTypes } from '@visual/icon';
import { InputText } from '@visual/input-text';
import { SelectBox } from '@visual/select-box';
import { TextDisplay } from '@visual/text-display';
import hash from 'object-hash';
import React from 'react';
import styles from './style.scss';

export class SearchFilterPopup extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    if (!isOk(props.items)) return;
    this.state = {
      selectedValues: props.items
        .map((item) => {
          return { id: item.id, selectedValue: item.selectedValue };
        })
        .reduce((res, item) => {
          res[item.id] = item.selectedValue;
          return res;
        }, {}),
    };
  }

  public static defaultProps: Partial<Props> = {
    isOpen: false,
    lang: LANGUAGE_DEFAULT,
  };

  public render(): JSX.Element {
    const { isOpen, onClose, lang } = this.props;
    return (
      <Dialog open={isOpen} onClose={onClose}>
        <DialogContent style={{ padding: 0 }} className={styles.content}>
          <div className={styles.content}>
            <div className={styles.title}>
              <TextDisplay weight='bold' color='dark' align='center' display='block'>
                {i18n.searchConditions[lang]}
              </TextDisplay>
            </div>
            {this.getSearchFields().map((item, i) => (
              <div key={hash(i)} className={styles.item}>
                {item}
              </div>
            ))}
            {this.getButtons()}
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  protected getButtons(): JSX.Element {
    const { lang, onSearch, onClose } = this.props;
    const { selectedValues } = this.state;
    return (
      <div className={styles.buttons}>
        <div className={styles.button}>
          <Button size='full' onClick={onSearch && (() => onSearch(selectedValues))}>
            {i18n.searchWithAboveConditions[lang]}
          </Button>
        </div>
        <div className={styles.button}>
          <Button size='full' theme='secondary' onClick={onClose}>
            {i18n.cancel[lang]}
          </Button>
        </div>
      </div>
    );
  }

  protected getSearchFields(): JSX.Element[] {
    const { items } = this.props;
    if (!isOk(items)) return;
    return items.map((item, i) => {
      switch (item.type) {
        case 'date': {
          return (
            <DatePicker
              value={item.selectedValue as Date}
              key={hash(i)}
              size='full'
              onChange={(newValue) => this.handleInputChanges(newValue, item.id)}
            />
          );
        }
        case 'text': {
          return (
            <InputText
              placeholder={item.placeholder}
              value={item.selectedValue as string | number}
              key={hash(i)}
              size='full'
              onChange={(newValue) => this.handleInputChanges(newValue, item.id)}
            />
          );
        }
        case 'select': {
          return (
            <SelectBox
              placeholder={item.placeholder}
              items={item.selectData}
              key={hash(i)}
              size='full'
              selectedValue={item.selectedValue && (item.selectedValue as number | string)}
              onChange={(value) => this.handleSelectBoxChanges(value, item.id)}
              prefixIcon={item.selectectBoxPrefixIcon}
            />
          );
        }
        case 'date-range': {
          return (
            <DateRangePicker
              isPlaceholderLabel
              placeholder={item.placeholder}
              size='full'
              key={hash(i)}
              value={item.selectedValue as [Date, Date]}
              onChange={(date) => this.handleInputChanges(date, item.id)}
            />
          );
        }
      }
    });
  }

  protected handleInputChanges(newValue: SearchFilterValidValues, id: number) {
    const { selectedValues } = this.state;
    selectedValues[id] = newValue;
    this.setState({
      selectedValues,
    });
  }

  protected handleSelectBoxChanges(newValue: string, id: number) {
    const { selectedValues } = this.state;
    selectedValues[id] = newValue;
    this.setState({
      selectedValues,
    });
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  items?: Item[];
  isOpen?: boolean;
  lang?: TSupportedLanguages;
}

export interface Item {
  id: number;
  fieldName: string;
  type: 'text' | 'select' | 'date' | 'date-range';
  selectedValue?: SearchFilterValidValues;
  isExternalField?: boolean;
  isExact?: boolean;
  selectData?: {
    id: number | string;
    text: string;
  }[];
  placeholder?: string;
  selectectBoxPrefixIcon?: IconTypes;
}

interface State {
  selectedValues: SelectedValues;
}

export type SelectedValues = { [key: number]: SearchFilterValidValues };

export type SearchFilterValidValues = string | number | Date | [Date, Date];

export interface IDispatchProps {
  onSearch: (selectedValues: SelectedValues) => void;
  onClose: () => void;
}

export type SearchFilterStateValues = { [key in ListPages]: SelectedValues };
