import { TextDisplay } from '@visual/text-display';
import * as React from 'react';
import styles from './style.scss';

export class Button extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    size: 'fit',
    display: 'inline',
    theme: 'primary',
    enabled: true,
    tsize: 'medium',
  };

  public render(): JSX.Element {
    const { theme, children, onClick, enabled, tsize } = this.props;
    return (
      <>
        <button className={this.getClassNames()} onClick={onClick} disabled={!enabled}>
          <TextDisplay
            weight={theme === 'primary' ? 'bold' : 'default'}
            color={theme === 'secondary' ? 'dark' : 'default'}
            align='center'
            size={tsize ? tsize : 'medium'}
          >
            {children}
          </TextDisplay>
        </button>
      </>
    );
  }

  protected getClassNames() {
    const { size, theme, display, color } = this.props;
    return [
      styles.button,
      styles[`size-${size}`],
      styles[`theme-${theme}`],
      styles[`display-${display}`],
      color && styles[`custom-color-${color}`],
    ]
      .filter((style) => style)
      .join(' ');
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  children?: string;
  tsize?: 'xx-small' | 'x-small' | 'small' | 'medium' | 'large' | 'x-large';
  theme?: 'primary' | 'secondary';
  size: 'fit' | 'full' | 'round';
  display: 'inline' | 'block';
  enabled?: boolean;
  color?: 'status1' | 'status2' | 'status3' | 'status4' | 'dark0' | 'secondary-light4';
}

export interface IDispatchProps {
  onClick?: (e: React.MouseEvent) => void;
}
