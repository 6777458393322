import { i18n } from '@i18n/lang';
import { EAlertStatus } from '@models/alert-status-type';
import { TSupportedLanguages } from '@models/supported-languages';
import { LANGUAGE_DEFAULT } from '@utils/common';
import { formatString } from '@utils/format-string';
import { getAlertStatusText } from '@utils/get-alert-status-text';
import { Props as ButtonProps } from '@visual/button';
import { getNextStatus } from './get-next-status';

export function getAlertResolutionButtonProps(
  status: EAlertStatus,
  lang: TSupportedLanguages = LANGUAGE_DEFAULT,
): Partial<ButtonProps> {
  if (!status) return;
  const nextStatus: EAlertStatus = getNextStatus(status);
  const buttonProps: Partial<ButtonProps> = {};

  switch (status) {
    case EAlertStatus.UNPROCESSED:
    case EAlertStatus.PROCESSING:
      buttonProps.children = formatString(i18n.convertTo[lang], {
        field: getAlertStatusText(nextStatus, lang),
      });
      buttonProps.children = i18n.process2[lang];
      if (lang === 'en') buttonProps.tsize = 'small';
      buttonProps.color = 'status3';
      break;
    /*case EAlertStatus.PROCESSING:
      buttonProps.children = formatString(i18n.convertTo2[lang], { field: i18n.process[lang] });
      buttonProps.color = 'status2';
      break;*/
    case EAlertStatus.PROCESSED:
    case EAlertStatus.APPROVED:
    // buttonProps.children = formatString(i18n.convertTo[lang], {
    //   field: getAlertStatusText(nextStatus, lang),
    // });
    // buttonProps.color = 'status3';
    // break;
    /*case EAlertStatus.APPROVED:
      buttonProps.children = formatString(i18n.convertTo[lang], {
        field: getAlertStatusText(nextStatus, lang),
      });
      buttonProps.color = 'dark0';
      break;*/
    case EAlertStatus.UNPROCESSABLE:
      buttonProps.children = i18n.revert[lang];
      buttonProps.children = 'Revert';
      buttonProps.color = 'status1';
  }
  buttonProps.size = 'round';
  return buttonProps;
}
