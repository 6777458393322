import React, { InputHTMLAttributes } from 'react';
import styles from './style.scss';

export class InputText extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = {
    type: 'text',
    value: '',
    size: 'fit',
    theme: 'primary',
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  public render(): JSX.Element {
    const { placeholder, style } = this.props;
    const { value } = this.state;

    const inputProps: InputHTMLAttributes<HTMLTextAreaElement | HTMLInputElement> = {
      placeholder,
      value: value || '',
      className: this.getClassNames(),
      onChange: (e) => this.handleOnChange(e.currentTarget.value),
    };

    return this.props.type === 'text' ? (
      <input style={style} type='text' {...inputProps} />
    ) : (
      <textarea style={style} {...inputProps} />
    );
  }

  protected getClassNames() {
    const { type, size, theme } = this.props;
    return [styles.input, styles[theme], styles[size], styles[type]]
      .filter((style) => style)
      .join(' ');
  }

  protected handleOnChange(newValue) {
    const { onChange } = this.props;
    this.setState({
      value: newValue,
    });
    if (!onChange) return;
    onChange(newValue);
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  placeholder?: string;
  type?: 'text' | 'textarea';
  value?: number | string;
  size?: 'full' | 'fit';
  style?: React.CSSProperties;
  theme?: 'primary' | 'secondary';
}

export interface IDispatchProps {
  onChange?: (newValue: number | string) => void;
}

interface State {
  value?: number | string;
}
