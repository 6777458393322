export enum EAlertStatus {
  // 未指定
  UNSPECIFIED = 0,
  // 未対応 urgent
  UNPROCESSED = 1,
  // 対応中
  PROCESSING = 2,
  // 解決済 resolved
  PROCESSED = 3,
  // 承認済
  APPROVED = 4,
  // 未解決 unresolved
  UNPROCESSABLE = 5,
}

export type TAlertStatus =
  | 'unprocessed'
  | 'processing'
  | 'processed'
  | 'approved'
  | 'unspecified'
  | 'unprocessable';
