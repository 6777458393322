import { i18n } from '@i18n/lang';
import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { EAlertStatus } from '@models/alert-status-type';
import { EAlert } from '@models/alert-type';
import { ECategoryType } from '@models/category-type';
import { TSupportedLanguages } from '@models/supported-languages';
import { getAutocomplete, LANGUAGE_DEFAULT } from '@utils/common';
import { AlertStatusPill } from '@visual/alert-status-pill';
import { Icon, IconTypes } from '@visual/icon';
import { Item as SelectBoxItem } from '@visual/select-box';
import { TextDisplay } from '@visual/text-display';
import React from 'react';
import styles from './style.scss';

class MonitoringFilterClass extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = {
    lang: LANGUAGE_DEFAULT,
  };

  public changeSearch;

  constructor(props: Props) {
    super(props);
    this.state = {
      searchText: '',
      changeStatusCommentText: '',
      targetAlertStatus: -999,
      selectedAlertStatus: props.selectedAlertStatus,
      selectedSortItem: props.selectedSortItem,
      selectedAlerts: props.selectedAlerts,
      isMultiSelect: false,
      showUpdateStatusPopup: false,
      showAddFilterPopup: false,
      advanceFilterSelectedLocationId:
        (props.selectedAlertFilterOptions &&
          props.selectedAlertFilterOptions.filterSelectedLocationId) ||
        0,
      advanceFilterSerialText:
        (props.selectedAlertFilterOptions && props.selectedAlertFilterOptions.filterSerialText) ||
        '',
      advanceFilterAlertType:
        (props.selectedAlertFilterOptions && props.selectedAlertFilterOptions.filterAlertType) ||
        null,
      advancedFilterRegion:
        (props.selectedAlertFilterOptions && props.selectedAlertFilterOptions.filterRegion) || null,

      selectedAdvanceOptions: {
        filterSerialText:
          (props.selectedAlertFilterOptions && props.selectedAlertFilterOptions.filterSerialText) ||
          '',
        filterSelectedLocationId:
          (props.selectedAlertFilterOptions &&
            props.selectedAlertFilterOptions.filterSelectedLocationId) ||
          0,
        filterRegion:
          (props.selectedAlertFilterOptions && props.selectedAlertFilterOptions.filterRegion) ||
          null,
        filterAlertType:
          (props.selectedAlertFilterOptions && props.selectedAlertFilterOptions.filterAlertType) ||
          null,
      },
    };
    this.changeSearch = debounce(this.props.onChangeSearchBox, 500);
  }

  handleChangeSearchBoxValue = (e) => {
    const val = e.target.value;
    const re = /^[0-9\b]+$/;
    if (val === '' || re.test(val)) {
      this.setState({ searchText: val }, () => {
        const { searchText } = this.state;
        this.changeSearch(searchText);
      });
    }
  };

  clearFilters() {
    const { onClearAllButtonClick } = this.props;
    this.setState(
      {
        searchText: '',
        selectedSortItem: 0,
        advanceFilterSelectedLocationId: 0,
        advanceFilterSerialText: '',
        advanceFilterAlertType: null,
        advancedFilterRegion: null,
        selectedAdvanceOptions: {
          filterSerialText: '',
          filterSelectedLocationId: 0,
          filterRegion: null,
          filterAlertType: null,
        },
      },
      () => {
        onClearAllButtonClick();
      },
    );
  }

  toggleMultiSelect() {
    const { isMultiSelect } = this.state;
    this.setState({
      isMultiSelect: !isMultiSelect,
    });
    const { onMultiSelectToggle } = this.props;

    if (!onMultiSelectToggle) return;
    onMultiSelectToggle(!isMultiSelect);
  }

  toggleUpdateStatusPopup() {
    const { showUpdateStatusPopup } = this.state;
    this.setState({
      showUpdateStatusPopup: !showUpdateStatusPopup,
    });
  }

  toggleAddFilterPopup() {
    const { showAddFilterPopup, selectedAdvanceOptions } = this.state;
    this.setState({
      showAddFilterPopup: !showAddFilterPopup,
      advanceFilterSelectedLocationId: selectedAdvanceOptions.filterSelectedLocationId,
      advanceFilterSerialText: selectedAdvanceOptions.filterSerialText,
      advanceFilterAlertType: selectedAdvanceOptions.filterAlertType,
      advancedFilterRegion: selectedAdvanceOptions.filterRegion,
    });
  }

  handleChangeLocation(v) {
    this.setState({ advanceFilterSelectedLocationId: v ? v.id : 0 });
  }

  addFilterConfirmButton() {
    const { showAddFilterPopup, selectedAdvanceOptions } = this.state;
    const { onApplyAdvanceFilterClick } = this.props;
    this.setState({ showAddFilterPopup: !showAddFilterPopup }, () => {
      const whereClause = this.setFilterOptions();
      onApplyAdvanceFilterClick(whereClause, selectedAdvanceOptions);
    });
  }

  setFilterOptions() {
    const { selectedAdvanceOptions } = this.state;

    const { locationList } = this.props;

    let whereClause = {};

    if (selectedAdvanceOptions.filterSerialText) {
      whereClause['serialCode'] = { $like: `%${selectedAdvanceOptions.filterSerialText}%` };
    }

    switch (selectedAdvanceOptions.filterAlertType) {
      case 1:
        // Delayed Receiving Scan Alert - distributor  Delayed Receiving Scan, restaurant  Delayed Receiving Scan
        whereClause['type'] = EAlert.SCAN_DATE;
        break;
      case 2:
        // Inventory Quality Alert - distributor inventory, restaurant inventory
        whereClause['type'] = [EAlert.QUALITY, EAlert.DIST_QUALITY];

        break;
      case 3:
        // GPS Alert - distributor receiving, distributor delivery, restaurant receiving, restaurant selling
        whereClause['type'] = [
          EAlert.ILLEGAL_ROUTE,
          EAlert.ILLEGAL_SELLING,
          EAlert.ILLEGAL_SHIPPING,
        ];
        break;
    }

    if (selectedAdvanceOptions.filterRegion && selectedAdvanceOptions.filterSelectedLocationId) {
      // both region and location is selected.
      whereClause['alertLocId'] = selectedAdvanceOptions.filterSelectedLocationId;
    } else if (selectedAdvanceOptions.filterSelectedLocationId) {
      // only location selected.
      whereClause['alertLocId'] = selectedAdvanceOptions.filterSelectedLocationId;
    } else if (selectedAdvanceOptions.filterRegion) {
      // only region selected.
      let locationIdByRegion = locationList.map((x) => {
        return x.id;
      });
      whereClause['alertLocId'] = locationIdByRegion;
    }

    return whereClause;
  }

  public render(): JSX.Element {
    const { selectedSortItem } = this.state;
    const { classes, lang, sortFields, userCategory } = this.props;
    const { searchText } = this.state;
    let sortedFieldsList =
      sortFields.length > 0 &&
      sortFields.map((item, i) => {
        return (
          <option key={i} value={item.id}>
            {item.text}
          </option>
        );
      }, this);

    return (
      <div className={classes.filterWrapperContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.searchBox}>
              <TextField
                type='text'
                variant='outlined'
                className={classes.formControl}
                fullWidth
                size='small'
                placeholder={i18n.searchBySerialnumber[lang]}
                value={searchText}
                onChange={this.handleChangeSearchBoxValue}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  classes: { notchedOutline: classes.noBorder },
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md='auto' xs='auto'>
          <div style={{ display: 'block', position: 'relative' }}>
            {this.getPrefixIcon()}
            <select
              className={this.getSelectBoxClassNames()}
              value={selectedSortItem}
              onChange={this.handleSelectedSortItemChange.bind(this)}
            >
              {sortedFieldsList}
            </select>
            {this.getSuffixArrowIcon()}
          </div>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md='auto' xs='auto'>
            {this.selectedAlertTypePill()}
            {this.selectedRegionsPill()}
            {this.selectedLocationsPill()}
          </Grid>
        </Grid>
        <Grid style={{ marginTop: '12px' }}>
          <Grid item md='auto' xs='auto'>
            {this.alertCountPill()}
            <div style={{ display: 'inline-block', float: 'right' }}>
              {this.addFilterButton()}
              {this.showAddFilterModal()}
              {this.alertClearAllButton()}
            </div>
          </Grid>
        </Grid>
        <Grid style={{ marginTop: '12px' }}>
          <Grid item md='auto' xs='auto'>
            {this.getAlertStatusPills()}
            <div style={{ display: 'inline-block', float: 'right' }}>
              {this.addCancelStatusUpdateButton()}
              {userCategory === ECategoryType.ADMIN || userCategory === ECategoryType.BREWERY
                ? this.addStatusUpdateButton()
                : ''}
              {this.showUpdateStatusModal()}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  protected getAlertStatusPills(): JSX.Element {
    const { selectedAlertStatus } = this.state;
    const { lang } = this.props;
    return (
      <div className={styles.alertStatusSubContainer}>
        <div className={styles.alertStatusPill}>
          <AlertStatusPill
            lang={lang}
            theme='toggle'
            type='unprocessed'
            isUnselected={!selectedAlertStatus.includes(EAlertStatus.UNPROCESSED)}
            onClick={() =>
              this.handleDoubleToggleAlertStatus(EAlertStatus.UNPROCESSED, EAlertStatus.PROCESSING)
            }
          />
        </div>
        {/*<div className={styles.alertStatusPill}>
          <AlertStatusPill
            lang={lang}
            theme='toggle'
            type='processing'
            isUnselected={!selectedAlertStatus.includes(EAlertStatus.PROCESSING)}
            onClick={() => this.handleToggleAlertStatus(EAlertStatus.PROCESSING)}
          />
    </div>*/}
        <div className={styles.alertStatusPill}>
          <AlertStatusPill
            lang={lang}
            theme='toggle'
            type='processed'
            isUnselected={!selectedAlertStatus.includes(EAlertStatus.PROCESSED)}
            onClick={() =>
              this.handleDoubleToggleAlertStatus(EAlertStatus.PROCESSED, EAlertStatus.APPROVED)
            }
          />
        </div>
        {/*<div className={styles.alertStatusPill}>
          <AlertStatusPill
            lang={lang}
            theme='toggle'
            type='approved'
            isUnselected={!selectedAlertStatus.includes(EAlertStatus.APPROVED)}
            onClick={() => this.handleToggleAlertStatus(EAlertStatus.APPROVED)}
          />
  </div>*/}
        <div className={styles.alertStatusPill}>
          <AlertStatusPill
            lang={lang}
            theme='toggle'
            type='unprocessable'
            isUnselected={!selectedAlertStatus.includes(EAlertStatus.UNPROCESSABLE)}
            onClick={() => this.handleToggleAlertStatus(EAlertStatus.UNPROCESSABLE)}
          />
        </div>
      </div>
    );
  }

  protected handleToggleAlertStatus(status: EAlertStatus) {
    const { selectedAlertStatus } = this.state;
    const { onAlertStatusToggle } = this.props;
    const newSelectedAlertStatus = [...selectedAlertStatus];
    if (newSelectedAlertStatus.includes(status)) {
      newSelectedAlertStatus.splice(newSelectedAlertStatus.indexOf(status), 1);
    } else {
      newSelectedAlertStatus.push(status);
    }

    this.setState({
      selectedAlertStatus: newSelectedAlertStatus,
    });

    if (!onAlertStatusToggle) return;
    onAlertStatusToggle(status);
  }

  protected handleDoubleToggleAlertStatus(status1: EAlertStatus, status2: EAlertStatus) {
    const { selectedAlertStatus } = this.state;
    const { onAlertStatusToggle } = this.props;
    const newSelectedAlertStatus = [...selectedAlertStatus];
    if (newSelectedAlertStatus.includes(status1)) {
      newSelectedAlertStatus.splice(newSelectedAlertStatus.indexOf(status1), 1);
      if (newSelectedAlertStatus.includes(status2)) {
        newSelectedAlertStatus.splice(newSelectedAlertStatus.indexOf(status2), 1);
      }
    } else {
      newSelectedAlertStatus.push(status1);
      if (!newSelectedAlertStatus.includes(status2)) {
        newSelectedAlertStatus.push(status2);
      }
    }

    this.setState({
      selectedAlertStatus: newSelectedAlertStatus,
    });
    if (!onAlertStatusToggle) return;
    onAlertStatusToggle(status1, status2);
  }

  protected selectedLocationsPill(): JSX.Element {
    const { selectedAdvanceOptions } = this.state;
    const { locationList } = this.props;
    const findLocationName = locationList.find(
      (x) => x.id === selectedAdvanceOptions.filterSelectedLocationId,
    );
    return (
      <>
        {selectedAdvanceOptions.filterSelectedLocationId > 0 && findLocationName ? (
          <div
            style={{
              marginTop: '10px',
              display: 'inline-block',
              marginRight: '5px',
            }}
          >
            <Chip
              style={{ backgroundColor: '#ccdaff', color: '#1d3c63', fontSize: '10px' }}
              label={findLocationName.name}
              size='small'
              onDelete={() => {
                this.setState(
                  {
                    advanceFilterSelectedLocationId: 0,
                    selectedAdvanceOptions: {
                      filterSerialText: selectedAdvanceOptions.filterSerialText,
                      filterSelectedLocationId: 0,
                      filterRegion: selectedAdvanceOptions.filterRegion,
                      filterAlertType: selectedAdvanceOptions.filterAlertType,
                    },
                  },
                  () => {
                    const { onApplyAdvanceFilterClick } = this.props;
                    const { selectedAdvanceOptions } = this.state;
                    const whereClause = this.setFilterOptions();
                    onApplyAdvanceFilterClick(whereClause, selectedAdvanceOptions);
                  },
                );
              }}
            />
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }

  protected selectedRegionsPill(): JSX.Element {
    const { selectedAdvanceOptions } = this.state;
    return (
      <>
        {selectedAdvanceOptions.filterRegion ? (
          <div
            style={{
              marginTop: '10px',
              display: 'inline-block',
              marginRight: '5px',
            }}
          >
            <Chip
              style={{
                backgroundColor: '#ccdaff',
                color: '#1d3c63',
                fontSize: '10px',
                padding: '0px 10px',
              }}
              label={this.getRegionName(selectedAdvanceOptions.filterRegion)}
              size='small'
              onDelete={() => {
                this.setState(
                  {
                    advancedFilterRegion: null,
                    selectedAdvanceOptions: {
                      filterSerialText: selectedAdvanceOptions.filterSerialText,
                      filterSelectedLocationId: selectedAdvanceOptions.filterSelectedLocationId,
                      filterRegion: null,
                      filterAlertType: selectedAdvanceOptions.filterAlertType,
                    },
                  },
                  () => {
                    const { onApplyAdvanceFilterClick } = this.props;
                    const { selectedAdvanceOptions } = this.state;
                    const whereClause = this.setFilterOptions();
                    onApplyAdvanceFilterClick(whereClause, selectedAdvanceOptions);
                  },
                );
              }}
            />
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }

  protected getRegionName(selectedRegion) {
    const { regionList } = this.props;
    const regionName = regionList.find((r) => r === selectedRegion);
    return regionName;
  }

  protected selectedAlertTypePill(): JSX.Element {
    const { selectedAdvanceOptions } = this.state;
    return (
      <>
        {selectedAdvanceOptions.filterAlertType > 0 ? (
          <div
            style={{
              marginTop: '10px',
              display: 'inline-block',
              marginRight: '5px',
            }}
          >
            <Chip
              style={{ backgroundColor: '#ccdaff', color: '#1d3c63', fontSize: '10px' }}
              label={this.getAlertType(selectedAdvanceOptions.filterAlertType)}
              size='small'
              onDelete={() => {
                this.setState(
                  {
                    advanceFilterAlertType: null,
                    selectedAdvanceOptions: {
                      filterSerialText: selectedAdvanceOptions.filterSerialText,
                      filterSelectedLocationId: selectedAdvanceOptions.filterSelectedLocationId,
                      filterRegion: selectedAdvanceOptions.filterRegion,
                      filterAlertType: null,
                    },
                  },
                  () => {
                    const { onApplyAdvanceFilterClick } = this.props;
                    const { selectedAdvanceOptions } = this.state;
                    const whereClause = this.setFilterOptions();
                    onApplyAdvanceFilterClick(whereClause, selectedAdvanceOptions);
                  },
                );
              }}
            />
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }

  protected getAlertType(type) {
    const { lang } = this.props;
    switch (type) {
      case 1:
        return `${i18n.receivingScanDelayAlert[lang]}`;
      case 2:
        return `${i18n.inventoryQualityAlert[lang]}`;
      case 3:
        return `${i18n.gpsAlert[lang]}`;
    }
  }

  protected alertCountPill(): JSX.Element {
    const { classes, alertsCount, lang } = this.props;
    return (
      <div className={classes.filterButtonWrapper}>
        <Chip
          label={`${alertsCount} ${i18n.unhandledAlerts[lang]}`}
          variant='outlined'
          size='small'
          className={classes.alertTotalCount}
        />
      </div>
    );
  }

  protected alertClearAllButton(): JSX.Element {
    const {
      searchText,
      advanceFilterSelectedLocationId,
      advanceFilterSerialText,
      advanceFilterAlertType,
      advancedFilterRegion,
    } = this.state;
    const { classes, lang } = this.props;
    return (
      <div className={classes.filterButtonWrapper} style={{ marginLeft: '5px' }}>
        <Button
          color='primary'
          disabled={
            searchText ||
            advanceFilterSelectedLocationId ||
            advanceFilterSerialText ||
            advancedFilterRegion ||
            advanceFilterAlertType
              ? false
              : true
          }
          variant='contained'
          size='small'
          onClick={() => this.clearFilters()}
          className={classes.buttonUi}
          classes={{ disabled: classes.disabledButton }}
          style={{ textTransform: 'capitalize', background: '#fdcdcd' }}
        >
          {i18n.clearAllButtonText[lang]}
        </Button>
      </div>
    );
  }

  protected addCancelStatusUpdateButton(): JSX.Element {
    const { isMultiSelect } = this.state;
    const { classes, lang } = this.props;
    return (
      <div className={classes.filterButtonWrapper}>
        <Button
          color='primary'
          // disabled={searchText ? true : false}
          variant='contained'
          size='small'
          onClick={() => this.toggleMultiSelect()}
          className={classes.buttonUi}
          classes={{ disabled: classes.disabledButton }}
          style={{ textTransform: 'capitalize', visibility: isMultiSelect ? 'visible' : 'hidden' }}
        >
          {i18n.cancel[lang]}
        </Button>
      </div>
    );
  }
  protected addStatusUpdateButton(): JSX.Element {
    const { isMultiSelect, selectedAlerts } = this.state;
    const { classes, lang } = this.props;

    // console.log('selectedAlerts', selectedAlerts);
    return (
      <div className={classes.filterButtonWrapper} style={{ marginLeft: '5px' }}>
        <Button
          color='primary'
          disabled={isMultiSelect && selectedAlerts?.length == 0 ? true : false}
          variant='contained'
          size='small'
          onClick={() =>
            isMultiSelect ? this.toggleUpdateStatusPopup() : this.toggleMultiSelect()
          }
          className={classes.buttonUi}
          classes={{ disabled: classes.disabledButton }}
          style={{ textTransform: 'capitalize' }}
        >
          {isMultiSelect
            ? i18n.addDoStatusUpdateButtonText[lang]
            : i18n.addStatusUpdateButtonText[lang]}
        </Button>
      </div>
    );
  }

  protected addFilterButton(): JSX.Element {
    const { searchText } = this.state;
    const { classes, lang } = this.props;
    return (
      <div className={classes.filterButtonWrapper}>
        <Button
          color='primary'
          disabled={searchText ? true : false}
          variant='contained'
          size='small'
          onClick={() => this.toggleAddFilterPopup()}
          className={classes.buttonUi}
          classes={{ disabled: classes.disabledButton }}
          style={{ textTransform: 'capitalize' }}
        >
          {i18n.addFilterButtonText[lang]}
        </Button>
      </div>
    );
  }

  protected showUpdateStatusModal(): JSX.Element {
    const { classes } = this.props;
    const { showUpdateStatusPopup } = this.state;
    return (
      <Dialog open={showUpdateStatusPopup} onClose={this.toggleUpdateStatusPopup}>
        <DialogContent style={{ padding: 0 }}>
          <div className={classes.addFilterPopup}>
            <div className={classes.filterPopupDetails}>{this.getUpdateStatusDetails()}</div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  protected showAddFilterModal(): JSX.Element {
    const { classes } = this.props;
    const { showAddFilterPopup } = this.state;
    return (
      <Dialog open={showAddFilterPopup} onClose={this.toggleAddFilterPopup}>
        <DialogContent style={{ padding: 0 }}>
          <div className={classes.addFilterPopup}>
            <div className={classes.filterPopupDetails}>{this.getDetails()}</div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  protected getUpdateStatusDetails(): JSX.Element {
    const { classes, lang, onExecuteRegisterAlertDetail } = this.props;
    const { changeStatusCommentText, targetAlertStatus, selectedAlerts } = this.state;

    return (
      <>
        <div className={classes.filterPopuRow}>
          <span className={classes.filterPopupCell}>
            <TextDisplay color='dark'>{`${i18n.alertStatusType[lang]}`}</TextDisplay>
          </span>
          <span className={classes.filterPopupCell}>
            <select
              className={classes.filterInputTypes}
              placeholder={`${i18n.filterPlaceHolderAlertStatus[lang]}`}
              value={targetAlertStatus}
              onChange={(event) => {
                const id = event.target.value;
                this.setState({ targetAlertStatus: JSON.parse(id) });
              }}
            >
              {this.renderItems('ALERT_STATUS_TYPES')}
            </select>
          </span>
        </div>
        <div className={classes.filterPopuRow}>
          <span className={classes.filterPopupCell}>
            <TextDisplay color='dark'>{i18n.assigneeNameField[lang]}</TextDisplay>
          </span>
          <span className={classes.filterPopupCell}>
            <TextField
              type='text'
              variant='outlined'
              className={classes.filterInputTypeText}
              fullWidth
              size='small'
              placeholder={i18n.leavingAComment[lang]}
              value={changeStatusCommentText}
              onChange={(e) => {
                const val = e.target.value;
                // const re = /^[0-9\b]+$/;
                // if (val === '' || re.test(val)) {
                this.setState({ changeStatusCommentText: val }, () => {});
                // }
              }}
              InputProps={{
                startAdornment: <InputAdornment position='start'></InputAdornment>,
                classes: { notchedOutline: classes.noBorder },
              }}
            />
          </span>
        </div>
        <div className={classes.modalFooter}>
          <div className={classes.filterPopuRow}>
            <div className={classes.filterPopupCell}>
              <Button
                disabled={changeStatusCommentText && targetAlertStatus > 0 ? false : true}
                color='primary'
                variant='contained'
                size='small'
                fullWidth
                className={classes.advanceFilterButtonUi}
                classes={{ disabled: classes.disabledButton }}
                style={{ textTransform: 'capitalize' }}
                onClick={() => {
                  console.log(
                    'updated',
                    selectedAlerts,
                    'to',
                    targetAlertStatus,
                    'comment',
                    changeStatusCommentText,
                  );

                  if (!onExecuteRegisterAlertDetail) return;
                  onExecuteRegisterAlertDetail(targetAlertStatus, changeStatusCommentText);

                  this.toggleUpdateStatusPopup();
                  this.toggleMultiSelect();

                  this.setState({ targetAlertStatus: 0 });
                  this.setState({ changeStatusCommentText: '' });
                }}
              >
                {i18n.addDoStatusUpdateButtonText[lang]}
              </Button>
            </div>
            <div className={classes.filterPopupCell}>
              <Button
                className={classes.advanceFilterButtonUi}
                classes={{ disabled: classes.disabledButton }}
                color='primary'
                variant='contained'
                size='small'
                fullWidth
                onClick={() => this.toggleUpdateStatusPopup()}
                style={{ textTransform: 'capitalize' }}
              >
                {i18n.cancel[lang]}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }

  protected getDetails(): JSX.Element {
    const { classes, userCategory, locationList, lang, onRegionSelect } = this.props;
    const {
      advanceFilterSerialText,
      advanceFilterSelectedLocationId,
      advanceFilterAlertType,
      advancedFilterRegion,
    } = this.state;

    const defaultLocValue = { id: 0, name: '' };
    let locValue = { ...defaultLocValue };
    const findSelectedLoc = locationList.find((x) => x.id === advanceFilterSelectedLocationId);
    if (findSelectedLoc) {
      locValue = findSelectedLoc;
    }
    return (
      <>
        <div className={classes.filterPopuRow}>
          <span className={classes.filterPopupCell}>
            <TextDisplay color='dark'>{`${i18n.alertType[lang]}`}</TextDisplay>
          </span>
          <span className={classes.filterPopupCell}>
            <select
              className={classes.filterInputTypes}
              placeholder={`${i18n.filterPlaceHolderAlertType[lang]}`}
              value={advanceFilterAlertType}
              onChange={(event) => {
                const id = event.target.value;
                this.setState({ advanceFilterAlertType: JSON.parse(id) });
              }}
            >
              {this.renderItems('ALERT_TYPES')}
            </select>
          </span>
        </div>
        {userCategory === ECategoryType.ADMIN || userCategory === ECategoryType.BREWERY ? (
          <div className={classes.filterPopuRow}>
            <span className={classes.filterPopupCell}>
              <TextDisplay color='dark'>{`${i18n.regionLabel[lang]}`}</TextDisplay>
            </span>
            <span className={classes.filterPopupCell}>
              <select
                className={classes.filterInputTypes}
                placeholder='Select region'
                value={advancedFilterRegion}
                onChange={(e) => {
                  const region = e.target.value;
                  this.setState(
                    { advancedFilterRegion: region, advanceFilterSelectedLocationId: 0 },
                    () => {
                      onRegionSelect(region);
                    },
                  );
                }}
              >
                {this.renderItems('ALERT_REGIONS')}
              </select>
            </span>
          </div>
        ) : (
          <></>
        )}
        {userCategory === ECategoryType.ADMIN ||
        userCategory === ECategoryType.BREWERY ||
        userCategory === ECategoryType.DISTRIBUTOR ? (
          <div className={classes.filterPopuRow}>
            <span className={classes.filterPopupCell}></span>
            <span className={classes.filterPopupCell}>
              {getAutocomplete(
                `${i18n.filterPlaceHolderLocationName[lang]}`,
                locValue,
                locationList,
                false,
                (e, v) => this.handleChangeLocation(v),
              )}
            </span>
          </div>
        ) : (
          <></>
        )}
        <div className={classes.filterPopuRow}>
          <span className={classes.filterPopupCell}>
            <TextDisplay color='dark'>{i18n.serialNo[lang]}</TextDisplay>
          </span>
          <span className={classes.filterPopupCell}>
            <TextField
              type='text'
              variant='outlined'
              className={classes.filterInputTypeText}
              fullWidth
              size='small'
              placeholder={i18n.searchBySerialnumber[lang]}
              value={advanceFilterSerialText}
              onChange={(e) => {
                const val = e.target.value;
                const re = /^[0-9\b]+$/;
                if (val === '' || re.test(val)) {
                  this.setState({ advanceFilterSerialText: val }, () => {});
                }
              }}
              InputProps={{
                startAdornment: <InputAdornment position='start'></InputAdornment>,
                classes: { notchedOutline: classes.noBorder },
              }}
            />
          </span>
        </div>
        <div className={classes.modalFooter}>
          <div className={classes.filterPopuRow}>
            <div className={classes.filterPopupCell}>
              <Button
                disabled={
                  advanceFilterSerialText ||
                  advanceFilterSelectedLocationId > 0 ||
                  advancedFilterRegion ||
                  advanceFilterAlertType > 0
                    ? false
                    : true
                }
                color='primary'
                variant='contained'
                size='small'
                fullWidth
                className={classes.advanceFilterButtonUi}
                classes={{ disabled: classes.disabledButton }}
                style={{ textTransform: 'capitalize' }}
                onClick={() => {
                  this.setState(
                    {
                      selectedAdvanceOptions: {
                        filterSerialText: advanceFilterSerialText,
                        filterSelectedLocationId: advanceFilterSelectedLocationId,
                        filterRegion: advancedFilterRegion,
                        filterAlertType: advanceFilterAlertType,
                      },
                    },
                    () => {
                      this.addFilterConfirmButton();
                    },
                  );
                }}
              >
                {i18n.addFilterButtonText[lang]}
              </Button>
            </div>
            <div className={classes.filterPopupCell}>
              <Button
                className={classes.advanceFilterButtonUi}
                classes={{ disabled: classes.disabledButton }}
                color='primary'
                variant='contained'
                size='small'
                fullWidth
                onClick={() => this.toggleAddFilterPopup()}
                style={{ textTransform: 'capitalize' }}
              >
                {i18n.cancel[lang]}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }

  protected renderItems(types: string): JSX.Element {
    const { regionList, lang } = this.props;

    let options = null;
    switch (types) {
      case 'ALERT_STATUS_TYPES':
        options = [
          { id: -999, text: `${i18n.filterPlaceHolderAlertStatus[lang]}` },
          { id: 1, text: `${i18n.unprocessed[lang]}` },
          { id: 3, text: `${i18n.processed[lang]}` },
          { id: 5, text: `${i18n.unprocessable[lang]}` },
        ];
        break;
      case 'ALERT_TYPES':
        options = [
          { id: -999, text: `${i18n.filterPlaceHolderAlertType[lang]}` },
          { id: 1, text: `${i18n.receivingScanDelayAlert[lang]}` },
          { id: 2, text: `${i18n.inventoryQualityAlert[lang]}` },
          { id: 3, text: `${i18n.gpsAlert[lang]}` },
        ];
        break;
      case 'ALERT_REGIONS':
        options = [{ id: '', text: `${i18n.filterPlaceHolderRegion[lang]}` }];
        regionList &&
          regionList.forEach((r) => {
            options.push({ id: r, text: r });
          });
        break;
    }
    if (options.length === 0) {
      return;
    }
    return options.map((option, i) => (
      <option key={i} value={option.id}>
        {option.text}
      </option>
    ));
  }

  protected handleSelectedSortItemChange(event) {
    const { onSelectedSortItemChange } = this.props;
    const { searchText } = this.state;
    const id = JSON.parse(event.target.value);
    this.setState({
      selectedSortItem: id,
    });
    if (!onSelectedSortItemChange) return;
    onSelectedSortItemChange(id, searchText);
  }

  protected getPrefixIcon(): JSX.Element {
    return (
      <span className={styles.prefixIcon}>
        <Icon type='sorting' color='primary' />
      </span>
    );
  }

  protected getSuffixArrowIcon(): JSX.Element {
    return (
      <span className={styles.suffixIcon}>
        <Icon type='down' color='primary' size='small' />
      </span>
    );
  }

  protected getSelectBoxClassNames() {
    return [styles.selectBox, styles['full'], styles['secondary'], styles.hasPrefixIcon]
      .filter((style) => style)
      .join(' ');
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  sortFields: SelectBoxItem[];
  selectedSortItem?: number;
  selectedAlerts: any;
  selectedAlertStatus?: EAlertStatus[];
  lang?: TSupportedLanguages;
  alertsCount?: number;
  classes: any;
  locationList: any;
  userCategory: number;
  regionList: any;
  prefixIcon?: IconTypes;
  theme?: 'primary' | 'secondary';
  selectedAlertFilterOptions: any;
}

export interface IDispatchProps {
  onSelectedSortItemChange: (sortItemId: number, serial: string) => void;
  onAlertStatusToggle: (status: EAlertStatus, status2?: EAlertStatus) => void;
  onMultiSelectToggle: (isMulti: boolean) => void;
  onExecuteRegisterAlertDetail: (status: EAlertStatus, comment?: string) => void;
  onChangeSearchBox: (serial: string) => void;
  onClearAllButtonClick: () => void;
  onApplyAdvanceFilterClick: (advanceFilters: any, selectedOptions: any) => void;
  onRegionSelect: (selectedRegion: string) => void;
}

interface State {
  selectedSortItem?: number;
  selectedAlerts: any;
  selectedAlertStatus?: EAlertStatus[];
  searchText: any;
  isMultiSelect: boolean;
  showUpdateStatusPopup: boolean;
  showAddFilterPopup: boolean;
  targetAlertStatus: number;
  changeStatusCommentText: string;
  advanceFilterSelectedLocationId: number;
  advanceFilterSerialText: string;
  advanceFilterAlertType: number;
  advancedFilterRegion: any;
  selectedAdvanceOptions: any;
}

const useStyles = (theme) => ({
  filterWrapperContainer: {
    padding: '15px 30px',
  },
  noBorder: {
    border: 'none',
  },
  searchBox: {
    background: '#ffffff',
    borderRadius: '50px',
    marginBottom: '10px',
  },
  formControl: {
    padding: '0px',
  },
  alertFiltersInnerContainer: {
    margin: '12px 0px 0px 5px',
  },
  alertTotalCount: {
    borderColor: '#ffffff',
    color: '#ffffff',
    padding: '10px 0px',
  },
  disabledButton: {
    backgroundColor: '#eaeaea !important',
    color: '#14355c !important',
  },
  filterButtonWrapper: {
    display: 'inline-block',
  },
  addFilterPopup: {
    backgroundColor: '#ffffff',
  },
  filterPopupDetails: {
    backgroundColor: '#f3fafb',
    borderRadius: '6px',
    width: '100%',
    minWidth: '295px',
    padding: '15px 15px 15px 15px',
  },
  filterPopuRow: {
    width: '100%',
    marginBottom: '15px',
  },
  filterPopupCell: {
    lineHeight: '2',
    display: 'block',
    marginBottom: '5px',
  },
  filterInputTypes: {
    width: '100%',
    padding: '10px',
    borderRadius: '50px',
  },
  filterInputTypeText: {
    borderRadius: '50px',
    background: '#fff',
    border: '1px solid #000',
  },
  buttonUi: {
    background: '#cbd9ff',
    borderRadius: '5px',
    padding: '0px 12px',
    marginBottom: '10px',
    color: '#14355c',
    '&:hover': {
      backgroundColor: '#cbd9ff',
    },
  },
  advanceFilterButtonUi: {
    background: '#cd9538',
    borderRadius: '25px',
    padding: '5px',
    marginBottom: '10px',
    color: '#fff',
    '&:hover': {
      filter: 'brightness(120%)',
      backgroundColor: '#cd9538',
    },
  },
  modalFooter: {
    marginTop: '25px',
  },
});

function debounce(func, wait) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export const MonitoringFilter = withStyles(useStyles)(MonitoringFilterClass);
