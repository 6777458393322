import WhiteLinearProgress from '@elements/WhiteLinearProgress';
import { Box, Grid, LinearProgress, Paper } from '@material-ui/core';
import { MuiThemeProvider, StyleRules, Theme, withStyles } from '@material-ui/core/styles';
import { AllPages } from '@models/all-pages';
import { ECategoryType } from '@models/category-type';
import { IQuery } from '@models/query';
import { IStateApps } from '@models/state-apps';
import { IStateServers } from '@models/state-servers';
import { openUserEditDialog, setCurrentPage, setEditUser } from '@redux/actions/appsActions';
import {
  delUser,
  getLocationRegions,
  getMasterUsers,
  getUserLocations,
  setUsersQuery,
} from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import appLanguages from '@utils/app-languages';
import {
  getDateRangePicker,
  getFilterButton,
  getSmallClearButton,
  getSmallSearchField,
  getSmallSelect,
  getSmallTextField,
  withConfirm,
} from '@utils/common';
import { getUserCategory } from '@utils/get-user-category';
import MUIDataTable from 'mui-datatables';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import userEnv from 'userEnv';
import {
  appMuiTheme,
  disableDisplayOpts,
  enableEmptyOpts,
  enableSortOnlyOpts,
  getActionsCustomBodyRender,
  getAvatarCircleCustomBodyRender,
  getSelectCustomBodyRender,
  getTimeAtCustomBodyRender,
  muiDataTableCommonOptions,
  rowDataToObj,
} from '../../../utils/mui-datatables-ops';
import UserEditDialog from './config/user-edit-dialog';
import { UsersCustomToolbar } from './config/users-custom-toolbar';

export interface IStateProps {
  classes: any;
  apps: IStateApps;
  servers: IStateServers;
  tempRegistrationEnabled?: boolean;
  locationList: any;
}

export interface IDispatchProps {
  confirm?: () => void;
  setEditUser: (any) => void;
  openUserEditDialog: (any) => void;
  getMasterUsers: (any) => void;
  setUsersQuery: (any) => void;
  delUser: (any) => void;
  getUserLocations: (any) => void;
  getLocationRegions: (query: IQuery) => void;
  setCurrentPage: (pageId: AllPages) => void;
}

export interface State {
  enableFilter: boolean;
  filterTimeout: any;
  initialized: boolean;
  filterCreatedAt?: any;
  filterUpdatedAt?: any;
  filterName?: any;
  filterEmail?: any;
  filterLocationCategory?: any;
  filterLocation?: any;
  filterCountry?: any;
  filterRole?: any;
  columns?: any[];
}

export type Props = IStateProps & IDispatchProps;

class UsersClass extends React.PureComponent<Props, State> {
  private initialFilters;

  public static defaultProps: Partial<Props> = {
    tempRegistrationEnabled: false,
  };

  handleOnDownload(buildHead, buildBody, columns, data) {
    const head = buildHead(columns);
    const body = buildBody(data);
    return `${head}${body}`;
  }

  constructor(props) {
    super(props);
    const { classes, apps } = this.props;
    const { categoryMapUserRole } = userEnv;
    const lang = apps.currentLanguage;
    // options
    const imageUrlOptions = {
      ...enableSortOnlyOpts,
      customBodyRender: (v) => getAvatarCircleCustomBodyRender(v, classes.smallAvatar),
    };
    const locationCategoryOptions = {
      ...enableSortOnlyOpts,
      customBodyRender: (v) => getSelectCustomBodyRender(categoryMapUserRole[lang], v),
    };
    const locationIdOptions = {
      ...enableSortOnlyOpts,
      customBodyRender: (v) => getSelectCustomBodyRender(this.getLocationOptionMap(), v),
    };
    const timeOptions = { ...enableSortOnlyOpts, customBodyRender: getTimeAtCustomBodyRender };
    const { isAdmin, isBrewery } = this.getUserTypes();
    this.initialFilters = {
      filterCreatedAt: [],
      filterUpdatedAt: [],
      filterName: '',
      filterEmail: '',
      filterCategory: '',
      filterLocationCategory: '',
      filterLocation: '',
      filterCountry: '',
      filterRole: '',
    };
    this.state = {
      enableFilter: false,
      filterTimeout: undefined,
      ...this.initialFilters,
      initialized: false,
      columns: [
        { name: 'id', label: appLanguages.id[lang], options: { ...disableDisplayOpts } },
        { name: 'uid', label: appLanguages.uid[lang], options: { ...disableDisplayOpts } },
        {
          name: 'password',
          label: appLanguages.password[lang],
          options: { ...disableDisplayOpts },
        },
        {
          name: 'appLanguages',
          label: appLanguages.language[lang],
          options: { ...disableDisplayOpts },
        },
        { name: 'company', label: appLanguages.company[lang], options: { ...disableDisplayOpts } },
        {
          name: 'locationId',
          label: appLanguages.locationId[lang],
          options: { ...disableDisplayOpts },
        },
        { name: 'imageUrl', label: appLanguages.image[lang], options: imageUrlOptions },
        { name: 'name', label: appLanguages.name[lang], options: { ...enableSortOnlyOpts } },
        { name: 'email', label: appLanguages.email[lang], options: { ...enableSortOnlyOpts } },
        {
          name: 'tempRegistration',
          label: appLanguages.tempRegistration[lang],
          options: { ...disableDisplayOpts },
        },
        {
          name: 'locationCategory',
          label: appLanguages.category[lang],
          options: locationCategoryOptions,
        },
        {
          name: 'locationId',
          label: appLanguages.belongLocation[lang],
          options: locationIdOptions,
        },
        {
          name: 'location.country',
          label: appLanguages.country[lang],
          options: isAdmin || isBrewery ? { ...enableSortOnlyOpts } : { ...disableDisplayOpts },
        },
        { name: 'createdAt', label: appLanguages.createdAt[lang], options: { ...timeOptions } },
        {
          name: 'updatedAt',
          label: appLanguages.updatedAt[lang],
          options: { ...timeOptions },
        },
        {
          name: 'creatorId',
          label: appLanguages.tempRegistration[lang],
          options: { ...disableDisplayOpts },
        },
        {
          name: '',
          options: {
            ...enableEmptyOpts,
            customBodyRender: (_, mt) => this.getActionsCustomBodyRender(mt),
          },
        },
      ],
    };
  }

  getLocationOptionMap() {
    const { servers } = this.props;
    const optionMap = {};
    servers?.allLocations?.map((o) => {
      optionMap[o.id] = o.name;
      return o.id;
    });
    return optionMap;
  }

  getUserTypes() {
    const { servers } = this.props;
    const { user } = servers;
    const userCategory = getUserCategory(user);
    const isAdmin = userCategory === ECategoryType.ADMIN;
    const isBrewery = userCategory === ECategoryType.BREWERY;
    return { isAdmin, isBrewery };
  }

  async componentDidMount() {
    const { servers, getMasterUsers, setCurrentPage, getLocationRegions } = this.props;
    const { where } = servers.usersQuery;
    await getLocationRegions({});

    //If query already exists,show it.
    (Object.keys(where).length > 1 || (Object.keys(where).length === 1 && !where.name)) &&
      this.setState({ enableFilter: true });
    where.createdAt &&
      this.setState({ filterCreatedAt: [where.createdAt.$gte, where.createdAt.$lte] });
    where.updatedAt &&
      this.setState({ filterUpdatedAt: [where.updatedAt.$gte, where.updatedAt.$lte] });
    where.name && this.setState({ filterName: where.name.$like.replace(/%/g, '') });
    where.email && this.setState({ filterEmail: where.email.$like.replace(/%/g, '') });
    where.locationCategory &&
      this.setState({ filterLocationCategory: where.locationCategory.toString() });
    where['$location.name$'] &&
      this.setState({ filterLocation: where['$location.name$'].$like.replace(/%/g, '') });
    where['$location.country$'] && this.setState({ filterCountry: where['$location.country$'] });
    setTimeout(() => getMasterUsers(servers.usersQuery), 300);
    setTimeout(() => this.setState({ initialized: true }), 600);
    setCurrentPage('master-users');
  }

  getActionsCustomBodyRender(tableMeta) {
    const {
      servers,
      confirm,
      apps: { currentLanguage: lang },
    } = this.props;
    const obj: any = this.rowDataToObj(tableMeta.rowData);
    const isRequesting = servers ? servers.isRequesting : true;
    const delTitle = appLanguages.deleteUser[lang];
    const delDesc = `${appLanguages.user[lang]}「${obj.email}」${appLanguages.deleteConfirmationSuffix[lang]} `;
    const onClickEdit = (o) => this.handleOnClickEdit(o);
    const onClickDelete = (o) => this.handleOnClickDelete(o);

    return getActionsCustomBodyRender(
      obj,
      delTitle,
      delDesc,
      isRequesting,
      onClickEdit,
      onClickDelete,
      confirm,
    );
  }

  setFilter(newWhere) {
    const { servers } = this.props;
    const where = { ...servers.usersQuery.where, ...newWhere };
    this.updateQuery({ where, offset: 0 });
    this.setState({ filterTimeout: null });
    console.log({ where, offset: 0 });
  }

  unsetFilter(key) {
    const { servers } = this.props;
    const { where } = servers.usersQuery;
    if (where[key] != undefined) delete where[key];
    this.setFilter(where);
  }

  clearFilterTimeout() {
    const { filterTimeout } = this.state;
    if (filterTimeout) clearTimeout(filterTimeout);
  }

  updateQuery(newQuery) {
    const { servers, setUsersQuery, getMasterUsers } = this.props;
    const query = { ...servers.usersQuery, ...newQuery };
    setUsersQuery(query);
    getMasterUsers(query);
  }

  rowDataToObj(rowData) {
    const { columns } = this.state;
    return rowDataToObj(columns, rowData);
  }

  handleOnClickEdit(obj) {
    const { setEditUser, openUserEditDialog, getUserLocations, servers } = this.props;
    let defaultLocationCategory: ECategoryType = ECategoryType.ADMIN;
    if (getUserCategory(servers?.user) === ECategoryType.DISTRIBUTOR) {
      defaultLocationCategory = ECategoryType.RESTAURANT;
    }
    getUserLocations(
      obj && obj.locationCategory ? Number(obj.locationCategory) : defaultLocationCategory,
    );
    console.log(obj);
    setEditUser(obj);
    openUserEditDialog(obj);
  }

  handleOnClickDelete(obj) {
    const { delUser } = this.props;
    delUser(obj);
  }

  handleOnChangePage(currentPage) {
    const { servers } = this.props;
    const { limit } = servers.usersQuery;
    this.updateQuery({ offset: currentPage * limit });
  }

  handleOnChangeRowsPerPage(numberOfRows) {
    this.updateQuery({ offset: 0, limit: numberOfRows });
  }

  handleOnColumnSortChange(changedColumn, direction) {
    const { columns } = this.state;
    for (let i = 0; i < columns.length; i += 1) {
      const column = columns[i];
      if (column.name === changedColumn) {
        if (direction.match(/desc/)) column.options.sortDirection = 'desc';
        else column.options.sortDirection = 'asc';
      } else {
        delete column.options.sortDirection;
      }
    }
    this.updateQuery({
      order: [[changedColumn, direction.match(/desc/) ? 'DESC' : 'ASC']],
    });
  }

  handleChangeTime(filterKey, dbKey, v) {
    this.clearFilterTimeout();
    const from = v && v.length && new Date(v[0].setHours(0, 0, 0));
    const to = v && v.length && new Date(v[1].setHours(23, 59, 59));
    const newState: Partial<State> = {};

    newState[filterKey] = v;

    newState.filterTimeout = setTimeout(() => {
      if (v.length) {
        this.setFilter({ [dbKey]: { $gte: from.toISOString(), $lte: to.toISOString() } });
      } else {
        this.unsetFilter(dbKey);
      }
    }, 1000);

    this.setState(newState as State);
  }

  handleChangeCreatedAt(v) {
    this.handleChangeTime('filterCreatedAt', 'createdAt', v);
  }

  handleChangeUpdatedAt(v) {
    this.handleChangeTime('filterUpdatedAt', 'updatedAt', v);
  }

  handleChangeFilterName(v) {
    this.clearFilterTimeout();
    this.setState({
      filterName: v,
      filterTimeout: setTimeout(() => this.setFilter({ name: { $like: `%${v}%` } }), 1000),
    });
  }

  handleChangeFilterEmail(v) {
    this.clearFilterTimeout();
    this.setState({
      filterEmail: v,
      filterTimeout: setTimeout(() => this.setFilter({ email: { $like: `%${v}%` } }), 1000),
    });
  }

  handleChangeFilterLocationCategory(v) {
    if (v === '') this.clearFilterTimeout();
    this.unsetFilter('locationId');
    this.setState({
      filterLocationCategory: v === '' ? v : parseInt(v),
      filterTimeout: setTimeout(() => {
        if (v === '') {
          // getUserLocations(v);
          return this.unsetFilter('locationCategory');
        } else {
          // const { getUserLocations } = this.props;
          // getUserLocations(parseInt(v));
        }
        return this.setFilter({ locationCategory: parseInt(v) });
      }, 1000),
    });
  }

  handleChangeFilterLocation(v) {
    this.clearFilterTimeout();
    this.setState({
      filterLocation: v,
      filterTimeout: setTimeout(() => {
        if (v === '') {
          return this.unsetFilter('$location.name$');
        } else {
          this.setFilter({ '$location.name$': { $like: `%${v}%` } });
        }
      }, 1000),
    });
    // if (v === '') this.clearFilterTimeout();
    // this.setState({
    //   filterLocation: v === '' ? v : parseInt(v),
    //   filterTimeout: setTimeout(() => {
    //     if (v === '') return this.unsetFilter('locationId');
    //     return this.setFilter({ locationId: parseInt(v) });
    //   }, 1000),
    // });
  }

  handleChangeFilterCountry(v) {
    this.clearFilterTimeout();
    this.setState({
      filterCountry: v,
      filterTimeout: setTimeout(
        () =>
          v === ''
            ? this.unsetFilter('$location.country$')
            : this.setFilter({ '$location.country$': v }),
        1000,
      ),
    });
  }

  handleClickResetFilter() {
    this.setState({ ...this.initialFilters });
    const { servers, setUsersQuery, getMasterUsers } = this.props;
    const name = servers.usersQuery.where?.name;
    //keep name
    const query = {
      ...servers.usersQuery,
      where: name ? { name } : {},
    };
    setUsersQuery(query);
    getMasterUsers(query);
  }

  render() {
    const { classes, apps, servers, tempRegistrationEnabled } = this.props;
    const {
      enableFilter,
      filterTimeout,
      filterCreatedAt,
      filterUpdatedAt,
      filterName,
      filterEmail,
      filterLocationCategory,
      filterLocation,
      filterCountry,
      initialized,
      columns,
    } = this.state;
    const { offset, limit, searchText } = servers.usersQuery;
    const { user } = servers;
    const currentPage = offset / limit;
    const numberOfRows = limit;
    const lang = apps.currentLanguage;
    const userCategory = getUserCategory(user);
    const isBrewery = userCategory === ECategoryType.BREWERY;
    const isAdmin = userCategory === ECategoryType.ADMIN;
    const isDistributor = userCategory === ECategoryType.DISTRIBUTOR;
    const { categoryMapLocation } = userEnv;
    const categories = [
      { id: '', name: appLanguages.empty[lang] },
      ...Object.entries(categoryMapLocation[lang]).map(([k, v]) => ({
        id: k,
        name: v,
      })),
    ].filter((item) => {
      let isReturn = true;
      if (isDistributor) {
        switch (ECategoryType[item.id]) {
          case 'ADMIN':
            isReturn = false;
            break;
          case 'BREWERY':
            isReturn = false;
            break;
          default:
            break;
        }
      }
      return isReturn;
    });

    const locationRegions = servers.locationRegions;
    const countries = locationRegions
      ? [
          { id: '', name: '-' },
          ...locationRegions.map((v) => ({
            id: v,
            name: v,
          })),
        ]
      : [];
    // const userLocations = this.state.filterLocationCategory
    //   ? [
    //       { id: '', name: appLanguages.empty[lang] },
    //       ...servers.userLocations.map((o) => {
    //         const v = { id: o.id, name: `${o.name}` };
    //         // if (userObj && o.id === userObj.locationId) userLocationValue = { ...v };
    //         return v;
    //       }),
    //     ]
    //   : [{ id: '', name: appLanguages.empty[lang] }];
    // const userLocations = servers.userLocations.map((o) => {
    //   const v = { id: o.id, name: `${o.name}` };
    //   // if (userObj && o.id === userObj.locationId) userLocationValue = { ...v };
    //   return v;
    // });
    const { where } = servers.usersQuery;
    const isRequesting =
      servers.isRequesting || servers.isGetRequesting || Boolean(filterTimeout) || !initialized;
    const filtersView = (
      <>
        <Grid container alignItems='flex-end'>
          <Grid item>
            {getDateRangePicker(
              appLanguages.createdAt[lang],
              filterCreatedAt,
              (v) => this.handleChangeCreatedAt(v),
              lang,
              true, //disable ruture
            )}
          </Grid>
          <Grid item>
            <Box ml={2} />
          </Grid>
          <Grid item>
            {getDateRangePicker(
              appLanguages.updatedAt[lang],
              filterUpdatedAt,
              (v) => this.handleChangeUpdatedAt(v),
              lang,
              true, //disable ruture
            )}
          </Grid>
        </Grid>
        <Grid container alignItems='flex-end'>
          {/* <Grid item>
            {getSmallTextField(appLanguages.name[lang], filterName, false, (e) =>
              this.handleChangeFilterName(e.target.value),
            )}
          </Grid> */}
          <Grid item>
            <Box ml={2} />
          </Grid>
          <Grid item>
            {getSmallTextField(appLanguages.email[lang], filterEmail, false, (e) =>
              this.handleChangeFilterEmail(e.target.value),
            )}
          </Grid>
          <Grid item>
            <Box ml={2} />
          </Grid>
          <Grid item>
            {getSmallSelect(
              appLanguages.category[lang],
              filterLocationCategory,
              categories,
              false,
              (e) => this.handleChangeFilterLocationCategory(e.target.value),
            )}
          </Grid>
          <Grid item>
            <Box ml={2} />
          </Grid>
          <Grid item>
            {/* {getSmallSelect(
              appLanguages.location[lang],
              filterLocation,
              userLocations,
              false,
              (e) => this.handleChangeFilterLocation(e.target.value),
            )} */}
            {getSmallTextField(appLanguages.location[lang], filterLocation, false, (e) =>
              this.handleChangeFilterLocation(e.target.value),
            )}
          </Grid>
          <Grid item>
            <Box ml={2} />
          </Grid>
        </Grid>
        {(isAdmin || isBrewery) && (
          <Grid container alignItems='flex-end'>
            <Grid item>
              <Box ml={2} />
            </Grid>
            <Grid item>
              {getSmallSelect(appLanguages.country[lang], filterCountry, countries, false, (e) =>
                this.handleChangeFilterCountry(e.target.value),
              )}
            </Grid>
          </Grid>
        )}

        {Object.keys(where).length ? (
          <Grid container justify='flex-end' alignItems='flex-end'>
            <Grid item xs={12}>
              <Box mt={1} />
            </Grid>
            <Grid item>
              {getSmallClearButton(appLanguages.reset[lang], false, () =>
                this.handleClickResetFilter(),
              )}
            </Grid>
          </Grid>
        ) : (
          ''
        )}
      </>
    );

    return (
      <>
        <MuiThemeProvider theme={appMuiTheme}>
          {isRequesting ? <LinearProgress color='secondary' /> : <WhiteLinearProgress />}
          <MUIDataTable
            title={
              <>
                {enableFilter ? <Box mt={1} /> : ''}
                <Paper
                  elevation={enableFilter ? 3 : 0}
                  className={enableFilter ? classes.paper : classes.none}
                >
                  {(isAdmin || isBrewery || isDistributor) &&
                    getFilterButton(
                      enableFilter,
                      () => this.setState({ enableFilter: !enableFilter }),
                      lang,
                    )}
                  {enableFilter ? filtersView : ''}
                </Paper>
                {enableFilter ? <Box mt={1} /> : ''}
                <Grid container alignItems='flex-end'>
                  <Grid item>
                    <Box ml={2} />
                  </Grid>
                  <Grid item>
                    {getSmallSearchField(appLanguages.searchByName[lang], filterName, false, (e) =>
                      this.handleChangeFilterName(e.target.value),
                    )}
                  </Grid>
                </Grid>
                <Box mt={10} />
              </>
            }
            data={servers.users}
            columns={columns}
            options={{
              ...muiDataTableCommonOptions(),
              downloadOptions: { filename: 'users.csv', separator: ',' },
              currentPage,
              rowsPerPage: numberOfRows,
              searchText,
              count: servers.usersTotalCounts,
              customToolbar: () => <UsersCustomToolbar />,
              onChangePage: (currentPage) => this.handleOnChangePage(currentPage),
              onChangeRowsPerPage: (numberOfRows) => this.handleOnChangeRowsPerPage(numberOfRows),
              onColumnSortChange: (changedColumn, direction) =>
                this.handleOnColumnSortChange(changedColumn, direction),
              onDownload: this.handleOnDownload,
            }}
          />
        </MuiThemeProvider>
        {apps.isOpenUserEditDialog ? (
          <UserEditDialog tempRegistrationEnabled={tempRegistrationEnabled} />
        ) : (
          ''
        )}
      </>
    );
  }
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps: IDispatchProps = {
  setEditUser,
  openUserEditDialog,
  getMasterUsers,
  setUsersQuery,
  delUser,
  getUserLocations,
  getLocationRegions,
  setCurrentPage,
};

const myStyles = (theme: Theme): StyleRules => ({
  smallAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  none: {},
  paper: { padding: theme.spacing(2) },
});

export const Users = compose<any>(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(withConfirm(UsersClass));
