// export enum ERestaurantProductPriceType {
//   UNKNOWN = 0,
//   LESS_10000 = 1,
//   WITHIN_10000_30000 = 2,
//   WITHIN_30000_50000 = 3,
//   MORE_THAN_50000 = 4,
//   MORE_THAN_100000 = 5,
// }

export enum ERestaurantProductPriceType {
  UNKNOWN = 0,
  LEVEL_1 = 1,
  LEVEL_2 = 2,
  LEVEL_3 = 3,
  LEVEL_4 = 4,
  LEVEL_5 = 5,
  LEVEL_6 = 6,
  LEVEL_7 = 7,
  LEVEL_8 = 8,
  LEVEL_9 = 9,
  LEVEL_10 = 10,
  LEVEL_11 = 11,
}
