import { isOk } from './is-ok';

export function convertSec(param: number, type: 'minutes' | 'hours' | 'days') {
  if (!isOk(param) || isNaN(param)) return;
  try {
    switch (type) {
      case 'minutes':
        return param / 60;
      case 'hours':
        return param / (60 * 60);
      case 'days':
        return param / (60 * 60 * 24);
    }
  } catch {
    return;
  }
}

export function convertToSec(param: number, type: 'minutes' | 'hours' | 'days') {
  if (!isOk(param) || isNaN(param)) return;
  try {
    switch (type) {
      case 'minutes':
        return param * 60;
      case 'hours':
        return param * (60 * 60);
      case 'days':
        return param * (60 * 60 * 24);
    }
  } catch {
    return;
  }
}
