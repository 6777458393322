import { i18n } from '@i18n/lang';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Theme,
  Typography,
} from '@material-ui/core';
import { styled, StyleRules, withStyles } from '@material-ui/core/styles';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { EAlertStatus } from '@models/alert-status-type';
import { AllPages } from '@models/all-pages';
import { ECategoryType } from '@models/category-type';
import { IQuery } from '@models/query';
import { IStateApps } from '@models/state-apps';
import { IStateServers } from '@models/state-servers';
import { setCurrentPage, setMonitoringFilter } from '@redux/actions/appsActions';
import {
  getInventory,
  getLocationRegions,
  getLocations,
  getTransaction,
  setAnalysisQuery,
} from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import appLanguages from '@utils/app-languages';
import {
  getDateRangePicker2,
  getFilterButton,
  getSmallClearButton,
  getSmallClearButton2,
} from '@utils/common';
import { LIMIT_PAGINATION_MAX } from '@utils/default-query';
import { formatDate } from '@utils/format-date';
import { getCuisineLangList } from '@utils/get-cuisine-lang-list';
import { getUserCategory } from '@utils/get-user-category';
import { History, LocationState } from 'history';
import _ from 'lodash';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { compose } from 'redux';
import userEnv from 'userEnv';
import styles from './style.scss';

class StockManagementClass extends React.PureComponent<Props, State> {
  private basePath;

  private today = new Date();
  private setDefaultPeriod() {
    let d = new Date();
    //d.setFullYear(d.getFullYear() - 1);
    // default period changed to half an year
    d.setMonth(d.getMonth() - 6);
    return d;
  }

  private initialFilters = {
    filterCreatedAt: [this.setDefaultPeriod(), this.today],
    filterSalesOrder: '',
    filterDestinationId: '',
    filterBrandId: '',
    filterCountry: '',
    filterSubCategory: '',
    filterCuisineType: '',
    filterDist: '',
    filterRest: {},
    originStateRest: {},
    originStateCountry: {},
    originBrandId: {},
    originSubCategory: {},
    originCuisineType: {},
  };
  public query: any = {};
  public pages = {
    offset: 0,
    limit: LIMIT_PAGINATION_MAX,
  };

  constructor(props) {
    super(props);
    this.basePath = `${userEnv.rootDir}charts/`;

    const { servers, setAnalysisQuery } = this.props;
    const { user } = servers;
    const orderBy = [['latestScanAt', 'DESC']];
    const queryInit = {
      ...servers.analysisQuery,
      order: orderBy,
      searchText: null,
      where: {
        ...(user.location.category === ECategoryType.BREWERY && { brewLocId: user.locationId }),
        ...(user.location.category === ECategoryType.DISTRIBUTOR && { distLocId: user.locationId }),
        ...(user.location.category === ECategoryType.RESTAURANT && { locId: user.locationId }),
      },
    };

    const invQuery = {
      searchText: null,
      where: {
        ...(user.location.category === ECategoryType.BREWERY && { brewLocId: user.locationId }),
        ...(user.location.category === ECategoryType.DISTRIBUTOR && { distLocId: user.locationId }),
        ...(user.location.category === ECategoryType.RESTAURANT && { locId: user.locationId }),
      },
    };

    const barDataInit = {
      length: 0,
    };

    this.state = {
      setTab: 0,
      filterTimeout: undefined,
      ...this.initialFilters,
      enableFilter: false,
      initialized: false,
      serialsList: null,
      serialsTotalCounts: 0,
      transactionRows: null,
      inventoryRows: null,
      filterData: null,
      relationTree: { Countries: {} },
      brands: {},
      subCategories: {},
      cuisineTypes: {},
      windowSize: {
        width: 0,
        height: 0,
      },
      width: window.innerWidth,
      query: queryInit,
      inv_query: invQuery,
      isLoading: false,
      brandList: {},
      cardData_inv_brew: null,
      cardData_inv_dist_rest: null,
      cardData_inv_countryDist: null,
      cardData_inv_dist: null,
      cardData_inv_rest: null,
      cardData_sales: null,
      cardData_sales_privatecustomer: null,
      barData_invTime: barDataInit,
      barData_shipNum: barDataInit,
      barData_shipLocNum: barDataInit,
    };

    setAnalysisQuery(queryInit);
  }

  updateQuery(newQuery) {
    const { query } = this.state;
    const { setAnalysisQuery } = this.props;
    const queryTemp = { ...query, ...newQuery };
    this.setState({ query: queryTemp }, () => {
      setAnalysisQuery(queryTemp);
    });
  }

  updateInvQuery(newQuery) {
    const { inv_query } = this.state;
    const queryTemp = { ...inv_query, ...newQuery };
    this.setState({ inv_query: queryTemp });
  }

  async fetchAnalysis(query, didMount?) {
    this.setState({ isLoading: true });
    const { inv_query } = this.state;

    /** getInventory: getInventory retrieves data from 'inventory' table. Data of this table has latest inventory information,
     *                so date-time filter The date/time filter does not affect data in the inventory table.
     * getTransaction:Instead of getAnalysis, this function call API which returns data for sales information.
     *                I changed the table name 'analysis' to 'transaction', and modified table structure.
     * */
    const { getInventory } = this.props;
    //if (!(this.props.servers.inventory[0] && didMount)) {
    setTimeout(getInventory(inv_query), 100);
    //}

    const { getTransaction } = this.props;
    //if (!(this.props.servers.transaction[0] && didMount)) {
    await getTransaction(query);
    //}

    const { servers } = this.props;
    const allLocs = servers.allLocations;
    const { transaction, inventory } = servers;

    const inv_temp = inventory.filter((element: any) => {
      return element.locType === 1;
    });

    let inventoryRows = inventory.map((row) => {
      return {
        locId: row.locId,
        locType: row.locType,
        brandId: row.brandId,
        brandName: row.brand.name,
        quantity: row.quantity,
        distLocId: row.distLocId,
      };
    });

    const countryMapping = {
      CN: 'CHN',
      JP: 'JPN',
      HK: 'HKG',
      SG: 'SGP',
      TW: 'TWN',
      KR: 'KOR',
    };

    // add distLocName and distcountry to inventory data
    inventoryRows = inventoryRows.reduce((acc, current) => {
      const findResult = allLocs.find((e) => e.id === current.distLocId);
      if (findResult) {
        //const mappedCountry = countryMapping[findResult['country']] || findResult['country'];
        const mappedCountry = findResult['country'];
        acc.push({
          ...current,
          distLocName: findResult['name'],
          distCountry: mappedCountry,
        });
      } else {
        acc.push({
          ...current,
          distLocName: null,
          distCountry: null,
        });
      }
      return acc;
    }, []);
    await this.setState({ inventoryRows: inventoryRows });

    let transactionRows = transaction.map((row) => {
      const recvDate = new Date(row.recvAt);
      const recvYear = recvDate.getFullYear();
      const recvMonth = recvDate.getMonth() + 1;
      const recvMonthStr =
        recvYear === 1970 ? null : `${recvYear}/${recvMonth.toString().padStart(2, '0')}`;

      const shipDate = new Date(row.shipAt);
      const shipYear = shipDate.getFullYear();
      const shipMonth = shipDate.getMonth() + 1;
      const shipMonthStr =
        shipYear === 1970 ? null : `${shipYear}/${shipMonth.toString().padStart(2, '0')}`;

      const lScanDate = new Date(row.latestScanAt);
      const lScanYear = lScanDate.getFullYear();
      const lScanMonth = lScanDate.getMonth() + 1;
      const lScanMonthStr =
        lScanYear === 1970 ? null : `${lScanYear}/${lScanMonth.toString().padStart(2, '0')}`;

      //const mappedCountry = countryMapping[row.location?.country] || row.location?.country;
      const mappedCountry = row.location?.country;
      return {
        serialCode: row.serialCode,
        brandId: row.brandId,
        brandName: row.brand.name,
        recvMonthStr: recvMonthStr,
        shipMonthStr: shipMonthStr,
        lScanMonthStr: lScanMonthStr,
        status: row.status,
        inventoryTime: row.inventoryTime,
        locId: row.locId,
        country: mappedCountry,
        subCategoryId: row.location?.subCategory,
        cuisineTypeId: row.location?.cuisineType,
        distLocId: row.distLocId,
        restLocId: row.restLocId,
      };
    });

    // add locName to transaction data
    transactionRows = transactionRows.reduce((acc, current) => {
      const findResult = allLocs.find((e) => e.id === current.locId);
      if (findResult) {
        acc.push({
          ...current,
          locName: findResult['name'],
          locType: findResult['category'],
        });
      } else {
        acc.push({
          ...current,
          locName: null,
          locType: null,
        });
      }
      return acc;
    }, []);

    transactionRows = transactionRows.filter((item) => item.distLocId !== null);
    await this.setState({ transactionRows: transactionRows });

    await this.processInvSalesData();

    if (this.state.filterData == null || this.state.filterData !== transactionRows) {
      this.setState({ filterData: transactionRows });
      this.processData(transactionRows, inventoryRows);
    }

    this.setState({ isLoading: false });
  }

  async processInvSalesData() {
    const { apps, servers, classes } = this.props;
    const { transactionRows, inventoryRows } = this.state;
    const _ = require('lodash');
    const lang = apps.currentLanguage;

    //Create brand list
    const brandList = await [...new Set(transactionRows.map((item: any) => item.brandName))]
      .sort((a: any, b: any) => {
        return a > b ? -1 : 1;
      })
      .sort((a: any, b: any) => {
        // sort like Gold,Black,Red,N by ,N by Vintage
        const orderMapping = {
          Gold: 1,
          Black: 2,
          Red: 3,
          'N by Hidetoshi Nakata (': 4,
          'N by Hidetoshi Nakata Vintage': 5,
        };
        const aName = a;
        const bName = b;

        const aPrefix = Object.keys(orderMapping).find((prefix) => aName.startsWith(prefix));
        const bPrefix = Object.keys(orderMapping).find((prefix) => bName.startsWith(prefix));

        const aPriority = aPrefix ? orderMapping[aPrefix] : 6; // 6はその他の場合の優先度
        const bPriority = bPrefix ? orderMapping[bPrefix] : 6;

        return aPriority - bPriority;
      });
    await this.setState({ brandList: brandList });

    //Brewery inventory data
    const invData_brew = await inventoryRows.filter((element: any) => {
      return element.locType === 1;
    });

    const cardData_inv_brew = await this.createData_card_inv(invData_brew);
    await this.setState({ cardData_inv_brew: cardData_inv_brew });

    //Distributor + restaurant unsold inventory data
    const invData_dist_rest = await inventoryRows.filter((element: any) => {
      return (element.locType === 2 || element.locType === 3) && element.distLocName !== null;
    });
    const cardData_inv_dist_rest = await this.createData_card_inv(invData_dist_rest);
    await this.setState({ cardData_inv_dist_rest: cardData_inv_dist_rest });

    //Data for Total Inventory Card by Distributor
    //const cardData_inv_countryDist = await this.createData_card_inv_countryDist(invData_dist_rest);
    //await this.setState({ cardData_inv_countryDist: cardData_inv_countryDist });

    //Distributor Inventory Data
    const invData_dist = await inventoryRows.filter((element: any) => {
      return element.locType === 2;
    });
    const cardData_inv_dist = await this.createData_card_inv(invData_dist);
    await this.setState({ cardData_inv_dist: cardData_inv_dist });

    //Data for Total Inventory Card by Distributor
    const cardData_inv_countryDist = await this.createData_card_inv_countryDist(invData_dist); // extract distoributor's data only
    await this.setState({ cardData_inv_countryDist: cardData_inv_countryDist });

    //Restaurant inventory data
    const invData_rest = await inventoryRows.filter((element: any) => {
      return element.locType === 3;
    });
    const cardData_inv_rest = await this.createData_card_inv(invData_rest);
    await this.setState({ cardData_inv_rest: cardData_inv_rest });

    //Data for sales cards
    const salesData = await transactionRows.filter((element: any) => {
      //Extract restaurant sales data
      return element.status === 1 && element.locType === 3;
    });
    const cardData_sales = await this.createData_card_sales(salesData);
    await this.setState({ cardData_sales: cardData_sales });

    const salesData_privatecustomer = await transactionRows.filter((element: any) => {
      return element.status === 1 && element.subCategoryId === 5;
    });
    const cardData_sales_privatecustomer = await this.createData_card_sales(
      salesData_privatecustomer,
    );
    await this.setState({ cardData_sales_privatecustomer: cardData_sales_privatecustomer });

    //Data for inventory turnover graph for hotels, restaurants, dining bars and membership clubs
    const transactionRows_invTimeTarget = await transactionRows.filter((element: any) => {
      return (
        element.subCategoryId === 0 ||
        element.subCategoryId === 1 ||
        element.subCategoryId === 2 ||
        element.subCategoryId === 3 ||
        element.subCategoryId === 4
      );
    });
    const barData_invTime = await this.createTrendData(
      transactionRows_invTimeTarget,
      'mean',
      'inventoryTime',
    );
    await this.setState({ barData_invTime: barData_invTime });

    //Data for sales graph
    const barData_shipNum = await this.createTrendData(transactionRows, 'sum', '');
    await this.setState({ barData_shipNum: barData_shipNum });

    //Data for number of restaurants selling graph
    const barData_shipLocNum = await this.createTrendData_locNum(transactionRows);
    await this.setState({ barData_shipLocNum: barData_shipLocNum });
  }

  //Calculates the unique number of specified key values ​​in an object
  getUniqueValueCount(arr, key) {
    var uniqueValues = [];
    for (var i = 0; i < arr.length; i++) {
      var value = arr[i][key];
      if (!uniqueValues.includes(value)) {
        uniqueValues.push(value);
      }
    }
    return uniqueValues.length;
  }

  createData_card_inv(data) {
    const group = _.groupBy(data, (value) => {
      return value.brandName;
    });

    const group_aggregated = _.map(group, (obj) => {
      const loc_count = this.getUniqueValueCount(obj, 'locId');
      return {
        name: obj[0].brandName,
        count: _.sumBy(obj, 'quantity'),
        loc_count: loc_count,
      };
    })
      .sort((a: any, b: any) => {
        return a.name > b.name ? -1 : 1;
      })
      .sort((a: any, b: any) => {
        // sort like Gold,Black,Red,N by ,N by Vintage
        const orderMapping = {
          Gold: 1,
          Black: 2,
          Red: 3,
          'N by Hidetoshi Nakata (': 4,
          'N by Hidetoshi Nakata Vintage': 5,
        };
        const aName = a.name;
        const bName = b.name;

        const aPrefix = Object.keys(orderMapping).find((prefix) => aName.startsWith(prefix));
        const bPrefix = Object.keys(orderMapping).find((prefix) => bName.startsWith(prefix));

        const aPriority = aPrefix ? orderMapping[aPrefix] : 6; // 6はその他の場合の優先度
        const bPriority = bPrefix ? orderMapping[bPrefix] : 6;

        return aPriority - bPriority;
      });

    return group_aggregated;
  }

  /** Creation of data for total inventory by distributor */
  createData_card_inv_countryDist(data) {
    //Extract data where distLocId is not null
    const data_withDist = data.filter((element: any) => {
      return element.distLocName !== null;
    });

    const group_dist = _.groupBy(data_withDist, (value) => {
      return value.distLocName + '#' + value.brandName;
    });

    const group_aggregated_dist = _.map(group_dist, (obj) => {
      return {
        distLocName: obj[0].distLocName,
        distCountry: obj[0].distCountry,
        brandName: obj[0].brandName,
        totalInv: _.sumBy(obj, 'quantity'),
        restInv: 0,
      };
    });

    const data_rest = data_withDist.filter((element: any) => {
      return element.locType === 3;
    });

    const group_rest = _.groupBy(data_rest, (value) => {
      return value.distLocName + '#' + value.brandName;
    });

    const group_aggregated_rest = _.map(group_rest, (obj) => {
      return {
        distLocName: obj[0].distLocName,
        distCountry: obj[0].distCountry,
        brandName: obj[0].brandName,
        totalInv: 0,
        restInv: _.sumBy(obj, 'quantity'),
      };
    });

    const merged = [...group_aggregated_dist, ...group_aggregated_rest];

    const group_merged = _.groupBy(merged, (value) => {
      return value.distLocName + '#' + value.brandName;
    });

    const group_merged_aggregated = _.map(group_merged, (obj) => {
      return {
        distLocName: obj[0].distLocName,
        distCountry: obj[0].distCountry,
        brandName: obj[0].brandName,
        totalInv: _.sumBy(obj, 'totalInv'),
        restInv: _.sumBy(obj, 'restInv'),
      };
    })
      //.sort((a: any, b: any) => {
      //  return a.brandName < b.brandName ? -1 : 1;
      //})
      .sort((a: any, b: any) => {
        return a.brandName > b.brandName ? -1 : 1;
      })
      .sort((a: any, b: any) => {
        // sort like Gold,Black,Red,N by ,N by Vintage
        const orderMapping = {
          Gold: 1,
          Black: 2,
          Red: 3,
          'N by Hidetoshi Nakata (': 4,
          'N by Hidetoshi Nakata Vintage': 5,
        };
        const aName = a.brandName;
        const bName = b.brandName;

        const aPrefix = Object.keys(orderMapping).find((prefix) => aName.startsWith(prefix));
        const bPrefix = Object.keys(orderMapping).find((prefix) => bName.startsWith(prefix));

        const aPriority = aPrefix ? orderMapping[aPrefix] : 6; // 6はその他の場合の優先度
        const bPriority = bPrefix ? orderMapping[bPrefix] : 6;

        return aPriority - bPriority;
      })
      .sort((a: any, b: any) => {
        return a.distLocName < b.distLocName ? -1 : 1;
      })
      .sort((a: any, b: any) => {
        return a.distCountry < b.distCountry ? -1 : 1;
      });

    const group_countryDist = _.groupBy(group_merged_aggregated, (value) => {
      return value.distLocName;
    });

    return group_countryDist;
  }

  createData_card_sales(data) {
    const group = _.groupBy(data, (value) => {
      return value.brandName;
    });

    const group_aggregated = _.map(group, (obj) => {
      const loc_count = this.getUniqueValueCount(obj, 'locName');
      const country_count = this.getUniqueValueCount(obj, 'country');

      const obj_distWithoutNull = obj.filter((value) => {
        return value.distLocId !== null;
      });
      const dist_count = this.getUniqueValueCount(obj_distWithoutNull, 'distLocId');

      const obj_restWithoutNull = obj.filter((value) => {
        return value.restLocId !== null;
      });
      const rest_count = this.getUniqueValueCount(obj_restWithoutNull, 'restLocId');

      return {
        name: obj[0].brandName,
        count: obj.length,
        loc_count: loc_count,
        country_count: country_count,
        dist_count: dist_count,
        rest_count: rest_count,
      };
    })
      .sort((a: any, b: any) => {
        return a.name > b.name ? -1 : 1;
      })
      .sort((a: any, b: any) => {
        // sort like Gold,Black,Red,N by ,N by Vintage
        const orderMapping = {
          Gold: 1,
          Black: 2,
          Red: 3,
          'N by Hidetoshi Nakata (': 4,
          'N by Hidetoshi Nakata Vintage': 5,
        };
        const aName = a.name;
        const bName = b.name;

        const aPrefix = Object.keys(orderMapping).find((prefix) => aName.startsWith(prefix));
        const bPrefix = Object.keys(orderMapping).find((prefix) => bName.startsWith(prefix));

        const aPriority = aPrefix ? orderMapping[aPrefix] : 6; // 6はその他の場合の優先度
        const bPriority = bPrefix ? orderMapping[bPrefix] : 6;

        return aPriority - bPriority;
      });

    return group_aggregated;
  }

  createData_period(data, dateKey) {
    const data_withoutNull = data.filter((obj) => {
      if (obj.shipMonthStr !== null) {
        return true;
      }
      return false;
    });

    const gr_mnth = _.groupBy(data_withoutNull, (value) => {
      return value[dateKey];
    });

    const gr_mnth_filtered = _.map(gr_mnth, (group) => {
      return {
        name: group[0][dateKey],
      };
    });

    const months = Array.from(new Set(Object.values(gr_mnth_filtered))).sort((a: any, b: any) => {
      return a.name < b.name ? -1 : 1;
    });

    let arr_months = [];
    months.forEach((element: any, index) => {
      arr_months[index] = element.name;
    });

    const start = arr_months[0];
    const end = arr_months[arr_months.length - 1];
    let startDate = new Date(start + '/01');
    let endDate = new Date(end + '/01');
    const diffMonth = (d1, d2) => {
      let d1Month, d2Month;
      d1Month = d1.getFullYear() * 12 + d1.getMonth();
      d2Month = d2.getFullYear() * 12 + d2.getMonth();
      return d2Month - d1Month;
    };

    const monthCount = diffMonth(startDate, endDate) + 1;
    let targetMonths = [];
    let month_obj, month_str, offset;

    //Create a list in yyyy/mm format
    for (let i = 0; i < monthCount; i++) {
      if (i == 0) {
        offset = 0;
      } else {
        offset = 1;
      }
      month_obj = new Date(startDate.setMonth(startDate.getMonth() + offset));
      month_str = `${month_obj.getFullYear()}/${('00' + (month_obj.getMonth() + 1)).slice(-2)}`;
      targetMonths.push(month_str);
    }
    return targetMonths;
  }

  //Convert year and month to format like Apr.2023
  convertMonthStr(date) {
    const newDate = new Date(date);
    const month_name =
      new Intl.DateTimeFormat('en', { month: 'short' }).format(newDate) +
      '.' +
      newDate.getFullYear();
    return month_name;
  }

  createTrendData(data, how, aggTarget) {
    const { apps } = this.props;
    const lang = apps.currentLanguage;
    const targetMonths = this.createData_period(data, 'shipMonthStr');

    const ResWithoutNull = data.filter((obj) => {
      return obj.shipMonthStr !== null && obj.locType === 3;
    });

    const group = _.groupBy(ResWithoutNull, (value) => {
      return value.brandName + '#' + value.shipMonthStr;
    });

    const group_aggregated = _.map(group, (obj) => {
      let value;
      if (how === 'mean') {
        value = Number(_.meanBy(obj, aggTarget).toFixed(1));
      } else {
        value = obj.length;
      }
      return {
        name: obj[0].shipMonthStr,
        brandName: obj[0].brandName == null ? '[N/A]' : obj[0].brandName,
        value: value,
      };
    });

    let group_reshaped = group_aggregated
      .reduce((acc, current) => {
        const findResult = acc.find((e) => e.name === current.name);

        if (findResult) {
          findResult[current.brandName] = current.value;
        } else {
          acc.push({
            name: current.name,
            [current.brandName]: current.value,
          });
        }
        return acc;
      }, [])
      .sort(function (a, b) {
        return a.name < b.name ? -1 : 1;
      });

    let bar_mnth = [];
    if (lang !== 'en') {
      bar_mnth = _.map(targetMonths, (item) => {
        return { name: item };
      });
    } else {
      group_reshaped = _.map(group_reshaped, (obj) => {
        const month_name = this.convertMonthStr(obj.name + '/01');
        return { ...obj, name: month_name };
      });

      bar_mnth = _.map(targetMonths, (item) => {
        const month_name = this.convertMonthStr(item + '/01');
        return { name: month_name };
      });
    }

    const barGraphData = bar_mnth.map((itemA) => {
      const itemB = group_reshaped.find((item) => item.name === itemA.name);
      return { ...itemA, ...itemB };
    });

    return barGraphData;
  }

  createTrendData_locNum(data) {
    const { apps } = this.props;
    const lang = apps.currentLanguage;
    const targetMonths = this.createData_period(data, 'shipMonthStr');

    const data_ResWithoutNull = data.filter((obj) => {
      if (obj.shipMonthStr !== null && obj.locType === 3) {
        return true;
      }
      return false;
    });

    const group = _.groupBy(data_ResWithoutNull, (value) => {
      return value.brandName + '#' + value.shipMonthStr + '#' + value.locId;
    });

    const group_agg1 = _.map(group, (obj) => {
      return {
        name: obj[0].shipMonthStr,
        brandName: obj[0].brandName == null ? '[N/A]' : obj[0].brandName,
        locId: obj[0].locId,
      };
    });

    const group2 = _.groupBy(group_agg1, (value) => {
      return value.name + '#' + value.brandName;
    });

    const group_agg2 = _.map(group2, (obj) => {
      return {
        name: obj[0].name,
        brandName: obj[0].brandName,
        value: obj.length,
      };
    });

    let group_reshaped = group_agg2
      .reduce((acc, current) => {
        const findResult = acc.find((e) => e.name === current.name);

        if (findResult) {
          findResult[current.brandName] = current.value;
        } else {
          acc.push({
            name: current.name,
            [current.brandName]: current.value,
          });
        }
        return acc;
      }, [])
      .sort(function (a, b) {
        return a.name < b.name ? -1 : 1;
      });
    /*
    // Convert year and month to format like Apr.2023
    group_reshaped = _.map(group_reshaped, (obj) => {
      const month_name = this.convertMonthStr(obj.name + '/01');
      return { ...obj, name: month_name };
    });

    //Bind with the created object
    const line_mnth = targetMonth.reduce((acc, current) => {
      const month_name = this.convertMonthStr(current + '/01');
      acc.push({
        name: month_name,
      });
      return acc;
    }, []);

    line_mnth.reduce((acc, current) => {
      var index = group_reshaped.findIndex((e) => e.name === current.name);
      if (index !== -1) {
        Object.assign(current, group_reshaped[index]);
      }
    }, []);

    return line_mnth;
    */
    let bar_mnth = [];
    if (lang !== 'en') {
      bar_mnth = _.map(targetMonths, (item) => {
        return { name: item };
      });
    } else {
      group_reshaped = _.map(group_reshaped, (obj) => {
        const month_name = this.convertMonthStr(obj.name + '/01');
        return { ...obj, name: month_name };
      });

      bar_mnth = _.map(targetMonths, (item) => {
        const month_name = this.convertMonthStr(item + '/01');
        return { name: month_name };
      });
    }

    const barGraphData = bar_mnth.map((itemA) => {
      const itemB = group_reshaped.find((item) => item.name === itemA.name);
      return { ...itemA, ...itemB };
    });

    return barGraphData;
  }

  processData(data, inventory) {
    const relationTree = { ...this.state.relationTree }; // Create a copy of the current state
    let filterCountry = { ...this.state.filterCountry };
    let brands = { ...this.state.brands };
    let subCategories = { ...this.state.subCategories };
    let cuisineTypes = { ...this.state.cuisineTypes };
    let filterRest = { ...this.state.filterRest };
    let filterBrandId = { ...this.state.filterBrandId };
    let filterCuisineType = { ...this.state.filterCuisineType };
    let filterSubCategory = { ...this.state.filterSubCategory };
    let originStateRest = { ...this.state.originStateRest };
    let originStateCountry = { ...this.state.originStateCountry };
    let originBrandId = { ...this.state.originBrandId };
    let originCuisineType = { ...this.state.originCuisineType };
    let originSubCategory = { ...this.state.originSubCategory };
    const { subCategoryMap } = userEnv;
    const { servers, apps } = this.props;
    const tempBrands = [];
    const tempSubCategory = [];
    const tempCuisineType = [];
    let newRest = filterRest;
    let newCountry = filterCountry;
    let newBrand = filterBrandId;
    let newCuisineType = filterCuisineType;
    let newSubCategoriesFilter = filterSubCategory;
    const lang = apps.currentLanguage;

    inventory.forEach((item) => {
      const brandId = item.brandId;
      const brandName = item.brandName;
      tempBrands.push({ id: brandId, name: brandName });
    });

    const filteredData = data.map((item) => ({
      country: item.country,
      distLocId: item.distLocId,
      restLocId: item.restLocId,
      brandName: item.brandName,
      brandId: item.brandId,
      subCategoryId: item.subCategoryId,
      cuisineTypeId: item.cuisineTypeId,
    }));

    // create unique value array without duplicates
    const uniqueFilteredData = filteredData.reduce((accumulator, item) => {
      const existingItem = accumulator.find(
        (existing) =>
          existing.country === item.country &&
          existing.distLocId === item.distLocId &&
          existing.restLocId === item.restLocId &&
          existing.brandName === item.brandName &&
          existing.brandId === item.brandId &&
          existing.subCategoryId === item.subCategoryId &&
          existing.cuisineTypeId === item.cuisineTypeId,
      );

      if (!existingItem) {
        accumulator.push(item);
      }

      return accumulator;
    }, []);

    uniqueFilteredData.forEach((item) => {
      const country = item.country;
      const distLocId = item.distLocId;
      const restLocId = item.restLocId;
      const brandName = item.brandName;
      const brandId = item.brandId;
      const subCategoryId = item.subCategoryId;
      const cuisineTypeId = item.cuisineTypeId;

      tempBrands.push({ id: brandId, name: brandName });

      const subCategory = {
        id: subCategoryId,
        name: subCategoryMap[lang][subCategoryId],
      };
      tempSubCategory.push(subCategory);

      const cuisineTypesLang = getCuisineLangList(lang);
      const cuisineTypeLang = cuisineTypesLang.find(
        (cuisineType) => cuisineType.id === cuisineTypeId,
      );
      tempCuisineType.push(cuisineTypeLang);

      if (!relationTree.Countries[country]) {
        relationTree.Countries[country] = { Dists: {} };
        if (newCountry[country] == null || newCountry[country] == undefined) {
          newCountry[country] = true;
        }
      }

      if (distLocId !== '' && distLocId !== null) {
        if (!relationTree.Countries[country].Dists[distLocId]) {
          let value;
          servers.allLocations?.map((locObj) => {
            if (locObj.id === distLocId) value = { ...locObj };
          });
          relationTree.Countries[country].Dists[distLocId] = {
            ...value,
            Rests: { [distLocId]: { ...value } },
          };
          if (newRest[distLocId] == null || newRest[distLocId] == undefined) {
            newRest[distLocId] = true;
          }
        }
        if (restLocId !== '' && restLocId !== null) {
          let value;
          servers.allLocations?.map((locObj) => {
            if (locObj.id === restLocId) value = { ...locObj };
          });
          relationTree.Countries[country].Dists[distLocId].Rests[restLocId] = { ...value };
          if (newRest[restLocId] == null || newRest[restLocId] == undefined) {
            newRest[restLocId] = true;
          }
        }
      } else {
        if (restLocId !== '' && restLocId !== null) {
          if (!relationTree.Countries[country].Dists[restLocId]) {
            let value;
            servers.allLocations?.map((locObj) => {
              if (locObj.id === restLocId) value = { ...locObj };
            });
            if (!relationTree.Countries[country].Dists[country]) {
              // In case of not going through a distributor
              /*
              relationTree.Countries[country].Dists[country] = {
                id: [country],
                name: i18n.notViaDist[lang],
                Rests: { [restLocId]: { ...value } },
              };
              */
            } else {
              relationTree.Countries[country].Dists[country].Rests[restLocId] = { ...value };
            }
            if (newRest[restLocId] == null || newRest[restLocId] == undefined) {
              newRest[restLocId] = true;
            }
          }
        }
      }
    });

    const tempNewBrands = { ...brands };
    tempBrands.forEach((obj, index) => {
      const newKey = Object.keys(tempNewBrands).length + index + 2;
      tempNewBrands[newKey] = obj;
    });
    const tempBrandsArray = Object.values(tempNewBrands);

    const newBrands: any = tempBrandsArray.reduce((uniqueArray: any, obj: any) => {
      // Check if any of the properties (id or name) are null or undefined
      if (obj.id === null || obj.id === undefined || obj.name === null || obj.name === undefined) {
        return uniqueArray; // Skip comparison if any property is null or undefined
      }

      // Check if the object is already present in the uniqueArray
      const isDuplicate = uniqueArray.some((t) => t.id === obj.id && t.name === obj.name);
      if (!isDuplicate) {
        uniqueArray.push(obj); // Add the object to the uniqueArray if it's not a duplicate
        if (newBrand[obj.id] == null || newBrand[obj.id] == 'undefined') newBrand[obj.id] = true;
      }

      return uniqueArray;
    }, []);

    const tempNewSubCategories = { ...subCategories };
    tempSubCategory.forEach((obj, index) => {
      const newKey = Object.keys(tempNewSubCategories).length + index + 2;
      tempNewSubCategories[newKey] = obj;
    });
    const tempSubCategoriesArray = Object.values(tempNewSubCategories);

    const newSubCategories: any = tempSubCategoriesArray.reduce((uniqueArray: any, obj: any) => {
      // Check if any of the properties (id or name) are null or undefined
      if (obj.id === null || obj.id === undefined || obj.name === null || obj.name === undefined) {
        return uniqueArray; // Skip comparison if any property is null or undefined
      }

      // Check if the object is already present in the uniqueArray
      const isDuplicate = uniqueArray.some((t) => t.id === obj.id && t.name === obj.name);
      if (!isDuplicate) {
        uniqueArray.push(obj); // Add the object to the uniqueArray if it's not a duplicate
        if (newSubCategoriesFilter[obj.id] == null || newSubCategoriesFilter[obj.id] == 'undefined')
          newSubCategoriesFilter[obj.id] = true;
      }

      return uniqueArray;
    }, []);

    const tempNewCuisineTypes = { ...cuisineTypes };
    tempCuisineType.forEach((obj, index) => {
      const newKey = Object.keys(tempNewCuisineTypes).length + index + 2;
      tempNewCuisineTypes[newKey] = obj;
    });
    const tempCuisineTypesArray = Object.values(tempNewCuisineTypes);

    const newCuisineTypes: any = tempCuisineTypesArray.reduce((uniqueArray: any, obj: any) => {
      // Check if any of the properties (id or name) are null or undefined
      if (obj === null || obj === undefined) {
        return uniqueArray; // Skip comparison if any property is null or undefined
      }

      // Check if the object is already present in the uniqueArray
      const isDuplicate = uniqueArray.some((t) => t.id === obj.id && t.name === obj.name);
      if (!isDuplicate) {
        uniqueArray.push(obj); // Add the object to the uniqueArray if it's not a duplicate
        if (newCuisineType[obj.id] == null || newCuisineType[obj.id] == 'undefined')
          newCuisineType[obj.id] = true;
      }

      return uniqueArray;
    }, []);

    filterRest = { ...filterRest, ...newRest };
    filterCountry = { ...filterCountry, ...newCountry };
    filterBrandId = { ...filterBrandId, ...newBrand };
    filterSubCategory = { ...filterSubCategory, ...newSubCategoriesFilter };
    filterCuisineType = { ...filterCuisineType, ...newCuisineType };
    originStateCountry = { ...originStateCountry, ...filterCountry };
    originStateRest = { ...originStateRest, ...filterRest };
    originBrandId = { ...originBrandId, ...filterBrandId };
    originSubCategory = { ...originSubCategory, ...filterSubCategory };
    originCuisineType = { ...originCuisineType, ...filterCuisineType };
    originStateRest = { ...originStateRest, ...filterRest };

    const sortedBrands = (newBrands as Array<any>).sort((a, b) => a.name.localeCompare(b.name));
    const sortedSubCategories = (newSubCategories as Array<any>).sort((a, b) =>
      a.name.localeCompare(b.name),
    );
    const sortedCuisineTypes = (newCuisineTypes as Array<any>).sort((a, b) =>
      a.name.localeCompare(b.name),
    );

    this.setState({ relationTree: relationTree });
    this.setState({ brands: sortedBrands });
    this.setState({ subCategories: sortedSubCategories });
    this.setState({ cuisineTypes: sortedCuisineTypes });
    this.setState({ filterRest: filterRest });
    this.setState({ filterCountry: filterCountry });
    this.setState({ filterBrandId: filterBrandId });
    this.setState({ filterSubCategory: filterSubCategory });
    this.setState({ filterCuisineType: filterCuisineType });
    this.setState({ originStateRest: this.toTrue(originStateRest) });
    this.setState({ originStateCountry: this.toTrue(originStateCountry) });
    this.setState({ originBrandId: this.toTrue(originBrandId) });
    this.setState({ originSubCategory: this.toTrue(originSubCategory) });
    this.setState({ originCuisineType: this.toTrue(originCuisineType) });
  }

  toTrue = (data) => {
    const updatedData = {};
    for (const key in data) {
      updatedData[key] = true;
    }
    return updatedData;
  };

  handleChange(e, tabValue) {
    window.scrollTo(0, 0);
    this.setState({ setTab: tabValue });
  }

  getCurrentIndex() {
    const { setTab } = this.state;
    if (setTab) return setTab;
    else return 0;
  }
  /* functions for getting window size */
  ref = React.createRef() as any;

  handleWindowResize = _.debounce(() => {
    const windowSize = this.ref.current.getBoundingClientRect();
    this.setState({ windowSize });
  });

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({
      width: window.innerWidth,
    });
  };

  public async componentDidMount() {
    //this.setState({ isLoading: true });
    try {
      setCurrentPage('charts');
    } catch (err) {
      console.log(err, '-----error-----');
    }
    const { getLocationRegions, servers } = this.props;
    const { user } = servers;
    await getLocationRegions({});

    const query: any = {
      offset: 0,
      limit: 10000,
      searchText: '',
      order: [['updatedAt', 'DESC']],
      totalCounts: 0,
      where: {
        hidden: false,
        $or: [{ category: ECategoryType.DISTRIBUTOR }, { category: ECategoryType.RESTAURANT }],
      },
    };
    await getLocations(query);

    if (this.state.width > 1024) this.setState({ enableFilter: true });

    //const { Op } = require('sequelize');
    //const setDefaultPeriodValue= this.setDefaultPeriod()
    query.where = {
      //...{ dateTime: { $gte: formatDate(this.setDefaultPeriod()) } },
      ...{ latestScanAt: { $gte: formatDate(this.setDefaultPeriod()) } },
      ...(user.location.category === ECategoryType.BREWERY && { brewLocId: user.locationId }),
      ...(user.location.category === ECategoryType.DISTRIBUTOR && { distLocId: user.locationId }),
      ...(user.location.category === ECategoryType.RESTAURANT && { locId: user.locationId }),
    };

    await this.fetchAnalysis(query, true);
    this.setState({ query: query });

    // get window size used for controling graph size
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    //this.setState({ isLoading: false });
  }

  /* functions for filter */
  handleChangeFilterBrand(v) {
    this.clearFilterTimeout();

    const { name, checked } = v;
    this.clearFilterTimeout();
    this.setState(
      (prevState) => {
        const filterBrandId = { ...prevState.filterBrandId };
        if (!filterBrandId[name]) {
          filterBrandId[name] = checked;
        } else {
          filterBrandId[name] = false;
        }
        return { filterBrandId };
      },
      () => {
        const { filterBrandId } = this.state;
        const newFilter = [];
        for (const name in filterBrandId) {
          if (filterBrandId[name]) {
            newFilter.push(name);
          }
        }
        const filter = {
          brandId: { $or: newFilter },
        };
        this.setFilter(filter);
        this.setInvFilter(filter);
      },
    );
  }

  handleChangeFilterDistDestination(v, country) {
    const { relationTree } = this.state;
    const { name, checked } = v;
    Object.values(relationTree.Countries[country].Dists[name].Rests).map((rest: any) => {
      this.handleChangeFilterRestDestination({ name: rest.id, checked: checked });
    });
  }

  handleChangeFilterRestDestination(v) {
    const { name, checked } = v;
    const { servers } = this.props;

    this.setState(
      (prevState) => {
        const filterRest = prevState.filterRest;
        filterRest[name] = checked;
        return { filterRest };
      },
      () => {
        const { filterRest } = this.state;
        const filter = [];
        for (const name in filterRest) {
          if (filterRest[name]) {
            servers.allLocations?.map((locObj) => {
              if (locObj.id === Number(name)) {
                if (locObj.category === 3) {
                  filter.push({ locId: name });
                } else filter.push({ locId: name });
              }
            });
          }
        }

        //always include user's own locationId
        const { user } = servers;
        let baseWhere = {
          ...(user.location.category === ECategoryType.BREWERY && {
            locId: user.locationId.toString(),
          }),
          ...(user.location.category === ECategoryType.DISTRIBUTOR && {
            locId: user.locationId.toString(),
          }),
          ...(user.location.category === ECategoryType.RESTAURANT && {
            locId: user.locationId.toString(),
          }),
        };

        filter.push(baseWhere);

        const brewLocs = servers.allLocations?.filter((obj) => {
          return obj.category === 1;
        });

        // for admins, include all brewery ids
        if (user.location.category === ECategoryType.ADMIN) {
          brewLocs.map((locObj) => {
            filter.push({ locId: locObj.id.toString() });
          });
        }

        this.setFilter({ $or: filter });
        this.setInvFilter({ $or: filter });
      },
    );
  }

  async handleClickResetFilter() {
    //this.setState({ isLoading: true });

    const {
      originStateRest,
      originStateCountry,
      originBrandId,
      originSubCategory,
      originCuisineType,
    } = this.state;
    this.setState({
      filterCreatedAt: [this.setDefaultPeriod(), this.today],
      filterSalesOrder: '',
      filterDestinationId: '',
      filterBrandId: originBrandId,
      filterCountry: originStateCountry,
      filterSubCategory: originSubCategory,
      filterCuisineType: originCuisineType,
      filterDist: '',
      filterRest: originStateRest,
    });
    const { servers } = this.props;
    const { user } = servers;
    const query = {
      ...servers.analysisQuery,
      where: {
        latestScanAt: { $gte: formatDate(this.setDefaultPeriod()) },
        ...(user.location.category === ECategoryType.BREWERY && { brewLocId: user.locationId }),
        ...(user.location.category === ECategoryType.DISTRIBUTOR && { distLocId: user.locationId }),
        ...(user.location.category === ECategoryType.RESTAURANT && { locId: user.locationId }),
      },
    };
    this.updateQuery(query);
    const invQuery = {
      searchText: null,
      where: {
        ...(user.location.category === ECategoryType.BREWERY && { brewLocId: user.locationId }),
        ...(user.location.category === ECategoryType.DISTRIBUTOR && { distLocId: user.locationId }),
        ...(user.location.category === ECategoryType.RESTAURANT && { locId: user.locationId }),
      },
    };

    this.updateInvQuery(invQuery);
    //Double call to fatchAnalysis so the graph can be updated
    setTimeout(() => this.fetchAnalysis(query), 400);
    await this.fetchAnalysis(query);

    //this.setState({ isLoading: false });
  }

  async handleClickSearch() {
    //this.setState({ isLoading: true });

    const { query } = this.state;

    if (this.filterCheck()) {
      await this.setState({ initialized: false }, () => {
        this.fetchAnalysis(query);
      });
    } else {
      this.setState({
        inventoryRows: [],
        transactionRows: [],
      });

      //In case filterCheck is false (all of the checkboxes are unchecked)
      const invalidQuery = {
        ...query,
        where: {
          brewLocId: null,
        },
      };
      await this.setState({ initialized: false }, () => {
        this.fetchAnalysis(invalidQuery);
      });
    }
    //this.setState({ isLoading: false });
  }

  filterCheck() {
    const lists = [
      this.state.filterBrandId,
      //this.state.filterCountry,
      this.state.filterRest,
      this.state.filterSubCategory,
      this.state.filterCuisineType,
      this.state.filterRest,
    ];

    // Check if there is at least one "true" value in each list
    const result = lists.every((list) => Object.values(list).some((value) => value === true));
    return result;
  }

  handleChangeFilterCountry(v) {
    const { relationTree } = this.state;
    const { name, checked } = v;
    Object.values(relationTree.Countries[name].Dists).map((dist: any) => {
      this.handleChangeFilterDistDestination({ name: dist.id, checked: checked }, name);
    });
    this.setState((prevState) => {
      const filterCountry = { ...prevState.filterCountry };
      filterCountry[name] = checked;
      return { filterCountry };
    });
  }

  handleChangeCreatedAt(v) {
    this.handleChangeTime('filterCreatedAt', 'latestScanAt', v);
  }

  handleChangeTime(filterKey, dbKey, v) {
    this.clearFilterTimeout();
    const from = v && v.length && new Date(v[0].setHours(0, 0, 0));
    const to = v && v.length && new Date(v[1].setHours(23, 59, 59));
    const newState: Partial<State> = {};

    newState[filterKey] = v;

    newState.filterTimeout = setTimeout(() => {
      if (v.length) {
        this.setFilter({ [dbKey]: { $gte: from.toISOString(), $lte: to.toISOString() } });
      } else {
        this.unsetFilter(dbKey);
      }
    }, 1000);

    this.setState(newState as State);
  }

  protected handleChangeSubCategory(v) {
    this.clearFilterTimeout();
    const { name, checked } = v;
    this.clearFilterTimeout();
    this.setState(
      (prevState) => {
        const filterSubCategory = { ...prevState.filterSubCategory };
        if (!filterSubCategory[name]) {
          filterSubCategory[name] = checked;
        } else {
          filterSubCategory[name] = false;
        }
        return { filterSubCategory };
      },
      () => {
        const { filterSubCategory } = this.state;
        const newFilter = [];
        for (const name in filterSubCategory) {
          if (filterSubCategory[name]) {
            newFilter.push(name);
          }
        }
        const filter = {
          subCategory: { $or: newFilter },
        };
        this.setFilter(filter);
        this.setInvFilter(filter);
      },
    );
  }

  protected handleChangeFilterCuisineType(v) {
    this.clearFilterTimeout();
    const { name, checked } = v;
    this.clearFilterTimeout();
    this.setState(
      (prevState) => {
        const filterCuisineType = { ...prevState.filterCuisineType };
        if (!filterCuisineType[name]) {
          filterCuisineType[name] = checked;
        } else {
          filterCuisineType[name] = false;
        }
        return { filterCuisineType };
      },
      () => {
        const { filterCuisineType } = this.state;
        const newFilter = [];
        for (const name in filterCuisineType) {
          if (filterCuisineType[name]) {
            newFilter.push(name);
          }
        }
        const filter = {
          cuisineType: { $or: newFilter },
        };
        this.setFilter(filter);
        this.setInvFilter(filter);
      },
    );
  }

  clearFilterTimeout() {
    const { filterTimeout } = this.state;

    if (filterTimeout) clearTimeout(filterTimeout);
  }

  setFilter(newWhere) {
    const { query } = this.state;
    const where = { ...query.where, ...newWhere };
    const queryUpd = Object.assign({}, query, { where });
    this.updateQuery(queryUpd);
    this.setState({ filterTimeout: null });
  }

  setInvFilter(newWhere) {
    const { inv_query } = this.state;
    const where = { ...inv_query.where, ...newWhere };
    const invQuery = Object.assign({}, inv_query, { where });
    this.updateInvQuery(invQuery);
  }

  unsetFilter(key) {
    const { servers } = this.props;
    const { where } = servers.analysisQuery;
    if (where[key]) delete where[key];
    this.setFilter(where);
  }

  aggregateInv_ave(data, key, targetCol) {
    const group = _.groupBy(data, (value) => {
      return value[key] + '#' + value.brandName;
    });

    const group_aggregated = _.map(group, (obj) => {
      return {
        name: obj[0][key],
        brand: obj[0].brandName,
        val: _.meanBy(obj, targetCol),
      };
    });

    return group_aggregated;
  }

  aggregateInv_sum(data, key) {
    const group = _.groupBy(data, (value) => {
      return value[key] + '#' + value.brandName;
    });

    const group_aggregated = _.map(group, (obj) => {
      return {
        name: obj[0][key],
        brand: obj[0].brandName,
        val: obj.length,
      };
    });

    return group_aggregated;
  }

  protected chartsView() {
    const { apps, servers, classes } = this.props;
    const { transactionRows, inventoryRows } = this.state;
    const _ = require('lodash');
    const lang = apps.currentLanguage;
    const {
      brandList,
      cardData_inv_brew,
      cardData_inv_dist_rest,
      cardData_inv_countryDist,
      cardData_inv_dist,
      cardData_inv_rest,
      cardData_sales,
      cardData_sales_privatecustomer,
      barData_invTime,
      barData_shipNum,
      barData_shipLocNum,
    } = this.state;

    /*** -------------------------functions for graph------------------------- ***/
    //create color lists for barchart
    let assignedColors = new Set(); // To keep track of assigned colors
    const colorlist_gold = [
      '#f3aa18',
      '#FFDD00',
      '#FFBB00',
      '#FF9900',
      '#FF7700',
      '#f8be4d',
      '#FFDD00',
      '#FFBB00',
      '#FF9900',
      '#FF7700',
    ];
    const colorlist_black = [
      '#565656',
      '#999999',
      '#B2B2B2',
      '#CCCCCC',
      '#E5E5E5',
      '#F5F5F5',
      '#FFFFFF',
      '#666666',
      '#4D4D4D',
      '#000000',
    ];
    const colorlist_red = [
      '#de0101',
      '#ff9090',
      '#FFB2B2',
      '#FFD1D1',
      '#FFE5E5',
      '#FFBEBE',
      '#FFAAAA',
      '#FF9797',
      '#FF8484',
      '#ca5b5b',
    ];
    /*const colorlist_N_vintage = [
      '#4035d6',
      '#5588FF',
      '#77AAFF',
      '#99CCFF',
      '#BBDFFF',
      '#DDD2FF',
      '#FFC5FF',
      '#FFB8FF',
      '#FFABFF',
      '#FF9EFF',
    ];*/
    const colorlist_N_vintage = [
      '#c47f39',
      '#BEB539',
      '#AEE933',
      '#44FF0D',
      '#34A312',
      '#CD853F',
      '#BEB539',
      '#AEE933',
      '#44FF0D',
      '#34A312',
    ];
    const colorlist_N = [
      '#0066cc',
      '#63a8fc',
      '#80AAFF',
      '#A0BFFF',
      '#C0D9FF',
      '#E0E4FF',
      '#F0F2FF',
      '#FAFAFF',
      '#F5F6FF',
      '#F0F1FF',
    ];
    const colorlist_ranbiki = [
      '#8c3c85',
      '#aa568f',
      '#c7709a',
      '#e28aa4',
      '#ff9eb0',
      '#ffb8bf',
      '#ffccd0',
      '#ffe0e4',
      '#fff4f7',
      '#6e2172',
    ];
    const colorlist_other = [
      '#cb787d',
      '#f8be4d',
      '#8dd9e2',
      '#5facce',
      '#5c9f84',
      '#395a5c',
      '#ffd6d9',
      '#f8f4b5',
      '#c4f6fc',
      '#9bb6ff',
      '#9efed8',
      '#78898a',
      '#fa0314',
      '#fdec00',
      '#01e6ff',
      '#0044ff',
      '#03ff9a',
      '#023f42',
    ];

    function assignColor(text, colorlist) {
      for (let color of colorlist) {
        if (!assignedColors.has(color)) {
          assignedColors.add(color);
          return color;
        }
      }
      return null;
    }

    const barColors = [];

    if (brandList.length > 0) {
      for (let item of brandList) {
        let color;

        if (item.startsWith('Gold')) {
          color = assignColor(item, colorlist_gold);
        } else if (item.startsWith('Black')) {
          color = assignColor(item, colorlist_black);
        } else if (item.startsWith('Red')) {
          color = assignColor(item, colorlist_red);
        } else if (item.startsWith('N by Hidetoshi Nakata Vintage')) {
          color = assignColor(item, colorlist_N_vintage);
        } else if (item.startsWith('N by Hidetoshi Nakata')) {
          color = assignColor(item, colorlist_N);
        } else if (item.startsWith('Ranbiki-shu')) {
          color = assignColor(item, colorlist_ranbiki);
        } else {
          color = assignColor(item, colorlist_other);
        }
        barColors.push(color);
        /*
        if (color !== null) {
          console.log(`${item}: ${color}`);
        } else {
          console.log(`${item}: No available color`);
        }
        */
      }
    }

    const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
      const { x, y, payload } = props;
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor='end' fill='#666' transform='rotate(-35)'>
            {payload.value}
          </text>
        </g>
      );
    };

    const CustomizedLabel: FunctionComponent<any> = (props: any) => {
      const { x, y, stroke, value } = props;
      return (
        <text x={x} y={y} dy={-4} fill={stroke} fontSize={8} textAnchor='middle'>
          {value}
        </text>
      );
    };

    const { width } = this.state;

    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className='custom-tooltip'>
            <p className='label'>{`${label} : ${payload[0].value}`}</p>
          </div>
        );
      }

      return null;
    };

    const insertTags_bars = (labelList) => {
      let tag = [];
      for (let i = 0; i < labelList.length; i++) {
        tag.push(
          <Bar
            dataKey={labelList[i]}
            //stackId='a'
            //fill={color_list[i]}
            fill={barColors[i]}
            maxBarSize={25}
            minBarSize={20}
            radius={[3, 3, 3, 3]}
          >
            <LabelList dataKey={labelList[i]} position='center' fontSize={12} />
          </Bar>,
        );
      }
      return tag;
    };

    const insertTags_barchart = (data, labelList, yAxisLabel) => {
      let tag = [];
      tag.push(
        <ResponsiveContainer width={'100%'} height={'100%'}>
          <BarChart
            data={data}
            style={{ fontSize: '15px' }}
            margin={{
              top: 0,
              right: 10,
              left: 30,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='name' interval={0} height={60} tick={<CustomizedAxisTick />} />
            <YAxis
              label={{
                value: yAxisLabel,
                angle: -90,
                position: 'insideBottomLeft',
              }}
            />
            <Tooltip />
            <Legend verticalAlign='top' align='right' fontSize={15} />
            {/*inset tags for each brands */}
            {insertTags_bars(labelList)}
            <Brush
              className='BarChart-brush'
              dataKey='name'
              stroke='gray'
              height={15}
              startIndex={
                width > 564
                  ? data.length > 6
                    ? data.length - 6
                    : 0
                  : data.length > 3
                  ? data.length - 3
                  : 0
              }
            />
          </BarChart>
        </ResponsiveContainer>,
      );

      return tag;
    };

    const CustomCard = styled(Card)(({ theme }) => ({
      border: '1px solid darkgray',
    }));

    const insertTags_cards_4items = (data) => {
      let tag = [];
      _.map(data, (obj) => {
        tag.push(
          <CustomCard className={styles.cardContentSalesBrew} elevation={2}>
            <p className={styles.cardItemTitleSales}>{obj.name}</p>
            <div className={styles.cardContentSales4RowItems}>
              <Grid
                container
                spacing={3}
                justify='center'
                alignContent='center'
                alignItems='center'
              >
                <Grid item xs={6}>
                  <p className={styles.cardItemName}>{i18n.totalSales[lang]}</p>
                </Grid>
                <Grid item xs={6}>
                  <h4 className={styles.cardItemValueSalesBrew}>{obj.count}</h4>
                </Grid>
              </Grid>
            </div>
            <div className={styles.cardContentSales4RowItems}>
              <Grid
                container
                spacing={3}
                justify='center'
                alignContent='center'
                alignItems='center'
              >
                <Grid item xs={6}>
                  <p className={styles.cardItemName}>{i18n.countryNum[lang]}</p>
                </Grid>
                <Grid item xs={6}>
                  <h4 className={styles.cardItemValueSalesBrew}>{obj.country_count}</h4>
                </Grid>
              </Grid>
            </div>
            <div className={styles.cardContentSales4RowItems}>
              <Grid
                container
                spacing={3}
                justify='center'
                alignContent='center'
                alignItems='center'
              >
                <Grid item xs={6}>
                  <p className={styles.cardItemName}>{i18n.distNum[lang]}</p>
                </Grid>
                <Grid item xs={6}>
                  <h4 className={styles.cardItemValueSalesBrew}>{obj.dist_count}</h4>
                </Grid>
              </Grid>
            </div>

            <div className={styles.cardContentSales4RowItems}>
              <Grid
                container
                spacing={3}
                justify='center'
                alignContent='center'
                alignItems='center'
              >
                <Grid item xs={6}>
                  <p className={styles.cardItemName}>{i18n.restNum[lang]}</p>
                </Grid>
                <Grid item xs={6}>
                  <h4 className={styles.cardItemValueSalesBrew}>{obj.rest_count}</h4>
                </Grid>
              </Grid>
            </div>
          </CustomCard>,
        );
      });
      return tag;
    };

    const insertTags_cards_2items = (data) => {
      let tag = [];
      _.map(data, (obj) => {
        tag.push(
          <CustomCard className={styles.cardContentSales2Items} elevation={2}>
            <p className={styles.cardItemTitleSales}>{obj.name}</p>
            <div className={styles.cardContentSales2RowItems}>
              <Grid
                container
                spacing={3}
                justify='center'
                alignContent='center'
                alignItems='center'
              >
                <Grid item xs={6}>
                  <p className={styles.cardItemName}>{i18n.bottleNum[lang]}</p>
                </Grid>
                <Grid item xs={6}>
                  <h4 className={styles.cardItemValueSalesBrew}>{obj.count}</h4>
                </Grid>
              </Grid>
            </div>
            <div className={styles.cardContentSales2RowItems}>
              <Grid
                container
                spacing={3}
                justify='center'
                alignContent='center'
                alignItems='center'
              >
                <Grid item xs={6}>
                  <p className={styles.cardItemName}>{i18n.restNum[lang]}</p>
                </Grid>
                <Grid item xs={6}>
                  <h4 className={styles.cardItemValueSalesBrew}>{obj.loc_count}</h4>
                </Grid>
              </Grid>
            </div>
          </CustomCard>,
        );
      });
      return tag;
    };

    const insertTags_cards_1item = (data) => {
      let tag = [];
      _.map(data, (obj) => {
        tag.push(
          <CustomCard className={styles.cardContentSales} elevation={2}>
            <p className={styles.cardItemTitleSales}>{obj.name}</p>
            <div className={styles.cardContentSalesLowerRow}>
              <Grid
                container
                spacing={3}
                justify-content='center'
                alignContent='center'
                alignItems='center'
              >
                <Grid item xs={12}>
                  <h4 className={styles.cardItemValueSales}>{obj.count}</h4>
                </Grid>
                <Grid item xs={12}>
                  <p className={styles.cardItemName1Item}>{i18n.bottleNum[lang]}</p>
                </Grid>
              </Grid>
            </div>
          </CustomCard>,
        );
      });
      return tag;
    };

    const insertTags_cards_1item_shadow = (data) => {
      let tag = [];
      _.map(data, (obj) => {
        tag.push(
          <CustomCard className={styles.cardContentSales} elevation={6}>
            <p className={styles.cardItemTitleSales}>{obj.name}</p>
            <div className={styles.cardContentSalesLowerRow}>
              <Grid
                container
                spacing={3}
                justify-content='center'
                alignContent='center'
                alignItems='center'
              >
                <Grid item xs={12}>
                  <h4 className={styles.cardItemValueSales}>{obj.count}</h4>
                </Grid>
                <Grid item xs={12}>
                  <p className={styles.cardItemName1Item}>{i18n.bottleNum[lang]}</p>
                </Grid>
              </Grid>
            </div>
          </CustomCard>,
        );
      });
      return tag;
    };

    const insertTags_cards_eachDist = (data) => {
      let tag = [];
      _.map(data, (obj) => {
        tag.push(
          <CustomCard className={styles.cardContentCountryDist} elevation={2}>
            <p className={styles.cardItemTitleCountryDist}>
              {obj.distCountry} / {obj.distLocName}
            </p>

            <Divider />
            <p className={styles.cardItemTitleBrand}>{obj.brandName}</p>

            <div className={styles.cardContentSalesLowerRow}>
              <Grid
                container
                spacing={3}
                justify-content='center'
                alignContent='center'
                alignItems='center'
              >
                <Grid item xs={12}>
                  <h4 className={styles.cardItemValueSales}>
                    {obj.totalInv}
                    {/* ({obj.restInv}) */}
                  </h4>
                </Grid>
                <Grid item xs={12}>
                  <p className={styles.cardItemName1Item}>{i18n.bottleNum[lang]}</p>
                </Grid>
              </Grid>
            </div>
          </CustomCard>,
        );
      });
      return tag;
    };

    const insertTags_cards_countryDist = (data) => {
      let tag = [];
      _.map(data, (obj) => {
        tag.push(
          <h5
            style={{
              color: 'red',
              textDecoration: 'underline',
              fontSize: '1.1em',
              //fontWeight: 'normal',
              marginTop: '5px',
              marginBottom: '5px',
              marginLeft: '10px',
            }}
          >
            {obj[0].distCountry} / {obj[0].distLocName}
          </h5>,
        );
        tag.push(<div className={styles.cardInv}>{insertTags_cards_eachDist(obj)}</div>);
      });
      return tag;
    };

    const manage_insertTags_cards_inv = () => {
      let tag = [];
      const category = getUserCategory(servers.user);
      switch (category) {
        case ECategoryType.ADMIN:
        case ECategoryType.BREWERY:
          tag.push(
            <>
              <h4 className={styles.graphtitle}>{i18n.brewInventory[lang]}</h4>
              <div className={styles.cardGroupInv} ref={this.ref}>
                <div className={styles.cardInv} ref={this.ref}>
                  {insertTags_cards_1item(cardData_inv_brew)}
                </div>
              </div>

              {/** 出荷先在庫総数→非表示に変更
              <div className={styles.cardDivider} />
              
              <h4 className={styles.graphtitle}>{i18n.shipDestInventory[lang]}</h4>
              <div className={styles.description}>
                <h5>
                  <span>
                    <EmojiObjectsOutlinedIcon className={styles.bulbIcon} />
                  </span>
                  {i18n.analysisDescription14[lang]}
                </h5>
              </div>
              
              <div className={styles.cardGroupInv} ref={this.ref}>
                <div className={styles.cardInv} ref={this.ref}>
                  {insertTags_cards_1item(cardData_inv_dist_rest)}
                </div>
              </div>
              */}

              <div className={styles.cardDivider} />

              <h4 className={styles.graphtitle}>{i18n.distBrandInventory[lang]}</h4>
              <div className={styles.description}>
                <h5>
                  <span>
                    <EmojiObjectsOutlinedIcon className={styles.bulbIcon} />
                  </span>
                  {i18n.analysisDescription17[lang]}
                </h5>
              </div>
              <div className={styles.cardGroupInv} ref={this.ref}>
                <div className={styles.cardsCountryDistAlign} ref={this.ref}>
                  {insertTags_cards_countryDist(cardData_inv_countryDist)}
                </div>
              </div>
            </>,
          );
          break;
        case ECategoryType.DISTRIBUTOR:
          tag.push(
            <>
              {/** 未販売在庫総数→非表示に変更
              <h4 className={styles.graphtitle}>{i18n.unsoldInventory[lang]}</h4>
              <div className={styles.description}>
                <h5>
                  <span>
                    <EmojiObjectsOutlinedIcon className={styles.bulbIcon} />
                  </span>
                  {i18n.analysisDescription14[lang]}
                </h5>
              </div>
              <div className={styles.cardGroupInv} ref={this.ref}>
                <div className={styles.cardInv} ref={this.ref}>
                  {insertTags_cards_1item_shadow(cardData_inv_dist_rest)}
                </div>
              </div>
              */}
              <h4 className={styles.graphtitle}>{i18n.selfInventory[lang]}</h4>
              <div className={styles.description}>
                <h5>
                  <span>
                    <EmojiObjectsOutlinedIcon className={styles.bulbIcon} />
                  </span>
                  {i18n.analysisDescription15[lang]}
                </h5>
              </div>
              <div className={styles.cardGroupInv} ref={this.ref}>
                <div className={styles.cardInv} ref={this.ref}>
                  {insertTags_cards_1item(cardData_inv_dist)}
                </div>
              </div>

              <div className={styles.cardDivider} />

              <h4 className={styles.graphtitle}>{i18n.restInventory[lang]}</h4>
              <div className={styles.description}>
                <h5>
                  <span>
                    <EmojiObjectsOutlinedIcon className={styles.bulbIcon} />
                  </span>
                  {i18n.analysisDescription16[lang]}
                </h5>
              </div>
              <div className={styles.cardGroupInv} ref={this.ref}>
                <div className={styles.cardInv} ref={this.ref}>
                  {insertTags_cards_2items(cardData_inv_rest)}
                </div>
              </div>
            </>,
          );
          break;
        case ECategoryType.RESTAURANT:
          tag.push(
            <>
              <div className={styles.cardGroupInv} ref={this.ref}>
                <div className={styles.cardInv} ref={this.ref}>
                  {insertTags_cards_1item(cardData_inv_rest)}
                </div>
              </div>
            </>,
          );
          break;
      }
      return tag;
    };

    const manage_insertTags_cards_privatecustomer = () => {
      let tag = [];
      const category = getUserCategory(servers.user);
      switch (category) {
        case ECategoryType.ADMIN:
        case ECategoryType.BREWERY:
          break;
        case ECategoryType.DISTRIBUTOR:
          tag.push(
            <>
              <h4 className={styles.graphtitle}>
                {i18n.totalSales[lang] + i18n.privateCustomer[lang]}
              </h4>
              <div className={styles.description}>
                <h5>
                  <span>
                    <EmojiObjectsOutlinedIcon className={styles.bulbIcon} />
                  </span>
                  {i18n.analysisDescription12[lang]}
                </h5>
              </div>
              <div className={styles.cardGroupInv} ref={this.ref}>
                <div className={styles.cardInv} ref={this.ref}>
                  {insertTags_cards_1item(cardData_sales_privatecustomer)}
                </div>
              </div>
            </>,
          );
          break;
        case ECategoryType.RESTAURANT:
          break;
      }
      return tag;
    };

    const manage_insertTags_cards_sales = () => {
      let tag = [];
      const category = getUserCategory(servers.user);
      switch (category) {
        case ECategoryType.ADMIN:
        case ECategoryType.BREWERY:
          tag.push(insertTags_cards_4items(cardData_sales));
          break;
        case ECategoryType.DISTRIBUTOR:
          tag.push(insertTags_cards_2items(cardData_sales));
          break;
        case ECategoryType.RESTAURANT:
          tag.push(insertTags_cards_1item(cardData_sales));
          break;
      }
      return tag;
    };

    const manage_insertTags_barchart_locNum = () => {
      let tag = [];
      const category = getUserCategory(servers.user);
      switch (category) {
        case ECategoryType.ADMIN:
        case ECategoryType.BREWERY:
        case ECategoryType.DISTRIBUTOR:
          tag.push(
            <>
              <h4 className={styles.graphtitle}>{i18n.soldLocNum[lang]}</h4>
              <div className={styles.description}>
                <h5>
                  <span>
                    <EmojiObjectsOutlinedIcon className={styles.bulbIcon} />
                  </span>
                  {i18n.analysisDescription13[lang]}
                </h5>
              </div>
              <div className={styles.wrapperInv} ref={this.ref}>
                <div className={styles.graphInv}>
                  {insertTags_barchart(barData_shipLocNum, brandList, i18n.invGraphYAxis3[lang])}
                </div>
              </div>
            </>,
          );
          break;
        case ECategoryType.RESTAURANT:
          break;
      }
      return tag;
    };

    const manage_insertTags_title_totalSales = () => {
      let tag = [];
      const category = getUserCategory(servers.user);

      if (category === ECategoryType.DISTRIBUTOR) {
        tag.push(
          <h4 className={styles.graphtitle}>
            {i18n.totalSales[lang] + '(' + i18n.onTrade[lang] + ')'}
          </h4>,
        );
      } else {
        tag.push(<h4 className={styles.graphtitle}>{i18n.totalSales[lang]}</h4>);
      }
      return tag;
    };

    //Change the display contents of the description for each user
    let description6, description7;
    const category = getUserCategory(servers.user);
    switch (category) {
      case ECategoryType.ADMIN:
      case ECategoryType.BREWERY:
      case ECategoryType.DISTRIBUTOR:
        description6 = i18n.analysisDescription6[lang];
        description7 = i18n.analysisDescription7[lang];
        break;
      case ECategoryType.RESTAURANT:
        description6 = i18n.analysisDescription6Res[lang];
        description7 = i18n.analysisDescription7Res[lang];
        break;
    }

    return (
      servers.user && (
        <main className={classes.content}>
          <div className={styles.outerFrame}>
            <h3 className={styles.chaptertitle}>{i18n.inventory[lang]}</h3>
            <div className={styles.description}>
              <h4>
                <span>
                  <EmojiObjectsOutlinedIcon className={styles.bulbIcon} />
                </span>
                {description6}
              </h4>
            </div>

            {manage_insertTags_cards_inv()}

            <h3 className={styles.chaptertitle}>{i18n.inventoryTurnoverPeriod[lang]}</h3>
            <div className={styles.description}>
              <h5>
                <span>
                  <EmojiObjectsOutlinedIcon className={styles.bulbIcon} />
                </span>
                {i18n.analysisDescription10[lang]}
              </h5>
            </div>
            <div className={styles.wrapperInv} ref={this.ref}>
              <div className={styles.graphInv}>
                {insertTags_barchart(barData_invTime, brandList, i18n.invGraphYAxis2[lang])}
              </div>
            </div>

            <h3 className={styles.chaptertitle}>{i18n.salesPerformance[lang]}</h3>
            <div className={styles.description}>
              <h4>
                <span>
                  <EmojiObjectsOutlinedIcon className={styles.bulbIcon} />
                </span>
                {description7}
              </h4>
            </div>

            {manage_insertTags_title_totalSales()}
            <div className={styles.description}>
              <h5>
                <span>
                  <EmojiObjectsOutlinedIcon className={styles.bulbIcon} />
                </span>
                {i18n.analysisDescription8[lang]}
              </h5>
            </div>
            <div className={styles.cardGroupInv} ref={this.ref}>
              <div className={styles.cardInv} ref={this.ref}>
                {manage_insertTags_cards_sales()}
              </div>
            </div>

            {manage_insertTags_cards_privatecustomer()}

            <h4 className={styles.graphtitle}>{i18n.soldBottleNum[lang]}</h4>
            <div className={styles.description}>
              <h5>
                <span>
                  <EmojiObjectsOutlinedIcon className={styles.bulbIcon} />
                </span>
                {i18n.analysisDescription9[lang]}
              </h5>
            </div>
            <div className={styles.wrapperInv} ref={this.ref}>
              <div className={styles.graphInv}>
                {insertTags_barchart(barData_shipNum, brandList, i18n.invGraphYAxis1[lang])}
              </div>
            </div>

            {manage_insertTags_barchart_locNum()}
          </div>

          <div className={styles.footerMargin} />
        </main>
      )
    );
  }

  countryUserFilter() {
    const { servers } = this.props;
    const { filterRest, relationTree } = this.state;
    const countries = Object.keys(relationTree.Countries);
    const { user } = servers;
    const category = getUserCategory(user);

    const allRests: any[] = [];

    countries.map((country) => {
      const dists = relationTree.Countries[country].Dists;

      Object.values(dists).forEach((dist: any) => {
        const rests = Object.values(dist.Rests)
          .filter((rest: any) => rest.id) // Filter out rests without an ID
          .sort((a: any, b: any) => a.name.localeCompare(b.name));

        allRests.push(...rests);
      });
    });

    // Remove duplicate rests based on their ID
    const uniqueRests = Array.from(new Set(allRests.map((rest) => rest.id))).map((id) =>
      allRests.find((rest) => rest.id === id),
    );

    // Sort the allDists array by name
    uniqueRests.sort((a, b) => a.name.localeCompare(b.name));

    return (
      <div>
        {countries ? (
          category === ECategoryType.DISTRIBUTOR ? (
            <div>
              {uniqueRests.map((rest: any) => (
                <div className={styles.filterStyle} key={rest.name}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterRest[rest.id]}
                        onClick={(e) =>
                          this.handleChangeFilterRestDestination(e.target ? e.target : 0)
                        }
                        name={rest.id}
                      />
                    }
                    label={rest.name}
                    style={{ margin: '0px' }} // reduce top and bottom margin to 0
                  />
                </div>
              ))}
            </div>
          ) : (
            // for brewery
            <div className={styles.filterStyle}>
              {countries
                .sort((a, b) => a.localeCompare(b))
                .map((country) => {
                  const dists = Object.values(
                    relationTree.Countries[country].Dists,
                  ).sort((a: any, b: any) => a.name.localeCompare(b.name));

                  return (
                    <Accordion style={{ boxShadow: 'none' }} key={country}>
                      <AccordionSummary
                        style={{ minHeight: '40px' }}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.countryCheck(country)}
                              indeterminate={this.countryIntermediate(country)}
                              onChange={(e) => this.handleChangeFilterCountry(e.target)}
                              name={country}
                            />
                          }
                          label={country}
                          style={{ margin: '0px' }} // reduce top and bottom margin to 0
                        />
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: '0px 16px 0px' }}>
                        <div className={styles.filterStyle2}>
                          {dists.map((dist: any) => {
                            const rests = Object.values(dist.Rests)
                              .filter((rest: any) => rest.id) // Filter out rests without an ID
                              .sort((a: any, b: any) => a.name.localeCompare(b.name));

                            return (
                              <Accordion style={{ boxShadow: 'none' }} key={dist.id}>
                                <AccordionSummary
                                  style={{ minHeight: '40px' }}
                                  expandIcon={<ExpandMoreIcon />}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.distCheck(country, dist.id)}
                                        indeterminate={this.distIntermediate(country, dist.id)}
                                        onChange={(e) =>
                                          this.handleChangeFilterDistDestination(
                                            e.target ? e.target : 0,
                                            country,
                                          )
                                        }
                                        name={dist.id}
                                      />
                                    }
                                    label={dist.name}
                                    style={{ margin: '0px' }} // reduce top and bottom margin to 0
                                  />
                                </AccordionSummary>
                                <AccordionDetails style={{ padding: '0px 32px 0px' }}>
                                  <div className={styles.filterStyle2}>
                                    {Object.values(rests).map((rest: any) => {
                                      if (rest.id) {
                                        return (
                                          <div key={rest.id}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={filterRest[rest.id]}
                                                  onClick={(e) =>
                                                    this.handleChangeFilterRestDestination(
                                                      e.target ? e.target : 0,
                                                    )
                                                  }
                                                  name={rest.id}
                                                />
                                              }
                                              label={rest.name}
                                              style={{ margin: '0px' }} // reduce top and bottom margin to 0
                                            />
                                          </div>
                                        );
                                      }
                                    })}
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            );
                          })}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
            </div>
          )
        ) : (
          ''
        )}
      </div>
    );
  }

  distIntermediate(country: string, id: any): boolean {
    const { relationTree, filterRest } = this.state;
    let intermediate = false;
    const dist = relationTree.Countries[country].Dists[id];
    const rests = dist.Rests;
    if (!this.distCheck(country, id)) {
      Object.values(rests).map((rest: any) => {
        if (filterRest[rest.id]) {
          intermediate = true;
        }
      });
    }
    return intermediate;
  }

  distCheck(country: string, id: any): boolean {
    const { relationTree, filterRest } = this.state;
    let check = true;
    const dist = relationTree.Countries[country].Dists[id];
    const rests = dist.Rests;
    if (!this.countryCheck(country)) {
      Object.values(rests).map((rest: any) => {
        if (!filterRest[rest.id]) {
          check = false;
        }
      });
    }
    return check;
  }

  countryIntermediate(country: string): boolean {
    const { relationTree, filterRest, filterCountry } = this.state;
    let intermediate = false;
    Object.values(relationTree.Countries[country].Dists).map((dist: any) => {
      const rests = dist.Rests;
      if (!this.countryCheck(country)) {
        Object.values(rests).map((rest: any) => {
          if (filterRest[rest.id]) {
            intermediate = true;
          }
        });
      }
    });
    return intermediate;
  }

  countryCheck(country: string): boolean {
    const { relationTree, filterRest, filterCountry } = this.state;
    let check = true;
    Object.values(relationTree.Countries[country].Dists).map((dist: any) => {
      const rests = dist.Rests;
      Object.values(rests).map((rest: any) => {
        if (!filterRest[rest.id]) {
          check = false;
        }
      });
    });
    return check;
  }

  render(): JSX.Element {
    const { apps, servers, classes } = this.props;
    const {
      enableFilter,
      filterCreatedAt,
      filterSalesOrder,
      filterDestinationId,
      filterBrandId,
      filterCountry,
      filterSubCategory,
      filterCuisineType,
      brands,
      subCategories,
      cuisineTypes,
    } = this.state;
    const { user } = servers;
    const category = getUserCategory(user);
    const lang = apps.currentLanguage;
    const { width } = this.state;
    const insertTags_datePickerWidth = (windowWidth) => {
      if (windowWidth >= 1500) {
        return '270px';
      } else if (windowWidth >= 1024 && windowWidth < 1500) {
        return '265px';
      } else if (windowWidth >= 576 && windowWidth < 1500) {
        return '800px';
      } else if (windowWidth < 576) {
        return '400px';
      }
    };
    const filterView = (
      <>
        <Grid container alignItems='flex-end'>
          <Grid item>
            <Box ml={2} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            {getDateRangePicker2(
              i18n.period[lang],
              filterCreatedAt,
              (v) => this.handleChangeCreatedAt(v),
              lang,
              true, //disable ruture
              insertTags_datePickerWidth(width),
            )}
          </Grid>
          {category === ECategoryType.RESTAURANT ? (
            <>
              <Grid item xs={12}>
                <Box mt={1} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Accordion
                  defaultExpanded={true}
                  style={{ border: '1px solid lightgray', marginTop: '5px' }}
                >
                  <AccordionSummary
                    style={{ maxHeight: '40px', minHeight: '40px' }}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>{i18n.brand[lang]}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: '0px 16px 10px' }}>
                    <div>
                      {Object.values(brands).map((brand: any) => {
                        return (
                          <div key={brand.id}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={filterBrandId[brand.id]}
                                  onChange={(e) => this.handleChangeFilterBrand(e ? e.target : 0)}
                                  name={brand.id.toString()}
                                />
                              }
                              label={brand.name}
                              style={{ padding: '0px 16px 0px' }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Box mt={1} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Accordion
                  defaultExpanded={true}
                  style={{ border: '1px solid lightgray', marginTop: '5px' }}
                >
                  <AccordionSummary
                    style={{ maxHeight: '40px', minHeight: '40px' }}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>
                      {category === ECategoryType.DISTRIBUTOR
                        ? appLanguages.user[lang]
                        : appLanguages.regionUser[lang]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: '0px 16px 10px' }}>
                    <div>{this.countryUserFilter()}</div>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid item xs={12}>
                <Box mt={1} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Accordion
                  defaultExpanded={true}
                  style={{ border: '1px solid lightgray', marginTop: '5px' }}
                >
                  <AccordionSummary
                    style={{ maxHeight: '40px', minHeight: '40px' }}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>{i18n.brand[lang]}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: '0px 16px 10px' }}>
                    <div>
                      {Object.values(brands).map((brand: any) => {
                        return (
                          <div key={brand.id}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={filterBrandId[brand.id]}
                                  onChange={(e) => this.handleChangeFilterBrand(e ? e.target : 0)}
                                  name={brand.id.toString()}
                                />
                              }
                              label={brand.name}
                              style={{ padding: '0px 16px 0px' }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid item xs={12}>
                <Box mt={1} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Accordion
                  defaultExpanded={true}
                  style={{ border: '1px solid lightgray', marginTop: '5px' }}
                >
                  <AccordionSummary
                    style={{ maxHeight: '40px', minHeight: '40px' }}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>{i18n.cuisineType[lang]}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: '0px 16px 10px' }}>
                    <div>
                      {Object.values(cuisineTypes).map((cuisineType: any) => {
                        return (
                          <div key={cuisineType.id}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={filterCuisineType[cuisineType.id]}
                                  onChange={(e) =>
                                    this.handleChangeFilterCuisineType(e ? e.target : 0)
                                  }
                                  name={cuisineType.id.toString()}
                                />
                              }
                              label={cuisineType.name}
                              style={{ margin: '0px', wordBreak: 'break-all' }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Box mt={1} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Accordion
                  defaultExpanded={true}
                  style={{ border: '1px solid lightgray', marginTop: '5px' }}
                >
                  <AccordionSummary
                    style={{ maxHeight: '40px', minHeight: '40px' }}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>{appLanguages.locationBusinessType[lang]}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: '0px 16px 10px' }}>
                    <div>
                      {Object.values(subCategories).map((subCategorie: any) => {
                        //filteredRests
                        return (
                          <div key={subCategorie.id}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={filterSubCategory[subCategorie.id]}
                                  onChange={(e) => this.handleChangeSubCategory(e ? e.target : 0)}
                                  name={subCategorie.id}
                                />
                              }
                              label={subCategorie.name}
                              style={{ margin: '0px', wordBreak: 'break-all' }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </>
          )}
        </Grid>
        {filterCreatedAt.length ||
        filterCountry ||
        filterSubCategory ||
        filterCuisineType ||
        filterSalesOrder ||
        filterDestinationId ||
        filterBrandId ? (
          <Grid container justify='flex-end'>
            <Grid item xs={12}>
              <Box mt={1} />
            </Grid>
            <Grid item>
              {getSmallClearButton(i18n.reset[lang], false, () => this.handleClickResetFilter())}
            </Grid>
            <Grid item style={{ marginLeft: '16px' }}>
              {getSmallClearButton2(appLanguages.search[lang], false, () =>
                this.handleClickSearch(),
              )}
            </Grid>
          </Grid>
        ) : (
          ''
        )}
      </>
    );

    return !this.state.isLoading ? (
      <>
        <Grid container spacing={2} alignItems='stretch'>
          {enableFilter ? (
            <Grid item xs={12} sm={12} md={3} xl={2}>
              {getFilterButton(
                enableFilter,
                () =>
                  this.setState({ enableFilter: !enableFilter }, () => {
                    window.scrollTo(0, 0);
                  }),
                lang,
              )}
              {filterView}
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
              {getFilterButton(
                enableFilter,
                () =>
                  this.setState({ enableFilter: !enableFilter }, () => {
                    window.scrollTo(0, 0);
                  }),
                lang,
              )}
              {this.chartsView()}
            </Grid>
          )}
          {enableFilter ? (
            <Grid item xs={12} sm={12} md={9} xl={10}>
              {this.chartsView()}
            </Grid>
          ) : null}
        </Grid>
      </>
    ) : (
      <Grid container justify='center'>
        <CircularProgress className={classes.progress} />
      </Grid>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  history: History<LocationState>;
  classes: any;
  setTab: number;
  mobileOpen?: boolean;
  filterTimeout: any;
  enableFilter: boolean;
  initialized: boolean;
  serialsList: any;
  serialsTotalCounts: 0;
  filterCreatedAt: any;
  filterSalesOrder: any;
  filterDestinationId: any;
  filterBrandId: any;
  filterSubCategory: any;
  filterCuisineType: any;
  filterDist: any;
  filterRest: any;
  originStateRest: any;
  originStateCountry: any;
  originBrandId: any;
  originSubCategory: any;
  originCuisineType: any;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

export interface State {
  setTab: number;
  mobileOpen?: boolean;
  filterTimeout: any;
  enableFilter: boolean;
  initialized: boolean;
  serialsList: any;
  serialsTotalCounts: 0;
  filterCreatedAt: any;
  filterSalesOrder: any;
  filterDestinationId: any;
  filterBrandId: any;
  transactionRows: any;
  inventoryRows: any;
  filterData: any;
  relationTree: any;
  brands: any;
  subCategories: any;
  cuisineTypes: any;
  filterCountry: any;
  filterSubCategory: any;
  filterCuisineType: any;
  filterDist: any;
  filterRest: any;
  originStateRest: any;
  originStateCountry: any;
  originBrandId: any;
  originSubCategory: any;
  originCuisineType: any;
  query: any;
  inv_query: any;
  windowSize: {
    width: number;
    height: number;
  };
  width: any;
  isLoading: boolean;
  brandList: any;
  cardData_inv_brew: any;
  cardData_inv_dist_rest: any;
  cardData_inv_countryDist: any;
  cardData_inv_dist: any;
  cardData_inv_rest: any;
  cardData_sales: any;
  cardData_sales_privatecustomer: any;
  barData_invTime: any;
  barData_shipNum: any;
  barData_shipLocNum: any;
}

export interface IDispatchProps {
  setCurrentPage: (pageId: AllPages) => void;
  setMonitoringFilter: (selectedSortItem: number, selectedStatus: EAlertStatus[]) => void;
  setAnalysisQuery: (query: IQuery) => void;
  getTransaction: (query: IQuery) => any;
  getInventory: (query: IQuery) => any;
  getLocationRegions: (query: IQuery) => void;
}

const mapDispatchToProps: IDispatchProps = {
  setCurrentPage,
  setMonitoringFilter,
  setAnalysisQuery,
  getTransaction,
  getInventory,
  getLocationRegions,
};

const myStyles = (theme: Theme): StyleRules => ({
  content: { width: '100%', marginLeft: 'auto', marginRight: 'auto' },
  toolbar: { paddingTop: '68px' },
  noneTextTransform: { textTransform: 'none' },
  appBar: { marginTop: '64px', backgroundColor: '#15355c', flexDirection: 'row' },
  card: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    border: '2px solid',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  gridContainer: {
    minWidth: '236px',
  },
});

export const StockManagement = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(StockManagementClass);
