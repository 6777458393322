import { TextDisplay } from '@visual/text-display';
import React from 'react';
import { DateRangePicker as DateRangePickerRSuite } from 'rsuite';
import styles from './style.scss';

export class DateRangePicker extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  public static defaultProps: Partial<Props> = {
    size: 'fit',
    isPlaceholderLabel: false,
  };

  public render(): JSX.Element {
    const { size, placeholder, isPlaceholderLabel } = this.props;
    const { value } = this.state;
    return (
      <div className={`${styles.dateRangePicker} ${styles[size]}`}>
        {isPlaceholderLabel && (
          <>
            <TextDisplay size='x-small' color='black'>
              {placeholder}:
            </TextDisplay>
            <br />
          </>
        )}
        <DateRangePickerRSuite
          placeholder={placeholder}
          size='sm'
          format='YYYY/MM/DD'
          value={value || undefined}
          onChange={(newValue: [Date, Date]) => this.handleOnChange(newValue)}
          css={{
            zIndex: 1500,
          }}
        />
      </div>
    );
  }

  protected handleOnChange(newValue: [Date, Date]) {
    const { onChange } = this.props;
    this.setState({ value: newValue });
    if (!onChange) return;
    onChange(newValue);
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  value?: [Date, Date];
  placeholder?: string;
  size?: 'full' | 'fit';
  isPlaceholderLabel?: boolean;
}

export interface IDispatchProps {
  onChange?: (dateRange: [Date, Date]) => void;
}

interface State {
  value?: [Date, Date];
}
