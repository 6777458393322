import { i18n } from '@i18n/lang';
import { ListPages } from '@models/all-pages';
import { ECategoryType } from '@models/category-type';
import { IStateApps } from '@models/state-apps';
import { IStateServers } from '@models/state-servers';
import { TSupportedLanguages } from '@models/supported-languages';
import { isOk } from '@utils/is-ok';
import { Item } from '@visual/search-filter-popup';

/*
  C1_流通履歴
  ==========
  期間（酒蔵出荷）
  銘柄
  拠点 (酒蔵, 酒販店, レストラン, VIP)
  伝票番号
  シリアル番号
  ステータス
*/

export function getFields(
  lang: TSupportedLanguages,
  apps: IStateApps,
  servers: IStateServers,
): Item[] {
  const { allLocations, allBrands } = servers;
  const { filterSelectedValues: selVal } = apps;
  const pageName: ListPages = 'distribution-history';
  return [
    {
      id: 0,
      selectedValue: selVal && selVal[pageName] && selVal[pageName][0],
      fieldName: 'brewShipAt',
      type: 'date-range',
      placeholder: i18n.brewShipAt[lang],
    },
    {
      id: 1,
      selectedValue: selVal && selVal[pageName] && selVal[pageName][1],
      fieldName: 'brandId',
      type: 'select',
      placeholder: `${i18n.brand[lang]}${i18n.inputSelect[lang]}`,
      selectData: isOk(allBrands)
        ? allBrands.map((brand) => ({ text: brand.name, id: brand.id }))
        : [],
    },
    {
      id: 2,
      selectedValue: selVal && selVal[pageName] && selVal[pageName][2],
      fieldName: 'brewLocId',
      type: 'select',
      placeholder: `${i18n.brewery[lang]}${i18n.inputSelect[lang]}`,
      selectData: isOk(allLocations)
        ? allLocations
            .filter((loc) => loc.category === ECategoryType.BREWERY)
            .map((location) => ({ text: location.name, id: location.id }))
        : [],
    },
    {
      id: 3,
      selectedValue: selVal && selVal[pageName] && selVal[pageName][3],
      fieldName: 'distLocId',
      type: 'select',
      placeholder: `${i18n.distributor[lang]}${i18n.inputSelect[lang]}`,
      selectData: isOk(allLocations)
        ? allLocations
            .filter((loc) => loc.category === ECategoryType.DISTRIBUTOR)
            .map((location) => ({ text: location.name, id: location.id }))
        : [],
    },
    {
      id: 4,
      selectedValue: selVal && selVal[pageName] && selVal[pageName][4],
      fieldName: 'restLocId',
      type: 'select',
      placeholder: `${i18n.restaurant[lang]}${i18n.inputSelect[lang]}`,
      selectData: isOk(allLocations)
        ? allLocations
            .filter((loc) => loc.category === ECategoryType.RESTAURANT)
            .map((location) => ({ text: location.name, id: location.id }))
        : [],
    },
    {
      id: 5,
      selectedValue: selVal && selVal[pageName] && selVal[pageName][5],
      fieldName: 'code',
      type: 'text',
      placeholder: i18n.serialNo[lang],
    },
    {
      id: 6,
      selectedValue: selVal && selVal[pageName] && selVal[pageName][6],
      fieldName: 'slip',
      type: 'text',
      placeholder: i18n.slipNo[lang],
    },
  ];
}
