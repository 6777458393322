import {
  AppBar,
  Box,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Slide,
  StyleRules,
  TextField,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { SlideProps } from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import { Close as CloseIcon, Videocam as VideocamIcon } from '@material-ui/icons';
import { IStateApps } from '@models/state-apps';
import { IStateAuth } from '@models/state-auth';
import { closeVideoCallDialog } from '@redux/actions/appsActions';
import { IStoreState } from '@redux/reducers';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Peer from 'skyway-js';

const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => (
  <Slide direction='left' ref={ref} {...props} />
));

/**
 * 参考1. (1対１バージョン): https://webrtc.ecl.ntt.com/documents/javascript-sdk.html
 * 参考2.（Roomバージョン）: https://qiita.com/yusuke84/items/54dce88f9e896903e64f
 */
class VideoCallDialog extends React.PureComponent<Props, State> {
  private peer;

  private static setEventLister(mediaConnection) {
    // Peer イベントリスナを設置
    mediaConnection.on('stream', (stream) => {
      // video要素にカメラ映像をセットして再生
      const videoElm: HTMLMediaElement = window.document.getElementById(
        'other-video-call',
      ) as HTMLMediaElement;
      videoElm.srcObject = stream;
      videoElm.play();
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      myPeerId: '',
      otherPeerId: '',
      localStream: undefined,
    };
    // Peer の初期化
    this.peer = new Peer({
      key: 'ea683bfb-9d98-4576-b3ed-a1fb942df2d6',
      debug: 0,
    });
  }

  componentDidMount() {
    // カメラ映像取得
    window.navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        // 成功時にvideo要素にカメラ映像をセットし、再生
        const videoElm: HTMLMediaElement = window.document.getElementById(
          'my-video-call',
        ) as HTMLMediaElement;
        videoElm.srcObject = stream;
        videoElm.play();
        // 着信時に相手にカメラ映像を返せるように、stateに保存しておく
        this.setState({ localStream: stream });
      })
      .catch((error) => {
        // 失敗時にはエラーログを出力
        console.error('mediaDevice.getUserMedia() error:', error);
      });

    // PeerID 取得
    this.peer.on('open', () => this.setState({ myPeerId: this.peer.id }));

    // Peer 着信処理
    this.peer.on('call', (mediaConnection) => {
      const { localStream } = this.state;
      if (!localStream) {
        console.warn("Can't receive an incoming call.");
        return;
      }
      mediaConnection.answer(localStream);
      VideoCallDialog.setEventLister(mediaConnection);
    });

    this.peer.on('error', (err) => {
      // TODO
      // eslint-disable-next-line no-alert
      window.alert(err.message);
    });

    this.peer.on('close', () => {
      // TODO
      // eslint-disable-next-line no-alert
      window.alert('通信が切断しました。');
    });
  }

  componentWillUnmount() {
    // Peer 終了処理
    const { localStream } = this.state;
    if (localStream) localStream.getTracks().forEach((track) => track.stop());
    const myVideo: HTMLMediaElement = window.document.getElementById(
      'my-video-call',
    ) as HTMLMediaElement;
    myVideo.srcObject = null;
    const otherVideo: HTMLMediaElement = window.document.getElementById(
      'other-video-call',
    ) as HTMLMediaElement;
    otherVideo.srcObject = null;
  }

  handleRequestVideoCall() {
    // Peer 発信処理
    const { otherPeerId, localStream } = this.state;
    if (!otherPeerId || !localStream) {
      console.warn("Can't start a video call.");
      return;
    }
    const mediaConnection = this.peer.call(otherPeerId, localStream);
    VideoCallDialog.setEventLister(mediaConnection);
  }

  render() {
    const { classes, apps, auth, closeVideoCallDialog } = this.props;
    const { myPeerId, otherPeerId } = this.state;
    const { isRequesting } = auth;
    return (
      <Dialog
        fullWidth
        open={apps.isOpenVideoCallDialog}
        onClose={() => closeVideoCallDialog()}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Grid container justify='center' alignItems='center' alignContent='center'>
              <Grid item xs>
                <IconButton
                  edge='start'
                  color='inherit'
                  onClick={() => closeVideoCallDialog()}
                  aria-label='Close'
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs>
                <Grid container justify='center' alignItems='center' alignContent='center'>
                  <Typography variant='h6' className={classes.title}>
                    Video Call
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs />
            </Grid>
          </Toolbar>
        </AppBar>
        {isRequesting ? <LinearProgress color='secondary' /> : ''}
        <Box mt={1} />
        <DialogContent>
          <Divider />
          <Paper className={classes.paper}>{`自身の接続ID: ${myPeerId}`}</Paper>
          <Box mb={1} />
          <Grid container wrap='nowrap'>
            <Grid item>
              <Paper elevation={3}>
                <video id='my-video-call' width='100%' autoPlay muted playsInline />
              </Paper>
            </Grid>
            <Grid item>
              <Paper elevation={3}>
                <video id='other-video-call' width='100%' autoPlay muted playsInline />
              </Paper>
            </Grid>
          </Grid>
          <Box mb={3} />
          <Grid container wrap='nowrap'>
            <Grid item xs>
              <TextField
                fullWidth
                label='相手の接続ID'
                value={otherPeerId}
                onChange={(e) => this.setState({ otherPeerId: e.target.value })}
              />
            </Grid>
            <Grid item>
              <IconButton
                edge='end'
                disabled={!this.peer || !myPeerId}
                onClick={() => this.handleRequestVideoCall()}
              >
                <VideocamIcon color='primary' fontSize='large' />
              </IconButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  classes: any;
  auth: IStateAuth;
  apps: IStateApps;
}

export interface IDispatchProps {
  closeVideoCallDialog: () => void;
}

interface State {
  myPeerId: string;
  otherPeerId: string;
  localStream: any;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  auth: state.auth,
  apps: state.apps,
});

const mapDispatchToProps: IDispatchProps = {
  closeVideoCallDialog,
};

const myStyles = (theme: Theme): StyleRules => ({
  appBar: { position: 'relative' },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

export default compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(VideoCallDialog);
