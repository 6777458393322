import { IStateApps } from '@models/state-apps';
import { IStateServers } from '@models/state-servers';
import { TSupportedLanguages } from '@models/supported-languages';
import { Item } from '@visual/search-filter-popup';

/*
  E_ダッシュボード
  =============
  キーワード
  テーマ
  期間
  銘柄
  拠点
*/

export function getFields(
  lang: TSupportedLanguages,
  apps: IStateApps,
  servers: IStateServers,
): Item[] {
  return [];
}
