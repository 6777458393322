import { isOk } from '@utils/is-ok';
import { TextDisplay } from '@visual/text-display';
import * as hash from 'object-hash';
import React from 'react';
import styles from './style.scss';

export class DistributionDetail extends React.Component<Props> {
  public static defaultProps: Partial<Props> = {
    detailData: [],
  };

  public render(): JSX.Element {
    return <div className={styles.detail}>{this.getDetail()}</div>;
  }

  protected getDetail(): JSX.Element {
    const { detailData } = this.props;

    const displayDetailData = detailData.filter((data) => data);
    const detailContent = displayDetailData.map((data, i) => (
      <div className={styles.detailRow} key={hash(i)}>
        <span className={styles.detailCell}>
          <TextDisplay>{data.label}</TextDisplay>
        </span>
        <span className={styles.detailCell}>
          <TextDisplay>：{data.value}</TextDisplay>
        </span>
      </div>
    ));

    return <>{isOk(detailContent) ? detailContent : <div className={styles.detailEmpty} />}</>;
  }
}

export type Props = IStateProps;

export interface IStateProps {
  detailData?: DetailContent[];
}

interface DetailContent {
  label: string;
  value: string;
}
