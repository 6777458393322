import { CircularLoading } from '@container/components/circular-loading';
import { SearchFilter } from '@container/components/search-filter';
import { i18n } from '@i18n/lang';
import { Grid as MaterialGrid, LinearProgress } from '@material-ui/core';
import { StyleRules, Theme, withStyles } from '@material-ui/core/styles';
import { AllPages, ListPages } from '@models/all-pages';
import { ECategoryType } from '@models/category-type';
import { IQuery } from '@models/query';
import { ISerial } from '@models/serial';
import { ESerialStatus } from '@models/serial-status-type';
import { IStateApps } from '@models/state-apps';
import { IStateAuth } from '@models/state-auth';
import { IStateServers } from '@models/state-servers';
import { setCurrentPage } from '@redux/actions/appsActions';
import { getAdditionalSerials, getSerials, setSerialsQuery } from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import { AppRoutePaths } from '@utils/app-route-paths';
import { LIST_PAGE_SCROLL_LOAD_LIMIT } from '@utils/config';
import { DefaultQuery } from '@utils/default-query';
import { formatDate } from '@utils/format-date';
import { getUserCategory, isUserBreweryOrAdmin } from '@utils/get-user-category';
import { isOk } from '@utils/is-ok';
import { ColoredBackground } from '@visual/colored-background';
import { Icon } from '@visual/icon';
import { ListPageItem } from '@visual/list-page-item';
import { History, LocationState } from 'history';
import * as hash from 'object-hash';
import React from 'react';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { compose } from 'redux';

/**
 * @returns {*}
 * @constructor
 */
class DistributionHistoryListClass extends React.PureComponent<Props> {
  protected static readonly CALLER_PAGE: ListPages = 'distribution-history';

  public componentDidMount() {
    const { getSerials, servers, setSerialsQuery, setCurrentPage } = this.props;

    setCurrentPage('distribution-history');

    // Initial default query
    const query = { ...DefaultQuery };
    const userPermission = getUserCategory(servers?.user);
    const userLocId = servers?.user?.location?.id;

    if (!isUserBreweryOrAdmin(servers?.user)) {
      query.where = {
        ...query.where,
        ...(userPermission === ECategoryType.BREWERY && { brewLocId: userLocId }),
        ...(userPermission === ECategoryType.DISTRIBUTOR && { distLocId: userLocId }),
        ...(userPermission === ECategoryType.RESTAURANT && { restLocId: userLocId }),
      };
    }

    setSerialsQuery(query);
    getSerials(query);
  }

  public render(): JSX.Element {
    const { servers, classes, auth } = this.props;
    const isRequesting =
      servers.isRequesting ||
      servers.isGetRequesting ||
      auth.isRequesting ||
      !isOk(servers?.user) ||
      !servers?.allBrands ||
      !servers?.allLocations;

    return (
      <ColoredBackground>
        <div className={classes.toolbar} />
        {isRequesting ? <CircularLoading /> : this.getContents()}
      </ColoredBackground>
    );
  }

  protected getContents(): JSX.Element {
    const {
      classes,
      servers: { isAdditionalGetRequesting },
    } = this.props;
    return (
      <>
        <SearchFilter caller='distribution-history' />
        <div className={classes.toolbar} />
        <MaterialGrid container justify='center' alignContent='center' alignItems='center'>
          {this.getDistributionHistoryList()}
        </MaterialGrid>
        {isAdditionalGetRequesting && <LinearProgress color='secondary' />}
      </>
    );
  }

  protected loadNextDataSet() {
    const {
      servers: { serialsQuery, serials },
      setSerialsQuery,
      getAdditionalSerials,
    } = this.props;
    const newQuery: IQuery = serialsQuery ? { ...serialsQuery } : { ...DefaultQuery };
    newQuery.offset = serials.length;
    newQuery.limit = LIST_PAGE_SCROLL_LOAD_LIMIT;
    setSerialsQuery(newQuery);
    getAdditionalSerials(newQuery);
  }

  protected handleOnClick(serialId: number) {
    const { history } = this.props;

    return history.push(`${AppRoutePaths['distribution-detail'].link}/${serialId}`);
  }

  protected getDistributionHistoryList() {
    const {
      apps: { currentLanguage: appLang },
      servers,
    } = this.props;
    const serials: ISerial[] = (servers && servers.serials) || [];

    return serials.map((serialInfo, i) => {
      const {
        brand: { name: brandName },
        code,
        brewShipAt,
        id,
        status,
      } = serialInfo;

      return (
        <React.Fragment key={hash(i)}>
          <ListPageItem
            title={brandName}
            subInfos={[
              { label: i18n.serialNo[appLang], value: code },
              { label: i18n.brewShipmentDate[appLang], value: formatDate(brewShipAt) },
            ]}
            endIcon={<Icon type='right' />}
            idForClick={id}
            subTitles={[this.getStatusText(status)]}
            onClick={(serialId) => this.handleOnClick(Number(serialId))}
          />
          {i === serials.length - 1 && <Waypoint onEnter={() => this.loadNextDataSet()} />}
        </React.Fragment>
      );
    });
  }

  protected getStatusText(status: ESerialStatus) {
    if (!status) return;
    const {
      apps: { currentLanguage: lang },
    } = this.props;
    switch (status) {
      case ESerialStatus.SHIPPED_FROM_BREWERY:
        return i18n.shippedFromBrewery[lang];
      case ESerialStatus.DISTRIBUTOR_INSTOCK:
        return i18n.distributorInstock[lang];
      case ESerialStatus.DISTRIBUTOR_DISPATCHED:
        return i18n.distributorDispatched[lang];
      case ESerialStatus.RESTAURANT_INSTOCK:
        return i18n.restaurantInstock[lang];
      case ESerialStatus.PURCHASED:
        return i18n.purchasedByConsumer[lang];
    }
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  auth: IStateAuth;
  history?: History<LocationState>;
  classes: any;
}

export interface IDispatchProps {
  getSerials: (query: any) => void;
  setSerialsQuery: (query: any) => void;
  getAdditionalSerials: (query: any) => void;
  setCurrentPage: (pageId: AllPages) => void;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
  auth: state.auth,
});

const mapDispatchToProps: IDispatchProps = {
  getSerials,
  getAdditionalSerials,
  setSerialsQuery,
  setCurrentPage,
};

const myStyles = (theme: Theme): StyleRules => ({
  toolbar: { paddingTop: '68px' },
});

export const DistributionHistoryList = compose<any>(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(DistributionHistoryListClass);
