import { RestaurantStatusTable } from '@container/components/restaurant-status-table';
import { AllPages } from '@models/all-pages';
import { IStateApps } from '@models/state-apps';
import { IStateAuth } from '@models/state-auth';
import { IStateServers } from '@models/state-servers';
import { setCurrentPage, setSelectedSidePanelOptionId } from '@redux/actions/appsActions';
import { IStoreState } from '@redux/reducers';
import { SearchFilterLink } from '@visual/search-filter-link';
import { History, LocationState } from 'history';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import styles from './style.scss';

class RestaurantManagementClass extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
  }

  public componentDidMount() {
    this.props.setSelectedSidePanelOptionId(4);
    this.props.setCurrentPage('restaurant-management');
  }

  public render(): JSX.Element {
    return <div className={styles.container}>{this.getContents()}</div>;
  }

  protected getContents(): JSX.Element {
    const {
      apps: { currentLanguage: lang },
    } = this.props;
    return (
      <>
        <SearchFilterLink lang={lang} />
        <div className={styles.tableContainer}>
          <RestaurantStatusTable />
        </div>
      </>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  auth: IStateAuth;
  classes: any;
  history?: History<LocationState>;
}

export interface IDispatchProps {
  setSelectedSidePanelOptionId: (id: number) => void;
  setCurrentPage: (pageId: AllPages) => void;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
  auth: state.auth,
});

const mapDispatchToProps: IDispatchProps = { setSelectedSidePanelOptionId, setCurrentPage };

export const RestaurantManagement = compose<any>(connect(mapStateToProps, mapDispatchToProps))(
  withRouter(RestaurantManagementClass),
);
