import WhiteLinearProgress from '@elements/WhiteLinearProgress';
import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputBase,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { StyleRules, Theme, withStyles } from '@material-ui/core/styles';
import { Clear as ClearIcon, Search as SearchIcon } from '@material-ui/icons';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { AvatarGroup } from '@material-ui/lab';
import { IQuery } from '@models/query';
import { IStateApps } from '@models/state-apps';
import { IStateServers } from '@models/state-servers';
import {
  getChatRooms,
  getChatUsers,
  getOrCreateChatRoom,
  setChatRoom,
  setChatRoomsQuery,
  setChatUsersQuery,
} from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import appLanguages from '@utils/app-languages';
import { isUserAdmin } from '@utils/get-user-category';
import * as cliTruncate from 'cli-truncate';
import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import userEnv from 'userEnv';
import { ChatMessages } from './ChatMessages';
import { ChatMessagesNone } from './ChatMessagesNone';

const TRUNCATE_DEFAULT_VALUE = 20;
const DRAWER_WIDTH = 400;

class ChatUsers extends React.PureComponent<Props, State> {
  private chatRoomsBaseQuery: IQuery;
  public static defaultProps: Partial<Props> = {
    isCollapsed: true,
    isSmall: false,
  };

  constructor(props) {
    super(props);
    const { servers } = this.props;
    this.chatRoomsBaseQuery = {
      order: [['updatedAt', 'DESC']],
      where: { ownerUserId: { $ne: servers.user.id }, memberUserId: { $ne: servers.user.id } },
    };
    this.state = {
      initialized: false,
      userSearchTimeout: null,
      userSearchKeyword: '',
      alignment: 'left',
      isCollapsed: props.isCollapsed,
      isMobile: window.innerWidth < 960,
    };
  }

  componentDidMount() {
    const { servers, getChatUsers, getChatRooms } = this.props;
    const { user } = servers;
    setTimeout(() => getChatUsers({ ...servers.chatUsersQuery }), 600);
    if (isUserAdmin(user)) {
      setTimeout(
        () => getChatRooms({ ...servers.chatRoomsQuery, ...this.chatRoomsBaseQuery }),
        600,
      );
    }
    setTimeout(() => this.setState({ initialized: true }), 1000);
  }

  handleClickUserSearch() {
    const { userSearchTimeout } = this.state;
    if (userSearchTimeout) clearTimeout(userSearchTimeout);
    const timeout = setTimeout(() => {
      const { userSearchKeyword, alignment } = this.state;
      const {
        servers,
        getChatUsers,
        setChatUsersQuery,
        getChatRooms,
        setChatRoomsQuery,
      } = this.props;
      const { user } = servers;
      const isMyRoom = alignment === 'left';
      const { chatUsersQuery, chatRoomsQuery } = servers;
      if (isMyRoom) {
        const query = { ...chatUsersQuery, searchText: userSearchKeyword };
        setChatUsersQuery(query);
        getChatUsers(query);
      } else if (isUserAdmin(user)) {
        const query = {
          ...chatRoomsQuery,
          ...this.chatRoomsBaseQuery,
          searchText: userSearchKeyword,
        };
        setChatRoomsQuery(query);
        getChatRooms(query);
      }
      this.setState({ userSearchTimeout: null });
    }, 300);
    this.setState({ userSearchTimeout: timeout });
  }

  handleClickUser(selectedUser) {
    this.onCollapseToggleClick();
    const { servers, getOrCreateChatRoom, setChatRoom } = this.props;
    const { user } = servers;
    if (!selectedUser || !user) return;
    const userId01 = user.id;
    const userId02 = selectedUser.id;
    setTimeout(() => setChatRoom(null), 0);
    setTimeout(() => getOrCreateChatRoom({ userId01, userId02 }), 10);
  }

  handleClickRoom(selectedChatRoom) {
    this.onCollapseToggleClick();
    const { setChatRoom } = this.props;
    setTimeout(() => setChatRoom(null), 0);
    setTimeout(() => setChatRoom(selectedChatRoom), 10);
  }

  handleClickAway(isClickInside) {
    if (!isClickInside) {
      const { isCollapsed } = this.state;
      const newVal = !isCollapsed;
      if (!isCollapsed) {
        this.setState({
          isCollapsed: newVal,
        });
      }
    }
  }

  onCollapseToggleClick() {
    const { onCollapseToggleClick } = this.props;
    const { isCollapsed } = this.state;
    const newVal = !isCollapsed;
    if (isCollapsed) {
      this.setState({
        isCollapsed: newVal,
      });
    }
    if (!onCollapseToggleClick) return;
    onCollapseToggleClick(newVal);
  }
  handleDrawer = () => {
    const { isCollapsed } = this.state;
    const newVal = !isCollapsed;
    this.setState({
      isCollapsed: newVal,
    });
  };

  render() {
    const { apps, servers, classes } = this.props;
    const {
      initialized,
      userSearchTimeout,
      userSearchKeyword,
      alignment,
      isCollapsed,
      isMobile,
    } = this.state;
    const isRequesting =
      servers.isRequesting || servers.isGetRequesting || Boolean(userSearchTimeout) || !initialized;
    const lang = apps.currentLanguage;
    const { categoryMapLocation } = userEnv;
    const myself = servers.user;
    const isMyRoom = alignment === 'left';
    const selectedChatRoom = servers.chatRoom;

    /*const toggleView = (
      <ToggleButtonGroup
        size='small'
        value={alignment}
        exclusive
        onChange={(e, v) => {
          if (!v) return;
          this.handleClickRoom(null);
          this.setState({ alignment: v });
        }}
      >
        <ToggleButton key={1} value='left' className={classes.toggleButton}>
          <ChatBubbleIcon />
          <Box ml={1} />
          {appLanguages.myChats[lang]}
        </ToggleButton>
        <ToggleButton key={2} value='right' className={classes.toggleButton}>
          <QuestionAnswerIcon />
          <Box ml={1} />
          {appLanguages.allUserChats[lang]}
        </ToggleButton>
      </ToggleButtonGroup>
    );*/

    const userSearchBoxView = (
      <Paper
        component='div'
        elevation={3}
        className={classes.searchRoot}
        onClick={() => this.onCollapseToggleClick()}
      >
        <IconButton
          type='submit'
          className={classes.searchIconButton}
          aria-label='search'
          onClick={() => this.handleClickUserSearch()}
        >
          <SearchIcon />
        </IconButton>
        <InputBase
          className={classes.searchInput}
          placeholder={appLanguages.searchUsers[lang]}
          value={userSearchKeyword}
          onChange={(e) => {
            this.setState({ userSearchKeyword: e.target.value });
            this.handleClickUserSearch();
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              this.handleClickUserSearch();
            }
          }}
        />
        {isRequesting ? (
          <IconButton>
            <CircularProgress size={20} color='secondary' />
          </IconButton>
        ) : (
          ''
        )}
        {userSearchKeyword ? (
          <IconButton
            type='submit'
            className={classes.searchIconButton}
            aria-label='clear'
            onClick={() => {
              this.setState({ userSearchKeyword: '' });
              this.handleClickUserSearch();
            }}
          >
            <ClearIcon />
          </IconButton>
        ) : (
          ''
        )}
      </Paper>
    );

    const searchView =
      !isCollapsed || !isMobile ? (
        <List>
          <ListItem>
            <ListItemText
              primary={
                <>
                  {/* Do not show all user chats even on Admin users */}
                  {/* isUserAdmin(user) ? toggleView : ''*/}
                  <Box mt={1} />
                  {userSearchBoxView}
                </>
              }
            />
            {isMobile ? (
              <div className={classes.toolbar}>
                <IconButton onClick={() => this.handleDrawer()}>
                  {!isCollapsed ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
              </div>
            ) : (
              ''
            )}
          </ListItem>
        </List>
      ) : (
        ''
      );

    const allChatListView = (
      <List>
        {servers.chatRooms.map((chatRoom) => {
          const { ownerUser, memberUser } = chatRoom;
          if (!ownerUser || !memberUser || ownerUser.id === memberUser.id) return '';
          // if (ownerUser.id === myself?.id || memberUser.id === myself?.id) return '';
          const selected = selectedChatRoom && selectedChatRoom.id === chatRoom.id;
          const avatarGroup = (
            <AvatarGroup max={2}>
              <Avatar variant='circle' src={ownerUser.imageUrl} className={classes.smallAvatar} />
              <Avatar variant='circle' src={memberUser.imageUrl} className={classes.smallAvatar} />
            </AvatarGroup>
          );
          return ownerUser && memberUser ? (
            <React.Fragment key={chatRoom.id}>
              <ListItem
                button
                key={chatRoom.id}
                onClick={() => this.handleClickRoom(chatRoom)}
                selected={selected}
              >
                <ListItemIcon>
                  {selected ? (
                    <Badge color='secondary' variant='dot'>
                      {avatarGroup}
                    </Badge>
                  ) : (
                    avatarGroup
                  )}
                </ListItemIcon>
                <Box ml={1} />
                {!isCollapsed || !isMobile ? (
                  <ListItemText
                    primary={
                      <>
                        <Typography variant='subtitle2'>
                          <b>{`${cliTruncate(ownerUser.name, TRUNCATE_DEFAULT_VALUE)}`}</b>
                          {ownerUser && ownerUser.location
                            ? `(${cliTruncate(
                                categoryMapLocation[lang][ownerUser.location.category],
                                TRUNCATE_DEFAULT_VALUE,
                              )})`
                            : ''}
                          {', '}
                          <b>{`${cliTruncate(memberUser.name, TRUNCATE_DEFAULT_VALUE)}`}</b>
                          {memberUser && memberUser.location
                            ? `(${cliTruncate(
                                categoryMapLocation[lang][memberUser.location.category],
                                TRUNCATE_DEFAULT_VALUE,
                              )})`
                            : ''}
                        </Typography>
                      </>
                    }
                  />
                ) : (
                  ''
                )}
              </ListItem>
              <Divider variant='middle' component='li' />
            </React.Fragment>
          ) : (
            ''
          );
        })}
      </List>
    );

    const myChatListView = (
      <List>
        {servers.chatUsers.map((chatUser) => {
          if (!chatUser || chatUser.id === myself?.id) return '';
          const { ownerUser, memberUser } = selectedChatRoom || {};
          const selected =
            ownerUser && memberUser
              ? ownerUser.id === chatUser.id || memberUser.id === chatUser.id
              : false;
          const avatar = (
            <Avatar variant='circle' src={chatUser.imageUrl} className={classes.smallAvatar} />
          );
          return (
            <React.Fragment key={chatUser.id}>
              <ListItem
                button
                key={chatUser.id}
                onClick={() => this.handleClickUser(chatUser)}
                selected={selected}
              >
                <ListItemIcon>
                  {selected ? (
                    <Badge color='secondary' variant='dot'>
                      {avatar}
                    </Badge>
                  ) : (
                    avatar
                  )}
                </ListItemIcon>
                {!isCollapsed || !isMobile ? (
                  <ListItemText
                    primary={
                      <>
                        <Typography variant='subtitle2'>
                          <b>{`${cliTruncate(chatUser.name, TRUNCATE_DEFAULT_VALUE)}`}</b>
                          {chatUser && chatUser.location
                            ? `(${cliTruncate(chatUser.location.name, TRUNCATE_DEFAULT_VALUE)})`
                            : ''}
                        </Typography>
                      </>
                    }
                  />
                ) : (
                  ''
                )}
              </ListItem>
              <Divider variant='middle' component='li' />
            </React.Fragment>
          );
        })}
      </List>
    );
    return (
      <>
        <Grid container>
          <Grid item>
            {window.addEventListener('resize', () =>
              this.setState({ isMobile: window.innerWidth < 960 }),
            )}
            <Drawer
              variant='permanent'
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: !isCollapsed || !isMobile,
                [classes.drawerClose]: isCollapsed && isMobile,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: !isCollapsed || !isMobile,
                  [classes.drawerClose]: isCollapsed && isMobile,
                }),
              }}
            >
              <Toolbar />
              <div
                id='user-list-view'
                onScroll={(e) => {
                  const html = window.document.getElementById('user-list-view');
                  const scrollTop = e.target['scrollTop'];
                  const scrollBottom = html.scrollHeight - html.clientHeight - scrollTop;
                  console.log('userListView scroll: ', scrollTop, scrollBottom);
                }}
                className={classes.drawerContainer}
              >
                {searchView}
                {isCollapsed && isMobile ? (
                  <div className={classes.toolbar}>
                    <IconButton onClick={() => this.handleDrawer()}>
                      <ChevronRightIcon />
                    </IconButton>
                  </div>
                ) : (
                  ''
                )}
                <Divider />
                {isRequesting ? <LinearProgress color='secondary' /> : <WhiteLinearProgress />}
                {isMyRoom ? myChatListView : allChatListView}
              </div>
            </Drawer>
          </Grid>
          <Grid
            item
            className={
              classes[isMobile ? 'chatMessageContainerMobile' : 'chatMessageContainerBrowser']
            }
          >
            {selectedChatRoom ? <ChatMessages /> : <ChatMessagesNone />}
          </Grid>
        </Grid>
      </>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  classes: any;
  isCollapsed?: boolean;
  isSmall?: boolean;
}

export interface IDispatchProps {
  getChatUsers: (query: IQuery) => void;
  setChatUsersQuery: (query: IQuery) => void;
  getOrCreateChatRoom: (obj: any) => void;
  setChatRoom: (obj: any) => void;
  getChatRooms: (obj: any) => void;
  setChatRoomsQuery: (query: IQuery) => void;
  onCollapseToggleClick?: (isCollapsed: boolean) => void;
  handleClickAway?: (isCollapsed: boolean) => void;
  handleResize?: (isSmall: boolean) => void;
  handleDrawer?: (isCollapsed: boolean) => void;
}

interface State {
  initialized: boolean;
  userSearchTimeout: any;
  userSearchKeyword: string;
  alignment: string;
  isCollapsed?: boolean;
  isMobile: boolean;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps: IDispatchProps = {
  getChatUsers,
  setChatUsersQuery,
  getOrCreateChatRoom,
  setChatRoom,
  getChatRooms,
  setChatRoomsQuery,
};

const drawerMinWidthSmDown = 80;
const drawerMinWidthMdUp = 400;
const drawerMinWidthLgUp = 400;
export const myStyles = (theme: Theme): StyleRules => ({
  drawerExpand: {
    minWidth: drawerMinWidthMdUp,
    maxWidth: drawerMinWidthMdUp,
  },
  chatMessageContainerMobile: {
    width: '100%',
    paddingLeft: '70px',
  },
  chatMessageContainerBrowser: {
    width: '100%',
    paddingLeft: '400px',
  },
  drawerPaper: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      minWidth: drawerMinWidthSmDown,
      maxWidth: drawerMinWidthSmDown,
    },
    [theme.breakpoints.up('md')]: { minWidth: drawerMinWidthMdUp, maxWidth: drawerMinWidthMdUp },
    [theme.breakpoints.up('lg')]: { minWidth: drawerMinWidthLgUp, maxWidth: drawerMinWidthLgUp },
  },
  drawerPaperExpand: {
    height: '100%',
    minWidth: drawerMinWidthMdUp,
    maxWidth: drawerMinWidthMdUp,
  },
  drawerContainer: { overflow: 'auto' },
  searchRoot: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  searchInput: { flex: 1 },
  searchIconButton: { padding: 10 },
  smallAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  toggleButton: {
    textTransform: 'none',
  },
  button: {
    margin: theme.spacing(1),
    textTransform: 'none',
  },
  pointer: {
    cursor: 'pointer',
  },
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    height: '100%',
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: drawerMinWidthSmDown, //width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
});

export default compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(ChatUsers);
