import { i18n } from '@i18n/lang';
import { ERestaurantProductPriceType } from '@models/restaurant-product-price-type';
import { TSupportedLanguages } from '@models/supported-languages';

export function getPriceTypeName(
  type: ERestaurantProductPriceType,
  lang: TSupportedLanguages,
  country: string,
) {
  switch (country) {
    case 'JP':
      switch (type) {
        case ERestaurantProductPriceType.UNKNOWN:
          return i18n.select[lang];
        case ERestaurantProductPriceType.LEVEL_1:
          return 'under 10,000 JPY';
        case ERestaurantProductPriceType.LEVEL_2:
          return '10,000~20,000 JPY';
        case ERestaurantProductPriceType.LEVEL_3:
          return '20,000~30,000 JPY';
        case ERestaurantProductPriceType.LEVEL_4:
          return '30,000~40,000 JPY';
        case ERestaurantProductPriceType.LEVEL_5:
          return '40,000~50,000 JPY';
        case ERestaurantProductPriceType.LEVEL_6:
          return '50,000~60,000 JPY';
        case ERestaurantProductPriceType.LEVEL_7:
          return '60,000~70,000 JPY';
        case ERestaurantProductPriceType.LEVEL_8:
          return '70,000~80,000 JPY';
        case ERestaurantProductPriceType.LEVEL_9:
          return '80,000~90,000 JPY';
        case ERestaurantProductPriceType.LEVEL_10:
          return '90,000~100,000 JPY';
        case ERestaurantProductPriceType.LEVEL_11:
          return '100,000 + JPY';
        default:
          return '';
      }
    case 'CN':
      switch (type) {
        case ERestaurantProductPriceType.UNKNOWN:
          return i18n.select[lang];
        case ERestaurantProductPriceType.LEVEL_1:
          return 'under 500 CNY';
        case ERestaurantProductPriceType.LEVEL_2:
          return '500~1,000 CNY';
        case ERestaurantProductPriceType.LEVEL_3:
          return '1,000~1,500 CNY';
        case ERestaurantProductPriceType.LEVEL_4:
          return '1,500~2,000 CNY';
        case ERestaurantProductPriceType.LEVEL_5:
          return '2,000~2,500 CNY';
        case ERestaurantProductPriceType.LEVEL_6:
          return '2,500~3,000 CNY';
        case ERestaurantProductPriceType.LEVEL_7:
          return '3,000~3,500 CNY';
        case ERestaurantProductPriceType.LEVEL_8:
          return '3,500~4,000 CNY';
        case ERestaurantProductPriceType.LEVEL_9:
          return '4,000~4,500 CNY';
        case ERestaurantProductPriceType.LEVEL_10:
          return '4,500~5,000 CNY';
        case ERestaurantProductPriceType.LEVEL_11:
          return '5,000 + CNY';
        default:
          return '';
      }
    case 'TW':
      switch (type) {
        case ERestaurantProductPriceType.UNKNOWN:
          return i18n.select[lang];
        case ERestaurantProductPriceType.LEVEL_1:
          return 'under 2,000 TWD';
        case ERestaurantProductPriceType.LEVEL_2:
          return '2,000~4,000 TWD';
        case ERestaurantProductPriceType.LEVEL_3:
          return '4,000~6,000 TWD';
        case ERestaurantProductPriceType.LEVEL_4:
          return '6,000~8,000 TWD';
        case ERestaurantProductPriceType.LEVEL_5:
          return '8,000~10,000 TWD';
        case ERestaurantProductPriceType.LEVEL_6:
          return '10,000~12,000 TWD';
        case ERestaurantProductPriceType.LEVEL_7:
          return '12,000~14,000 TWD';
        case ERestaurantProductPriceType.LEVEL_8:
          return '14,000~16,000 TWD';
        case ERestaurantProductPriceType.LEVEL_9:
          return '16,000~18,000 TWD';
        case ERestaurantProductPriceType.LEVEL_10:
          return '18,000~20,000 TWD';
        case ERestaurantProductPriceType.LEVEL_11:
          return '20,000 + TWD';
        default:
          return '';
      }
    case 'KR':
      switch (type) {
        case ERestaurantProductPriceType.UNKNOWN:
          return i18n.select[lang];
        case ERestaurantProductPriceType.LEVEL_1:
          return 'under 100,000 KRW';
        case ERestaurantProductPriceType.LEVEL_2:
          return '100,000~200,000 KRW';
        case ERestaurantProductPriceType.LEVEL_3:
          return '200,000~300,000 KRW';
        case ERestaurantProductPriceType.LEVEL_4:
          return '300,000~400,000 KRW';
        case ERestaurantProductPriceType.LEVEL_5:
          return '400,000~500,000 KRW';
        case ERestaurantProductPriceType.LEVEL_6:
          return '500,000~600,000 KRW';
        case ERestaurantProductPriceType.LEVEL_7:
          return '600,000~700,000 KRW';
        case ERestaurantProductPriceType.LEVEL_8:
          return '700,000~800,000 KRW';
        case ERestaurantProductPriceType.LEVEL_9:
          return '800,000~900,000 KRW';
        case ERestaurantProductPriceType.LEVEL_10:
          return '900,000~1,000,000 KRW';
        case ERestaurantProductPriceType.LEVEL_11:
          return '1,000,000 + KRW';
        default:
          return '';
      }
    case 'HK':
      switch (type) {
        case ERestaurantProductPriceType.UNKNOWN:
          return i18n.select[lang];
        case ERestaurantProductPriceType.LEVEL_1:
          return 'under 500 HKD';
        case ERestaurantProductPriceType.LEVEL_2:
          return '500~1,000 HKD';
        case ERestaurantProductPriceType.LEVEL_3:
          return '1,000~1,500 HKD';
        case ERestaurantProductPriceType.LEVEL_4:
          return '1,500~2,000 HKD';
        case ERestaurantProductPriceType.LEVEL_5:
          return '2,000~2,500 HKD';
        case ERestaurantProductPriceType.LEVEL_6:
          return '2,500~3,000 HKD';
        case ERestaurantProductPriceType.LEVEL_7:
          return '3,000~3,500 HKD';
        case ERestaurantProductPriceType.LEVEL_8:
          return '3,500~4,000 HKD';
        case ERestaurantProductPriceType.LEVEL_9:
          return '4,000~4,500 HKD';
        case ERestaurantProductPriceType.LEVEL_10:
          return '4,500~5,000 HKD';
        case ERestaurantProductPriceType.LEVEL_11:
          return '5,000 + HKD';
        default:
          return '';
      }
    case 'SG':
      switch (type) {
        case ERestaurantProductPriceType.UNKNOWN:
          return i18n.select[lang];
        case ERestaurantProductPriceType.LEVEL_1:
          return 'under 100 SGD';
        case ERestaurantProductPriceType.LEVEL_2:
          return '100~200 SGD';
        case ERestaurantProductPriceType.LEVEL_3:
          return '200~300 SGD';
        case ERestaurantProductPriceType.LEVEL_4:
          return '300~400 SGD';
        case ERestaurantProductPriceType.LEVEL_5:
          return '400~500 SGD';
        case ERestaurantProductPriceType.LEVEL_6:
          return '500~600 SGD';
        case ERestaurantProductPriceType.LEVEL_7:
          return '600~700 SGD';
        case ERestaurantProductPriceType.LEVEL_8:
          return '700~800 SGD';
        case ERestaurantProductPriceType.LEVEL_9:
          return '800~900 SGD';
        case ERestaurantProductPriceType.LEVEL_10:
          return '900~1,000 SGD';
        case ERestaurantProductPriceType.LEVEL_11:
          return '1,000 + SGD';
        default:
          return '';
      }
    default:
      switch (type) {
        case ERestaurantProductPriceType.UNKNOWN:
          return i18n.select[lang];
        case ERestaurantProductPriceType.LEVEL_1:
          return 'under 100 USD';
        case ERestaurantProductPriceType.LEVEL_2:
          return '100~200 USD';
        case ERestaurantProductPriceType.LEVEL_3:
          return '200~300 USD';
        case ERestaurantProductPriceType.LEVEL_4:
          return '300~400 USD';
        case ERestaurantProductPriceType.LEVEL_5:
          return '400~500 USD';
        case ERestaurantProductPriceType.LEVEL_6:
          return '500~600 USD';
        case ERestaurantProductPriceType.LEVEL_7:
          return '600~700 USD';
        case ERestaurantProductPriceType.LEVEL_8:
          return '700~800 USD';
        case ERestaurantProductPriceType.LEVEL_9:
          return '800~900 USD';
        case ERestaurantProductPriceType.LEVEL_10:
          return '900~1,000 USD';
        case ERestaurantProductPriceType.LEVEL_11:
          return '1,000 + USD';
        default:
          return '';
      }
  }
}
