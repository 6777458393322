import { i18n } from '@i18n/lang';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  StyleRules,
  Theme,
  withStyles,
} from '@material-ui/core';
import { IStateApps } from '@models/state-apps';
import { IStateServers } from '@models/state-servers';
import { openSnackbarMessage } from '@redux/actions/appsActions';
import {
  getScanIntervalTime,
  setScanDueDate,
  setScanIntervalTime,
  setScanQualityDate,
} from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import { getOutlinedButton } from '@utils/common';
import hash from 'object-hash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

class SystemSettingsDialogClass extends React.PureComponent<Props> {
  protected ScanDueDateSettings: IntervalSettings = {
    iteration: 6,
    start: 24,
    end: 96,
    type: 'hour',
    i18nKey: 'scanDueDate',
  };

  protected ScanQualityDateSettings: IntervalSettings = {
    iteration: 15,
    start: 60,
    end: 180,
    type: 'day',
    i18nKey: 'scanQualityDate',
  };

  public componentDidMount() {
    this.props.getScanIntervalTime();
  }

  public render() {
    const {
      classes,
      open,
      handleClose,
      setScanIntervalTime,
      servers: { scanIntervals, isRequesting, isGetRequesting },
      apps: { currentLanguage: lang },
      setScanDueDate,
      setScanQualityDate,
    } = this.props;

    const isLoading = isRequesting || isGetRequesting;

    return (
      <Dialog fullWidth maxWidth='sm' open={open} onClose={() => handleClose()}>
        {isLoading ? <LinearProgress color='secondary' /> : ''}
        <DialogTitle>{i18n.systemSettings[lang]}</DialogTitle>
        <DialogContent>
          <Paper elevation={3} className={classes.paper}>
            <Box mt={1} mb={1}>
              {this.getSelectBox(
                this.ScanDueDateSettings,
                scanIntervals?.scanDueDate,
                setScanDueDate,
              )}
            </Box>
            <Box mt={1} mb={1}>
              {this.getSelectBox(
                this.ScanQualityDateSettings,
                scanIntervals?.scanQualityDate,
                setScanQualityDate,
              )}
            </Box>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Grid container justify='flex-end'>
            <Grid item>
              <Box ml={0.5} mr={0.5}>
                {getOutlinedButton(i18n.cancel[lang], isLoading, () => handleClose())}
              </Box>
            </Grid>
            <Grid item>
              <Box ml={0.5} mr={0.5}>
                {getOutlinedButton(i18n.register[lang], isLoading, setScanIntervalTime)}
              </Box>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }

  private getSelectBox(
    settings: IntervalSettings,
    value: number,
    onChange: (val: number) => void,
  ): JSX.Element {
    const menuItems: JSX.Element[] = [];
    const {
      apps: { currentLanguage: lang },
    } = this.props;
    const suffix = settings.type === 'hour' ? i18n.perHour[lang] : i18n.perDay[lang];

    for (let i = settings.start; i <= settings.end; i += settings.iteration) {
      menuItems.push(<MenuItem key={hash(i)} value={i}>{`${i}${suffix}`}</MenuItem>);
    }
    const id = hash(settings.i18nKey);
    return (
      <>
        <InputLabel id={id}>{i18n[settings.i18nKey][lang]}</InputLabel>
        <Select
          labelId={id}
          id='select'
          value={value}
          onChange={(e) => onChange(Number(e.target.value))}
        >
          {menuItems}
        </Select>
      </>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  servers: IStateServers;
  apps: IStateApps;
  open?: boolean;
  classes: any;
}

export interface IDispatchProps {
  handleClose?: () => void;
  openSnackbarMessage: (type: string, message: string) => void;
  setScanDueDate: (value: number) => void;
  setScanQualityDate: (value: number) => void;
  getScanIntervalTime: () => void;
  setScanIntervalTime: () => void;
}

interface IntervalSettings {
  iteration: number;
  start: number;
  end: number;
  type: 'hour' | 'day';
  i18nKey: keyof typeof i18n;
}
const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  servers: state.servers,
  apps: state.apps,
});

const mapDispatchToProps: IDispatchProps = {
  openSnackbarMessage,
  setScanDueDate,
  setScanQualityDate,
  getScanIntervalTime,
  setScanIntervalTime,
};

const myStyles = (theme: Theme): StyleRules => ({
  paper: { padding: theme.spacing(2) },
});

export const SystemSettingsDialog = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(SystemSettingsDialogClass);
