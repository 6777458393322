import { CircularLoading } from '@container/components/circular-loading';
import ColorLinearProgress from '@elements/ColorLinearProgress';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { StyleRules, Theme, withStyles } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { IStateApps } from '@models/state-apps';
import { IStateAuth } from '@models/state-auth';
import { initializeApps, openSnackbarMessage } from '@redux/actions/appsActions';
import { loginWithCustomToken, loginWithEmail, logout } from '@redux/actions/authActions';
import { initializeServers } from '@redux/actions/serversActions';
import appLanguages from '@utils/app-languages';
import { History, LocationState } from 'history';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import userEnv from 'userEnv';

const storage = window.localStorage;

class LoginClass extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = { loginEmail: '', loginPassword: '', showPassword: false };
    const params = new URLSearchParams(window.location.search);
    //If url contains uid parameter, request custom token to login automatically.
    const encrypted_uid = params.get('uid');
    if (encrypted_uid) this.requestCustomToken(encrypted_uid);
  }

  async requestCustomToken(encrypted_uid) {
    // const { rootDir } = userEnv;
    const {
      apps,
      loginWithCustomToken,
      logout,
      initializeApps,
      initializeServers,
      history,
    } = this.props;
    const lang = apps.currentLanguage;

    if (encrypted_uid) {
      const decrypted_uid = encrypted_uid.substr(0, encrypted_uid.indexOf('_'));
      const timestamp =
        Number(encrypted_uid.substr(encrypted_uid.indexOf('_') + 1)) - userEnv.cryptoKey;
      const now = Date.now();
      if (now - timestamp > 10000 || !timestamp) {
        openSnackbarMessage('warning', appLanguages.tokenIsInvalid[lang]);
        setTimeout(() => initializeApps(), 10);
        setTimeout(() => initializeServers(), 10);
        setTimeout(() => storage.clear(), 50);
        setTimeout(() => logout(), 100);
        history.push('/');
      } else {
        fetch(userEnv.cloudFunctionUrl + '/requestCustomToken', {
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify({
            uid: decrypted_uid,
          }),
        })
          .then((res) => {
            if (!res.ok) {
              throw new Error('HTTP error ' + res.status);
            }
            return res.text();
          })
          .then((text) => {
            console.log('getting custom token');
            loginWithCustomToken(text);
            //return text;
          })
          .catch((err) => {
            console.log(err);
            setTimeout(() => initializeApps(), 10);
            setTimeout(() => initializeServers(), 10);
            setTimeout(() => storage.clear(), 50);
            setTimeout(() => logout(), 100);
            history.push('/');
          });
      }
    }
  }

  handleClickShowPassword() {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  }

  handleChangeEmail(e) {
    this.setState({ loginEmail: e.target.value });
  }

  handleChangePassword(e) {
    this.setState({ loginPassword: e.target.value });
  }

  handleClickLogin() {
    const { loginEmail, loginPassword } = this.state;
    const { loginWithEmail } = this.props;
    loginWithEmail(loginEmail, loginPassword);
  }

  render() {
    const { apps, classes, auth } = this.props;
    const { showPassword, loginEmail, loginPassword } = this.state;
    const progress = <ColorLinearProgress />;
    const lang = apps.currentLanguage;

    const params = new URLSearchParams(window.location.search);
    const uid = params.get('uid');
    return !uid ? (
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Box mt={3} />
        <Grid container justify='center' className='text-bootstrap4-secondary'>
          <Grid item>
            <Box ml={1} />
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item>
                <Avatar
                  onClick={() => window.location.reload()}
                  className={classes.avatar}
                  src='/img/sakenomy.png'
                />
              </Grid>
              <Grid item>
                <Box ml={1} />
              </Grid>
              <Grid item>
                <Button onClick={() => window.location.reload()} color='inherit'>
                  <Typography
                    variant='h5'
                    color='textSecondary'
                    noWrap
                    className={classes.topTitle}
                  >
                    <b>SAKEBlockchain</b> <span>Dashboard</span>
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box mt={3} />
        {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}

        <Grid container justify='center' className='text-bootstrap4-secondary'>
          <Card className={classes.card}>
            {auth.isRequesting ? progress : ''}
            <CardContent>
              <Box mt={3} />
              {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
              <Grid container spacing={1} alignItems='flex-end'>
                <Grid item sm={1} xs={2}>
                  <EmailIcon />
                </Grid>
                <Grid item sm={11} xs={10}>
                  <TextField
                    fullWidth
                    type='email'
                    value={loginEmail}
                    onChange={(e) => this.handleChangeEmail(e)}
                    id='login-email'
                    label={appLanguages.emailLogin[lang]}
                    disabled={auth.isRequesting}
                  />
                </Grid>
              </Grid>
              <Box mt={3} />
              {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
              <Grid container spacing={1} alignItems='flex-end'>
                <Grid item sm={1} xs={2}>
                  <LockIcon />
                </Grid>
                <Grid item sm={11} xs={10}>
                  <TextField
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    value={loginPassword}
                    onChange={(e) => this.handleChangePassword(e)}
                    id='login-password'
                    label={appLanguages.passwordLogin[lang]}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            edge='end'
                            aria-label='Toggle password visibility'
                            onClick={() => this.handleClickShowPassword()}
                          >
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    disabled={auth.isRequesting}
                  />
                </Grid>
              </Grid>
              <Box mt={5} />
              {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
              <Grid container justify='center'>
                <Button
                  onClick={() => this.handleClickLogin()}
                  variant='contained'
                  color='primary'
                  disabled={auth.isRequesting}
                >
                  {appLanguages.login[lang]}
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </main>
    ) : (
      <CircularLoading />
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  auth: IStateAuth;
  classes: any;
  history: History<LocationState>;
}

export interface IDispatchProps {
  loginWithEmail: (user: string, password: string) => void;
  loginWithCustomToken: (text: string) => void;
  openSnackbarMessage: (format: string, message: string) => void;
  logout: () => void;
  initializeApps: () => void;
  initializeServers: () => void;
}

interface State {
  loginEmail: string;
  loginPassword: string;
  showPassword: boolean;
}

const myStyles = (theme: Theme): StyleRules => ({
  topTitle: { textTransform: 'none' },
  card: {
    maxWidth: 450,
    minWidth: 300,
    width: '50%',
  },
  content: {
    width: '100%',
  },
  toolbar: { paddingTop: '68px' },
  avatar: { cursor: 'pointer' },
});

const mapStateToProps = (state) => ({
  apps: state.apps,
  auth: state.auth,
});
const mapDispatchToProps = {
  loginWithEmail,
  loginWithCustomToken,
  openSnackbarMessage,
  logout,
  initializeApps,
  initializeServers,
};

export const Login = compose<any>(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(LoginClass));
