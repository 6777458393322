import { CircularLoading } from '@container/components/circular-loading';
import { i18n } from '@i18n/lang';
import { StyleRules, withStyles } from '@material-ui/core';
import { AllPages } from '@models/all-pages';
import { INotification } from '@models/api-get-notices';
import { IQuery } from '@models/query';
import { IStateApps } from '@models/state-apps';
import { IStateAuth } from '@models/state-auth';
import { IStateServers } from '@models/state-servers';
import { setCurrentPage } from '@redux/actions/appsActions';
import {
  addIdToReadNotificationIdList,
  getAdditionalNotifications,
  getNotifications,
  setNotificationQuery,
} from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import { LIST_PAGE_SCROLL_LOAD_LIMIT } from '@utils/config';
import { DefaultQuery } from '@utils/default-query';
import { formatDate } from '@utils/format-date';
import { isOk } from '@utils/is-ok';
import { ColoredBackground } from '@visual/colored-background';
import { ListPageItem } from '@visual/list-page-item';
import { NotificationDetailPopup } from '@visual/notification-detail-popup';
import hash from 'object-hash';
import React from 'react';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { compose } from 'redux';

class NotificationListClass extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      targetNotification: null,
      showNotificationPopup: false,
    };
  }

  public async componentDidMount() {
    await this.props.setNotificationQuery(DefaultQuery);
    await this.props.getNotifications(DefaultQuery);
    await this.props.setCurrentPage('notification-list');
  }

  public render(): JSX.Element {
    const { servers, auth } = this.props;
    const isRequesting =
      servers.isRequesting ||
      servers.isGetRequesting ||
      auth.isRequesting ||
      !servers.notifications;

    return (
      <ColoredBackground>
        {isRequesting ? <CircularLoading /> : this.getContents()}
      </ColoredBackground>
    );
  }

  protected getContents() {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.headerSpace} />
        <div>{this.getListItems()}</div>
        {this.getNotificationPopup()}
      </>
    );
  }

  protected getListItems(): JSX.Element[] {
    const {
      apps: { currentLanguage: lang },
      servers: { notifications },
    } = this.props;

    if (!isOk(notifications)) return;

    return notifications.map((notice, i) => {
      return (
        <React.Fragment key={hash(i)}>
          <ListPageItem
            title={notice?.title}
            subInfos={[{ label: i18n.dayAndTime[lang], value: formatDate(notice?.updatedAt) }]}
            badge={notice?.isNew && i18n.badgeNew[lang]}
            onClick={() => this.openNotificationPopup(notice.id)}
          />
          {i === notifications.length - 1 && <Waypoint onEnter={() => this.loadNextDataSet()} />}
        </React.Fragment>
      );
    });
  }

  protected openNotificationPopup(notificationId: number) {
    const {
      servers: { notifications },
    } = this.props;
    const targetNotification = notifications?.find((notice) => notice.id === notificationId);
    if (!targetNotification) return;
    this.setState({
      targetNotification: targetNotification,
      showNotificationPopup: true,
    });
  }

  protected getNotificationPopup(): JSX.Element {
    const { targetNotification, showNotificationPopup } = this.state;
    const {
      apps: { currentLanguage: lang },
    } = this.props;
    if (!targetNotification) return;

    return (
      <NotificationDetailPopup
        title={targetNotification.title}
        onClose={() => this.toggleNotificationPopup()}
        lang={lang}
        showPopup={showNotificationPopup}
        onConfirmationButtonClick={() => this.handleOnConfirmationButtonClick(targetNotification)}
      >
        {targetNotification.body}
      </NotificationDetailPopup>
    );
  }

  protected handleOnConfirmationButtonClick(notice: any) {
    const {
      servers: { user },
    } = this.props;
    const notificationObj = Object.assign({}, notice, { userId: user.id });
    const { addIdToReadNotificationIdList } = this.props;
    if (notice.isNew) {
      addIdToReadNotificationIdList(notificationObj);
    }
    this.toggleNotificationPopup();
  }

  protected toggleNotificationPopup() {
    const { showNotificationPopup } = this.state;
    this.setState({
      showNotificationPopup: !showNotificationPopup,
    });
  }

  protected loadNextDataSet() {
    const {
      servers: { notificationQuery, notifications },
      getAdditionalNotifications,
      setNotificationQuery,
    } = this.props;
    const newQuery: IQuery = notificationQuery ? { ...notificationQuery } : { ...DefaultQuery };
    newQuery.offset = notifications.length;
    newQuery.limit = LIST_PAGE_SCROLL_LOAD_LIMIT;
    setNotificationQuery(newQuery);
    getAdditionalNotifications(newQuery);
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  auth: IStateAuth;
  classes: any;
}

export interface IDispatchProps {
  getNotifications: (query: IQuery) => void;
  getAdditionalNotifications: (query: IQuery) => void;
  setNotificationQuery: (query: IQuery) => void;
  addIdToReadNotificationIdList: (id: number) => void;
  setCurrentPage: (pageId: AllPages) => void;
}

interface State {
  targetNotification?: INotification;
  showNotificationPopup?: boolean;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
  auth: state.auth,
});

const mapDispatchToProps: IDispatchProps = {
  getNotifications,
  getAdditionalNotifications,
  setNotificationQuery,
  addIdToReadNotificationIdList,
  setCurrentPage,
};

const myStyles = (): StyleRules => ({
  headerSpace: { paddingTop: '68px' },
});

export const NotificationList = compose<any>(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(NotificationListClass);
