export function injectJS(
  file: string,
  location: 'after-body' | 'head' = 'after-body',
  options: ScriptOptions = {},
): void {
  /* Don't inject if the file is already been there */
  if (exist(file)) return;

  const script = document.createElement('script');
  script.src = file;
  script.id = generateFileId(file);

  Object.keys(options).forEach((key) => {
    script[key] = options[key];
  });

  if (location === 'after-body') {
    document.body.appendChild(script);
  } else {
    document.head.appendChild(script);
  }
}

function generateFileId(file: string) {
  const lastIndex = file.indexOf('?') === -1 ? file.length : file.indexOf('?');
  const fileName = file.substring(file.lastIndexOf('/') + 1, lastIndex);

  return `__${fileName}__`;
}

function exist(file: string): boolean {
  return !!document.getElementById(generateFileId(file));
}

interface ScriptOptions {
  async?: boolean;
  defer?: boolean;
  charset?: string;
}
