import { i18n } from '@i18n/lang';
import { EAlert } from '@models/alert-type';
import { TSupportedLanguages } from '@models/supported-languages';
import { store } from '@redux/reducers';
import { LANGUAGE_DEFAULT } from '@utils/common';
import { formatString } from '@utils/format-string';

export function getAlertMessage(
  type: EAlert,
  lang: TSupportedLanguages = LANGUAGE_DEFAULT,
  quality_alert_duration?,
): string {
  let scanQualityDate;
  let message;
  if (!type) return;
  switch (type) {
    case EAlert.SCAN_DATE:
      return i18n.scanDateAlert[lang];
    case EAlert.SCAN_QUANTITY:
      return i18n.scanQuantityAlert[lang];
    case EAlert.QUALITY:
      scanQualityDate = quality_alert_duration;
      message = formatString(i18n.qualityAlert[lang], { day: scanQualityDate });
      return message;
    case EAlert.ROUTE:
      return i18n.routeAlert[lang];
    case EAlert.CONSUMER_LOCATION:
      return i18n.consumerLocationAlert[lang];
    case EAlert.ILLEGAL_ROUTE:
      return i18n.illegalRouteAlert[lang];
    case EAlert.ILLEGAL_SELLING:
      return i18n.illegalSellingAlert[lang];
    case EAlert.DIST_QUALITY:
      scanQualityDate = store.getState()?.servers?.scanIntervals?.scanQualityDate;
      message = formatString(i18n.distQualityAlert[lang], { day: scanQualityDate.toString() });
      return message;
    case EAlert.ILLEGAL_SHIPPING:
      return i18n.illegalShippingAlert[lang];
  }
}
