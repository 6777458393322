import { Toolbar } from '@material-ui/core';
import { StyleRules, Theme, withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { IStateApps } from '@models/state-apps';
import { IStoreState } from '@redux/reducers';
import appLanguages from '@utils/app-languages';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { myStyles as chatMessagesStyles } from './ChatMessages';

class ChatMessagesNoneClass extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      alert: '',
      alertTimeout: null,
    };
  }

  componentDidMount() {
    const { apps } = this.props;
    const lang = apps.currentLanguage;
    const alertTimeout = setTimeout(() => {
      this.setState({
        alert: (
          <Alert style={{ minWidth: '100%' }} severity='warning'>
            {appLanguages.pleaseSelectUser[lang]}
          </Alert>
        ),
      });
    }, 1500);
    this.setState({ alertTimeout });
  }

  componentWillUnmount() {
    const { alertTimeout } = this.state;
    if (alertTimeout) clearTimeout(alertTimeout);
    this.setState({ alertTimeout: null });
  }

  render() {
    const { classes } = this.props;
    const { alert } = this.state;
    return (
      <>
        <main className={classes.content}>
          <Toolbar />
          {alert}
        </main>
      </>
    );
  }
}

export type Props = IStateProps;

export interface IStateProps {
  apps: IStateApps;
  classes: any;
}

interface State {
  alert: any;
  alertTimeout: any;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
});

export const myStyles = (theme: Theme): StyleRules => ({ ...chatMessagesStyles(theme) });

export const ChatMessagesNone = compose(
  withStyles(myStyles),
  connect(mapStateToProps),
)(ChatMessagesNoneClass);
