import React from 'react';
import styles from './style.scss';

export class PageContainer extends React.PureComponent<Props> {
  public render = (): JSX.Element => (
    <div className={styles.pageContainer}>{this.props.children}</div>
  );
}

export type Props = IStateProps;
export interface IStateProps {
  children?: React.ReactNode;
}
