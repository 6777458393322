import { i18n } from '@i18n/lang';
import { EAlertStatus } from '@models/alert-status-type';
import { IDeactivateRequest } from '@models/api-deactivate-location';
import { INotification } from '@models/api-get-notices';
import { IRejectRequest } from '@models/api-reject-location';
import { IUpdateAlertRequest } from '@models/api-update-alert';
import { ECategoryType } from '@models/category-type';
import { ILocation } from '@models/location';
import { IQuery } from '@models/query';
import { ERestaurantStatus } from '@models/restaurant-status-type';
import { ISerial } from '@models/serial';
import { IStoreState, store } from '@redux/reducers';
import appLanguages from '@utils/app-languages';
import { LIST_PAGE_INITIAL_ITEM_LIMIT } from '@utils/config';
import { convertToSec } from '@utils/convert-sec';
import { DefaultQuery } from '@utils/default-query';
import { functions } from '@utils/firebase';
import { isOk } from '@utils/is-ok';
import { addReadNotificationId, getNotificationStatus } from '@utils/notification';
import { animateScroll as scroller } from 'react-scroll';
import {
  ACTIVATE_LOCATION,
  ACTIVATE_USER,
  CLEAR_SUGGESTIONS_PLACES,
  DEACTIVATE_LOCATION,
  DELETE_BRAND,
  DELETE_CHAT_MESSAGE,
  DELETE_CHAT_ROOM,
  DELETE_COMMENT,
  DELETE_EDIT_LOCATION_IMAGES,
  DELETE_HISTORY_SUMMARY,
  DELETE_LOCATION,
  DELETE_LOCATION_IMAGE,
  DELETE_RESTAURANT_PRODUCT,
  DELETE_SERIAL,
  DELETE_USER,
  GET_ACTIVITIES,
  GET_ADDITIONAL_ALERTS,
  GET_ADDITIONAL_NOTIFICATIONS,
  GET_ADDITIONAL_SERIALS,
  GET_ALERTS,
  GET_ALERTS_COUNT,
  GET_ALERTS_URGENT_COUNT,
  GET_ALERT_ANALYSIS,
  GET_ALL_BRANDS,
  GET_ALL_LOCATIONS,
  GET_ANALYSIS,
  GET_AUTH_USER,
  GET_BRANDS,
  GET_BRAND_LOCATIONS,
  GET_CHAT_MESSAGES,
  GET_CHAT_ROOMS,
  GET_CHAT_USERS,
  GET_COMMENTS,
  GET_DETAIL_VIEW_ALERT,
  GET_DETAIL_VIEW_SERIAL,
  GET_HISTORY_SUMMARIES,
  GET_INVENTORY,
  GET_LOCATIONS,
  GET_LOCATION_IMAGES,
  GET_LOCATION_REGIONS,
  GET_MASTER_BRANDS,
  GET_MASTER_LOCATIONS,
  GET_MASTER_USERS,
  GET_NEW_NOTIFICATION_COUNT,
  GET_NOTIFICATIONS,
  GET_OR_CREATE_CHAT_ROOM,
  GET_PLACE_AUTOCOMPLETE,
  GET_RESTAURANT_PRODUCTS,
  GET_RESTAURANT_PRODUCTS_BY_ID,
  GET_SCAN_INTERVAL_TIME,
  GET_SERIALS,
  GET_SERIAL_BRANDS,
  GET_SERIAL_BREW_LOCATIONS,
  GET_SERIAL_DIST_LOCATIONS,
  GET_SERIAL_REST_LOCATIONS,
  GET_STOCKS,
  GET_SUPPLIERS,
  GET_TRANSACTION,
  GET_USERS,
  GET_USER_LOCATIONS,
  INITIALIZE_SERVERS,
  LOGOUT,
  REGISTER_ALERT_DETAIL,
  REGISTER_ALERT_SERIAL,
  REGISTER_BRAND,
  REGISTER_CHAT_MESSAGE,
  REGISTER_CHAT_ROOM,
  REGISTER_COMMENT,
  REGISTER_HISTORY_SUMMARY,
  REGISTER_LOCATION,
  REGISTER_LOCATION_IMAGE,
  REGISTER_LOGIN_HISTORY,
  REGISTER_NOTIFICATION,
  REGISTER_RESTAURANT_PRODUCT,
  REGISTER_SERIAL,
  REGISTER_SERIALS,
  REGISTER_TEMP_USER,
  REGISTER_USER,
  REJECT_LOCATION,
  RESET_SERIAL,
  SEND_NOTIFICATION,
  SERVER_ADDITIONAL_GET_REQUEST,
  SERVER_GET_AUTH_USER_REQUEST,
  SERVER_GET_REQUEST,
  SERVER_REQUEST,
  SERVER_REQUEST_DONE,
  SET_ACTIVITIES_QUERY,
  SET_ALERTS_QUERY,
  SET_ALERT_FILTER_QUERY,
  SET_ANALYSIS_QUERY,
  SET_BRANDS_QUERY,
  SET_CHAT_MESSAGES_QUERY,
  SET_CHAT_ROOM,
  SET_CHAT_ROOMS_QUERY,
  SET_CHAT_USERS_QUERY,
  SET_COMMENTS_QUERY,
  SET_EDIT_LOCATION_IMAGES,
  SET_HISTORY_SUMMARIES_QUERY,
  SET_LOCATIONS_QUERY,
  SET_NOTIFICATION_QUERY,
  SET_RESTAURANT_PRODUCTS_QUERY,
  SET_SCAN_DUE_DATE,
  SET_SCAN_INTERVAL_TIME,
  SET_SCAN_QUALITY_DATE,
  SET_SERIALS_QUERY,
  SET_USERS_QUERY,
  UPDATE_ALERT,
} from '.';
import {
  closeBrandEditDialog,
  closeLocationEditDialog,
  closeSerialEditDialog,
  closeUserEditDialog,
  openSnackbarMessage,
  setEditSerial,
} from './appsActions';
import { logout } from './authActions';

const MAX_LIMIT = 500;
const logout2 = () => ({ type: LOGOUT });

export const initializeServers = () => ({ type: INITIALIZE_SERVERS });
export const serversUpdateRequest = () => ({ type: SERVER_REQUEST });
export const serversGetRequest = () => ({ type: SERVER_GET_REQUEST });
export const serversGetAuthUserRequest = () => ({ type: SERVER_GET_AUTH_USER_REQUEST });
export const serversAdditionalGetRequest = () => ({ type: SERVER_ADDITIONAL_GET_REQUEST });
export const serversResponseDone = () => ({ type: SERVER_REQUEST_DONE });
const serversResponseSuccess = (type, result) => ({ type, result });
const serversResponseFailure = (type, error) => ({ type, error });
const lang = store.getState().apps.currentLanguage;
const handleError = (type, error, dispatch) => {
  dispatch(serversResponseFailure(type, error));
  let message = `${error.message}`;
  switch (error.code) {
    case 'internal':
      message = i18n.serverError[lang];
      break;
    case 'failed-precondition':
      message = i18n.sessionTimedout[lang];
      dispatch(logout2());
      break;
    default:
      break;
  }
  // if (error.code === 'internal') {
  //   message = i18n.serverError[lang];
  // }
  dispatch(openSnackbarMessage('error', message));
};

export const getScanIntervalTime = () => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getScanIntervalTime');
  try {
    const response = await request({});
    dispatch(serversResponseSuccess(GET_SCAN_INTERVAL_TIME, response));
  } catch (response) {
    handleError(GET_SCAN_INTERVAL_TIME, response, dispatch);
  }
};

export const setScanDueDate = (value: number) => ({ type: SET_SCAN_DUE_DATE, value });

export const setScanQualityDate = (value: number) => ({ type: SET_SCAN_QUALITY_DATE, value });

export const setScanIntervalTime = () => async (dispatch) => {
  dispatch(serversGetRequest());
  const scanInterval = store.getState().servers.scanIntervals;
  const request = functions.httpsCallable('setScanIntervalTime');
  try {
    const response = await request({
      scanDueDate: convertToSec(scanInterval.scanDueDate, 'hours'),
      scanQualityDate: convertToSec(scanInterval.scanQualityDate, 'days'),
    });
    dispatch(serversResponseSuccess(SET_SCAN_INTERVAL_TIME, response));
  } catch (response) {
    handleError(SET_SCAN_INTERVAL_TIME, response, dispatch);
  } finally {
    dispatch(getScanIntervalTime());
  }
};

// Master: Serials

export const getSerials = (query) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getSerials');
  try {
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_SERIALS, response));
  } catch (response) {
    handleError(GET_SERIALS, response, dispatch);
  }
};

export const getDetailViewSerial = (serialId: number) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getSerials');
  try {
    const query: IQuery = {
      where: { id: serialId },
    };
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_DETAIL_VIEW_SERIAL, response));
  } catch (response) {
    handleError(GET_DETAIL_VIEW_SERIAL, response, dispatch);
  }
};

export const getDetailViewAlert = (alertId: number) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getAlerts');
  try {
    const query: IQuery = {
      where: { id: alertId },
    };
    const response = await request(query);
    // response.data.objects[0].locType = 2; // FOR TEST: manually change locType to 2(distributor)
    // console.log('getDetailViewAlert', response);
    dispatch(serversResponseSuccess(GET_DETAIL_VIEW_ALERT, response));
  } catch (response) {
    handleError(GET_DETAIL_VIEW_ALERT, response, dispatch);
  }
};

export const getAlerts = (query: IQuery = {}) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getAlerts');
  try {
    const state: IStoreState = store.getState();
    const user = state?.servers?.user;
    if (user.location.category === ECategoryType.RESTAURANT)
      query.where.alertLocId = user.locationId;
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_ALERTS, response));
  } catch (response) {
    handleError(GET_ALERTS, response, dispatch);
  }
};

export const getAlertsCount = (query: IQuery) => async (dispatch) => {
  // dispatch(serversGetRequest());
  const request = functions.httpsCallable('getAlertsCount');
  try {
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_ALERTS_COUNT, response));
  } catch (response) {
    handleError(GET_ALERTS_COUNT, response, dispatch);
  }
};

export const sendNotification = (params: {
  alertId: number;
  serial: number;
  fromUserId: number;
  toUserId: number;
  type?: number;
}) => async (dispatch) => {
  dispatch(serversUpdateRequest());
  const request = functions.httpsCallable('sendNotification');
  try {
    const response = await request(params);
    dispatch(serversResponseSuccess(SEND_NOTIFICATION, response));
  } catch (response) {
    handleError(SEND_NOTIFICATION, response, dispatch);
  } finally {
    // dispatch(
    //   getAlerts({
    //     ...store.getState().servers.alertsQuery,
    //     offset: 0,
    //     limit: LIST_PAGE_INITIAL_ITEM_LIMIT,
    //   }),
    // );
    dispatch(getDetailViewAlert(params.alertId));
  }
};

export const getAlertsUrgentCount = (query: IQuery) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getAlertsUrgentCount');
  try {
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_ALERTS_URGENT_COUNT, response));
  } catch (response) {
    handleError(GET_ALERTS_URGENT_COUNT, response, dispatch);
  }
};

export const registerAlertDetail = (params: {
  alertId: number;
  status: EAlertStatus;
  userId: number;
  assigneeName?: string;
}) => async (dispatch) => {
  dispatch(serversUpdateRequest());
  const request = functions.httpsCallable('registerAlertDetail');
  try {
    const response = await request(params);
    dispatch(serversResponseSuccess(REGISTER_ALERT_DETAIL, response));
  } catch (response) {
    handleError(REGISTER_ALERT_DETAIL, response, dispatch);
  } finally {
    dispatch(
      getAlerts({
        ...store.getState().servers.alertsQuery,
        offset: 0,
        limit: LIST_PAGE_INITIAL_ITEM_LIMIT,
      }),
    );
    dispatch(getDetailViewAlert(params.alertId));
  }
};

export const getAdditionalAlerts = (query) => async (dispatch) => {
  dispatch(serversAdditionalGetRequest());
  const request = functions.httpsCallable('getAlerts');
  try {
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_ADDITIONAL_ALERTS, response));
  } catch (response) {
    handleError(GET_ADDITIONAL_ALERTS, response, dispatch);
  }
};

export const getAdditionalSerials = (query) => async (dispatch) => {
  dispatch(serversAdditionalGetRequest());
  const request = functions.httpsCallable('getSerials');
  try {
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_ADDITIONAL_SERIALS, response));
  } catch (response) {
    handleError(GET_ADDITIONAL_SERIALS, response, dispatch);
  }
};

export const getSerialBrewLocations = () => (dispatch) => {
  dispatch(serversGetRequest());
  const query = { limit: MAX_LIMIT, where: { category: ECategoryType.BREWERY } };
  const request = functions.httpsCallable('getLocations');
  request(query)
    .then((result) => dispatch(serversResponseSuccess(GET_SERIAL_BREW_LOCATIONS, result)))
    .catch((error) => handleError(GET_SERIAL_BREW_LOCATIONS, error, dispatch));
};

export const getSerialDistLocations = () => (dispatch) => {
  dispatch(serversGetRequest());
  const query = { limit: MAX_LIMIT, where: { category: ECategoryType.DISTRIBUTOR } };
  const request = functions.httpsCallable('getLocations');
  request(query)
    .then((result) => dispatch(serversResponseSuccess(GET_SERIAL_DIST_LOCATIONS, result)))
    .catch((error) => handleError(GET_SERIAL_DIST_LOCATIONS, error, dispatch));
};

export const getSerialRestLocations = () => (dispatch) => {
  dispatch(serversGetRequest());
  const query = { limit: MAX_LIMIT, where: { category: ECategoryType.RESTAURANT } };
  const request = functions.httpsCallable('getLocations');
  request(query)
    .then((result) => dispatch(serversResponseSuccess(GET_SERIAL_REST_LOCATIONS, result)))
    .catch((error) => handleError(GET_SERIAL_REST_LOCATIONS, error, dispatch));
};

export const registerSerial = (params, enableNotify = true) => (dispatch) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('registerSerial');
  request(params)
    .then((result) => {
      dispatch(serversResponseSuccess(REGISTER_SERIAL, result));
      if (!enableNotify) return;
      if (params.id)
        dispatch(openSnackbarMessage('success', appLanguages.updatedSerialMessage[lang]));
      else dispatch(openSnackbarMessage('success', appLanguages.addedSerialMessage[lang]));
    })
    .catch((error) => handleError(REGISTER_SERIAL, error, dispatch))
    .finally(() => dispatch(getSerials(store.getState().servers.serialsQuery)));
};

export const updateAlert = (params: IUpdateAlertRequest) => async (dispatch) => {
  dispatch(serversUpdateRequest());
  const request = functions.httpsCallable('updateAlert');
  try {
    const response = await request(params);
    dispatch(serversResponseSuccess(UPDATE_ALERT, response));
  } catch (error) {
    handleError(UPDATE_ALERT, error, dispatch);
  }
};

export const registerAlertSerial = (params: ISerial) => async (dispatch) => {
  dispatch(serversUpdateRequest());
  const request = functions.httpsCallable('registerSerial');
  try {
    const response = await request(params);
    dispatch(serversResponseSuccess(REGISTER_ALERT_SERIAL, response));
  } catch (response) {
    handleError(REGISTER_ALERT_SERIAL, response, dispatch);
  } finally {
    dispatch(getSerials(store.getState().servers.serialsQuery));
    dispatch(getDetailViewSerial(params.id));
  }
};

export const delSerial = (params) => (dispatch) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('delSerial');
  request(params)
    .then((result) => {
      dispatch(serversResponseSuccess(DELETE_SERIAL, result));
      dispatch(openSnackbarMessage('success', appLanguages.deletedSerialMessage[lang]));
    })
    .catch((error) => handleError(DELETE_SERIAL, error, dispatch))
    .finally(() => dispatch(getSerials(store.getState().servers.serialsQuery)));
};

export const resetSerial = (params) => (dispatch) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('resetSerial');
  request(params)
    .then((result) => {
      dispatch(serversResponseSuccess(RESET_SERIAL, result));
      dispatch(openSnackbarMessage('success', appLanguages.resetSerialMessage[lang]));
    })
    .catch((error) => handleError(RESET_SERIAL, error, dispatch))
    .finally(() => dispatch(getSerials(store.getState().servers.serialsQuery)));
};

export const setSerialsQuery = (query) => ({ type: SET_SERIALS_QUERY, query });

export const setAnalysisQuery = (query) => ({ type: SET_ANALYSIS_QUERY, query });

export const setAlertsQuery = (query) => ({ type: SET_ALERTS_QUERY, query });

export const getSerialBrands = () => (dispatch) => {
  const newQuery = { limit: MAX_LIMIT };
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getBrands');
  request(newQuery)
    .then((result) => dispatch(serversResponseSuccess(GET_SERIAL_BRANDS, result)))
    .catch((error) => handleError(GET_SERIAL_BRANDS, error, dispatch));
};

// Master: Locations

export const getLocations = (query) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getLocations');
  try {
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_LOCATIONS, response));
  } catch (response) {
    handleError(GET_LOCATIONS, response, dispatch);
  }
};

export const getMasterLocations = (query) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getMasterLocations');
  try {
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_MASTER_LOCATIONS, response));
  } catch (response) {
    handleError(GET_MASTER_LOCATIONS, response, dispatch);
  }
};

export const getLocationDetail = (query) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getLocations');
  try {
    const response = await request(query);
    return response;
  } catch (response) {
    console.log(response);
  }
};

export const getLocationsImages = (query) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getImages');
  try {
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_LOCATION_IMAGES, response));
  } catch (response) {
    handleError(GET_LOCATION_IMAGES, response, dispatch);
  }
};

export const getAllLocations = (query: IQuery = {}) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getLocations');
  try {
    const response = await request({ ...query, limit: 10000 });
    dispatch(serversResponseSuccess(GET_ALL_LOCATIONS, response));
  } catch (error) {
    handleError(GET_ALL_LOCATIONS, error, dispatch);
  }
};

export const registerLocatonImages = (locationId: number) => async (dispatch) => {
  const state: IStoreState = store.getState();
  const images = state?.servers?.editLocationImages;
  const request = functions.httpsCallable('registerImage');
  // TODO [SAKE-BC] Convert to multiple images upload
  if (!isOk(images)) return;
  images.forEach(async (image) => {
    try {
      dispatch(serversUpdateRequest());
      const response = await request({ ...image, locationId });
      dispatch(serversResponseSuccess(REGISTER_LOCATION_IMAGE, response));
    } catch (response) {
      handleError(REGISTER_LOCATION_IMAGE, response, dispatch);
    }
  });

  const query: IQuery = { ...DefaultQuery };
  query.where = { locationId };
  await dispatch(getLocationsImages(query));
};

// Sreenath11Nov21- delete location images API
export const deleteLocatonImages = (locationId: number) => async (dispatch) => {
  const state: IStoreState = store.getState();
  const images = state?.servers?.deletedLocationImages;
  const request = functions.httpsCallable('deleteImage');
  if (!isOk(images)) return;
  images.forEach(async (image) => {
    // image object { id, location, type, url}
    try {
      dispatch(serversUpdateRequest());
      const response = await request({ id: image.id });
      dispatch(serversResponseSuccess(DELETE_LOCATION_IMAGE, response));
    } catch (response) {
      handleError(DELETE_LOCATION_IMAGE, response, dispatch);
    }
  });
  const query: IQuery = { ...DefaultQuery };
  query.where = { locationId };
  await dispatch(getLocationsImages(query));
};

export const activateLocation = (params: any, closePopup?: boolean, isOnly?: boolean) => async (
  dispatch,
) => {
  dispatch(serversUpdateRequest());
  params.isApproveOnly = isOnly;
  console.log(params);
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('activateLocation');
  try {
    const response = await request(params);
    dispatch(serversResponseSuccess(ACTIVATE_LOCATION, response));
    dispatch(openSnackbarMessage('success', appLanguages.activateLocationMessage[lang]));
    if (closePopup) {
      dispatch(closeLocationEditDialog());
    }
  } catch (response) {
    handleError(ACTIVATE_LOCATION, response, dispatch);
  } finally {
    dispatch(getMasterLocations(store.getState().servers.locationsQuery));
  }
};

export const deactivateLocation = (params: IDeactivateRequest) => async (dispatch) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('deactivateLocation');
  try {
    const response = await request(params);
    dispatch(serversResponseSuccess(DEACTIVATE_LOCATION, response));
    dispatch(openSnackbarMessage('success', appLanguages.deactivateLocationMessage[lang]));
  } catch (response) {
    handleError(DEACTIVATE_LOCATION, response, dispatch);
  } finally {
    dispatch(closeLocationEditDialog());
    dispatch(getMasterLocations(store.getState().servers.locationsQuery));
  }
};

export const rejectLocation = (params: IRejectRequest) => async (dispatch) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('rejectLocation');
  try {
    const response = await request(params);
    dispatch(serversResponseSuccess(REJECT_LOCATION, response));
    dispatch(openSnackbarMessage('success', appLanguages.rejectMessage[lang]));
  } catch (response) {
    handleError(REJECT_LOCATION, response, dispatch);
  } finally {
    dispatch(closeLocationEditDialog());
  }
};

export const registerLocation = (params: Partial<ILocation>, closePopup?: boolean) => async (
  dispatch,
) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('registerLocation');
  try {
    const response = await request(params);
    const state: IStoreState = store.getState();
    const imagesToDelete = state?.servers?.deletedLocationImages;
    dispatch(serversResponseSuccess(REGISTER_LOCATION, response));
    // Sreenath11Nov21- Location image delete API call
    const images = state?.servers?.editLocationImages;
    if (imagesToDelete.length) {
      dispatch(deleteLocatonImages(response?.data?.id));
      if (!images.length) {
        params.description += ' ';
        await request(params);
        params.description = params.description.slice(0, params.description.length);
        await request(params);
      }
    }
    dispatch(registerLocatonImages(response?.data?.id));

    // if (images.length) {
    //   params.description += ' ';
    //   await request(params);
    //   params.description = params.description.slice(0, params.description.length);
    //   await request(params);
    // }

    if (params.id) {
      dispatch(openSnackbarMessage('success', appLanguages.updatedLocationMessage[lang]));
    } else {
      dispatch(openSnackbarMessage('success', appLanguages.addedLocationMessage[lang]));
    }
    if (closePopup) dispatch(closeLocationEditDialog());
  } catch (response) {
    handleError(REGISTER_LOCATION, response, dispatch);
  } finally {
    dispatch(getMasterLocations(store.getState().servers.locationsQuery));
  }
};

export const delLocation = (params) => (dispatch) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('delLocation');
  request(params)
    .then((result) => {
      dispatch(serversResponseSuccess(DELETE_LOCATION, result));
      dispatch(openSnackbarMessage('success', appLanguages.deletedLocationMessage[lang]));
    })
    .catch((error) => handleError(DELETE_LOCATION, error, dispatch))
    .finally(() => dispatch(getMasterLocations(store.getState().servers.locationsQuery)));
};

export const setLocationsQuery = (query) => ({ type: SET_LOCATIONS_QUERY, query });

export const setEditLocationImages = (obj) => ({ type: SET_EDIT_LOCATION_IMAGES, obj });
export const deleteEditLocationImages = (obj) => ({ type: DELETE_EDIT_LOCATION_IMAGES, obj });

export const getSuppliers = (query, category) => (dispatch) => {
  const where = category > 1 ? { category: { $between: [1, category - 1] } } : { id: 0 };
  const newQuery = { ...query, limit: MAX_LIMIT, where };
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getLocations');
  request(newQuery)
    .then((result) => dispatch(serversResponseSuccess(GET_SUPPLIERS, result)))
    .catch((error) => handleError(GET_SUPPLIERS, error, dispatch));
};

// Master: Brands

export const getBrands = (query) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getBrands');
  try {
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_BRANDS, response));
  } catch (response) {
    handleError(GET_BRANDS, response, dispatch);
  }
};

export const getMasterBrands = (query) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getMasterBrands');
  try {
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_MASTER_BRANDS, response));
  } catch (response) {
    handleError(GET_MASTER_BRANDS, response, dispatch);
  }
};

export const getAllBrands = (query: IQuery = {}) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getBrands');
  try {
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_ALL_BRANDS, response));
  } catch (response) {
    handleError(GET_ALL_BRANDS, response, dispatch);
  }
};

export const registerBrand = (params, closePopup?: boolean) => async (dispatch) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('registerBrand');
  try {
    const response = await request(params);
    dispatch(serversResponseSuccess(REGISTER_BRAND, response));

    if (params.id) {
      dispatch(openSnackbarMessage('success', appLanguages.updatedBrandMessage[lang]));
    } else {
      dispatch(openSnackbarMessage('success', appLanguages.addedBrandMessage[lang]));
    }

    if (closePopup) {
      dispatch(closeBrandEditDialog());
    }
  } catch (response) {
    handleError(REGISTER_BRAND, response, dispatch);
  } finally {
    dispatch(getMasterBrands(store.getState().servers.brandsQuery));
  }
};

export const delBrand = (params) => (dispatch) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('delBrand');
  request(params)
    .then((result) => {
      dispatch(serversResponseSuccess(DELETE_BRAND, result));
      dispatch(openSnackbarMessage('success', appLanguages.deletedBrandMessage[lang]));
    })
    .catch((error) => handleError(DELETE_BRAND, error, dispatch))
    .finally(() => dispatch(getMasterBrands(store.getState().servers.brandsQuery)));
};

export const setBrandsQuery = (query) => ({ type: SET_BRANDS_QUERY, query });

export const getBrandLocations = () => (dispatch) => {
  const query = { limit: MAX_LIMIT, where: { category: ECategoryType.BREWERY } }; // 0:none / 1:brewery / 2:distributor / 3:restaurant
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getMasterLocations');
  request(query)
    .then((result) => dispatch(serversResponseSuccess(GET_BRAND_LOCATIONS, result)))
    .catch((error) => handleError(GET_BRAND_LOCATIONS, error, dispatch));
};

// Master: Users

export const getUsers = (query) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getUsers');
  try {
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_USERS, response));
  } catch (error) {
    handleError(GET_USERS, error, dispatch);
  }
};

export const getMasterUsers = (query) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getMasterUsers');
  try {
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_MASTER_USERS, response));
  } catch (error) {
    handleError(GET_MASTER_USERS, error, dispatch);
  }
};

export const getAuthUser = () => async (dispatch) => {
  dispatch(serversGetAuthUserRequest());
  const request = functions.httpsCallable('getAuthUser');
  try {
    const response = await request({});
    dispatch(serversResponseSuccess(GET_AUTH_USER, response));
  } catch (response) {
    handleError(GET_AUTH_USER, response, dispatch);
    dispatch(logout());
  }
};

export const activateUser = (params) => (dispatch) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('activateUser');
  request(params)
    .then((result) => {
      dispatch(serversResponseSuccess(ACTIVATE_USER, result));
      if (params.id)
        dispatch(openSnackbarMessage('success', appLanguages.updatedUserMessage[lang]));
      else dispatch(openSnackbarMessage('success', appLanguages.addedUserMessage[lang]));
    })
    .catch((error) => handleError(ACTIVATE_USER, error, dispatch))
    .finally(() => dispatch(getMasterUsers(store.getState().servers.usersQuery)));
};

export const registerTempUser = (params) => (dispatch) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('registerTempUser');
  request(params)
    .then((result) => {
      dispatch(serversResponseSuccess(REGISTER_TEMP_USER, result));
      if (params.id)
        dispatch(openSnackbarMessage('success', appLanguages.updatedUserMessage[lang]));
      else dispatch(openSnackbarMessage('success', appLanguages.addedUserMessage[lang]));
    })
    .catch((error) => handleError(REGISTER_TEMP_USER, error, dispatch))
    .finally(() => dispatch(getMasterUsers(store.getState().servers.usersQuery)));
};

export const registerUser = (params, closePopup?: boolean) => async (dispatch) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('registerUser');
  console.log(params);
  try {
    const response = await request(params);
    dispatch(serversResponseSuccess(REGISTER_USER, response));
    if (params.id) {
      dispatch(openSnackbarMessage('success', appLanguages.updatedUserMessage[lang]));
    } else {
      dispatch(openSnackbarMessage('success', appLanguages.addedUserMessage[lang]));
    }
    if (closePopup) {
      dispatch(closeUserEditDialog());
    }
    return response;
  } catch (error) {
    handleError(REGISTER_USER, error, dispatch);
  } finally {
    dispatch(getMasterUsers(store.getState().servers.usersQuery));
  }
};

export const registerUserLang = (params) => async (dispatch) => {
  dispatch(serversUpdateRequest());
  const request = functions.httpsCallable('registerUser');

  try {
    await request(params);
  } catch (error) {
  } finally {
    dispatch(getMasterUsers(store.getState().servers.usersQuery));
  }
};

export const registerLoginHistory = () => async (dispatch) => {
  const request = functions.httpsCallable('registerLoginHistory');

  try {
    const userAgent = window.navigator.userAgent.toLowerCase();
    let deviceType;
    let deviceOS;
    let browser;
    if (userAgent.indexOf('iphone') > 0) {
      deviceType = 'iPhone';
    } else if (
      userAgent.indexOf('ipad') > 0 ||
      (userAgent.indexOf('macintosh') !== -1 && 'ontouchend' in document)
    ) {
      deviceType = 'iPad';
    } else if (userAgent.indexOf('mobile') > 0 || userAgent.indexOf('android') > 0) {
      deviceType = 'Android';
    } else if (
      !(
        userAgent.indexOf('iphone') > 0 ||
        userAgent.indexOf('ipad') > 0 ||
        userAgent.indexOf('android') > 0 ||
        userAgent.indexOf('mobile') > 0
      )
    ) {
      deviceType = 'PC';
    }
    if (userAgent.indexOf('windows nt') !== -1) {
      deviceOS = 'Microsoft Windows';
    } else if (userAgent.indexOf('android') !== -1) {
      deviceOS = 'android';
    } else if (userAgent.indexOf('iphone') !== -1 || userAgent.indexOf('ipad') !== -1) {
      deviceOS = 'iOS';
    } else if (userAgent.indexOf('mac os x') !== -1) {
      deviceOS = 'macOS';
      if (deviceType === 'iPad') deviceOS = 'iOS';
    } else {
      deviceOS = 'other';
    }

    if (userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
      browser = 'IE';
    } else if (userAgent.indexOf('edg') != -1) {
      browser = 'edge';
    } else if (userAgent.indexOf('chrome') != -1 || userAgent.indexOf('crios') != -1) {
      browser = 'chrome';
    } else if (userAgent.indexOf('firefox') != -1 || userAgent.indexOf('fxios') != -1) {
      browser = 'firefox';
    } else if (userAgent.indexOf('safari') != -1) {
      browser = 'safari';
    } else if (userAgent.indexOf('opera') != -1) {
      browser = 'opera';
    } else if (userAgent.indexOf('samsung') != -1) {
      browser = 'samsung';
    } else {
      browser = 'other';
    }
    const now = new Date().toISOString();
    let user = store.getState().servers.user;
    if (user) {
      const payload = {
        userId: user.id,
        loginEmail: user.email,
        loginName: user.name,
        locationId: user.locationId || '',
        locationName: user.location.name || '',
        locationCountry: user.location.country || '',
        actionType: 'LOGIN',
        actionDatetime: now,
        deviceType: deviceType || '',
        deviceOS: deviceOS || '',
        browser: browser || '',
        browserVersion: '',
        browserLang: window.navigator.language || '',
        network:
          window.navigator.connection?.type || window.navigator.connection?.effectiveType || '',
      };
      const response = await request(payload);
      dispatch(serversResponseSuccess(REGISTER_LOGIN_HISTORY, response));
    }
  } catch (error) {
    // handleError(REGISTER_LOGIN_HISTORY, error, dispatch);
    console.log(error);
  }
};

export const delUser = (params) => (dispatch) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('delUser');
  request(params)
    .then((result) => {
      dispatch(serversResponseSuccess(DELETE_USER, result));
      dispatch(openSnackbarMessage('success', appLanguages.deletedUserMessage[lang]));
    })
    .catch((error) => handleError(DELETE_USER, error, dispatch))
    .finally(() => dispatch(getMasterUsers(store.getState().servers.usersQuery)));
};

export const setUsersQuery = (query) => ({ type: SET_USERS_QUERY, query });

export const getUserLocations = (category, showAllStatus: boolean = false) => (dispatch) => {
  const blacklistedStatus = showAllStatus
    ? {}
    : { status: { $notIn: [ERestaurantStatus.REVIEWING] } };
  const newQuery = { limit: MAX_LIMIT, where: { category, ...blacklistedStatus } }; // 0:none / 1:brewery / 2:distributor / 3:restaurant
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getMasterLocations');
  request(newQuery)
    .then((result) => {
      // Sreenath 18NOV21- filtering inactive locations from the response w.r.t showAllStatus flag
      let locations = result?.data?.objects || [];
      if (!showAllStatus) {
        locations = locations.filter((x) => x.status !== ERestaurantStatus.REVIEWING);
        dispatch(
          serversResponseSuccess(GET_USER_LOCATIONS, {
            data: {
              objects: locations,
              totalCounts: locations.length,
            },
          }),
        );
      } else {
        dispatch(serversResponseSuccess(GET_USER_LOCATIONS, result));
      }
    })
    .catch((error) => handleError(GET_USER_LOCATIONS, error, dispatch));
};

// Section: activities

export const getActivities = (query) => (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getActivities');
  request(query)
    .then((result) => dispatch(serversResponseSuccess(GET_ACTIVITIES, result)))
    .catch((error) => handleError(GET_ACTIVITIES, error, dispatch));
};

export const setActivitiesQuery = (query) => ({ type: SET_ACTIVITIES_QUERY, query });

// Section: comments

export const getComments = (query) => (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getComments');
  request(query)
    .then((result) => dispatch(serversResponseSuccess(GET_COMMENTS, result)))
    .catch((error) => handleError(GET_COMMENTS, error, dispatch));
};

export const registerComment = (params) => (dispatch) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('registerComment');
  request(params)
    .then((result) => {
      dispatch(serversResponseSuccess(REGISTER_COMMENT, result));
      if (params.id)
        dispatch(openSnackbarMessage('success', appLanguages.updatedCommentMessage[lang]));
      else dispatch(openSnackbarMessage('success', appLanguages.addedCommentMessage[lang]));
    })
    .catch((error) => handleError(REGISTER_COMMENT, error, dispatch))
    .finally(() => dispatch(getComments(store.getState().servers.commentsQuery)));
};

export const delComment = (params) => (dispatch) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('delComment');
  request(params)
    .then((result) => {
      dispatch(serversResponseSuccess(DELETE_COMMENT, result));
      dispatch(openSnackbarMessage('success', appLanguages.deletedCommentMessage[lang]));
    })
    .catch((error) => handleError(DELETE_COMMENT, error, dispatch))
    .finally(() => dispatch(getComments(store.getState().servers.commentsQuery)));
};

export const setCommentsQuery = (query) => ({ type: SET_COMMENTS_QUERY, query });

// Sections: HistorySummaries

export const getHistorySummaries = (query) => (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getHistorySummaries');
  request(query)
    .then((result) => dispatch(serversResponseSuccess(GET_HISTORY_SUMMARIES, result)))
    .catch((error) => handleError(GET_HISTORY_SUMMARIES, error, dispatch));
};

export const registerHistorySummary = (params) => (dispatch) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('registerHistorySummary');
  request(params)
    .then((result) => {
      dispatch(serversResponseSuccess(REGISTER_HISTORY_SUMMARY, result));
      if (params.id) dispatch(openSnackbarMessage('success', appLanguages.updatedMessage[lang]));
      else dispatch(openSnackbarMessage('success', appLanguages.addedMessage[lang]));
    })
    .catch((error) => handleError(REGISTER_HISTORY_SUMMARY, error, dispatch))
    .finally(() => dispatch(getHistorySummaries(store.getState().servers.historySummariesQuery)));
};

export const delHistorySummary = (params) => (dispatch) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('delHistorySummary');
  request(params)
    .then((result) => {
      dispatch(serversResponseSuccess(DELETE_HISTORY_SUMMARY, result));
      dispatch(openSnackbarMessage('success', appLanguages.deletedMessage[lang]));
    })
    .catch((error) => handleError(DELETE_HISTORY_SUMMARY, error, dispatch))
    .finally(() => dispatch(getHistorySummaries(store.getState().servers.historySummariesQuery)));
};

export const setHistorySummariesQuery = (query) => ({ type: SET_HISTORY_SUMMARIES_QUERY, query });

// Sections: RestaurantProducts
export const getRestaurantProducts = (query) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getRestaurantProducts');
  try {
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_RESTAURANT_PRODUCTS, response));
  } catch (response) {
    handleError(GET_RESTAURANT_PRODUCTS, response, dispatch);
  }
};

export const getRestaurantProductsById = (restLocId: number) => async (dispatch, getState) => {
  const state: IStoreState = getState();
  // Caching: Dont call API data already exists
  if (isOk(state?.servers?.restaurantProductsById[restLocId])) return;

  const query = { ...DefaultQuery };
  query.limit = 0;
  query.where = { restLocId };

  dispatch(serversAdditionalGetRequest());
  const request = functions.httpsCallable('getRestaurantProducts');
  try {
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_RESTAURANT_PRODUCTS_BY_ID, response));
  } catch (response) {
    handleError(GET_RESTAURANT_PRODUCTS_BY_ID, response, dispatch);
  }
};

export const registerRestaurantProduct = (params) => (dispatch) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('registerRestaurantProduct');
  request(params)
    .then((result) => {
      dispatch(serversResponseSuccess(REGISTER_RESTAURANT_PRODUCT, result));
      if (params.id) dispatch(openSnackbarMessage('success', appLanguages.updatedMessage[lang]));
      else dispatch(openSnackbarMessage('success', appLanguages.addedMessage[lang]));
    })
    .catch((error) => handleError(REGISTER_RESTAURANT_PRODUCT, error, dispatch))
    .finally(() =>
      dispatch(getRestaurantProducts(store.getState().servers.restaurantProductsQuery)),
    );
};

export const delRestaurantProduct = (params) => (dispatch) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('delRestaurantProduct');
  request(params)
    .then((result) => {
      dispatch(serversResponseSuccess(DELETE_RESTAURANT_PRODUCT, result));
      dispatch(openSnackbarMessage('success', appLanguages.deletedMessage[lang]));
    })
    .catch((error) => handleError(DELETE_RESTAURANT_PRODUCT, error, dispatch))
    .finally(() =>
      dispatch(getRestaurantProducts(store.getState().servers.restaurantProductsQuery)),
    );
};

export const setRestaurantProductsQuery = (query) => ({
  type: SET_RESTAURANT_PRODUCTS_QUERY,
  query,
});

// Chat: users

export const getChatUsers = (query) => (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getChatUsers');
  request(query)
    .then((result) => dispatch(serversResponseSuccess(GET_CHAT_USERS, result)))
    .catch((error) => handleError(GET_CHAT_USERS, error, dispatch));
};

export const setChatUsersQuery = (query) => ({ type: SET_CHAT_USERS_QUERY, query });

// Chat: allUserChats

export const getOrCreateChatRoom = (query) => (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getOrCreateChatRoom');
  request(query)
    .then((result) => {
      dispatch(serversResponseSuccess(GET_OR_CREATE_CHAT_ROOM, result));
    })
    .catch((error) => handleError(GET_OR_CREATE_CHAT_ROOM, error, dispatch));
};

export const setChatRoom = (obj) => ({ type: SET_CHAT_ROOM, obj });

export const getChatRooms = (query) => (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getChatRooms');
  request(query)
    .then((result) => {
      dispatch(serversResponseSuccess(GET_CHAT_ROOMS, result));
    })
    .catch((error) => handleError(GET_CHAT_ROOMS, error, dispatch));
};

export const registerChatRoom = (params) => (dispatch) => {
  dispatch(serversUpdateRequest());
  const request = functions.httpsCallable('registerChatRoom');
  request(params)
    .then((result) => {
      dispatch(serversResponseSuccess(REGISTER_CHAT_ROOM, result));
    })
    .catch((error) => handleError(REGISTER_CHAT_ROOM, error, dispatch))
    .finally(() => dispatch(getChatRooms(store.getState().servers.chatRoomsQuery)));
};

export const delChatRoom = (params) => (dispatch) => {
  dispatch(serversUpdateRequest());
  const request = functions.httpsCallable('delChatRoom');
  request(params)
    .then((result) => {
      dispatch(serversResponseSuccess(DELETE_CHAT_ROOM, result));
    })
    .catch((error) => handleError(DELETE_CHAT_ROOM, error, dispatch))
    .finally(() => dispatch(getChatRooms(store.getState().servers.chatRoomsQuery)));
};

export const setChatRoomsQuery = (query) => ({ type: SET_CHAT_ROOMS_QUERY, query });

// Chat: chatMessages

export const getChatMessages = (query, bottomScroll = false) => (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getChatMessages');
  request(query)
    .then((result) => {
      dispatch(serversResponseSuccess(GET_CHAT_MESSAGES, result));
      if (bottomScroll) {
        setTimeout(() => scroller.scrollToBottom({ duration: 0, delay: 0, smooth: false }), 1);
      }
    })
    .catch((error) => handleError(GET_CHAT_MESSAGES, error, dispatch));
};

export const registerChatMessage = (params) => (dispatch) => {
  dispatch(serversUpdateRequest());
  const request = functions.httpsCallable('registerChatMessage');
  request(params)
    .then((result) => {
      dispatch(serversResponseSuccess(REGISTER_CHAT_MESSAGE, result));
    })
    .catch((error) => handleError(REGISTER_CHAT_MESSAGE, error, dispatch))
    .finally(() => dispatch(getChatMessages(store.getState().servers.chatMessagesQuery, true)));
};

export const delChatMessage = (params) => (dispatch) => {
  dispatch(serversUpdateRequest());
  const request = functions.httpsCallable('delChatMessage');
  request(params)
    .then((result) => {
      dispatch(serversResponseSuccess(DELETE_CHAT_MESSAGE, result));
    })
    .catch((error) => handleError(DELETE_CHAT_MESSAGE, error, dispatch))
    .finally(() => dispatch(getChatMessages(store.getState().servers.chatMessagesQuery, true)));
};

export const setChatMessagesQuery = (query) => ({ type: SET_CHAT_MESSAGES_QUERY, query });

// Section: Google map api

export const getPlaceAutocomplete = (query) => (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getPlaceAutocomplete');
  request(query)
    .then((result) => {
      dispatch(serversResponseSuccess(GET_PLACE_AUTOCOMPLETE, result));
    })
    .catch((error) => handleError(GET_PLACE_AUTOCOMPLETE, error, dispatch));
};

export const clearSuggestionsPlaces = () => ({ type: CLEAR_SUGGESTIONS_PLACES });

export const setNotificationQuery = (query) => ({ type: SET_NOTIFICATION_QUERY, query });

export const getNotifications = (query: IQuery) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getNotices');
  try {
    const response = await request(query);
    const user = store.getState().servers.user;
    const resNotification: INotification[] = response?.data?.objects;
    const notifications: INotification[] = resNotification.map((notification) => {
      return {
        ...notification,
        isNew: getNotificationStatus(notification, user.id),
      };
    });
    dispatch(serversResponseSuccess(GET_NOTIFICATIONS, notifications));
  } catch (response) {
    handleError(GET_NOTIFICATIONS, response, dispatch);
  }
};

export const getAdditionalNotifications = (query: IQuery) => async (dispatch) => {
  dispatch(serversAdditionalGetRequest());
  const request = functions.httpsCallable('getNotices');
  try {
    const response = await request(query);
    const user = store.getState().servers.user;
    const resNotification: INotification[] = response?.data?.objects;
    const notifications: INotification[] = resNotification.map((notification) => {
      return {
        ...notification,
        isNew: getNotificationStatus(notification, user.id),
      };
    });
    dispatch(serversResponseSuccess(GET_ADDITIONAL_NOTIFICATIONS, notifications));
  } catch (response) {
    handleError(GET_ADDITIONAL_NOTIFICATIONS, response, dispatch);
  }
};

// Sreenath-25Nov21- reworked on registerNotification, getNewNotificationCount, addIdToReadNotificationIdList
// to register/update and to fetch the unread notifications based on user id in the readBy field of notices collection
export const registerNotification = (notificationObj: Partial<INotification>) => async (
  dispatch,
) => {
  const request = functions.httpsCallable('registerNotice');
  const lang = store.getState().apps.currentLanguage;
  try {
    let payload = notificationObj;
    if (notificationObj.userId) {
      payload = Object.assign({}, notificationObj, { readBy: notificationObj.userId });
    }
    const response = await request(payload);
    dispatch(serversResponseSuccess(REGISTER_NOTIFICATION, response));
    if (!notificationObj.userId) {
      dispatch(openSnackbarMessage('success', appLanguages.notificationSent[lang]));
    }
  } catch (response) {
    handleError(REGISTER_NOTIFICATION, response, dispatch);
  }
};

export const getNewNotificationCount = (id: any) => async (dispatch) => {
  dispatch(serversAdditionalGetRequest());
  const request = functions.httpsCallable('getUnReadNoticesCount');
  try {
    const response = await request({ userId: id });
    const unreadNoticesCount = response?.data?.totalCounts;
    dispatch(serversResponseSuccess(GET_NEW_NOTIFICATION_COUNT, unreadNoticesCount));
  } catch (response) {
    handleError(GET_NEW_NOTIFICATION_COUNT, response, dispatch);
  }
};

export const addIdToReadNotificationIdList = (notice: any) => async (dispatch) => {
  await addReadNotificationId(notice.id);
  await dispatch(registerNotification(notice));
  await dispatch(getNewNotificationCount(notice.userId));
  await dispatch(getNotifications(DefaultQuery));
};

export const getLocationRegions = () => (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getLocationRegions');
  request({})
    .then((result) => dispatch(serversResponseSuccess(GET_LOCATION_REGIONS, result)))
    .catch((error) => handleError(GET_LOCATION_REGIONS, error, dispatch));
};

export const setAlertFilterQuery = (query) => ({ type: SET_ALERT_FILTER_QUERY, query });

export const registerSerials = (params, successMessage?: string) => async (dispatch) => {
  //console.log('registerSerials');
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('registerSerials');
  //console.log('registerSerials2');

  try {
    const response = await request(params);
    dispatch(serversResponseSuccess(REGISTER_SERIALS, response));
    if (params.id) {
      dispatch(openSnackbarMessage('success', successMessage || appLanguages.updatedMessage[lang]));
    } else {
      dispatch(openSnackbarMessage('success', successMessage || appLanguages.addedMessage[lang]));
    }
    dispatch(setEditSerial({}));
    dispatch(closeSerialEditDialog());
  } catch (error) {
    handleError(REGISTER_SERIALS, error, dispatch);
  } finally {
    //console.log(JSON.stringify(params));
    //console.log(JSON.stringify(params.serials[0].code));
    dispatch(getSerials({ where: { id: params.serials[0].id } }));
    //dispatch(getStocks(store.getState().servers.stocksQuery));
  }
};

//This is copy of above function but finally block has been commented out to solve the issue for cancel SHipping -> Stock management tranisition
export const registerSerialsCancelShipping = (params, successMessage?: string) => async (
  dispatch,
) => {
  dispatch(serversUpdateRequest());
  const lang = store.getState().apps.currentLanguage;
  const request = functions.httpsCallable('registerSerials');

  try {
    const response = await request(params);
    dispatch(serversResponseSuccess(REGISTER_SERIALS, response));
    if (params.id) {
      dispatch(openSnackbarMessage('success', successMessage || appLanguages.updatedMessage[lang]));
    } else {
      dispatch(openSnackbarMessage('success', successMessage || appLanguages.addedMessage[lang]));
    }
    dispatch(setEditSerial({}));
    dispatch(closeSerialEditDialog());
  } catch (error) {
    handleError(REGISTER_SERIALS, error, dispatch);
  } finally {
    //dispatch(getSerials(store.getState().servers.serialsQuery));
    //dispatch(getStocks(store.getState().servers.stocksQuery));
  }
};

export const getStocks = (query: IQuery) => async (dispatch) => {
  if (!isOk(query?.brewLocId) && !isOk(query?.distLocId) && !isOk(query?.restLocId)) return;

  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getStocks');
  try {
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_STOCKS, response));
  } catch (error) {
    handleError(GET_STOCKS, error, dispatch);
  }
};

export const getAnalysis = (query: IQuery = {}) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getAnalysis');
  try {
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_ANALYSIS, response));
    return response;
  } catch (response) {
    handleError(GET_ANALYSIS, response, dispatch);
  }
};

export const getAlertAnalysis = (query: IQuery = {}) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getAlertAnalysis');
  try {
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_ALERT_ANALYSIS, response));
    return response;
  } catch (response) {
    handleError(GET_ALERT_ANALYSIS, response, dispatch);
  }
};

export const getTransaction = (query: IQuery = {}) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getTransaction');
  try {
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_TRANSACTION, response));
    return response;
  } catch (response) {
    handleError(GET_TRANSACTION, response, dispatch);
  }
};

export const getInventory = (query: IQuery = {}) => async (dispatch) => {
  dispatch(serversGetRequest());
  const request = functions.httpsCallable('getInventory');
  try {
    const response = await request(query);
    dispatch(serversResponseSuccess(GET_INVENTORY, response));
    return response;
  } catch (response) {
    handleError(GET_INVENTORY, response, dispatch);
  }
};
