import { StyleRules, withStyles } from '@material-ui/core/styles';
import { IStateApps } from '@models/state-apps';
import { IStoreState } from '@redux/reducers';
import GoogleMapReact from 'google-map-react';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

/**
 * ref1: https://github.com/google-map-react/google-map-react/blob/HEAD/API.md
 * ref2: https://qiita.com/miyabiya/items/98c8a177e6077ee50971
 */
class GoogleMapViewClass extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      maps: null,
      marker: null,
    };
  }

  setMarker() {
    const { lat, lng } = this.props;
    const { map, maps, marker } = this.state;
    if (marker) marker.setMap(null);
    this.setState({ marker: null });
    if (map && maps && lat && lng) {
      const position = { lat, lng };
      const newMarker = new maps.Marker({ position, map });
      this.setState({ marker: newMarker });
    }
  }

  handleGoogleApiLoaded(map, maps) {
    this.setState({ map, maps });
    this.setMarker();
  }

  handleChange() {
    this.setMarker();
  }

  render() {
    const { apps, apiKey, lat, lng, zoom } = this.props;
    const center = { lat, lng };
    const lang = apps.currentLanguage;
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '300px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: apiKey, language: lang }}
          center={center}
          defaultZoom={zoom}
          onGoogleApiLoaded={({ map, maps }) => this.handleGoogleApiLoaded(map, maps)}
          yesIWantToUseGoogleMapApiInternals
          onChange={() => this.handleChange()}
        />
      </div>
    );
  }
}

export type Props = IStateProps;

export interface IStateProps {
  apps: IStateApps;
  apiKey: string;
  lat: number;
  lng: number;
  zoom: number;
}

interface State {
  map: any;
  maps: any;
  marker: any;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
});

const myStyles = (): StyleRules => ({});

export const GoogleMapView = compose(
  withStyles(myStyles),
  connect(mapStateToProps),
)(GoogleMapViewClass);
