import { i18n } from '@i18n/lang';
import { TSupportedLanguages } from '@models/supported-languages';
import { LANGUAGE_DEFAULT } from '@utils/common';
import { Icon } from '@visual/icon';
import { TextDisplay } from '@visual/text-display';
import React from 'react';
import styles from './style.scss';

export class SearchFilterLink extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    lang: LANGUAGE_DEFAULT,
  };

  public render(): JSX.Element {
    const { lang, onSearchButtonClick } = this.props;
    return (
      <div className={styles.filterAnchor}>
        <div className={styles.contents} onClick={onSearchButtonClick}>
          <span className={styles.searchIcon}>
            <Icon color='primary' type='search' />
          </span>
          <TextDisplay color='secondary-light2'>{i18n.searchFilter[lang]}</TextDisplay>
        </div>
      </div>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  lang?: TSupportedLanguages;
}
export interface IDispatchProps {
  onSearchButtonClick: () => void;
}
