import { i18n } from '@i18n/lang';
import { EAlertStatus } from '@models/alert-status-type';
import { TSupportedLanguages } from '@models/supported-languages';
import { LANGUAGE_DEFAULT } from '@utils/common';
import { formatString } from '@utils/format-string';
import { getAlertStatusText } from '@utils/get-alert-status-text';
import { Props as ButtonProps } from '@visual/button';
import { getPreviousStatus } from './get-previous-status';

export function getAlertRevertButtonProps(
  status: EAlertStatus,
  lang: TSupportedLanguages = LANGUAGE_DEFAULT,
): Partial<ButtonProps> {
  if (!status) return;
  const previousStatus: EAlertStatus = getPreviousStatus(status);
  const buttonProps: Partial<ButtonProps> = {};
  //console.log('previousStatus: ' + previousStatus);
  switch (status) {
    case EAlertStatus.UNPROCESSED:
    case EAlertStatus.PROCESSING:
      buttonProps.children = formatString(i18n.revertTo[lang], {
        field: getAlertStatusText(previousStatus, lang),
      });
      buttonProps.color = 'status1';
      break;
    /*case EAlertStatus.PROCESSING:
      buttonProps.children = formatString(i18n.revertTo[lang], { field: i18n.process[lang] });
      buttonProps.color = 'status2';
      break;*/
    case EAlertStatus.PROCESSED:
    case EAlertStatus.APPROVED:
      buttonProps.children = formatString(i18n.convertTo2[lang], {
        field: getAlertStatusText(status, lang),
      });
      // buttonProps.children = i18n.toUnprocessable[lang];
      buttonProps.color = 'status3';
      break;
    /*case EAlertStatus.APPROVED:
      buttonProps.children = formatString(i18n.revertTo[lang], {
        field: getAlertStatusText(previousStatus, lang),
      });
      buttonProps.color = 'dark0';
      break;*/
    case EAlertStatus.UNPROCESSABLE:
      buttonProps.children = i18n.toUnprocessable[lang];
      buttonProps.color = 'status4';
  }
  return buttonProps;
}
