import { EAlertStatus } from '@models/alert-status-type';
import { TSupportedLanguages } from '@models/supported-languages';
import { LANGUAGE_DEFAULT } from '@utils/common';
import {
  AlertStatusPill,
  Props as AlertStatusPillProps,
  Theme as AlertStatusPillTheme,
} from '@visual/alert-status-pill';
import React from 'react';
import { getAlertStatusName } from './get-alert-status-name';

export function getAlertStatusPill(
  status: EAlertStatus,
  lang: TSupportedLanguages = LANGUAGE_DEFAULT,
  theme: AlertStatusPillTheme = 'pill',
): JSX.Element {
  if (!status) return;
  const alertStatusProps: Partial<AlertStatusPillProps> = {
    type: getAlertStatusName(status),
    lang,
    theme,
  };
  return <AlertStatusPill {...alertStatusProps} />;
}
