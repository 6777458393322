export function getDateDuration(from, to) {
  try {
    if (!from) return;
    const useTo = (to && new Date(to).getTime()) || new Date().getTime();
    const duration = useTo - new Date(from).getTime();
    const days = Math.floor(duration / (1000 * 60 * 60 * 24));
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);

    return [
      days > 0 && `${days}d `,
      (hours > 0 || minutes > 0) && `${String(hours).padStart(2, '0')}:`,
      (hours > 0 || minutes > 0) && `${String(minutes).padStart(2, '0')}`,
    ]
      .filter((dur) => dur)
      .join('');
  } catch {
    return;
  }
}
