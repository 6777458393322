import { DistributionDetail } from '@container/pages/distribution-detail';
import { DistributionHistoryList } from '@container/pages/distribution-history-list';
import { MainMenu } from '@container/pages/main-menu';
import { Monitoring } from '@container/pages/monitoring';
import { NotificationList } from '@container/pages/notification-list';
import { i18n } from '@i18n/lang';
import { IQuery } from '@models/query';
import { IStateApps } from '@models/state-apps';
import { IStateAuth } from '@models/state-auth';
import { IStateServers } from '@models/state-servers';
import { Charts } from '@old-components/charts';
import { Chat } from '@old-components/chat';
import { Header } from '@old-components/header';
import { Master } from '@old-components/master';
import { NotFound } from '@old-components/not-found';
import { Progress } from '@old-components/progress';
import { SnackbarMessage } from '@old-components/snackbar';
import {
  getAllBrands,
  getAllLocations,
  getAuthUser,
  getScanIntervalTime,
  registerLoginHistory,
} from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import { AppRoutePaths } from '@utils/app-route-paths';
import { LANGUAGE_DEFAULT } from '@utils/common';
import { injectJS } from '@utils/inject-js';
import { isOk } from '@utils/is-ok';
import { Footer } from '@visual/footer';
import { NotificationDetailPopup } from '@visual/notification-detail-popup';
import { PageContainer } from '@visual/page-container';
import { History, LocationState } from 'history';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import userEnv from 'userEnv';

class LayoutClass extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      showPrivacyPolicyPopup: false,
    };
  }

  public async componentDidMount() {
    const {
      getAuthUser,
      getAllBrands,
      getAllLocations,
      getScanIntervalTime,
      registerLoginHistory,
      servers: { allLocations, allBrands, isLoginHistoryRegistered },
    } = this.props;

    getAuthUser();
    getScanIntervalTime();

    if (!allBrands) getAllBrands();
    if (!allLocations) getAllLocations();
    if (!isLoginHistoryRegistered) registerLoginHistory();
    this.addGoogleMapSuggest();
  }

  public render(): JSX.Element {
    const {
      apps: { nowSetting, currentLanguage: lang, isFooterVisible },
      servers: { isServerGetAuthUserRequesting, user },
      auth: { isRequesting },
    } = this.props;
    if (nowSetting || isServerGetAuthUserRequesting || isRequesting || !isOk(user)) {
      return <Progress />;
    }

    return (
      <>
        <Header />
        <PageContainer>
          <Switch>
            <Route
              exact
              path='/'
              render={() => <Redirect to={AppRoutePaths['main-menu'].path} />}
            />
            <Route exact path={AppRoutePaths['main-menu'].path} component={MainMenu} />
            <Route exact path={AppRoutePaths.master.path} component={Master} />
            <Route
              exact
              path={AppRoutePaths['distribution-history'].path}
              component={DistributionHistoryList}
            />
            <Route
              path={AppRoutePaths['distribution-detail'].path}
              component={DistributionDetail}
            />
            <Route path={AppRoutePaths['notification-list'].path} component={NotificationList} />
            <Route exact path={AppRoutePaths['monitoring'].path} component={Monitoring} />
            <Route
              exact
              path={AppRoutePaths['monitoring-details'].path}
              render={(props) => <DistributionDetail {...props} showAlerts={true} />}
            />
            <Route exact path={AppRoutePaths.chat.path} component={Chat} />
            <Route exact path={AppRoutePaths['charts'].path} component={Charts} />
            <Route component={NotFound} />
          </Switch>
          {this.getPrivacyPolicyPopup()}
        </PageContainer>
        {isFooterVisible && (
          <Footer lang={lang} onPrivacyMessageClick={() => this.togglePrivacyPolicyPopup()} />
        )}
        <SnackbarMessage />
      </>
    );
  }

  protected getPrivacyPolicyPopup() {
    const { showPrivacyPolicyPopup } = this.state;
    const {
      apps: { currentLanguage: lang },
    } = this.props;
    return (
      <NotificationDetailPopup
        lang={lang}
        showPopup={showPrivacyPolicyPopup}
        title={i18n.privacyPolicy[lang]}
        onClose={() => this.togglePrivacyPolicyPopup()}
        onConfirmationButtonClick={() => this.togglePrivacyPolicyPopup()}
      >
        {i18n.privacyPolicyMessageBody[lang]}
      </NotificationDetailPopup>
    );
  }

  protected togglePrivacyPolicyPopup() {
    const { showPrivacyPolicyPopup } = this.state;
    this.setState({
      showPrivacyPolicyPopup: !showPrivacyPolicyPopup,
    });
  }

  protected addGoogleMapSuggest() {
    const useLang = this.props.apps.currentLanguage || LANGUAGE_DEFAULT;
    const apiKey = userEnv.googleMapApiKey;
    const librariesQuery = 'libraries=places,visualization,geometry&sensor=false';
    injectJS(
      `https://maps.googleapis.com/maps/api/js?language=${useLang}&key=${apiKey}&${librariesQuery}`,
      'head',
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  auth: IStateAuth;
  history?: History<LocationState>;
  classes?: any;
}

export interface IDispatchProps {
  getAuthUser: () => void;
  getScanIntervalTime: () => void;
  getAllBrands: (query?: IQuery) => void;
  getAllLocations: (query?: IQuery) => void;
  registerLoginHistory: () => void;
}

interface State {
  showPrivacyPolicyPopup: boolean;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
  auth: state.auth,
});

const mapDispatchToProps: IDispatchProps = {
  getAuthUser,
  getScanIntervalTime,
  getAllBrands,
  getAllLocations,
  registerLoginHistory,
};

export const Layout = compose(connect(mapStateToProps, mapDispatchToProps))(
  withRouter(LayoutClass),
);
