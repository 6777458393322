import { i18n } from '@i18n/lang';
import { EAlertStatus } from '@models/alert-status-type';
import { TSupportedLanguages } from '@models/supported-languages';
import { LANGUAGE_DEFAULT } from '@utils/common';

export function getAlertStatusText(
  status: EAlertStatus,
  lang: TSupportedLanguages = LANGUAGE_DEFAULT,
): string {
  if (!status) return;
  switch (status) {
    case EAlertStatus.UNPROCESSED:
      return i18n.unprocessed[lang];

    case EAlertStatus.PROCESSING:
      return i18n.processing[lang];

    case EAlertStatus.PROCESSED:
      return i18n.processed[lang];

    case EAlertStatus.APPROVED:
      return i18n.approved[lang];

    case EAlertStatus.UNPROCESSABLE:
      return i18n.unprocessed[lang];
  }
}
