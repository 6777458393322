import TabPanel, { a11yProps } from '@elements/TabPanel';
import { i18n } from '@i18n/lang';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Paper,
  Slider,
  StyleRules,
  Tab,
  Tabs,
  TextField,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { IDeactivateRequest } from '@models/api-deactivate-location';
import { IGetImages } from '@models/api-get-images';
import { IRejectRequest } from '@models/api-reject-location';
import { ECategoryType } from '@models/category-type';
import { ILocation } from '@models/location';
import { IQuery } from '@models/query';
import { ERestaurantImageType } from '@models/restaurant-image-type';
import { ERestaurantStatus } from '@models/restaurant-status-type';
import { IStateApps } from '@models/state-apps';
import { IStateServers } from '@models/state-servers';
import {
  closeLocationEditDialog,
  openSnackbarMessage,
  openUserEditDialog,
  setEditLocation,
  setEditUser,
} from '@redux/actions/appsActions';
import {
  activateLocation,
  deactivateLocation,
  deleteEditLocationImages,
  getLocationsImages,
  getMasterLocations,
  getSuppliers,
  registerLocation,
  rejectLocation,
  setEditLocationImages,
} from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import appLanguages from '@utils/app-languages';
import {
  BAIDU_ONLY_COUNTRIES,
  exportToCSV,
  getAddTitle,
  getAutocomplete,
  getAutocompleteFreeSolo,
  getCSVFileUploadButton,
  getEditTitle,
  getFileDeleteButton,
  getNumericTextField,
  getOutlinedButton,
  getTextField,
  getTextFieldMultiline,
  getTextFieldMultiline2,
  TOKYO_COORDINATES,
} from '@utils/common';
import { DefaultQuery } from '@utils/default-query';
import { functions } from '@utils/firebase';
import { getCuisineLangList } from '@utils/get-cuisine-lang-list';
import { getCuisineTypeName } from '@utils/get-cuisine-type-name';
import { getPriceTypeLangList } from '@utils/get-price-type-lang-list copy';
import { getPriceTypeName } from '@utils/get-price-type-name';
import { getUserCategory, isUserBreweryOrAdmin } from '@utils/get-user-category';
import { isOk } from '@utils/is-ok';
import { replaceNonAlphaNumeric } from '@utils/replace-non-alphanumeric';
import { BaiduMapLocation } from '@visual/map-view-baidu';
import { RestaurantImageSlider } from '@visual/restaurant-image-slider';
import * as csvSync from 'csv-parse/lib/sync';
import * as hash from 'object-hash';
import React, { createRef, RefObject } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import userEnv from 'userEnv';
import _countries from '../../../../utils/countries-multiple-lang.json';
import UserEditDialog from '../../users/config/user-edit-dialog';
import { GoogleMapSuggest } from './google-map-suggest';
import { GoogleMapView } from './google-map-view';

const defaultTimestamp = '1970-01-01T00:00:00.000Z';
const options = [
  { label: 'OFF', value: null },
  { label: '30', value: 30 },
  { label: '60', value: 60 },
  { label: '90', value: 90 },
  { label: '120', value: 120 },
  { label: '150', value: 150 },
  { label: '180', value: 180 },
  { label: '210', value: 210 },
  { label: '240', value: 240 },
  { label: '270', value: 270 },
  { label: '300', value: 300 },
  { label: '330', value: 330 },
  { label: '360', value: 360 },
];
class LocationEditDialogClass extends React.PureComponent<Props, State> {
  inputRef: RefObject<HTMLInputElement>;
  constructor(props: Props) {
    const editCountry = props?.apps?.editLocation?.country;
    super(props);
    this.inputRef = createRef();
    this.state = {
      mainTabValue: 0,
      restImageTabValue: 0,
      csvFileText: null,
      csvFileObjects: null,
      tabValue: 0,
      candidatePublicIds: [],
      csvUpCount: 0,
      warningMessages: [],
      infoMessages: [],
      registering: false,
      isDeactivateDialogOpen: false,
      isRejectDialogOpen: false,
      deactivateReason: '',
      rejectReason: '',
      mapMode: BAIDU_ONLY_COUNTRIES.includes(editCountry) ? 'baidu' : 'google-maps',
      mapAddr: props?.apps?.editLocation?.mapAddress,
      qualityDuration: 60,
    };
  }

  public componentDidMount() {
    // setTimeout(() => this.setCandidatePublicIds(), 10);
    // Get Images if Restaurant Type
    const { servers } = this.props;
    const locationObj = this.getLocationObject();
    const query: IQuery = DefaultQuery;
    let quality_alert_value = this.props.servers.locations
      .filter((obj) => obj.name === locationObj.name && obj.country === locationObj.country) // Filter objects with a value of 1 for property a
      .map((obj) => obj.quality_alert_duration); // Extract the values of property b for the filtered objects
    this.setState({ qualityDuration: quality_alert_value[0] });

    if (locationObj?.category === ECategoryType.RESTAURANT && isOk(locationObj?.id)) {
      // Sreenath11Nov21- fetch records with default deletedAt value, which means they are not deleted.
      query.where = { locationId: locationObj?.id, deletedAt: defaultTimestamp };
    } else {
      // Sreenath16Nov21- Fetches zero records this is to set the editLocationImages state value to [].
      query.where = { locationId: null, deletedAt: defaultTimestamp };
    }

    this.props.getLocationsImages(query);
  }

  async handleReject() {
    const locationObj: Partial<ILocation> = this.getLocationObject();
    const { rejectReason } = this.state;
    const { rejectLocation, servers, getMasterLocations } = this.props;
    await rejectLocation({ id: locationObj.id, reason: rejectReason });
    getMasterLocations(servers.locationsQuery);
    this.setState({ isRejectDialogOpen: false });
  }
  handleChangeDuration(newValue) {
    this.setState({ qualityDuration: newValue.value });
  }

  public render() {
    const {
      mainTabValue,
      csvFileText,
      tabValue,
      candidatePublicIds,
      registering,
      isDeactivateDialogOpen,
      isRejectDialogOpen,
      deactivateReason,
      rejectReason,
    } = this.state;
    const { classes, apps, servers } = this.props;
    const { categoryMapLocation, subCategoryMap } = userEnv;
    const lang = apps.currentLanguage;
    const { closeLocationEditDialog } = this.props;
    const locationObj: Partial<ILocation> = this.getLocationObject();
    const { id } = locationObj;

    //console.log(locationObj);
    const userCategory = getUserCategory(servers?.user);
    // const isAdmin = isUserAdmin(servers?.user);

    const handleOpenDeactivateDialog = () => {
      this.setState({ isDeactivateDialogOpen: true });
    };

    const handleCloseDeactivateDialog = () => {
      this.setState({ isDeactivateDialogOpen: false });
    };

    const handleChangeDeactivateReason = (e) => {
      this.setState({ deactivateReason: e.target.value });
    };

    const handleDeactivate = () => {
      const { deactivateLocation } = this.props;
      deactivateLocation({ id: locationObj.id, reason: deactivateReason });
      this.setState({ isDeactivateDialogOpen: false });
    };

    const handleOpenRejectDialog = () => {
      this.setState({ isRejectDialogOpen: true });
    };

    const handleCloseRejectDialog = () => {
      this.setState({ isRejectDialogOpen: false });
    };

    const handleChangeRejectReason = (e) => {
      console.log(e.target.value);
      if (e.target.value.includes('\n')) console.log('改行あり');
      this.setState({ rejectReason: e.target.value });
    };

    // const handleReject = () => {
    //   const { rejectLocation, servers, getMasterLocations } = this.props;
    //   rejectLocation({ id: locationObj.id, reason: rejectReason });
    //   getMasterLocations(servers.locationsQuery);
    //   this.setState({ isRejectDialogOpen: false });
    // };
    // Categories
    let categories = Object.entries(categoryMapLocation[lang]).map(([k, v]) => ({
      id: k,
      name: v,
    }));

    if (userCategory === ECategoryType.DISTRIBUTOR) {
      categories = categories.filter(
        (category) => category.id === String(ECategoryType.RESTAURANT),
      );
    }

    let categoryValue = {
      id: locationObj.category,
      name: categoryMapLocation[lang][locationObj.category],
    };

    // Sub Categories
    const subCategories = Object.entries(subCategoryMap[lang]).map(([k, v]) => ({
      id: k,
      name: v,
    }));

    const subCategoryValue = {
      id: locationObj.subCategory,
      name: subCategoryMap[lang][locationObj.subCategory],
    };

    // Cuisine Types
    const cuisineTypes = getCuisineLangList(lang);
    const cuisineTypeValue = {
      id: locationObj.cuisineType,
      name: getCuisineTypeName(locationObj.cuisineType, lang),
    };

    // Price Types
    const priceTypes = getPriceTypeLangList(lang, locationObj.country);
    const priceTypesValue = {
      id: locationObj.priceType,
      name: getPriceTypeName(locationObj.priceType, lang, locationObj.country),
    };

    // Suppliers
    // let supplierValue = null;
    // const suppliers = servers.suppliers.map((o) => {
    //   const v = {
    //     id: o.id,
    //     name: `[${categoryMapLocation[lang][o.category]}] ${o.name}`,
    //   };
    //   if (o.id === locationObj.supplierId) supplierValue = { ...v };
    //   return v;
    // });

    const isRequesting = servers.isGetRequesting || servers.isRequesting || registering;

    let activateButton = null;
    if (
      isUserBreweryOrAdmin(servers?.user) &&
      locationObj.category === ECategoryType.RESTAURANT &&
      locationObj.status === ERestaurantStatus.REVIEWING
    ) {
      activateButton = getOutlinedButton(
        appLanguages.activateAndCreateUser[lang],
        isRequesting,
        () => this.handleActivation(false),
      );
    }

    let activateOnlyButton = null;
    if (
      isUserBreweryOrAdmin(servers?.user) &&
      locationObj.category === ECategoryType.RESTAURANT &&
      locationObj.status === ERestaurantStatus.REVIEWING
    ) {
      activateOnlyButton = getOutlinedButton(appLanguages.activateOnly[lang], isRequesting, () =>
        this.handleActivation(true),
      );
    }

    let rejectButton = null;
    if (
      isUserBreweryOrAdmin(servers?.user) &&
      locationObj.category === ECategoryType.RESTAURANT &&
      locationObj.status === ERestaurantStatus.REVIEWING
    ) {
      rejectButton = getOutlinedButton(
        appLanguages.rejectLocation[lang],
        isRequesting,
        handleOpenRejectDialog,
      );
    }

    let deactivateButton = null;
    if (
      isUserBreweryOrAdmin(servers?.user) &&
      locationObj.category === ECategoryType.RESTAURANT &&
      locationObj.status === ERestaurantStatus.ACTIVE
    ) {
      deactivateButton = getOutlinedButton(
        appLanguages.deactivateLocation[lang],
        isRequesting,
        handleOpenDeactivateDialog,
      );
    }

    const deactivateDialog = () => {
      return (
        <Dialog
          open={isDeactivateDialogOpen}
          onClose={handleCloseDeactivateDialog}
          fullWidth={true}
          maxWidth='xs'
        >
          <DialogTitle>{`${appLanguages.deactivateLocation[lang]}`}</DialogTitle>
          <DialogContent>
            {getTextFieldMultiline(
              `${appLanguages.deactivateReason[lang]}`,
              deactivateReason || '',
              isRequesting || false,
              (e) => handleChangeDeactivateReason(e),
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeactivateDialog} color='primary' autoFocus>
              {`${appLanguages.cancel[lang]}`}
            </Button>
            <Button onClick={handleDeactivate} color='secondary'>
              {`${appLanguages.update[lang]}`}
            </Button>
          </DialogActions>
        </Dialog>
      );
    };

    const rejectDialog = () => {
      return (
        <Dialog
          open={isRejectDialogOpen}
          onClose={handleCloseRejectDialog}
          fullWidth={true}
          maxWidth='xs'
        >
          <DialogTitle>{`${appLanguages.rejectLocation[lang]}`}</DialogTitle>
          <DialogContent>
            {getTextFieldMultiline(
              `${appLanguages.rejectReason[lang]}`,
              rejectReason || '',
              isRequesting || false,
              (e) => handleChangeRejectReason(e),
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseRejectDialog} color='primary'>
              {`${appLanguages.cancel[lang]}`}
            </Button>
            <Button onClick={() => this.handleReject()} color='secondary' autoFocus>
              {`${appLanguages.notify[lang]}`}
            </Button>
          </DialogActions>
        </Dialog>
      );
    };

    const isRestaurantLocation = locationObj.category === ECategoryType.RESTAURANT;
    return (
      <Dialog
        fullWidth
        open={apps.isOpenLocationEditDialog}
        onClose={() => closeLocationEditDialog()}
      >
        {isRequesting ? <LinearProgress color='secondary' /> : ''}
        <DialogTitle>
          {id
            ? getEditTitle(appLanguages.editLocation[lang])
            : getAddTitle(appLanguages.addNewLocation[lang])}
        </DialogTitle>
        <DialogContent>
          <Paper elevation={3} className={classes.paper}>
            {/*
           <AppBar position='static' color='default'>
              <Tabs
                value={mainTabValue}
                variant='scrollable'
                scrollButtons='auto'
                onChange={(e, v) => this.setState({ mainTabValue: v })}
              >
                <Tab label={appLanguages.dataUpload[lang]} {...a11yProps(0)} />
                <Tab label={appLanguages.csvFileBulkAdd[lang]} {...a11yProps(1)} />
              </Tabs>
            </AppBar>
           */}
            <TabPanel value={mainTabValue} index={0}>
              <Box mt={1} mb={3}>
                {/* カテゴリ */}
                {getAutocomplete(
                  `${appLanguages.category[lang]} (${appLanguages.require[lang]})`,
                  { ...categoryValue },
                  [...categories],
                  isRequesting || false,
                  (e, v) => this.handleChangeCategory(v ? v.id : 0),
                )}

                <Box mt={1} />
                {locationObj.category !== ECategoryType.BREWERY && (
                  <>
                    <Box mt={1} />
                    {/* 名前 (Japanese) */}
                    {getTextField(
                      isRestaurantLocation
                        ? `${appLanguages.shopName[lang]} (${appLanguages.langLocal[lang]})`
                        : `${appLanguages.name[lang]} (${appLanguages.require[lang]})`,
                      locationObj.name || '',
                      isRequesting || false,
                      (e) => this.updateEditObj({ name: e.target.value }),
                    )}
                  </>
                )}

                {isRestaurantLocation && (
                  <>
                    {/* 名前 (English) */}
                    <Box mt={1} />
                    {getTextField(
                      `${
                        isRestaurantLocation ? appLanguages.shopName[lang] : appLanguages.name[lang]
                      }${i18n.englishParenthesis[lang]}`,
                      locationObj.enName || '',
                      isRequesting || false,
                      (e) => this.updateEditObj({ enName: e.target.value }, 'alphanumeric'),
                    )}
                    {/* email */}
                    <Box mt={1} />
                    {getTextField(
                      `${appLanguages.email[lang]} (${appLanguages.require[lang]})`,
                      locationObj.email || '',
                      isRequesting || false,
                      (e) => {
                        console.log(e.target.value);
                        this.updateEditObj({ email: e.target.value });
                      },
                    )}
                    <Typography variant='subtitle2' color='secondary'>
                      {appLanguages.emailUsage[lang]}
                    </Typography>
                  </>
                )}

                {/* 取引先番号 */}
                {getAutocompleteFreeSolo(
                  `${appLanguages.customerCode[lang]}`,
                  locationObj.customerCode || '',
                  [...candidatePublicIds],
                  isRequesting || false,
                  (e, v) => this.updateEditObj({ customerCode: v }),
                  (e) => this.updateEditObj({ customerCode: e.target.value }, 'alphanumeric'),
                )}

                {isRestaurantLocation && (
                  <>
                    <Box mt={1} />
                    {getTextField(
                      `${i18n.telephoneNumber[lang]}`,
                      locationObj.tel || '',
                      isRequesting || false,
                      (e) => this.updateEditObj({ tel: e.target.value }, 'numeric'),
                    )}
                  </>
                )}
                <Box mt={1} />
                {/* 住所 [表示用] */}
                {getTextFieldMultiline(
                  `${appLanguages.mainAddress[lang]} (${appLanguages.require[lang]})`,
                  locationObj.mainAddress || '',
                  isRequesting || false,
                  (e) => this.updateEditObj({ mainAddress: e.target.value }),
                )}
                {/* New address format */}
                {/* {this.getAddressForm()} */}
                {locationObj.category === ECategoryType.BREWERY && (
                  <>
                    <Box mt={3} />
                    <Paper elevation={3} className={classes.paper}>
                      <AppBar position='static' color='default'>
                        <Tabs
                          value={tabValue}
                          variant='scrollable'
                          scrollButtons='auto'
                          onChange={(e, v) => this.setState({ tabValue: v })}
                        >
                          <Tab label={appLanguages.langJapanese[lang]} {...a11yProps(0)} />
                          <Tab label={appLanguages.langEnglish[lang]} {...a11yProps(1)} />
                          <Tab label={appLanguages.langChinese[lang]} {...a11yProps(2)} />
                          <Tab label={appLanguages.langCantonese[lang]} {...a11yProps(3)} />
                          <Tab label={appLanguages.langKorean[lang]} {...a11yProps(4)} />
                          <Tab label={appLanguages.langThai[lang]} {...a11yProps(5)} />
                          <Tab label={appLanguages.langVietnamese[lang]} {...a11yProps(6)} />
                        </Tabs>
                      </AppBar>
                      <TabPanel value={tabValue} index={0}>
                        {/* 日本語 */}
                        <>
                          <Box mt={1} />
                          {/* 名前 */}
                          {getTextField(
                            `${appLanguages.name[lang]} (${appLanguages.require[lang]})`,
                            locationObj.name || '',
                            isRequesting || false,
                            (e) => this.updateEditObj({ name: e.target.value }),
                          )}
                          <Box mt={1} />
                          {/* 情報 */}
                          {getTextFieldMultiline(
                            `${appLanguages.information[lang]}`,
                            locationObj.information || '',
                            isRequesting || false,
                            (e) => this.updateEditObj({ information: e.target.value }),
                          )}
                          <Box mt={1} />
                          {/* 説明 */}
                          {getTextFieldMultiline(
                            `${appLanguages.description[lang]}`,
                            locationObj.description || '',
                            isRequesting || false,
                            (e) => this.updateEditObj({ description: e.target.value }),
                          )}
                          <Box mt={1} />
                          {/* 住所 [表示用] */}
                          {getTextFieldMultiline(
                            `${appLanguages.mainAddress[lang]} (${appLanguages.require[lang]})`,
                            locationObj.mainAddress || '',
                            isRequesting || false,
                            (e) => this.updateEditObj({ mainAddress: e.target.value }),
                          )}
                        </>
                      </TabPanel>
                      <TabPanel value={tabValue} index={1}>
                        {/* 英語 */}
                        <>
                          <Box mt={1} />
                          {/* 名前 */}
                          {getTextField(
                            `${appLanguages.name[lang]} (${appLanguages.require[lang]})`,
                            locationObj.enName || '',
                            isRequesting,
                            (e) => this.updateEditObj({ enName: e.target.value }),
                          )}
                          <Box mt={1} />
                          {/* 情報 */}
                          {getTextFieldMultiline(
                            `${appLanguages.information[lang]}`,
                            locationObj.enInformation || '',
                            isRequesting,
                            (e) => this.updateEditObj({ enInformation: e.target.value }),
                          )}
                          <Box mt={1} />
                          {/* 説明 */}
                          {getTextFieldMultiline(
                            `${appLanguages.description[lang]}`,
                            locationObj.enDescription || '',
                            isRequesting,
                            (e) => this.updateEditObj({ enDescription: e.target.value }),
                          )}
                          <Box mt={1} />
                          {/* 住所 [表示用] */}
                          {getTextFieldMultiline(
                            `${appLanguages.mainAddress[lang]}`,
                            locationObj.enMainAddress || '',
                            isRequesting,
                            (e) => this.updateEditObj({ enMainAddress: e.target.value }),
                          )}
                        </>
                      </TabPanel>
                      <TabPanel value={tabValue} index={2}>
                        {/* 中国語 */}
                        <>
                          <Box mt={1} />
                          {/* 名前 */}
                          {getTextField(
                            `${appLanguages.name[lang]} (${appLanguages.require[lang]})`,
                            locationObj.cnName || '',
                            isRequesting,
                            (e) => this.updateEditObj({ cnName: e.target.value }),
                          )}
                          <Box mt={1} />
                          {/* 情報 */}
                          {getTextFieldMultiline(
                            `${appLanguages.information[lang]}`,
                            locationObj.cnInformation || '',
                            isRequesting,
                            (e) => this.updateEditObj({ cnInformation: e.target.value }),
                          )}
                          <Box mt={1} />
                          {/* 説明 */}
                          {getTextFieldMultiline(
                            `${appLanguages.description[lang]}`,
                            locationObj.cnDescription || '',
                            isRequesting,
                            (e) => this.updateEditObj({ cnDescription: e.target.value }),
                          )}
                          <Box mt={1} />
                          {/* 住所（表示用） */}
                          {getTextFieldMultiline(
                            `${appLanguages.mainAddress[lang]}`,
                            locationObj.cnMainAddress || '',
                            isRequesting,
                            (e) => this.updateEditObj({ cnMainAddress: e.target.value }),
                          )}
                        </>
                      </TabPanel>
                      <TabPanel value={tabValue} index={3}>
                        {/* 香港語 */}
                        <>
                          <Box mt={1} />
                          {/* 名前 */}
                          {getTextField(
                            `${appLanguages.name[lang]} (${appLanguages.require[lang]})`,
                            locationObj.hkName || '',
                            isRequesting,
                            (e) => this.updateEditObj({ hkName: e.target.value }),
                          )}
                          <Box mt={1} />
                          {/* 情報 */}
                          {getTextFieldMultiline(
                            `${appLanguages.information[lang]}`,
                            locationObj.hkInformation || '',
                            isRequesting,
                            (e) => this.updateEditObj({ hkInformation: e.target.value }),
                          )}
                          <Box mt={1} />
                          {/* 説明 */}
                          {getTextFieldMultiline(
                            `${appLanguages.description[lang]}`,
                            locationObj.hkDescription || '',
                            isRequesting,
                            (e) => this.updateEditObj({ hkDescription: e.target.value }),
                          )}
                          <Box mt={1} />
                          {/* 住所（表示用） */}
                          {getTextFieldMultiline(
                            `${appLanguages.mainAddress[lang]}`,
                            locationObj.hkMainAddress || '',
                            isRequesting,
                            (e) => this.updateEditObj({ hkMainAddress: e.target.value }),
                          )}
                        </>
                      </TabPanel>
                      <TabPanel value={tabValue} index={4}>
                        {/* 韓国語 */}
                        <>
                          <Box mt={1} />
                          {/* 名前 */}
                          {getTextField(
                            `${appLanguages.name[lang]} (${appLanguages.require[lang]})`,
                            locationObj.krName || '',
                            isRequesting,
                            (e) => this.updateEditObj({ krName: e.target.value }),
                          )}
                          <Box mt={1} />
                          {/* 情報 */}
                          {getTextFieldMultiline(
                            `${appLanguages.information[lang]}`,
                            locationObj.krInformation || '',
                            isRequesting,
                            (e) => this.updateEditObj({ krInformation: e.target.value }),
                          )}
                          <Box mt={1} />
                          {/* 説明 */}
                          {getTextFieldMultiline(
                            `${appLanguages.description[lang]}`,
                            locationObj.krDescription || '',
                            isRequesting,
                            (e) => this.updateEditObj({ krDescription: e.target.value }),
                          )}
                          <Box mt={1} />
                          {/* 住所（表示用） */}
                          {getTextFieldMultiline(
                            `${appLanguages.mainAddress[lang]}`,
                            locationObj.krMainAddress || '',
                            isRequesting,
                            (e) => this.updateEditObj({ krMainAddress: e.target.value }),
                          )}
                        </>
                      </TabPanel>
                      <TabPanel value={tabValue} index={5}>
                        {/* タイ語 */}
                        <>
                          <Box mt={1} />
                          {/* 名前 */}
                          {getTextField(
                            `${appLanguages.name[lang]} (${appLanguages.require[lang]})`,
                            locationObj.thName || '',
                            isRequesting,
                            (e) => this.updateEditObj({ thName: e.target.value }),
                          )}
                          <Box mt={1} />
                          {/* 情報 */}
                          {getTextFieldMultiline(
                            `${appLanguages.information[lang]}`,
                            locationObj.thInformation || '',
                            isRequesting,
                            (e) => this.updateEditObj({ thInformation: e.target.value }),
                          )}
                          <Box mt={1} />
                          {/* 説明 */}
                          {getTextFieldMultiline(
                            `${appLanguages.description[lang]}`,
                            locationObj.thDescription || '',
                            isRequesting,
                            (e) => this.updateEditObj({ thDescription: e.target.value }),
                          )}
                          <Box mt={1} />
                          {/* 住所（表示用） */}
                          {getTextFieldMultiline(
                            `${appLanguages.mainAddress[lang]}`,
                            locationObj.thMainAddress || '',
                            isRequesting,
                            (e) => this.updateEditObj({ thMainAddress: e.target.value }),
                          )}
                        </>
                      </TabPanel>

                      <TabPanel value={tabValue} index={6}>
                        {/* ベトナム語 */}
                        <>
                          <Box mt={1} />
                          {/* 名前 */}
                          {getTextField(
                            `${appLanguages.name[lang]} (${appLanguages.require[lang]})`,
                            locationObj.viName || '',
                            isRequesting,
                            (e) => this.updateEditObj({ viName: e.target.value }),
                          )}
                          <Box mt={1} />
                          {/* 情報 */}
                          {getTextFieldMultiline(
                            `${appLanguages.information[lang]}`,
                            locationObj.viInformation || '',
                            isRequesting,
                            (e) => this.updateEditObj({ viInformation: e.target.value }),
                          )}
                          <Box mt={1} />
                          {/* 説明 */}
                          {getTextFieldMultiline(
                            `${appLanguages.description[lang]}`,
                            locationObj.viDescription || '',
                            isRequesting,
                            (e) => this.updateEditObj({ viDescription: e.target.value }),
                          )}
                          <Box mt={1} />
                          {/* 住所（表示用） */}
                          {getTextFieldMultiline(
                            `${appLanguages.mainAddress[lang]}`,
                            locationObj.viMainAddress || '',
                            isRequesting,
                            (e) => this.updateEditObj({ viMainAddress: e.target.value }),
                          )}
                        </>
                      </TabPanel>
                    </Paper>
                  </>
                )}

                <Box mt={2} />
                {/* 位置情報 */}
                {this.getSetCurrentLocationView()}

                {locationObj.category === ECategoryType.DISTRIBUTOR &&
                (userCategory == 0 || userCategory == 1) ? (
                  <>
                    <Box mt={3} />
                    <Autocomplete
                      options={options}
                      getOptionLabel={(option) => option.label}
                      value={options.find((option) => option.value === this.state.qualityDuration)}
                      onChange={(event, newValue) => {
                        this.handleChangeDuration(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={appLanguages.qualityAlertDuration[lang]}
                          variant='outlined'
                        />
                      )}
                      disableClearable={true}
                    />
                    <Box mt={3} />
                  </>
                ) : (
                  ''
                )}

                {isRestaurantLocation && (
                  <>
                    <Box mt={1} />
                    {/* webサイト */}
                    {getTextField(
                      `${i18n.website[lang]}`,
                      locationObj.linkAddress || '',
                      isRequesting || false,
                      (e) => this.updateEditObj({ linkAddress: e.target.value }),
                    )}

                    <Box mt={1} />
                    {/* サブカテゴリ（レストランを選択した場合のみ） */}
                    {getAutocomplete(
                      `${appLanguages.locationBusinessType[lang]} (${appLanguages.require[lang]})`,
                      { ...subCategoryValue },
                      [...subCategories],
                      isRequesting || false,
                      (e, v) => this.handleChangeSubCategory(v ? v.id : 0),
                    )}

                    <Box mt={1} />
                    {/* 料理種別 */}
                    {getAutocomplete(
                      `${i18n.cuisineType[lang]}`,
                      { ...cuisineTypeValue },
                      [...cuisineTypes],
                      isRequesting || false,
                      (e, v) => this.handleChangeCuisineType(v ? v.id : 0),
                    )}
                    <Box mt={1} />
                    {/* 価格帯 */}
                    {getAutocomplete(
                      `${i18n.priceType[lang]}`,
                      { ...priceTypesValue },
                      [...priceTypes],
                      isRequesting || false,
                      (e, v) => this.handleChangePriceType(v ? v.id : 0),
                    )}
                    <Box mt={1} />
                    {/* 席数 */}
                    {getNumericTextField(
                      `${i18n.chairCount[lang]}`,
                      locationObj.chairCount || '',
                      isRequesting || false,
                      (e) => this.updateEditObj({ chairCount: e.target.value }, 'numeric'),
                    )}
                    <Box mt={1} />
                    {/* 受賞歴・コメント */}
                    {getTextField(
                      `${i18n.awardsComments[lang]}`,
                      locationObj.awardsComment || '',
                      isRequesting || false,
                      (e) => this.updateEditObj({ awardsComment: e.target.value }),
                    )}
                    <Box mt={3} />

                    {userCategory == 0 || userCategory == 1 ? (
                      <Autocomplete
                        options={options}
                        getOptionLabel={(option) => option.label}
                        value={options.find(
                          (option) => option.value === this.state.qualityDuration,
                        )}
                        onChange={(event, newValue) => {
                          this.handleChangeDuration(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={appLanguages.qualityAlertDuration[lang]}
                            variant='outlined'
                          />
                        )}
                        disableClearable={true}
                      />
                    ) : (
                      ''
                    )}

                    <Box mt={3} />
                  </>
                )}

                {/* TODO: 現在使用予定がないため、非表示とする */}
                {/* <Hidden xsUp>
                  <Box mt={1} />
                  {getAutocomplete(
                    `${appLanguages.supplier[lang]}`,
                    supplierValue,
                    suppliers,
                    isRequesting,
                    (e, v) => this.updateEditObj({ supplierId: v ? v.id : 0 }),
                  )}
                </Hidden> */}
                {isRestaurantLocation && (
                  <Paper elevation={3}>
                    <Box mt={1} />
                    <Typography variant='subtitle1' color='textPrimary'>
                      {appLanguages.storageServiceInfo[lang]}
                    </Typography>
                    {isRequesting || this.getRestaurantImageTabs(locationObj.id)}
                  </Paper>
                )}
              </Box>
            </TabPanel>
            <TabPanel value={mainTabValue} index={1}>
              {this.getUploadCsvView()}
            </TabPanel>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Grid container justify='flex-end'>
            <Box ml={1} />
            {getOutlinedButton(`${appLanguages.cancel[lang]}`, isRequesting, () =>
              closeLocationEditDialog(),
            )}
            {isUserBreweryOrAdmin(servers?.user) &&
              locationObj.category === ECategoryType.RESTAURANT &&
              locationObj.status === ERestaurantStatus.REVIEWING && <Box ml={1} />}
            {isUserBreweryOrAdmin(servers?.user) && rejectButton}
            {rejectDialog()}
            <Box ml={1} />
            {isUserBreweryOrAdmin(servers?.user) && deactivateButton}
            {deactivateDialog()}
            {isUserBreweryOrAdmin(servers?.user) && activateButton}
            {isUserBreweryOrAdmin(servers?.user) &&
              locationObj.category === ECategoryType.RESTAURANT &&
              locationObj.status === ERestaurantStatus.REVIEWING && <Box ml={1} />}
            {isUserBreweryOrAdmin(servers?.user) && activateOnlyButton}
            {apps.isOpenUserEditDialog ? (
              <UserEditDialog
                tempRegistrationEnabled={false}
                isFirstCreate={true}
                locationObj={locationObj}
              />
            ) : (
              ''
            )}
            <Box ml={1} />
            {isUserBreweryOrAdmin(servers?.user) &&
              locationObj.status !== ERestaurantStatus.PENDING &&
              getOutlinedButton(
                `${id ? appLanguages.update[lang] : appLanguages.register[lang]}`,
                isRequesting || (mainTabValue === 1 && !csvFileText),
                () => this.handleClickRegister(),
              )}
            {!isUserBreweryOrAdmin(servers?.user) &&
              getOutlinedButton(
                `${id ? appLanguages.update[lang] : appLanguages.register[lang]}`,
                isRequesting || (mainTabValue === 1 && !csvFileText),
                () => this.handleClickRegister(),
              )}
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }

  protected getRestaurantImageTabs(restId: number) {
    const { restImageTabValue } = this.state;
    const {
      apps: { currentLanguage: lang },
      servers: {
        editLocationImages: images,
        user: { uid: userUid },
      },
    } = this.props;
    return (
      <>
        <Tabs
          value={restImageTabValue}
          variant='scrollable'
          scrollButtons='auto'
          onChange={(e, v) => this.setState({ restImageTabValue: v })}
        >
          <Tab label={`[${i18n.image[lang]}] ${i18n.refrigerator[lang]}`} />
          <Tab label={`[${i18n.image[lang]}] ${i18n.tempPanel[lang]}`} />
          <Tab label={`[${i18n.image[lang]}] ${i18n.restaurantExterior[lang]}`} />
          <Tab label={`[${i18n.image[lang]}] ${i18n.restaurantInterior[lang]}`} />
          <Tab label={`[${i18n.image[lang]}] ${i18n.glassware[lang]}`} />
          <Tab label={`[${i18n.image[lang]}] ${i18n.drinkMenuSample[lang]}`} />
        </Tabs>
        <TabPanel value={restImageTabValue} index={0}>
          <RestaurantImageSlider
            lang={lang}
            restId={restId}
            userUid={userUid}
            type={ERestaurantImageType.REFRIGERATOR}
            images={
              images && images.filter((image) => image.name === ERestaurantImageType.REFRIGERATOR)
            }
            onImageChange={(imageUrls, deletedImages) => {
              if (deletedImages) {
                this.handleOnImageDeleted(deletedImages, ERestaurantImageType.REFRIGERATOR);
              }
              this.handleOnImageChange(imageUrls, ERestaurantImageType.REFRIGERATOR);
            }}
          />
        </TabPanel>
        <TabPanel value={restImageTabValue} index={1}>
          <RestaurantImageSlider
            lang={lang}
            restId={restId}
            userUid={userUid}
            type={ERestaurantImageType.TEMP_PANEL}
            images={
              images && images.filter((image) => image.name === ERestaurantImageType.TEMP_PANEL)
            }
            onImageChange={(imageUrls, deletedImages) => {
              if (deletedImages) {
                this.handleOnImageDeleted(deletedImages, ERestaurantImageType.TEMP_PANEL);
              }
              this.handleOnImageChange(imageUrls, ERestaurantImageType.TEMP_PANEL);
            }}
          />
        </TabPanel>
        <TabPanel value={restImageTabValue} index={2}>
          <RestaurantImageSlider
            lang={lang}
            restId={restId}
            userUid={userUid}
            type={ERestaurantImageType.RESTAURANT_EXTERIOR}
            images={
              images &&
              images.filter((image) => image.name === ERestaurantImageType.RESTAURANT_EXTERIOR)
            }
            onImageChange={(imageUrls, deletedImages) => {
              if (deletedImages) {
                this.handleOnImageDeleted(deletedImages, ERestaurantImageType.RESTAURANT_EXTERIOR);
              }
              this.handleOnImageChange(imageUrls, ERestaurantImageType.RESTAURANT_EXTERIOR);
            }}
          />
        </TabPanel>
        <TabPanel value={restImageTabValue} index={3}>
          <RestaurantImageSlider
            lang={lang}
            restId={restId}
            userUid={userUid}
            type={ERestaurantImageType.RESTAURANT_INTERIOR}
            images={
              images &&
              images.filter((image) => image.name === ERestaurantImageType.RESTAURANT_INTERIOR)
            }
            onImageChange={(imageUrls, deletedImages) => {
              if (deletedImages) {
                this.handleOnImageDeleted(deletedImages, ERestaurantImageType.RESTAURANT_INTERIOR);
              }
              this.handleOnImageChange(imageUrls, ERestaurantImageType.RESTAURANT_INTERIOR);
            }}
          />
        </TabPanel>
        <TabPanel value={restImageTabValue} index={4}>
          <RestaurantImageSlider
            lang={lang}
            restId={restId}
            userUid={userUid}
            type={ERestaurantImageType.GLASSWARE}
            images={
              images && images.filter((image) => image.name === ERestaurantImageType.GLASSWARE)
            }
            onImageChange={(imageUrls, deletedImages) => {
              if (deletedImages) {
                this.handleOnImageDeleted(deletedImages, ERestaurantImageType.GLASSWARE);
              }
              this.handleOnImageChange(imageUrls, ERestaurantImageType.GLASSWARE);
            }}
          />
        </TabPanel>
        <TabPanel value={restImageTabValue} index={5}>
          <RestaurantImageSlider
            lang={lang}
            restId={restId}
            userUid={userUid}
            type={ERestaurantImageType.DRINK_MENU_SAMPLE}
            images={
              images &&
              images.filter((image) => image.name === ERestaurantImageType.DRINK_MENU_SAMPLE)
            }
            onImageChange={(imageUrls, deletedImages) => {
              if (deletedImages) {
                this.handleOnImageDeleted(deletedImages, ERestaurantImageType.DRINK_MENU_SAMPLE);
              }
              this.handleOnImageChange(imageUrls, ERestaurantImageType.DRINK_MENU_SAMPLE);
            }}
          />
        </TabPanel>
      </>
    );
  }

  // Sreenath11Nov21 - Location image delete
  protected handleOnImageDeleted(deletedImages: string[], type: ERestaurantImageType) {
    const { apps, deleteEditLocationImages } = this.props;
    const locationId = apps?.editLocation?.id;
    deleteEditLocationImages(
      deletedImages.map((image) => ({
        locationId,
        name: type,
        id: image['id'],
        url: image['url'],
      })),
    );
  }

  protected handleOnImageChange(imagesUrls: string[], type: ERestaurantImageType) {
    const { apps, setEditLocationImages } = this.props;
    const locationId = apps?.editLocation?.id;
    if (!isOk(imagesUrls)) {
      return;
    }
    setEditLocationImages(
      imagesUrls.map((imageUrl) => ({ locationId, name: type, url: imageUrl })),
    );
  }

  protected getSetCurrentLocationView(): JSX.Element {
    const { registering, mapMode } = this.state;
    const { classes, apps, servers } = this.props;
    const { googleMapApiKey } = userEnv;
    const lang = apps.currentLanguage;
    const obj = this.getLocationObject();
    const mapLat = obj.latitude;
    const mapLng = obj.longitude;
    const isRequesting = servers.isGetRequesting || servers.isRequesting || registering;

    // Countries
    // const countries = Object.entries(countryMap[lang]).map(([k, v]) => ({ id: k, name: v }));
    // const countryValue = { id: obj.country, name: countryMap[lang][obj.country] };
    let countryValue = null;
    const countries = _countries.map((o) => {
      const v = { id: o.alpha2.toUpperCase(), name: `${o[lang]} [${o.alpha2.toUpperCase()}]` };
      if (v.id === obj.country) countryValue = { ...v };
      return v;
    });

    return (
      <Paper elevation={3} className={classes.paper}>
        <Grid container>
          <Grid item>
            <Typography variant='subtitle1' color='textPrimary'>
              {appLanguages.locationInfo[lang]}
            </Typography>
          </Grid>
        </Grid>
        <Box mt={1} />
        {getAutocomplete(
          `${appLanguages.country[lang]} (${appLanguages.require[lang]})`,
          countryValue,
          countries,
          isRequesting,
          (e, v) =>
            this.updateEditObj({ country: v ? v.id : '' }, 'object', (param) =>
              this.toggleGoogleBaiduMap(param),
            ),
        )}
        <Box mt={1} />

        {/* Google map */}

        {(mapMode === 'google-maps' || mapMode === 'baidu') && (
          <>
            <GoogleMapSuggest
              label={`${appLanguages.mapAddress[lang]} (${appLanguages.require[lang]})`}
              initialValue={obj.mapAddress}
              language={lang}
              region={obj.country}
              onSuggestionSelected={(suggestion) => {
                const { description, lat, lng } = suggestion;
                setTimeout(() => {
                  this.updateEditObj({ mapAddress: description });
                  this.updateEditObj({ latitude: lat });
                  this.updateEditObj({ longitude: lng });
                }, 100);
              }}
            />
            <GoogleMapView apiKey={googleMapApiKey} lat={mapLat} lng={mapLng} zoom={15} />
          </>
        )}

        {/* Baidu map */}

        {/* {mapMode === 'baidu' && (

          <MapViewBaidu
            isMapClickable
            showAutoComplete
            addressValue={obj.mapAddress}
            coords={{ lat: mapLat, lng: mapLng }}
            onAddressChange={(baiduMapLocation) => this.handleBaiduMapChange(baiduMapLocation)}
            identifier='location-master'
          />
        )} */}

        <Typography variant='subtitle2' className='bootstrap4-color-secondary'>
          {`latitude: ${mapLat}, longitude: ${mapLng}`}
        </Typography>

        {/* 許容範囲 */}
        <Box mt={3} />
        <Typography color='textSecondary'>
          {`${appLanguages.allowableRange2[lang]}: ${obj.range}m`}
        </Typography>
        <Slider
          value={obj.range}
          onChange={(e, v) => this.updateEditObj({ range: v })}
          valueLabelDisplay='auto'
          step={100}
          marks
          min={100}
          max={3000}
        />
      </Paper>
    );
  }
  // protected getAddressForm(): JSX.Element {
  //   const { registering } = this.state;
  //   const { apps, servers } = this.props;
  //   const locationObj: Partial<ILocation> = this.getLocationObject();
  //   const lang = apps.currentLanguage;
  //   const isRequesting = servers.isGetRequesting || servers.isRequesting || registering;
  //   let countryValue = null;
  //   const countries = _countries.map((o) => {
  //     const v = { id: o.alpha2.toUpperCase(), name: `${o[lang]} [${o.alpha2.toUpperCase()}]` };
  //     if (v.id === locationObj.country) countryValue = { ...v };
  //     return v;
  //   });

  //   return (
  //     <>
  //       {getAutocomplete(
  //         `${appLanguages.country[lang]} (${appLanguages.require[lang]})`,
  //         countryValue,
  //         countries,
  //         isRequesting,
  //         (e, v) =>
  //           this.updateEditObj({ country: v ? v.id : '' }, 'object', (param) =>
  //             this.toggleGoogleBaiduMap(param),
  //           ),
  //       )}
  //       {getTextField(
  //         `${appLanguages.addrState[lang]} (${appLanguages.require[lang]})`,
  //         locationObj.addrState || '',
  //         isRequesting || false,
  //         (e) => {
  //           this.updateEditObj({ addrState: e.target.value });
  //         },
  //       )}
  //       {getTextField(
  //         `${appLanguages.addrCity[lang]} (${appLanguages.require[lang]})`,
  //         locationObj.addrCity || '',
  //         isRequesting || false,
  //         (e) => this.updateEditObj({ addrCity: e.target.value }),
  //       )}
  //       {getTextField(
  //         `${appLanguages.addrBlock[lang]} (${appLanguages.require[lang]})`,
  //         locationObj.addrBlock || '',
  //         isRequesting || false,
  //         (e) => this.updateEditObj({ addrBlock: e.target.value }),
  //       )}
  //       {getTextField(
  //         `${appLanguages.addrBuilding[lang]}`,
  //         locationObj.addrBuilding || '',
  //         isRequesting || false,
  //         (e) => this.updateEditObj({ addrBuilding: e.target.value }),
  //       )}
  //       {/* 住所 [表示用] */}
  //       {getTextFieldMultiline(
  //         `${appLanguages.mainAddress[lang]} (${appLanguages.require[lang]})`,
  //         locationObj.mainAddress ||
  //           locationObj.addrState +
  //             locationObj.addrCity +
  //             locationObj.addrBlock +
  //             locationObj.addrBuilding ||
  //           '',
  //         true,
  //         (e) => {
  //           // this.updateEditObj({
  //           //   mapAddress: locationObj.addrState + locationObj.addrCity + locationObj.addrBlock,
  //           // });
  //           // this.updateEditObj({ mainAddress: e.target.value });
  //         },
  //       )}
  //       <Box mt={1} />
  //       {getOutlinedButton(appLanguages.applyToMap[lang], isRequesting, () => {
  //         this.updateEditObj({
  //           mapAddress: locationObj.addrState + locationObj.addrCity + locationObj.addrBlock,
  //           mainAddress:
  //             locationObj.addrState +
  //             locationObj.addrCity +
  //             locationObj.addrBlock +
  //             locationObj.addrBuilding,
  //         });
  //         this.setState({
  //           mapAddr: locationObj.addrState + locationObj.addrCity + locationObj.addrBlock,
  //         });
  //         if (this.inputRef.current) {
  //           setTimeout(() => {
  //             this.inputRef.current.focus();
  //           }, 500);
  //         }
  //       })}
  //     </>
  //   );
  // }

  protected handleBaiduMapChange(baiduMapLocation: BaiduMapLocation) {
    if (!isOk(baiduMapLocation)) return;
    this.updateEditObj({ mapAddress: baiduMapLocation.addressValue });
    this.updateEditObj({ latitude: baiduMapLocation.coords.lat });
    this.updateEditObj({ longitude: baiduMapLocation.coords.lng });
  }

  protected getUploadCsvView(): JSX.Element {
    const {
      csvFileText,
      csvFileObjects,
      csvUpCount,
      warningMessages,
      infoMessages,
      registering,
    } = this.state;
    const { classes, apps, servers } = this.props;
    const lang = apps.currentLanguage;

    const isRequesting = servers.isGetRequesting || servers.isRequesting || registering;

    return (
      <Paper elevation={0}>
        <Grid container justify='center' alignItems='flex-start' alignContent='flex-start'>
          <Grid item xs={12}>
            <Box mt={1} />
          </Grid>
          <Grid item>
            <Typography variant='caption'>{appLanguages.csvFileFormatPrefix[lang]}</Typography>
            <Button
              color='primary'
              onClick={() => exportToCSV('locations_format_', [this.formatData()])}
            >
              {appLanguages.here[lang]}
            </Button>
            <Typography variant='caption'>{appLanguages.downloadSuffix[lang]}</Typography>
          </Grid>
          <Grid item>
            {getCSVFileUploadButton(
              `locations-csv-file${csvUpCount}`,
              appLanguages.csvUpload[lang],
              classes.fileInput,
              classes.fileButton,
              isRequesting,
              (e) => this.handleChangeCsvFiles(e),
            )}
            {csvFileObjects && csvFileText ? (
              getFileDeleteButton(
                appLanguages.reset[lang],
                classes.fileButton,
                isRequesting,
                () => {
                  this.setState({ csvFileText: null, csvFileObjects: null });
                  this.clearInfoMessages();
                  this.clearWarningMessages();
                },
              )
            ) : (
              <Box />
            )}
          </Grid>
          <Grid item xs={12}>
            {csvFileObjects && csvFileText ? (
              getTextFieldMultiline2(
                appLanguages.csvFileContents[lang],
                csvFileText,
                true,
                () => null,
              )
            ) : (
              <Box />
            )}
          </Grid>
          <Grid item xs={12}>
            {warningMessages.map((message) => (
              <Alert key={hash(message)} severity='warning'>
                {message}
              </Alert>
            ))}
            {infoMessages.map((message) => (
              <Alert key={hash(message)} severity='success'>
                {message}
              </Alert>
            ))}
          </Grid>
        </Grid>
      </Paper>
    );
  }

  protected getLocationObject(): Partial<ILocation> {
    const locationObj = this.props?.apps?.editLocation;
    const userCategory = getUserCategory(this.props.servers.user);
    return {
      id: locationObj?.id || null,
      customerCode: (locationObj && locationObj.customerCode) || '',
      category:
        (locationObj && Number(locationObj.category)) ||
        (userCategory === ECategoryType.DISTRIBUTOR && ECategoryType.RESTAURANT) ||
        0,
      subCategory: (locationObj && Number(locationObj.subCategory)) || 0,
      addrState: (locationObj && locationObj.addrState) || '',
      addrCity: (locationObj && locationObj.addrCity) || '',
      addrBlock: (locationObj && locationObj.addrBlock) || '',
      addrStreetNum: (locationObj && locationObj.addrStreetNum) || '',
      addrBuilding: (locationObj && locationObj.addrBuilding) || '',
      addrRoom: (locationObj && locationObj.addrRoom) || '',
      mapAddress: (locationObj && locationObj.mapAddress) || '',
      country: (locationObj && locationObj.country) || '',
      latitude: (locationObj && locationObj.latitude) || TOKYO_COORDINATES.lat, // デフォルトは東京の緯度
      longitude: (locationObj && locationObj.longitude) || TOKYO_COORDINATES.lng, // デフォルトは東京の経度
      range: (locationObj && locationObj.range) || 200,
      supplierId: (locationObj && locationObj.supplierId) || null,
      email: (locationObj && locationObj.email) || null,
      //supplier: (locationObj && locationObj.supplier) || null,
      // 日本語
      name: (locationObj && locationObj.name) || '',
      mainAddress: (locationObj && locationObj.mainAddress) || '',
      information: (locationObj && locationObj.information) || '',
      description: (locationObj && locationObj.description) || '',
      // 英語
      enName: (locationObj && locationObj.enName) || '',
      enMainAddress: (locationObj && locationObj.enMainAddress) || '',
      enInformation: (locationObj && locationObj.enInformation) || '',
      enDescription: (locationObj && locationObj.enDescription) || '',
      // 中国
      cnName: (locationObj && locationObj.cnName) || '',
      cnMainAddress: (locationObj && locationObj.cnMainAddress) || '',
      cnInformation: (locationObj && locationObj.cnInformation) || '',
      cnDescription: (locationObj && locationObj.cnDescription) || '',
      // 香港
      hkName: (locationObj && locationObj.hkName) || '',
      hkMainAddress: (locationObj && locationObj.hkMainAddress) || '',
      hkInformation: (locationObj && locationObj.hkInformation) || '',
      hkDescription: (locationObj && locationObj.hkDescription) || '',
      // 韓国
      krName: (locationObj && locationObj.krName) || '',
      krMainAddress: (locationObj && locationObj.krMainAddress) || '',
      krInformation: (locationObj && locationObj.krInformation) || '',
      krDescription: (locationObj && locationObj.krDescription) || '',
      // タイ
      thName: (locationObj && locationObj.thName) || '',
      thMainAddress: (locationObj && locationObj.thMainAddress) || '',
      thInformation: (locationObj && locationObj.thInformation) || '',
      thDescription: (locationObj && locationObj.thDescription) || '',
      // ベトナム
      viName: (locationObj && locationObj.viName) || '',
      viMainAddress: (locationObj && locationObj.viMainAddress) || '',
      viInformation: (locationObj && locationObj.viInformation) || '',
      viDescription: (locationObj && locationObj.viDescription) || '',
      // Restaurant Fields
      cuisineType: (locationObj && Number(locationObj.cuisineType)) || 0,
      awardsComment: (locationObj && locationObj.awardsComment) || '',
      linkAddress: (locationObj && locationObj.linkAddress) || '',
      tel: (locationObj && locationObj.tel) || '',
      chairCount: (locationObj && locationObj.chairCount) || 0,
      creatorId: (locationObj && locationObj.creatorId) || null,
      status: (locationObj && locationObj.status) || null,
      priceType: (locationObj && Number(locationObj.priceType)) || 0,
    };
  }

  protected setCandidatePublicIds() {
    const obj = this.getLocationObject();
    const { category, subCategory, country } = obj;
    const request = functions.httpsCallable('getCandidateLocationPublicIds');
    request({ category, subCategory, country })
      .then((result) => {
        this.setState({ candidatePublicIds: result.data.objects });
      })
      .catch((error) => window.console.error('Failed setCandidatePublicIds. ', error));
  }

  protected updateEditObj(
    updateObj,
    type: 'text' | 'numeric' | 'alphanumeric' | 'object' = 'text',
    callBackFunction?: (newVal?: any) => void,
  ) {
    const { setEditLocation } = this.props;
    const key = Object?.keys(updateObj)[0];
    switch (type) {
      case 'numeric':
        if (isNaN(Number(updateObj[key]))) return;
        if (Number(updateObj[key]) < 0) return;
        break;
      case 'alphanumeric':
        updateObj[key] = replaceNonAlphaNumeric(String(updateObj[key]));
        break;
    }

    setEditLocation({ ...this.getLocationObject(), ...updateObj });

    if (callBackFunction) {
      callBackFunction(updateObj);
    }
  }

  protected handleChangeCategory(v) {
    this.updateEditObj({ category: v });
    // setTimeout(() => this.setCandidatePublicIds(), 10);
    setTimeout(() => {
      // TODO: 現在、未使用
      const { getSuppliers } = this.props;
      getSuppliers(DefaultQuery, v && v > 1 && Number(v));
      this.updateEditObj({ supplierId: 0 });
    }, 1000);
  }

  protected handleChangeSubCategory(v) {
    this.updateEditObj({ subCategory: v });
    // setTimeout(() => this.setCandidatePublicIds(), 10);
  }

  protected handleChangeCuisineType(v) {
    this.updateEditObj({ cuisineType: v });
    // setTimeout(() => this.setCandidatePublicIds(), 10);
  }

  protected handleChangePriceType(v) {
    this.updateEditObj({ priceType: v });
    // setTimeout(() => this.setCandidatePublicIds(), 10);
  }

  protected toggleGoogleBaiduMap(countryObj: { country: string }) {
    const { mapMode } = this.state;
    if (BAIDU_ONLY_COUNTRIES.includes(countryObj?.country) && mapMode === 'google-maps') {
      this.setState({ mapMode: 'baidu' });
    } else if (!BAIDU_ONLY_COUNTRIES.includes(countryObj?.country) && mapMode === 'baidu') {
      this.setState({ mapMode: 'google-maps' });
    }
  }

  protected handleChangeCsvFiles(e) {
    const {
      openSnackbarMessage,
      apps: { currentLanguage: lang },
    } = this.props;
    const { files } = e.target;
    if (!files.length) return;
    const csvFile = files[0];
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.onerror = () => {
      openSnackbarMessage('warning', appLanguages.fileReadFailed[lang]);
    };
    reader.onload = () => {
      const text = reader?.result?.toString() || '';
      const result = csvSync(text);
      if (!result || !result.length) {
        openSnackbarMessage('warning', appLanguages.csvFormatError[lang]);
      } else {
        this.setState({
          csvFileText: text,
          csvFileObjects: result,
        });
      }
    };
    reader.readAsText(csvFile);
  }

  protected handleActivation(isApproveOnly) {
    const locationObj: Partial<ILocation> = this.getLocationObject();
    if (!isApproveOnly) {
      const { openUserEditDialog, setEditUser, servers } = this.props;
      openUserEditDialog(locationObj);
      const userObj = {
        locationId: locationObj.id,
        locationCategory: 3,
        creatorId: servers.user.id,
        name: locationObj.name,
        country: locationObj.country,
        withActivation: true,
      };
      setEditUser(userObj);
    } else {
      const { activateLocation } = this.props;
      const locationObj: Partial<ILocation> = this.getLocationObject();
      console.log('here');
      activateLocation({ ...locationObj }, true, true);
      this.handleClickRegister();
    }
  }

  protected handleClickRegister() {
    const {
      registerLocation,
      apps: { currentLanguage: lang },
      servers: { user },
    } = this.props;
    const { mainTabValue, csvFileObjects } = this.state;

    if (mainTabValue === 0) {
      // Single Registration
      const locationObj: Partial<ILocation> = this.getLocationObject();
      locationObj.customerCode = locationObj?.customerCode?.trim().replace(/ /g, '.');
      // Set creatorId ONLY on NEW Registration
      if (!locationObj?.id) {
        locationObj.creatorId = user?.id;
      }
      if (locationObj.category == 2 || locationObj.category == 3) {
        locationObj.quality_alert_duration = this.state.qualityDuration;
      }
      registerLocation({ ...locationObj }, true);
    } else {
      // CSV Registration
      this.clearInfoMessages();
      this.clearWarningMessages();
      this.setState({ registering: true });
      setTimeout(() => this.setState({ registering: false }), 3000);
      let index = 0;
      for (const csvFileObj of csvFileObjects) {
        index += 1;
        if (index > 20) {
          this.addWarningMessages(appLanguages.errorServerLoad[lang]);
          break;
        }
        if (!csvFileObj || csvFileObj.length < Object.keys(this.formatData()).length) {
          this.addWarningMessages(`${index}${appLanguages.errorRowNotEnoughItems[lang]}`);
          continue;
        }
        const locationObj: Partial<ILocation> = {
          customerCode: csvFileObj[0],
          name: csvFileObj[1],
          category: 0, // TODO: csvFileObj[2],
          subCategory: 0, // TODO: csvFileObj[3],
          country: csvFileObj[5] || 'JP',
          latitude: parseFloat(csvFileObj[6]) || 0,
          longitude: parseFloat(csvFileObj[7]) || 0,
          allowableRange: parseInt(csvFileObj[8]) || 200,
          mapAddress: csvFileObj[9],
          // 日本語
          mainAddress: csvFileObj[10],
          information: csvFileObj[11],
          description: csvFileObj[12],
          // 英語
          enName: csvFileObj[13],
          enMainAddress: csvFileObj[14],
          enInformation: csvFileObj[15],
          enDescription: csvFileObj[16],
          // 中国語
          cnName: csvFileObj[17],
          cnMainAddress: csvFileObj[18],
          cnInformation: csvFileObj[19],
          cnDescription: csvFileObj[20],
          // 香港語
          hkName: csvFileObj[21],
          hkMainAddress: csvFileObj[22],
          hkInformation: csvFileObj[23],
          hkDescription: csvFileObj[24],
          // 韓国語
          krName: csvFileObj[25],
          krMainAddress: csvFileObj[26],
          krInformation: csvFileObj[27],
          krDescription: csvFileObj[28],
          // タイ語
          thName: csvFileObj[29],
          thMainAddress: csvFileObj[30],
          thInformation: csvFileObj[31],
          thDescription: csvFileObj[32],
          // ベトナム語
          viName: csvFileObj[33],
          viMainAddress: csvFileObj[34],
          viInformation: csvFileObj[35],
          viDescription: csvFileObj[36],
        };

        if (locationObj.customerCode === '公開ID') continue; // ヘッダーはスキップ
        if (!locationObj.name) {
          this.addWarningMessages(
            `${index}${appLanguages.errorRowIncompleteRequiredFieldsLocation2[lang]}`,
          );
          continue;
        }
        if (!locationObj.mainAddress || !locationObj.mapAddress || !locationObj.country) {
          this.addWarningMessages(
            `${index}${appLanguages.errorRowIncompleteRequiredFieldsLocation1[lang]}`,
          );
          continue;
        }
        const query = {
          ...DefaultQuery,
          limit: 1,
          where: { $or: [{ customerCode: locationObj.customerCode }, { name: locationObj.name }] },
        };
        const targetIndex = index;
        functions
          .httpsCallable('getMasterLocations')(query)
          .then((result) => {
            const { objects } = result.data;
            if (objects && objects.length) {
              const resObj = objects[0];
              if (resObj.customerCode === locationObj.customerCode) {
                this.addWarningMessages(
                  `${targetIndex}${appLanguages.errorRowAlreadyRegistered[lang]}${appLanguages.customerCode[lang]}(${locationObj.customerCode})${appLanguages.desu[lang]}`,
                );
              } else if (resObj.name === locationObj.name) {
                this.addWarningMessages(
                  `${targetIndex}${appLanguages.errorRowAlreadyRegistered[lang]}${appLanguages.brand[lang]}(${locationObj.name})${appLanguages.desu[lang]}`,
                );
              }
            } else {
              // 新規登録のみ対応
              const lat = locationObj.latitude;
              const lng = locationObj.longitude;
              if (!lat || !lng) {
                // 緯度経度が未指定の場合は、Google Map APIから取得
                const params = {
                  input: locationObj.mapAddress,
                  language: lang,
                  region: locationObj.country,
                  key: userEnv.googleMapApiKey,
                };
                const request = functions.httpsCallable('getPlaceAutocomplete');
                request(params)
                  .then((result) => {
                    const { objects } = result.data;
                    if (objects.length) {
                      const resObj = objects[0];
                      locationObj.latitude = resObj.lat;
                      locationObj.longitude = resObj.lng;
                      registerLocation(locationObj);
                      this.addInfoMessages(
                        `${targetIndex}${appLanguages.rowBrand[lang]}(${locationObj.customerCode})${appLanguages.addedSuffix[lang]}`,
                      );
                    } else {
                      this.addWarningMessages(
                        `${targetIndex}${appLanguages.errorRowCoordinatesInvalid[lang]}（${appLanguages.locationId[lang]}: ${locationObj.customerCode}, ${appLanguages.baseName[lang]}: ${locationObj.name}`,
                      );
                    }
                  })
                  .catch((error) => console.error(error));
              } else {
                locationObj.latitude = lat;
                locationObj.longitude = lng;
                registerLocation(locationObj);
                this.addInfoMessages(
                  `${targetIndex}${appLanguages.rowBrand[lang]}(${locationObj.customerCode})${appLanguages.addedSuffix[lang]}`,
                );
              }
            }
          });
      }
    }
  }

  protected addWarningMessages(message) {
    if (!message) return;
    const { warningMessages } = this.state;
    warningMessages.push(message);
    this.setState(warningMessages);
  }

  protected addInfoMessages(message) {
    if (!message) return;
    const { infoMessages } = this.state;
    infoMessages.push(message);
    this.setState(infoMessages);
  }

  protected clearWarningMessages() {
    this.setState({ warningMessages: [] });
  }

  protected clearInfoMessages() {
    this.setState({ infoMessages: [] });
  }

  protected formatData() {
    const { apps } = this.props;
    const lang = apps.currentLanguage;
    return {
      [appLanguages.customerCode[lang]]: '',
      [appLanguages.name[lang]]: '',
      [appLanguages.category[lang]]: '',
      [appLanguages.locationBusinessType[lang]]: '',
      [appLanguages.country[lang]]: '',
      [appLanguages.latitude[lang]]: '',
      [appLanguages.longitude[lang]]: '',
      [appLanguages.allowableRange[lang]]: '',
      [appLanguages.mapAddress[lang]]: '',
      // 日本語
      [appLanguages.mainAddress[lang]]: '',
      [appLanguages.information[lang]]: '',
      [appLanguages.description[lang]]: '',
      // 英語
      [appLanguages.enName[lang]]: '',
      [appLanguages.enMainAddress[lang]]: '',
      [appLanguages.enInformation[lang]]: '',
      [appLanguages.enDescription[lang]]: '',
      // 中国語
      [appLanguages.cnName[lang]]: '',
      [appLanguages.cnMainAddress[lang]]: '',
      [appLanguages.cnInformation[lang]]: '',
      [appLanguages.cnDescription[lang]]: '',
      // 香港語
      [appLanguages.hkName[lang]]: '',
      [appLanguages.hkMainAddress[lang]]: '',
      [appLanguages.hkInformation[lang]]: '',
      [appLanguages.hkDescription[lang]]: '',
      // 韓国語
      [appLanguages.krName[lang]]: '',
      [appLanguages.krMainAddress[lang]]: '',
      [appLanguages.krInformation[lang]]: '',
      [appLanguages.krDescription[lang]]: '',
      // タイ語
      [appLanguages.thName[lang]]: '',
      [appLanguages.thMainAddress[lang]]: '',
      [appLanguages.thInformation[lang]]: '',
      [appLanguages.thDescription[lang]]: '',
      // ベトナム語
      [appLanguages.viName[lang]]: '',
      [appLanguages.viMainAddress[lang]]: '',
      [appLanguages.viInformation[lang]]: '',
      [appLanguages.viDescription[lang]]: '',
    };
  }
}
export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  classes: any;
}

export interface IDispatchProps {
  setEditLocation: (locationObj: ILocation) => void;
  setEditUser: (any) => void;
  activateLocation: (locationObj: any, closePopup?: boolean, isOnly?: boolean) => void;
  deactivateLocation: (obj: IDeactivateRequest) => void;
  rejectLocation: (obj: IRejectRequest) => void;
  registerLocation: (locationObj: any, closePopup?: boolean) => void;
  closeLocationEditDialog: () => void;
  getSuppliers: (query: IQuery, categoryId: ECategoryType) => void;
  openSnackbarMessage: (format: string, message: string) => void;
  openUserEditDialog: (any) => void;
  getLocationsImages: (query: IQuery) => void;
  getMasterLocations: (query: IQuery) => void;
  setEditLocationImages: (obj: IGetImages[]) => void;
  deleteEditLocationImages: (obj: any) => void;
}

interface State {
  mainTabValue: number;
  restImageTabValue: number;
  csvFileText: any;
  csvFileObjects: any;
  tabValue: number;
  candidatePublicIds: any;
  csvUpCount: number;
  warningMessages: any;
  infoMessages: any;
  registering: boolean;
  isDeactivateDialogOpen: boolean;
  isRejectDialogOpen: boolean;
  deactivateReason: any;
  rejectReason: any;
  mapMode: 'google-maps' | 'baidu';

  mapAddr: string;

  qualityDuration: any;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps: IDispatchProps = {
  setEditLocation,
  setEditUser,
  closeLocationEditDialog,
  activateLocation,
  deactivateLocation,
  rejectLocation,
  registerLocation,
  getSuppliers,
  getMasterLocations,
  openSnackbarMessage,
  getLocationsImages,
  setEditLocationImages,
  deleteEditLocationImages,
  openUserEditDialog,
};

const myStyles = (theme: Theme): StyleRules => ({
  paper: {
    padding: theme.spacing(1),
  },
  tab: { '& .MuiBox-root': { padding: 12 } },
  fileButton: { margin: theme.spacing(1) },
  fileInput: { display: 'none' },
});

export const LocationEditDialog = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(LocationEditDialogClass);
