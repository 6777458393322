import {
  AppBar,
  Avatar,
  Box,
  Grid,
  Hidden,
  IconButton,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { StyleRules, Theme, withStyles } from '@material-ui/core/styles';
import { Send as SendIcon, Videocam as VideocamIcon } from '@material-ui/icons';
import { AvatarGroup } from '@material-ui/lab';
import { IQuery } from '@models/query';
import { IStateApps } from '@models/state-apps';
import { IStateServers } from '@models/state-servers';
import { openVideoCallDialog } from '@redux/actions/appsActions';
import {
  getChatMessages,
  registerChatMessage,
  setChatMessagesQuery,
} from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import appLanguages from '@utils/app-languages';
import { firestore } from '@utils/firebase';
import * as cliTruncate from 'cli-truncate';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import userEnv from 'userEnv';
import { myStyles as chatUsersStyles } from './ChatUsers';
import VideoCallDialog from './VideoCallDialog';

const TRUNCATE_DEFAULT_VALUE = 20;

class ChatMessagesClass extends React.PureComponent<Props, State> {
  private firestoreUnsubscribeForChatOwner;
  private firestoreUnsubscribeForChatMember;

  public static listenToScroll() {
    const winScroll = window.document.body.scrollTop || window.document.documentElement.scrollTop;
    const height =
      window.document.documentElement.scrollHeight - window.document.documentElement.clientHeight;
    const scrolled = winScroll / height;
    console.log('current position: ', scrolled);
  }

  constructor(props) {
    super(props);
    this.state = {
      message: '',
      isMobile: window.innerWidth < 600,
    };
    this.firestoreUnsubscribeForChatOwner = undefined;
    this.firestoreUnsubscribeForChatMember = undefined;
  }

  componentDidMount() {
    window.addEventListener('scroll', ChatMessagesClass.listenToScroll);

    const { servers, getChatMessages, setChatMessagesQuery } = this.props;
    const { user, chatRoom } = servers;
    if (user && chatRoom) {
      const query = { ...servers.chatMessagesQuery, where: { chatRoomId: chatRoom.id } };
      setChatMessagesQuery(query);
      getChatMessages(query, true);
      // setup firestore
      const commonQuerySnapshot = firestore
        .collection(`chats/room_${chatRoom.id}/messages`)
        .where('chatRoomId', '==', chatRoom.id);
      this.firestoreUnsubscribeForChatOwner = commonQuerySnapshot
        .where('ownerUserId', '==', user.id)
        .onSnapshot((querySnapshot) => {
          querySnapshot.forEach((doc) => this.handleEventGetNewMessage(doc.data()));
        });
      this.firestoreUnsubscribeForChatMember = commonQuerySnapshot
        .where('memberUserId', '==', user.id)
        .onSnapshot((querySnapshot) => {
          querySnapshot.forEach((doc) => this.handleEventGetNewMessage(doc.data()));
        });
    }

    window.addEventListener('resize', () => this.setState({ isMobile: window.innerWidth < 600 }));
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', ChatMessagesClass.listenToScroll);

    if (this.firestoreUnsubscribeForChatOwner) this.firestoreUnsubscribeForChatOwner();
    if (this.firestoreUnsubscribeForChatMember) this.firestoreUnsubscribeForChatMember();
  }

  handleEventGetNewMessage(newMessage) {
    const { servers, getChatMessages } = this.props;
    const { user, chatMessagesQuery } = servers;

    const before15s = new Date().getTime() - 15000; // 15s * 1000ms
    const { createdAtTimestamp, userId } = newMessage;
    if (userId && userId === user.id) return;
    if (!createdAtTimestamp || createdAtTimestamp < before15s) return;
    getChatMessages(chatMessagesQuery, true);
  }

  handleClickSendMessageButton() {
    const { servers, registerChatMessage } = this.props;
    const { message } = this.state;
    const { user, chatRoom } = servers;
    if (!message || !user || !chatRoom) return;
    registerChatMessage({ text: message, userId: user.id, chatRoomId: chatRoom.id });
    setTimeout(() => this.setState({ message: '' }), 100);
  }

  render() {
    const { apps, servers, classes, openVideoCallDialog } = this.props;
    const { message, isMobile } = this.state;
    const lang = apps.currentLanguage;
    const { categoryMapLocation } = userEnv;
    const myself = servers.user;
    const { chatRoom } = servers;
    const newMessages = [...servers.chatMessages];

    const { ownerUser, memberUser } = chatRoom;
    const isMyChat =
      (ownerUser && ownerUser.id === myself?.id) || (memberUser && memberUser.id === myself?.id);
    const otherUser = ownerUser && ownerUser.id !== myself?.id ? ownerUser : memberUser;

    const selectedUserView = (
      <AppBar position='fixed' className={classes.topAppBar}>
        <Toolbar>
          <div className={classes.topAppBarMinHeight} />
          <div className={classes.topGrow} />
          <Paper className={classes.drawerPaper} />
          <Grid container wrap='wrap-reverse' spacing={2} alignItems='center'>
            <>
              <Grid item>
                {isMyChat ? (
                  <Avatar src={otherUser.imageUrl} />
                ) : (
                  <AvatarGroup>
                    <Avatar src={ownerUser.imageUrl} />
                    <Avatar src={memberUser.imageUrl} />
                  </AvatarGroup>
                )}
              </Grid>
              <Grid item xs>
                <Typography variant='body1' color='textPrimary'>
                  {isMyChat ? (
                    <>
                      <b>{`${cliTruncate(otherUser.name, TRUNCATE_DEFAULT_VALUE)}`}</b>
                      {otherUser.location
                        ? `(${categoryMapLocation[lang][otherUser.location.category]})`
                        : ''}
                    </>
                  ) : (
                    <>
                      <b>{`${cliTruncate(ownerUser.name, TRUNCATE_DEFAULT_VALUE)}`}</b>
                      {ownerUser.location
                        ? `(${categoryMapLocation[lang][ownerUser.location.category]})`
                        : ''}
                      {', '}
                      <b>{`${cliTruncate(memberUser.name, TRUNCATE_DEFAULT_VALUE)}`}</b>
                      {memberUser.location
                        ? `(${categoryMapLocation[lang][memberUser.location.category]})`
                        : ''}
                    </>
                  )}
                </Typography>
              </Grid>
            </>
          </Grid>
        </Toolbar>
      </AppBar>
    );

    const messageListView = newMessages.reverse().map((msg) => {
      const justify = myself?.id === msg.userId ? 'flex-end' : 'flex-start';
      return (
        <Grid container justify={justify} key={msg.id}>
          <Grid item>
            <Paper className={classes.messagePaper}>
              <Grid container wrap='nowrap' spacing={2}>
                {myself?.id !== msg.userId ? (
                  <Grid item>
                    <Avatar src={msg && msg.user ? msg.user.imageUrl : ''} />
                  </Grid>
                ) : (
                  ''
                )}
                <Grid item xs>
                  <Typography variant='body2' color='textPrimary'>
                    {msg.text}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify={justify}>
              <Typography variant='body2' color='textSecondary'>
                {new Date(msg.updatedAt).toLocaleString()}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2} />
          </Grid>
        </Grid>
      );
    });

    const sendMessageView = (
      <AppBar position='fixed' color='default' className={classes.bottomAppBar}>
        <Toolbar>
          <div className={classes.bottomGrow} />
          <Paper className={classes.drawerPaper} />
          <TextField
            color='primary'
            label=''
            placeholder={
              isMobile
                ? appLanguages.enterMessageForMobile[lang]
                : appLanguages.enterMessageForPC[lang]
            }
            fullWidth
            multiline
            rowsMax={2}
            value={message}
            onChange={(e) => this.setState({ message: e.target.value })}
            variant='outlined'
            onKeyPress={(e) => {
              if (e.key === 'Enter' && e.shiftKey) {
                this.handleClickSendMessageButton();
              }
            }}
          />
          <IconButton
            disabled={!ownerUser || !memberUser || !message}
            edge='end'
            onClick={() => this.handleClickSendMessageButton()}
          >
            <SendIcon
              color={ownerUser && memberUser && message ? 'primary' : 'disabled'}
              fontSize='large'
            />
          </IconButton>

          <Hidden xsUp>
            {/* TODO: ビデオ通話機能保留 */}
            <IconButton edge='end' onClick={() => openVideoCallDialog()}>
              <VideocamIcon color='primary' fontSize='large' />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    );

    return (
      <>
        <main className={classes.content}>
          <Toolbar />
          <div className={classes.topAppBarMinHeight} />
          {selectedUserView}
          {messageListView}
          <div className={classes.bottomAppBarMinHeight} />
          {isMyChat ? sendMessageView : ''}
        </main>
        {apps.isOpenVideoCallDialog ? <VideoCallDialog /> : ''}
      </>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  classes: any;
}

export interface IDispatchProps {
  getChatMessages: (query: IQuery, bottomScroll?: boolean) => void;
  registerChatMessage: (params: any) => void;
  setChatMessagesQuery: (query: IQuery) => void;
  openVideoCallDialog: () => void;
}

interface State {
  message: string;
  isMobile: boolean;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps: IDispatchProps = {
  getChatMessages,
  registerChatMessage,
  setChatMessagesQuery,
  openVideoCallDialog,
};

const topAppBarMinHeight = 92;
const bottomAppBarMinHeight = 60;

export const myStyles = (theme: Theme): StyleRules => ({
  ...chatUsersStyles(theme),
  content: { flexGrow: 1, padding: theme.spacing(3) },
  topAppBarMinHeight: {
    minHeight: topAppBarMinHeight,
  },
  topAppBar: {
    top: theme.mixins.toolbar.minHeight,
    bottom: 'auto',
    minHeight: topAppBarMinHeight,
    backgroundColor: '#fff',
  },
  topGrow: { flexGrow: 1 },
  messagePaper: {
    maxWidth: 420,
    minWidth: 200,
    padding: theme.spacing(2),
    whiteSpace: 'pre-line',
  },
  bottomAppBarMinHeight: {
    minHeight: bottomAppBarMinHeight,
  },
  bottomAppBar: {
    top: 'calc(100% - 61px)',
    minHeight: bottomAppBarMinHeight,
    backgroundColor: '#fff',
  },
  bottomGrow: { flexGrow: 1 },
});

export const ChatMessages = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(ChatMessagesClass);
