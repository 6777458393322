export enum ERestaurantProductCuisineType {
  UNKNOWN = 0,
  JAPANESE_SUSHI = 1,
  JAPANESE_TEMPURA = 2,
  JAPANESE_KAISEKI = 3,
  JAPANESE_KAPPO_RYORI = 4,
  JAPANESE_TEPPANYAKI = 5,
  JAPANESE_YAKITORI_KUSHIYAKI_CHICKEN = 6,
  JAPANESE_IZAKAYA_SAKE_BAR = 7,
  JAPANESE_MODERN = 8,
  STEAK_YAKINIKU_BBQ = 9,
  CHINESE_CANTONESE = 10,
  CHINESE_SICHUAN = 11,
  CHINESE_SHANGHAINESE = 12,
  CHINESE_TAIWANESE = 13,
  CHINESE_ZHEJIANG = 14,
  EUROPEAN = 15,
  FRENCH = 16,
  ITALIAN = 17,
  FUSION = 18,
  KOREAN = 19,
  SOUTH_EAST_ASIAN = 20,
  OTHERS = 21,
}
