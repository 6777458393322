import { CircularLoading } from '@container/components/circular-loading';
import { i18n } from '@i18n/lang';
import { Grid } from '@material-ui/core';
import { ERestaurantImageType } from '@models/restaurant-image-type';
import { IStateApps } from '@models/state-apps';
import { IStateServers } from '@models/state-servers';
import { TSupportedLanguages } from '@models/supported-languages';
import { IStoreState, store } from '@redux/reducers';
import { getFileDeleteButton, LANGUAGE_DEFAULT } from '@utils/common';
import { isOk } from '@utils/is-ok';
import { uploadImages } from '@utils/upload-images';
import { TextDisplay } from '@visual/text-display';
import hash from 'object-hash';
import React from 'react';
import Carousel from 'react-material-ui-carousel';
import styles from './style.scss';

export class RestaurantImageSlider extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = {
    lang: LANGUAGE_DEFAULT,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      images: [],
    };
  }

  public render(): JSX.Element {
    const { loading } = this.state;
    if (loading) return <CircularLoading />;
    return (
      <Grid container>
        <div className={styles.restaurantImageSlider}>
          {this.getCarousel()}
          <Grid item>
            <div style={{ marginTop: '10px' }}>{this.getUploadButton()}</div>
          </Grid>
        </div>
      </Grid>
    );
  }

  protected getUploadButton(): JSX.Element {
    const { type, restId, lang } = this.props;
    const objId = `${restId}-${type}`;
    return (
      <label className={styles.label} htmlFor={objId}>
        <input
          accept='image/*'
          multiple
          type='file'
          hidden
          id={objId}
          onChange={(e) => this.handleOnImageChange(e)}
        />
        <span className={styles.uploadButton}>
          <TextDisplay display='inline'>{i18n.uploadImages[lang]}</TextDisplay>
        </span>
      </label>
    );
  }

  protected async handleOnImageChange(e) {
    try {
      this.setState({ loading: true }, () => {});
      const { onImageChange, restId, userUid } = this.props;
      const imageUrls = await uploadImages(e, restId, userUid);
      if (!onImageChange || !isOk(imageUrls)) return;
      this.setState({ loading: false }, () => onImageChange(imageUrls));
    } catch (e) {
      console.log(e);
    }
  }

  protected getCarousel(): JSX.Element {
    const { images } = this.props;
    const state: IStoreState = store.getState();
    const editImages = state?.servers?.editLocationImages;
    if (!isOk(images)) return <React.Fragment />;
    return (
      <Carousel autoPlay={false}>
        {images.map((image, i) => (
          <>
            <div className={styles.imageContainer} key={hash(i)}>
              <img className={styles.image} src={image.url} />
            </div>
            <div>
              {getFileDeleteButton('', '', images.length === 0 ? true : false, () => {
                const { onImageChange } = this.props;
                let newUrls = [];
                let newImages = editImages.filter((item) => {
                  if (image.url !== item.url) {
                    return true;
                  }
                });
                this.setState({ images: newImages });
                onImageChange(newUrls, [image]);
              })}
            </div>
          </>
        ))}
      </Carousel>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  images: { id?: number; name?: string; url: string }[];
  restId: number;
  userUid: string;
  type: ERestaurantImageType;
  lang?: TSupportedLanguages;
  classes: any;
}

interface State {
  loading: boolean;
  images: { id?: number; name?: string; url: string }[];
}

export interface IDispatchProps {
  onImageChange?: (imageUrls: any, isDeleted?: any) => void;
}
