import React, { CSSProperties } from 'react';
import masterStyles from '../.common-styles/master.scss';
import styles from './style.scss';

export class ColoredBackground extends React.Component<Props> {
  public static defaultProps: Partial<Props> = {
    widthType: 'full',
  };

  public componentDidMount() {
    if (this.props.widthType === 'full') {
      document.body.classList.add(masterStyles.coloredBackground);
    }
  }

  public componentWillUnmount() {
    if (this.props.widthType === 'full') {
      document.body.classList.remove(masterStyles.coloredBackground);
    }
  }

  public render(): JSX.Element {
    const { widthType, children, style } = this.props;
    return (
      <div className={`${styles.background} ${styles[widthType]}`} style={style}>
        {children}
      </div>
    );
  }
}

export type Props = IStateProps;

export interface IStateProps {
  widthType?: 'full' | 'fit';
  children?: any;
  style?: CSSProperties;
}
