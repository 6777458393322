import { AlertStatusPill } from '@visual/alert-status-pill';
import { Icon } from '@visual/icon';
import { Logo } from '@visual/logo';
import React from 'react';
import styles from './style.scss';

export class DistributionDetailImage extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { imgUrl, onBrandBottleClick } = this.props;
    const imgObj = !!imgUrl ? (
      <img className={styles.distributionDetailImage} onClick={onBrandBottleClick} src={imgUrl} />
    ) : (
      <Logo type='no-image' />
    );
    return (
      <div className={styles.distributionDetailImageContainer}>
        {this.getAlertStatusPill()}
        {/* {this.getBrandBottle()} */}
        {imgObj}
      </div>
    );
  }

  protected getBrandBottle(): JSX.Element {
    const { onBrandBottleClick } = this.props;
    if (!onBrandBottleClick) return;
    return (
      <div className={styles.brandBottle}>
        <Icon
          type='bottle'
          color='primary'
          size='large'
          isCircular={true}
          onClick={onBrandBottleClick}
        />
      </div>
    );
  }

  protected getAlertStatusPill(): JSX.Element {
    const { alertStatusPill } = this.props;
    if (!alertStatusPill) return;
    return <div className={styles.alertStatusPill}>{alertStatusPill}</div>;
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  imgUrl?: string;
  alertStatusPill?: React.ReactElement<AlertStatusPill>;
}

export interface IDispatchProps {
  onBrandBottleClick?: () => void;
}
