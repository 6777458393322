import { i18n } from '@i18n/lang';
import { ERestaurantStatus } from '@models/restaurant-status-type';
import { TSupportedLanguages } from '@models/supported-languages';
import { LANGUAGE_DEFAULT } from '@utils/common';
import { TColors as TextDisplayColors, TextDisplay } from '@visual/text-display';
import React from 'react';
import styles from './style.scss';

export class RestaurantStatusIcon extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    lang: LANGUAGE_DEFAULT,
  };

  public render(): JSX.Element {
    const { type, lang } = this.props;
    let label;
    let pillStyle;
    let textColor: TextDisplayColors;
    switch (type) {
      case ERestaurantStatus.ACTIVE:
        pillStyle = styles.active;
        label = i18n.active[lang];
        break;
      case ERestaurantStatus.DEACTIVATED:
        pillStyle = styles.deactivated;
        label = i18n.deactivated[lang];
        break;
      case ERestaurantStatus.REVIEWING:
        pillStyle = styles.reviewing;
        label = i18n.reviewing[lang];
        textColor = 'black';
        break;
      case ERestaurantStatus.BLACKLISTED:
        pillStyle = styles.blacklisted;
        label = i18n.blacklisted[lang];
        break;
      default:
        pillStyle = styles.unknown;
        label = i18n.unknown[lang];
        break;
    }
    return (
      <div className={`${styles.restaurantStatusPill} ${pillStyle}`}>
        <TextDisplay weight='bold' color={textColor} size='small'>
          {label}
        </TextDisplay>
      </div>
    );
  }
}

export type Props = IStateProps;

export interface IStateProps {
  type: ERestaurantStatus;
  lang?: TSupportedLanguages;
}
