import { EAlertStatus } from '@models/alert-status-type';

export function getNextStatus(status: EAlertStatus): EAlertStatus {
  if (!status) return;
  switch (status) {
    case EAlertStatus.UNPROCESSED:
    //return EAlertStatus.PROCESSING;
    case EAlertStatus.PROCESSING:
      return EAlertStatus.PROCESSED;
    case EAlertStatus.PROCESSED:
      return EAlertStatus.PROCESSED;
    case EAlertStatus.UNPROCESSABLE:
      return EAlertStatus.UNPROCESSABLE;
  }
}
