import { i18n } from '@i18n/lang';
import { Button, Grid, Hidden, IconButton, Tooltip } from '@material-ui/core';
import { CloudDownload as CloudDownloadIcon, Refresh as RefreshIcon } from '@material-ui/icons';
import { IQuery } from '@models/query';
import { IStateApps } from '@models/state-apps';
import { IStateServers } from '@models/state-servers';
import { openLocationEditDialog } from '@redux/actions/appsActions';
import { getMasterLocations, setLocationsQuery } from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import appLanguages from '@utils/app-languages';
import { exportToCSV } from '@utils/common';
import { functions } from '@utils/firebase';
import { formatString } from '@utils/format-string';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import userEnv from 'userEnv';

export interface State {
  searchOpen: boolean;
  filterName: string;
  filterTimeout: any;
}

class LocationsCustomToolbarClass extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    const { servers } = this.props;
    const { where } = servers.locationsQuery;
    this.state = {
      searchOpen: where.name ? true : false,
      filterName: where.name ? where.name.$like.replace(/%/g, '') : '',
      filterTimeout: undefined,
    };
  }
  setFilter(newWhere) {
    const { servers } = this.props;
    const where = { ...servers.locationsQuery.where, ...newWhere };
    this.updateQuery({ where, offset: 0 });
    this.setState({ filterTimeout: null });
  }

  unsetFilter(key) {
    const { servers } = this.props;
    const { where } = servers.usersQuery;
    if (where[key] != undefined) delete where[key];
    this.setFilter(where);
  }

  clearFilterTimeout() {
    const { filterTimeout } = this.state;
    if (filterTimeout) clearTimeout(filterTimeout);
  }
  updateQuery(newQuery) {
    const { servers, setLocationsQuery, getMasterLocations } = this.props;
    const query = { ...servers.usersQuery, ...newQuery };
    setLocationsQuery(query);
    getMasterLocations(query);
  }

  handleClickEdit() {
    const { openLocationEditDialog } = this.props;
    openLocationEditDialog({ country: 'JP' });
  }

  handleClickReload() {
    const { servers, getMasterLocations } = this.props;
    getMasterLocations(servers.locationsQuery);
  }

  handleClickSearch() {
    this.setState({ searchOpen: !this.state.searchOpen });
  }

  handleChangefilterName = (v) => {
    this.clearFilterTimeout();
    this.setState({
      filterName: v,
      filterTimeout: setTimeout(() => this.setFilter({ name: { $like: `%${v}%` } }), 1000),
    });
  };

  formatData(obj) {
    const { apps } = this.props;
    const { categoryMapLocation, subCategoryMap } = userEnv;
    const lang = apps.currentLanguage;
    return {
      [appLanguages.customerCode[lang]]: obj.customerCode || '',
      [appLanguages.name[lang]]: obj.name || '',
      [appLanguages.category[lang]]: categoryMapLocation[lang][obj.category],
      [appLanguages.locationBusinessType[lang]]: subCategoryMap[lang][obj.subCategory],
      [appLanguages.hiddenSetting[lang]]: obj.hidden ? '有効' : '無効',
      [appLanguages.country[lang]]: obj.country, // 国コードで表示
      [appLanguages.latitude[lang]]: obj.latitude,
      [appLanguages.longitude[lang]]: obj.longitude,
      [appLanguages.allowableRange[lang]]: obj.range,
      [appLanguages.mapAddress[lang]]: obj.mapAddress,
      [appLanguages.email[lang]]: obj.email,
      // 日本語
      [appLanguages.mainAddress[lang]]: obj.mainAddress || '',
      [appLanguages.information[lang]]: obj.information || '',
      [appLanguages.description[lang]]: obj.description || '',
      // 英語
      [appLanguages.enName[lang]]: obj.enName || '',
      [appLanguages.enMainAddress[lang]]: obj.enMainAddress || '',
      [appLanguages.enInformation[lang]]: obj.enInformation || '',
      [appLanguages.enDescription[lang]]: obj.enDescription || '',
      // 中国語
      [appLanguages.cnName[lang]]: obj.cnName || '',
      [appLanguages.cnMainAddress[lang]]: obj.cnMainAddress || '',
      [appLanguages.cnInformation[lang]]: obj.cnInformation || '',
      [appLanguages.cnDescription[lang]]: obj.cnDescription || '',
      // 香港語
      [appLanguages.hkName[lang]]: obj.hkName || '',
      [appLanguages.hkMainAddress[lang]]: obj.hkMainAddress || '',
      [appLanguages.hkInformation[lang]]: obj.hkInformation || '',
      [appLanguages.hkDescription[lang]]: obj.hkDescription || '',
      // 韓国語
      [appLanguages.krName[lang]]: obj.krName || '',
      [appLanguages.krMainAddress[lang]]: obj.krMainAddress || '',
      [appLanguages.krInformation[lang]]: obj.krInformation || '',
      [appLanguages.krDescription[lang]]: obj.krDescription || '',
      // タイ語
      [appLanguages.thName[lang]]: obj.thName || '',
      [appLanguages.thMainAddress[lang]]: obj.thMainAddress || '',
      [appLanguages.thInformation[lang]]: obj.thInformation || '',
      [appLanguages.thDescription[lang]]: obj.thDescription || '',
      // ベトナム語
      [appLanguages.viName[lang]]: obj.viName || '',
      [appLanguages.viMainAddress[lang]]: obj.viMainAddress || '',
      [appLanguages.viInformation[lang]]: obj.viInformation || '',
      [appLanguages.viDescription[lang]]: obj.viDescription || '',
    };
  }

  handleClickDownload() {
    const prefix = 'locations';
    const { servers } = this.props;
    const totalCounts = servers.locationsTotalCounts;
    const limit = 500;
    let index = 0;
    const request = functions.httpsCallable('getMasterLocations');
    for (let offset = 0; offset < totalCounts; offset += 500) {
      const query = { ...servers.locationsQuery, offset, limit };
      request(query)
        // eslint-disable-next-line no-loop-func
        .then((result) =>
          exportToCSV(
            `${prefix}_${index}_`,
            result.data.objects.map((o) => this.formatData(o)),
          ),
        )
        .catch((error) => window.console.error(error));
      index += 1;
    }
  }

  render() {
    const {
      apps: { currentLanguage: lang },
    } = this.props;
    // const searchOpen = this.state.searchOpen;
    // const filterName = this.state.filterName;
    const gridListView = (
      <>
        {/* {searchOpen && (
          <Grid item>
            {getSmallTextField(appLanguages.name[lang], filterName, false, (e) =>
              this.handleChangefilterName(e.target.value),
            )}
          </Grid>
        )}
        <Grid item>
          <Tooltip title={`${appLanguages.search[lang]} (${appLanguages.name[lang]})`}>
            <IconButton onClick={() => this.handleClickSearch()}>
              <SearchIcon />
            </IconButton>
          </Tooltip>
        </Grid> */}
        <Grid item>
          <Tooltip title={appLanguages.csvDownload[lang]}>
            <IconButton onClick={() => this.handleClickDownload()}>
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title={appLanguages.reload[lang]}>
            <IconButton onClick={() => this.handleClickReload()}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title={appLanguages.addNewData[lang]}>
            <Button
              color='primary'
              disableElevation
              variant='contained'
              onClick={() => this.handleClickEdit()}
            >
              {formatString(i18n.addNewMasterInfo[lang], { data: i18n.customerLocation[lang] })}
            </Button>
          </Tooltip>
        </Grid>
      </>
    );

    return (
      <>
        <Hidden mdUp>
          <Grid container justify='center' alignContent='center' alignItems='center' wrap='nowrap'>
            {gridListView}
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Grid
            container
            justify='flex-end'
            alignContent='center'
            alignItems='center'
            wrap='nowrap'
          >
            {gridListView}
          </Grid>
        </Hidden>
      </>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
}

export interface IDispatchProps {
  openLocationEditDialog: (obj: any) => void;
  getMasterLocations: (query: IQuery) => void;
  setLocationsQuery: (query: IQuery) => void;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps: IDispatchProps = {
  openLocationEditDialog,
  getMasterLocations,
  setLocationsQuery,
};

export const LocationsCustomToolbar = compose(connect(mapStateToProps, mapDispatchToProps))(
  LocationsCustomToolbarClass,
);
