import { Box, CircularProgress, Grid, TextField, Typography } from '@material-ui/core';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import { IStateServers } from '@models/state-servers';
import { clearSuggestionsPlaces, getPlaceAutocomplete } from '@redux/actions/serversActions';
import { LANGUAGE_JAPANESE } from '@utils/common';
import React from 'react';
import Autosuggest from 'react-autosuggest';
import { connect } from 'react-redux';
import { compose } from 'redux';
import userEnv from 'userEnv';

class GoogleMapSuggestClass extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = {
    label: '',
    initialValue: '',
    placeholder: '',
    language: LANGUAGE_JAPANESE,
    region: 'JP',
    onSuggestionSelected: (suggestion) => {
      const { placeId, description, lat, lng } = suggestion;
      window.console.log('onSuggestionSelected called. ', placeId, description, lat, lng);
    },
  };

  constructor(props) {
    super(props);
    const { initialValue } = this.props;
    this.state = {
      value: initialValue,
      requestTimeout: undefined,
    };
  }

  handleOnSuggestionsFetchRequested(value) {
    const { language, region, getPlaceAutocomplete } = this.props;
    const { requestTimeout } = this.state;
    if (requestTimeout) clearTimeout(requestTimeout);
    const newRequestTimeout = setTimeout(() => {
      const params = {
        input: value,
        language,
        region,
        key: userEnv.googleMapApiKey,
      };
      getPlaceAutocomplete(params);
      this.setState({ requestTimeout: undefined });
    }, 600);
    this.setState({ requestTimeout: newRequestTimeout });
  }

  render() {
    const { label, placeholder, onSuggestionSelected } = this.props;
    const { servers, clearSuggestionsPlaces } = this.props;
    const { value, requestTimeout } = this.state;

    const isRequesting = servers.isGetRequesting || servers.isRequesting || requestTimeout;

    return (
      <Autosuggest
        suggestions={servers.suggestionsPlaces}
        onSuggestionsFetchRequested={({ value }) => this.handleOnSuggestionsFetchRequested(value)}
        onSuggestionsClearRequested={() => clearSuggestionsPlaces()}
        onSuggestionSelected={(e, { suggestion }) => onSuggestionSelected(suggestion)}
        getSuggestionValue={(suggestion) => suggestion.description}
        renderSuggestion={(suggestion) => (
          <Typography variant='body2' color='textSecondary'>
            {suggestion.description}
          </Typography>
        )}
        inputProps={{
          placeholder,
          value,
          onChange: (e, o) => this.setState({ value: o.newValue }),
        }}
        renderInputComponent={(inputProps) => (
          <>
            <TextField
              {...inputProps}
              label={
                <Grid container alignContent='center' alignItems='center'>
                  <Grid item>{label}</Grid>
                  <Grid item>
                    <Box ml={1} />
                  </Grid>
                  <Grid item>
                    {isRequesting ? <CircularProgress size={20} color='secondary' /> : ''}
                  </Grid>
                </Grid>
              }
              margin='normal'
              fullWidth
              InputProps={{
                endAdornment: isRequesting ? <CircularProgress size={20} color='secondary' /> : '',
              }}
            />
          </>
        )}
      />
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  label: string;
  initialValue: string;
  placeholder: string;
  language: string;
  region: string;
  servers: IStateServers;
}

export interface IDispatchProps {
  onSuggestionSelected: (suggestion: any) => void;
  getPlaceAutocomplete: (params: any) => void;
  clearSuggestionsPlaces: () => void;
}

interface State {
  value: string;
  requestTimeout: any;
}

const mapStateToProps = (state) => ({
  servers: state.servers,
});

const mapDispatchToProps = {
  getPlaceAutocomplete,
  clearSuggestionsPlaces,
};

const myStyles = (): StyleRules => ({});

export const GoogleMapSuggest = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(GoogleMapSuggestClass);
