export enum EAlert {
  SCAN_DATE = 1, // スキャン期限アラート（入荷）
  QUALITY = 3, // 在庫品質 (Keep Time)
  ILLEGAL_ROUTE = 6, // 不正流通（位置範囲外）
  ILLEGAL_SELLING = 7, //GPS selling alert
  ILLEGAL_SHIPPING = 9, //GPS shipping alert
  // Abolished Alert Types
  SCAN_QUANTITY = 2, // スキャン数量
  ROUTE = 4, // 流通ルート
  CONSUMER_LOCATION = 5, // 消費者流通（Consumer)
  DIST_QUALITY = 8, // 在庫品質 (Keep Time)
}
