import { Box, Grid, Paper, StyleRules, Theme, withStyles } from '@material-ui/core';
import { IStateApps } from '@models/state-apps';
import { SnackbarMessage } from '@old-components/snackbar';
import { openSnackbarMessage } from '@redux/actions/appsActions';
import { getUserLocations, registerTempUser } from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import appLanguages from '@utils/app-languages';
import { getOutlinedButton, getTextField, getTextFieldForPassword } from '@utils/common';
import { validateEmail } from '@utils/validate-email';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

class AddTempRestaurantUserClass extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      password: '',
      email: '',
      showPassword: false,
    };
  }

  handleChangeName(e) {
    this.setState({
      name: e.target.value,
    });
  }

  handleChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  handleShowPasswordToggle() {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  }

  handleClickTempRegister() {
    const { name, password, email } = this.state;
    const {
      apps: { currentLanguage: lang },
      openSnackbarMessage,
      registerTempUser,
    } = this.props;
    const tempUserObj = {
      name,
      password,
      email,
      locationCategory: 1,
    };
    if (!tempUserObj.name) {
      return openSnackbarMessage('error', appLanguages.pleaseSetTheName[lang]);
    }
    if (!tempUserObj.email) {
      return openSnackbarMessage('error', appLanguages.pleaseSetTheEmail[lang]);
    }
    if (!validateEmail(tempUserObj.email)) {
      return openSnackbarMessage('error', appLanguages.invalidEmailFormat[lang]);
    }
    if (!tempUserObj.password) {
      return openSnackbarMessage('error', appLanguages.pleaseSetThePassword[lang]);
    }

    registerTempUser(tempUserObj);

    return true;
  }

  render() {
    const {
      apps: { currentLanguage: lang },
      classes,
    } = this.props;
    const { name, password, email, showPassword } = this.state;
    return (
      <Paper elevation={3} className={classes.paper}>
        <SnackbarMessage />
        <Box m={5}>
          <Box mt={1} />
          {getTextField(
            `${appLanguages.name[lang]} (${appLanguages.require[lang]})`,
            name,
            false,
            (e) => this.handleChangeName(e),
          )}
          <Box mt={1} />
          {getTextField(
            `${appLanguages.email[lang]} (${appLanguages.require[lang]})`,
            email,
            false,
            (e) => this.handleChangeEmail(e),
          )}
          <Box mt={1} />
          {getTextFieldForPassword(
            `${appLanguages.password[lang]} (${appLanguages.require[lang]})`,
            password,
            false,
            (e) => this.handleChangePassword(e),
            showPassword,
            () => this.handleShowPasswordToggle(),
          )}
          <Grid container justify='flex-end'>
            <Box mb={2} mt={5}>
              {getOutlinedButton(appLanguages.tempRegistration[lang], false, () =>
                setTimeout(() => this.handleClickTempRegister(), 1),
              )}
            </Box>
          </Grid>
        </Box>
      </Paper>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  classes: any;
  apps: IStateApps;
}

export interface IDispatchProps {
  openSnackbarMessage: (type: string, message: string) => void;
  registerTempUser: (userData: any) => void;
}

interface State {
  name: string;
  password: string;
  email: string;
  showPassword: boolean;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
});

const mapDispatchToProps = {
  registerTempUser,
  getUserLocations,
  openSnackbarMessage,
};

const myStyles = (theme: Theme): StyleRules => ({
  fileButton: { margin: theme.spacing(1) },
  fileInput: { display: 'none' },
  avatar: {
    width: theme.spacing(14),
    height: theme.spacing(14),
  },
});

export const AddTempRestaurantUser = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(AddTempRestaurantUserClass);
