import { i18n } from '@i18n/lang';
import { TSupportedLanguages } from '@models/supported-languages';
import { LANGUAGE_DEFAULT } from '@utils/common';
import { formatString } from '@utils/format-string';
import { Item as SelectBoxItem } from '@visual/select-box';

export function getSortSelectOptions(
  lang: TSupportedLanguages = LANGUAGE_DEFAULT,
): SelectBoxItem[] {
  const selectOptions = [
    { id: 0, text: formatString(i18n.sortNewer[lang], { field: i18n.date[lang] }) },
    { id: 1, text: formatString(i18n.sortOlder[lang], { field: i18n.date[lang] }) },
    { id: 2, text: formatString(i18n.sortAscending[lang], { field: i18n.serialNo[lang] }) },
    { id: 3, text: formatString(i18n.sortDescending[lang], { field: i18n.serialNo[lang] }) },
    // { id: 4, text: i18n.sortByStatus[lang] },
  ];

  return selectOptions;
}
