import { i18n } from '@i18n/lang';
import { ERestaurantStatus } from '@models/restaurant-status-type';
import { TSupportedLanguages } from '@models/supported-languages';
import { LANGUAGE_DEFAULT } from '@utils/common';
import { Icon } from '@visual/icon';
import { NestedTable } from '@visual/nested-table';
import { RestaurantStatusIcon } from '@visual/restaurant-status-icon';
import { TextDisplay } from '@visual/text-display';
import React from 'react';
import styles from './style.scss';

export class RestaurantTableRow extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = {
    isNestedTableCollapsed: false,
    flagship: false,
    lang: LANGUAGE_DEFAULT,
    isFirstRow: false,
    isLastRow: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isNestedTableCollapsed: props.isNestedTableCollapsed,
    };
  }

  public render(): JSX.Element {
    return (
      <>
        {this.getMainRow()}
        {this.getNestedTable()}
      </>
    );
  }

  protected getMainRow(): JSX.Element {
    const { status, name, code, lastInspectionDate, lang } = this.props;
    return (
      <tr className={styles.tableRow}>
        <td className={this.getTableCellStyle()}>
          <RestaurantStatusIcon lang={lang} type={status} />
        </td>
        <td className={this.getTableCellStyle()}>
          <TextDisplay style={{ width: '100px' }} truncate='ellipsis'>
            {name}
          </TextDisplay>
        </td>
        <td className={this.getTableCellStyle()}>
          <TextDisplay style={{ width: '100px' }} truncate='ellipsis'>
            {code}
          </TextDisplay>
        </td>
        <td className={this.getTableCellStyle()}>{this.getFlagship()}</td>
        <td className={this.getTableCellStyle()}>{this.getSellingInfoCollapseButton()}</td>
        <td className={this.getTableCellStyle()}>
          <TextDisplay>{lastInspectionDate}</TextDisplay>
        </td>
        <td className={this.getTableCellStyle()}>
          <span className={styles.paddedRight}>
            <Icon isCircular type='dashboard' color='primary' />
          </span>
          <Icon isCircular type='monitor' color='primary' />
        </td>
      </tr>
    );
  }
  protected getNestedTable(): JSX.Element {
    const { isNestedTableCollapsed } = this.state;
    if (!this.props.nestedTableData) return;
    const {
      nestedTableData: { columns, rows },
    } = this.props;
    if (!isNestedTableCollapsed) return;
    return (
      <tr className={styles.tableRow}>
        <td colSpan={4}></td>
        <td colSpan={3}>
          <NestedTable columns={columns} rows={rows} />
        </td>
      </tr>
    );
  }

  protected getFlagship(): JSX.Element {
    const { flagship } = this.props;
    return (
      <div className={`${styles.flagship} ${flagship && styles.isFlagship}`}>
        <Icon
          type={flagship ? 'check' : 'close'}
          size='xx-small'
          color={flagship ? 'dark' : 'dark0'}
        />
      </div>
    );
  }

  protected getSellingInfoCollapseButton(): JSX.Element {
    const { lang, id: restLocId } = this.props;
    const { isNestedTableCollapsed } = this.state;

    return (
      <div
        className={`${styles.sellingInfoCollapseButton} ${
          isNestedTableCollapsed && styles.isCollapsed
        }`}
        onClick={() => this.handleOnSellingInfoClick(restLocId)}
      >
        <span className={styles.paddedRight}>
          <TextDisplay size='small' color={isNestedTableCollapsed ? 'dark0' : 'default'}>
            {isNestedTableCollapsed ? i18n.close[lang] : i18n.open[lang]}
          </TextDisplay>
        </span>
        <Icon
          type={isNestedTableCollapsed ? 'top' : 'down'}
          size='xx-small'
          color={isNestedTableCollapsed ? 'dark0' : 'default'}
        />
      </div>
    );
  }

  protected handleOnSellingInfoClick(restLocId: number) {
    const { onNestedTableDisplayClick } = this.props;
    const { isNestedTableCollapsed } = this.state;
    const newValue = !isNestedTableCollapsed;
    this.setState({
      isNestedTableCollapsed: newValue,
    });
    if (!onNestedTableDisplayClick) return;
    onNestedTableDisplayClick(newValue, restLocId);
  }

  protected getTableCellStyle() {
    const { isFirstRow, isLastRow } = this.props;
    return [styles.tableCell, isFirstRow && styles.firstRow, isLastRow && styles.lastRow]
      .filter((style) => style)
      .join(' ');
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  id: number;
  status: ERestaurantStatus;
  name: string;
  code: string;
  lastInspectionDate: string;
  flagship?: boolean;
  lang?: TSupportedLanguages;
  isFirstRow?: boolean;
  isLastRow?: boolean;
  isNestedTableCollapsed?: boolean;
  nestedTableData?: { columns: string[]; rows: { data: (string | number)[] }[] };
}

export interface IDispatchProps {
  onRestaurantNameClick?: (restLocId: number) => void;
  onNestedTableDisplayClick?: (isDisplay: boolean, restLocId: number) => void;
}

interface State {
  isNestedTableCollapsed?: boolean;
}
