import { isOk } from '@utils/is-ok';
import { TextDisplay } from '@visual/text-display';
import hash from 'object-hash';
import React from 'react';
import styles from './style.scss';

export class ListPageItem extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isSelected: false,
    };
  }
  public render(): JSX.Element {
    const { title, onClick, idForClick, alert } = this.props;

    return (
      <div
        className={`${styles.container} ${onClick && styles.clickable}`}
        onClick={() => this.handleOnClick(idForClick)}
      >
        {this.getCheckBox()}
        <div className={styles.subContainer}>
          {this.getTopIcons()}
          <TextDisplay size='large' weight='bold' truncate='ellipsis'>
            {title}
          </TextDisplay>
          <TextDisplay size='large' weight='bold' color='alert' truncate='ellipsis'>
            {alert}
          </TextDisplay>
          <div className={styles.detailTable}>
            <div className={styles.row}>{this.getSubTitles()}</div>
            <div className={styles.row}>{this.getSubInfos()}</div>
          </div>
        </div>
        {this.getBadge()}
        {this.getEndIcon()}
      </div>
    );
  }

  protected getBadge(): JSX.Element {
    const { badge } = this.props;
    if (!badge) return;
    return (
      <div className={styles.badge}>
        <TextDisplay weight='bold' size='small'>
          {badge}
        </TextDisplay>
      </div>
    );
  }

  protected getTopIcons(): JSX.Element {
    const { topIcon } = this.props;
    if (!isOk(topIcon)) return;
    return (
      <div className={styles.topIconContainer}>
        <span className={styles.topIcon}>{topIcon}</span>
      </div>
    );
  }

  protected handleOnClick(idForClick: number | string) {
    const { onClick, isMulti, onSelected, selectedAlerts, idForAlert } = this.props;
    if (isMulti) {
      if (selectedAlerts.indexOf(idForAlert) == -1) {
        // push selected value in list
        onSelected(idForAlert, true);
      } else {
        // remove unchecked value from the list
        onSelected(idForAlert, false);
      }
      return;
    }
    if (!onClick) return;
    onClick(idForClick);
  }

  protected getSubTitles(): JSX.Element[] {
    const { subTitles } = this.props;
    if (!isOk(subTitles)) return;

    return subTitles.map((subTitle, i) => (
      <div className={styles.cell} key={hash(i)}>
        <TextDisplay weight='bold' color='primary'>
          {subTitle}
        </TextDisplay>
      </div>
    ));
  }

  protected getSubInfos(): JSX.Element[] {
    const { subInfos } = this.props;
    if (!isOk(subInfos)) return;

    return subInfos.map((subInfo, i) => (
      <div className={styles.cell} key={hash(i)}>
        <TextDisplay size='x-small'>{subInfo.label}</TextDisplay>
        <TextDisplay>{subInfo.value}</TextDisplay>
      </div>
    ));
  }

  protected getCheckBox(): JSX.Element {
    const { isMulti, onSelected, idForAlert, selectedAlerts } = this.props;
    // const { isSelected } = this.state;

    const handleChange = (e) => {
      const { checked } = e.target;
      // if (!onSelected) return;
      if (checked) {
        // push selected value in list
        console.log('checked, id:', idForAlert);
        onSelected(idForAlert, checked);
      } else {
        // remove unchecked value from the list
        console.log('unchecked, id:', idForAlert);
        onSelected(idForAlert, checked);
      }
    };

    if (!isMulti) return;
    return (
      <input
        type='checkbox'
        name='lang'
        onChange={handleChange}
        checked={selectedAlerts.indexOf(idForAlert) != -1}
        style={{ marginRight: '20px' }}
      />
    );
  }

  protected getEndIcon(): JSX.Element {
    const { endIcon } = this.props;
    if (!endIcon) return;
    return <div className={styles.endIcon}>{endIcon}</div>;
  }
}
export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  title: string;
  topIcon?: React.ReactNode;
  alert?: string;
  subTitles?: string[];
  subInfos?: { label: string; value: string }[];
  endIcon?: React.ReactNode;
  badge?: number | string;
  idForClick?: number | string;
  idForAlert?: number | string;
  isMulti?: boolean;
  selectedAlerts?: any;
}

export interface IDispatchProps {
  onClick?: (idForClick: number | string) => void;
  onSelected?: (idForClick: number | string, selected: boolean) => void;
}
interface State {
  isSelected: boolean;
}
