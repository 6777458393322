import { CircularLoading } from '@container/components/circular-loading';
import { StyleRules, withStyles } from '@material-ui/core';
import { AllPages } from '@models/all-pages';
import { INotification } from '@models/api-get-notices';
import { IStateApps } from '@models/state-apps';
import { IStateAuth } from '@models/state-auth';
import { IStateServers } from '@models/state-servers';
import { setCurrentPage } from '@redux/actions/appsActions';
import { registerNotification } from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import { ColoredBackground } from '@visual/colored-background';
import { NotificationRegister as NotificationRegisterVisual } from '@visual/notification-register';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

class NotificationRegisterClass extends React.Component<Props, State> {
  public componentDidMount() {
    this.props.setCurrentPage('notification-register');
  }

  public render(): JSX.Element {
    const { servers, auth, classes } = this.props;
    const isRequesting = servers.isRequesting || servers.isGetRequesting || auth.isRequesting;

    return (
      <ColoredBackground>
        <div className={classes.headerSpace} />
        {isRequesting ? <CircularLoading /> : this.getContents()}
      </ColoredBackground>
    );
  }

  protected getContents(): JSX.Element {
    const {
      apps: { currentLanguage: lang },
    } = this.props;
    return (
      <NotificationRegisterVisual
        lang={lang}
        onSendButtonClick={(title: string, content: string) =>
          this.handleSendButtonClick(title, content)
        }
      />
    );
  }

  protected handleSendButtonClick(title: string, content: string) {
    const { registerNotification } = this.props;
    const notificationObj: Partial<INotification> = {
      title,
      body: content,
    };
    registerNotification(notificationObj);
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  auth: IStateAuth;
  classes: any;
}

export interface IDispatchProps {
  registerNotification: (notificationObj: Partial<INotification>) => void;
  setCurrentPage: (pageId: AllPages) => void;
}

interface State {
  targetNotification?: INotification;
  showNotificationPopup?: boolean;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
  auth: state.auth,
});

const mapDispatchToProps: IDispatchProps = {
  registerNotification,
  setCurrentPage,
};

const myStyles = (): StyleRules => ({
  headerSpace: { paddingTop: '68px' },
});

export const NotificationRegister = compose<any>(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(NotificationRegisterClass);
