import { Initialize } from '@container/main/initialize';
import { store } from '@redux/reducers';
import { ConfirmProvider } from 'material-ui-confirm';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

export function App() {
  return (
    <Provider store={store}>
      <ConfirmProvider>
        <BrowserRouter>
          <Switch>
            <Route exact component={Initialize} />
          </Switch>
        </BrowserRouter>
      </ConfirmProvider>
    </Provider>
  );
}
