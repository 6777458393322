import { CircularProgress, StyleRules, Theme, withStyles } from '@material-ui/core';
import React from 'react';
import { compose } from 'redux';

class CircularLoadingClass extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { classes } = this.props;
    return (
      <div className={classes.circularProgressContainer}>
        <div className={classes.toolbar} />
        <CircularProgress color='secondary' />
      </div>
    );
  }
}

export type Props = IStateProps;

export interface IStateProps {
  classes: any;
}

const myStyles = (theme: Theme): StyleRules => ({
  toolbar: { paddingTop: '68px' },
  circularProgressContainer: {
    textAlign: 'center',
  },
});

export const CircularLoading = compose(withStyles(myStyles))(CircularLoadingClass);
