import { Icon, IconTypes } from '@visual/icon';
import { TextDisplay } from '@visual/text-display';
import React from 'react';
import styles from './style.scss';

export class CardButton extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    theme: 'primary',
    badgeCount: 0,
    size: 'fit',
  };

  public render(): JSX.Element {
    const { iconType, children, size, onClick, theme } = this.props;
    return (
      <div className={`${styles.cardButton} ${styles[theme]} ${styles[size]}`} onClick={onClick}>
        <div className={styles.leftContent}>
          <Icon type={iconType} size='x-large' color='primary' />
          {children && (
            <TextDisplay style={{ paddingLeft: '10px' }} size='x-large'>
              {children}
            </TextDisplay>
          )}
        </div>
        <div className={styles.rightContent}>{this.getBadge()}</div>
      </div>
    );
  }

  protected getBadge(): JSX.Element {
    const { badgeCount } = this.props;
    if (!(badgeCount > 0)) return;
    return (
      <span className={styles.badge}>
        <TextDisplay weight='bold' size='x-small'>
          {badgeCount}
        </TextDisplay>
      </span>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  iconType?: IconTypes;
  children?: string;
  theme?: TTheme;
  badgeCount?: number;
  size?: 'fit' | 'full';
}

export type TTheme = 'primary' | 'secondary';

export interface IDispatchProps {
  onClick?: () => void;
}
