import { i18n } from '@i18n/lang';
import { Dialog, DialogContent } from '@material-ui/core';
import { TSupportedLanguages } from '@models/supported-languages';
import { LANGUAGE_DEFAULT } from '@utils/common';
import { Button } from '@visual/button';
import { TextDisplay } from '@visual/text-display';
import hash from 'object-hash';
import React from 'react';
import styles from './style.scss';

export class NotificationDetailPopup extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    showPopup: false,
    lang: LANGUAGE_DEFAULT,
  };

  public render(): JSX.Element {
    const {
      title,
      onConfirmationButtonClick,
      onCancelClick,
      onSendClick,
      children,
      showPopup,
      onClose,
      lang,
    } = this.props;

    const buttons = [
      onConfirmationButtonClick && (
        <Button onClick={onConfirmationButtonClick}>{i18n.confirm[lang]}</Button>
      ),
      onSendClick && (
        <Button onClick={onSendClick}>{i18n.sendWithTheFollowingContents[lang]}</Button>
      ),
      onCancelClick && (
        <Button color='secondary-light4' onClick={onCancelClick}>
          {i18n.cancel[lang]}
        </Button>
      ),
    ]
      .filter((button) => button)
      .map((button, i) => (
        <div key={hash(i)} className={styles.button}>
          {button}
        </div>
      ));

    return (
      <Dialog open={showPopup} onClose={onClose}>
        <DialogContent style={{ padding: 0 }}>
          <div className={styles.notificationDetailPopup}>
            <div className={styles.title}>
              <TextDisplay color='primary' weight='bold' size='x-large'>
                {title}
              </TextDisplay>
            </div>
            <div className={styles.content}>
              <TextDisplay color='black'>
                <pre>
                  <div dangerouslySetInnerHTML={{ __html: children }} />
                </pre>
              </TextDisplay>
            </div>
            <div className={styles.buttonContainer}>{buttons}</div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  title: string;
  children: string;
  showPopup?: boolean;
  lang?: TSupportedLanguages;
}

export interface IDispatchProps {
  onConfirmationButtonClick?: () => void;
  onSendClick?: () => void;
  onCancelClick?: () => void;
  onClose?: () => void;
}
