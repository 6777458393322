import { i18n } from '@i18n/lang';
import { Button, Grid, Hidden, IconButton, Tooltip } from '@material-ui/core';
import { CloudDownload as CloudDownloadIcon, Refresh as RefreshIcon } from '@material-ui/icons';
import { ECategoryType } from '@models/category-type';
import { IQuery } from '@models/query';
import { IStateApps } from '@models/state-apps';
import { IStateServers } from '@models/state-servers';
import { openUserEditDialog } from '@redux/actions/appsActions';
import { getMasterUsers, getUserLocations, setUsersQuery } from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import appLanguages from '@utils/app-languages';
import { exportToCSV } from '@utils/common';
import { functions } from '@utils/firebase';
import { formatString } from '@utils/format-string';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

export interface State {
  searchOpen: boolean;
  filterName: string;
  filterTimeout: any;
}

class UsersCustomToolbarClass extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    const { servers } = this.props;
    const { where } = servers.usersQuery;
    this.state = {
      searchOpen: where.name ? true : false,
      filterName: where.name ? where.name.$like.replace(/%/g, '') : '',
      filterTimeout: undefined,
    };
  }
  setFilter(newWhere) {
    const { servers } = this.props;
    const where = { ...servers.usersQuery.where, ...newWhere };
    this.updateQuery({ where, offset: 0 });
    this.setState({ filterTimeout: null });
  }

  unsetFilter(key) {
    const { servers } = this.props;
    const { where } = servers.usersQuery;
    if (where[key] != undefined) delete where[key];
    this.setFilter(where);
  }

  clearFilterTimeout() {
    const { filterTimeout } = this.state;
    if (filterTimeout) clearTimeout(filterTimeout);
  }
  updateQuery(newQuery) {
    const { servers, setUsersQuery, getMasterUsers } = this.props;
    const query = { ...servers.usersQuery, ...newQuery };
    setUsersQuery(query);
    getMasterUsers(query);
  }

  handleClickEdit() {
    const { openUserEditDialog } = this.props;
    // getUserLocations(0);
    openUserEditDialog();
  }

  handleClickReload() {
    const { servers, getMasterUsers } = this.props;
    getMasterUsers(servers.usersQuery);
  }

  handleClickSearch() {
    this.setState({ searchOpen: !this.state.searchOpen });
  }

  handleChangefilterName = (v) => {
    this.clearFilterTimeout();
    this.setState({
      filterName: v,
      filterTimeout: setTimeout(() => this.setFilter({ name: { $like: `%${v}%` } }), 1000),
    });
  };

  formatData(obj) {
    const { apps } = this.props;
    const lang = apps.currentLanguage;
    return {
      [appLanguages.name[lang]]: obj.name || '',
      [appLanguages.email[lang]]: obj.email || '',
      [appLanguages.password[lang]]: obj.password || '',
      [appLanguages.avatarImageUrl[lang]]: obj.imageUrl || '',
      [appLanguages.company[lang]]: obj.company || '',
      [appLanguages.locationId[lang]]: (obj.location && obj.location.customerCode) || '',
    };
  }

  handleClickDownload() {
    const prefix = 'users';
    const { servers } = this.props;
    const totalCounts = servers.usersTotalCounts;
    const limit = 500;
    let index = 0;
    const request = functions.httpsCallable('getMasterUsers');
    for (let offset = 0; offset < totalCounts; offset += 500) {
      const query = { ...servers.usersQuery, offset, limit };
      request(query)
        // eslint-disable-next-line no-loop-func
        .then((result) =>
          exportToCSV(
            `${prefix}_${index}_`,
            result.data.objects.map((o) => this.formatData(o)),
          ),
        )
        .catch((error) => window.console.error(error));
      index += 1;
    }
  }

  render() {
    const {
      apps: { currentLanguage: lang },
    } = this.props;
    // const searchOpen = this.state.searchOpen;
    // const filterName = this.state.filterName;
    const gridListView = (
      <>
        {/* {searchOpen && (
          <Grid item>
            {getSmallTextField(appLanguages.name[lang], filterName, false, (e) =>
              this.handleChangefilterName(e.target.value),
            )}
          </Grid>
        )}
        <Grid item>
          <Tooltip title={`${appLanguages.search[lang]} (${appLanguages.name[lang]})`}>
            <IconButton onClick={() => this.handleClickSearch()}>
              <SearchIcon />
            </IconButton>
          </Tooltip>
        </Grid> */}
        <Grid item>
          <Tooltip title={appLanguages.csvDownload[lang]}>
            <IconButton onClick={() => this.handleClickDownload()}>
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title={appLanguages.reload[lang]}>
            <IconButton onClick={() => this.handleClickReload()}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Button
            color='primary'
            disableElevation
            variant='contained'
            onClick={() => this.handleClickEdit()}
          >
            {formatString(i18n.addNewMasterInfo[lang], { data: i18n.users[lang] })}
          </Button>
        </Grid>
      </>
    );

    return (
      <>
        <Hidden mdUp>
          <Grid container justify='center' alignContent='center' alignItems='center' wrap='nowrap'>
            {gridListView}
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Grid
            container
            justify='flex-end'
            alignContent='center'
            alignItems='center'
            wrap='nowrap'
          >
            {gridListView}
          </Grid>
        </Hidden>
      </>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
}

export interface IDispatchProps {
  openUserEditDialog: () => void;
  getMasterUsers: (query: IQuery) => void;
  getUserLocations: (category: ECategoryType) => void;
  setUsersQuery: (any) => void;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps: IDispatchProps = {
  openUserEditDialog,
  getMasterUsers,
  getUserLocations,
  setUsersQuery,
};

export const UsersCustomToolbar = compose(connect(mapStateToProps, mapDispatchToProps))(
  UsersCustomToolbarClass,
);
