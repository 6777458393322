import { Icon, IconTypes } from '@visual/icon';
import { TextDisplay } from '@visual/text-display';
import React from 'react';
import styles from './style.scss';

export class MapMarker extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { type, label, secondLabel } = this.props;

    return (
      <div className={styles.mapMarker}>
        <span className={styles.iconBg}>
          <Icon type={type} isCircular color='primary' />
        </span>
        <span className={styles.textBg}>
          <TextDisplay truncate='ellipsis' size='small' color='primary'>
            {label}
          </TextDisplay>
          {secondLabel && (
            <TextDisplay truncate='ellipsis' size='small' color='primary'>
              （{secondLabel}）
            </TextDisplay>
          )}
        </span>
      </div>
    );
  }
}

export type Props = IStateProps;
export interface IStateProps {
  lat: number;
  lng: number;
  type: IconTypes;
  label: string;
  secondLabel?: string;
}
