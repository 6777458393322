export function formatDate(rawDate, format: Format = '24hour'): string {
  try {
    if (!rawDate || rawDate === {}) return '';
    const useDate = rawDate instanceof Date ? rawDate : new Date(rawDate);

    if (format === 'MM/DD') {
      return useDate.toLocaleString('ja-JP', { month: '2-digit', day: '2-digit' });
    } else {
      return useDate.toLocaleString('ja-JP', {
        hour12: false,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      });
    }
  } catch (e) {
    return '';
  }
}

type Format = '24hour' | 'MM/DD';
