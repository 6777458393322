import { CircularLoading } from '@container/components/circular-loading';
import { i18n } from '@i18n/lang';
import { LinearProgress, StyleRules, withStyles } from '@material-ui/core';
import { EAlertStatus } from '@models/alert-status-type';
import { AllPages } from '@models/all-pages';
import { IQuery } from '@models/query';
import { IStateApps } from '@models/state-apps';
import { IStateAuth } from '@models/state-auth';
import { IStateServers } from '@models/state-servers';
import { setCurrentPage, setMonitoringFilter } from '@redux/actions/appsActions';
import {
  getAdditionalAlerts,
  getAlerts,
  getAlertsCount,
  getLocationRegions,
  getMasterLocations,
  registerAlertDetail,
  setAlertFilterQuery,
  setAlertsQuery,
} from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import { AppRoutePaths } from '@utils/app-route-paths';
import { LIST_PAGE_SCROLL_LOAD_LIMIT } from '@utils/config';
import { getCountryName } from '@utils/country-info/get-country-name';
import { DefaultQuery } from '@utils/default-query';
import { formatDate } from '@utils/format-date';
import { getAlertMessage } from '@utils/get-alert-message';
import { getUserCategory } from '@utils/get-user-category';
import { isOk } from '@utils/is-ok';
import { ColoredBackground } from '@visual/colored-background';
import { Icon } from '@visual/icon';
import { ListPageItem } from '@visual/list-page-item';
import { MonitoringFilter } from '@visual/monitoring-filter';
import { History, LocationState } from 'history';
import * as hash from 'object-hash';
import React from 'react';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { compose } from 'redux';
import { getAlertStatusPill } from '../../../../utils/get-alert-status-pill';
import { getAlertsQuery, getAlertsQueryPerSerialCodeAndSort } from './config/get-alerts-query';
import { getSortSelectOptions } from './config/get-sort-select-options';

const MAX_LIMIT = 500;

class MonitoringClass extends React.PureComponent<Props, State> {
  private searchText = '';
  private filterOptions = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      locationsList: [],
      isMulti: false,
      selectedAlerts: [],
    };
  }

  public async componentDidMount() {
    try {
      const {
        getAlerts,
        setAlertsQuery,
        apps: { monitoringFilter },
        servers: { user, locationsQuery, alertFilterQuery, alertsQuery },
        setCurrentPage,
        getMasterLocations,
        getLocationRegions,
      } = this.props;

      const userCategory = getUserCategory(user);
      let locQuery = { ...locationsQuery };
      locQuery.limit = 100000;

      await getLocationRegions({});
      await getMasterLocations(locQuery);
      setCurrentPage('monitoring');
      let query: IQuery = getAlertsQuery(
        monitoringFilter?.selectedSortItem,
        monitoringFilter?.selectedStatus,
        user,
      );
      if (alertFilterQuery) {
        query = alertsQuery;
        query.offset = 0;
        query.limit = 10;
        this.filterOptions = query.where;
      }
      await setAlertsQuery(query);
      await getAlerts(query);
      const {
        servers: { locations },
      } = this.props;

      let locationsList = this.filterLocationsByUser(userCategory, locations);
      this.setState({ locationsList: locationsList });
    } catch (err) {
      console.log(err, '-----error-----');
    }
  }

  public render(): JSX.Element {
    const { servers, auth, classes } = this.props;
    const isRequesting =
      servers.isRequesting ||
      servers.isGetRequesting ||
      auth.isRequesting ||
      !isOk(servers.user) ||
      !servers.allLocations ||
      !servers.allBrands;

    return (
      <ColoredBackground>
        <div className={classes.headerSpace} />
        {this.getMonitoringFilter()}
        <div className={classes.monitoringFilter} />
        {isRequesting ? <CircularLoading /> : this.getContents()}
      </ColoredBackground>
    );
  }

  protected filterLocationsByUser(userCategory, allLocations) {
    let locationsList;
    if (userCategory <= 1) {
      // user is an admin/brewery, so fetch all locations
      locationsList = allLocations.map((o) => {
        const v = { id: o.id, name: o.name, region: o.country };
        return v;
      });
    } else if (userCategory === 2) {
      // user is a distributor, so fetch only restaurants
      const restaurantLocations = allLocations.filter((x) => x.category === 3);
      locationsList = restaurantLocations.map((o) => {
        const v = { id: o.id, name: o.name, region: o.country };
        return v;
      });
    } else {
      locationsList = [];
    }
    return locationsList;
  }

  protected getMonitoringFilter(): JSX.Element {
    const {
      apps: {
        currentLanguage: lang,
        monitoringFilter: { selectedSortItem, selectedStatus },
      },
      servers: { user, filteredAlertsCount, locationRegions, alertFilterQuery },
    } = this.props;
    const { locationsList, selectedAlerts } = this.state;
    const userCategory = getUserCategory(user);
    return (
      <MonitoringFilter
        selectedAlertFilterOptions={alertFilterQuery}
        userCategory={userCategory}
        sortFields={getSortSelectOptions(lang)}
        selectedAlertStatus={selectedStatus}
        selectedSortItem={selectedSortItem}
        selectedAlerts={selectedAlerts}
        locationList={locationsList}
        regionList={locationRegions}
        onRegionSelect={(selectedRegion: string) => this.filterLocationsByRegion(selectedRegion)}
        lang={lang}
        alertsCount={filteredAlertsCount || 0}
        onAlertStatusToggle={(status: EAlertStatus, status2?: EAlertStatus) =>
          this.handleOnAlertStatusToggle(status, status2)
        }
        onMultiSelectToggle={(isMulti: boolean) => this.handleOnMultiSelectToggle(isMulti)}
        onExecuteRegisterAlertDetail={(status: EAlertStatus, comment?: string) => {
          selectedAlerts.forEach((id) => {
            console.log(
              'executing registerAlertDetail API (id, status, comment): ',
              Number(id),
              status,
              comment,
            );
            this.executeRegisterAlertDetail(Number(id), status, comment);
          });
        }}
        onSelectedSortItemChange={(id: number, serial: string) =>
          this.handleOnSelectedSortItemChange(id, serial)
        }
        onChangeSearchBox={(serial: string) => this.handleSearchBoxChange(serial)}
        onClearAllButtonClick={() => this.handleClearAllFilters()}
        onApplyAdvanceFilterClick={(advanceFilterOptions: any, selectedOptions: any) =>
          this.onApplyAdvanceFilterClickClickHandler(advanceFilterOptions, selectedOptions)
        }
      />
    );
  }

  protected advancedFilterOptions(options: any) {
    let advancedFilterOptions = {};
    if (options) {
      if (options.alertLocId) {
        advancedFilterOptions['alertLocId'] = options.alertLocId;
      }
      if (options.type) {
        advancedFilterOptions['type'] = options.type;
      }
      if (options.serialCode) {
        advancedFilterOptions['serialCode'] = options.serialCode;
      }
    }
    return advancedFilterOptions;
  }

  protected filterLocationsByRegion(selectedRegion) {
    const {
      servers: { allLocations, user },
    } = this.props;
    const userCategory = getUserCategory(user);
    const filteredLocations = this.filterLocationsByUser(userCategory, allLocations);
    let filteredLocationsByRegion;
    if (selectedRegion) {
      filteredLocationsByRegion = filteredLocations.filter((x) => x.region === selectedRegion);
    } else {
      filteredLocationsByRegion = filteredLocations;
    }

    this.setState({ locationsList: filteredLocationsByRegion });
  }

  protected async onApplyAdvanceFilterClickClickHandler(filterOptions, selectedOptions) {
    try {
      const {
        apps: {
          monitoringFilter: { selectedSortItem, selectedStatus },
        },
        servers: { user },
        setAlertsQuery,
        getAlerts,
        setAlertFilterQuery,
      } = this.props;
      this.filterOptions = filterOptions;
      let newAlertQuery = getAlertsQuery(selectedSortItem, selectedStatus, user);
      if (this.searchText) {
        const newQuery = getAlertsQueryPerSerialCodeAndSort(
          this.searchText,
          selectedSortItem,
          user,
        );
        const newQueryWhereClause = Object.assign({}, newAlertQuery.where, newQuery.where);
        newAlertQuery.where = newQueryWhereClause;
      }
      const advancedFilterOptions = this.advancedFilterOptions(this.filterOptions);
      const queryWhereClause = newAlertQuery.where;
      const newWhereClause = Object.assign({}, queryWhereClause, advancedFilterOptions);
      newAlertQuery.where = newWhereClause;
      await setAlertFilterQuery(selectedOptions);
      await setAlertsQuery(newAlertQuery);
      await getAlerts(newAlertQuery);
    } catch (err) {
      console.log(err);
    }
  }

  protected async handleClearAllFilters() {
    const {
      getAlerts,
      setAlertsQuery,
      setMonitoringFilter,
      apps: { monitoringFilter },
      servers: { user },
    } = this.props;

    this.filterOptions = null;
    this.searchText = '';

    const query: IQuery = getAlertsQuery(0, monitoringFilter?.selectedStatus, user);

    await setMonitoringFilter(0, monitoringFilter?.selectedStatus);
    await setAlertsQuery(query);
    await getAlerts(query);
  }

  protected async handleSearchBoxChange(serial: string) {
    try {
      const {
        setAlertsQuery,
        getAlerts,
        servers: { user },
        apps: {
          monitoringFilter: { selectedSortItem, selectedStatus },
        },
      } = this.props;
      let newAlertQuery = getAlertsQuery(selectedSortItem, selectedStatus, user);
      if (serial) {
        this.searchText = serial;
        const newQuery = getAlertsQueryPerSerialCodeAndSort(serial, selectedSortItem, user);
        const newQueryWhereClause = Object.assign({}, newAlertQuery.where, newQuery.where);
        newAlertQuery.where = newQueryWhereClause;
        console.log(newAlertQuery);
      } else {
        this.searchText = '';
      }

      // consider the advanced filter options here
      const advancedFilterOptions = this.advancedFilterOptions(this.filterOptions);
      const queryWhereClause = newAlertQuery.where;
      const newWhereClause = Object.assign({}, queryWhereClause, advancedFilterOptions);
      newAlertQuery.where = newWhereClause;
      await setAlertsQuery(newAlertQuery);
      await getAlerts(newAlertQuery);
    } catch (err) {
      console.log(err);
    }
  }

  protected handleOnSelectedSortItemChange(id: number, serial: string) {
    const {
      apps: {
        monitoringFilter: { selectedStatus },
      },
      servers: { user },
      setMonitoringFilter,
      setAlertsQuery,
      getAlerts,
    } = this.props;

    let newAlertQuery = getAlertsQuery(id, selectedStatus, user);

    if (this.searchText) {
      const newQuery = getAlertsQueryPerSerialCodeAndSort(this.searchText, id, user);
      const newQueryWhereClause = Object.assign({}, newAlertQuery.where, newQuery.where);
      newAlertQuery.where = newQueryWhereClause;
    }

    // consider the advanced filter options here
    const advancedFilterOptions = this.advancedFilterOptions(this.filterOptions);
    const queryWhereClause = newAlertQuery.where;
    const newWhereClause = Object.assign({}, queryWhereClause, advancedFilterOptions);
    newAlertQuery.where = newWhereClause;

    setMonitoringFilter(id, selectedStatus);
    setAlertsQuery(newAlertQuery);
    getAlerts(newAlertQuery);
  }

  protected handleOnMultiSelectToggle(_isMulti: boolean) {
    const { selectedAlerts } = this.state;
    console.log('handleOnMultiSelectToggle', _isMulti);

    if (_isMulti) selectedAlerts.splice(0, selectedAlerts.length);
    this.setState({ isMulti: _isMulti });
  }

  protected executeRegisterAlertDetail(id: number, status: EAlertStatus, assigneeName?: string) {
    const {
      registerAlertDetail,
      servers: { user },
    } = this.props;
    console.log('executeRegisterAlertDetail', id, status, assigneeName, user);
    registerAlertDetail({
      status,
      alertId: id,
      userId: user.id,
      ...(assigneeName && { assigneeName }),
    });
  }

  protected handleOnAlertStatusToggle(status: EAlertStatus, status2?: EAlertStatus) {
    const {
      apps: {
        monitoringFilter: { selectedSortItem, selectedStatus },
      },
      setMonitoringFilter,
      setAlertsQuery,
      getAlerts,
      servers: { user },
    } = this.props;
    const newSelectedStatus = [...selectedStatus];
    console.log('newSelectedStatus before: ' + newSelectedStatus);
    if (newSelectedStatus.includes(status)) {
      newSelectedStatus.splice(newSelectedStatus.indexOf(status), 1);
      if (status2 && newSelectedStatus.includes(status2)) {
        newSelectedStatus.splice(newSelectedStatus.indexOf(status2), 1);
      }
    } else {
      newSelectedStatus.push(status);
      if (status2 && !newSelectedStatus.includes(status2)) {
        newSelectedStatus.push(status2);
      }
    }
    console.log('newSelectedStatus after: ' + newSelectedStatus);
    this.filterOptions && delete this.filterOptions.$or;
    let newAlertQuery = getAlertsQuery(selectedSortItem, newSelectedStatus, user);
    if (this.searchText) {
      const newQuery = getAlertsQueryPerSerialCodeAndSort(this.searchText, selectedSortItem, user);
      const newQueryWhereClause = Object.assign({}, newAlertQuery.where, newQuery.where);
      newAlertQuery.where = newQueryWhereClause;
    }
    const advancedFilterOptions = this.advancedFilterOptions(this.filterOptions);
    const queryWhereClause = newAlertQuery.where;
    const newWhereClause = Object.assign(
      {},
      queryWhereClause,
      this.filterOptions,
      advancedFilterOptions,
    );
    newAlertQuery.where = newWhereClause;

    setMonitoringFilter(selectedSortItem, newSelectedStatus);
    setAlertsQuery(newAlertQuery);
    getAlerts(newAlertQuery);
  }

  protected getContents(): JSX.Element {
    const {
      servers: { isAdditionalGetRequesting },
    } = this.props;
    return (
      <>
        {this.getAlertList()}
        {isAdditionalGetRequesting && <LinearProgress color='secondary' />}
      </>
    );
  }

  protected handleOnClick(serialId: number, alertId: number) {
    // const win = window.open(
    //   `${AppRoutePaths['monitoring-details'].link}/${serialId}/${alertId}`,
    //   '_blank',
    // );
    // win.focus();
    const { history } = this.props;
    return history.push(`${AppRoutePaths['monitoring-details'].link}/${serialId}/${alertId}`);
  }

  protected handleOnSelect(serialId: number, alertId: number, selected: boolean) {
    const { selectedAlerts } = this.state;
    if (selected) {
      selectedAlerts.push(alertId);
    } else {
      const index = selectedAlerts.indexOf(alertId);
      selectedAlerts.splice(index, 1);
    }
    console.log('handleOnSelect: ', selectedAlerts);
    this.forceUpdate();
  }

  protected getAlertList() {
    const {
      apps: { currentLanguage: lang },
      servers: { alerts, locations },
    } = this.props;

    const { isMulti, selectedAlerts } = this.state;

    let quality_alert_duration;

    if (!isOk(alerts)) return;

    return alerts.map((alertInfo, i) => {
      const { status, type, serialCode, alertAt, serial, id: alertId, alertLocId } = alertInfo;
      if (!isOk(alerts)) {
        return;
      }
      //let quality_alert_duration = 1;
      const distLoc = serial?.recvDistLoc || serial?.brewShipDistLoc;
      const restLoc = serial?.recvRestLoc || serial?.brewShipRestLoc || serial?.distShipRestLoc;
      const alertLocation =
        (alertLocId === distLoc?.id && distLoc) || (alertLocId === restLoc?.id && restLoc);

      const location_details = locations?.find((value) => {
        if (value.id == alertLocId) {
          return value.quality_alert_duration;
        }
      });
      if (location_details && location_details.quality_alert_duration) {
        quality_alert_duration = location_details.quality_alert_duration;
      } else quality_alert_duration = '';
      const title = [getCountryName(alertLocation?.country, lang), alertLocation?.name]
        .filter((name) => name)
        .join('_');

      return (
        <React.Fragment key={hash(i)}>
          <ListPageItem
            title={title}
            topIcon={getAlertStatusPill(status, lang)}
            alert={getAlertMessage(type, lang, quality_alert_duration)}
            subInfos={[
              { label: i18n.serialNo[lang], value: String(serialCode) },
              { label: i18n.alertDate[lang], value: formatDate(alertAt) },
            ]}
            endIcon={<Icon type='right' />}
            idForClick={serial?.id}
            idForAlert={alertId}
            isMulti={isMulti}
            selectedAlerts={selectedAlerts}
            onClick={(serialId) => this.handleOnClick(Number(serialId), alertId)}
            onSelected={(serialId, selected) =>
              this.handleOnSelect(Number(serialId), alertId, selected)
            }
          />
          {i === alerts.length - 1 && <Waypoint onEnter={() => this.loadNextDataSet()} />}
        </React.Fragment>
      );
    });
  }

  protected loadNextDataSet() {
    const {
      servers: { alertsQuery, alerts },
      setAlertsQuery,
      getAdditionalAlerts,
    } = this.props;
    const newQuery: IQuery = alertsQuery ? { ...alertsQuery } : { ...DefaultQuery };
    newQuery.offset = alerts.length;
    newQuery.limit = LIST_PAGE_SCROLL_LOAD_LIMIT;
    setAlertsQuery(newQuery);
    getAdditionalAlerts(newQuery);
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  auth: IStateAuth;
  history?: History<LocationState>;
  classes: any;
}

export interface IDispatchProps {
  getAlerts: (query: IQuery) => void;
  setAlertsQuery: (query: IQuery) => void;
  getAdditionalAlerts: (query: IQuery) => void;
  setMonitoringFilter: (selectedSortItem: number, selectedStatus: EAlertStatus[]) => void;
  setCurrentPage: (pageId: AllPages) => void;
  getAlertsCount: (query: IQuery) => void;
  getMasterLocations: (query: IQuery) => void;
  getLocationRegions: (query: IQuery) => void;
  setAlertFilterQuery: (filterOptions: any) => void;
  registerAlertDetail: (params: {
    alertId: number;
    status: EAlertStatus;
    userId: number;
    assigneeName?: string;
  }) => void;
}

interface State {
  locationsList: any;
  selectedAlerts: any;
  isMulti: boolean;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
  auth: state.auth,
});

const mapDispatchToProps: IDispatchProps = {
  getAlerts,
  setAlertsQuery,
  getAdditionalAlerts,
  setMonitoringFilter,
  setCurrentPage,
  getAlertsCount,
  getMasterLocations,
  getLocationRegions,
  setAlertFilterQuery,
  registerAlertDetail,
};

const myStyles = (): StyleRules => ({
  headerSpace: { paddingTop: '68px' },
  monitoringFilter: { paddingTop: '0px' },
});

export const Monitoring = compose<any>(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(MonitoringClass);
