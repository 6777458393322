import { ILocation } from '@models/location';
import { ISerial } from '@models/serial';
import { isOk } from '@utils/is-ok';

export function getCurrentLocations(targetSerial: ISerial): IGetCurrentLocations {
  // Location Info
  let currentBrewLoc = targetSerial?.brewLoc;
  let currentDistLoc = { ...(targetSerial?.recvDistLoc || targetSerial?.brewShipDistLoc) };
  let currentRestLoc = {
    ...(targetSerial?.recvRestLoc ||
      targetSerial?.brewShipRestLoc ||
      targetSerial?.distShipRestLoc),
  };

  currentDistLoc = !isOk(currentDistLoc) ? null : currentDistLoc;
  currentRestLoc = !isOk(currentRestLoc) ? null : currentRestLoc;

  return {
    currentBrewLoc,
    currentDistLoc,
    currentRestLoc,
  };
}

interface IGetCurrentLocations {
  currentBrewLoc: ILocation;
  currentDistLoc: ILocation;
  currentRestLoc: ILocation;
}
