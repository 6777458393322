/* eslint-disable no-case-declarations */
import { IQuery } from '@models/query';
import { isOk } from '@utils/is-ok';
import { Item, SelectedValues } from '@visual/search-filter-popup';

export function getWhereCondition(fields: Item[], selectedValues: SelectedValues) {
  const newWhereQuery: Partial<IQuery> = {};
  const externalFields: Partial<IQuery> = {};
  if (!selectedValues) return;
  Object.keys(selectedValues).forEach((key) => {
    if (!isOk(selectedValues[key])) return;
    const targetField = fields.find((item) => item.id === Number(key));
    let fieldValue;
    switch (targetField.type) {
      case 'date-range':
        const from = new Date(selectedValues[key][0].setHours(0, 0, 0));
        const to = new Date(selectedValues[key][1].setHours(23, 59, 59));
        fieldValue = {
          $gte: from.toISOString(),
          $lte: to.toISOString(),
        };
        break;
      case 'select':
        fieldValue = selectedValues[key];
        break;
      case 'text':
        fieldValue = targetField.isExact
          ? selectedValues[key]
          : { $like: `%${selectedValues[key]}%` };
        break;
      default:
        break;
    }

    if (targetField.isExternalField) {
      externalFields[targetField.fieldName] = fieldValue;
    } else {
      newWhereQuery[targetField.fieldName] = fieldValue;
    }
  });

  return { where: newWhereQuery, ...externalFields };
}
