import { NotificationRegister } from '@container/pages/notification-register';
import { RestaurantManagement } from '@container/pages/restaurant-management';
import { IQuery } from '@models/query';
import { IStateApps } from '@models/state-apps';
import { IStateAuth } from '@models/state-auth';
import { IStateServers } from '@models/state-servers';
import { Charts } from '@old-components/charts';
import { Header } from '@old-components/header';
import { Progress } from '@old-components/progress';
import { SnackbarMessage } from '@old-components/snackbar';
import {
  getAllBrands,
  getAllLocations,
  getAuthUser,
  registerLoginHistory,
} from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import { AppRoutePaths } from '@utils/app-route-paths';
import { isOk } from '@utils/is-ok';
import { ColoredBackground } from '@visual/colored-background';
import { PageContainer } from '@visual/page-container';
import { History, LocationState } from 'history';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';

class LayoutPCClass extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
  }

  public componentDidMount() {
    const {
      getAuthUser,
      getAllBrands,
      getAllLocations,
      registerLoginHistory,
      servers: { allLocations, allBrands, isLoginHistoryRegistered },
    } = this.props;

    getAuthUser();

    if (!allBrands) getAllBrands();
    if (!allLocations) getAllLocations();
    if (!isLoginHistoryRegistered) registerLoginHistory();
  }

  public render(): JSX.Element {
    const {
      apps: { nowSetting },
      servers: { user, allLocations, allBrands },
      auth: { isRequesting },
    } = this.props;
    if (nowSetting || isRequesting || !isOk(user) || !allLocations || !allBrands) {
      return <Progress />;
    }

    return (
      <>
        <Header />
        <PageContainer>
          <ColoredBackground>
            <Switch>
              <Route
                exact
                path={AppRoutePaths['restaurant-management'].path}
                component={RestaurantManagement}
              />
              <Route
                exact
                path={AppRoutePaths['notification-register'].path}
                component={NotificationRegister}
              />
              <Route exact path={AppRoutePaths['charts'].path} component={Charts} />
            </Switch>
          </ColoredBackground>
        </PageContainer>
        <SnackbarMessage />
      </>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  auth: IStateAuth;
  history?: History<LocationState>;
  classes: any;
}

export interface IDispatchProps {
  getAuthUser: () => void;
  getAllBrands: (query?: IQuery) => void;
  getAllLocations: (query?: IQuery) => void;
  registerLoginHistory: () => void;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
  auth: state.auth,
});

const mapDispatchToProps: IDispatchProps = {
  getAuthUser,
  getAllBrands,
  getAllLocations,
  registerLoginHistory,
};

export const LayoutPC = compose(connect(mapStateToProps, mapDispatchToProps))(
  withRouter(LayoutPCClass),
);
