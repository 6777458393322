import { StyleRules, Theme, withStyles } from '@material-ui/core';
import { AllPages } from '@models/all-pages';
import { IStateApps } from '@models/state-apps';
import { IStateServers } from '@models/state-servers';
import { setCurrentPage } from '@redux/actions/appsActions';
import { IStoreState } from '@redux/reducers';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Progress } from '../progress';
import ChatUsers from './utils/ChatUsers';

class ChatClass extends React.PureComponent<Props> {
  public componentDidMount() {
    this.props.setCurrentPage('chat');
  }

  public render(): JSX.Element {
    const { servers } = this.props;
    return <>{servers.user ? <ChatUsers /> : <Progress />}</>;
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  servers: IStateServers;
  apps: IStateApps;
  classes: any;
}

export interface IDispatchProps {
  setCurrentPage: (pageId: AllPages) => void;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  servers: state.servers,
  apps: state.apps,
});

const mapDispatchToProps: IDispatchProps = {
  setCurrentPage,
};

const myStyles = (theme: Theme): StyleRules => ({
  headerSpace: { paddingTop: '68px' },
});

export const Chat = compose(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(ChatClass);
