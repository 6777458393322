import { i18n } from '@i18n/lang';
import { ERestaurantProductPriceType } from '@models/restaurant-product-price-type';
import { TSupportedLanguages } from '@models/supported-languages';

export function getPriceTypeLangList(lang: TSupportedLanguages, country: string): CuisineType[] {
  let priceRange;
  switch (country) {
    case 'JP':
      priceRange = [
        {
          id: ERestaurantProductPriceType.UNKNOWN,
          name: i18n.select[lang],
        },
        {
          id: ERestaurantProductPriceType.LEVEL_1,
          name: 'under 10,000 JPY',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_2,
          name: '10,000~20,000 JPY',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_3,
          name: '20,000~30,000 JPY',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_4,
          name: '30,000~40,000 JPY',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_5,
          name: '40,000~50,000 JPY',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_6,
          name: '50,000~60,000 JPY',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_7,
          name: '60,000~70,000 JPY',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_8,
          name: '70,000~80,000 JPY',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_9,
          name: '80,000~90,000 JPY',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_10,
          name: '90,000~100,000 JPY',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_11,
          name: '100,000 + JPY',
        },
      ];
      break;
    case 'CN':
      priceRange = [
        {
          id: ERestaurantProductPriceType.UNKNOWN,
          name: i18n.select[lang],
        },
        {
          id: ERestaurantProductPriceType.LEVEL_1,
          name: 'under 500 CNY',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_2,
          name: '500~1,000 CNY',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_3,
          name: '1,000~1,500 CNY',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_4,
          name: '1,500~2,000 CNY',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_5,
          name: '2,000~2,500 CNY',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_6,
          name: '2,500~3,000 CNY',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_7,
          name: '3,000~3,500 CNY',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_8,
          name: '3,500~4,000 CNY',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_9,
          name: '4,000~4,500 CNY',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_10,
          name: '4,500~5,000 CNY',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_11,
          name: '5,000 + CNY',
        },
      ];
      break;
    case 'TW':
      priceRange = [
        {
          id: ERestaurantProductPriceType.UNKNOWN,
          name: i18n.select[lang],
        },
        {
          id: ERestaurantProductPriceType.LEVEL_1,
          name: 'under 2,000 TWD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_2,
          name: '2,000~4,000 TWD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_3,
          name: '4,000~6,000 TWD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_4,
          name: '6,000~8,000 TWD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_5,
          name: '8,000~10,000 TWD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_6,
          name: '10,000~12,000 TWD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_7,
          name: '12,000~14,000 TWD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_8,
          name: '14,000~16,000 TWD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_9,
          name: '16,000~18,000 TWD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_10,
          name: '18,000~20,000 TWD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_11,
          name: '20,000 + TWD',
        },
      ];
      break;
    case 'KR':
      priceRange = [
        {
          id: ERestaurantProductPriceType.UNKNOWN,
          name: i18n.select[lang],
        },
        {
          id: ERestaurantProductPriceType.LEVEL_1,
          name: 'under 100,000 KRW',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_2,
          name: '100,000~200,000 KRW',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_3,
          name: '200,000~300,000 KRW',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_4,
          name: '300,000~400,000 KRW',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_5,
          name: '400,000~500,000 KRW',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_6,
          name: '500,000~600,000 KRW',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_7,
          name: '600,000~700,000 KRW',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_8,
          name: '700,000~800,000 KRW',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_9,
          name: '800,000~900,000 KRW',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_10,
          name: '900,000~1,000,000 KRW',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_11,
          name: '1,000,000 + KRW',
        },
      ];
      break;
    case 'HK':
      priceRange = [
        {
          id: ERestaurantProductPriceType.UNKNOWN,
          name: i18n.select[lang],
        },
        {
          id: ERestaurantProductPriceType.LEVEL_1,
          name: 'under 500 HKD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_2,
          name: '500~1,000 HKD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_3,
          name: '1,000~1,500 HKD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_4,
          name: '1,500~2,000 HKD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_5,
          name: '2,000~2,500 HKD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_6,
          name: '2,500~3,000 HKD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_7,
          name: '3,000~3,500 HKD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_8,
          name: '3,500~4,000 HKD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_9,
          name: '4,000~4,500 HKD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_10,
          name: '4,500~5,000 HKD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_11,
          name: '5,000 + HKD',
        },
      ];
      break;
    case 'SG':
      priceRange = [
        {
          id: ERestaurantProductPriceType.UNKNOWN,
          name: i18n.select[lang],
        },
        {
          id: ERestaurantProductPriceType.LEVEL_1,
          name: 'under 100 SGD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_2,
          name: '100~200 SGD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_3,
          name: '200~300 SGD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_4,
          name: '300~400 SGD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_5,
          name: '400~500 SGD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_6,
          name: '500~600 SGD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_7,
          name: '600~700 SGD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_8,
          name: '700~800 SGD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_9,
          name: '800~900 SGD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_10,
          name: '900~1,000 SGD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_11,
          name: '1,000 + SGD',
        },
      ];
      break;
    case 'TH':
      priceRange = [
        {
          id: ERestaurantProductPriceType.UNKNOWN,
          name: i18n.select[lang],
        },
        {
          id: ERestaurantProductPriceType.LEVEL_1,
          name: 'under 2,000 THB',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_2,
          name: '2,000~4,500 THB',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_3,
          name: '4,500~7,000 THB',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_4,
          name: '7,000~9,500 THB',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_5,
          name: '9,500~12,000 THB',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_6,
          name: '12,000~14,500 THB',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_7,
          name: '14,500~17,000 THB',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_8,
          name: '17,000~19,500 THB',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_9,
          name: '19,500~22,000 THB',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_10,
          name: '22,000~24,500 THB',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_11,
          name: '24,500 + THB',
        },
      ];
      break;
    case 'VN':
      priceRange = [
        {
          id: ERestaurantProductPriceType.UNKNOWN,
          name: i18n.select[lang],
        },
        {
          id: ERestaurantProductPriceType.LEVEL_1,
          name: 'under 1,500,000 VND',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_2,
          name: '1,500,000~3,000,000 VND',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_3,
          name: '3,000,000~4,500,000 VND',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_4,
          name: '4,500,000~6,000,000 VND',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_5,
          name: '6,000,000~7,500,000 VND',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_6,
          name: '7,500,000~9,000,000 VND',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_7,
          name: '9,000,000~10,500,000 VND',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_8,
          name: '10,500,000~13,000,000 VND',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_9,
          name: '13,000,000~14,500,000 VND',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_10,
          name: '14,500,000~16,000,000 VND',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_11,
          name: '16,000,000 + VND',
        },
      ];
      break;
    default:
      priceRange = [
        {
          id: ERestaurantProductPriceType.UNKNOWN,
          name: i18n.select[lang],
        },
        {
          id: ERestaurantProductPriceType.LEVEL_1,
          name: 'under 100 USD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_2,
          name: '100~200 USD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_3,
          name: '200~300 USD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_4,
          name: '300~400 USD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_5,
          name: '400~500 USD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_6,
          name: '500~600 USD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_7,
          name: '600~700 USD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_8,
          name: '700~800 USD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_9,
          name: '800~900 USD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_10,
          name: '900~1,000 USD',
        },
        {
          id: ERestaurantProductPriceType.LEVEL_11,
          name: '1,000 + USD',
        },
      ];
      break;
  }

  return priceRange;
}

interface CuisineType {
  id: ERestaurantProductPriceType;
  name: string;
}
