import { Dialog, DialogContent } from '@material-ui/core';
import { TSupportedLanguages } from '@models/supported-languages';
import { LANGUAGE_DEFAULT } from '@utils/common';
import { isOk } from '@utils/is-ok';
import { Icon, IconTypes } from '@visual/icon';
import { MapMarker } from '@visual/map-marker';
import GoogleMapReact from 'google-map-react';
import hash from 'object-hash';
import React from 'react';
import styles from './style.scss';

export class MapView extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = {
    lang: LANGUAGE_DEFAULT,
    zoom: 15,
    isModal: false,
    showPopup: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      center: this.getCenterPoint(),
      map: null,
      maps: null,
      distance: 0,
      zoom: props.zoom,
    };
  }

  public render(): JSX.Element {
    const { isModal, showPopup, onClose } = this.props;
    if (isModal) {
      return (
        <Dialog open={showPopup} onClose={onClose} fullWidth maxWidth='lg'>
          <DialogContent style={{ padding: 0 }}>
            <div className={styles.mapView}>{this.getMap()}</div>
          </DialogContent>
        </Dialog>
      );
    }
    return <div className={styles.mapView}>{this.getMap()}</div>;
  }

  protected getMap(): JSX.Element {
    const { lang, apiKey } = this.props;
    const { center, zoom } = this.state;
    return (
      <GoogleMapReact
        resetBoundsOnResize
        bootstrapURLKeys={{
          key: apiKey,
          language: lang,
        }}
        zoom={zoom}
        center={center}
        onGoogleApiLoaded={({ map, maps }) => this.handleGoogleApiLoaded(map, maps)}
      >
        {this.getMarkers()}
      </GoogleMapReact>
    );
  }

  protected handleGoogleApiLoaded(map, maps) {
    const { coordinates } = this.props;
    if (!isOk(coordinates)) return;
    this.setState({ map, maps });
    if (!map || !maps) return;

    const markers = coordinates.map(
      (coordinate) => new maps.LatLng(coordinate.coord.lat, coordinate.coord.lng),
    );

    this.setPolyline(markers);
    const distance = this.getDistance(markers[0], markers[markers.length - 1]);
    let zoom = 15;
    if (distance > 100000) zoom = 6; // 10km and above
    if (distance > 500000) zoom = 5; // 50km and above
    if (distance > 1000000) zoom = 4; // 100 and above

    this.setState({ distance, zoom });
  }

  protected setPolyline(path) {
    const { maps, map } = this.state;
    if (!maps || !map) return null;
    return new maps.Polyline({
      path,
      geodesic: true,
      map,
      strokeColor: '#cb0000',
      strokeOpacity: 0.6,
      strokeWeight: 1.5,
    });
  }

  protected setMarker(coordinates: MapViewCoordinate) {
    const { maps, map } = this.state;
    const lat = coordinates?.coord?.lat;
    const lng = coordinates?.coord?.lng;
    const label = coordinates?.label;
    const icon = coordinates?.icon && <Icon type={coordinates.icon} />;

    if (!maps || !map || !lat || !lng) return null;

    const position = { lat, lng };

    const newMarker = icon
      ? new maps.Marker({ position, map, icon })
      : new maps.Marker({ position, map });

    if (label) {
      const myInfoWindow = new maps.InfoWindow({ label });
      myInfoWindow.open(map, newMarker);
      maps.event.addListener(myInfoWindow, 'closeclick', () => {
        maps.event.addListenerOnce(newMarker, 'click', () => {
          myInfoWindow.open(map, newMarker);
        });
      });
    }

    return newMarker;
  }

  protected getDistance(fromPosition, toPosition) {
    const { maps } = this.state;
    if (!maps || !fromPosition || !toPosition) return 0;
    const { geometry } = maps;
    const { spherical } = geometry;
    const d = spherical.computeDistanceBetween(fromPosition, toPosition);
    return d ? parseInt(d) || 0 : 0;
  }

  protected getMarkers(): JSX.Element[] {
    const { coordinates } = this.props;
    if (!isOk(coordinates)) return;
    return coordinates.map((coordinate, i) => {
      const { coord, icon: type, label, secondLabel } = coordinate;
      const markerProp = { ...coord, type, label, secondLabel };
      return <MapMarker key={hash(i)} {...markerProp} />;
    });
  }

  protected getCenterPoint(): GoogleMapReact.Coords {
    const { coordinates } = this.props;
    const totalCoords = coordinates.reduce(
      (res, coordinate) => {
        const lat = coordinate?.coord?.lat;
        const lng = coordinate?.coord?.lng;
        if (!isOk(lat) || !isOk(lng)) return res;
        res.lat += lat;
        res.lng += lng;
        res.total += 1;
        return res;
      },
      { lat: 0, lng: 0, total: 0 },
    );
    if (!isOk(totalCoords)) {
      return coordinates[0]?.coord;
    }
    return { lat: totalCoords.lat / totalCoords.total, lng: totalCoords.lng / totalCoords.total };
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apiKey: string;
  lang?: TSupportedLanguages;
  zoom?: number;
  center?: GoogleMapReact.Coords;
  defaultText?: string;
  coordinates?: MapViewCoordinate[];
  isModal?: boolean;
  showPopup?: boolean;
}

export interface IDispatchProps {
  onClose?: () => void;
}

export interface MapViewCoordinate {
  coord: GoogleMapReact.Coords;
  icon: IconTypes;
  label?: string;
  secondLabel?: string;
}

interface State {
  center: GoogleMapReact.Coords;
  map: any;
  maps: any;
  distance: number;
  zoom: number;
}
