import React from 'react';
import styles from './style.scss';

export class Logo extends React.Component<Props> {
  public render(): JSX.Element {
    const { onClick, type, style } = this.props;
    return (
      <div
        style={style}
        className={`${styles.logo} ${styles[type]} ${onClick && styles.clickable}`}
        onClick={onClick}
      />
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IDispatchProps {
  onClick?: () => void;
}

export interface IStateProps {
  type: Type;
  style?: React.CSSProperties;
}

export type Type =
  | 'fab-board'
  | 'fab-cancel'
  | 'fab-config'
  | 'fab-edit'
  | 'fab-info'
  | 'fab-map'
  | 'fab-ok'
  | 'fab-page-top'
  | 'fab-request'
  | 'fab-local-time-2'
  | 'fab-local-time'
  | 'fab-chat'
  | 'no-image'
  | 'logo-white'
  | 'logo-gold';
