import { withStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#fff',
  },
  barColorPrimary: {
    backgroundColor: '#fff',
  },
})(LinearProgress);

export default ColorLinearProgress;
