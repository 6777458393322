export enum ESerialStatus {
  //酒蔵出荷済み
  SHIPPED_FROM_BREWERY = 1,
  //酒販店入荷済み
  DISTRIBUTOR_INSTOCK = 2,
  //酒販店出荷済み
  DISTRIBUTOR_DISPATCHED = 3,
  //レストラン入荷済み
  RESTAURANT_INSTOCK = 4,
  //消費済み
  PURCHASED = 5,
}
