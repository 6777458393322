import DateFnsUtils from '@date-io/date-fns';
import { i18n } from '@i18n/lang';
import {
  AppBar,
  Box,
  Card,
  CardMedia,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { Videocam as VideocamIcon, VideocamOff as VideocamOffIcon } from '@material-ui/icons';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { EAlert } from '@models/alert-type';
import { ECategoryType } from '@models/category-type';
import { TSupportedLanguages } from '@models/supported-languages';
import { extractSerialFromUrl, LANGUAGE_DEFAULT } from '@utils/common';
import { formatString } from '@utils/format-string';
import { isOk } from '@utils/is-ok';
import { Button } from '@visual/button';
import { CardButton } from '@visual/card-button';
import { Icon } from '@visual/icon';
import { InputText } from '@visual/input-text';
import { Item as SelectBoxItem, SelectBox } from '@visual/select-box';
import { TextDisplay } from '@visual/text-display';
import React from 'react';
import TabPanel, { a11yProps } from '../../../elements/TabPanel';
import Reader from './lib'; // Wrapper for QrReader
import styles from './style.scss';

const DEFAULT_VALUE = 0;
const storage = window.localStorage;

export class SerialInformationEditPopup extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = {
    lang: LANGUAGE_DEFAULT,
    showPopup: false,
    showShippingDestEdit: false,
  };

  protected cameraSwitchKey;
  protected tabValueKey;

  constructor(props: Props) {
    super(props);
    this.cameraSwitchKey = 'SerialQRScan.cameraSwitch';
    this.tabValueKey = 'SerialQRScan.tabValue';
    this.state = {
      view: 'action-select',
      selectedDistId: props.selectedDistId,
      selectedRestId: props.selectedRestId,
      selectedDistRange: props.selectedDistRange,
      selectedRestRange: props.selectedRestRange,
      tabValue: parseInt(storage.getItem(this.tabValueKey)) || 0,
      cameraSwitch: storage.getItem(this.cameraSwitchKey) || 'on',
      handleChangeScannedSerialsStrTimeout: undefined,
      updateScannedSerialsTimeout: undefined,
      handleScanTimeout: undefined,
      scannedSerialCodesStr: '',
      serialCode: '',
      scanIntervalTime: 0,
      cameraId: undefined,
      devices: [],
      slipNo: '',
    };
  }
  protected selectCamera = () => {
    return this.state.cameraId;
  };
  protected initState() {
    this.setState({
      scannedSerialCodesStr: '',
    });
  }
  protected handleChangeTabValue(v) {
    const { tabValue } = this.state;
    if (tabValue === v) return;
    this.setState({ tabValue: v });
    storage.setItem(this.tabValueKey, `${v}`);
    setTimeout(() => this.initState(), 1);
    // setTimeout(() => this.clearScannedData(), 1);
  }

  public async componentDidMount() {
    // Get the list of video devices associated with the device
    if (navigator) {
      // request camera permission
      await navigator.mediaDevices
        .getUserMedia({ audio: false, video: { facingMode: 'environment' } })
        .then(function (stream) {
          stream.getTracks().forEach((x) => x.stop());
        })
        .catch((error) => {
          console.log(error);
        });
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          const videoSelect = [];
          devices.forEach((device) => {
            if (device.kind === 'videoinput') {
              videoSelect.push(device);
            }
          });
          const sortedVideo = [];
          let frontCamera;
          videoSelect.forEach((device, i) => {
            if (i !== 0) {
              sortedVideo.push(device);
            } else if (i == 0) {
              frontCamera = device;
            }
          });
          frontCamera && sortedVideo.push(frontCamera);
          return sortedVideo;
        })
        .then((devices) => {
          this.setState({
            cameraId: devices[0].deviceId,
            devices,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  public render(): JSX.Element {
    const { showPopup, lang } = this.props; // ,close
    const { view } = this.state;
    let contentView;
    let title;

    switch (view) {
      case 'location-distance-edit':
        contentView = this.getLocationDistanceEditView();
        title = i18n.editScanRangeDistance[lang];
        break;
      case 'location-edit':
        contentView = this.getLocationEditView();
        title = i18n.editShippingDestination[lang];
        break;
      case 'cancel-delivery':
        contentView = this.getCancelDeliveryView();
        title = i18n.cancelDelivery[lang];
        break;
      case 'notify-not-delivered':
        contentView = this.getNotifyNotDeliveredView();
        title = i18n.notifyNotDelivered[lang];
        break;
      case 'register-as-sold':
        contentView = this.getRegisterAsSoldView();
        title = i18n.registerAsSold[lang];
        break;
      case 'register-as-in-stock':
        contentView = this.getRegisterAsInStockView();
        title = i18n.registerAsInStock[lang];
        break;
      case 'notify-not-delivered-done':
        contentView = this.getNotifyNotDeliveredDoneView();
        title = i18n.notifyNotDelivered[lang];
        break;
      case 'register-as-sold-done':
        contentView = this.getRegisterAsSoldDoneView();
        title = i18n.registerAsSold[lang];
        break;
      case 'register-as-in-stock-done':
        contentView = this.getRegisterAsInStockDoneView();
        title = i18n.registerAsInStock[lang];
        break;
      case 'register-as-in-stock-done-error':
        contentView = this.getRegisterAsInStockDoneErrorView();
        title = i18n.registerAsInStock[lang];
        break;
      case 'location-edit-slip-number':
        contentView = this.getLocationEditSlipNumberView();
        title = i18n.editShippingDestinationSlipNumber[lang];
        break;
      case 'sales-edit':
        contentView = this.getSaleEditView();
        title = i18n.addSalesRecord[lang];
        break;
      default:
        contentView = this.getActionSelectView();
        title = i18n.editDistributionData[lang];
        break;
    }
    // onClose={() => onClose()}
    return (
      <Dialog open={showPopup}>
        <DialogContent style={{ padding: 0 }}>
          <div className={styles.serialLocationEditPopup}>
            <div className={styles.title}>
              <TextDisplay color='black' align='center' weight='bold'>
                {title}
              </TextDisplay>
            </div>
            {contentView}
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  protected toggleView(view: View) {
    this.setState({ view });
  }

  protected getLocationDistanceEditView(): JSX.Element {
    const { lang, distRangeItems, restRangeItems } = this.props;
    const { selectedDistRange, selectedRestRange } = this.state;

    let distPlaceHolder;
    let restPlaceHolder;
    if (isOk(distRangeItems) && isOk(restRangeItems)) {
      distPlaceHolder = formatString(i18n.selectRangeDistance[lang], {
        field: i18n.distributor[lang],
      });
      restPlaceHolder = formatString(i18n.selectRangeDistance[lang], {
        field: i18n.restaurant[lang],
      });
    } else {
      distPlaceHolder = formatString(i18n.selectRangeDistance[lang], { field: '' });
      restPlaceHolder = formatString(i18n.selectRangeDistance[lang], { field: '' });
    }

    const distributorSelectBox = isOk(distRangeItems) && (
      <div className={styles.element}>
        <SelectBox
          size='full'
          items={distRangeItems}
          placeholder={distPlaceHolder}
          selectedValue={selectedDistRange}
          onChange={(value) => this.handleDistRangeChange(Number(value))}
        />
      </div>
    );

    const restaurantSelectBox = isOk(restRangeItems) && (
      <div className={styles.element}>
        <SelectBox
          size='full'
          items={restRangeItems}
          placeholder={restPlaceHolder}
          selectedValue={selectedRestRange}
          onChange={(value) => this.handleRestRangeChange(Number(value))}
        />
      </div>
    );
    const registerButtonEnable = isOk(selectedDistRange) || isOk(selectedRestRange);

    return (
      <>
        <div className={styles.contentsContainer}>
          {distributorSelectBox}
          {restaurantSelectBox}
        </div>
        <div>
          <div className={styles.element}>
            <Button
              size='full'
              onClick={() => this.handleOnLocationRangeClick()}
              enabled={registerButtonEnable}
            >
              {i18n.runUpdate[lang]}
            </Button>
          </div>
          <div className={styles.element}>
            <Button size='full' theme='secondary' onClick={() => this.toggleView('action-select')}>
              {i18n.return[lang]}
            </Button>
          </div>
        </div>
      </>
    );
  }

  protected handleDistRangeChange(selectedDistRange: number) {
    this.setState({
      selectedDistRange,
    });
  }

  protected handleRestRangeChange(selectedRestRange: number) {
    this.setState({
      selectedRestRange,
    });
  }

  protected getLocationEditView(): JSX.Element {
    const { lang, distLocationItems, restLocationItems, alertLocType } = this.props;
    const { selectedDistId, selectedRestId } = this.state;

    let distPlaceHolder;
    let restPlaceHolder;
    if (isOk(distLocationItems) && isOk(restLocationItems)) {
      distPlaceHolder = formatString(i18n.selectShippingDestination[lang], {
        field: i18n.distributor[lang],
      });
      restPlaceHolder = formatString(i18n.selectShippingDestination[lang], {
        field: i18n.restaurant[lang],
      });
    } else {
      distPlaceHolder = formatString(i18n.selectShippingDestination[lang], { field: '' });
      restPlaceHolder = formatString(i18n.selectShippingDestination[lang], { field: '' });
    }

    //Sreenath 30Nov21- disable/enable process button
    // Disable the already selected selectedDistId/selectedRestId based on the alertLocType value
    // process button disabled if the selected value is the DEFAULT_VALUE
    let disableOption = DEFAULT_VALUE;
    let registerButtonEnable = false;
    if (alertLocType === ECategoryType.DISTRIBUTOR) {
      if (selectedDistId !== DEFAULT_VALUE && selectedDistId !== this.props.selectedDistId) {
        registerButtonEnable = true;
      }
      disableOption = this.props.selectedDistId;
    } else if (alertLocType === ECategoryType.RESTAURANT) {
      if (selectedRestId !== DEFAULT_VALUE && selectedRestId !== this.props.selectedRestId) {
        registerButtonEnable = true;
      }
      disableOption = this.props.selectedRestId;
    }

    const distributorSelectBox = isOk(distLocationItems) && (
      <div className={styles.element}>
        <SelectBox
          size='full'
          items={distLocationItems}
          placeholder={distPlaceHolder}
          selectedValue={selectedDistId}
          disableOption={disableOption}
          onChange={(value) => this.handleDistLocationChange(value)}
        />
      </div>
    );
    const restaurantSelectBox = isOk(restLocationItems) &&
      alertLocType !== ECategoryType.DISTRIBUTOR && (
        <div className={styles.element}>
          <SelectBox
            size='full'
            items={restLocationItems}
            placeholder={restPlaceHolder}
            selectedValue={selectedRestId}
            disableOption={disableOption}
            onChange={(value) => this.handleRestLocationChange(value)}
          />
        </div>
      );

    return (
      <>
        <div className={styles.contentsContainer}>
          {distributorSelectBox}
          {restaurantSelectBox}
        </div>
        <div>
          <div className={styles.element}>
            <Button
              size='full'
              onClick={() => this.handleOnLocationEditClick()}
              enabled={registerButtonEnable}
            >
              {i18n.runUpdate[lang]}
            </Button>
          </div>
          <div className={styles.element}>
            <Button size='full' theme='secondary' onClick={() => this.toggleView('action-select')}>
              {i18n.return[lang]}
            </Button>
          </div>
        </div>
      </>
    );
  }

  protected handleSlipNoChange(title: string | number) {
    this.setState({
      slipNo: String(title),
    });
  }

  protected getLocationEditSlipNumberView(): JSX.Element {
    const { lang, alertLocType } = this.props;
    const { selectedDistId, selectedRestId, slipNo } = this.state;
    console.log('selectedDistId, selectedRestId', selectedDistId, selectedRestId);

    //Sreenath 30Nov21- disable/enable process button
    // Disable the already selected selectedDistId/selectedRestId based on the alertLocType value
    // process button disabled if the selected value is the DEFAULT_VALUE
    // let disableOption = DEFAULT_VALUE;
    let registerButtonEnable = false;
    if (alertLocType === ECategoryType.DISTRIBUTOR) {
      if (selectedDistId !== DEFAULT_VALUE && selectedDistId !== this.props.selectedDistId) {
        registerButtonEnable = true;
      }
      // disableOption = this.props.selectedDistId;
    } else if (alertLocType === ECategoryType.RESTAURANT) {
      if (selectedRestId !== DEFAULT_VALUE && selectedRestId !== this.props.selectedRestId) {
        registerButtonEnable = true;
      }
      // disableOption = this.props.selectedRestId;
    }

    const slipNumberInputText = (
      <div className={styles.element}>
        <InputText
          size='full'
          placeholder={'Slip No'}
          onChange={(slipno) => this.handleSlipNoChange(slipno)}
        ></InputText>
      </div>
    );
    return (
      <>
        <div className={styles.contentsContainer}>{slipNumberInputText}</div>
        <div>
          <div className={styles.element}>
            <Button
              size='full'
              onClick={() => this.handleOnLocationEditSlipNumberClick()}
              enabled={registerButtonEnable && slipNo != ''}
            >
              {i18n.confirm[lang]}
            </Button>
          </div>
          <div className={styles.element}>
            <Button size='full' theme='secondary' onClick={() => this.toggleView('location-edit')}>
              {i18n.return[lang]}
            </Button>
          </div>
        </div>
      </>
    );
  }

  protected getNotifyNotDeliveredView(): JSX.Element {
    const { lang } = this.props;

    const cancelDeliveryConfirmText = (
      <div className={styles.element}>
        <TextDisplay color='black' align='center'>
          {i18n.confirmNotifyNotDelivered[lang]}
        </TextDisplay>
      </div>
    );
    return (
      <>
        <div className={styles.contentsContainer}>{cancelDeliveryConfirmText}</div>
        <div>
          <div className={styles.element}>
            <Button size='full' onClick={() => this.handleNotifyNotDeliveredClick()}>
              {i18n.confirm[lang]}
            </Button>
          </div>
          <div className={styles.element}>
            <Button size='full' theme='secondary' onClick={() => this.toggleView('action-select')}>
              {i18n.return[lang]}
            </Button>
          </div>
        </div>
      </>
    );
  }

  protected getRegisterAsSoldView(): JSX.Element {
    const { lang } = this.props;

    const cancelDeliveryConfirmText = (
      <div className={styles.element}>
        <TextDisplay color='black' align='center'>
          {i18n.confirmRegisterAsSold[lang]}
        </TextDisplay>
      </div>
    );
    return (
      <>
        <div className={styles.contentsContainer}>{cancelDeliveryConfirmText}</div>
        <div>
          <div className={styles.element}>
            <Button size='full' onClick={() => this.handleRegisterSoldClick()}>
              {i18n.confirm[lang]}
            </Button>
          </div>
          <div className={styles.element}>
            <Button size='full' theme='secondary' onClick={() => this.toggleView('action-select')}>
              {i18n.return[lang]}
            </Button>
          </div>
        </div>
      </>
    );
  }
  protected getTabSwitcher(isNowLoading: boolean): JSX.Element {
    const { tabValue } = this.state;
    const { lang } = this.props;
    // const userCategory = getUserCategory(server?.user);
    // const isBrew = userCategory === ECategoryType.BREWERY;
    const isBrew = false;

    return (
      <AppBar position='static' color='primary'>
        <Tabs
          value={tabValue}
          variant='scrollable'
          scrollButtons='auto'
          onChange={(_, v) => this.handleChangeTabValue(v)}
        >
          {!isBrew &&
            [
              <Tab
                disabled={isNowLoading}
                label={<Typography variant='caption'>{i18n.camera[lang]}</Typography>}
                {...a11yProps(0)}
              />,
              true && (
                <Tab
                  disabled={isNowLoading}
                  label={<Typography variant='caption'>{i18n.externalQR[lang]}</Typography>}
                  {...a11yProps(1)}
                />
              ),
            ].filter((tab) => tab)}
        </Tabs>
      </AppBar>
    );
  }
  protected getScanWithInterQRCameraView(isNowLoading: boolean): JSX.Element {
    const { lang } = this.props;
    const { cameraSwitch, handleScanTimeout } = this.state;
    const { cameraId, devices } = this.state;
    return (
      <>
        {!handleScanTimeout ? (
          <Typography variant='caption' color='textSecondary'>
            {i18n.pleaseBringTheQRCodeCloserToTheCamera[lang]}
          </Typography>
        ) : (
          <Typography variant='caption' color='textSecondary'>
            {i18n.reading[lang]}
          </Typography>
        )}
        <Paper>
          <Grid container justify='flex-start' alignContent='flex-start' alignItems='flex-start'>
            <Grid item xs={12}>
              <ToggleButtonGroup
                size='small'
                exclusive
                value={cameraSwitch}
                onChange={(e, v) => {
                  this.setState({ cameraSwitch: v });
                  storage.setItem(this.cameraSwitchKey, v);
                }}
              >
                <ToggleButton value='on'>
                  <VideocamIcon fontSize='small' />
                </ToggleButton>
                <ToggleButton value='off'>
                  <VideocamOffIcon fontSize='small' />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12}>
              <Box mt={1} />
            </Grid>
          </Grid>
          <Grid container justify='center' alignContent='center' alignItems='center'>
            <Grid>
              {
                // UI Componet to choose camera for scanning.
                devices.length && (
                  <select
                    onChange={(e) => {
                      const value = e.target.value;
                      this.setState({ cameraId: undefined }, () => {
                        this.setState({ cameraId: value });
                      });
                    }}
                  >
                    {devices.map((deviceInfo, index) => (
                      <React.Fragment key={deviceInfo.deviceId}>
                        <option value={deviceInfo.deviceId}>{`Camera ${index}`}</option>
                      </React.Fragment>
                    ))}
                  </select>
                )
              }
              <Paper elevation={3}>
                {isNowLoading || cameraSwitch !== 'on' ? (
                  <Card>
                    <CardMedia image='img/black.jpeg' title='...' />
                  </Card>
                ) : (
                  <div>
                    {cameraId && (
                      <Reader
                        chooseDeviceId={this.selectCamera}
                        delay={100}
                        resolution={1000}
                        style={{ width: '100%', height: 200 }}
                        onScan={(data) => this.handleScan(data)}
                        onError={(err) => window.console.warn('Failed scan QR.', err)}
                      />
                    )}
                  </div>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
  protected handleScan(data) {
    if (!data) return;
    const { handleScanTimeout, updateScannedSerialsTimeout } = this.state;
    const { onCheckSerialCodeMatch } = this.props;
    const isNowLoading = Boolean(handleScanTimeout || updateScannedSerialsTimeout);
    console.log('isNowLoading', isNowLoading);
    if (isNowLoading) return;
    const url = data.trim();
    console.log('URL before serial code extraction', url);
    const serialCode = extractSerialFromUrl(url);
    if (serialCode && onCheckSerialCodeMatch(serialCode)) {
      console.log('this.addSerialCode', serialCode);
      this.setState({
        serialCode: serialCode,
      });
      this.toggleView('register-as-in-stock-done'); // record the serialCode and use it in the Done function API
    } else {
      console.log('this.addSerialCode error(undefined)');
      this.toggleView('register-as-in-stock-done-error');
    }

    // const intervalTimeMSec = scanIntervalTime * 1000;
    // const _finish = () =>
    //   setTimeout(() => this.setState({ handleScanTimeout: undefined }), intervalTimeMSec);

    // const timeout = setTimeout(() => {
    //   try {
    //     const { lang } = this.props;
    //     const url = data.trim();
    //     console.log('URL before serial code extraction', url);
    //     const serialCode = extractSerialFromUrl(url);
    //     if (serialCode) {
    //       console.log('this.addSerialCode', serialCode);
    //       // const { scannedSerialCodes } = apps;
    //       // if (scannedSerialCodes.filter((s) => s === serialCode).length) {
    //       //   _finish();
    //       //   return;
    //       // }
    //       // this.addSerialCode(serialCode);
    //       console.log('this.addSerialCode', serialCode);
    //       _finish();
    //     } else {
    //       openSnackbarMessage('error', i18n.invalidQRCode[lang]);
    //       _finish();
    //     }
    //   } catch (error) {
    //     alert('Invalid QR');
    //   }
    // }, 100);
    // this.setState({ handleScanTimeout: timeout });
  }
  protected getRegisterAsInStockView(): JSX.Element {
    const { lang } = this.props;
    const {
      handleScanTimeout,
      handleChangeScannedSerialsStrTimeout,
      updateScannedSerialsTimeout,
      tabValue,
    } = this.state;
    const isNowLoading = Boolean(
      handleChangeScannedSerialsStrTimeout || updateScannedSerialsTimeout || handleScanTimeout,
      // isGetRequesting ||
      // isRequesting,
    );
    return (
      <>
        {/* <div className={styles.contentsContainer}>{cancelDeliveryConfirmText}</div> */}
        <div>
          <Paper elevation={3}>
            {/* {this.getTabSwitcher(isNowLoading)} */}
            {true && [
              <TabPanel value={tabValue} index={0}>
                {tabValue === 0 ? this.getScanWithInterQRCameraView(isNowLoading) : <Box />}
              </TabPanel>,
            ]}
          </Paper>
          <div className={styles.element}>
            <Button size='full' theme='secondary' onClick={() => this.toggleView('action-select')}>
              {i18n.return[lang]}
            </Button>
          </div>
        </div>
      </>
    );
  }
  protected getNotifyNotDeliveredDoneView(): JSX.Element {
    const { lang } = this.props;

    const cancelDeliveryConfirmText = (
      <div className={styles.element}>
        <TextDisplay color='black' align='center'>
          {i18n.notifyDistributorDone[lang]}
        </TextDisplay>
      </div>
    );
    return (
      <>
        <div className={styles.contentsContainer}>{cancelDeliveryConfirmText}</div>
        <div>
          <div className={styles.element}>
            <Button
              size='full'
              theme='secondary'
              onClick={() => this.handleOnNotifyNotDeliveredClick()}
            >
              {i18n.ok[lang]}
            </Button>
          </div>
        </div>
      </>
    );
  }

  protected getRegisterAsSoldDoneView(): JSX.Element {
    const { lang } = this.props;

    const cancelDeliveryConfirmText = (
      <div className={styles.element}>
        <TextDisplay color='black' align='center'>
          {i18n.notifyDistributorDone[lang]}
        </TextDisplay>
      </div>
    );
    return (
      <>
        <div className={styles.contentsContainer}>{cancelDeliveryConfirmText}</div>
        <div>
          <div className={styles.element}>
            <Button
              size='full'
              theme='secondary'
              onClick={() => this.handleOnRegisterAsSoldClick()}
            >
              {i18n.ok[lang]}
            </Button>
          </div>
        </div>
      </>
    );
  }

  protected getRegisterAsInStockDoneErrorView(): JSX.Element {
    const { lang } = this.props;
    // const { serialCode } = this.state;

    const cancelDeliveryConfirmText = (
      <div className={styles.element}>
        <TextDisplay color='black' align='center'>
          {i18n.registerAsInStockError[lang]}
        </TextDisplay>
      </div>
    );
    return (
      <>
        <div className={styles.contentsContainer}>{cancelDeliveryConfirmText}</div>
        <div>
          <div className={styles.element}>
            <Button
              size='full'
              theme='secondary'
              onClick={() => this.handleOnRegisterAsInStockErrorClick()}
            >
              {i18n.ok[lang]}
            </Button>
          </div>
        </div>
      </>
    );
  }
  protected getRegisterAsInStockDoneView(): JSX.Element {
    const { lang } = this.props;
    // const { serialCode } = this.state;

    const cancelDeliveryConfirmText = (
      <div className={styles.element}>
        <TextDisplay color='black' align='center'>
          {i18n.registerAsInStockDone[lang]}
        </TextDisplay>
      </div>
    );
    return (
      <>
        <div className={styles.contentsContainer}>{cancelDeliveryConfirmText}</div>
        <div>
          <div className={styles.element}>
            <Button
              size='full'
              theme='secondary'
              onClick={() => this.handleOnRegisterAsInStockClick()}
            >
              {i18n.ok[lang]}
            </Button>
          </div>
        </div>
      </>
    );
  }
  protected getCancelDeliveryView(): JSX.Element {
    const { lang } = this.props;

    const cancelDeliveryConfirmText = (
      <div className={styles.element}>
        <TextDisplay color='black' align='center'>
          {i18n.confirmCancelDelivery[lang]}
        </TextDisplay>
      </div>
    );
    return (
      <>
        <div className={styles.contentsContainer}>{cancelDeliveryConfirmText}</div>
        <div>
          <div className={styles.element}>
            <Button size='full' onClick={() => this.handleOnCancelDeliveryClick()}>
              {i18n.confirm[lang]}
            </Button>
          </div>
          <div className={styles.element}>
            <Button size='full' theme='secondary' onClick={() => this.toggleView('action-select')}>
              {i18n.return[lang]}
            </Button>
          </div>
        </div>
      </>
    );
  }

  protected handleOnLocationEditClick() {
    this.toggleView('location-edit-slip-number');
  }

  protected handleOnCancelDeliveryClick() {
    // const { onCancelClick } = this.props;
    // onCancelClick();
    // this.toggleView('action-select');
    const { onCancelDeliveryClick } = this.props;
    if (!onCancelDeliveryClick) return;
    onCancelDeliveryClick();
  }

  protected handleOnNotifyNotDeliveredClick() {
    // const { onClose } = this.props;
    // onClose();
    // const { value } = this.state;
    const { onNotifyNotDeliveredClick } = this.props;
    if (!onNotifyNotDeliveredClick) return;
    onNotifyNotDeliveredClick();
    // this.toggleView('action-select');
  }
  protected handleOnRegisterAsSoldClick() {
    // const { onCancelClick } = this.props;
    // onCancelClick();
    // // here we should close the whole popup, in the distribution-detail page
    // this.toggleView('action-select');
    const { onRegisterAsSoldClick } = this.props;
    if (!onRegisterAsSoldClick) return;
    onRegisterAsSoldClick();
  }
  protected handleOnRegisterAsInStockClick() {
    // const { onCancelClick } = this.props;
    // onCancelClick();
    // this.toggleView('action-select');
    const { onRegisterAsInStockClick } = this.props;
    if (!onRegisterAsInStockClick) return;
    onRegisterAsInStockClick();
  }
  protected handleOnRegisterAsInStockErrorClick() {
    const { onCancelClick } = this.props;
    onCancelClick();
    this.toggleView('action-select');
  }

  protected handleOnLocationEditSlipNumberClick() {
    const { selectedDistId, selectedRestId, slipNo } = this.state;
    const { onLocationEditClick } = this.props;
    if (!onLocationEditClick) return;
    onLocationEditClick({ selectedDistId, selectedRestId, slipNo });
    this.setState({ view: 'action-select' });
  }

  protected handleOnLocationRangeClick() {
    const { selectedDistRange, selectedRestRange } = this.state;
    const { onRangeEditClick } = this.props;
    if (!onRangeEditClick) return;
    onRangeEditClick({ selectedDistRange, selectedRestRange });
    this.setState({ view: 'action-select' });
  }

  protected handleDistLocationChange(selectedId: string) {
    this.setState({
      selectedDistId: Number(selectedId),
    });
  }

  protected handleRestLocationChange(selectedId: string) {
    this.setState({
      selectedRestId: Number(selectedId),
    });
  }

  protected getSaleEditView(): JSX.Element {
    const { lang, onCancelClick, size, restRecvAt } = this.props;
    const { value } = this.state;
    const myMaxDate = new Date();
    console.log('restRecvAt: ' + restRecvAt);
    const myMinDate = restRecvAt;
    return (
      <>
        <div className={styles.contentsContainer}></div>
        <div>
          <div className={styles.element}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                format='yyyy/MM/dd'
                fullWidth={size === 'full'}
                className={styles.datePicker}
                InputProps={{ disableUnderline: true }}
                maxDate={myMaxDate.toString()}
                minDate={myMinDate}
                value={value}
                onChange={(newDate) => this.handleOnChange(newDate)}
                keyboardIcon={<Icon type='calender' color='dark' size='small' />}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.button}>
              <Button size='full' theme='secondary' onClick={() => this.handleRegisterSaleClick()}>
                {i18n.confirm[lang]}
              </Button>
            </div>
            <div className={styles.element}>
              <Button
                size='full'
                theme='secondary'
                onClick={() => this.toggleView('action-select')}
              >
                {i18n.return[lang]}
              </Button>
            </div>
            <div className={styles.element}>
              <Button
                size='full'
                theme='secondary'
                onClick={() => {
                  this.setState(() => onCancelClick());
                }}
              >
                {i18n.cancel[lang]}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }

  protected handleOnChange(newDate: Date) {
    const { onChange } = this.props;
    this.setState({ value: newDate });
    if (!onChange) return;
    //onChange(newDate);
  }

  protected handleRegisterSaleClick() {
    const { value } = this.state;
    const { onRegisterSaleClick } = this.props;
    if (!onRegisterSaleClick) return;
    onRegisterSaleClick(value);
  }

  protected handleRegisterSoldClick() {
    // const { value } = this.state;
    this.toggleView('register-as-sold-done');
  }
  protected handleNotifyNotDeliveredClick() {
    this.toggleView('notify-not-delivered-done');
  }

  protected getActionSelectView(): JSX.Element {
    const {
      lang,
      onCancelClick,
      onScanAppClick,
      // onChatButtonClick,
      showShippingDestEdit,
      // showSalesRecord,
      // distLocationItems,
      distRangeItems,
      // restLocationItems,
      restRangeItems,
      alertLocType,
      alertType,
      userCategory,
      onAdminSetResolvedClick,
      onAdminSetUnresolvedClick,
      onToggleSetToUnprocessablePopup,
      serialInfo,
      // onToggleAlertResolutionPopup,
    } = this.props;
    return (
      <>
        <div className={styles.contentsContainer}>
          {((userCategory == ECategoryType.BREWERY &&
            (alertType == EAlert.QUALITY || alertType == EAlert.DIST_QUALITY) &&
            alertLocType == ECategoryType.DISTRIBUTOR) ||
            userCategory == ECategoryType.ADMIN) && (
            <div className={styles.element}>
              <CardButton size='full' iconType='check' onClick={() => onAdminSetResolvedClick()}>
                {i18n.adminSetAlertResolved[lang]}
              </CardButton>
            </div>
          )}
          {userCategory == ECategoryType.DISTRIBUTOR &&
            alertType == EAlert.QUALITY &&
            alertLocType == ECategoryType.RESTAURANT && (
              <div className={styles.element}>
                <CardButton size='full' iconType='check' onClick={() => onAdminSetResolvedClick()}>
                  {i18n.adminSetAlertResolved[lang]}
                </CardButton>
              </div>
            )}
          {((userCategory == ECategoryType.BREWERY &&
            (alertType == EAlert.QUALITY || alertType == EAlert.DIST_QUALITY) &&
            alertLocType == ECategoryType.RESTAURANT) ||
            (userCategory == ECategoryType.ADMIN &&
              (alertType == EAlert.QUALITY || alertType == EAlert.DIST_QUALITY) &&
              alertLocType == ECategoryType.RESTAURANT)) && (
            <>
              {alertLocType == ECategoryType.RESTAURANT && (
                <div className={styles.element}>
                  <CardButton
                    size='full'
                    iconType='bottle'
                    onClick={() => this.toggleView('register-as-sold')}
                  >
                    {i18n.registerAsSold[lang]}
                  </CardButton>
                </div>
              )}
              <div className={styles.element}>
                <CardButton
                  size='full'
                  iconType='close'
                  onClick={() => onToggleSetToUnprocessablePopup()}
                >
                  {i18n.adminSetAlertUnresolved[lang]}
                </CardButton>
              </div>
            </>
          )}
          {((userCategory == ECategoryType.BREWERY &&
            !(
              (alertType == EAlert.QUALITY || alertType == EAlert.DIST_QUALITY) &&
              alertLocType == ECategoryType.RESTAURANT
            )) ||
            (userCategory == ECategoryType.ADMIN &&
              !(
                (alertType == EAlert.QUALITY || alertType == EAlert.DIST_QUALITY) &&
                alertLocType == ECategoryType.RESTAURANT
              ))) && (
            <div className={styles.element}>
              <CardButton size='full' iconType='close' onClick={() => onAdminSetUnresolvedClick()}>
                {i18n.adminSetAlertUnresolved[lang]}
              </CardButton>
            </div>
          )}

          {(alertLocType == ECategoryType.DISTRIBUTOR ||
            (alertLocType == ECategoryType.RESTAURANT && !serialInfo?.distRecvAt)) &&
            alertType == EAlert.SCAN_DATE &&
            userCategory == ECategoryType.BREWERY && (
              <div className={styles.element}>
                <CardButton
                  size='full'
                  iconType='map'
                  onClick={() => this.toggleView('location-edit')}
                >
                  {i18n.editShippingDestination[lang]}
                </CardButton>
              </div>
            )}
          {alertLocType == ECategoryType.DISTRIBUTOR &&
            alertType == EAlert.SCAN_DATE &&
            userCategory == ECategoryType.DISTRIBUTOR && (
              <div>
                <div className={styles.element}>
                  <CardButton
                    size='full'
                    iconType='close'
                    onClick={() => this.toggleView('notify-not-delivered')}
                  >
                    {i18n.notifyNotDelivered[lang]}
                  </CardButton>
                </div>
                <div className={styles.element}>
                  <CardButton size='full' iconType='camera' onClick={onScanAppClick}>
                    {i18n.scanProduct[lang]}
                  </CardButton>
                </div>
              </div>
            )}
          {/* {showShippingDestEdit && (isOk(distLocationItems) || isOk(restLocationItems)) && ( */}
          {alertLocType == ECategoryType.RESTAURANT &&
            alertType == EAlert.SCAN_DATE &&
            userCategory == ECategoryType.DISTRIBUTOR && (
              <div>
                <div className={styles.element}>
                  <CardButton
                    size='full'
                    iconType='map'
                    onClick={() => this.toggleView('location-edit')}
                  >
                    {i18n.editShippingDestination[lang]}
                  </CardButton>
                </div>
                <div className={styles.element}>
                  <CardButton
                    size='full'
                    iconType='close'
                    onClick={() => this.toggleView('cancel-delivery')}
                  >
                    {i18n.cancelDelivery[lang]}
                  </CardButton>
                </div>
              </div>
            )}
          {/* {onScanAppClick && */}
          {alertLocType == ECategoryType.RESTAURANT &&
            alertType == EAlert.SCAN_DATE &&
            userCategory == ECategoryType.RESTAURANT && (
              <div>
                <div className={styles.element}>
                  <CardButton size='full' iconType='camera' onClick={onScanAppClick}>
                    {i18n.scanProduct[lang]}
                  </CardButton>
                </div>
                <div className={styles.element}>
                  <CardButton
                    size='full'
                    iconType='close'
                    onClick={() => this.toggleView('notify-not-delivered')}
                  >
                    {i18n.notifyNotDelivered[lang]}
                  </CardButton>
                </div>
                <div className={styles.element}>
                  <CardButton
                    size='full'
                    iconType='bottle'
                    onClick={() => this.toggleView('register-as-sold')}
                  >
                    {i18n.registerAsSold[lang]}
                  </CardButton>
                </div>
              </div>
            )}
          {/* {showShippingDestEdit && (isOk(distLocationItems) || isOk(restLocationItems)) && (
            <div className={styles.element}>
              <CardButton
                size='full'
                iconType='bottle'
                onClick={() => this.toggleView('location-edit')}
              >
                {i18n.editShippingDestination[lang]}
              </CardButton>
            </div>
          )} */}
          {/* {(alertType == EAlert.QUALITY || alertType == EAlert.DIST_QUALITY) &&
            (userCategory == ECategoryType.RESTAURANT ||
              (userCategory == ECategoryType.DISTRIBUTOR &&
                alertLocType == ECategoryType.DISTRIBUTOR)) && (
              <>
                <div className={styles.element}>
                  <CardButton
                    size='full'
                    iconType='bottle'
                    onClick={() => this.toggleView('register-as-in-stock')}
                  >
                    {i18n.registerAsInStock[lang]}
                  </CardButton>
                </div>
              </>
            )} */}
          {(alertType == EAlert.QUALITY || alertType == EAlert.DIST_QUALITY) &&
            ((userCategory == ECategoryType.RESTAURANT &&
              alertLocType == ECategoryType.RESTAURANT) ||
              (userCategory == ECategoryType.DISTRIBUTOR &&
                alertLocType == ECategoryType.DISTRIBUTOR)) && (
              <>
                <div className={styles.element}>
                  <CardButton
                    size='full'
                    iconType='bottle'
                    onClick={() => this.toggleView('register-as-in-stock')}
                  >
                    {i18n.registerAsInStock[lang]}
                  </CardButton>
                </div>
              </>
            )}
          {alertLocType == ECategoryType.RESTAURANT &&
            alertType == EAlert.QUALITY &&
            userCategory == ECategoryType.RESTAURANT && (
              <>
                <div className={styles.element}>
                  <CardButton
                    size='full'
                    iconType='bottle'
                    onClick={() => this.toggleView('register-as-sold')}
                  >
                    {i18n.registerAsSold[lang]}
                  </CardButton>
                </div>
              </>
            )}
          {/* {onChatButtonClick && (
            <div className={styles.element}>
              <CardButton size='full' iconType='chat' onClick={onChatButtonClick}>
                {i18n.reportViaChat[lang]}
              </CardButton>
            </div>
          )} */}
          {showShippingDestEdit && (isOk(distRangeItems) || isOk(restRangeItems)) && (
            <div className={styles.element}>
              <CardButton
                size='full'
                iconType='map'
                onClick={() => this.toggleView('location-distance-edit')}
              >
                {i18n.editScanRangeDistance[lang]}
              </CardButton>
            </div>
          )}
          {/* {showSalesRecord && (
            <div className={styles.element}>
              <CardButton size='full' iconType='map' onClick={() => this.toggleView('sales-edit')}>
                {i18n.addSalesRecord[lang]}
              </CardButton>
            </div>
          )} */}
        </div>
        <Button size='full' theme='secondary' onClick={onCancelClick}>
          {i18n.cancel[lang]}
        </Button>
      </>
    );
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  title: string;
  showPopup?: boolean;
  lang?: TSupportedLanguages;
  distLocationItems?: SelectBoxItem[];
  restLocationItems?: SelectBoxItem[];
  distRangeItems?: SelectBoxItem[];
  restRangeItems?: SelectBoxItem[];
  showShippingDestEdit?: boolean;
  showSalesRecord?: boolean;
  selectedDistId?: number;
  selectedRestId?: number;
  selectedDistRange?: number;
  selectedRestRange?: number;
  slipNo?: string;
  alertLocType?: number;
  alertType?: number;
  userCategory?: number;
  value?: Date;
  restRecvAt?: string;
  serialInfo?: any;
  size?: 'full' | 'fit';
}

export interface IDispatchProps {
  onScanAppClick?: () => void;
  onLocationEditClick?: (params: {
    selectedDistId?: number;
    selectedRestId?: number;
    slipNo?: string;
  }) => void;
  onRangeEditClick?: (params: { selectedDistRange?: number; selectedRestRange?: number }) => void;
  onClose?: () => void;
  onCancelClick?: () => void;
  onChatButtonClick?: () => void;
  onChange?: (date: Date) => void;
  onRegisterSaleClick?: (value: Date) => void;
  onRegisterAsInStockClick?: () => void;
  onRegisterAsSoldClick?: () => void;
  onNotifyNotDeliveredClick?: () => void;
  onCancelDeliveryClick?: () => void;
  onCheckSerialCodeMatch?: (scannedSerialCode: string) => boolean;

  onAdminSetResolvedClick?: () => void;
  onAdminSetUnresolvedClick?: () => void;
  onToggleSetToUnprocessablePopup?: () => void;
  onToggleAlertResolutionPopup?: () => void;
}

interface State {
  view: View;
  selectedDistId?: number;
  selectedRestId?: number;
  selectedDistRange?: number;
  selectedRestRange?: number;
  value?: Date;
  tabValue: number;
  cameraSwitch: string;
  handleChangeScannedSerialsStrTimeout: any;
  updateScannedSerialsTimeout: any;
  handleScanTimeout: any;
  scannedSerialCodesStr: string;
  serialCode: string;
  scanIntervalTime: number;
  cameraId: any;
  devices: any;
  slipNo: string;
}

type View =
  | 'action-select'
  | 'location-edit'
  | 'cancel-delivery'
  | 'notify-not-delivered'
  | 'register-as-sold'
  | 'register-as-in-stock'
  | 'notify-not-delivered-done'
  | 'register-as-sold-done'
  | 'register-as-in-stock-done'
  | 'register-as-in-stock-done-error'
  | 'location-edit-slip-number'
  | 'location-distance-edit'
  | 'sales-edit';
