import { IStateApps } from '@models/state-apps';
import { IStateAuth } from '@models/state-auth';
import { IStateServers } from '@models/state-servers';
import { applyMiddleware, combineReducers, createStore } from 'redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import { apps } from './apps';
import { auth } from './auth';
import { servers } from './servers';

const reducer = combineReducers({
  apps,
  auth,
  servers,
});

const enhancer = composeWithDevTools(applyMiddleware(thunk));
// const enhancer = applyMiddleware(thunk);
// TODO [SAKE-BC] Revert on release
export const store = createStore(reducer, enhancer);
export default reducer;

export interface IStoreState {
  apps: IStateApps;
  servers: IStateServers;
  auth: IStateAuth;
}
