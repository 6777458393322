export const jaPrivacyPolicy = `
<p>株式会社JAPAN CRAFT SAKE COMPANY（以下、当社といいます。）は、個人情報の保護に関する法令を遵守すると共に、 この個人情報保護基本方針（以下、基本方針といいます。）に基づき、ユーザー様の個人情報を適切に取扱い、
  保護管理に努めます。</p>
<p><strong>個人情報保護基本方針</strong></p>
<ol>
  <li><strong>個人情報の取得と利用</strong></li>
</ol>
<p>当社は、利用目的を明確にした上で目的の範囲内に限り、個人情報を取得いたします。また個人情報の利用は、その利用目的から逸脱しない範囲とします。</p>
<ol start="2">
  <li><strong>個人情報の管理と保護</strong></li>
</ol>
<p>
  当社は、厳重に個人情報を管理することとし、ユーザー様にご承諾いただいた場合を除き、第三者に対しデータを開示・提供することはいたしません。また、個人情報に関する不正アクセス、紛失、破壊、改ざん、漏洩を防ぐための適切な予防および是正処置を行います。
</p>
<ol start="3">
  <li><strong>法令遵守</strong></li>
</ol>
<p>当社は、保有する個人情報に関して適用される法令等を遵守いたします。</p>
<ol start="4">
  <li><strong>本人の権利尊重</strong></li>
</ol>
<p>
  当社は、個人情報に関する本人の権利を尊重し、ご本人からの開示、訂正もしくは削除または利用もしくは提供の拒否を求められたときは、法令等の定めに従いこれに応じます。また、個人情報の取扱いに関する苦情、相談につき適切かつ迅速に対応いたします。
</p>
<ol start="5">
  <li><strong>個人情報保護管理体制の継続的改善</strong></li>
</ol>
<p>当社は、個人情報保護に関する管理の体制と仕組みについて継続的改善を実施いたします。</p>
<br/>
<p><strong>個人情報の取扱いについて</strong></p>
<p>この「個人情報の取扱いについて」（以下、「本プライバシーポリシー」といいます）は、当社がこのウェブサイト上で提供するサービス（以下、本サービスといいます）における個人情報の取扱いを定めたものです。</p>
<br/>
<p><strong>第1条 取得する個人情報の項目</strong></p>
<p>当社は、法に基づき、下記の個人情報を取得いたします。</p>
<ol>
  <li>本サービスへのログインおよび利用等に関する情報</li>
</ol>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 本サービスログインのためのアカウントのメールアドレス</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 本サービスの利用を通じてユーザー様が行った取引の遂行のための情報および当該取引内容に関する情報</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- ユーザー様から当社へのお問い合わせやご連絡等に関する情報</p>
<ol start="2">
  <li>ユーザー様が使用する端末に関する情報およびCookie等を利用して取得する情報</li>
</ol>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 情報通信端末の機体識別に関する情報</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 情報通信端末のOS情報</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- インターネットへの接続に関する情報</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 位置情報</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- IPアドレス情報</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 閲覧したURLおよび閲覧した日時に関するタイムスタンプ情報</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 本サービスの利用に関する情報</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- サーバログ情報</p>
<ol start="3">
  <li>第三者から取得する情報</li>
</ol>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- ユーザー様の同意を得て第三者から取得する情報</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 法令に基づき取得する情報</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 公開されていることにより取得する情報</p>
<br/>
<p>
  当社が取得するユーザー様の情報は、全てユーザー様の意思によってご提供いただくものです。なお、本サービスの利用に関連して当社が取得する上記の情報の全部又は一部をご提供いただけない場合は、本サービスの利用をお断りする場合がございますので、あらかじめご了承下さい。
</p>
<br/>
<p><strong>第2条 個人情報の利用目的</strong></p>
<p>当社は、第1条に記載した個人情報を、法に基づき、下記のとおり利用いたします。</p>
<ol>
  <li>本サービスへのログインおよび本サービスの利用（ユーザー様の同意に基づく利用またはユーザー様との契約の履行のための利用）</li>
</ol>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- ユーザー様が本サービスにログインするため</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- ユーザー様に適した本サービスを提供・運用するため</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- ユーザー様と当社との間の取引の成立および履行その他のユーザー様による本サービスの利用のため</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 本サービスの登録利用者であるユーザー様の管理のため</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 本サービスの商品等の梱包・発送業務のため</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 本サービスの対価の請求のため</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 本サービスの運営上必要な事項の通知のため</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 本サービスの各種問合わせ対応のため</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 退会したユーザー様へのご連絡・各種問合わせ対応のため</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 不正行為等の防止および対応のため</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 当社が実施するサービス又は企画に関する連絡のため</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- ユーザー様の本人認証及び各種画面における登録情報の自動表示</p>
<ol start="2">
  <li>ユーザーが本サービスへログインし、本サービスをご利用いただくためには、当社が上記の目的で利用するために、ユーザー様の個人情報をご提供いただく必要がございます。</li>
  <li>ユーザー様からのお問い合わせに、顧客管理プラットフォーム「Zendesk」を利用しております。Zendeskでの個人情報の取扱いに関しては、Zendesk Inc.のプライバシーポリシーを参照ください。</li>
</ol>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;【ZendeskInc.のプライバシーポリシー】</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://www.zendesk.co.jp/company/customers-partners/privacy-policy/">https://www.zendesk.co.jp/company/customers-partners/privacy-policy/</a>
</p>
<p>4.マーケティングのための利用（ユーザー様の同意に基づく利用）</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 本サービス上または第三者の媒体において本サービスを通したマーケティングをするため</p>
<ol start="5">
  <li>商品開発・研究のための利用（ユーザー様との同意に基づく利用）</li>
</ol>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- マーケティングデータの調査・分析のため</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 当社の新たなサービスや商品等の開発のため</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 本サービス改善および当社の新たなサービス等を検討するため</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 本サービスのご利用状況等を調査、分析するため</p>
<br/>
<p><strong>第3条 個人情報の提供の同意</strong></p>
<p>
  当社は、法令で認められる場合またはユーザー様の同意をいただいた場合には第三者に対して個人情報を提供いたします。以下の場合においては、当社は第三者に対して、必要な範囲で個人情報（第1号および第2号並びに第4号にあっては、直接特定の個人を識別することができる情報を除外した情報）を提供させていただきます。
</p>
<ol>
  <li>本サービスの提供または品質向上に必要な場合</li>
  <li>新たなサービスの検討・開発のために必要な場合</li>
  <li>我が国と同等の水準にあると認められる個人情報の保護を有していると認められる外国にある第三者に個人情報の取り扱いを委託する場合</li>
  <li>大学その他の学術研究を目的とする機関若しくは団体又はそれらに属する者に学術研究の用に供する目的で個人情報を提供する場合</li>
</ol>
<p>5.ユーザー様が取引する商品の製造、保管、配送等を行う酒類製造メーカー、配送業者等の委託先に対しユーザー様の個人情報の提供が必要な場合</p>
<br/>
<p>なお、当社は、ユーザー様への本サービスの提供等のため必要がある場合には、委託先に対する必要かつ適切な監督を行った上で、個人情報の取り扱いを第三者に委託することがあります。</p>
<br/>
<p><strong>第4条 個人情報の共同利用</strong></p>
<p>当社は、以下のとおり個人情報を共同利用します。</p>
<ol>
  <li>共同して利用される個人情報の項目</li>
</ol>
<p>第1条に記載した項目</p>
<ol start="2">
  <li>共同して利用する者の範囲</li>
</ol>
<p>EYストラテジー・アンド・コンサルティング株式会社</p>
<ol start="3">
  <li>利用する者の利用目的</li>
</ol>
<p>第2条に記載した利用目的</p>
<ol start="4">
  <li>共同利用する個人情報の管理について責任を有する者</li>
</ol>
<p>株式会社JAPAN CRAFT SAKE COMPANY</p>
<ol start="5">
  <li>当社及び当社提携企業間で利用者情報を受け渡す手段及び方法</li>
</ol>
<p>データを記録した電子ファイルまたは印刷物</p>
<br/>
<p><strong>第5条 個人情報の保持期間</strong></p>
<p>当社は、法令で定められている保存期間が経過した場合、または下記「個人情報の利用目的」に記載した利用目的の達成のために利用する必要がなくなった場合には、当該個人情報を遅滞なく消去いたします。</p>
<p><strong></strong></p>
<p><strong>第6条 本プライバシーポリシーの変更</strong></p>
<p>
  個人情報を適切に利用し、その保護を徹底するために本プライバシーポリシーの全部または一部を改定することがあります。重要な変更がある場合は、適用開始日をお知らせしたうえで通知を行います。但し、法令上ユーザー様の同意が必要となるような内容の変更の場合は、当社所定の方法で同意を得るものとします。
</p>
<br/>
<p><strong>第7条 クッキーの利用について</strong></p>
<p>当社の本サービスではユーザー様の閲覧情報を分析し、個々のユーザー様に適したサービスや情報等を提供する目的のため、Cookieを利用しています。詳しくはクッキーポリシーをご覧ください。</p>
<br/>
<p><strong>第8条 情報の開示、提供について</strong></p>
<p>当社は、利用者の同意を得ることなく、本サービスにて利用者より取得、保存した情報を、本プライバシーポリシーに明示している場合以外で第三者に開示又は提供することはありません。ただし、以下の場合は除きます。</p>
<ol>
  <li>法令に基づく場合。</li>
  <li>人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合。</li>
  <li>国の機関もしくは地方公共団体又はその委託を受けた者が、法令の定めにより遂行することに協力する必要がある場合であって、本人の同意を得ることによりその遂行に支障を及ぼすおそれがある場合。</li>
  <li>公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき。</li>
</ol>
<br/>
<p><strong>第9条 個人情報の取扱いに関するご相談・苦情等について</strong></p>
<br/>
<p>当社の個人情報の取扱いに関するご相談や苦情、異議等のお問い合わせについては、下記の窓口までご連絡いただきますよう、お願い申し上げます。また、ユーザー様のご同意はいつでも撤回することが可能です。</p>
<br/>
<p><strong>［個人情報についてのお問い合わせ先］</strong></p>
<p>〒107-6022</p>
<p>東京都港区赤坂1-12-32 アーク森ビル22F</p>
<p>株式会社JAPAN CRAFT SAKE COMPANY</p>
<br/>
<p><strong>第10条　個人情報保護管理者</strong></p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 株式会社JAPAN CRAFT SAKE COMPANY 代表取締役 中田英寿</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 個人情報保護管理者に対するお問い合わせ等は、上記の「個人情報についてのお問い合わせ先」までご連絡ください。</p>
<br/>
<p><strong>クッキーポリシー</strong></p>
<p>株式会社JAPAN CRAFT SAKE COMPANYのウェブサイトおよびサービス上では、ユーザー様の閲覧情報を分析し、個々のユーザー様に適したサービスや情報等を提供する目的のため、Cookieが使用されています。
  本Cookieポリシー（以下「本ポリシー」といいます）においては、当社が管理するCookie（以下に定義します。）および当社のウェブサイトおよびサービス上で提携する第三者が設置するCookieの詳細、ならびにお客様による拒否方法を記載しています。
</p>
<br/>
<ol>
  <li><strong>Cookieとは</strong></li>
</ol>
<p>
  Cookieとは、ユーザー様がウェブサイトやサービスにアクセスした際の閲覧情報を、ユーザー様のデバイス（PCやスマートフォンなどの機器）に保存する機能のことです（Webビーコン等を含みます）。なお、Cookie情報から、ユーザー様の個人情報を特定することは出来ません。
  Cookieは、当社のウェブサイトおよびサービス上で提供される機能を利用するために設けられるものと、当社各社と提携する第三者によって設定されるものの2種類があります。ユーザー様は、ブラウザの設定によりCookieを拒否することができますが、拒否された場合は、一部のサービスが受けられない場合があることをご了承下さい。
</p>
<br/>
<ol start="2">
  <li><strong>Cookieの利用目的</strong></li>
  <li>
    ユーザー様が当社のウェブサイトおよびサービスを閲覧する際に、ユーザー様のデバイスを識別し、サービス等を利用できるために使用しております。そのため、ユーザー様がデバイス上にCookieを保存することによって、同じ情報を繰り返し入力することがなくなるなど、ブラウザ上等での利便性が高まります。
  </li>
  <li>当社では、Cookieを使用して収集した情報を利用して、ユーザー様のウェブサイトの利用状況（アクセス状況、トラフィック、ルーティング等）を分析し、ウェブサイトやサービスの向上、改善のために使用することがあります。</li>
  <li>ユーザー様からのお問い合わせに、顧客管理プラットフォーム「Zendesk」にて、Cookieを使用し情報を収集しております。サービスを利用するにあたって必要なCookieの取扱いに関しては、Zendesk
    Inc.のCookieポリシーを参照ください。</li>
</ol>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;【ZendeskInc.のCookieポリシー】</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <a href="https://www.zendesk.co.jp/company/policies-procedures/cookie-policy/">
    https://www.zendesk.co.jp/company/policies-procedures/cookie-policy/
  </a>
</p>
<ol start="4">
  <li>上記目的の他、当社が取得したCookie情報については、各社個人情報保護方針（プライバシーポリシー）に規定されている利用目的の範囲内で利用させていただきます。</li>
</ol>
<br/>
<p>制定日2021年5月21日</p>`;
