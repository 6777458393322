import React from 'react';
import styles from './style.scss';

export class FloatingArea extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    location: 'bottom-right',
  };

  public render(): JSX.Element {
    const { children, location } = this.props;

    return (
      <div className={`${styles.floatingArea} ${styles[`location-${location}`]}`}>{children}</div>
    );
  }
}

export type Props = IStateProps;

export interface IStateProps {
  children?: React.ReactNode;
  location?: 'bottom-left' | 'bottom-right';
}
