import { ListPages } from '@models/all-pages';
import { IQuery } from '@models/query';
import { IStateApps } from '@models/state-apps';
import { IStateServers } from '@models/state-servers';
import {
  closeSearchFilterPopup,
  openSearchFilterPopup,
  setSearchFilterSelectionValues,
} from '@redux/actions/appsActions';
import {
  getAlerts,
  getBrands,
  getLocations,
  getSerials,
  setAlertsQuery,
  setSerialsQuery,
} from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import { DefaultQuery } from '@utils/default-query';
import { SearchFilterAnchor } from '@visual/search-filter-anchor';
import {
  Item as SearchFilterPopupItem,
  SearchFilterPopup,
  SearchFilterStateValues,
  SelectedValues,
} from '@visual/search-filter-popup';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getFields as brandsFields } from './config/brands';
import { getFields as breweriesFields } from './config/breweries';
import { getFields as customizationFields } from './config/customization';
import { getFields as dashboardFields } from './config/dashboard';
import { getFields as distributionHistoryFields } from './config/distribution-history';
import { getFields as distributorsFields } from './config/distributor';
import { getWhereCondition } from './config/get-where-condition';
import { getFields as monitoringFields } from './config/monitoring';
import { getFields as notificationListFields } from './config/notification-list';
import { getFields as purchaseHistoryFields } from './config/purchase-history';
import { getFields as reportsFields } from './config/reports';
import { getFields as restaurantsFields } from './config/restaurants';
import { getFields as scansIngoingFields } from './config/scans-ingoing';
import { getFields as scansOutgoingFields } from './config/scans-outgoing';
import { getFields as surveyHistoryFields } from './config/survey-history';
import { getFields as taskManagementFields } from './config/task-management';
import { getFields as vipsFields } from './config/vips';

class SearchFilterClass extends React.PureComponent<Props> {
  public componentDidMount() {
    const { caller } = this.props;
    switch (caller) {
      case 'brands':
      case 'breweries':
      case 'customization':
      case 'dashboard':
      case 'distribution-history':
      case 'monitoring':
      case 'distributors':
      case 'notification-list':
      case 'purchase-history':
      case 'reports':
      case 'restaurants':
      case 'scans-ingoing':
      case 'scans-outgoing':
      case 'survey-history':
      case 'task-management':
      case 'vips':
        break;
    }
  }

  public render(): JSX.Element {
    const {
      apps: { currentLanguage: lang, isSearchFilterPopupOpen },
      closeSearchFilterPopup,
      caller,
    } = this.props;

    const searchFields = this.getFields();

    return (
      <>
        <SearchFilterAnchor lang={lang} onSearchButtonClick={() => this.handleClick(caller)} />
        <SearchFilterPopup
          items={searchFields}
          isOpen={isSearchFilterPopupOpen}
          onClose={closeSearchFilterPopup}
          onSearch={(selectedValue: SelectedValues) =>
            this.handleOnSearch(searchFields, selectedValue)
          }
          lang={lang}
        />
      </>
    );
  }

  protected handleOnSearch(fields: SearchFilterPopupItem[], selectedValues: SelectedValues) {
    const {
      caller,
      setSearchFilterSelectionValues,
      closeSearchFilterPopup,
      apps: { filterSelectedValues },
    } = this.props;

    // Update selection stateprops
    setSearchFilterSelectionValues({ ...filterSelectedValues, ...{ [caller]: selectedValues } });

    // Update query
    const newQuery: IQuery = { ...DefaultQuery, ...getWhereCondition(fields, selectedValues) };

    switch (caller) {
      case 'brands':
      case 'breweries':
      case 'customization':
      case 'dashboard':
        break;
      case 'distribution-history':
        this.props.setSerialsQuery(newQuery);
        this.props.getSerials(newQuery);
        break;
      case 'distributors':
      case 'monitoring':
      case 'notification-list':
      case 'purchase-history':
      case 'reports':
      case 'restaurants':
      case 'scans-ingoing':
      case 'scans-outgoing':
      case 'survey-history':
      case 'task-management':
      case 'vips':
        break;
    }
    closeSearchFilterPopup();
  }

  protected getFields(): SearchFilterPopupItem[] {
    const {
      caller,
      apps,
      servers,
      apps: { currentLanguage: lang },
    } = this.props;
    switch (caller) {
      case 'brands': {
        return brandsFields(lang, apps, servers);
      }
      case 'breweries': {
        return breweriesFields(lang, apps, servers);
      }
      case 'customization': {
        return customizationFields(lang, apps, servers);
      }
      case 'dashboard': {
        return dashboardFields(lang, apps, servers);
      }
      case 'distribution-history': {
        return distributionHistoryFields(lang, apps, servers);
      }
      case 'distributors': {
        return distributorsFields(lang, apps, servers);
      }
      case 'monitoring': {
        return monitoringFields(lang, apps, servers);
      }
      case 'notification-list': {
        return notificationListFields(lang, apps, servers);
      }
      case 'purchase-history': {
        return purchaseHistoryFields(lang, apps, servers);
      }
      case 'reports': {
        return reportsFields(lang, apps, servers);
      }
      case 'restaurants': {
        return restaurantsFields(lang, apps, servers);
      }
      case 'scans-ingoing': {
        return scansIngoingFields(lang, apps, servers);
      }
      case 'scans-outgoing': {
        return scansOutgoingFields(lang, apps, servers);
      }
      case 'survey-history': {
        return surveyHistoryFields(lang, apps, servers);
      }
      case 'task-management': {
        return taskManagementFields(lang, apps, servers);
      }
      case 'vips': {
        return vipsFields(lang, apps, servers);
      }
      default: {
        return;
      }
    }
  }

  protected handleClick(caller: ListPages) {
    this.props.openSearchFilterPopup(caller);
  }
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  classes: any;
  caller?: ListPages;
}

export interface IDispatchProps {
  openSearchFilterPopup: (caller: ListPages) => void;
  closeSearchFilterPopup: () => void;
  getBrands: (query: IQuery) => void;
  getLocations: (query: IQuery) => void;
  getSerials: (query: IQuery) => void;
  setSerialsQuery: (query: IQuery) => void;
  getAlerts: (query: IQuery) => void;
  setAlertsQuery: (query: IQuery) => void;
  setSearchFilterSelectionValues: (selectedListPageFilterValues: SearchFilterStateValues) => void;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

const mapDispatchToProps: IDispatchProps = {
  openSearchFilterPopup,
  closeSearchFilterPopup,
  getBrands,
  getLocations,
  getSerials,
  setSerialsQuery,
  setSearchFilterSelectionValues,
  getAlerts,
  setAlertsQuery,
};

export const SearchFilter = compose<any>(connect(mapStateToProps, mapDispatchToProps))(
  SearchFilterClass,
);
