import { EAlertStatus } from '@models/alert-status-type';
import { AllPages, ListPages } from '@models/all-pages';
import { TBrandLocPopupOpener } from '@models/brand-loc-popup-opener';
import { SearchFilterStateValues } from '@visual/search-filter-popup';
import {
  CLOSE_BRAND_EDIT_DIALOG,
  CLOSE_GOOGLE_MAP_DIALOG,
  CLOSE_HISTORY_SUMMARY_ACTIVITIES_DIALOG,
  CLOSE_HISTORY_SUMMARY_COMMENTS_DIALOG,
  CLOSE_HISTORY_SUMMARY_EDIT_DIALOG,
  CLOSE_HISTORY_SUMMARY_TRANSITION_DIALOG,
  CLOSE_LOCATION_ANALYZE_COMMENTS_DIALOG,
  CLOSE_LOCATION_EDIT_DIALOG,
  CLOSE_ORDER_OF_SECTIONS_DIALOG,
  CLOSE_REST_PRODUCT_ACTIVITIES_DIALOG,
  CLOSE_REST_PRODUCT_COMMENTS_DIALOG,
  CLOSE_REST_PRODUCT_EDIT_DIALOG,
  CLOSE_REST_PRODUCT_TRANSITION_DIALOG,
  CLOSE_SCAN_ANALYZE_AGES_TRANSITION_DIALOG,
  CLOSE_SCAN_ANALYZE_BRANDS_TRANSITION_DIALOG,
  CLOSE_SCAN_ANALYZE_CITIES_TRANSITION_DIALOG,
  CLOSE_SCAN_ANALYZE_COMMENTS_DIALOG,
  CLOSE_SCAN_ANALYZE_COUNTRIES_TRANSITION_DIALOG,
  CLOSE_SCAN_ANALYZE_GENDERS_TRANSITION_DIALOG,
  CLOSE_SCAN_ANALYZE_TIMEZONES_TRANSITION_DIALOG,
  CLOSE_SEARCH_FILTER_POPUP,
  CLOSE_SERIALS_COMMENTS_DIALOG,
  CLOSE_SERIAL_EDIT_DIALOG,
  CLOSE_SNACKBAR_MESSAGE,
  CLOSE_USER_EDIT_DIALOG,
  CLOSE_VIDEO_CALL_DIALOG,
  DONE_SETTING,
  INITIALIZE_APPS,
  OPEN_BRAND_EDIT_DIALOG,
  OPEN_GOOGLE_MAP_POLYLINE_DIALOG,
  OPEN_HISTORY_SUMMARY_ACTIVITIES_DIALOG,
  OPEN_HISTORY_SUMMARY_COMMENTS_DIALOG,
  OPEN_HISTORY_SUMMARY_EDIT_DIALOG,
  OPEN_HISTORY_SUMMARY_TRANSITION_DIALOG,
  OPEN_LOCATION_ANALYZE_COMMENTS_DIALOG,
  OPEN_LOCATION_EDIT_DIALOG,
  OPEN_ORDER_OF_SECTIONS_DIALOG,
  OPEN_REST_PRODUCT_ACTIVITIES_DIALOG,
  OPEN_REST_PRODUCT_COMMENTS_DIALOG,
  OPEN_REST_PRODUCT_EDIT_DIALOG,
  OPEN_REST_PRODUCT_TRANSITION_DIALOG,
  OPEN_SCAN_ANALYZE_AGES_TRANSITION_DIALOG,
  OPEN_SCAN_ANALYZE_BRANDS_TRANSITION_DIALOG,
  OPEN_SCAN_ANALYZE_CITIES_TRANSITION_DIALOG,
  OPEN_SCAN_ANALYZE_COMMENTS_DIALOG,
  OPEN_SCAN_ANALYZE_COUNTRIES_TRANSITION_DIALOG,
  OPEN_SCAN_ANALYZE_GENDERS_TRANSITION_DIALOG,
  OPEN_SCAN_ANALYZE_TIMEZONES_TRANSITION_DIALOG,
  OPEN_SEARCH_FILTER_POPUP,
  OPEN_SERIALS_COMMENTS_DIALOG,
  OPEN_SERIAL_EDIT_DIALOG,
  OPEN_SNACKBAR_MESSAGE,
  OPEN_USER_EDIT_DIALOG,

  // test video call
  OPEN_VIDEO_CALL_DIALOG,
  SET_CURRENT_LANGUAGE,
  SET_CURRENT_PAGE,
  SET_EDIT_BRAND,
  SET_EDIT_HISTORY_SUMMARY,
  SET_EDIT_LOCATION,
  SET_EDIT_REST_PRODUCT,
  SET_EDIT_SERIAL,
  SET_EDIT_USER,
  SET_EXPANDED_REST_STATUS_TABLE_NESTED_TABLE,
  SET_MONITORING_FILTER,
  SET_ORDER_OF_SECTIONS,
  SET_SEARCH_FILTER_SELECTION_VALUES,
  SET_SELECTED_SIDE_PANEL_OPTION_ID,
  START_SETTING,
} from '.';

export const initializeApps = () => ({ type: INITIALIZE_APPS });

export const openSnackbarMessage = (format, message) => ({
  type: OPEN_SNACKBAR_MESSAGE,
  format,
  message,
});

export const closeSnackbarMessage = () => ({ type: CLOSE_SNACKBAR_MESSAGE });

export const startSetting = () => ({ type: START_SETTING });

export const doneSetting = () => ({ type: DONE_SETTING });
const __setCurrentLanguage = (lang) => ({ type: SET_CURRENT_LANGUAGE, lang });

export const setCurrentLanguage = (lang) => (dispatch) => {
  setTimeout(() => dispatch(startSetting()), 1);
  setTimeout(() => dispatch(__setCurrentLanguage(lang)), 10);
  setTimeout(() => dispatch(doneSetting()), 600);
};

export const openOrderOfSectionsDialog = () => ({ type: OPEN_ORDER_OF_SECTIONS_DIALOG });

export const closeOrderOfSectionsDialog = () => ({ type: CLOSE_ORDER_OF_SECTIONS_DIALOG });

export const setOrderOfSections = (array) => ({ type: SET_ORDER_OF_SECTIONS, array });

export const setEditSerial = (obj) => ({ type: SET_EDIT_SERIAL, obj });

export const openSerialEditDialog = (obj = {}) => ({ type: OPEN_SERIAL_EDIT_DIALOG, obj });

export const closeSerialEditDialog = () => ({ type: CLOSE_SERIAL_EDIT_DIALOG });

export const openGoogleMapPolylineDialog = (obj = {}) => ({
  type: OPEN_GOOGLE_MAP_POLYLINE_DIALOG,
  obj,
});

export const closeGoogleMapDialog = () => ({ type: CLOSE_GOOGLE_MAP_DIALOG });

export const openSerialsCommentsDialog = () => ({ type: OPEN_SERIALS_COMMENTS_DIALOG });

export const closeSerialsCommentsDialog = () => ({ type: CLOSE_SERIALS_COMMENTS_DIALOG });

export const setEditHistorySummary = (obj) => ({ type: SET_EDIT_HISTORY_SUMMARY, obj });

export const openHistorySummaryEditDialog = (obj = {}) => ({
  type: OPEN_HISTORY_SUMMARY_EDIT_DIALOG,
  obj,
});

export const closeHistorySummaryEditDialog = () => ({ type: CLOSE_HISTORY_SUMMARY_EDIT_DIALOG });

export const openHistorySummaryActivitiesDialog = () => ({
  type: OPEN_HISTORY_SUMMARY_ACTIVITIES_DIALOG,
});

export const closeHistorySummaryActivitiesDialog = () => ({
  type: CLOSE_HISTORY_SUMMARY_ACTIVITIES_DIALOG,
});

export const openHistorySummaryTransitionDialog = () => ({
  type: OPEN_HISTORY_SUMMARY_TRANSITION_DIALOG,
});

export const closeHistorySummaryTransitionDialog = () => ({
  type: CLOSE_HISTORY_SUMMARY_TRANSITION_DIALOG,
});

export const openHistorySummaryCommentsDialog = () => ({
  type: OPEN_HISTORY_SUMMARY_COMMENTS_DIALOG,
});

export const closeHistorySummaryCommentsDialog = () => ({
  type: CLOSE_HISTORY_SUMMARY_COMMENTS_DIALOG,
});

export const openSearchFilterPopup = (caller: ListPages) => ({
  type: OPEN_SEARCH_FILTER_POPUP,
  caller,
});

export const setSearchFilterSelectionValues = (
  selectedListPageFilterValues: SearchFilterStateValues,
) => ({
  type: SET_SEARCH_FILTER_SELECTION_VALUES,
  selectedListPageFilterValues,
});

export const setCurrentPage = (pageId: AllPages) => ({ type: SET_CURRENT_PAGE, pageId });

export const closeSearchFilterPopup = () => ({
  type: CLOSE_SEARCH_FILTER_POPUP,
});

export const setSelectedSidePanelOptionId = (obj: number) => ({
  type: SET_SELECTED_SIDE_PANEL_OPTION_ID,
  obj,
});

export const setEditRestProduct = (obj) => ({ type: SET_EDIT_REST_PRODUCT, obj });

export const openRestProductEditDialog = (obj = {}) => ({
  type: OPEN_REST_PRODUCT_EDIT_DIALOG,
  obj,
});

export const closeRestProductEditDialog = () => ({ type: CLOSE_REST_PRODUCT_EDIT_DIALOG });

export const openRestProductActivitiesDialog = () => ({
  type: OPEN_REST_PRODUCT_ACTIVITIES_DIALOG,
});

export const closeRestProductActivitiesDialog = () => ({
  type: CLOSE_REST_PRODUCT_ACTIVITIES_DIALOG,
});

export const openRestProductTransitionDialog = () => ({
  type: OPEN_REST_PRODUCT_TRANSITION_DIALOG,
});

export const closeRestProductTransitionDialog = () => ({
  type: CLOSE_REST_PRODUCT_TRANSITION_DIALOG,
});

export const openRestProductCommentsDialog = () => ({ type: OPEN_REST_PRODUCT_COMMENTS_DIALOG });

export const closeRestProductCommentsDialog = () => ({ type: CLOSE_REST_PRODUCT_COMMENTS_DIALOG });

export const openScanAnalyzeCommentsDialog = () => ({ type: OPEN_SCAN_ANALYZE_COMMENTS_DIALOG });

export const closeScanAnalyzeCommentsDialog = () => ({ type: CLOSE_SCAN_ANALYZE_COMMENTS_DIALOG });

export const openScanAnalyzeBrandsTransitionDialog = () => ({
  type: OPEN_SCAN_ANALYZE_BRANDS_TRANSITION_DIALOG,
});

export const closeScanAnalyzeBrandsTransitionDialog = () => ({
  type: CLOSE_SCAN_ANALYZE_BRANDS_TRANSITION_DIALOG,
});

export const openScanAnalyzeCountriesTransitionDialog = () => ({
  type: OPEN_SCAN_ANALYZE_COUNTRIES_TRANSITION_DIALOG,
});

export const closeScanAnalyzeCountriesTransitionDialog = () => ({
  type: CLOSE_SCAN_ANALYZE_COUNTRIES_TRANSITION_DIALOG,
});

export const openScanAnalyzeCitiesTransitionDialog = () => ({
  type: OPEN_SCAN_ANALYZE_CITIES_TRANSITION_DIALOG,
});

export const closeScanAnalyzeCitiesTransitionDialog = () => ({
  type: CLOSE_SCAN_ANALYZE_CITIES_TRANSITION_DIALOG,
});

export const openScanAnalyzeAgesTransitionDialog = () => ({
  type: OPEN_SCAN_ANALYZE_AGES_TRANSITION_DIALOG,
});

export const closeScanAnalyzeAgesTransitionDialog = () => ({
  type: CLOSE_SCAN_ANALYZE_AGES_TRANSITION_DIALOG,
});

export const openScanAnalyzeGendersTransitionDialog = () => ({
  type: OPEN_SCAN_ANALYZE_GENDERS_TRANSITION_DIALOG,
});

export const closeScanAnalyzeGendersTransitionDialog = () => ({
  type: CLOSE_SCAN_ANALYZE_GENDERS_TRANSITION_DIALOG,
});

export const openScanAnalyzeTimezonesTransitionDialog = () => ({
  type: OPEN_SCAN_ANALYZE_TIMEZONES_TRANSITION_DIALOG,
});

export const closeScanAnalyzeTimezonesTransitionDialog = () => ({
  type: CLOSE_SCAN_ANALYZE_TIMEZONES_TRANSITION_DIALOG,
});

export const openLocationAnalyzeCommentsDialog = () => ({
  type: OPEN_LOCATION_ANALYZE_COMMENTS_DIALOG,
});

export const closeLocationAnalyzeCommentsDialog = () => ({
  type: CLOSE_LOCATION_ANALYZE_COMMENTS_DIALOG,
});

export const setMonitoringFilter = (selectedSortItem: number, selectedStatus: EAlertStatus[]) => ({
  type: SET_MONITORING_FILTER,
  selectedSortItem,
  selectedStatus,
});

export const setEditLocation = (obj) => ({ type: SET_EDIT_LOCATION, obj });

export const openLocationEditDialog = (obj = {}, opener: TBrandLocPopupOpener = 'master') => ({
  type: OPEN_LOCATION_EDIT_DIALOG,
  obj,
  opener,
});

export const closeLocationEditDialog = () => ({ type: CLOSE_LOCATION_EDIT_DIALOG });

export const setEditBrand = (obj) => ({ type: SET_EDIT_BRAND, obj });

export const openBrandEditDialog = (obj = {}, opener: TBrandLocPopupOpener = 'master') => ({
  type: OPEN_BRAND_EDIT_DIALOG,
  obj,
  opener,
});

export const closeBrandEditDialog = () => ({ type: CLOSE_BRAND_EDIT_DIALOG });

export const setEditUser = (obj) => ({ type: SET_EDIT_USER, obj });

export const openUserEditDialog = (obj = {}) => ({ type: OPEN_USER_EDIT_DIALOG, obj });

export const closeUserEditDialog = () => ({ type: CLOSE_USER_EDIT_DIALOG });

export const openVideoCallDialog = (obj = {}) => ({ type: OPEN_VIDEO_CALL_DIALOG, obj });

export const closeVideoCallDialog = () => ({ type: CLOSE_VIDEO_CALL_DIALOG });

export const setExpandedRestStatusTableNestedTable = (restLocId: number, isDisplay: boolean) => ({
  type: SET_EXPANDED_REST_STATUS_TABLE_NESTED_TABLE,
  restLocId,
  isDisplay,
});
