export const enPrivacyPolicy = `
<p>Privacy Notice -SAKE Blockchain</p>
<ol>
  <li><strong>Introduction</strong></li>
</ol>
<p>This Privacy Notice is intended to describe the practices JAPAN CRAFT SAKE COMPANY CO., LTD (&ldquo;JCSC&rdquo;)
  follows in relation to the use of SAKE Blockchain (&ldquo;Platform&rdquo;) with respect to the privacy protection of
  all personal data. All the data collected via the Platform is provided with user consent, and if all/any of the data
  was not provided, JCSC may not permit use of the Platform.</p>

<br />
<strong>2.Who manages the Platform?</strong>
<br />
<p>JCSC which can determine the purposes and means for data processing in its own right (i.e. act as a data controller
  or in a similar capacity).</p>
<p>The Platform is hosted in Japan.</p>

<br />
<strong>3.Why do we need your personal data?</strong>
<br />
<p>The Platform collects data for the following means:</p>
<p>1) Login to the service and usage of the service (use based on the user's consent or use for the performance of a
  contract with the user)</p>
<ul>
  <li>For user to log in to the service</li>
  <li>To provide and operate the necessary service for user</li>
  <li>To complete and fulfill transactions between user and JCSC</li>
  <li>For user management</li>
  <li>To assist product packing and shipping operation</li>
  <li>To assist product invoice operation</li>
  <li>To notify significant matter of operation</li>
  <li>For customer service assistance</li>
  <li>To contact withdrew user</li>
  <li>To prevent and deal with misconduct</li>
  <li>To contact information regarding new service and campaign</li>
  <li>For user authentication and registered information display unique to each user</li>
</ul>
<p>2) In order for user to log in to the service and to leverage the service, JCSC requires to collect user privacy
  information.</p>
<p>3) The Platform uses "Zendesk", the client management system, for customer service. Please refer to Zendesk Inc.
  Privacy Policy for information with respect to personal data usage/handling.</p>
<p>【Zendesk Inc. Privacy Policy】</p>
<p><a
    href="https://www.zendesk.co.jp/company/customers-partners/privacy-policy/">https://www.zendesk.co.jp/company/customers-partners/privacy-policy/</a>
</p>
<p>4) Marketing through the service or in a 3<sup>rd</sup> party medium</p>
<p>5) Product development and research (with user consent)</p>
<ul>
  <li>For market research and analysis</li>
  <li>To develop new service and product</li>
  <li>To improve the service</li>
  <li>To investigate and analyze usage status of the service</li>
</ul>

<br />
<strong>4.What type of personal data is processed in the Platform?</strong>
<br />
<p>The Platform processes the following data:</p>
<p>User login/profile</p>
<ul>
  <li>Email address</li>
  <li>Product distribution/transaction data</li>
  <li>Customer service-related data (if any)</li>
</ul>
<p>Device data, Cookie</p>
<ul>
  <li>Device specific/identification information</li>
  <li>Device OS information</li>
  <li>Internet connection related data</li>
  <li>Location data</li>
  <li>IP address</li>
  <li>Viewed URL and its timestamp</li>
  <li>Usage data of the Platform</li>
  <li>Server log</li>
</ul>
<p>Data acquired from the 3<sup>rd</sup> party</p>
<ul>
  <li>Data from 3<sup>rd</sup> party with user consent</li>
  <li>Data required based on laws and regulation</li>
  <li>Publicly open data</li>
</ul>

<br />
<strong>5. Who can access your personal data?</strong>
<br />
<ul>
  <li>JCSC</li>
  <li>EY Strategy and Consulting Co., Ltd. (Japan)</li>
</ul>
<p>-Items of Personal Information to be Jointly Used: Items stipulated in paragraph 4;</p>
<ul>
  <li>Purpose of Joint Use: Purpose stipulated in paragraph 3;</li>
  <li>Name of Party Responsible for User Privacy Information: JCSC</li>
  <li>Means and methods for passing User Privacy Information: Electronic or printed files containing data</li>
</ul>

<p>JCSC provides user personal information with business operators (including breweries, logistic companies, service
  providers involved to run and support IT infrastructure (such as identity management, hosting, data analysis, back-up,
  security and cloud storage services), and other 3<sup>rd</sup> party service providers) entrusting a whole or part of
  the handling of the user personal information within the necessary scope to achieve a purpose of usage. The provision
  of user personal information under this paragraph to the operators of which country or the region of such business
  operators is located outside the territory of Japan shall be conducted, only if such country or region establishes a
  personal information protection system recognized to have equivalent standards to that in Japan in regard to the
  protection of an individual's rights and interests.</p>

<p>JCSC is bound to maintain appropriate levels of data protection, security and confidentiality, and comply with any
  applicable legal requirements to not allow transferal of personal data outside China.</p>

<p>To the extent that personal data has been rendered anonymous in a way that user or user device is no longer
  reasonably identifiable, such information will be treated as non-personal data and the terms of this Privacy Notice
  will not apply. JCSC provides such anonymously processed information to the 3<sup>rd</sup> party service providers for
  the purposes below;</p>
<ul>
  <li>Improvement or development of the services</li>
  <li>R&amp;D for new services</li>
  <li>Academic research at universities or other institutions, or individual researchers belonged to them</li>
</ul>
<br />
<strong>6. Data retention</strong>
<br />
<p>JCSC policy is to retain personal data only for as long as it is needed for the purposes described in the section 3.
  Retention periods vary in different jurisdictions and are set in accordance with local regulatory and professional
  retention requirements.</p>
<p>In order to meet JCSC professional and legal requirements, to establish, exercise or defend legal rights and for
  archiving and historical purposes, JCSC needs to retain information for significant periods of time, but will not
  exceed the limit required by applicable laws, regulations and professional standards.</p>
<p>User Data is stored for the time period in which the user maintains a profile. If the user did not maintain a
  profile, the corresponding user data (including hard copy) will be deleted after a specific duration.</p>

<br />
<strong>7. Cookies</strong>
<br />
<p>The Platform uses 1<sup>st</sup> and 3<sup>rd</sup> party "Cookies" in order to conduct data analytics and to collect
  data about content user interact with on the Platform to optimize user experience.</p>
<p>A "cookie" is technology that allows the Platform to store tokens of information (an 'identifier') on user device
  while using the Platform. Cookies serve to help JCSC track a user&rsquo;s preferences when using the Platform.</p>

<p>How do I disable cookies?</p>
<p>By accepting the &ldquo;cookie banner&rdquo; on the Platform and using the Platform user agrees that the Platform can
  place cookies in browser. If user does not want to receive a cookie from the Platform, user has the option to set
  browser to block or delete cookie. However, please be aware that if user did turn off 'cookies' in browser, user will
  not be able to fully experience some of the features in the Platform.</p>

<br />
<strong>8. Security</strong>
<br />
<p>JCSC protects the confidentiality and security of information it obtains via the Platform. Access to such information
  is limited, and policies and procedures are in place that are designed to safeguard the information from loss, misuse
  and improper disclosure.</p>

<br />
<strong>9. Change in Privacy Notice</strong>
<br />
<p>JCSC may modify this Privacy Notice for an appropriate use and protection of personal data. If there was a
  significant change in this Privacy Notice, JCSC will notify its change along with effective date.　Notwithstanding the
  preceding provision, JCSC will acquire the consent of user in a manner prescribed by JCSC in the case of changes to
  the contents that require the consent in accordance with applicable laws.</p>

<br />
<strong>10. Controlling your personal data</strong>
<br />
<p>JCSC will not transfer user personal data to 3rd party (other than any external party referred to in section 5)
  unless JCSC;</p>
<ul>
  <li>acquired user permission</li>
  <li>was required by law and regulation</li>
  <li>needed user personal data to protect individual life/safety under a situation when user permission obtain was
    difficult</li>
  <li>needed to provide personal data to government/public institutions or contracted organization with those
    institutions, while obtaining user permission might conflict with this responsibility</li>
  <li>needed to provide personal data to enhance public hygiene or to promote fostering healthy children under a
    situation when user permission obtain was difficult</li>
  <li>In addition to the foregoing, to provide personal data pursuant to applicable laws and regulations</li>
</ul>
<p>User is legally entitled to request details of JCSC&rsquo;s personal data, to confirm whether own personal data is
  processed in the Platform, to access own personal data in the Platform, (where applicable) to withdraw own consent, or
  to rectify/delete a readily portable copy of own personal data. Please contact JCSC for such request.</p>

<br />
<strong>11. Contact</strong>
<br />
<p>JAPAN CRAFT SAKE COMPANY CO., LTD President/CEO Hidetoshi Nakata is the Privacy Policy Manager. Please contact below
  for any request, objection, complaint, or etc. regarding personal data handling.</p>
<p>Postal Code: 107-6022</p>
<p>ARK Mori Building 22F</p>
<p>1-12-32 Akasaka, Minato-ku, Tokyo</p>
<p>JAPAN CRAFT SAKE COMPANY Co., Ltd.</p>
<p>Email:sbc@craftsake.jp</p>
`;
