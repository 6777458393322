import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { StyleRules, Theme, withStyles } from '@material-ui/core/styles';
import { IStateApps } from '@models/state-apps';
import { IStoreState } from '@redux/reducers';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

class ProgressClass extends React.PureComponent<Props> {
  render() {
    const { classes } = this.props;
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Box mt={3} />
        <Grid container justify='center'>
          <Grid item xs={12}>
            <Box mt={5} />
          </Grid>
          <Grid item xs={12}>
            <Box mt={5} />
          </Grid>
          <Grid item xs={12}>
            <Grid container justify='center'>
              <CircularProgress className={classes.progress} />
            </Grid>
          </Grid>
        </Grid>
      </main>
    );
  }
}

export type Props = IStateProps;

export interface IStateProps {
  apps: IStateApps;
  classes: any;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
});

const myStyles = (theme: Theme): StyleRules => ({
  content: {
    width: '100%',
  },
  toolbar: { paddingTop: '68px' },
  progress: { margin: theme.spacing(2) },
});

export const Progress = compose(withStyles(myStyles), connect(mapStateToProps))(ProgressClass);
