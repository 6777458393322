import { EAlertStatus } from '@models/alert-status-type';
import { AllPages } from '@models/all-pages';
import { IStateApps } from '@models/state-apps';
import { LANGUAGE_DEFAULT } from '@utils/common';
import { safeGetJSON } from '@utils/safe-get-json';
import {
  CLOSE_BRAND_EDIT_DIALOG,
  CLOSE_GOOGLE_MAP_DIALOG,
  CLOSE_HISTORY_SUMMARY_ACTIVITIES_DIALOG,
  CLOSE_HISTORY_SUMMARY_COMMENTS_DIALOG,
  CLOSE_HISTORY_SUMMARY_EDIT_DIALOG,
  CLOSE_HISTORY_SUMMARY_TRANSITION_DIALOG,
  CLOSE_LOCATION_ANALYZE_COMMENTS_DIALOG,
  CLOSE_LOCATION_EDIT_DIALOG,
  CLOSE_ORDER_OF_SECTIONS_DIALOG,
  CLOSE_REST_PRODUCT_ACTIVITIES_DIALOG,
  CLOSE_REST_PRODUCT_COMMENTS_DIALOG,
  CLOSE_REST_PRODUCT_EDIT_DIALOG,
  CLOSE_REST_PRODUCT_TRANSITION_DIALOG,
  CLOSE_SCAN_ANALYZE_AGES_TRANSITION_DIALOG,
  CLOSE_SCAN_ANALYZE_BRANDS_TRANSITION_DIALOG,
  CLOSE_SCAN_ANALYZE_CITIES_TRANSITION_DIALOG,
  CLOSE_SCAN_ANALYZE_COMMENTS_DIALOG,
  CLOSE_SCAN_ANALYZE_COUNTRIES_TRANSITION_DIALOG,
  CLOSE_SCAN_ANALYZE_GENDERS_TRANSITION_DIALOG,
  CLOSE_SCAN_ANALYZE_TIMEZONES_TRANSITION_DIALOG,
  CLOSE_SEARCH_FILTER_POPUP,
  CLOSE_SERIALS_COMMENTS_DIALOG,
  CLOSE_SERIAL_EDIT_DIALOG,
  CLOSE_SNACKBAR_MESSAGE,
  CLOSE_USER_EDIT_DIALOG,
  CLOSE_VIDEO_CALL_DIALOG,
  DONE_SETTING,
  INITIALIZE_APPS,
  OPEN_BRAND_EDIT_DIALOG,
  OPEN_GOOGLE_MAP_POLYLINE_DIALOG,
  OPEN_HISTORY_SUMMARY_ACTIVITIES_DIALOG,
  OPEN_HISTORY_SUMMARY_COMMENTS_DIALOG,
  OPEN_HISTORY_SUMMARY_EDIT_DIALOG,
  OPEN_HISTORY_SUMMARY_TRANSITION_DIALOG,

  // - location analyze
  OPEN_LOCATION_ANALYZE_COMMENTS_DIALOG,
  OPEN_LOCATION_EDIT_DIALOG,
  // - sections
  OPEN_ORDER_OF_SECTIONS_DIALOG,
  OPEN_REST_PRODUCT_ACTIVITIES_DIALOG,
  OPEN_REST_PRODUCT_COMMENTS_DIALOG,
  OPEN_REST_PRODUCT_EDIT_DIALOG,
  OPEN_REST_PRODUCT_TRANSITION_DIALOG,
  OPEN_SCAN_ANALYZE_AGES_TRANSITION_DIALOG,
  OPEN_SCAN_ANALYZE_BRANDS_TRANSITION_DIALOG,
  OPEN_SCAN_ANALYZE_CITIES_TRANSITION_DIALOG,
  // - scan analyze
  OPEN_SCAN_ANALYZE_COMMENTS_DIALOG,
  OPEN_SCAN_ANALYZE_COUNTRIES_TRANSITION_DIALOG,
  OPEN_SCAN_ANALYZE_GENDERS_TRANSITION_DIALOG,
  OPEN_SCAN_ANALYZE_TIMEZONES_TRANSITION_DIALOG,
  OPEN_SEARCH_FILTER_POPUP,
  OPEN_SERIALS_COMMENTS_DIALOG,
  OPEN_SERIAL_EDIT_DIALOG,
  OPEN_SNACKBAR_MESSAGE,
  OPEN_USER_EDIT_DIALOG,

  // test video call
  OPEN_VIDEO_CALL_DIALOG,
  SET_CURRENT_LANGUAGE,
  SET_CURRENT_PAGE,
  // - brands
  SET_EDIT_BRAND,
  // - rest-ship-conf
  SET_EDIT_HISTORY_SUMMARY,

  // - locations
  SET_EDIT_LOCATION,
  // - rest-management
  SET_EDIT_REST_PRODUCT,
  // - serial-dist-histories
  SET_EDIT_SERIAL,

  // - users
  SET_EDIT_USER,
  SET_EXPANDED_REST_STATUS_TABLE_NESTED_TABLE,
  SET_MONITORING_FILTER,
  SET_ORDER_OF_SECTIONS,
  SET_SEARCH_FILTER_SELECTION_VALUES,
  SET_SELECTED_SIDE_PANEL_OPTION_ID,
  START_SETTING,
} from '../actions';

const storage = window.localStorage;

const currentLanguage = storage.getItem(SET_CURRENT_LANGUAGE)
  ? storage.getItem(SET_CURRENT_LANGUAGE)
  : LANGUAGE_DEFAULT;
const orderOfSessions = storage.getItem(SET_ORDER_OF_SECTIONS)
  ? safeGetJSON(storage.getItem(SET_ORDER_OF_SECTIONS))
  : [];
const initializeState: IStateApps = {
  selectedSidePanelOptionId: null,
  nowSetting: false,
  isOpenSnackbarMessage: false,
  snackbarMessageFormat: 'info',
  currentLanguage,
  snackbarMessage: '',
  editSerial: {},
  isOpenSerialEditDialog: false,
  googleMapPolylineSettings: {},
  isOpenGoogleMapPolylineDialog: false,
  isOpenSerialCommentsDialog: false,
  editHistorySummary: {},
  isOpenHistorySummaryEditDialog: false,
  isOpenHistorySummaryActivitiesDialog: false,
  isOpenHistorySummaryTransitionDialog: false,
  isOpenHistorySummaryCommentsDialog: false,
  editRestProduct: {},
  isOpenRestProductEditDialog: false,
  isOpenRestProductActivitiesDialog: false,
  isOpenRestProductTransitionDialog: false,
  isOpenRestProductCommentsDialog: false,
  isOpenScanAnalyzeCommentsDialog: false,
  isOpenScanAnalyzeBrandsTransitionDialog: false,
  isOpenScanAnalyzeCountriesTransitionDialog: false,
  isOpenScanAnalyzeCitiesTransitionDialog: false,
  isOpenScanAnalyzeAgesTransitionDialog: false,
  isOpenScanAnalyzeGendersTransitionDialog: false,
  isOpenScanAnalyzeTimezonesTransitionDialog: false,
  isOpenLocationAnalyzeCommentsDialog: false,
  editLocation: {},
  isOpenLocationEditDialog: false,
  editBrand: {},
  isOpenBrandEditDialog: false,
  editUser: null,
  isOpenUserEditDialog: false,
  isOpenOrderOfSessionsDialog: false,
  orderOfSessions,
  isOpenVideoCallDialog: false,
  searchFilterPageCaller: null,
  isSearchFilterPopupOpen: false,
  filterSelectedValues: {
    brands: null,
    breweries: null,
    customization: null,
    dashboard: null,
    'distribution-history': null,
    distributors: null,
    monitoring: null,
    'notification-list': null,
    'purchase-history': null,
    reports: null,
    restaurants: null,
    'scans-ingoing': null,
    'scans-outgoing': null,
    'survey-history': null,
    'task-management': null,
    vips: null,
  },
  expandedRestStatusTableNestTable: {},
  monitoringFilter: {
    selectedSortItem: 0,
    selectedStatus: [EAlertStatus.UNPROCESSED, EAlertStatus.PROCESSING, EAlertStatus.PROCESSED],
  },
  currentPage: null,
  brandPopupOpener: 'master',
  locPopupOpener: 'master',
  isFooterVisible: true,
};

export function apps(state: IStateApps = initializeState, action): IStateApps {
  let isOpenSnackbarMessage = false;
  let snackbarMessageFormat = 'info';
  let snackbarMessage = '';
  switch (action.type) {
    case INITIALIZE_APPS: {
      storage.clear();
      return {
        ...initializeState,
      };
    }
    case OPEN_SNACKBAR_MESSAGE: {
      isOpenSnackbarMessage = true;
      snackbarMessage = action.message;
      snackbarMessageFormat = action.format;
      return {
        ...state,
        isOpenSnackbarMessage,
        snackbarMessageFormat,
        snackbarMessage,
      };
    }
    case OPEN_SEARCH_FILTER_POPUP: {
      return {
        ...state,
        searchFilterPageCaller: action.caller,
        isSearchFilterPopupOpen: true,
      };
    }
    case CLOSE_SEARCH_FILTER_POPUP: {
      return {
        ...state,
        searchFilterPageCaller: null,
        isSearchFilterPopupOpen: false,
      };
    }
    case SET_SEARCH_FILTER_SELECTION_VALUES: {
      return {
        ...state,
        filterSelectedValues: {
          ...state.filterSelectedValues,
          ...action.selectedListPageFilterValues,
        },
      };
    }
    case CLOSE_SNACKBAR_MESSAGE:
      return {
        ...state,
        isOpenSnackbarMessage,
        snackbarMessageFormat,
        snackbarMessage,
      };
    case START_SETTING:
      return {
        ...state,
        nowSetting: true,
      };
    case DONE_SETTING:
      return {
        ...state,
        nowSetting: false,
      };
    case SET_CURRENT_LANGUAGE: {
      const currentLanguage = action.lang;
      storage.setItem(SET_CURRENT_LANGUAGE, currentLanguage);
      return {
        ...state,
        currentLanguage,
      };
    }
    // sections
    case OPEN_ORDER_OF_SECTIONS_DIALOG:
      return {
        ...state,
        isOpenOrderOfSessionsDialog: true,
      };
    case CLOSE_ORDER_OF_SECTIONS_DIALOG:
      return {
        ...state,
        isOpenOrderOfSessionsDialog: false,
      };
    case SET_ORDER_OF_SECTIONS: {
      const orderOfSessions = action.array;
      if (Array.isArray(orderOfSessions)) {
        storage.setItem(SET_ORDER_OF_SECTIONS, JSON.stringify(orderOfSessions));
        return {
          ...state,
          orderOfSessions,
        };
      }
      break;
    }
    // serial-dist-histories
    case SET_EDIT_SERIAL:
      return {
        ...state,
        editSerial: action.obj,
      };
    case OPEN_SERIAL_EDIT_DIALOG:
      return {
        ...state,
        editSerial: action.obj,
        isOpenSerialEditDialog: true,
      };
    case CLOSE_SERIAL_EDIT_DIALOG:
      return {
        ...state,
        editSerial: null,
        isOpenSerialEditDialog: false,
      };
    case SET_EXPANDED_REST_STATUS_TABLE_NESTED_TABLE:
      return {
        ...state,
        expandedRestStatusTableNestTable: {
          ...state.expandedRestStatusTableNestTable,
          [action.restLocId]: action.isDisplay,
        },
      };
    case OPEN_GOOGLE_MAP_POLYLINE_DIALOG:
      return {
        ...state,
        googleMapPolylineSettings: action.obj,
        isOpenGoogleMapPolylineDialog: true,
      };
    case CLOSE_GOOGLE_MAP_DIALOG:
      return {
        ...state,
        googleMapPolylineSettings: null,
        isOpenGoogleMapPolylineDialog: false,
      };
    case OPEN_SERIALS_COMMENTS_DIALOG:
      return {
        ...state,
        isOpenSerialCommentsDialog: true,
      };
    case CLOSE_SERIALS_COMMENTS_DIALOG:
      return {
        ...state,
        isOpenSerialCommentsDialog: false,
      };
    // rest-ship-conf
    case SET_EDIT_HISTORY_SUMMARY:
      return {
        ...state,
        editHistorySummary: action.obj,
      };
    case OPEN_HISTORY_SUMMARY_EDIT_DIALOG:
      return {
        ...state,
        editHistorySummary: action.obj,
        isOpenHistorySummaryEditDialog: true,
      };
    case CLOSE_HISTORY_SUMMARY_EDIT_DIALOG:
      return {
        ...state,
        editHistorySummary: null,
        isOpenHistorySummaryEditDialog: false,
      };
    case OPEN_HISTORY_SUMMARY_ACTIVITIES_DIALOG:
      return {
        ...state,
        isOpenHistorySummaryActivitiesDialog: true,
      };
    case CLOSE_HISTORY_SUMMARY_ACTIVITIES_DIALOG:
      return {
        ...state,
        isOpenHistorySummaryActivitiesDialog: false,
      };
    case OPEN_HISTORY_SUMMARY_TRANSITION_DIALOG:
      return {
        ...state,
        isOpenHistorySummaryTransitionDialog: true,
      };
    case CLOSE_HISTORY_SUMMARY_TRANSITION_DIALOG:
      return {
        ...state,
        isOpenHistorySummaryTransitionDialog: false,
      };
    case OPEN_HISTORY_SUMMARY_COMMENTS_DIALOG:
      return {
        ...state,
        isOpenHistorySummaryCommentsDialog: true,
      };
    case CLOSE_HISTORY_SUMMARY_COMMENTS_DIALOG:
      return {
        ...state,
        isOpenHistorySummaryCommentsDialog: false,
      };
    case SET_SELECTED_SIDE_PANEL_OPTION_ID:
      return {
        ...state,
        selectedSidePanelOptionId: action.obj,
      };
    case SET_CURRENT_PAGE:
      const currentPage: AllPages = action.pageId;
      const isFooterVisible = currentPage !== 'chat';
      return {
        ...state,
        isFooterVisible,
        currentPage: action.pageId,
      };
    // rest-management
    case SET_EDIT_REST_PRODUCT:
      return {
        ...state,
        editRestProduct: action.obj,
      };
    case OPEN_REST_PRODUCT_EDIT_DIALOG:
      return {
        ...state,
        editRestProduct: action.obj,
        isOpenRestProductEditDialog: true,
      };
    case CLOSE_REST_PRODUCT_EDIT_DIALOG:
      return {
        ...state,
        editRestProduct: null,
        isOpenRestProductEditDialog: false,
      };
    case OPEN_REST_PRODUCT_ACTIVITIES_DIALOG:
      return {
        ...state,
        isOpenRestProductActivitiesDialog: true,
      };
    case CLOSE_REST_PRODUCT_ACTIVITIES_DIALOG:
      return {
        ...state,
        isOpenRestProductActivitiesDialog: false,
      };
    case OPEN_REST_PRODUCT_TRANSITION_DIALOG:
      return {
        ...state,
        isOpenRestProductTransitionDialog: true,
      };
    case CLOSE_REST_PRODUCT_TRANSITION_DIALOG:
      return {
        ...state,
        isOpenRestProductTransitionDialog: false,
      };
    case OPEN_REST_PRODUCT_COMMENTS_DIALOG:
      return {
        ...state,
        isOpenRestProductCommentsDialog: true,
      };
    case CLOSE_REST_PRODUCT_COMMENTS_DIALOG:
      return {
        ...state,
        isOpenRestProductCommentsDialog: false,
      };
    // scan analyze
    case OPEN_SCAN_ANALYZE_COMMENTS_DIALOG:
      return {
        ...state,
        isOpenScanAnalyzeCommentsDialog: true,
      };
    case CLOSE_SCAN_ANALYZE_COMMENTS_DIALOG:
      return {
        ...state,
        isOpenScanAnalyzeCommentsDialog: false,
      };
    case OPEN_SCAN_ANALYZE_BRANDS_TRANSITION_DIALOG:
      return {
        ...state,
        isOpenScanAnalyzeBrandsTransitionDialog: true,
      };
    case CLOSE_SCAN_ANALYZE_BRANDS_TRANSITION_DIALOG:
      return {
        ...state,
        isOpenScanAnalyzeBrandsTransitionDialog: false,
      };
    case OPEN_SCAN_ANALYZE_COUNTRIES_TRANSITION_DIALOG:
      return {
        ...state,
        isOpenScanAnalyzeCountriesTransitionDialog: true,
      };
    case CLOSE_SCAN_ANALYZE_COUNTRIES_TRANSITION_DIALOG:
      return {
        ...state,
        isOpenScanAnalyzeCountriesTransitionDialog: false,
      };
    case OPEN_SCAN_ANALYZE_CITIES_TRANSITION_DIALOG:
      return {
        ...state,
        isOpenScanAnalyzeCitiesTransitionDialog: true,
      };
    case CLOSE_SCAN_ANALYZE_CITIES_TRANSITION_DIALOG:
      return {
        ...state,
        isOpenScanAnalyzeCitiesTransitionDialog: false,
      };
    case OPEN_SCAN_ANALYZE_AGES_TRANSITION_DIALOG:
      return {
        ...state,
        isOpenScanAnalyzeAgesTransitionDialog: true,
      };
    case CLOSE_SCAN_ANALYZE_AGES_TRANSITION_DIALOG:
      return {
        ...state,
        isOpenScanAnalyzeAgesTransitionDialog: false,
      };
    case OPEN_SCAN_ANALYZE_GENDERS_TRANSITION_DIALOG:
      return {
        ...state,
        isOpenScanAnalyzeGendersTransitionDialog: true,
      };
    case CLOSE_SCAN_ANALYZE_GENDERS_TRANSITION_DIALOG:
      return {
        ...state,
        isOpenScanAnalyzeGendersTransitionDialog: false,
      };
    case OPEN_SCAN_ANALYZE_TIMEZONES_TRANSITION_DIALOG:
      return {
        ...state,
        isOpenScanAnalyzeTimezonesTransitionDialog: true,
      };
    case CLOSE_SCAN_ANALYZE_TIMEZONES_TRANSITION_DIALOG:
      return {
        ...state,
        isOpenScanAnalyzeTimezonesTransitionDialog: false,
      };
    case SET_MONITORING_FILTER:
      return {
        ...state,
        monitoringFilter: {
          selectedSortItem: action.selectedSortItem,
          selectedStatus: action.selectedStatus,
        },
      };
    // location analyze
    case OPEN_LOCATION_ANALYZE_COMMENTS_DIALOG:
      return {
        ...state,
        isOpenLocationAnalyzeCommentsDialog: true,
      };
    case CLOSE_LOCATION_ANALYZE_COMMENTS_DIALOG:
      return {
        ...state,
        isOpenLocationAnalyzeCommentsDialog: false,
      };
    // locations
    case SET_EDIT_LOCATION:
      return {
        ...state,
        editLocation: action.obj,
      };
    case OPEN_LOCATION_EDIT_DIALOG:
      return {
        ...state,
        editLocation: action.obj,
        locPopupOpener: action.opener,
        isOpenLocationEditDialog: true,
      };
    case CLOSE_LOCATION_EDIT_DIALOG:
      return {
        ...state,
        editLocation: null,
        isOpenLocationEditDialog: false,
      };
    // brands
    case SET_EDIT_BRAND:
      return {
        ...state,
        editBrand: action.obj,
      };
    case OPEN_BRAND_EDIT_DIALOG:
      return {
        ...state,
        editBrand: action.obj,
        brandPopupOpener: action.opener,
        isOpenBrandEditDialog: true,
      };
    case CLOSE_BRAND_EDIT_DIALOG:
      return {
        ...state,
        editBrand: null,
        isOpenBrandEditDialog: false,
      };
    // users
    case SET_EDIT_USER:
      return {
        ...state,
        editUser: action.obj,
      };
    case OPEN_USER_EDIT_DIALOG:
      return {
        ...state,
        editUser: action.obj,
        isOpenUserEditDialog: true,
      };
    case CLOSE_USER_EDIT_DIALOG:
      return {
        ...state,
        editUser: null,
        isOpenUserEditDialog: false,
      };
    // test video call
    case OPEN_VIDEO_CALL_DIALOG:
      return {
        ...state,
        isOpenVideoCallDialog: true,
      };
    case CLOSE_VIDEO_CALL_DIALOG:
      return {
        ...state,
        isOpenVideoCallDialog: false,
      };
    default:
      break;
  }
  return state;
}
