import { IAlert } from '@models/api-get-alerts';
import { ECategoryType } from '@models/category-type';
import { ISerial } from '@models/serial';
import { isOk } from '@utils/is-ok';
import { getCurrentLocations } from './get-current-locations';

export function getAlertCategory(targetSerial: ISerial, targetAlert: IAlert): ECategoryType {
  const { currentBrewLoc, currentDistLoc, currentRestLoc } = getCurrentLocations(targetSerial);
  if (isOk(targetAlert?.alertLocId)) {
    if (currentBrewLoc?.id === targetAlert?.alertLocId) {
      return ECategoryType.BREWERY;
    } else if (currentDistLoc?.id === targetAlert?.alertLocId) {
      return ECategoryType.DISTRIBUTOR;
    } else if (currentRestLoc?.id === targetAlert?.alertLocId) {
      return ECategoryType.RESTAURANT;
    }
  }
}
