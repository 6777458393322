import userEnv from 'userEnv';
import { isOk } from './is-ok';
import { safeGetJSON } from './safe-get-json';

export function getReadNotificationIds(): number[] {
  const currentData = localStorage.getItem(userEnv.localStorageReadNotifications);
  if (!isOk(currentData)) return [];
  try {
    return safeGetJSON(currentData);
  } catch {
    return [];
  }
}

export function addReadNotificationId(addId: number) {
  const currentIds = getReadNotificationIds();
  currentIds.push(addId);
  localStorage.setItem(userEnv.localStorageReadNotifications, JSON.stringify(currentIds));
}

export function isNotificationIdNew(targetId: number) {
  const currentIds = getReadNotificationIds();
  return !currentIds.includes(targetId);
}

export function getNotificationStatus(notification: any, userId: number) {
  const notice = notification;
  if (notice.readBy) {
    const readBy = JSON.parse(notice.readBy);
    const userReadStatus = readBy.find((x: any) => x === userId);
    if (!userReadStatus) {
      return true;
    }
    return false;
  }
  return true;
}
