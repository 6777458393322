import { storage } from '@utils/firebase';

export async function uploadImages(
  e: React.ChangeEvent<HTMLInputElement>,
  restId: number,
  userUid: string,
) {
  const { files } = e.target;
  const uploadedImagesUrl: string[] = [];
  for (let i = 0; i < files.length; i += 1) {
    const imgFile = files[i];
    const fileName = `${imgFile.size}-${imgFile.lastModified}-${imgFile.type}`.replace('/', '_');
    const storageRef = storage.ref(`upload/${userUid}/restaurant-images/${restId}/${fileName}`);
    const uploadTask = await storageRef.put(imgFile);

    uploadedImagesUrl.push(await uploadTask.ref.getDownloadURL());
  }

  return await uploadedImagesUrl;
}
