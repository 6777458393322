import { Box, Hidden } from '@material-ui/core';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import { IStateApps } from '@models/state-apps';
import { IStateServers } from '@models/state-servers';
import { Brands } from '@old-components/brands';
import { IStoreState } from '@redux/reducers';
import { History, LocationState } from 'history';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import userEnv from 'userEnv';
import TabPanel from '../../../elements/TabPanel';
import { Locations } from '../locations';
import { Progress } from '../progress';
import { Users } from '../users';

class MasterClass extends React.PureComponent<Props> {
  private basePath;

  constructor(props) {
    super(props);
    this.basePath = `${userEnv.rootDir}master/`;
  }

  getCurrentIndex() {
    const { history } = this.props;
    const path = history.location.pathname;
    if (path === `${this.basePath}locations`) return 0;
    if (path === `${this.basePath}brands`) return 1;
    if (path === `${this.basePath}users`) return 2;
    return 0;
  }

  handleChange(e, tabValue) {
    const { history } = this.props;
    window.scrollTo(0, 0);
    switch (tabValue) {
      case 0:
        return history.push(`${this.basePath}locations`);
      case 1:
        return history.push(`${this.basePath}brands`);
      case 2:
        return history.push(`${this.basePath}users`);
      default:
        break;
    }
    return true;
  }

  render() {
    const { servers, classes } = this.props;
    const tabValue = this.getCurrentIndex();

    return servers.user ? (
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Hidden xsUp>
          {/* Chrome の自動補完対策 (1) */}
          <input type='email' onChange={() => null} value='' />
          <input type='password' onChange={() => null} value='' />
          <input type='text' onChange={() => null} value='' />
        </Hidden>
        <Box style={{ display: 'none' }}>
          {/* Chrome の自動補完対策 (2) */}
          <input type='email' onChange={() => null} value='' />
          <input type='password' onChange={() => null} value='' />
          <input type='text' onChange={() => null} value='' />
        </Box>
        <TabPanel value={tabValue} index={0}>
          {tabValue === 0 ? <Locations /> : <Box />}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {tabValue === 1 ? <Brands /> : <Box />}
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          {tabValue === 2 ? <Users /> : <Box />}
        </TabPanel>
      </main>
    ) : (
      <Progress />
    );
  }
}

export type Props = IStateProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  history: History<LocationState>;
  classes: any;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
});

const myStyles = (): StyleRules => ({
  content: { width: '100%' },
  toolbar: { paddingTop: '68px' },
  noneTextTransform: { textTransform: 'none' },
});

export const Master = compose(
  withStyles(myStyles),
  connect(mapStateToProps),
)(withRouter(MasterClass));
